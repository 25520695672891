import { PaginationConfig } from "@interfaces";
import * as fromCompanyActions from "../actions/company.actions";

export interface CompanyState {
  search: string;
  paginationConfig: PaginationConfig;
}

export const initialState: CompanyState = {
  search: "",
  paginationConfig: {
    page: 1,
    pageSize: 5,
    collectionSize: 120,
  },
};

export function CompanyReducer(
  state: CompanyState = initialState,
  action: fromCompanyActions.CompanyActions
): CompanyState {
  switch (action.type) {
    case fromCompanyActions.COMPANY_SEARCH:
      return {
        ...state,
        search: action.search,
      };
    case fromCompanyActions.COMPANY_PAGE:
      return {
        ...state,
        paginationConfig: { ...action.paginationConfig },
      };
    default:
      return state;
  }
}
