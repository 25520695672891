import { Platform } from "@angular/cdk/platform";
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
  } from "@angular/common/http";
  import { Injectable } from "@angular/core";
  import { Router } from "@angular/router";
  import { TranslateService } from "@ngx-translate/core";
  import { Observable } from "rxjs";
  @Injectable()
  export class MobileInterceptor implements HttpInterceptor {
    constructor(
      private router: Router,
      public translate: TranslateService,
      private platform: Platform
    ) {}
    
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {

        if ((this.platform.ANDROID || this.platform.IOS) && (!this.router.url.includes("/login") && this.router.url !== "/" && !this.router.url.includes("/signup") )) {
            window.location.href = "https://www.vyootrip.com/mobile/";
          }

      return next.handle(req.clone());
    }
  }
  