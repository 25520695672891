import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Hotel } from "@core/models";
import { environment } from "@env/environment";
import { SearchConfig } from "@shared/interfaces";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class SearchService {
  hotelsList: Hotel[] = [];
  hotelsMap: Hotel[] = [];
  searchConfig: SearchConfig = {
    uuid: "",
    locationText: "",
    latitude: "",
    longitude: "",
    checkIn: null,
    minElements: "0",
    checkOut: null,
    occupancies: [
      {
        rooms: 1,
        adults: 1,
        paxes: [],
      },
    ],
  };
  nextMinElements = 0;
  facilities: string[] = [];
  constructor(private http: HttpClient) {}

  // LIST HOTELS BY COORDINATES
  getListHotelsByCoordinate(params): Observable<any> {
    return this.http
      .post<any>(
        `${environment.baseUrlApi}/hotels/hotels-by-coordinates`,
        params
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  getListHotelsByCoordinateAndFilters(params) {
    return this.http
      .post<any>(
        `${environment.baseUrlApi}/hotels/hotels-by-coordinates`,
        params
      )
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }
  getListHotelsScroll(params, minElements) {
    return this.http.post<any>(
      `${environment.baseUrlApi}/hotels/hotels-by-coordinates?minElements=${minElements}`,
      params
    );
  }
  // GET HOTEL
  getHotel(code: number, params) {
    return this.http
      .post<any>(`${environment.baseUrlApi}/hotels/${code}`, params)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  // GET FACILITIES
  getFacilities() {
    return this.http
      .get<any>(`${environment.baseUrlApi}/hotels/facilities`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateStaticData() {
    return this.http
      .get<any>(`${environment.baseUrlApi}/hotels/update-static-data`)
      .pipe(
        map((res: any) => {
          return {}
        })
      );
  }
}
