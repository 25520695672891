import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as Highcharts from "highcharts";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import Variablepie from "highcharts/modules/variable-pie";

HC_exporting(Highcharts);
HC_exporting_data(Highcharts);
Variablepie(Highcharts);
@Component({
  selector: "pie-optm",
  templateUrl: "./pie-optm.component.html",
  styleUrls: ["./pie-optm.component.scss"],
})
export class PieOptmComponent implements OnInit, OnDestroy {
  @Input() savings;
  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.loadChart();
  }

  loadChart() {
    this.translate
      .get([
        "company.optm-form.savings.title",
        "company.optm-form.savings.optimization",
        "company.optm-form.savings.support",
        "company.optm-form.savings.co2",
        "company.optm-form.savings.times",
        "company.optm-form.savings.cost",
        "common.hotels",
        "common.flights",
        "common.trains",
      ])
      .subscribe((res) => {
        Highcharts.chart("pie", {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
          },
          title: {
            text: res["company.optm-form.savings.title"],
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              },
            },
          },
          series: [
            {
              type: undefined,
              colorByPoint: true,
              name: res["company.optm-form.savings.optimization"],
              data: [
                {
                  name: res["company.optm-form.savings.support"],
                  y:
                    this.savings !== null &&
                    this.savings &&
                    this.savings.savingSupport
                      ? this.savings.savingSupport
                      : 20,
                },
                {
                  name: res["common.hotels"],
                  y:
                    this.savings !== null &&
                    this.savings &&
                    this.savings.savingHotel
                      ? this.savings.savingHotel
                      : 20,
                },
                {
                  name: res["common.flights"],
                  y:
                    this.savings !== null &&
                    this.savings &&
                    this.savings.savingFlight
                      ? this.savings.savingFlight
                      : 10,
                },
                {
                  name: res["company.optm-form.savings.co2"],
                  y:
                    this.savings !== null &&
                    this.savings &&
                    this.savings.savingCO2
                      ? this.savings.savingCO2
                      : 10,
                },
                {
                  name: res["company.optm-form.savings.times"],
                  y:
                    this.savings !== null &&
                    this.savings &&
                    this.savings.savingTime
                      ? this.savings.savingTime
                      : 20,
                },
                {
                  name: res["common.trains"],
                  y:
                    this.savings !== null &&
                    this.savings &&
                    this.savings.savingTrain
                      ? this.savings.savingTrain
                      : 5,
                },
                {
                  name: res["company.optm-form.savings.cost"],
                  y:
                    this.savings !== null &&
                    this.savings &&
                    this.savings.savingManagement
                      ? this.savings.savingManagement
                      : 25,
                },
              ],
            },
          ],
          exporting: {
            csv: {},
            buttons: {
              contextButton: {
                menuItems: ["downloadPNG", "downloadJPEG"],
              },
            },
          },
        });
      });
  }

  ngOnDestroy() {}
}
