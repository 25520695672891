import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { PaginationConfig } from "@interfaces";
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbInputDatepicker,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CompanyService, NgxToastrService, UtilsService } from "@services";
import { ViewPdfModalCompanyComponent } from "app/modules/businessEntities/company/booked-homepage/view-pdf-modal-company/view-pdf-modal-company.component";
import moment from "moment";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import swal from "sweetalert2";
import { UploadBill } from "./uploadBill/uploadBill.component";

@Component({
  selector: "app-bills",
  templateUrl: "./bills.component.html",
  styleUrls: ["./bills.component.scss"],
})
export class BillsComponent implements OnInit, OnDestroy {
  @Input() uuidBusinessEntity;
  @Input() businessEntityType;
  loading: false;
  form: UntypedFormGroup;
  bills: any[];
  paginationConf: PaginationConfig = {
    page: 1,
    pageSize: 8,
    collectionSize: 120,
  };
  lowValue = 0;
  highValue = 8;
  subscriptions: Subscription[] = [];
  reloadSubscription: Subscription;
  @ViewChild("date") dateNgb: NgbInputDatepicker;
  @ViewChild("dateInput") dateInput: ElementRef;
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private ngbDateParser: NgbDateParserFormatter,
    private ngxToastrService: NgxToastrService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      date: [""],
      reference: [""],
    });
    this.bills = [];

    this.companyService.getBills(this.uuidBusinessEntity).subscribe((bills) => {
      this.bills = bills.reverse();
    });

    this.reloadSubscription= this.utilsService.reload$.subscribe(() => {
      this.reloadBills();
    });
  }
  get f() {
    return this.form.controls;
  }

  reloadBills(){
    this.companyService.getBills(this.uuidBusinessEntity).subscribe((bills) => {
      this.bills = bills.reverse();
    });
  }

  cleanFilters() {
    this.f.date.setValue("");
    this.f.reference.setValue("");
    this.dateInput.nativeElement.value = "";
  }
  transformDate(date: Date) {
    return this.translate.currentLang === "es"
      ? moment(date).format("DD/MM/YYYY")
      : moment(date).format("MM/DD/YYYY");
  }

  getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  downloadBill(bill) {
    this.companyService
      .getBill(bill.uuid, bill.companyUuid)
      .pipe(take(1))
      .subscribe(
        (res) => {
          const modalRef = this.modalService.open(
            ViewPdfModalCompanyComponent,
            { size: "lg", centered: true }
          );
          if (res.type.includes("image")) {
            modalRef.componentInstance.blobUrl = bill.uuid;
            modalRef.componentInstance.companyUuid = bill.companyUuid;
          } else {
            modalRef.componentInstance.blobUrl = URL.createObjectURL(res);
          }
          modalRef.componentInstance.fileName = bill.reference;
        },
        () =>
          this.translate
            .get(["common.error-pdf"])
            .pipe(take(1))
            .subscribe((value) =>
              this.ngxToastrService.typeInfo(null, value["common.error-pdf"])
            )
      );
  }

  removeBill(bill) {
    this.translate
      .get([
        `user.details.cancel-expense`,
        "user.details.action-no-undo",
        "common.confirm",
        "common.cancel",
      ])
      .subscribe((result) => {
        swal
          .fire({
            title: result["user.details.cancel-bill"],
            text: result["user.details.action-no-undo"],
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0CC27E",
            cancelButtonColor: "#FF586B",
            confirmButtonText: result["common.confirm"],
            cancelButtonText: result["common.cancel"],
            customClass: {
              confirmButton: "btn btn-success btn-raised",
              cancelButton: "btn btn-danger btn-raised mr-5",
            },
            buttonsStyling: false,
          })
          .then((action) => {
            if (action.value) {
              this.subscriptions.push(
                this.companyService.deleteBill(bill.uuid).subscribe(
                  () => {
                    this.translate
                      .get(`user.details.bill-canceled`)
                      .subscribe((resp) => {
                        this.ngxToastrService.typeSuccess(null, resp);
                        this.companyService
                          .getBills(this.uuidBusinessEntity)
                          .subscribe((bills) => {
                            this.bills = bills.reverse();
                          });
                      });
                  },
                  (err) => {
                    this.translate
                      .get(`user.details.error-cancel-bill`)
                      .subscribe((resp) => {
                        this.ngxToastrService.typeInfo(resp, err.error.message);
                      });
                  }
                )
              );
            }
          });
      });
  }

  onUploadBill() {
    const modalRef = this.modalService.open(UploadBill, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.companyUuid = this.uuidBusinessEntity;
    modalRef.componentInstance.sendInfo.subscribe((billInfo) => {
      modalRef.componentInstance.loading = true;
      const formData = new FormData();
      if(billInfo.file) {
        formData.append("file", billInfo.file, billInfo.file.name);
      }
      formData.append("date", billInfo.date);
      // TODO ver este parámetro
      formData.append("companyUuid", this.uuidBusinessEntity);
      formData.append("reference", billInfo.reference);

      this.companyService.postBill(formData).subscribe(
        (result) => {
          modalRef.componentInstance.loading = false;
          modalRef.dismiss("Send Info");
          this.translate
            .get(["common.congrat", "company.bills.upload-bill-success"])
            .subscribe((resp) => {
              this.ngxToastrService.typeSuccess(
                resp["common.congrat"],
                resp["company.bills.upload-bill-success"]
              );
            });
        },
        (err) => {
          modalRef.componentInstance.loading = false;
          this.translate
            .get("company.bills.upload-bill-error")
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.message);
            });
        }
      );
    });
  }

  onDateSelection(date: NgbDate) {
    this.form.get("date").setValue(date);
    this.dateInput.nativeElement.value = this.ngbDateParser.format(date);
  }

  canClean() {
    return (
      this.f.date.value !== "" ||
      this.f.reference.value !== ""
    );
  }

  setPage() {
    this.paginator.firstPage();
  }

  ngOnDestroy(): void {
    this.reloadSubscription.unsubscribe();
  }


}
