export class Rates {
  rateClass: string;
  net: string;
  rateComments: string;
  boardCode: string;
  rooms: number;
  adults: number;
  children: number;
  cancellationPolicies: any[];
}
