<div class="container">
  <h3 class="heading-5">
    <div class="icon-bcg">
      <img src="assets/img/svg/sidebar/profile.svg" alt="" />
    </div>
    {{
      "train.dialog-travelers.title" | translate
    }}
  </h3>
  <form class="form-container" [formGroup]="travelerForm">
    <div class="travelers-number-container">
      <button class="left-button" (click)="removeTraveler()">-</button>
      <div>
        {{ getTravelers().controls.length }}
        {{ "train.form-train.travelers" | translate }}
      </div>
      <button class="right-button" (click)="addTraveler()">+</button>
    </div>
    <div class="travelers-age-container">
      <h4 class="heading-6">{{ "train.dialog-travelers.age-title" | translate }}</h4>
      <div
        *ngFor="let traveler of getTravelers().controls; index as i"
        formArrayName="travelers"
      >
        <div [formGroupName]="i">
          <span
            >{{ "train.form-train.traveler" | translate }} {{ i + 1 }}:</span
          >
          <input type="number" min="0" required formControlName="age" />
        </div>
      </div>
    </div>
  </form>
  <div class="actions-container">
    <button class="custom-btn custom-btn--close" (click)="cancel()">
      {{ "common.cancel" | translate }}
    </button>
    <button
      class="custom-btn"
      (click)="confirm()"
      [disabled]="travelerForm.valid === false"
    >
      {{ "train.accept" | translate }}
    </button>
  </div>
</div>
