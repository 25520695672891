import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-insurance",
  templateUrl: "./insurance.component.html",
  styleUrls: ["./insurance.component.scss"],
})
export class InsuranceComponent implements OnInit {
  @Output() sendInfo = new EventEmitter<any>();
  actions = true;
  choosed: string = null;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  select(type) {
    if (this.choosed === type) {
      this.choosed = null;
    } else {
      this.choosed = type;
    }
  }

  onSubmit() {
    this.sendInfo.emit(this.choosed);
    this.closeModal();
  }

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }
}
