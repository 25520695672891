import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatStepperModule } from "@angular/material/stepper";
import { TrainService } from "@core/services";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@shared/shared.module";
import { UserModule } from "app/modules/businessEntities/user/user.module";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { CountdownModule } from "ngx-countdown";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import { DialogTravelersComponent } from "./dialog-travelers/dialog-travelers.component";
import { ConfirmBookingComponent } from "./train-confirm-stepper-container/confirm-booking/confirm-booking.component";
import { PassengerFormComponent } from "./train-confirm-stepper-container/passenger-form/passenger-form.component";
import { TrainConfirmStepperContainerComponent } from "./train-confirm-stepper-container/train-confirm-stepper-container.component";
import { TrainSeatSelectionComponent } from "./train-confirm-stepper-container/train-seat-selection/train-seat-selection.component";
import { TrainSearchResultComponent } from "./train-search-result/train-search-result.component";
import { TrainSearchComponent } from "./train-search/train-search.component";
import { TrainRoutingModule } from "./trains-routing.module";
import { TrainsComponent } from "./trains.component";

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        TranslateModule,
        SharedModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatSelectModule,
        FormsModule,
        MatDialogModule,
        MatButtonModule,
        MatStepperModule,
        MatCardModule,
        MatSelectCountryModule,
        PdfJsViewerModule,
        TrainRoutingModule,
        UserModule,
        CountdownModule,
        NgxSliderModule,
    ],
    exports: [],
    declarations: [
        TrainsComponent,
        ErrorDialogComponent,
        TrainSearchComponent,
        DialogTravelersComponent,
        TrainSearchResultComponent,
        TrainSeatSelectionComponent,
        TrainConfirmStepperContainerComponent,
        PassengerFormComponent,
        ConfirmBookingComponent,
    ],
    providers: [TrainService]
})
export class TrainsModule {}
