import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-gallery-modal",
  templateUrl: "./gallery-modal.component.html",
  styleUrls: ["./gallery-modal.component.scss"],
})
export class GalleryModalComponent implements OnInit {
  @Input() room: any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }
}
