<div class="modal-header">
  <h4 class="modal-title heading-5" id="modal-basic-title">
    {{ "plans.create" | translate }}
  </h4>
</div>
<div class="modal-body p-3">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="planForm">
        <div class="form-row mb-3">
          <div class="col-lg-6 col-md-6 col-xs-12 input-div">
            <label class="input-label">{{
              "plans.form.name" | translate
            }}</label>
            <input class="form-control" formControlName="name" type="text" />
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12 input-div">
            <label class="input-label">{{
              "plans.form.price" | translate
            }}</label>
            <input class="form-control" formControlName="price" type="number" />
          </div>
        </div>
        <label class="input-label">{{
          "plans.customize-card" | translate
        }}</label>
        <div class="color-container">
          <div
            class="color cursor-pointer"
            *ngFor="let color of colorsArr"
            [style]="{ 'background-color': color }"
            (click)="selectColor(color)"
            [ngClass]="{ active: color === selectedColor }"
          ></div>
        </div>
        <div class="form-row mb-3 permission-title">
          <span>{{ "plans.permissions" | translate }}</span>
        </div>
        <div
          class="form-row mb-3 input-div permissions"
          formGroupName="permissions"
        >
          <div class="telefone1">
            <label class="input-label">{{
              "plans.permissions-form.assistance-phone" | translate
            }}</label>
            <input
              class="form-control"
              formControlName="assistancePhone"
              type="text"
              required
            />
          </div>
          <div class="telefone2">
            <label class="input-label">{{
              "plans.permissions-form.init-call-hour" | translate
            }}</label>
            <input class="form-control" formControlName="initCallHour" required />
          </div>
          <div class="telefone3">
            <label class="input-label">{{
              "plans.permissions-form.end-call-hour" | translate
            }}</label>
            <input class="form-control" formControlName="endCallHour" required />
          </div>
        </div>
        <div class="toggle-group" formGroupName="permissions">
          <mat-slide-toggle formControlName="canRequestAutomatizedBookings">
            {{ "plans.permissions-form.automatized-bookings" | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="canRequestCustomBookings">
            {{ "plans.permissions-form.custom-booking" | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="canUseApp">
            {{ "plans.permissions-form.use-app" | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="canUseTRM">
            {{ "plans.permissions-form.use-trm" | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="canGetBills">
            {{ "plans.permissions-form.get-bills" | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="canCreateTravelPolicies">
            {{ "plans.permissions-form.travel-policies" | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="canCreateBookingRequest">
            {{ "plans.permissions-form.booking-request" | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="canUseAlfred">
            {{ "plans.permissions-form.use-alfred" | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="canUseLocalsfy">
            {{ "plans.permissions-form.use-localsfy" | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="canUploadCreditCard">
            {{ "plans.permissions-form.upload-credit-card" | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="canRequestWithoutCard">
            {{ "plans.permissions-form.request-no-card" | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="canPayFlexible">
            {{ "plans.permissions-form.pay-flexible" | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle
            [(ngModel)]="isUnlimitedGroups"
            [ngModelOptions]="{ standalone: true }"
            name="isUnlimitedGroups"
          >
            {{ "plans.permissions-form.unlimited-groups" | translate }}
            <input
              class="max-groups-input"
              type="number"
              min="0"
              *ngIf="!isUnlimitedGroups"
              [disabled]="isUnlimitedGroups"
              [(ngModel)]="maxGroups"
              [ngModelOptions]="{ standalone: true }"
              name="maxGroups"
            />
          </mat-slide-toggle>
        </div>
        <div class="buttons-group">
          <button class="custom-btn custom-btn--close" (click)="closeModal()">
            {{ "common.cancel" | translate }}
          </button>
          <button
            class="custom-btn"
            (click)="onSubmit()"
            [disabled]="!planForm.valid"
          >
            {{ "common.send" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
