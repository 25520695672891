<!--Forgot Password Starts-->
<section id="request-pass" [ngClass]="getClassBackground()">
  <div class="card">
    <div class="image-container">
      <img src="assets/img/gallery/cloche-icon.svg" alt="img-password" height="400" />
    </div>
    <div class="content" [ngClass]="{ 'loading-background': loading }">
      <h4 class="heading-5 mb-2">
        <b>
          {{ "login.request-pass.reset-send.check-mail" | translate }}
        </b>
      </h4>
      <p class="mb-2">
        {{ "login.request-pass.reset-send.mail-send" | translate }}
        <strong>{{ email }}</strong
        >{{ "login.request-pass.reset-send.click-link" | translate }}
      </p>
      <p class="mb-2">
        {{ "login.request-pass.reset-send.mail-spam" | translate }}
      </p>
      <div class="buttons-container">
        <div class="">
          <button
            class="col-12 custom-btn custom-btn--close"
            type="button"
            [routerLink]="goToLogin()"
            [disabled]="loading"
          >
            <span>
              {{ "login.request-pass.back-login" | translate }}
            </span>
          </button>
        </div>
        <div class="">
          <button
            class="btn custom-btn"
            (click)="resendEmail()"
            [disabled]="loading"
          >
            <span class="text-decoration-none text-white">
              {{ "login.request-pass.reset-send.send-mail-again" | translate }}
            </span>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="loading" class="row">
      <app-loading [userType]="typeUser"></app-loading>
    </div>
  </div>
</section>
<!--Forgot Password Ends-->
