import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { PlanPermissions } from "@core/models/plan";
import { AuthService } from "@core/services";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.reducer";
import { Observable } from "rxjs";

@Injectable()
export class CanNavigateGuard implements CanActivate {
  userType: string;
  permissions: PlanPermissions;
  constructor(
    public authService: AuthService,
    private router: Router,
    private store: Store<AppState>
  ) {
    this.store.select("auth").subscribe((s) => {
      this.userType = s.user?.type;
      this.permissions = s?.user["plan"]?.permissions;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.userType === "USER" || this.userType === "COMPANY") {
      if (
        this.permissions &&
        this.permissions.canRequestAutomatizedBookings &&
        this.authService.userFilters
      ) {
        if (this.userType === "COMPANY") {
          return true;
        } else if (
          state.url.toString().includes("search") &&
          this.authService.userFilters.requestBooked
        ) {
          return true;
        } else if (
          state.url.toString().includes("flight") &&
          this.authService.userFilters.requestFlight
        ) {
          return true;
        } else if (
          state.url.toString().includes("train") &&
          this.authService.userFilters.requestTrain
        ) {
          return true;
        } else {
          this.router.navigate(["/"]);
          return false;
        }
      } else {
        if (this.userType === "COMPANY") {
          if (this.permissions.canUseTRM) {
            this.router.navigate(["/"]);
          } else {
            this.router.navigate(["/home"]);
          }
        } else {
          this.router.navigate(["/home"]);
        }
        return false;
      }
    } else {
      return true;
    }
  }
}
