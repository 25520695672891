import { Injectable } from "@angular/core";
import { CanLoad, Route, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.reducer";
import { AuthState } from "app/store/reducers/auth.reducer";
import { Observable } from "rxjs";
import { map, skipWhile, take } from "rxjs/operators";

@Injectable()
export class AuthEmployeeCompanyGuard implements CanLoad {
  constructor(private router: Router, private store: Store<AppState>) {}
  canLoad(route: Route): Observable<boolean> {
    return this.store.select("auth").pipe(
      skipWhile((authState: AuthState) => authState.skipping),
      take(1),
      map((authState: AuthState) => {
        if (!authState.isAuthenticated || authState.user.type?.toUpperCase() === "USER") {
          this.router.navigate(["/login"]);
        }
        return authState.isAuthenticated && authState.user.type?.toUpperCase() !== "USER";
      })
    );
  }
}
