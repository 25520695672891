import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { GraphService } from "@services";
import * as Highcharts from "highcharts";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import * as moment from "moment";
import { take } from "rxjs/operators";
HC_exporting(Highcharts);
HC_exporting_data(Highcharts);

@Component({
  selector: "app-graph",
  templateUrl: "./graph.component.html",
  styleUrls: ["./graph.component.scss"],
})
export class GraphComponent implements OnInit {
  data: any[];
  categories;
  from: string;
  to: string;
  showError: boolean;
  isLoading: boolean = false
  @Input() name: string;
  @Input() type: "hub" | "agency" | "company" | "group" | "user" | "all";
  @Input() uuid: string;
  @Input() graphic: "all" | "service";

  constructor(
    public activeModal: NgbActiveModal,
    private graphService: GraphService,
    private translate: TranslateService
  ) {
    this.from = "01-2020";
    this.to = moment().format("MM-YYYY");
  }

  ngOnInit() {
    this.getDataGraphic();
  }
  
  getDataGraphic() {
    this.isLoading = true
    this.graphService
      .getDataGraphic({
        type: this.type,
        graphic: this.graphic,
        uuid: this.uuid,
        from: this.from,
        to: this.to,
      })
      .pipe(take(1))
      .subscribe((graph: any) => {
        this.createGraph(graph);
        this.isLoading = false
      });
  }

  createGraph(graph, type?: string) {
    const params = [];
    if (this.graphic === "service") {
      graph.data.forEach((value) => {
        params.push(`graph.${value.name}`);
      });
    }
    params.push("graph.expenses");
    params.push("graph.months");
    this.translate.get(params).subscribe((result: any[]) => {
      this.data = graph.data.map((value) => {
        if (value.general === null || value.general === undefined) {
          value.general = 0;
        }
        if (value.data) {
          value.data = value.data.map((d) => 
            d !== null ? parseFloat(d.toFixed(2)) : ''
          );
        }

        return parseFloat(value.general.toFixed(2));
      });

      let series = [];
      Highcharts.setOptions({
        colors: [
          "#F85F1F",
          "#9271f6",
          "#17362f",
          "#058DC7",
          "#50B432",
          "#ED561B",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#5CB5A2",
          "#6AF9C4",
        ],
      });
      if (this.graphic === "service") {
        graph.data.forEach((value, index) => {
          series.push({
            name: result[`graph.${value.name}`],
            data: graph.data[index].data,
            animation: {
              duration: 2000,
              // Uses Math.easeOutBounce
              easing: "easeOutBounce",
            },
            tooltip: {
              pointFormat: " <br/>{series.name}: <b>{point.y}</b>",
              valueSuffix: " €",
              shared: true,
            },
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [
                [0, Highcharts.getOptions().colors[index]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[index])
                    .setOpacity(0)
                    .get("rgba"),
                ],
              ],
            },
            marker: {
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          });
        });
      } else {
        series = [
          {
            name: result["graph.expenses"],
            data: this.data,
            animation: {
              duration: 2000,
              // Uses Math.easeOutBounce
              easing: "easeOutBounce",
            },
            tooltip: {
              pointFormat: " <br/>{series.name}: <b>{point.y}</b>",
              valueSuffix: " €",
              shared: true,
            },
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0)
                    .get("rgba"),
                ],
              ],
            },
          },
        ];
      }
      Highcharts.chart("container", {
        chart: {
          type: this.graphic === "all" ? "areaspline" : "column",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: graph.name,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: result["graph.expenses"],
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} €</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: true,
              symbol: "circle",
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: series,
        exporting: {
          csv: {
            columnHeaderFormatter: function (item, key) {
              if (!key) {
                return result["graph.months"];
              }
              return false;
            },
          },
          buttons: {
            contextButton: {
              menuItems: [
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadXLS",
              ],
            },
          },
        },
      });
    });
  }

  checkDate($event) {
    if ($event) {
      let checkDate = $event.split("-").join("");
      if (checkDate.length > 0) {
        if (checkDate.length < 5) {
          checkDate = checkDate.match(new RegExp(".{1,2}", "g")).join("-");
          return checkDate;
        } else if ($event.match(new RegExp(".{2}-.{3,4}", "g"))) {
          return $event;
        }
        return $event;
      }
    }
  }

  checkInDate($event) {
    this.from = this.checkDate($event);
    this.getDataValidate();
  }

  checkOutDate($event) {
    this.to = this.checkDate($event);
    this.getDataValidate();
  }

  getDataValidate() {
    if (this.correctDates()) {
      this.showError = false;
      this.getDataGraphic();
    } else {
      this.showError = true;
    }
  }
  correctDates() {
    const checkRegExpFrom = this.from
      ? this.from.match(new RegExp("((0[1-9]|1[0-2])-[2]\\d{3})"))
      : null;
    const checkRegExpTo = this.to
      ? this.to.match(new RegExp("((0[1-9]|1[0-2])-[2]\\d{3})"))
      : null;
    const fromAux = checkRegExpFrom ? moment(this.from, "MM-YYYY") : null;
    const toAux = checkRegExpTo ? moment(this.to, "MM-YYYY") : null;
    const validFrom = fromAux
      ? Number(fromAux.format("YYYY")) >= 2019 &&
        Number(fromAux.format("YYYY")) <= Number(moment().format("YYYY"))
      : null;
    const validTo = toAux
      ? Number(toAux.format("YYYY")) >= 2019 &&
        Number(toAux.format("YYYY")) <= Number(moment().format("YYYY"))
      : null;
    return validFrom && validTo ? !fromAux.isAfter(toAux) : false;
  }

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }
}
