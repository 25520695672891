import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { TokenService } from "./token.service";
@Injectable({
  providedIn: "root",
})
export class ExpensesService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  createExpense(body: any): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(`${environment.baseUrlApi}/expenses`, body, {
      headers: reqHeaders,
    });
  }

  editExpense(uuid: string, body: FormData): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/expenses/update/${uuid}`,
      body,
      {
        headers: reqHeaders,
      }
    );
  }

  getCompanyExpenses(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get<any>(
      `${environment.baseUrlApi}/expenses/company/${uuid}`,
      {
        headers: reqHeaders,
      }
    );
  }

  getGroupExpenses(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get<any>(
      `${environment.baseUrlApi}/expenses/groups/${uuid}`,
      {
        headers: reqHeaders,
      }
    );
  }

  getUserExpenses(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get<any>(
      `${environment.baseUrlApi}/expenses/users/${uuid}`,
      {
        headers: reqHeaders,
      }
    );
  }

  getExpensePdf(bonoFileUrl: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get(`${environment.baseUrlApi}${bonoFileUrl}`, {
      headers: reqHeaders,
      responseType: "blob",
    });
  }
  deleteExpense(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.delete(`${environment.baseUrlApi}/expenses/${uuid}`, {
      headers: reqHeaders,
    });
  }

  updateExpenseStatus(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.put(`${environment.baseUrlApi}/expenses/status/${uuid}`, {}, {
      headers: reqHeaders,
    });
  }

}
