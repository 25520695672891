import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-view-pdf-modal",
  templateUrl: "./view-pdf-modal.component.html",
  styleUrls: ["./view-pdf-modal.component.scss"],
})
export class ViewPdfModalComponent implements OnInit {
  @Input() blobUrl: any;
  @Input() fileName: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  downloadPDFBooked() {
    window.open(this.blobUrl, "_blank");
  }

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }
}
