import { FlightRouteDto } from "./flight-route-dto";
export class FlightDto {
  route?: FlightRouteDto[];
  onlyDirect?: boolean;
  roundtrip?: boolean;
  userUuid?: string;
  type?: "ECONOMY" | "PREMIUM_ECONOMY" | "BUSINESS" | "CLASS_FIRST";
  seniors?: number;
  adults?: number;
  youngs?: number;
  children?: number;
  infants?: number;
  travelers?: number;
  sources?: string[];
}
