<div class="container">
  <h2>{{ "train.search-result.title" | translate }}</h2>
  <div class="content-container">
    <div class="search-result-modifier border-radius-30 white-container">
      <div class="train-switch">
        <div class="trip-length-selector-container">
          <div
            id="roundtrip"
            class="cursor-pointer"
            [ngClass]="{ active: showingTrainsReturn === false }"
            (click)="
              searchConfig?.oneWay === false &&
              trainsList?.returnTrains != null &&
              trainsList?.returnTrains.length > 0
                ? changeTrainsReturn(false)
                : null
            "
          >
            {{ "train.search-result.outward-train" | translate }}
          </div>
          <div
            id="oneWay"
            class="cursor-pointer"
            [ngClass]="{ active: showingTrainsReturn }"
            (click)="changeTrainsReturn(true)"
            *ngIf="
              searchConfig?.oneWay === false &&
              trainsList?.returnTrains != null &&
              trainsList?.returnTrains.length > 0
            "
          >
            {{ "train.search-result.return-train" | translate }}
          </div>
        </div>
      </div>
      <div class="search-modifier">
        <div class="itinerary semi-bold">
          <p>
            {{ parseTrainCodeToName(searchConfig?.origin).substring(0, 3) }}-{{
              parseTrainCodeToName(searchConfig?.destination).substring(0, 3)
            }}<span *ngIf="searchConfig?.oneWay === false"
              >,&nbsp;{{
                parseTrainCodeToName(searchConfig?.destination).substring(0, 3)
              }}-{{
                parseTrainCodeToName(searchConfig?.origin).substring(0, 3)
              }}
            </span>
          </p>
          <span>{{
            ("0" + searchConfig?.departDate.day).slice(-2) +
              "-" +
              ("0" + searchConfig?.departDate.month).slice(-2) +
              "-" +
              searchConfig?.departDate.year
          }}</span
          ><span
            *ngIf="
              searchConfig?.oneWay === false &&
              trainsList?.returnTrains != null &&
              trainsList?.returnTrains.length > 0
            "
          >
            |
            {{
              ("0" + searchConfig?.returnDate.day).slice(-2) +
                "-" +
                ("0" + searchConfig?.returnDate.month).slice(-2) +
                "-" +
                searchConfig?.returnDate.year
            }}</span
          >
        </div>
        <button
          class="button-primary"
          (click)="changeSearchParams()"
        >
          {{ "train.search-result.modify-search" | translate }}
        </button>
      </div>
    </div>
    <div class="search-result-list border-radius-30 white-container">
      <div class="result-grid">
        <div class="empty"></div>
        <div class="empty"></div>
        <div class="fare" *ngFor="let renfeClass of renfeClasses">
          <h4>{{ renfeClass.title }}</h4>
        </div>
      </div>
      <ng-container *ngIf="notAvailable === false; else unavailable">
        <div *ngFor="let train of filteredTrains; let i = index">
          <div class="result-grid">
            <div class="train-logo">
              <img src="assets/img/trains/renfe.svg" alt="" />
            </div>
            <div class="time-container">
              <p>{{ train.departureTime }}</p>
              <div>
                <span>{{ train.travelDurationString }}</span>
                <img src="assets/img/trains/line.svg" alt="" />
                <span
                  *ngIf="train.trainTypeDescription != null"
                  class="semi-bold"
                  >{{ train.trainTypeDescription }}</span
                >
              </div>
              <p>{{ train.arrivalTime }}</p>
            </div>
            <div
              id="tourist"
              class="fare cursor-pointer border-radius-20 semi-bold"
              [ngClass]="{
                unavailable: train.priceTourist.length === 0,
                selected:
                  (this.selectedClass === 'T' && this.selectedIdx === i) ||
                  isSelected(train, 'T')
              }"
              (click)="
                train.priceTourist.length === 0 ? null : openTrainDialog(i, 'T')
              "
            >
              <span *ngIf="train.priceTourist.length > 0">{{
                "train.prices.from" | translate | titlecase
              }}</span>
              <span *ngIf="train.priceTourist.length > 0"
                >{{ train.priceTourist[0] | price }} €</span
              >
              <span *ngIf="train.priceTourist.length === 0">{{
                "train.prices.noAvailable" | translate | titlecase
              }}</span>
            </div>
            <div
              id="touristPlus"
              class="fare cursor-pointer border-radius-20 semi-bold"
              [ngClass]="{
                unavailable: train.priceTouristPlus.length === 0,
                selected:
                  (this.selectedClass === 'P' && this.selectedIdx === i) ||
                  isSelected(train, 'P')
              }"
              (click)="
                train.priceTouristPlus.length === 0
                  ? null
                  : openTrainDialog(i, 'P')
              "
            >
              <span *ngIf="train.priceTouristPlus.length > 0">{{
                "train.prices.from" | translate | titlecase
              }}</span>
              <span *ngIf="train.priceTouristPlus.length > 0"
                >{{ train.priceTouristPlus[0] | price }} €</span
              >
              <span *ngIf="train.priceTouristPlus.length === 0">{{
                "train.prices.noAvailable" | translate | titlecase
              }}</span>
            </div>
          </div>
          <div
            *ngIf="
              selectedClassFares != null &&
              selectedClassFares.length > 0 &&
              showFare[i]
            "
            class="fare-container result-grid"
          >
            <div *ngFor="let fare of selectedClassFares; let j = index">
              <div
                class="fare cursor-pointer border-radius-20 semi-bold sub-fare"
                [ngClass]="{
                  selectedFare: this.selectedFareIdx === j
                }"
                (click)="selectFare(j, fare)"
              >
                {{ fare.name }} {{ fare.price | price }}€
              </div>
            </div>
          </div>
          <div
            *ngIf="
              selectedClassFares != null &&
              selectedClassFares.length > 0 &&
              showFare[i]
            "
            class="fare-description border-radius-30"
          >
            <h4 class="semi-bold">
              {{ "train.taxes.selected" | translate }}
              <span class="ticket-class">{{ getClassName() }}</span
              >.
            </h4>
            <div style="display: block; margin: auto; z-index: 9999">
              <app-loading *ngIf="loading" style="position: relative; left: calc(50% - 25px);" [userType]="'employee'"></app-loading>
            </div>
            <ul *ngIf="(showingTrainsReturn === false && complements.ComplementosIda) || (showingTrainsReturn !== false && complements.ComplementosVuelta)">
              <p>{{ "train.taxes.extra" | translate }}</p>
              <li *ngFor="let complement of (showingTrainsReturn === false ? complements.ComplementosIda[0].complements : complements.ComplementosVuelta[0].complements)">
                {{ complement.name }}: {{ complement.price }} €
              </li>
            </ul>
            <!-- <ul *ngIf="getRenfePerks().included.length > 0">
              <p>{{ "train.taxes.include" | translate }}</p>
              <li *ngFor="let perk of getRenfePerks().included">
                {{ perk.title | translate }}: {{ perk.description | translate }}
              </li>
            </ul>
            <ul *ngIf="getRenfePerks().notIncluded.length > 0">
              <p>{{ "train.taxes.notInclude" | translate }}</p>
              <li
                class="not-included"
                *ngFor="let perk of getRenfePerks().notIncluded"
              >
                {{ perk.title | translate }}
              </li>
            </ul>
            <ul *ngIf="getRenfePerks().extras.length > 0">
              <p>{{ "train.taxes.extra" | translate }}</p>
              <li *ngFor="let perk of getRenfePerks().extras">
                {{ perk.title | translate }}: {{ perk.description | translate }}
              </li>
            </ul> -->

          </div>
        </div>
      </ng-container>
      <ng-template #unavailable>
        <div class="no-results">
          <h4>{{ "train.trenymas.notAvailable" | translate }}</h4>
        </div>
      </ng-template>
    </div>
    <div class="timer border-radius-20">
      <countdown
        #countdownElem
        [config]="{ leftTime: 300, format: 'mm:ss' }"
        (event)="onTimerFinished($event)"
      ></countdown>
    </div>
    <div class="summary">
      <div class="border-radius-30 white-container">
        <div class="logo">
          <img src="assets/img/trains/renfe.svg" alt="" />
        </div>
        <div class="title">
          <h4>
            {{ "train.search-result.summary.title" | translate }}
          </h4>
        </div>
        <div class="outward">
          <p class="semi-bold">
            {{ "train.search-result.summary.outward" | translate }}:
          </p>
          <p>
            {{ parseTrainCodeToName(searchConfig?.origin) | titlecase }}
            {{ "common.to" | translate }}
            {{ parseTrainCodeToName(searchConfig?.destination) | titlecase }}
          </p>
          <p *ngIf="oldSelection['outward']">
            {{oldSelection["outward"].selectedClassFares[oldSelection["outward"].selectedFareIdx].price}}€
            x{{searchConfig.adults.length + (searchConfig.children ? searchConfig.children.length : 0)}} {{"train.confirm-stepper-container.summary.passengers" | translate}}
          </p>
        </div>
        <div
          class="return"
          *ngIf="
            searchConfig?.oneWay === false &&
            trainsList?.returnTrains != null &&
            trainsList?.returnTrains.length > 0
          "
        >
          <p class="semi-bold">
            {{ "train.search-result.summary.return" | translate }}:
          </p>
          <p>
            {{ parseTrainCodeToName(searchConfig?.destination) | titlecase }}
            {{ "common.to" | translate }}
            {{ parseTrainCodeToName(searchConfig?.origin) | titlecase }}
          </p>
          <p *ngIf="oldSelection['return']">
            {{oldSelection["return"].selectedClassFares[oldSelection["return"].selectedFareIdx].price}}€
            x{{searchConfig.adults.length + (searchConfig.children ? searchConfig.children.length : 0)}} {{"train.confirm-stepper-container.summary.passengers" | translate}}
          </p>
        </div>
      </div>
      <button
        class="button-accept"
        *ngIf="selectedFareIdx != null && selectedFareIdx > -1"
        (click)="onAccept()"
      >
        <span *ngIf="canConfirm() === false">
          {{
            (this.showingTrainsReturn === false
              ? "train.search-result.button.next-train"
              : "train.search-result.button.previous-train"
            ) | translate
          }}
        </span>
        <span *ngIf="canConfirm()">
          {{ "train.search-result.button.continue-booking" | translate }}
        </span>
      </button>
    </div>
  </div>
</div>
