<div class="container-guests">
  <div class="guest" *ngFor="let guest of guests; index as g; trackBy: trackByBookList;">
    <label>{{ "train.user-form.traveler" | translate }} {{ g + 1 }}</label>
    <div class="guest-data">
      <div>
        <label>{{ "common.name" | translate }} *</label>
        <input
          class="form-control"
          type="text"
          matInput
          required
          value="{{guest.name}}"
          (change)="changeGuest($event, g, 'name')"
          [ngStyle]="{border: !guest.name || guest.name === '' ? '1px solid #f46f69' :'1px solid #f6f8f8'}"
        />
      </div>
      <div>
        <label>{{ "authorization.labels.lastName" | translate }} *</label>
        <input
          class="form-control"
          type="text"
          matInput
          required
          value="{{guest.surname}}"
          (change)="changeGuest($event, g, 'surname')"
          [ngStyle]="{border: !guest.surname || guest.surname === '' ? '1px solid #f46f69' :'1px solid #f6f8f8'}"
        />
      </div>
    </div>
  </div>
</div>
<button (click)="onSubmit()">{{ "dashboard.add" | translate }}</button>