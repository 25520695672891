import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TrainStateService {
  trainSelectedState = {};
  state$ = new BehaviorSubject<any>(null);
  dataFromCompany$ = new BehaviorSubject<any>(null);
  sendData = this.dataFromCompany$.asObservable();
  trainOrigin = "";
  trainDestination = "";
  trainDateIn = {};
  trainDateOut = {};
  trainTravelers: any = {};
  trainSources = 2;
  isAdminUserDetails: boolean;
  isUser = true;
  selectedUsers: any;
  constructor() {}

  setTrainSelectedState(data: any) {
    this.trainSelectedState = data;
  }

  setTrainField(type: string, value: any) {
    switch (type) {
      case "origin":
        this.trainOrigin = value;
        break;
      case "destination":
        this.trainDestination = value;
        break;
      case "dateIn":
        this.trainDateIn = value;
        break;
      case "dateOut":
        this.trainDateOut = value;
        break;
      case "travelers":
        this.trainTravelers = value;
        break;
      case "sources":
        this.trainSources = value;
        break;
      case "isUser":
        this.isUser = value;
      case "selectedUsers":
        this.selectedUsers = value;
      break;
      default:
        break;
    }
  }
  getTrainField(type: string) {
    switch (type) {
      case "origin":
        return this.trainOrigin;
      case "destination":
        return this.trainDestination;
      case "dateIn":
        return this.trainDateIn;
      case "dateOut":
        return this.trainDateOut;
      case "travelers":
        return this.trainTravelers;
      case "sources":
        return this.trainSources;
      case "isUser":
        return this.isUser;
      case "selectedUsers":
        return this.selectedUsers;
      default:
        return;
    }
  }
  getTrainSelectedState() {
    return this.trainSelectedState;
  }

  setDataFromCompany(data) {
    this.dataFromCompany$.next(data);
  }

  setAdminState(value: boolean) {
    this.isAdminUserDetails = value;
  }
  getAdminState() {
    return this.isAdminUserDetails;
  }
}
