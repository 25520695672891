import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Agency, Company, Employee, Hub, User } from "@models";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AuthService } from "@services";
import { AppState } from "app/app.reducer";
import { of } from "rxjs";
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import * as fromAuthActions from "../actions/auth.actions";
import { ResetFiltersAction } from "../actions/search.actions";
import { AuthState } from "../reducers/auth.reducer";

@Injectable()
export class AuthEffects {
  @Effect()
  loginUser$ = this.actions$.pipe(
    ofType(fromAuthActions.LOGIN_USER),
    switchMap((action: fromAuthActions.LoginUserAction) => {
      return this.authService.loginUser(action.email, action.password).pipe(
        map((res) => new fromAuthActions.LoginSuccessAction(res)),
        catchError((err) =>
          of(new fromAuthActions.LoginFailureAction(err.error))
        )
      );
    })
  );

  @Effect()
  loginEmployee$ = this.actions$.pipe(
    ofType(fromAuthActions.LOGIN_EMPLOYEE),
    switchMap((action: fromAuthActions.LoginEmployeeAction) => {
      return this.authService.loginEmployee(action.email, action.password).pipe(
        map((res) => new fromAuthActions.LoginSuccessAction(res)),
        catchError((err) =>
          of(new fromAuthActions.LoginFailureAction(err.error))
        )
      );
    })
  );

  @Effect()
  loginCompany$ = this.actions$.pipe(
    ofType(fromAuthActions.LOGIN_COMPANY),
    switchMap((action: fromAuthActions.LoginCompanyAction) => {
      return this.authService.loginCompany(action.email, action.password).pipe(
        map((res) => new fromAuthActions.LoginSuccessAction(res)),
        catchError((err) =>
          of(new fromAuthActions.LoginFailureAction(err.error))
        )
      );
    })
  );

  @Effect()
  populate$ = this.actions$.pipe(
    ofType(fromAuthActions.POPULATE),
    switchMap(() => {
      return this.authService.getProfile().pipe(
        map((res: User | Employee | Company | Agency | Hub) => {
          return new fromAuthActions.LoginSuccessAction(res);
        }),
        catchError(() => of(new fromAuthActions.LogoutSuccessAction()))
      );
    })
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType(fromAuthActions.LOGOUT),
    withLatestFrom(this.store.select("auth")),
    switchMap(
      ([action, authState]: [fromAuthActions.LogoutAction, AuthState]) => {
        const userType =
          authState && authState.user ? authState.user.type : "USER";
        localStorage.removeItem("userToBook");
        return of(new fromAuthActions.LogoutSuccessRedirectionAction(userType));
      }
    )
  );

  @Effect({ dispatch: false })
  logoutSuccessRedirection$ = this.actions$.pipe(
    ofType(fromAuthActions.LOGOUT_SUCCESS_REDIRECTION),
    withLatestFrom(this.store.select("auth")),
    tap(
      ([action, authState]: [
        fromAuthActions.LogoutSuccessRedirectionAction,
        AuthState
      ]) => {
        this.store.dispatch(new ResetFiltersAction());
        this.authService.purgeAuth();
        this.authService.userCompanyPlan = null;
        if (action.payload === "EMPLOYEE") {
          this.router.navigate(["/admin-login"]);
        } else {
          this.router.navigate(["/login"]);
        }
      }
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private store: Store<AppState>
  ) {}
}
