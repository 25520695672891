<div class="modal-header">
  <h4 class="modal-title heading-5" id="modal-basic-title">
    {{ "company.bills.upload-bill" | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <img src="assets/img/svg/close.svg" alt="">
  </button>
</div>
<div class="modal-body p-3">
  <form
    [formGroup]="form"
    [ngClass]="{ 'loading-background': loading }"
    (ngSubmit)="onSubmit()"
  >
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>{{ "company.bills.upload-bill" | translate }}*</label>
          <div class="orden-input">
            <input
              formControlName="file"
              type="file"
              id="file"
              (change)="onFileChange($event)"
              accept="application/pdf,.doc,.docx,.xls,.xlsx,.odf,image/*"
            />
            <label class="pl-3" *ngIf="file">{{ file.name }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <label for="reference"
            >{{ "company.bills.filters.reference" | translate }}*</label
          >
          <input
            formControlName="reference"
            type="text"
            id="reference"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <label>{{ "company.bills.filters.date" | translate }}*</label>
          <input
            class="form-control filter-dates"
            readonly
            [placeholder]="'dd/mm/yyyy'"
            [value]="f.date.value !== null ? f.date.value : ''"
            name="date"
            id="date"
            ngbDatepicker
            #dateBill="ngbDatepicker"
            #dateInput
            (dateSelect)="onDateSelection($event)"
            (click)="dateBill.toggle()"
            [ngClass]="!f.date.value ? 'filter-dates-placeholder' : ''"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="button-container">
          <button
            class="custom-btn mb-0"
            ngbAutofocus
            [disabled]="!form.valid || loading"
          >
            {{ "common.send" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
