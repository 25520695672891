import { Pipe, PipeTransform } from "@angular/core";
import { LanguageService } from "@core/services";

@Pipe({
  name: "price",
})
export class PricePipe implements PipeTransform {
  decimalPlaces = 2;

  constructor(private languageService: LanguageService) {}

  transform(value: string): any {
    const price = Number(
      Math.round(parseFloat(value + "e" + this.decimalPlaces)) +
        "e-" +
        this.decimalPlaces
    );
    const locale = this.languageService.getLocale();
    // TODO estudiar como coger es o en
    return price.toLocaleString(locale && locale !== null ? locale : "es-ES", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  }
}
