import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AllowService } from "@core/services/allow.service";
import { SearchConfig } from "@interfaces";
import { Booked, Hotel, User } from "@models";
import { NgbDateParserFormatter, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AuthService,
  BookedService,
  NgxToastrService,
  SearchService,
  TravelUtilsService,
  UserService,
  UtilsService,
} from "@services";
import { DialogAssignProjectComponent } from "@shared/component/dialog-assign-project/dialog-assign-project.component";
import { ViewPdfModalCompanyComponent } from "app/modules/businessEntities/company/booked-homepage/view-pdf-modal-company/view-pdf-modal-company.component";
import { AppState } from "app/modules/components/search/search.reducer";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { SearchFormComponent } from "../search-form/search-form.component";
import { SummaryBookedComponent } from "./summary-booked/summary-booked.component";
import Swal from "sweetalert2";
import { LetItGoType } from "@core/models/letitgo-type";
import { AccommodationGuestsComponent } from "../accommodation-guests/accommodation-guests.component";

@Component({
  selector: "app-search-see-booked",
  templateUrl: "./search-see-booked.component.html",
  styleUrls: ["./search-see-booked.component.scss"],
})
export class SearchSeeBookedComponent implements OnInit, OnDestroy {
  public lat: number = 51.678418;
  public lng: number = 7.809007;
  actualRoom: number = null;
  hotel: Hotel;
  code: number;
  uuid: string;
  canRequest: boolean;
  comments = "";
  travelData: any = {};
  searchConfig: SearchConfig;
  totalArray: any[] = [];
  countStars: any[] = [];
  subscription = new Subscription();
  isUser: boolean;
  uuidUser: string;
  isAdmin = false;
  isEmployee: boolean = false;
  private subcriptions: Subscription[] = [];
  canUseTRM: boolean;
  userToBook: string;
  showPolicies: boolean;
  actualGroupRoom: number;
  groupFilter: any;
  totalAdults = 0;
  hotelsMapCopy: Hotel[];
  hotelsListCopy: Hotel[];
  selectedOccupancy: {
    adults: number;
    rooms: number;
    paxes: any[];
  };
  selectedRoom: any;
  selectedRooms: any[] = [];
  selectedRoomIndex: number;
  selectedRoomsCopy: any[] = [];
  totalPrice = 0;
  canOnlyRequestRooms = false;
  mapHasBeenDragged: boolean = false;
  firstSearch: boolean = false;
  @ViewChild("bottom") bottom!: ElementRef;
  orderSummary: any[] = [];
  areAllFormsValid: boolean;
  isInvalid: boolean = false;
  @ViewChild("scrollAfterChangeRoom") scrollAfterChangeRoom: ElementRef;
  guests: any[] = [];
  user: User;
  filters: any;
  facilities: any;
  bookingInfo: any;
  selectedUsers: any;
  isDescriptionExpanded = false;
  isDetailsExpanded = false;
  roomFilters = [
    { code: 'breakfast', name: 'Breakfast Included' },
    { code: 'cancellation', name: 'Free Cancellation' }
  ];
  selectedFilters: Set<string> = new Set<string>();
  filteredRooms: any[] = [];
  selectedBedTypes: { [key: string]: string } = {};
  filteredRates: { [key: string]: any[] } = {};
  bedTypesForRooms: { [roomId: string]: string[] } = {};
  roomRatesVisibility: { [key: string]: boolean } = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private searchService: SearchService,
    private ngxToastrService: NgxToastrService,
    private store: Store<AppState>,
    private bookedService: BookedService,
    public translate: TranslateService,
    private ngbDateParser: NgbDateParserFormatter,
    private router: Router,
    private travelUtilsService: TravelUtilsService,
    private userService: UserService,
    public utilsService: UtilsService,
    public allowService: AllowService,
    private authService: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.subcriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() !== "USER" && (s.user.type?.toUpperCase() === "COMPANY" || s.user.type?.toUpperCase() === "EMPLOYEE" ||
            s.user.type?.toUpperCase() === "AGENCY" || s.user.type?.toUpperCase() === "HUB")) {
          this.isAdmin = true;
          if (s.user.type?.toUpperCase() === "EMPLOYEE") {
            this.isEmployee = true;
          }
          this.userToBook = localStorage.getItem("userToBook")
            ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
            : null;
          this.userService.getUser(this.userToBook).subscribe(u => {
            this.user = u;
          })
        } else {
          this.user = s.user  
        }
        this.canUseTRM = s.user.type
          ? s.user.plan && s.user.plan.permissions.canUseTRM
          : s.user.user.plan && s.user.user.plan.permissions.canUseTRM;
      })
    );

    this.searchConfig = this.searchService.searchConfig;
    this.hotelsMapCopy =
      this.router.getCurrentNavigation().extras?.state?.hotelsMapCopy;
    this.hotelsListCopy =
      this.router.getCurrentNavigation().extras?.state?.hotelsListCopy
    this.filters = this.router.getCurrentNavigation().extras?.state?.filters
    this.facilities = this.router.getCurrentNavigation().extras?.state?.facilities
    this.selectedUsers = this.router.getCurrentNavigation().extras?.state?.selectedUsers

    this.store
      .select("auth")
      .pipe(take(1))
      .subscribe((auth) => {
        if (auth.isAuthenticated) {
          this.uuidUser = this.searchConfig.userUuid
            ? this.searchConfig.userUuid
            : auth.user.uuid;
        }
        const st: any = auth;
        this.isUser = st.user.type?.toUpperCase() === "USER" ? true : false;
      });
      this.utilsService.socket.on("create-booking", (result) => {
        this.bookingInfo = this.utilsService.getBookingInfo();
        this.selectedRooms = this.utilsService.getSelectedRooms();
        if(!this.selectedUsers){
          this.selectedUsers = this.utilsService.getSelectedUsers();
        }
        if(!this.hotel){
          this.hotel = this.utilsService.getHotelInfo();
        }

        if (this.utilsService.correlationId.includes(result.correlationId)) {
          this.utilsService.correlationId.splice(this.utilsService.correlationId.indexOf(result.correlationId), 1)
          this.utilsService.loadingHotels = false;
          const user = this.selectedUsers.find(u => u.correlationId === result.correlationId);
          const userUuid = user ? user.uuid : this.uuidUser;
          if((result.data.type && (result.data.type?.toLowerCase().includes("error") || result.data.type?.toLowerCase().includes("exception"))) || (result.data.bookingStatus && (result.data.bookingStatus?.toLowerCase().includes('unknown') || result.data.bookingStatus?.toLowerCase().includes('error')))) {
            this.translate
            .get([
              "common.congrat",
              "search.search-booked.success-room-booked",
            ])
            .subscribe((response) => {
              Swal.fire({
                position: "center",
                imageUrl:
                  "../../../../../assets/img/svg/check-circle.svg",
                title: response["common.congrat"],
                text: response[
                  "search.search-booked.success-room-booked"
                ],
                showConfirmButton: false,
                timer: 300000000000,
              }).then(() => {
                const checkInAux = moment(
                  moment(
                    this.ngbDateParser.format(this.searchConfig.checkIn),
                    "DD/MM/YYYY"
                  ).toLocaleString()
                ).format("YYYY-MM-DD");
                const checkOutAux = moment(
                  moment(
                    this.ngbDateParser.format(this.searchConfig.checkOut),
                    "DD/MM/YYYY"
                  ).toLocaleString()
                ).format("YYYY-MM-DD");
                const room = this.bookingInfo.rooms.find((r) => r.correlationId === result.correlationId)
                const params = {
                  rooms: [room],
                  destinationName: this.hotel.address,
                  checkIn: checkInAux,
                  checkOut: checkOutAux,
                  userUuid: userUuid,
                  comments: "",
                  hotelName: this.hotel.name,
                  guests: this.guests,
                };
                if (this.bookingInfo.customCode !== "") {
                  params["customCode"] = this.bookingInfo.customCode;
                }
                if (this.bookingInfo.costCenter !== "") {
                  params["costCenter"] = this.bookingInfo.costCenter;
                }
                params['hotel'] = this.hotel;
                const roomIndex = this.selectedRooms.findIndex(r => r.correlationId === room.correlationId);
                this.createBookingFailed(result, room, roomIndex, params, this.bookingInfo);
              });
            });
          } else {
            this.translate
            .get([
              "common.congrat",
              "search.search-booked.success-room-booked",
            ])
            .subscribe((response) => {
              Swal.fire({
                position: "center",
                imageUrl:
                  "../../../../../assets/img/svg/check-circle.svg",
                title: response["common.congrat"],
                text: response[
                  "search.search-booked.success-room-booked"
                ],
                showConfirmButton: false,
                timer: 300000000000,
              }).then(() => {
                    //this.onGetPDF(result.data);
                    const navigationExtras: NavigationExtras = {
                      state: {
                        module: "hotels",
                      },
                    };
                    this.cleanSearchData();
                    if (this.isUser) {
                      this.router.navigate(
                        [`/my-bookeds`],
                        navigationExtras
                      );
                    } else {
                      this.router.navigate(
                        [`/user/booked/${this.uuidUser}`],
                        {
                          state: { module: "hotels" },
                          queryParams: { specialAction: true },
                        }
                      );
                    }

                // this.handleTravelFlow(result.data.uuid).subscribe(
                //   (res) => {
                //     if (res != null) {
                //       if (res.isCreate) {
                //         console.log("aki 1")
                //         this.travelUtilsService.createTravel(
                //           res.travelData,
                //           res.addBody,
                //           res.travelData.type
                //         );
                //       } else {
                //         console.log("aki 2")
                //         this.travelUtilsService.addItemToTravel(
                //           res.addBody,
                //           res.travelData.uuid
                //         );
                //       }
                //     }
                //     this.onGetPDF(result.data);
                //     const navigationExtras: NavigationExtras = {
                //       state: {
                //         module: "hotels",
                //       },
                //     };
                //     this.cleanSearchData();
                //     if (this.isUser) {
                //       this.router.navigate(
                //         [`/my-bookeds`],
                //         navigationExtras
                //       );
                //     } else {
                //       this.router.navigate(
                //         [`/user/booked/${this.uuidUser}`],
                //         {
                //           state: { module: "hotels" },
                //           queryParams: { specialAction: true },
                //         }
                //       );
                //     }
                //   },
                //   (err) => {
                //     this.cleanSearchData();
                //   }
                // );

              });
            });
          }
        }
      })
  }

  ngOnInit() {
    this.travelUtilsService.removeTravelLocalStorage();
    this.code = this.activatedRoute.snapshot.params.code;
    this.uuid = this.activatedRoute.snapshot.queryParams.hotelUuid;
    this.canRequest =
      this.activatedRoute.snapshot.queryParams.canRequest === "true"
        ? true
        : false;
    this.travelData = this.travelUtilsService.getTravelLocalStorage();
    if (!this.searchConfig.checkIn || !this.searchConfig.checkOut) {
      this.router.navigate(["/search"]);
      return false;
    }
    this.totalAdults = 0;
    this.searchConfig.occupancies.forEach((o) => {
      this.totalAdults += o.adults;
    });
    this.selectedOccupancy = this.searchConfig.occupancies[0];

    this.userService
      .getGroupUser(this.uuidUser)
      .pipe(take(1))
      .subscribe((value) => {
        if (value) {
          this.canOnlyRequestRooms = value.filters.onlyAuthorization;
          this.groupFilter = value.filters;
        }

        this.hotel = this.hotelsListCopy.find((h) => h.code === this.code);
        this.route.queryParams.subscribe((params) => {
          // Extrae el valor de hotelUuid de los parámetros
          const hotelUuid = params["hotelUuid"];
          this.hotel.uuid = hotelUuid;
        });


        try {
          this.countStars = new Array(Math.floor(Number(this.hotel.stars)));
        } catch (e) {
          this.countStars = new Array();
        }
        this.utilsService.loadingHotels = false;
        if (this.hotel && this.hotel.rooms) {
          this.initializeFilteredRates();
          this.filteredRooms = this.filterByOccupancy(this.hotel.rooms);
        }
      });
      this.filteredRooms.forEach(room => {
        this.roomRatesVisibility[room.code] = false;
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleTravelFlow(bookingUuid: string) {
    const checkInAux = moment(
      this.ngbDateParser.format(this.searchConfig.checkIn),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const checkOutAux = moment(
      this.ngbDateParser.format(this.searchConfig.checkOut),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const bookingData = {
      initDate: checkInAux,
      endDate: checkOutAux,
      bookingUuid: bookingUuid,
      type: "booking",
    };

    bookingData["userUuid"] = this.uuidUser;
    const dialogAssignProject = this.dialog.open(DialogAssignProjectComponent, {
      data: { bookingData, isUser: this.isUser, isNewBooking: true },
      panelClass: "new-dialog",
    });
    return dialogAssignProject.afterClosed();
  }

  onGetPDF(booked: Booked) {
    if (booked.bonoFileUrl) {
      this.bookedService
        .getBookedsPdf(booked.bonoFileUrl)
        .pipe(take(1))
        .subscribe(
          (res) => {
            const modalRef = this.modalService.open(
              ViewPdfModalCompanyComponent,
              { size: "lg", centered: true }
            );
            modalRef.componentInstance.blobUrl = URL.createObjectURL(res);
            modalRef.componentInstance.fileName = booked.apiReference;
          },
          () =>
            this.translate
              .get(["common.error-pdf"])
              .pipe(take(1))
              .subscribe((value) =>
                this.ngxToastrService.typeInfo(null, value["common.error-pdf"])
              )
        );
    } else {
      this.bookedService
        .getCustomBookingPdf(`/media/${booked["voucherFileUuid"]}/custom`)
        .pipe(take(1))
        .subscribe(
          (res) => {
            const modalRef = this.modalService.open(
              ViewPdfModalCompanyComponent,
              { size: "lg", centered: true }
            );
            modalRef.componentInstance.blobUrl = URL.createObjectURL(res);
            modalRef.componentInstance.fileName = booked.apiReference;
          },
          () =>
            this.translate
              .get(["common.error-pdf"])
              .pipe(take(1))
              .subscribe((value) =>
                this.ngxToastrService.typeInfo(null, value["common.error-pdf"])
              )
        );
    }
  }

  isSameOrAfterToday(date) {
    return date ? moment(date).isSameOrAfter(moment()) : null;
  }

  hoverCancel(i: number, j: number) {
    this.actualRoom = i;
    this.actualGroupRoom = j;
    this.showPolicies = !this.showPolicies;
  }

  openSearchModal() {
    const modalRef = this.dialog.open(SearchFormComponent, {
      panelClass: "hotel-form-modal",
    });
    modalRef.componentInstance.openModal = true;
    modalRef.componentInstance.isOnDetails = true;
    modalRef.componentInstance.userToBook = this.userToBook;
    modalRef.componentInstance.searchConfig = this.searchConfig;
    modalRef.componentInstance.hotelsMapCopy = this.hotelsMapCopy;
    modalRef.componentInstance.hotelsListCopy = this.hotelsListCopy;
    modalRef.componentInstance.emitData.subscribe((data) => {
      this.searchConfig = data;
      const url = this.userToBook ? `/search/${this.userToBook}` : "/search";
      this.router.navigate([url], {
        state: {
          searchConfig: this.searchConfig,
          hotelsMapCopy: this.hotelsMapCopy,
          hotelsListCopy: this.hotelsListCopy,
          newSearch: true,
        },
      });

      modalRef.close();
    });
  }

  goBack() {
    this.router.navigate(
      [
        `/search${
          (this.isAdmin && this.canUseTRM) || this.isEmployee
            ? "/" + this.userToBook
            : ""
        }`,
      ],
      {
        state: {
          searchConfig: this.searchConfig,
          hotelsMapCopy: this.hotelsMapCopy,
          hotelsListCopy: this.hotelsListCopy,
          filters: this.filters,
          facilities: this.facilities,
          selectedUsers: this.selectedUsers
        }
      }
    );
  }

  selectRoom(room, rate) {
    if (this.selectedRoom && room !== this.selectedRoom && rate !== this.selectedRoom.rates[0]) {
      this.selectedRooms[this.getCurrentOccupancyIndex()] = null;
    }
    if (room !== this.selectedRoom || rate !== this.selectedRoom.rates[0]) {
      this.selectedRoom = {...room};
      this.selectedRoom.paxes = new Array(this.searchConfig.occupancies[this.getCurrentOccupancyIndex()].adults).fill(
        {
          roomId: this.getCurrentOccupancyIndex() + 1,
          type: "AD"
        }
      )
      this.selectedRoom.rates = [{...rate}];
      this.resetPriceAndUpdate();
      this.totalPrice += Number.parseFloat(
        rate.totalWithPercentage
      );
      this.selectedRooms[this.getCurrentOccupancyIndex()] = this.selectedRoom;
    }
  }

  resetPriceAndUpdate() {
    this.totalPrice = 0;
    if (this.selectedRooms[0] != null) {
      this.selectedRooms.forEach((selectedRoom) => {
        if (selectedRoom != null) {
          this.totalPrice += Number.parseFloat(
            selectedRoom.rates[0].totalWithPercentage
          );
        }
      });
    }
    if (this.selectedRooms[this.getCurrentOccupancyIndex()] != null) {
      this.totalPrice -= Number.parseFloat(
        this.selectedRooms[this.getCurrentOccupancyIndex()].rates[0]
          .totalWithPercentage
      );
    }
  }

  changeNextRoom(isNext: boolean) {
    this.scrollToComponent();
    const idx = this.getCurrentOccupancyIndex();
    let newIdx = 0;
    if (isNext) {
      newIdx = idx + 1;
      this.selectedOccupancy = this.searchConfig.occupancies[newIdx];

      // Find if room is already in orderSummary list and update it
      const isInSummary = this.orderSummary.findIndex(
        (room) => room.id === newIdx
      );
      if (isInSummary !== -1) {
        this.orderSummary.push({ room: this.selectedRooms[idx], id: idx });
        this.orderSummary.splice(isInSummary, 1);
      } else {
        this.orderSummary.push({ room: this.selectedRooms[idx], id: idx });
      }
    } else {
      newIdx = idx - 1;
      this.selectedOccupancy = this.searchConfig.occupancies[newIdx];

      // Add last selectedRoom to orderSummary list
      this.orderSummary.push({ room: this.selectedRooms[idx], id: idx });
      // Find if room is already in orderSummary list and update it
      const isInSummary = this.orderSummary.findIndex(
        (room) => room.id === newIdx
      );
      if (isInSummary !== -1) {
        this.orderSummary.splice(isInSummary, 1);
      } else {
        this.orderSummary.push({ room: this.selectedRooms[idx], id: idx });
      }
    }
    if (this.selectedRooms[newIdx] != null) {
      this.selectedRoom = this.selectedRooms[newIdx];
    } else {
      this.selectedRoom = null;
    }
    this.filteredRooms = this.filterByOccupancy(this.hotel.rooms);
  }

  getCurrentOccupancyIndex() {
    return this.searchConfig.occupancies.findIndex(
      (occupancy) => occupancy === this.selectedOccupancy
    );
  }

  isFirstMiddleOrLast() {
    const index = this.searchConfig.occupancies.findIndex(
      (occupancy) => occupancy === this.selectedOccupancy
    );
    if (index === this.searchConfig.occupancies.length - 1) {
      return 1;
    } else if (index > 0 && index < this.searchConfig.occupancies.length - 1) {
      return 0;
    } else {
      return -1;
    }
  }

  isChecked(room, rate) {
    return this.selectedRoom != null
      && this.selectedRoom.code == room.code
      && this.selectedRoom.rates[0].rateKey == rate.rateKey
      && this.selectedRoom.rates[0].boardCode == rate.boardCode
      && this.selectedRoom.rates[0].bed == rate.bed
      && this.selectedRoom.rates[0].cancellationPolicies[0].text == rate.cancellationPolicies[0].text;
  }

  checkIfAllotment(rate) {
    if (rate.allotment < 1) {
      return false
    } else {
      const equals = this.selectedRooms.filter((room) => room.rates[0].rateKey === rate.rateKey)
      if(equals && rate.allotment <= equals.length && !this.selectedRoom) {
        return false
      }
    }
    return true
  }

  filterByOccupancy(rooms: any[]) {
    let res = [];
    rooms.forEach((room: any) => {
      const ratesFiltered = room.rates.filter((rate: any) => {
        const matchesOccupancy = rate.adults == this.selectedOccupancy.adults && this.checkIfAllotment(rate);
        const matchesBreakfast = !this.selectedFilters.has('breakfast') || ['HB', 'AI', 'BB'].includes(rate.boardCode);
        const matchesCancellation = !this.selectedFilters.has('cancellation') || this.hasFreeCancellation(rate);
        
        return matchesOccupancy && matchesBreakfast && matchesCancellation;
      });

      if (ratesFiltered.length > 0) {
        res.push({ ...room, rates: ratesFiltered });
      }
    });
    return res;
  }

  getOffersPercentage(roomRate) {
    const offer = roomRate.offers[0].amount;
    const price = roomRate.totalWithPercentage;
    let percentage = Math.round((offer * 100) / price);
    return percentage.toString();
  }

  doBooking() {
    if (!this.guests || this.guests.length !== this.totalAdults) {
      return this.translate
        .get(["hotel.should-guests-data"])
        .subscribe((result) => {
          this.ngxToastrService.typeInfo(
            "",
            result["hotel.should-guests-data"]
          );
        });
    }
      this.isInvalid = false;
      this.selectedRooms[this.getCurrentOccupancyIndex()] = this.selectedRoom;

      this.selectedRoomsCopy = this.selectedRooms;
      
      const modalRef = this.modalService.open(SummaryBookedComponent, {
        size: "lg",
        centered: true,
      });
      this.translate
        .get(["hotel.check-booking-details", "hotel.change-during-process"])
        .subscribe((result) => {
          this.ngxToastrService.typeInfo(
            result["hotel.check-booking-details"],
            result["hotel.change-during-process"]
          );
        });
      modalRef.componentInstance.hotel = this.hotel;
      modalRef.componentInstance.totalAdults = this.totalAdults;
      modalRef.componentInstance.searchConfig = this.searchConfig;
      modalRef.componentInstance.selectedRooms = [...this.selectedRooms];
      modalRef.componentInstance.code = this.code;
      modalRef.componentInstance.isEmployee = this.isEmployee;
      modalRef.componentInstance.isAdmin = this.isAdmin;
      modalRef.componentInstance.guests = [...this.guests];
      modalRef.componentInstance.uuidUser = this.uuidUser;
      modalRef.componentInstance.selectedUsers = this.selectedUsers;
      modalRef.componentInstance.sendInfo.subscribe((bookingInfo) => {
        modalRef.componentInstance.loading = true;
        this.utilsService.loadingHotels = true;
        const checkInAux = moment(
          moment(
            this.ngbDateParser.format(this.searchConfig.checkIn),
            "DD/MM/YYYY"
          ).toLocaleString()
        ).format("YYYY-MM-DD");
        const checkOutAux = moment(
          moment(
            this.ngbDateParser.format(this.searchConfig.checkOut),
            "DD/MM/YYYY"
          ).toLocaleString()
        ).format("YYYY-MM-DD");

        bookingInfo.rooms.forEach((room, index) => {
          const params = {
            rooms: [room],
            destinationName: this.hotel.address,
            checkIn: checkInAux,
            checkOut: checkOutAux,
            userUuid: this.selectedUsers[index]?.uuid ?? this.uuidUser,
            comments: bookingInfo.adminHotelComment ?? "",
            hotelName: this.hotel.name,
            guests: this.guests.splice(0, room.paxes.length),
          };
          if (bookingInfo.customCode !== "") {
            params["customCode"] = bookingInfo.customCode;
          }
          if (bookingInfo.costCenter !== "") {
            params["costCenter"] = bookingInfo.costCenter;
          }
          params['hotel'] = this.hotel;
          params['hotel'].rooms = [room];
          if(this.isEmployee || this.isAdmin){
            this.canRequest = true;
          }
          if ((this.getUserBookingOption(room.rates[0]) === 1 && this.canRequest) || this.isEmployee || this.isAdmin) {
            this.createBooking(params, modalRef, room, bookingInfo.rooms.indexOf(room), bookingInfo);
          } else {
            this.createAuthorization(bookingInfo, params, modalRef);
          }
        });
      });
    }


  getUserBookingOption(rate) {
    let { priceMin, priceMax } = this.groupFilter;
    let checkInAux = moment(
      this.ngbDateParser.format(this.searchConfig.checkIn),
      "DD/MM/YYYY"
    ).toString();
    let checkOutAux = moment(
      this.ngbDateParser.format(this.searchConfig.checkOut),
      "DD/MM/YYYY"
    ).toString();

    let checkIn = new Date(checkInAux);
    let checkOut = new Date(checkOutAux);

    let milisecondDiference = Math.abs(checkOut.getTime() - checkIn.getTime());
    let totalNights = Math.floor(milisecondDiference / (1000 * 60 * 60 * 24));

    let totalPriceMax = totalNights * priceMax;

    if (
      rate.totalWithPercentage > priceMin &&
      rate.totalWithPercentage < totalPriceMax
    ) {
      return 1;
    } else {
      return 2;
    }
  }
  cleanSearchData() {
    this.searchService.searchConfig = {
      checkIn: null,
      checkOut: null,
      latitude: "",
      locationText: "",
      longitude: "",
      minElements: "0",
      uuid: "",
      occupancies: [
        {
          rooms: 1,
          adults: 1,
          children: 0,
          paxes: [],
        },
      ],
    };
    this.searchService.hotelsList = [];
    this.searchService.hotelsMap = [];
  }

  scrollToComponent() {
    this.scrollAfterChangeRoom.nativeElement.scrollIntoView({
      behavior: "smooth",
    });
  }

  scrollToBottom() {
    if (this.bottom) {
      this.bottom.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }

  addTravellersNames() {
    const modalRef = this.modalService.open(AccommodationGuestsComponent, {
      size: "lg",
      centered: true,
      backdrop : 'static',
      keyboard : false
    });
    const guestsAux = this.selectedUsers.map(user => ({
      name: user.name,
      surname: user.lastname
    }));

    modalRef.componentInstance.guests = guestsAux;
    modalRef.componentInstance.sendInfo.subscribe((result: any[]) => {
      if (!result.some((g) => !g.name || g.name === '' || !g.surname || g.surname === '')) {
        this.guests = result;
        modalRef.close();
      }
    });
  }

  createBooking(params, modalRef, roomBooked, roomBookedIdx, bookingInfo) {
    const correlation = crypto.randomUUID();
    const userIndex = this.selectedUsers.findIndex(user => user.uuid === params.userUuid);
    if (userIndex !== -1) {
      this.selectedUsers[userIndex].correlationId = correlation;
    }

    modalRef.close();
    this.utilsService.correlationId.push(correlation);
    params['correlationId'] = correlation;
    bookingInfo.rooms[roomBookedIdx].correlationId = correlation;
    this.bookingInfo = bookingInfo;

    this.utilsService.setSelectedUsers(this.selectedUsers);
    this.utilsService.setBookingInfo(bookingInfo);
    this.utilsService.setHotelInfo(this.hotel);
    this.utilsService.setSelectedRooms(this.selectedRooms);
    this.bookedService.createBooked(params).subscribe(
      (created: Booked) => {
      },
      (err) => {
        this.createBookingFailed(err, roomBooked, roomBookedIdx, params, bookingInfo);
      }
    );
  }

  createAuthorization(bookingInfo, params, modalRef) {
    let requestParams;
    requestParams = {
      type: "booking",
      comments: bookingInfo.requestComment ?? "",
      bookingData: params,
    };
    if (!this.isUser) {
      requestParams["userUuid"] = this.uuidUser;
    }
    requestParams["price"] =
      requestParams["bookingData"].rooms[0].net;
    if (bookingInfo.customCode !== "") {
      requestParams["customCode"] = bookingInfo.customCode;
    }
    if (bookingInfo.costCenter !== "") {
      requestParams["costCenter"] = bookingInfo.costCenter;
    }
    const correlation = crypto.randomUUID();
    this.utilsService.correlationId.push(correlation);
    requestParams.bookingData['correlationId'] = correlation;
    this.bookedService.requestAuthorization(requestParams).subscribe(
      () => {
        modalRef.close();
        this.utilsService.loadingHotels = false;
        this.translate
          .get([
            "common.congrat",
            "search.search-booked.success-room-request",
          ])
          .subscribe((response) => {
            Swal.fire({
              position: "center",
              imageUrl:
                "../../../../../assets/img/svg/check-circle.svg",
              title: response["common.congrat"],
              text: response[
                "search.search-booked.success-room-request"
              ],
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              const navigationExtras: NavigationExtras = {
                state: {
                  module: "hotels",
                },
              };
              if (this.isUser) {
                this.router.navigate(
                  [`/my-bookeds`],
                  navigationExtras
                );
              } else {
                this.router.navigate(
                  [`/user/booked/${this.uuidUser}`],
                  navigationExtras
                );
              }
            });
          });
        this.authService.totalAuthorizations++;
      },
      (err) => {
        modalRef.close();
        Swal.close();
        this.utilsService.loadingHotels = false;
        const body = {
          hotel: {
            name: this.hotel.name,
            totalRooms: this.searchConfig.occupancies.length,
            room: {
              adults: this.totalAdults,
              price:
                params.rooms[0].rates[0].totalWithPercentage ||
                `${params.rooms[0].rates[0].net}`,
              roomNumber: this.getCurrentOccupancyIndex(),
              roomName: params.rooms[0].name,
            },
            address: this.hotel.address,
          },
          totalPrice: this.totalPrice.toLocaleString("es-ES", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
          cancellationPolicies: params.rooms[0].rates[0].cancellationPolicies[0].from,
          checkIn: moment(new Date(params.checkIn)).format(
            "DD/MM/YYYY"
          ),
          checkOut: moment(new Date(params.checkOut)).format(
            "DD/MM/YYYY"
          ),
        };
        this.travelUtilsService.removeTravelLocalStorage();
        if (bookingInfo.customCode !== "") {
          body["customCode"] = bookingInfo.customCode;
        }
        if (bookingInfo.costCenter !== "") {
          body["costCenter"] = bookingInfo.costCenter;
        }
  
        if (bookingInfo.adminHotelComment !== "") {
          body["comments"] = bookingInfo.adminHotelComment;
        }
  
        if (
          this.selectedRooms[this.getCurrentOccupancyIndex()].rates[0].boardCode ==
          "BB"
        ) {
          body["breakfast"] = true;
        } else {
          body["breakfast"] = false;
        }
  
        if (
          this.selectedRooms[this.getCurrentOccupancyIndex()]?.rates[0]?.boardName
        ) {
          body["boardName"] =
            this.selectedRooms[this.getCurrentOccupancyIndex()].rates[0].boardName;
        }
  
        if (
          this.selectedRooms[this.getCurrentOccupancyIndex()].rates[0]
            .cancellationPolicies
        ) {
          body["cancellation"] = true;
          body["cancellationDate"] =
            this.selectedRooms[
              this.getCurrentOccupancyIndex()
            ].rates[0].cancellationPolicies[0].from;
        } else {
          body["cancellation"] = false;
        }
        this.utilsService.sendEmailOperations(
          LetItGoType.hotel,
          body,
          this.uuidUser
        );
        const navigationExtras: NavigationExtras = {
          state: {
            module: "hotels",
          },
        };
        if (this.isUser) {
          modalRef.close();
          this.router.navigate([`/my-bookeds`], navigationExtras);
        } else {
          modalRef.close();
          this.router.navigate(
            [`/user/booked/${this.uuidUser}`],
            navigationExtras
          );
        }
      }
    );
  }

  createBookingFailed(err, roomBooked, roomBookedIdx, params, bookingInfo) {
    this.cleanSearchData();
    this.travelUtilsService.removeTravelLocalStorage();
    Swal.close();
    this.utilsService.loadingHotels = false;

    // Se comprueba si es letitgo o si es fallo de tarjeta
    if (
      err.status === 422 &&
      err.error &&
      err.error.message ===
        "No tienes permisos para realizar la reserva sin tarjeta de crédito"
    ) {
      this.translate
        .get(["common.card-permission-error"])
        .pipe(take(1))
        .subscribe((value) =>
          this.ngxToastrService.typeInfo(
            null,
            value["common.card-permission-error"]
          )
        );
    } else if (err && err.status === 422) {
      this.translate
        .get(["agency.search.search-booked.error-room-booked"])
        .pipe(take(1))
        .subscribe((value) =>
          this.ngxToastrService.typeInfo(
            null,
            value["agency.search.search-booked.error-room-booked"]
          )
        );
    } else {
      // Se elimina el mensaje de error para poner el swal con el envío del email a operaciones
      /*                   this.translate
                      .get("search.search-booked.error-room-booked")
                      .subscribe((resp) => {
                        this.ngxToastrService.typeInfo(resp, err.error.message);
                      }); */
      const body = {
        hotel: {
          name: this.hotel.name,
          totalRooms: this.searchConfig.occupancies.length,
          room: {
            adults: this.totalAdults,
            price:
              roomBooked.rates[0].totalWithPercentage ||
              `${roomBooked.rates[0].net}`,
            roomNumber: roomBookedIdx,
            roomName: roomBooked.name,
          },
          address: this.hotel.address,
        },
        totalPrice: this.totalPrice.toLocaleString("es-ES", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
        cancellationPolicies: roomBooked.rates[0].cancellationPolicies[0].from,
        checkIn: moment(new Date(params.checkIn)).format(
          "DD/MM/YYYY"
        ),
        checkOut: moment(new Date(params.checkOut)).format(
          "DD/MM/YYYY"
        ),
      };
      this.travelUtilsService.removeTravelLocalStorage();
      if (bookingInfo.customCode !== "") {
        body["customCode"] = bookingInfo.customCode;
      }
      if (bookingInfo.costCenter !== "") {
        body["costCenter"] = bookingInfo.costCenter;
      }

      if (bookingInfo.adminHotelComment !== "") {
        body["comments"] = bookingInfo.adminHotelComment;
      }

      if (
        this.selectedRooms[roomBookedIdx].rates[0].boardCode ==
        "BB"
      ) {
        body["breakfast"] = true;
      } else {
        body["breakfast"] = false;
      }

      if (
        this.selectedRooms[roomBookedIdx]?.rates[0]?.boardName
      ) {
        body["boardName"] =
          this.selectedRooms[roomBookedIdx].rates[0].boardName;
      }

      if (
        this.selectedRooms[roomBookedIdx].rates[0]
          .cancellationPolicies
      ) {
        body["cancellation"] = true;
        body["cancellationDate"] =
          this.selectedRooms[
            roomBookedIdx
          ].rates[0].cancellationPolicies[0].from;
      } else {
        body["cancellation"] = false;
      }
      this.utilsService.sendEmailOperations(
        LetItGoType.hotel,
        body,
        params.userUuid
      );
      this.translate
        .get(["common.congrat", "common.operations"])
        .subscribe((resultOperations) => {
          Swal.fire({
            position: "center",
            imageUrl:
              "../../../../../assets/img/svg/check-circle.svg",
            title: resultOperations["common.congrat"],
            text: resultOperations["common.operations"],
            showConfirmButton: false,
            timer: this.utilsService.letItGoTimer,
            timerProgressBar: true,
          }).then(() => {
            const navigationExtras: NavigationExtras = {
              state: {
                module: "hotels",
              },
            };
            if (this.isUser) {
              this.router.navigate(
                [`/my-bookeds`],
                navigationExtras
              );
            } else {
              this.router.navigate(
                [`/user/booked/${this.uuidUser}`],
                {
                  state: { module: "hotels" },
                  queryParams: { specialAction: true },
                }
              );
            }
          });
        });
      }
  }

  onPanelOpened() {
    this.isDescriptionExpanded = true;
  }

  onPanelClosed() {
    this.isDescriptionExpanded = false;
  }

  onDetailsOpened() {
    this.isDetailsExpanded = true;
  }

  onDetailsClosed() {
    this.isDetailsExpanded = false;
  }

  getDescriptionPanelClass() {
    return {
      'expanded-margin': this.isDescriptionExpanded
    };
  }

  getDetailsPanelClass() {
    return {
      'expanded-margin': this.isDetailsExpanded
    };
  }

  isFilterChecked(filterCode: string): boolean {
    return this.selectedFilters.has(filterCode);
  }

  applyFilters(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.selectedFilters.add(checkbox.id);
    } else {
      this.selectedFilters.delete(checkbox.id);
    }
    this.filteredRooms = this.filterByOccupancy(this.hotel.rooms);
    this.updateFilteredRates();
  }

  updateFilteredRates() {
    this.filteredRates = {};
    this.filteredRooms.forEach(room => {
    this.filteredRates[room.code] = this.filterDuplicateRates(room.rates, this.selectedBedTypes[room.code]);
    });
  }

  hasFreeCancellation(rate) {
    return rate.cancellationPolicies && rate.cancellationPolicies[0] && this.isSameOrAfterToday(rate.cancellationPolicies[0].from);
  }

  formatCancellationDate(date: string): string {
    return moment(date).format('DD/MM/YYYY');
  }

  filterDuplicateRates(rates: any[], selectedBedType: string) {
    const uniqueRates = [];
    const seen = new Set();
  
    for (const rate of rates) {
      const rateIdentifier = `${rate.totalWithPercentage}-${rate.cancellationPolicies[0]?.from}-${rate.cancellationPolicies[0]?.amountWithPercentage}-${rate.rateType}`;
  
      if (!seen.has(rateIdentifier)) {
        seen.add(rateIdentifier);
        uniqueRates.push(rate);
      } else {
        const existingRate = uniqueRates.find(r => {
          const identifier = `${r.totalWithPercentage}-${r.cancellationPolicies[0]?.from}-${r.cancellationPolicies[0]?.amountWithPercentage}-${r.rateType}`;
          return identifier === rateIdentifier;
        });
  
        if (existingRate && rate.bed === selectedBedType && existingRate.bed !== selectedBedType) {
          const index = uniqueRates.indexOf(existingRate);
          uniqueRates[index] = rate;
        }
      }
    }
    return uniqueRates;
  }

  getUniqueBedTypes(rates: any[]): string[] {
    const bedTypes = rates.map(rate => rate.bed);
    return Array.from(new Set(bedTypes));
  }

  onBedTypeChange(roomCode: string, event: any) {
    this.selectedBedTypes[roomCode] = event.value;
    this.filterRatesByBedType(roomCode);
  }
  
  filterRatesByBedType(roomCode: string) {
    const room = this.hotel.rooms.find(r => r.code === roomCode);
    if (room) {
      const filteredByBed = room.rates.filter(rate => rate.bed === this.selectedBedTypes[roomCode]);
      this.filteredRates[roomCode] = this.filterDuplicateRates(filteredByBed, this.selectedBedTypes[room.code]);
    }
  }

  initializeFilteredRates() {
    this.hotel.rooms.forEach(room => {
      const bedTypes = this.getUniqueBedTypes(room.rates);
      this.selectedBedTypes[room.code] = bedTypes.length ? bedTypes[0] : '';
      this.filterRatesByBedType(room.code);
    });
  }
  
  toggleRoomRatesVisibility(roomCode: string) {
    this.roomRatesVisibility[roomCode] = !this.roomRatesVisibility[roomCode];
  }

  getMinPrice(rates: any[]): number {
    return Math.min(...rates.map(rate => rate.totalWithPercentage));
  }

}
