import { Pipe, PipeTransform } from "@angular/core";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import * as _lodash from "lodash-es";
import * as moment from "moment";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  constructor(private ngbDateParser: NgbDateParserFormatter) {}
  public transform(value, keys: string[], terms: any[], types: any[]) {
    if (!terms) {
      return value;
    }

    if (keys.length === 0 || terms.length === 0 || types.length === 0) {
      return value;
    }
    return (value || []).filter((item) =>
      keys
        .map((v, i) => i)
        .every((i) => {
          switch (types[i]) {
            case "eq":
              return _lodash.get(item, keys[i], null) === terms[i];
              break;
            case "rg":
              const itemTest = _lodash.get(item, keys[i], null);
              return new RegExp(terms[i], "gi").test(
                (itemTest !== null && typeof itemTest === "string") ||
                  itemTest instanceof String
                  ? itemTest.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                  : itemTest
              );
            case "number":
              if (terms[i] === null) { return true; }
              return terms[i] === _lodash.get(item, keys[i], null);
            case "date":
              if (terms[i][0]) {
                const fromDateFormat = moment(
                  this.ngbDateParser.format(terms[i][0]),
                  "DD/MM/YYYY"
                ).format("YYYY-MM-DD");
                const toDateFormat = moment(
                  this.ngbDateParser.format(
                    terms[i][1] ? terms[i][1] : terms[i][0]
                  ),
                  "DD/MM/YYYY"
                ).format("YYYY-MM-DD");
                return moment(_lodash.get(item, keys[i], null)).isBetween(
                  fromDateFormat,
                  toDateFormat,
                  "day",
                  "[]"
                );
              } else {
                return true;
              }
            case "dateStart":
              if (terms[i]) {
                const dateFormat = moment(
                  this.ngbDateParser.format(terms[i]),
                  "DD/MM/YYYY"
                ).format("YYYY-MM-DD");
                return moment(_lodash.get(item, keys[i], null)).isSameOrAfter(
                  dateFormat,
                  "day"
                );
              } else {
                return true;
              }
            case "dateEnd":
              if (terms[i]) {
                const dateFormat = moment(
                  this.ngbDateParser.format(terms[i]),
                  "DD/MM/YYYY"
                ).format("YYYY-MM-DD");
                const itValue = _lodash.get(item, keys[i], null);
                if (itValue == null) {
                  return true;
                } else {
                  return moment(itValue).isSameOrBefore(dateFormat, "day");
                }
              } else {
                return true;
              }
            case "comb":
              const comb: any[] = keys[i].split(" ");
              const items =
                _lodash.get(item, comb[0], null) +
                " " +
                _lodash.get(item, comb[1], null);
              return new RegExp(terms[i], "gi").test(items);
            default:
              return false;
          }
        })
    );
  }
}
