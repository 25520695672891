import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Solution, TransportRequest } from "@core/models";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StateService {
  flightSelectedState: any;
  didFirstSearch = false;
  flightsGoing: any[];
  flightsReturn: any[];
  transportRequest: TransportRequest;
  state$ = new BehaviorSubject<any>(null);
  dataFromCompany$ = new BehaviorSubject<any>(null);
  sendData = this.dataFromCompany$.asObservable();
  flightOrigin = "";
  flightDestination = "";
  flightDateIn = {};
  flightDateOut = {};
  flightTravelers: any[] = [];
  flightSources = 2;
  isAdminUserDetails: boolean;
  isUser = true;
  flightSearchForm: UntypedFormGroup;
  searchOptionsToggled: boolean;
  firstSelected = false;
  distanceDeparture = 0;
  distanceArrival = 0;
  minTimeDeparture = 0;
  maxTimeDeparture = 24;
  minTimeReturnDeparture = 0;
  maxTimeReturnDeparture = 24;
  orderByLleego: Solution[] = [];
  oneWay = false;
  originSelected: string;
  destinationSelected: string;
  residentCandidates: any;
  transportId: string;
  totalPrice: any;
  selectedUsers: any;

  constructor() {}

  setFlightSelectedState(data: any) {
    this.flightSelectedState = data;
  }

  setFlightField(type: string, value: any) {
    switch (type) {
      case "origin":
        this.flightOrigin = value;
        break;
      case "destination":
        this.flightDestination = value;
        break;
      case "dateIn":
        this.flightDateIn = value;
        break;
      case "dateOut":
        this.flightDateOut = value;
        break;
      case "travelers":
        this.flightTravelers = value;
        break;
      case "sources":
        this.flightSources = value;
        break;
      case "isUser":
        this.isUser = value;
        break;
      default:
        break;
    }
  }
  setFlightSearch(form: UntypedFormGroup) {
    this.flightSearchForm = form;
  }
  getFlightField(type: string) {
    switch (type) {
      case "origin":
        return this.flightOrigin;
      case "destination":
        return this.flightDestination;
      case "dateIn":
        return this.flightDateIn;
      case "dateOut":
        return this.flightDateOut;
      case "travelers":
        return this.flightTravelers;
      case "sources":
        return this.flightSources;
      case "isUser":
        return this.isUser;
      default:
        return;
    }
  }
  getFlightSelectedState() {
    return this.flightSelectedState;
  }

  setDataFromCompany(data) {
    this.dataFromCompany$.next(data);
  }

  setAdminState(value: boolean) {
    this.isAdminUserDetails = value;
  }
  getAdminState() {
    return this.isAdminUserDetails;
  }
}
