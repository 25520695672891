import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatStepperModule } from "@angular/material/stepper";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@shared/shared.module";
import { UserModule } from "app/modules/businessEntities/user/user.module";
import { AirbnbRequestComponent } from "./components/airbnb-request/airbnb-request.component";
import { CarRequestComponent } from "./components/car-request/car-request.component";
import { CustomFlightTrainComponent } from "./components/custom-flight-train/custom-flight-train.component";
import { RequestCustomHotelComponent } from "./components/request-custom-hotel/request-custom-hotel.component";
import { CustomServicesRoutingModule } from "./custom-services-routing.module";
import { CustomServicesComponent } from "./custom-services.component";

@NgModule({
  declarations: [
    CustomServicesComponent,
    CustomFlightTrainComponent,
    RequestCustomHotelComponent,
    CarRequestComponent,
    AirbnbRequestComponent,
  ],
  imports: [
    CommonModule,
    CustomServicesRoutingModule,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSelectModule,
    MatButtonToggleModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatStepperModule,
    MatCardModule,
    UserModule,
  ],
})
export class CustomServicesModule {}
