import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { map } from "rxjs/operators";
import { TokenService } from "./token.service";

@Injectable()
export class RequestService {
  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getRequests(params: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/custom-requests`, {
        params: params,
        headers: reqHeaders,
      })
      .pipe(map(async (res: any) => await res.docs));
  }

  putStatusRequest(params: { requestUuid: string, status: 'active' | 'completed' }) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .put<any>(`${environment.baseUrlApi}/custom-requests/changeStatus`, params,
        {
          headers: reqHeaders,
        })
      .pipe(map(async (requestChanged: any) => await requestChanged));
  }
}
