import { Injectable } from "@angular/core";

@Injectable()
export class ForgotService {
  email = "email";
  constructor() {}

  getEmail(): string {
    return localStorage.getItem(this.email);
  }

  saveEmail(email: string) {
    localStorage.setItem(this.email, email);
  }
}
