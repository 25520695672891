<div class="master-container">
  <div class="content-container white-container border-radius-30">
    <img
      src="assets/img/svg/train/go-back-arrow.svg"
      alt="back arrow"
      class="cursor-pointer"
      (click)="goBack()"
    />
    <div class="stepper-container border-radius-30">
      <div
        *ngFor="let stepperTab of stepperTabs; let i = index"
        [ngClass]="{
          active: stepperTab.selected,
          'cursor-pointer': stepperTab.enabled
        }"
        (click)="changeStepperTab(stepperTab, true)"
      >
        <div class="round-container semi-bold">
          {{ i + 1 }}
        </div>
        <span class="semi-bold">{{ stepperTab.title | translate }}</span>
      </div>
    </div>
    <app-train-seat-selection
      *ngIf="canChooseSeat && isTabSelected('seatSelection')"
      [graphRes]="graphRes"
      [returnWagonList]="returnWagonList"
      [outwardWagonList]="outwardWagonList"
      [passengers]="searchConfig.travelers.length"
      [firstTrain]="firstTrain"
      [outwardSeats]="outwardSeats"
      [returnSeats]="returnSeats"
      [classCodeG]="confirmedSelection[0].class"
      [classCodeR]="confirmedSelection[1]?.class"
      (selectSeatEvent)="selectSeat($event)"
      [events]="eventsSubject.asObservable()"
    ></app-train-seat-selection>
    <app-passenger-form
      [passengerForm]="passengerForm"
      *ngIf="isTabSelected('passengerForm')"
    ></app-passenger-form>
    <app-confirm-booking
      [bookFailed]="bookFailed"
      [isMocked]="isMocked"
      [params]="params"
      [uuidUser]="uuidUser"
      [searchConfig]="searchConfig"
      [confirmedSelection]="confirmedSelection"
      [passengerForm]="passengerForm"
      [outwardSeats]="outwardSeats"
      [returnSeats]="returnSeats"
      [isUser]="isUser"
      [isAdmin]="isAdmin"
      [userType]="userType"
      [amount]="amount"
      [farePriceMap]="farePriceMap"
      [canRequest]="canRequest"
      [trainsComplements]="trainsComplements"
      *ngIf="isTabSelected('confirmBooking')"
      [bookFailed]="bookFailed"
    ></app-confirm-booking>
  </div>
  <div class="summary" *ngIf="searchConfig != null">
    <div class="border-radius-30 white-container">
      <div class="logo">
        <img src="assets/img/trains/renfe.svg" alt="" />
      </div>
      <div class="title">
        <h4>
          {{ "train.search-result.summary.title" | translate }}
        </h4>
      </div>
      <div class="outward">
        <p class="semi-bold">
          {{ "train.search-result.summary.outward" | translate }}:
        </p>
        <p>{{ parseDate(searchConfig.departDate) }}</p>
        <p>
          <b>{{ confirmedSelection[0].departTime }}</b>
          {{ parseTrainCodeToName(searchConfig.origin) | titlecase }}
        </p>
        <p>
          <b>{{ confirmedSelection[0].arrivalTime }}</b>
          {{ parseTrainCodeToName(searchConfig.destination) | titlecase }}
        </p>
        <p class="segment-price">
          <b>
            {{
              "train.confirm-stepper-container.summary.passengers" | translate
            }}:
            {{ searchConfig.travelers.length }}
          </b>
        </p>
        <p *ngIf="outwardSeats.length > 0" class="segment-seat-selection">
          <span
            ><b>Extras: </b>
            {{
              "train.confirm-stepper-container.summary.seat-selection"
                | translate
            }}</span
          >
          <span>
            <b
              >{{
                calcSeatsPrice(outwardSeats.length, confirmedSelection[0])
                  | price
              }}€</b
            >
          </span>
        </p>
      </div>
      <div
        class="return"
        *ngIf="
          trainsList?.returnTrains != null &&
          trainsList?.returnTrains.length > 0
        "
      >
        <p class="semi-bold">
          {{ "train.search-result.summary.return" | translate }}:
        </p>
        <p>{{ parseDate(searchConfig.returnDate) }}</p>
        <p>
          <b>{{ confirmedSelection[1].departTime }}</b>
          {{ parseTrainCodeToName(searchConfig.destination) | titlecase }}
        </p>
        <p>
          <b>{{ confirmedSelection[1].arrivalTime }}</b>
          {{ parseTrainCodeToName(searchConfig.origin) | titlecase }}
        </p>
        <p class="segment-price">
          <b>
            {{
              "train.confirm-stepper-container.summary.passengers" | translate
            }}:
            {{ searchConfig.travelers.length }}
          </b>
        </p>
        <p *ngIf="returnSeats.length > 0" class="segment-seat-selection">
          <span
            ><b>Extras: </b>
            {{
              "train.confirm-stepper-container.summary.seat-selection"
                | translate
            }}</span
          >
          <span>
            <b
              >{{
                calcSeatsPrice(returnSeats.length, confirmedSelection[1])
                  | price
              }}€</b
            >
          </span>
        </p>
      </div>
      <div class="distanced-container">
        <b>
          {{
            "train.confirm-stepper-container.summary.price"
              | translate
              | uppercase
          }}: {{ calcTotal() | price }}€
        </b>
      </div>
    </div>
    <!-- SEAT SELECTION ACTIONS -->
    <div *ngIf="isTabSelected('seatSelection')">
      <button
        class="button-accept semi-bold"
        [disabled]="checkSeatsAreCorrect() === false"
        *ngIf="firstTrain"
        (click)="
          searchConfig.oneWay === false ? changeToReturnTrain() : confirmSeats()
        "
      >
        {{ "train.confirm-stepper-container.stepper.continue" | translate }}
      </button>
      <button
        class="button-accept semi-bold"
        *ngIf="firstTrain === false"
        [disabled]="checkSeatsAreCorrect() === false"
        (click)="checkSeatsAreCorrect() ? confirmSeats() : null"
      >
        {{ "train.confirm-stepper-container.stepper.continue" | translate }}
      </button>
      <button
        class="button-accept semi-bold"
        *ngIf="firstTrain === false"
        id="changeTrain"
        (click)="changeToFirstTrain()"
      >
        {{ "train.confirm-stepper-container.stepper.change-train" | translate }}
      </button>
    </div>
    <!-- END OF SEAT SELECTION ACTIONS -->
    <!-- PASSENGER FORM ACTIONS -->
    <div *ngIf="isTabSelected('passengerForm')">
      <button
        class="button-accept semi-bold"
        [disabled]="passengerForm?.valid === false"
        (click)="passengerForm?.valid ? confirmPassengers() : null"
      >
        {{
          "train.confirm-stepper-container.passenger-form.continue" | translate
        }}
      </button>
      <button
        *ngIf="canChooseSeat"
        class="button-accept semi-bold"
        id="cancelTravelers"
        (click)="changeStepperTab(stepperTabs[0])"
      >
        {{
          "train.confirm-stepper-container.passenger-form.cancel" | translate
        }}
      </button>
    </div>
    <!-- END OF PASSENGER FORM ACTIONS -->
  </div>
</div>
