import { Injectable } from "@angular/core";
import { Subscription, take } from "rxjs";
import { TravelService } from "./travel.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxToastrService } from "./ngx.toastr.service";

@Injectable({
  providedIn: "root",
})
export class TravelUtilsService {
  subscriptions: Subscription[] = [];
  constructor(private travelService: TravelService,
              private translateService: TranslateService,
              private ngxToastrService: NgxToastrService) {}

  async createTravel(bodyTravel: any, data: any, type: string, callback?: () => void) {
      try {
        const s = await this.travelService.postTravel(bodyTravel).toPromise();
        const addBody = {
            item: {
            type: data.item.type,
            // initDate: bodyTravel.initDate,
            // endDate: bodyTravel.endDate,
            uuid: data.item.uuid,
          },
        };
        addBody["userUuid"] = data.userUuid;
        addBody["companyUuid"] = data.companyUuid;
              
        await this.addItemToTravel(addBody, s.uuid);
                  
        const result = await this.translateService.get("project.my-projects.created").pipe(take(1)).toPromise();
        this.ngxToastrService.typeSuccess(result, '');
                  
        if (callback) {
          callback();
        }
      } catch (err) {
        const result = await this.translateService.get("project.my-projects.no-created").toPromise();
        this.ngxToastrService.typeError(result, err.error.message);
                  
      if (callback) {
        callback();
      }
    }
  }

  addItemToTravel(body: any, uuid: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.subscriptions.push(
        this.travelService.addItemTravel(body, uuid).subscribe(
          (res) => {
            resolve();
          },
          (err) => {
            reject(err);
          }
        )
      );
    });
  }

  setTravelLocalStorage(travelData: any) {
    localStorage.setItem("travel", JSON.stringify(travelData));
    return;
  }

  getTravelLocalStorage() {
    return JSON.parse(localStorage.getItem("travel"));
  }

  removeTravelLocalStorage() {
    localStorage.removeItem("travel");
    return;
  }
}
