<div *ngIf="loading" style="z-index: 9999;">
  <app-loading [userType]="'employee'"></app-loading>
</div>
<div class="text-center" *ngIf="transactions.length <= 0 && !loading">
  <div>
    <img
      class="not_found_image"
      src="assets/img/gallery/flights-not-found.svg"
      alt="No hay archivos"
    />
    <h3 class="mt-2">
      <b>{{ "company.transaction.no-transaction" | translate }}</b>
    </h3>
  </div>
</div>
<!-- TABLA DE TRANSACCIONES -->
<div id="whole-container" *ngIf="transactions.length > 0">
  <div class="container-customize">
    <form [formGroup]="form">
      <div class="row">
        <div class="filters">
          <!-- FILTER -->
          <div>
            <input
              (input)="setPage()"
              formControlName="user"
              class="form-control form-control--squared form-control--small"
              placeholder="{{ 'company.transaction.filters.user' | translate }}"
            />
          </div>
          <div>
            <input
              (input)="setPage()"
              formControlName="department"
              class="form-control form-control--squared form-control--small"
              placeholder="{{
                'company.transaction.filters.department' | translate
              }}"
            />
          </div>
          <div>
            <select
              type="text"
              formControlName="type"
              id="type"
              class="form-control form-control--squared form-control--small select-input"
              (change)="setPage()"
            >
              <option selected hidden value="">
                {{ "common.none" | translate }}
              </option>
              <option [value]="'pending'">
                {{ "company.transaction.filters.type-pending" | translate }}
              </option>
              <option [value]="'payment'">
                {{ "company.transaction.filters.type-payment" | translate }}
              </option>
              <option [value]="'refund'">
                {{ "company.transaction.filters.type-refund" | translate }}
              </option>
            </select>
          </div>
          <div>
            <input
              class="form-control form-control--squared form-control--small filter-dates"
              (dateSelect)="onDateSelection($event)"
              placeholder="{{
                'company.transaction.filters.created' | translate
              }}"
              #dateDep
              #dp="ngbDatepicker"
              ngbDatepicker
              #d="ngbDatepicker"
              (click)="d.toggle(); setPage()"
              readonly
              [ngClass]="!date.value ? 'filter-dates-placeholder' : ''"
            />
          </div>
          <div>
            <input
              class="form-control form-control--squared form-control--small filter-dates"
              readonly
              placeholder="{{
                'company.transaction.filters.created' | translate
              }}"
              name="date"
              id="date"
              ngbDatepicker
              #date="ngbDatepicker"
              #dateRet
              (dateSelect)="onDateSelection($event)"
              (click)="date.toggle(); setPage()"
              [ngClass]="!date.value ? 'filter-dates-placeholder' : ''"
            />
          </div>
          <div class="text-center align-middle" id="filters-buttons">
            <button
              type="button"
              [ngClass]="canClean() ? 'clear-btn red' : 'clear-btn'"
              type="button"
              (click)="cleanFilters()"
            >
              {{ "common.clean" | translate }}
              <img
                src="{{
                  !canClean()
                    ? 'assets/img/svg/grey-cross.svg'
                    : 'assets/img/svg/red-cross.svg'
                }}"
              />
            </button>
          </div>
        </div>
      </div>
      <table class="table table-hover mb-0 table-groups">
        <thead>
          <th scope="col">
            {{ "company.transaction.filters.user" | translate }}
          </th>
          <th scope="col">
            {{ "company.transaction.filters.department" | translate }}
          </th>
          <th scope="col">
            {{ "company.transaction.filters.status" | translate }}
          </th>
          <th scope="col">
            <!-- {{ 'company.transaction.filters.booked' | translate }} -->
            {{ "company.transaction.filters.service" | translate }}
          </th>
          <!-- <th scope="col" id="auth">
                        {{ 'company.transaction.filters.auth_code' | translate }}</th> -->
          <th scope="col">
            {{ "company.transaction.filters.created" | translate }}
          </th>
          <th scope="col">
            {{ "company.transaction.filters.amount" | translate }}
          </th>
          <th></th>
        </thead>
        <tbody>
          <!-- DATA TABLE -->
          <tr
            *ngFor="
              let transaction of transactions
                | filter
                  : ['user', 'department', 'type', 'createdAt']
                  : [
                      f.user.value,
                      f.department.value,
                      f.type.value,
                      [fromDate, toDate]
                    ]
                  : ['rg', 'rg', 'rg', 'date']
                | slice : lowValue : highValue;
              let i = index
            "
          >
            <td>
              <p>{{ transaction.user }}</p>
            </td>
            <td>
              <p id="deparment">{{ transaction.department?.toLowerCase() }}</p>
            </td>

            <td *ngIf="isEmployee">
              <select
                type="text"
                id="type"
                *ngIf="editableArr[i] || transaction.type?.toLowerCase() === 'pending'"
                class="form-control form-control--squared form-control--small"
                (change)="
                changeTransactionType(transaction, $event.target.value, i)
              "
              >
                <option
                  (click)="
                    changeTransactionType(transaction, $event.target.value, i)
                  "
                  [value]="'pending'"
                  [selected]="transaction.type?.toLowerCase() === 'pending'"
                >
                  {{ "company.transaction.filters.type-pending" | translate }}
                </option>
                <option
                  (click)="
                    changeTransactionType(transaction, $event.target.value, i)
                  "
                  [value]="'payment'"
                  [selected]="transaction.type?.toLowerCase() === 'payment'"
                >
                  {{ "company.transaction.filters.type-payment" | translate }}
                </option>
                <option
                  (click)="
                    changeTransactionType(transaction, $event.target.value, i)
                  "
                  [value]="'refund'"
                  [selected]="transaction.type?.toLowerCase() === 'refund'"
                >
                  {{ "company.transaction.filters.type-refund" | translate }}
                </option>
              </select>
              <ng-container
                *ngIf="transaction.type?.toLowerCase() !== 'pending' && !editableArr[i]"
              >
                <p *ngIf="transaction.type?.toLowerCase() === 'payment'" style="color: #10B981">
                  {{ "company.transaction.filters.type-payment" | translate }}
                </p>
                <p *ngIf="transaction.type?.toLowerCase() === 'refund'" style="color: #ED5A46">
                  {{ "company.transaction.filters.type-refund" | translate }}
                </p>
                <p *ngIf="transaction.type?.toLowerCase()=== 'pending'">
                  {{ "company.transaction.filters.type-pending" | translate }}
                </p>
              </ng-container>
            </td>

            <td *ngIf="!isEmployee">
              <p *ngIf="transaction.type?.toLowerCase() === 'payment'">
                {{ "company.transaction.filters.type-payment" | translate }}
              </p>
              <p *ngIf="transaction.type?.toLowerCase() === 'refund'">
                {{ "company.transaction.filters.type-refund" | translate }}
              </p>
              <p *ngIf="transaction.type?.toLowerCase() === 'pending' && !isEmployee">
                {{ "company.transaction.filters.type-pending" | translate }}
              </p>
            </td>
            <td>
              <p
                *ngIf="
                  transaction.service !== '' &&
                  transaction.service !== 'expense' &&
                  transaction.service !== 'flight' &&
                  transaction.service !== 'train' &&
                  transaction.service !== 'car'
                "
              >
                {{ "company.transaction.hotel" | translate }}
              </p>
              <p *ngIf="transaction.service === 'expense'">
                {{ "expenses.title" | translate }}
              </p>
              <p *ngIf="transaction.service === 'flight'">
                {{ "company.transaction.flight" | translate }}
              </p>
              <p *ngIf="transaction.service === 'train'">
                {{ "company.transaction.train" | translate }}
              </p>
              <p *ngIf="transaction.service === 'car'">
                {{ "company.transaction.car" | translate }}
              </p>
              <p *ngIf="transaction.service === ''">
                {{ "company.transaction.filters.no-booked-create" | translate }}
              </p>
            </td>
            <td>
              <p>{{ getDate(transaction.createdAt) }}</p>
            </td>
            <td>
              <p>{{ transaction.amount | price }} €</p>
            </td>
            <td id="container-buttons" ngbDropdown>
              <a *ngIf="transaction.service !== 'expense' || isEmployee" ngbDropdownToggle>
                <img class="pointer" src="assets/img/svg/black-kebab.svg" />
                <div ngbDropdownMenu>
                  <p class="text-left" (click)="onView(transaction)">
                    <img src="assets/img/svg/black-eye.svg" />
                    Ver reserva</p>

                  <p class="text-left" *ngIf="transaction.type === 'pending' && isEmployee"
                  (click)="doTransactionAction(transaction, 'PAYMENT')">
                    <img src="assets/img/svg/black-bill-2.svg" />
                    {{ "company.transaction.filters.action-pay" | translate }}</p>

                  <p class="text-left" *ngIf="transaction.type === 'payment' && isEmployee"
                  (click)="doTransactionAction(transaction, 'REFUND')">
                    <img src="assets/img/svg/black-bill-2.svg" />
                    {{ "company.transaction.filters.action-refund" | translate }}</p>

                    <p class="text-left d-flex" *ngIf="transaction.type !== 'pending' && isEmployee"
                    (click)="editableArr[i] = !editableArr[i]">
                      <img src="assets/img/svg/black-edit-2.svg" id="edit-img" />
                      {{ 'agency.agency-card.edit' | translate }}</p>
                </div>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
  <div
    class="col-12 d-flex justify-content-center my-5"
    *ngIf="
      (
        transactions
        | filter
          : ['user', 'department', 'type', 'createdAt']
          : [f.user.value, f.department.value, f.type.value, [fromDate, toDate]]
          : ['rg', 'rg', 'rg', 'date']
      ).length === 0
    "
  >
    <div class="icon-bcg icon-bcg--large">
      <img
        src="assets/img/svg/empty-search.svg"
        alt="emptySearch"
        class="empty-search"
      />
    </div>
    <h2 class="ml-3 my-auto">{{ "search.not-data" | translate }}</h2>
  </div>
  <div
    *ngIf="
      (
        transactions
        | filter
          : ['user', 'department', 'type', 'createdAt']
          : [f.user.value, f.department.value, f.type.value, [fromDate, toDate]]
          : ['rg', 'rg', 'rg', 'date']
      ).length > 0
    "
    class="d-flex justify-content-center mt-2 mb-2 col-12"
  >
    <mat-paginator
      #paginator
      [length]="
        (
          transactions
          | filter
            : ['user', 'department', 'type', 'createdAt']
            : [
                f.user.value,
                f.department.value,
                f.type.value,
                [fromDate, toDate]
              ]
            : ['rg', 'rg', 'rg', 'date']
        ).length
      "
      [pageSize]="5"
      (page)="getPaginatorData($event)"
      showFirstLastButtons
      [hidePageSize]="true"
      style="border-radius: 15px"
    >
    </mat-paginator>
  </div>
</div>
