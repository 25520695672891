import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { User } from "@core/models";
import {
  Fares,
  MultifamilyRS,
  SeatAvailSegment,
  Segment,
  TransportSupplement,
} from "@core/models/flight-llego";
import {
  FlightLleegoService,
  NgxToastrService,
  StateService,
  UserService,
  UtilsService,
} from "@core/services";
import { TranslateService } from "@ngx-translate/core";
import airports from "assets/utils/airports.json";
import moment from "moment";

@Component({
  selector: "app-flight-step-detail",
  templateUrl: "./flight-step-detail.component.html",
  styleUrls: ["./flight-step-detail.component.scss"],
})
export class FlightStepDetailComponent implements OnInit, AfterViewInit {
  @Input() loadingFlight: Boolean;
  @Input() selectedFlights: Segment[][];
  @Input() fareSelected?: Fares;
  @Input() totalPrice: number;
  basePrice = 0;
  @Input() fareName: string;
  canBaggage = false;
  @Input() requestData?: any;
  @Input() multiFamily: MultifamilyRS;
  baggagesInfo: TransportSupplement[];
  baggageCodes = [
    "SSR_ABAG",
    "SSR_ASVC",
    "SSR_BBAG",
    "SSR_CBAG",
    "CKIN",
    "SSR_DBAG",
    "EXBG",
    "SSR_FBAG",
    "SSR_HBAG",
    "LGXB",
    "MEXB",
    "SSR_OBAG",
    "SSR_PDBG",
    "SSR_SBAG",
    "SMXB",
    "SNBG",
    "SSR_XABG",
    "SSR_XBAG",
    "XBAA",
    "XBAB",
    "XBAS",
    "XBAT",
    "XLXB",
    "XLSW",
    "XTSI",
  ];
  @Output() baggageSelectedEvent = new EventEmitter<any>();
  selectedBags: any[] = [];
  bagSelected: any;
  seeHangover: boolean[] = [false, false];
  needDocApis: boolean;
  needAddress: boolean;
  emitOnBookingDetail: any;
  availablePayments: any;
  farePricing: any;
  selectedBaggages: TransportSupplement[] = [];
  @Output() changeFare: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextStep: EventEmitter<any> = new EventEmitter<any>();
  @Input() durations: any[];
  originName: string;
  departureName: string;
  currentSegment: SeatAvailSegment;
  user: User;
  canUseResident: boolean;

  constructor(
    public translate: TranslateService,
    private flightLleegoService: FlightLleegoService,
    private utilsService: UtilsService,
    private ngxToastrService: NgxToastrService,
    private router: Router,
    private userService: UserService,
    private stateService: StateService,
  ) {}

  ngOnInit(): void {
    this.utilsService.loadingFlights = true;
    this.userService
      .getUser(this.requestData.userUuid)
      .subscribe((res: User) => {
        this.user = res;
        this.canUseResident = this.user.resident && this.user.resident.toString() !== "none" && this.stateService.residentCandidates && this.stateService.residentCandidates.find(r => r === this.user.resident);
        let params = {
          token: this.requestData.token,
          userUuid: this.requestData.userUuid,
          passengers: this.requestData.passengers,
        };
        if (
          this.fareSelected &&
          this.multiFamily &&
          this.multiFamily.fares.length > 0
        ) {
          params["solutionID0"] = this.fareSelected.id;
          params["journey00"] = this.fareSelected.journeys_ids[0];
          params["journey01"] =
            this.fareSelected.journeys_ids[
              this.fareSelected.journeys_ids.length - 1
            ];
        } else {
          params["solutionID0"] = this.requestData.solutionID0;
          params["journey00"] = this.requestData.journey00;
          params["journey01"] = this.requestData.journey01;
        }
        if (this.canUseResident) {
          params["resident"] = this.user.resident;
        }
        this.flightLleegoService.getFlightPricing(params).subscribe(
          (res) => {
            this.availablePayments = res.available_payments[0];
            if (res.solutions[0].transport.catalogue) {
              delete params.passengers;
              delete params["resident"];
              if (this.canUseResident) {
                this.stateService.transportId = res.solutions[0].transport.id;
                params["solutionID0"] = res.solutions[0].transport.id;
              }
              // Maletas
              this.flightLleegoService
                .getFlightStandalonecatalogue(params)
                .subscribe(
                  (resBaggages) => {
                    this.utilsService.loadingFlights = false;
                    if (
                      resBaggages.solutions[0] &&
                      resBaggages.solutions[0].services &&
                      resBaggages.solutions[0].services.length > 0
                    ) {
                      const baggages = [];
                      resBaggages.solutions[0].services.forEach((service) => {
                        if (service.category?.toLowerCase() === "baggage") {
                          baggages.push(service);
                        }
                      });
                      if (baggages.length > 0) {
                        if (
                          this.selectedFlights.length > 1 &&
                          !(
                            this.fareSelected &&
                            this.multiFamily &&
                            this.multiFamily.fares.length > 0
                          )
                        ) {
                          const outwardBaggages = baggages.filter((bag) =>
                            bag.short_name?.toLowerCase().includes("outward")
                          );
                          const returnBaggages = baggages.filter((bag) =>
                            bag.short_name?.toLowerCase().includes("outward")
                          );
                          this.baggagesInfo = [
                            ...outwardBaggages,
                            ...returnBaggages,
                          ];
                        } else {
                          this.baggagesInfo = baggages;
                        }
                        this.canBaggage = true;
                        this.loadingFlight = false;
                      }
                    } else {
                      this.translate
                        .get("flight.form-flight.error")
                        .subscribe((resp) => {
                          this.utilsService.loadingFlights = false;
                          this.canBaggage = false;
                        });
                    }
                  },
                  (err) => {
                    this.translate
                      .get("flight.form-flight.error")
                      .subscribe((resp) => {
                        this.utilsService.loadingFlights = false;
                        this.canBaggage = false;
                      });
                  }
                );
            }
            this.totalPrice = res.price.total * 1;
            this.stateService.totalPrice = this.totalPrice;
            this.needDocApis = res.solutions[0].need_doc_apis;
            this.needAddress = res.solutions[0].need_address;
            this.emitOnBookingDetail =
              res.solutions[0].transport.emit_on_booking_detail;
            this.canBaggage = res.solutions[0].transport.catalogue
              ? true
              : false;
            if (!this.canBaggage) {
              this.utilsService.loadingFlights = false;
            }
            if (
              this.fareSelected &&
              this.multiFamily &&
              this.multiFamily.fares.length > 0
            ) {
              this.bagSelected;
              this.farePricing = {
                amount: res.price.total,
                base: res.price.base,
                taxes: Math.round(res.price.total_taxes),
              };
            }
          },
          (err) => {
            this.utilsService.loadingFlights = false;
            this.translate.get("flight.form-flight.error").subscribe((resp) => {
              this.loadingFlight = false;
              this.utilsService.loadingFlights = false;
              this.ngxToastrService.typeInfo(resp, "");
              this.router.navigate(['/flight'], { queryParams: { didSearch: true } });
            });
          }
        );
      });

    this.loadingFlight = false;
  }

  ngAfterViewInit(): void {}

  getLogo(value) {
    return `assets/img/airlines/small/${value}.png`;
  }

  updateBaggagePrice(event: any) {
    this.totalPrice = this.basePrice;
    for (const foundBaggage of event) {
      this.totalPrice += foundBaggage.total;
    }
    this.selectedBaggages = event;
  }

  toggleHanover(index: number) {
    this.seeHangover[index] = !this.seeHangover[index];
  }

  calcTotalSegmentTime(segmentArr: Segment[]): string {
    let totalTime;
    const departDatetime = moment(
      segmentArr[0].object.departure_date,
      moment.ISO_8601
    );
    const returnDatetime = moment(
      segmentArr[segmentArr.length - 1].object.arrival_date,
      moment.ISO_8601
    );
    const duration = moment.duration(returnDatetime.diff(departDatetime));
    const days = duration.days();
    const hours = duration.hours();
    const hoursFormatted = `${hours}h `;
    const minutes = duration.minutes();
    const minutesFormatted = `${minutes}m`;
    totalTime = `${hoursFormatted}${minutesFormatted}`;
    if (days > 0) {
      const daysFormatted = `${days}d `;
      totalTime = `${daysFormatted}${totalTime}`;
    }
    return totalTime;
  }

  calcPartialSegmentTime(segment: Segment): string {
    let totalTime;
    const departDatetime = moment(
      segment.object.departure_date,
      moment.ISO_8601
    );
    const returnDatetime = moment(segment.object.arrival_date, moment.ISO_8601);
    const duration = moment.duration(returnDatetime.diff(departDatetime));
    const hours = duration.hours();
    const hoursFormatted = `${hours}h `;
    const minutes = duration.minutes();
    const minutesFormatted = `${minutes}m`;
    totalTime = `${hoursFormatted} ${minutesFormatted}`;
    return totalTime;
  }

  getStops(segmentArr: Segment[]): Segment[] {
    const segmentArrCopy = [...segmentArr];
    segmentArrCopy.splice(0, 1);
    return segmentArrCopy;
  }

  emitChangeFare() {
    this.changeFare.emit();
  }

  getBaggageOptions(segmentArr: Segment[]): TransportSupplement[] {
    const segmentIds = segmentArr.map((segment) => segment.id);
    const baggageOptions = this.baggagesInfo.filter((service) => {
      return segmentIds.every(function (element) {
        return service.seg_references.includes(element);
      });
    });
    return baggageOptions;
  }

  selectBaggage(baggageInfoIndex: number, baggage?: any) {
    if (baggage !== -1) {
      if (baggageInfoIndex === 1 && this.selectedBags[0] == null) {
        this.selectedBags[0] = { total: 0 };
      }
      this.selectedBags[baggageInfoIndex] = baggage;
      this.baggageSelectedEvent.emit(this.selectedBags);
    } else {
      this.selectedBags[baggageInfoIndex] = { total: 0 };
      this.baggageSelectedEvent.emit(this.selectedBags);
    }
  }

  getPrice() {
    let price = Math.round(this.farePricing.amount * 100) / 100;
    if (this.selectedBags.length != 0) {
      for (const baggage of this.selectedBags) {
        this.bagSelected = baggage.total;
        if (baggage.total == null) {
          price;
        } else {
          price = Math.round((price + baggage.total) * 100) / 100;
        }
      }
      return price;
    } else {
      return price;
    }
  }

  baggageSelected(event, index) {
    if (event.value !== 0) {
      if (index === 1 && this.selectedBags[0] == null) {
        this.selectedBags[0] = { total: 0 };
      }
      this.selectedBags[index] = event.value;
      this.baggageSelectedEvent.emit(this.selectedBags);
    } else {
      this.selectedBags[index] = { total: 0 };
      this.baggageSelectedEvent.emit(this.selectedBags);
    }
  }

  goNextStep() {
    const nextEmit = {
      needAddress: this.needAddress ? this.needAddress : false,
      needDocApis: this.needDocApis ? this.needDocApis : false,
      emitOnBookingDetail: this.emitOnBookingDetail
        ? this.emitOnBookingDetail
        : {},
      availablePayments: this.availablePayments ? this.availablePayments : {},
      totalPrice: this.canUseResident ? this.totalPrice : (this.fareSelected ? this.getPrice() : this.totalPrice),
      selectBaggage: this.selectedBags ?? "",
    };
    if (this.needAddress || this.needDocApis || this.emitOnBookingDetail) {
      this.nextStep.emit(nextEmit);
    } else {
      this.nextStep.emit(nextEmit);
    }
  }

  getBaggagesInfo(segmentArr: any) {
    return this.baggagesInfo.filter(
      (bag) => bag.seg_references[0] === segmentArr[0].id
    );
  }

  isNumber(bag) {
    return !isNaN(bag.total.toFixed());
  }
  getNameAirport(iata: string) {
    let nameAirport = "";
    airports["Table1"].forEach((ai) => {
      if (ai["IATA"] === iata) {
        nameAirport = ai["city"];
      }
    });
    return nameAirport;
  }
}
