import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: "gender" })
export class GenderPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: string) {
    this.translate.get(["common.male", "common.female"]).subscribe((resp) => {
      switch (value) {
        case "male":
          return resp["common.male"];
        case "female":
          return resp["common.female"];
        default:
          return value;
      }
    });
  }
}
