import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { TemplateConfig } from "@interfaces";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  public templateConf: TemplateConfig;

  constructor(private http: HttpClient) {
    this.setConfigValue();
  }

  setConfigValue() {
    this.templateConf = {
      layout: {
        variant: "Light", // options:  Dark, Light & Transparent
        dir: "ltr", // Options: ltr, rtl
        sidebar: {
          collapsed: true, // Options: true, false
          size: "sidebar-md", // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
          // tslint:disable-next-line: max-line-length
          backgroundColor: "man-of-steel", // Options: 'black', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'
          backgroundImage: true, // Options: true, false | Set true to show background image
          backgroundImageURL:
            "../assets/img/svg/sidebar/sidebar-background.png",
        },
      },
    };
  }

  getPercentageVyoo(element: string) {
    return this.http
      .get<any>(`${environment.baseUrlApi}/config/${element}`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  putPercentageVyoo(element: string, percentage) {
    return this.http
      .put<any>(`${environment.baseUrlApi}/config/${element}`, percentage)
      .pipe(map((res) => res));
  }
}
