<form [formGroup]="passengerForm" *ngIf="passengerForm != null">
  <mat-accordion formArrayName="users">
    <mat-expansion-panel
      class="master-container"
      *ngFor="let item of formArr.controls; let i = index"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "train.form-train.traveler" | translate }} {{ i + 1 }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container [formGroupName]="i">
        <div>
          <p class="semi-bold">
            {{
              "train.confirm-stepper-container.passenger-form.name" | translate
            }}
          </p>
          <label class="border-radius-20">
            <input id="name" type="text" formControlName="name" />
          </label>
        </div>
        <div>
          <p class="semi-bold">
            {{
              "train.confirm-stepper-container.passenger-form.last-name"
                | translate
            }}
            1
          </p>
          <label class="border-radius-20">
            <input id="lastName" type="text" formControlName="surname" />
          </label>
        </div>
        <div>
          <p class="semi-bold">
            {{
              "train.confirm-stepper-container.passenger-form.last-name"
                | translate
            }}
            2
          </p>
          <label class="border-radius-20">
            <input id="lastName2" type="text" formControlName="lastSurname" />
          </label>
        </div>
        <div ngbDropdown>
          <p class="semi-bold">
            {{
              "train.confirm-stepper-container.passenger-form.id-type"
                | translate
            }}
          </p>
          <div
            *ngIf="documentTypeOptions.length > 0"
            ngbDropdownToggle
            class="dropdown-toggle border-radius-20 cursor-pointer"
          >
            {{
              passengerForm.value.users[i].documentType != null
                ? passengerForm.value.users[i].documentType
                : ("train.trenymas.none" | translate)
            }}
          </div>
          <div
            ngbDropdownMenu
            class="dropdown-menu border-radius-20"
            aria-labelledby="dropdownBasic1"
          >
            <p
              ngbDropdownItem
              *ngFor="
                let documentTypeOption of documentTypeOptions;
                let j = index
              "
              (click)="selectDocumentType(documentTypeOption, i)"
              [ngClass]="{
                selected:
                  documentTypeOption.text ===
                  passengerForm.value.users[i].documentType,
                last: documentTypeOptions.length - 1 === j
              }"
            >
              {{ documentTypeOption.text | translate }}
            </p>
          </div>
        </div>
        <div>
          <p class="semi-bold">
            {{
              "train.confirm-stepper-container.passenger-form.id-number"
                | translate
            }}
          </p>
          <label class="border-radius-20">
            <input id="idNumber" type="text" formControlName="documentNumber" />
          </label>
        </div>
        <div class="empty"></div>
        <div id="email-container">
          <p class="semi-bold">
            {{
              "train.confirm-stepper-container.passenger-form.email" | translate
            }}
          </p>
          <label class="border-radius-20">
            <input id="email" type="email" formControlName="email" />
          </label>
        </div>
        <div id="phone-container">
          <p class="semi-bold">
            {{
              "train.confirm-stepper-container.passenger-form.phone" | translate
            }}
          </p>
          <label class="border-radius-20">
            <input id="phone" type="text" formControlName="phone" />
          </label>
        </div>
        <div class="w-half">
          <p class="semi-bold">
            {{
              "train.confirm-stepper-container.passenger-form.renfe-card"
                | translate
            }}
          </p>
          <label class="border-radius-20">
            <input id="passengerRenfeCard" type="text" formControlName="passengerRenfeCard" />
          </label>
        </div>
        <div class="w-half">
          <p class="semi-bold">
            {{
              "train.confirm-stepper-container.passenger-form.plus50"
                | translate
            }}
          </p>
          <label class="border-radius-20">
            <input id="plus50" type="text" formControlName="plus50" />
          </label>
        </div>
        <div class="w-half">
          <p class="semi-bold">
            {{
              "train.confirm-stepper-container.passenger-form.golden-card"
                | translate
            }}
          </p>
          <label class="border-radius-20">
            <input id="goldenCard" type="text" formControlName="goldenCard" />
          </label>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</form>
