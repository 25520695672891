export class ConfirmFlightDto {
  requestId: string;
  preorderId: string;
  travelers: {
    id?: string;
    dateOfBirth?: string;
    gender?: "MALE" | "FEMALE";
    name?: {
      firstName: string;
      middleName?: string;
      lastName: string;
      secondLastName?: string;
    };
    documents?: {
      documentType?:
        | "VISA"
        | "PASSPORT"
        | "IDENTITY_CARD"
        | "NIE"
        | "TRAVELER"
        | "REDRESS";
      number?: string;
      issuanceDate?: string;
      expiryDate?: string;
      issuanceCountry?: string;
      issuanceLocation?: string;
      nationality?: string;
      birthPlace?: string;
      validityCountry?: string;
      birthCountry?: string;
      holder?: boolean;
    }[];
  }[];
  contact?: {
    phones?: {
      deviceType?: "MOBILE" | "LANDLINE" | "FAX";
      countryCallingCode?: string;
      number?: string;
    }[];
    companyName: string;
    emailAddress: string;
  };
  userUuid?: string;
  travelName?: string;
}
