import { UntypedFormGroup } from "@angular/forms";

export function ValidatePassword(formGroup: UntypedFormGroup) {
  const f = formGroup.controls;
  if (f.password.value === f.confirmPassword.value) {
    return null;
  }

  return { validatePassword: true };
}
