<!-- Sidebar Header starts -->
<div class="sidebar-header">
  <div class="logo clearfix" appSidebarToggle>
    <a
      class="d-none d-sm-none d-md-none d-lg-block cursor-default"
      id="sidebarToggle"
    >
      <img
        #toggleIcon
        id="collapse-icon"
        class="toggle-icon cursor-pointer"
        src="assets/img/svg/open-arrow.svg"
      />
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content" [perfectScrollbar]>
  <div class="nav-container">
    <ul class="navigation" appSidebarList *ngIf="menuItems && optionItems">
      <!-- First level menu -->
      <li
        appSidebarlink
        level="{{ depth + 1 }}"
        (toggleEmit)="handleToggle($event)"
        [routePath]="menuItem.path"
        [classes]="menuItem.class"
        [title]="menuItem.title"
        [parent]=""
        *ngFor="let menuItem of menuItems; let i = index"
        [ngClass]="{
          'has-sub': menuItem.class === 'has-sub' ? true : false,
          open:
            activeTitles.includes(menuItem.title) && !nav_collapsed_open
              ? true
              : false,
          'nav-collapsed-open':
            nav_collapsed_open && activeTitles.includes(menuItem.title),
          'service-disabled': menuItem.enabled === false
        }"
        [routerLinkActive]="'active'"
        id="{{ 'menu-' + i }}"
        class="sidebar-link"
        [matTooltip]="
          menuItem.enabled === false
            ? ('common.service-disabled' | translate)
            : null
        "
      >
        <a
          id="item"
          appSidebarAnchorToggle
          [routerLink]="
            (menuItem.enabled === true || menuItem.enabled == null) &&
            menuItem.class === ''
              ? [menuItem.path]
              : null
          "
          *ngIf="!menuItem.isExternalLink; else externalLinkBlock"
        >
          <div [ngClass]="menuItem.selected ? 'icon-bcg icon-bcg--selected' : 'icon-bcg'" >
            <img
              class="ng-tns-c3-0 ng-star-inserted"
              src="assets/img/gallery/alfred.png"
              style="max-width: 30px"
              *ngIf="menuItem.title === 'sidebar.help'"
            />
            <!-- CAMBIAR CON EL PATH CORRECTO-->
            <img
              src="assets/img/svg/sidebar/{{menuItem.icon}}.svg"
              *ngIf="menuItem.title !== 'sidebar.help'"
            />
          </div>
          <span class="menu-title">{{ menuItem.title | translate }}</span>
          <span
            *ngIf="menuItem.badge !== ''"
            [ngClass]="[menuItem.badgeClass]"
            >{{ menuItem.badge }}</span
          >
        </a>
        <ng-template #externalLinkBlock>
          <a id="item" [href]="[menuItem.path]" target="_blank">
            <img
              class="ng-tns-c3-0 ng-star-inserted"
              src="assets/img/gallery/alfred.png"
              style="max-width: 30px"
              *ngIf="menuItem.title === 'sidebar.help'"
            />
            <img
              src="assets/img/svg/sidebar/{{
                menuItem.selected ? menuItem.iconSelected : menuItem.icon
              }}.svg"
              *ngIf="menuItem.title !== 'sidebar.help'"
            />
            <span class="menu-title">{{ menuItem.title | translate }}</span>
            <span
              *ngIf="menuItem.badge !== ''"
              [ngClass]="[menuItem.badgeClass]"
              >{{ menuItem.badge }}</span
            >
          </a>
        </ng-template>
        <!-- Second level menu -->
        <ul
          class="menu-content"
          *ngIf="menuItem.submenu.length > 0"
          [@slideInOut]="activeTitles.includes(menuItem.title) ? true : false"
        >
          <li
            appSidebarlink
            level="{{ depth + 2 }}"
            (toggleEmit)="handleToggle($event)"
            [routePath]="menuSubItem.path"
            [classes]="menuSubItem.class"
            [title]="menuSubItem.title"
            [parent]="menuItem.title"
            *ngFor="let menuSubItem of menuItem.submenu"
            [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'"
            [ngClass]="{
              'has-sub': menuSubItem.class === 'has-sub' ? true : false,
              open:
                activeTitles.includes(menuSubItem.title) && !nav_collapsed_open
                  ? true
                  : false,
              'nav-collapsed-open':
                nav_collapsed_open && activeTitles.includes(menuSubItem.title)
            }"
          >
            <a
              appSidebarAnchorToggle
              [routerLink]="
                menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]
              "
              *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock"
            >
              <img
                class="ng-tns-c3-0 ng-star-inserted"
                src="assets/img/gallery/alfred.png"
                style="max-width: 30px"
                *ngIf="menuSubItem.title === 'sidebar.help'"
              />
              <i
                [ngClass]="[menuSubItem.icon]"
                *ngIf="menuSubItem.title !== 'sidebar.help'"
              ></i>
              <span class="menu-title">{{
                menuSubItem.title | translate
              }}</span>
              <span
                *ngIf="menuSubItem.badge !== ''"
                [ngClass]="[menuSubItem.badgeClass]"
                >{{ menuSubItem.badge }}</span
              >
            </a>
            <ng-template #externalSubLinkBlock>
              <a [href]="[menuSubItem.path]">
                <img
                  class="ng-tns-c3-0 ng-star-inserted"
                  src="assets/img/gallery/alfred.png"
                  style="max-width: 30px"
                  *ngIf="menuSubItem.title === 'sidebar.help'"
                />
                <i
                  [ngClass]="[menuSubItem.icon]"
                  *ngIf="menuSubItem.title !== 'sidebar.help'"
                ></i>
                <span class="menu-title">{{
                  menuSubItem.title | translate
                }}</span>
                <span
                  *ngIf="menuSubItem.badge !== ''"
                  [ngClass]="[menuSubItem.badgeClass]"
                  >{{ menuSubItem.badge }}</span
                >
              </a>
            </ng-template>
            <!-- Third level menu -->
            <ul
              class="menu-content"
              *ngIf="menuSubItem.submenu.length > 0"
              [@slideInOut]="
                activeTitles.includes(menuSubItem.title) ? true : false
              "
            >
              <li
                appSidebarlink
                level="{{ depth + 3 }}"
                [routePath]="menuSubsubItem.path"
                [classes]="menuSubsubItem.class"
                [title]="menuSubsubItem.title"
                [parent]="menuSubItem.title"
                *ngFor="let menuSubsubItem of menuSubItem.submenu"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                [ngClass]="[menuSubsubItem.class]"
              >
                <a
                  appSidebarAnchorToggle
                  [routerLink]="[menuSubsubItem.path]"
                  *ngIf="
                    !menuSubsubItem.isExternalLink;
                    else externalSubSubLinkBlock
                  "
                >
                  <img
                    class="ng-tns-c3-0 ng-star-inserted"
                    src="assets/img/gallery/alfred.png"
                    style="max-width: 30px"
                    *ngIf="menuSubsubItem.title === 'sidebar.help'"
                  />
                  <i
                    [ngClass]="[menuSubsubItem.icon]"
                    *ngIf="menuSubsubItem.title !== 'sidebar.help'"
                  ></i>
                  <span class="menu-title">{{
                    menuSubsubItem.title | translate
                  }}</span>
                  <span
                    *ngIf="menuSubsubItem.badge !== ''"
                    [ngClass]="[menuSubsubItem.badgeClass]"
                    >{{ menuSubsubItem.badge }}</span
                  >
                </a>
                <ng-template #externalSubSubLinkBlock>
                  <a [href]="[menuSubsubItem.path]">
                    <img
                      class="ng-tns-c3-0 ng-star-inserted"
                      src="assets/img/gallery/alfred.png"
                      style="max-width: 30px"
                      *ngIf="menuSubsubItem.title === 'sidebar.help'"
                    />
                    <i
                      [ngClass]="[menuSubsubItem.icon]"
                      *ngIf="menuSubsubItem.title !== 'sidebar.help'"
                    ></i>
                    <span class="menu-title">{{
                      menuSubsubItem.title | translate
                    }}</span>
                    <span
                      *ngIf="menuSubsubItem.badge !== ''"
                      [ngClass]="[menuSubsubItem.badgeClass]"
                      >{{ menuSubsubItem.badge }}</span
                    >
                  </a>
                </ng-template>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <hr />

      <!-- Second menu -->
      <li>
        <div
            *ngIf="isGroupAdmin"
            class="cursor-pointer show-auth menu-title"
            style="gap: 5px; display: none;"
            (click)="goToRequests()"
          >
            <span>
              {{ "common.authorizations" | translate }}
            </span>
            <span
              *ngIf="authService && authService.totalAuthorizations > 0"
              class="notification"
            >
              {{ authService.totalAuthorizations }}
            </span>
          </div>
      </li>

      <li
        appSidebarlink
        level="{{ depth + 1 }}"
        (toggleEmit)="handleToggle($event)"
        [classes]="menuItem.class"
        [title]="menuItem.title"
        [parent]=""
        *ngFor="let menuItem of optionItems; let i = index"
        [ngClass]="{
          'has-sub': menuItem.class === 'has-sub' ? true : false,
          open:
            activeTitles.includes(menuItem.title) && !nav_collapsed_open
              ? true
              : false,
          'nav-collapsed-open':
            nav_collapsed_open && activeTitles.includes(menuItem.title)
        }"
        [routerLinkActive]="'active'"
        id="{{ 'option-' + i }}"
        class="sidebar-link"
      >
        <a
          id="option"
          appSidebarAnchorToggle
          [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
          *ngIf="!menuItem.isExternalLink; else externalLinkBlock"
        >
          <div [ngClass]="menuItem.selected ? 'icon-bcg icon-bcg--selected' : 'icon-bcg'" >
            <img
              src="assets/img/svg/sidebar/{{
                menuItem.selected ? menuItem.iconSelected : menuItem.icon
              }}.svg"
            />
          </div>  
          <span class="menu-title">{{ menuItem.title | translate }}</span>
          <span
            *ngIf="menuItem.badge !== ''"
            [ngClass]="[menuItem.badgeClass]"
            >{{ menuItem.badge }}</span
          >
        </a>
        <ng-template #externalLinkBlock>
          <a id="option" [href]="[menuItem.path]" target="_blank">
            <div [ngClass]="menuItem.selected ? 'icon-bcg icon-bcg--selected' : 'icon-bcg'" >
              <img
                src="assets/img/svg/sidebar/{{
                  menuItem.selected ? menuItem.iconSelected : menuItem.icon
                }}.svg"
              />
            </div>
            <span class="menu-title">{{ menuItem.title | translate }}</span>
            <span
              *ngIf="menuItem.badge !== ''"
              [ngClass]="[menuItem.badgeClass]"
              >{{ menuItem.badge }}</span
            >
          </a>
        </ng-template>
      </li>

      <!-- ESTO NO ESTÁ CONTEMPLADO EN EL DISEÑO PERO ES IMPORTANTE HAY QUE HABLARLO CON CARMEN-->
      <li
        *ngIf="userType === 'EMPLOYEE'"
        (click)="editConfig()"
        id="liConfig"
        class="col-12"
      >
        <a>
          <i class="ft-settings"></i>
          <span class="menu-title">{{
            "employee.config.config" | translate
          }}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
