import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { GroupRestriction } from "@core/models";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-send-email-users",
  templateUrl: "./send-email-users.component.html",
  styleUrls: ["./send-email-users.component.scss"],
})
export class SendEmailUsersComponent implements OnInit {
  @Input() loading: Boolean;
  @Input() groups: GroupRestriction[];
  emails: string[] = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  selectedGroup: GroupRestriction;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  form: UntypedFormGroup;
  @Output() sendInfo = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      emails: ["", [Validators.required]],
      group: ["", []],
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our email
    if ((value || "").trim()) {
      this.emails.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  remove(email): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }
  onSubmit() {
    const data = {
      emails: this.emails,
      group: this.selectedGroup,
    };
    this.sendInfo.emit(data);
    this.closeModal();
  }

  onGroupChange(group) {
    this.selectedGroup = group;
  }

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }
}
