import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CompanyService, NgxToastrService } from "@services";
import { Subscription } from "rxjs";

@Component({
  selector: "app-savings-satisfaction-form",
  templateUrl: "./savings-satisfaction-form.component.html",
})
export class SavingsSatisfactionFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  @Input() companyUuid: string;
  @Input() savings;
  @Input() satisfaction;
  @Output() sendInfo = new EventEmitter<any>();
  @Input() isSuperAdmin;

  optimizationSubscriptions: Subscription[] = [];
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    private ngxToastrService: NgxToastrService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      savingHotel: [
        this.savings && this.savings !== null && this.savings.savingHotel
          ? this.savings.savingHotel
          : 20,
        [Validators.required],
      ],
      savingFlight: [
        this.savings && this.savings !== null && this.savings.savingFlight
          ? this.savings.savingFlight
          : 10,
        [Validators.required],
      ],
      savingTrain: [
        this.savings && this.savings !== null && this.savings.savingTrain
          ? this.savings.savingTrain
          : 5,
        [Validators.required],
      ],
      savingManagement: [
        this.savings && this.savings !== null && this.savings.savingManagement
          ? this.savings.savingManagement
          : 25,
        [Validators.required],
      ],
      savingCO2: [
        this.savings && this.savings !== null && this.savings.savingCO2
          ? this.savings.savingCO2
          : 10,
        [Validators.required],
      ],
      savingSupport: [
        this.savings && this.savings !== null && this.savings.savingSupport
          ? this.savings.savingSupport
          : 20,
        [Validators.required],
      ],
      savingTime: [
        this.savings && this.savings !== null && this.savings.savingTime
          ? this.savings.savingTime
          : 10,
        [Validators.required],
      ],
      commonValue1: [
        this.satisfaction &&
        this.satisfaction !== null &&
        this.satisfaction.commonValue1
          ? this.satisfaction.commonValue1
          : 3,
        [Validators.required],
      ],
      commonValue2: [
        this.satisfaction &&
        this.satisfaction !== null &&
        this.satisfaction.commonValue2
          ? this.satisfaction.commonValue2
          : 4,
        [Validators.required],
      ],
      commonValue3: [
        this.satisfaction &&
        this.satisfaction !== null &&
        this.satisfaction.commonValue3
          ? this.satisfaction.commonValue3
          : 5,
        [Validators.required],
      ],
      mainValue1: [
        this.satisfaction &&
        this.satisfaction !== null &&
        this.satisfaction.mainValue1
          ? this.satisfaction.mainValue1
          : 4,
        [Validators.required],
      ],
      mainValue2: [
        this.satisfaction &&
        this.satisfaction !== null &&
        this.satisfaction.mainValue2
          ? this.satisfaction.mainValue2
          : 5,
        [Validators.required],
      ],
      mainValue3: [
        this.satisfaction &&
        this.satisfaction !== null &&
        this.satisfaction.mainValue3
          ? this.satisfaction.mainValue3
          : 6,
        [Validators.required],
      ],
      vyooValue1: [
        this.satisfaction &&
        this.satisfaction !== null &&
        this.satisfaction.vyooValue1
          ? this.satisfaction.vyooValue1
          : 8,
        [Validators.required],
      ],
      vyooValue2: [
        this.satisfaction &&
        this.satisfaction !== null &&
        this.satisfaction.vyooValue2
          ? this.satisfaction.vyooValue2
          : 9,
        [Validators.required],
      ],
      vyooValue3: [
        this.satisfaction &&
        this.satisfaction !== null &&
        this.satisfaction.vyooValue3
          ? this.satisfaction.vyooValue3
          : 10,
        [Validators.required],
      ],
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    const savingData = {
      companyUuid: this.companyUuid,
      savingHotel: this.f.savingHotel.value,
      savingFlight: this.f.savingFlight.value,
      savingTrain: this.f.savingTrain.value,
      savingManagement: this.f.savingManagement.value,
      savingCO2: this.f.savingCO2.value,
      savingSupport: this.f.savingSupport.value,
      savingTime: this.f.savingTime.value,
    };

    const satisfactionData = {
      commonValue1: this.f.commonValue1.value,
      commonValue2: this.f.commonValue2.value,
      commonValue3: this.f.commonValue3.value,
      mainValue1: this.f.mainValue1.value,
      mainValue2: this.f.mainValue2.value,
      mainValue3: this.f.mainValue3.value,
      vyooValue1: this.f.vyooValue1.value,
      vyooValue2: this.f.vyooValue2.value,
      vyooValue3: this.f.vyooValue3.value,
    };

    if (!this.savings && !this.satisfaction) {
      this.optimizationSubscriptions.push(
        !this.isSuperAdmin
          ? this.companyService
              .postSaving(this.companyUuid, savingData)
              .subscribe(
                (s) => {
                  this.router.navigateByUrl("/");
                },
                (err) => {
                  this.translate.get("optimization.error").subscribe((resp) => {
                    this.ngxToastrService.typeInfo(null, err);
                  });
                }
              )
          : this.companyService.postSatisfaction(satisfactionData).subscribe(
              (s) => {
                this.translate.get("optimization.success").subscribe((resp) => {
                  this.ngxToastrService.typeSuccess(null, resp);
                  this.router.navigateByUrl("/");
                });
                this.activeModal.dismiss();
              },
              (err) => {
                this.translate.get("optimization.error").subscribe((resp) => {
                  this.ngxToastrService.typeInfo(null, err);
                });
              }
            )
      );
    } else {
      this.optimizationSubscriptions.push(
        !this.isSuperAdmin
          ? this.companyService
              .putSaving(this.companyUuid, this.savings.uuid, savingData)
              .subscribe(
                (s) => {
                  this.router.navigateByUrl("/");
                },
                (err) => {
                  this.translate.get("optimization.error").subscribe((resp) => {
                    this.ngxToastrService.typeInfo(null, resp);
                  });
                }
              )
          : this.companyService
              .putSatisfaction(this.satisfaction.uuid, satisfactionData)
              .subscribe(
                (s) => {
                  this.translate
                    .get("optimization.success")
                    .subscribe((resp) => {
                      this.ngxToastrService.typeSuccess(null, resp);
                    });
                  this.activeModal.dismiss();
                  this.router.navigateByUrl("/");
                },
                (err) => {
                  this.translate.get("optimization.error").subscribe((resp) => {
                    this.ngxToastrService.typeInfo(null, resp);
                  });
                }
              )
      );
    }
  }

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }

  ngOnDestroy() {
    this.optimizationSubscriptions.forEach((s) => s.unsubscribe());
  }
}
