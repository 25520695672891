import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-traveler-llego",
  templateUrl: "./traveler-llego.component.html",
  styleUrls: ["./traveler-llego.component.scss"],
})
export class TravelerLlegoComponent implements OnInit {
  travelers = 1;
  travelersAge: number[] = [];
  travelersAgeForm: UntypedFormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number[],
    public dialogRef: MatDialogRef<TravelerLlegoComponent>,
    private formBuilder: UntypedFormBuilder
  ) {
    this.travelersAgeForm = this.formBuilder.group({
      ages: new UntypedFormArray([]),
    });
  }

  ngOnInit() {
    if (this.data) {
      this.travelers = this.data.length;
      this.data.forEach((age) => {
        this.ages.push(
          this.formBuilder.group({
            age: [
              age,
              [Validators.required, Validators.min(0), Validators.max(99)],
            ],
          })
        );
      });
    }
  }

  get form() {
    return this.travelersAgeForm.controls;
  }
  get ages() {
    return this.form.ages as UntypedFormArray;
  }
  get agesFormGroups() {
    return this.ages.controls as UntypedFormGroup[];
  }

  onChangeTravelers() {
    if (this.ages.length < this.travelers) {
      for (let i = this.ages.length; i < this.travelers; i++) {
        this.ages.push(
          this.formBuilder.group({
            age: [
              null,
              [Validators.required, Validators.min(0), Validators.max(99)],
            ],
          })
        );
      }
    } else {
      for (let i = this.ages.length; i >= this.travelers; i--) {
        this.ages.removeAt(i);
      }
    }
  }

  addTraveler() {
    if (this.travelers < 10) {
      this.travelers++;
      this.onChangeTravelers();
    }
  }
  removeTraveler() {
    if (this.travelers - 1 > 0) {
      this.travelers--;
      this.onChangeTravelers();
    }
  }

  onSubmit() {
    for (let i = 0; i < this.ages.value.length; i++) {
      this.travelersAge.push(this.ages.value[i].age);
    }
    this.dialogRef.close(this.travelersAge);
  }
}
