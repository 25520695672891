import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import { RequestUtilsService } from "@core/services";
import { Subscription } from "rxjs";

@Component({
  selector: "app-custom-request-details",
  templateUrl: "./custom-request-details.component.html",
  styleUrls: ["./custom-request-details.component.scss"],
})
export class CustomRequestDetailsComponent implements OnInit {
  @Input() hasPrivileges: boolean;
  @Input() request: any;
  @Input() user: any;
  icon: string;
  comments: string;
  sendInfo: EventEmitter<boolean> = new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];
  constructor(private requestUtilsService: RequestUtilsService) {}

  ngOnInit(): void {
    this.request = JSON.parse(JSON.stringify(this.request));
    this.getIcon();
    if (this.request.type?.toLowerCase() === "hotel") {
      if (this.request.comments.includes("+ (rooms)")) {
        this.request.rooms =
          this.request.comments.length > 0
            ? this.request.comments
              .split("+ (rooms):")[1]
              .split("+ (people):")[0]
              .trim()
            : "";
        this.request.people = 
          this.request.comments.length > 0
            ? this.request.comments.split("+ (people):")[1].trim()
            : "";
        this.request.comments =
          this.request.comments.length > 0
            ? this.request.comments
              .split("(comments):")[1]
              .split("+ (rooms):")[0]
              .trim()
            : "";
      }
    } 
  }

  getIcon() {
    switch (this.request.type?.toLowerCase()) {
      case "hotel":
        this.icon = "assets/img/svg/building.svg";
        break;
      case "flight":
        this.icon = "assets/img/svg/flight-black.svg";
        break;
      case "car":
        this.icon = "assets/img/svg/car-black.svg";
        break;
      case "train":
        this.icon = "assets/img/svg/sidebar/train.svg";
        break;
      case "airbnb":
        this.icon = "assets/img/svg/airbnb.svg";
        break;
      default:
        break;
    }
  }

  addBook() {
    switch (this.request.type?.toLowerCase()) {
      case "hotel":
        this.requestUtilsService.createCustomHotel(
          this.request,
          this.hasPrivileges
        );
        break;
      case "flight":
        this.requestUtilsService.createCustomFlightTrain(
          this.request,
          "flight",
          this.hasPrivileges
        );
        break;
      case "train":
        this.requestUtilsService.createCustomFlightTrain(
          this.request,
          "train",
          this.hasPrivileges
        );
        break;
      case "car":
        if (this.request.price) {
          this.request.amount = this.request.price;
          delete this.request.price;
        }
        this.requestUtilsService.createCustomCar(
          this.request,
          this.hasPrivileges
        );
        break;
      case "airbnb":
        this.requestUtilsService.createCustomAirbnb(
          this.request,
          this.hasPrivileges
        );
      break;
      default:
        break;
    }
  }

  isValidDate(date: any): boolean {
    return date && !isNaN(Date.parse(date));
  }
}
