import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { User } from "@core/models";
import { CompanyService, UserService } from "@core/services";
import { NgxToastrService } from "@core/services/ngx.toastr.service";
import { TravelUtilsService } from "@core/services/travel-utils.service";
import { UtilsService } from "@core/services/utils.service";
import {
  NgbActiveModal,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "app/app.reducer";
import cities from "assets/utils/cities.json";
import moment from "moment";
import { Subscription, take } from "rxjs";

@Component({
  selector: "app-create-car",
  templateUrl: "./create-car.component.html",
  styleUrls: ["./create-car.component.scss"],
})
export class CreateCarComponent implements OnInit {
  type = "car";
  @Input() isAdmin;
  @Input() loading: Boolean;
  @Input() companyUuid: string;
  @Input() userId: string;
  @Input() data;
  @Input() isEdit: boolean;
  @Input() blobUrl?: any;
  @Input() fileBack?: any;
  @Output() sendInfo = new EventEmitter<any>();
  request: any;

  editing = false;
  date = new Date();
  citiesList = [];
  file: File;
  timeDataDepart: any;
  timeDataReturn: any;
  departDataDate: any;
  returnDataDate: any;

  form: UntypedFormGroup;

  bodyTravel: any = {};
  minDateNgStruct = {
    year: this.date.getFullYear() - 1,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  titleSelect = "";
  pickupOptions = [];
  carType = [];
  translationsDone = false;
  subcriptions: Subscription[] = [];
  user: User;
  isInvalid: boolean = false
  userType;
  companyUsers: { name: string; lastname: string; uuid: string }[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    private travelUtilsService: TravelUtilsService,
    private ngbDateParser: NgbDateParserFormatter,
    private utilsService: UtilsService,
    private formBuilder: UntypedFormBuilder,
    private ngxToastrService: NgxToastrService,
    private userService: UserService,
    private store: Store<AppState>,
    private companyService: CompanyService
  ) {
    this.subcriptions.push(
      this.translate
        .get([
          "car.custom.request.carType.suv",
          "car.custom.request.carType.touring",
          "car.custom.request.carType.van",
          "car.custom.request.pickup.title",
          "car.custom.request.pickup.airport",
          "car.custom.request.pickup.trainStation",
          "car.custom.request.pickup.office",
          "car.custom.request.carType.touring",
          "car.custom.request.carType.suv",
          "car.custom.request.carType.van",
        ])
        .subscribe((result) => {
          this.pickupOptions = [
            {
              value: "airport",
              viewValue: result["car.custom.request.pickup.airport"],
            },
            {
              value: "trainStation",
              viewValue: result["car.custom.request.pickup.trainStation"],
            },
            {
              value: "office",
              viewValue: result["car.custom.request.pickup.office"],
            },
          ];

          this.carType = [
            {
              value: "suv",
              viewValue: result["car.custom.request.carType.suv"],
            },
            {
              value: "touring",
              viewValue: result["car.custom.request.carType.touring"],
            },
            {
              value: "van",
              viewValue: result["car.custom.request.carType.van"],
            },
          ];

          this.titleSelect = result["car.custom.request.pickup.title"];

          this.translationsDone = true;
        })
    );
  }

  ngOnInit(): void {
    this.data && this.data !== null
      ? (this.editing = true)
      : (this.editing = false);

    this.store
    .select("auth")
    .pipe(take(1))
    .subscribe((res) => {
      if (res.isAuthenticated) {
        this.userType = res.user.type;
      }
    });
    this.form = this.formBuilder.group({
      file: [
        this.editing ? this.data.file : "",
        this.blobUrl && this.fileBack ? [] : [],
      ],
      from: [
        { value: this.editing ? this.data.from : this.request ? this.request.from : "", disabled: this.userType != "EMPLOYEE" && this.isEdit},
        [Validators.required],
      ],
      to: [
        { value: this.editing ? this.data.to : this.request ? this.request.to : "", disabled: this.userType != "EMPLOYEE" && this.isEdit},
        [Validators.required],
      ],
      pickupPlaceFrom: [
        { value: this.editing
          ? this.data.pickupPlaceFrom
          : this.request
          ? this.request.pickupPlaceFrom
          : "", disabled: this.userType != "EMPLOYEE" && this.isEdit},
        [Validators.required],
      ],
      pickupPlaceTo: [
        { value: this.editing
          ? this.data.pickupPlaceTo
          : this.request
          ? this.request.pickupPlaceTo
          : "", disabled: this.userType != "EMPLOYEE" && this.isEdit},
        [Validators.required],
      ],

      price: [
        {
          value: this.editing
          ? this.data?.price?.toFixed(2) || this.data?.amount?.toFixed(2)
          : this.request
          ? this.request.amount
            ? this.request.amount.toFixed(2)
            : ""
          : "",
          disabled: this.data?.payment || this.data?.refund || (this.userType != "EMPLOYEE" && this.isEdit),
        },
        [Validators.required],
      
      ],
      age: [
        { value: this.editing ? this.data.age : this.request ? this.request.age : "", disabled: this.userType != "EMPLOYEE" && this.isEdit},
        [Validators.required, Validators.min(18)],
      ],
      departDate: [
        this.editing ? this.ngbDateParser.parse(
          moment(this.data.departDate).toString()
        ) : "",
        [Validators.required],
      ],
      returnDate: [
        this.editing ? this.ngbDateParser.parse(
          moment(this.data.returnDate).toString()
        ) : "",
        [Validators.required],
      ],
      timeReturn: [
        { value: this.editing ? {
          hour: new Date(this.data.returnDate).getHours(),
          minute: new Date(this.data.returnDate).getMinutes(),
        } : "", disabled: this.userType != "EMPLOYEE" && this.isEdit},
        [Validators.required],
      ],
      timeDepart: [
        { value: this.editing ? {
          hour: new Date(this.data.departDate).getHours(),
          minute: new Date(this.data.departDate).getMinutes(),
        } : "", disabled: this.userType != "EMPLOYEE" && this.isEdit},
        [Validators.required],
      ],
      provider: [{ value: this.editing ? this.data.provider : "", disabled: this.userType != "EMPLOYEE" && this.isEdit}, [Validators.required]],
      carType: [
        { value: this.editing
          ? this.data.carType
          : this.request
          ? this.request.carType
          : "", disabled: this.userType != "EMPLOYEE" && this.isEdit},
        [Validators.required],
      ],
      insuranceWithExcess: [
        { value: this.editing
          ? this.data.insuranceWithExcess
          : this.request
          ? this.request.insuranceWithExcess
          : false, disabled: this.userType != "EMPLOYEE" && this.isEdit}
      ],
      freeTaxes: [{ value: this.editing ? this.data.freeTaxes : false, disabled: this.userType != "EMPLOYEE" && this.isEdit}],
      comments: [{ value: this.editing ? this.data.comments : "", disabled: this.userType != "EMPLOYEE" && this.isEdit}],
      customCode: [
        this.editing
          ? this.data.customCode != null
            ? this.data.customCode
            : null
          : "",
      ],
      costCenter: [
        this.editing
          ? this.data.costCenter != null
            ? this.data.costCenter
            : null
          : "",
      ],
    });

    if (this.editing) {
      this.form.addControl('passenger', new FormControl({ value: this.data ? this.data.userUuid : '', disabled: !(this.userType?.toLowerCase() === 'employee' || this.userType?.toLowerCase() === 'company') }));
    }

    if (this.form.value.departDate) {
      this.form.patchValue({ departDate: { day: this.form.value.departDate.day, month: this.form.value.departDate.month + 1, year: this.form.value.departDate.year } })
    }
    if (this.form.value.returnDate) {
      this.form.patchValue({ returnDate: { day: this.form.value.returnDate.day, month: this.form.value.returnDate.month + 1, year: this.form.value.returnDate.year } })
    }

    if (this.request) {
      const formattedInitDate = moment(this.request.initDate).format("DD/MM/YYYY");
      const formattedEndDate = moment(this.request.endDate).format("DD/MM/YYYY");

      const valueInit = {
        year: moment(formattedInitDate, "DD/MM/YYYY").year(),
        month: moment(formattedInitDate, "DD/MM/YYYY").month() + 1,
        day: moment(formattedInitDate, "DD/MM/YYYY").date(),
      };
      this.form.patchValue({ departDate: valueInit });

      const initDate = new Date(this.request.initDate);
      const hour = initDate.getHours();
      const minute = initDate.getMinutes();
      const timeInit = {
        hour: hour,
        minute: minute
      };
      this.form.patchValue({ timeDepart: timeInit });

      if (this.request.endDate) {
        const valueEnd = {
          year: moment(formattedEndDate, "DD/MM/YYYY").year(),
          month: moment(formattedEndDate, "DD/MM/YYYY").month() + 1,
          day: moment(formattedEndDate, "DD/MM/YYYY").date(),
        };
        this.form.patchValue({ returnDate: valueEnd });

        const endDate = new Date(this.request.endDate);
        const hour = endDate.getHours();
        const minute = endDate.getMinutes();
        const timeEnd = {
          hour: hour,
          minute: minute
        };
        this.form.patchValue({ timeReturn: timeEnd });
      }
    }
    if (this.userId) {
      this.userService
        .getUser(this.userId)
        .pipe(take(1))
        .subscribe((user: User) => {
          this.user = user;
        });
    }

    if(this.editing && this.utilsService.isAdmin(this.userType?.toLowerCase())){
      this.getCompanyUsers();
    }
  }

  onSubmit() {
    this.isInvalid = false;
    this.loading = true;
    const body = this.form.getRawValue();
    if (!body["file"] && this.fileBack) {
      body["file"] = this.fileBack;
    } else {
      body["file"] = this.file;
    }
    const travel = this.travelUtilsService.getTravelLocalStorage();
    const dateDepartureAux = moment(
      this.ngbDateParser.format(this.f.departDate.value),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const dateReturnAux = moment(
      this.ngbDateParser.format(this.f.returnDate.value),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    body["departDate"] = moment(
      dateDepartureAux +
        " " +
        (
          "0" + (this.f.timeDepart.value ? this.f.timeDepart.value.hour : "00")
        ).slice(-2) +
        ":" +
        (
          "0" +
          (this.f.timeDepart.value ? this.f.timeDepart.value.minute : "00")
        ).slice(-2)
    );
    body["returnDate"] = moment(
      dateReturnAux +
        " " +
        (
          "0" + (this.f.timeReturn.value ? this.f.timeReturn.value.hour : "00")
        ).slice(-2) +
        ":" +
        (
          "0" +
          (this.f.timeReturn.value ? this.f.timeReturn.value.minute : "00")
        ).slice(-2)
    );
    if (this.userId) {
      body["userUuid"] = this.userId;
    } else {
      body["userUuid"] = this.data.userUuid;
    }
    if (travel && travel.title) {
      body["travelName"] = travel.title;
    }
    if (body.price) {
      body["price"] = parseFloat(
        body.price.toString().replace(",", ".")
      ).toFixed(2);
    }

    if(this.request?.traveller) {
      body['traveller'] = this.request?.traveller;
    } else if (this.user) {
      let traveller = [];
      traveller.push({name: this.user.name, surname: this.user.lastname});
      body['traveller'] = traveller;
    }
    
    this.sendInfo.emit(body);
  }

  get f() {
    return this.form.controls;
  }

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }

  getOutDate() {
    return this.f.departDate ? this.f.departDate.value : this.minDateNgStruct;
  }

  departureBeforeReturn(dateDepartureAux, dateReturnAux) {
    return this.utilsService.departureBeforeReturn(
      dateDepartureAux,
      dateReturnAux,
      this.f
    );
  }

  getCities(value: string) {
    this.citiesList = [];
    if (value !== "" && value.length > 2) {
      cities.Table1.forEach((v) => {
        if (
          v["name"]?.toLowerCase().includes(value?.toLowerCase()) &&
          !this.citiesList.includes(v.name)
        ) {
          this.citiesList.push(v["name"]);
        }
      });
    } else {
      this.citiesList = [];
    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0 && this.userType != "USER") {

      if(this.isEdit && this.userType != "EMPLOYEE"){
        return
      }else{
        this.file = event.target.files[0];
      }
    }
  }

  handleTravel(dataTravel: any) {
    this.bodyTravel = dataTravel;
    this.travelUtilsService.setTravelLocalStorage(this.bodyTravel);
    this.translate.get(["project.form.saved"]).subscribe((resp) => {
      this.ngxToastrService.typeSuccess(resp["project.form.saved"], "");
    });
  }

  cleanCitiesList() {
    this.citiesList = [];
  }

  getCompanyUsers() {
    this.companyService.getCompanyUsers(this.companyUuid).subscribe(
      (res) => {
        this.companyUsers = res.map(user => ({
          name: user.name,
          lastname: user.lastname,
          uuid: user.uuid
        }));
  
        if (this.data) {
          this.form.patchValue({
            passenger: this.data.userUuid
          });
        }
      },
      (err) => {
        console.error('Error fetching users:', err);
      }
    );
  }

}
