<div class="modal-header">
  <h4 class="modal-title heading-5" id="modal-basic-title">
    {{ (editing ? "expenses.edit" : "expenses.create") | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  <!-- <div class="row">
    <div class="col-12">
      <app-create-travel-modal
        [customBooking]="true"
        [userUuid]="userUuid"
        (sendInfo)="handleTravel($event)"
      >
      </app-create-travel-modal>
    </div>
  </div> -->
  <form
    [formGroup]="form"
    [ngClass]="{ 'loading-background': loading }"
    (ngSubmit)="form.valid && !loading ? onSubmit() : (isInvalid = true)"
  >
    <div class="row mt-0" *ngIf="editing && (userType?.toLowerCase() === 'employee' || userType?.toLowerCase() === 'company')">
      <div class="col-12">
        <div class="form-group">
          <label class="pt-0" for="passenger">{{ "common.traveller" | translate }}</label>
          <select
            formControlName="passenger"
            id="passenger"
            class="form-control custom-select"
          >
          <option *ngFor="let user of companyUsers" [value]="user.uuid">
            {{ user.name }} {{ user.lastname }}
          </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-6">
        <div class="form-group">
          <label>{{ "expenses.document" | translate }}</label>
          <div>
            <input
              formControlName="file"
              type="file"
              id="file"
              class="inputFile"
              (change)="onFileChange($event)"
              accept="application/pdf"
            />
            <label for="file" class="px-3 py-1 labelFile" [class.disabled]="userType !== 'EMPLOYEE'">{{
              "common.choose-file" | translate
            }}</label>
            <label class="pl-3" *ngIf="file">{{ file.name }}</label>
          </div>
        </div>
      </div>
      <div class="col-6 checkbox">
        <mat-checkbox
          class="example-margin"
          [formControl]="form.controls['freeTaxes']"
        >
          <small>{{ "expenses.freeTaxes" | translate }}</small>
        </mat-checkbox>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-6">
        <div class="form-group">
          <div>
            <input
              formControlName="type"
              placeholder="{{ 'expenses.type' | translate }}"
              type="text"
              id="type"
              class="form-control"
              [ngClass]="{
                'input-error':
                  !form.controls.type.valid && isInvalid
              }"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <div>
            <input
              formControlName="price"
              placeholder="{{ 'expenses.price' | translate }}"
              type="number"
              id="price"
              [readonly]="expense && expense.amount"
              class="form-control"
              [ngClass]="{
                'input-error':
                  !form.controls.price.valid && isInvalid && !form.controls.price.disabled
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-6">
        <div class="form-group">
          <div class="form-group d-flex align-items-center">
            <div class="input-group">
              <input
                [disabled]="this.userType != 'EMPLOYEE'"
                id="date_form"
                class="form-control filter-dates-placeholder"
                readonly
                placeholder="{{ 'authorization.labels.from' | translate }}"
                name="dpInput"
                formControlName="beginDate"
                ngbDatepicker
                #dInput="ngbDatepicker"
                (click)="dInput.toggle()"
                [maxDate]="getOutMaxDate()"
                [ngClass]="{
                  'input-error':
                    !form.controls.beginDate.valid && isInvalid
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <div class="input-group">
            <input
              [disabled]="this.userType != 'EMPLOYEE'"
              id="date_to"
              class="form-control filter-dates-placeholder"
              readonly
              placeholder="{{ 'authorization.labels.to' | translate }}"
              name="dpOutput"
              formControlName="endDate"
              ngbDatepicker
              #dOutput="ngbDatepicker"
              (click)="dOutput.toggle()"
              [minDate]="getOutMinDate()"
              [ngClass]="{
                'input-error':
                  !form.controls.endDate.valid && isInvalid
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <input
            formControlName="customCode"
            placeholder="{{ 'common.custom-code' | translate }}"
            type="text"
            id="customCode"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <input
            formControlName="costCenter"
            placeholder="{{ 'common.cost-center' | translate }}"
            type="text"
            id="costCenter"
            class="form-control"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            class="custom-btn mb-0"
            ngbAutofocus
            mat-button
            [ngStyle]="{
              cursor:
                !form.valid || loading ? 'not-allowed' : 'pointer'
            }"
            [ngClass]="{
              'disabled-button': !form.valid || loading
            }"
          >
            {{ "common.send" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="row" *ngIf="loading">
    <app-loading [userType]="'employee'"></app-loading>
  </div>
</div>
