import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import {
  Agency,
  Company,
  Employee,
  GroupRestriction,
  Hub,
  User,
} from "@models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AgencyService,
  AuthService,
  BookedService,
  CompanyService,
  ConfigService,
  ExcelService,
  GroupService,
  HubService,
  LanguageService,
  MediaService,
  NgxToastrService,
  UserService,
  UtilsService,
} from "@services";
import { AppState } from "app/app.reducer";
import { SendEmailUsersComponent } from "app/modules/businessEntities/user/send-email-users/send-email-users.component";
import { ConfigComponent } from "app/modules/components/config/config.component";
import { GraphComponent } from "app/modules/components/graph/graph/graph.component";
import * as fromAuthActions from "app/store/actions/auth.actions";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import swal from "sweetalert2";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, AfterViewInit {
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  @ViewChild("userIcon") userIcon: ElementRef;
  @ViewChild("requestItem") requestItem: ElementRef;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();
  userOrEmployee: User | Employee | Company | Agency | Hub;
  language: string;
  companyUuid = "";
  hubId;
  partnerId;
  public config: any = {};
  subcriptions: Subscription[] = [];
  groupSubscription: Subscription;
  groups: GroupRestriction[] = [];
  user: User;
  employee: Employee;
  isAdmin = false;
  company: Company;
  agency: Agency;
  hub: Hub;
  canUseTRM: boolean;
  canInvite = false;
  groupUuid: string;
  canGoLocalsfy: boolean;
  isGroupAdmin = false;
  params: any = {};
  cardImageBase64: string;
  totalAuthorizations = 0;
  authenticatedUser: any;
  isCompany = false;
  isAgency = false;
  isHub = false;
  isEmployee = false;
  isUser = false;
  isLoadingExcel: boolean = false;
  hubImage: string;
  agencyImage: string;

  constructor(
    public translate: TranslateService,
    private configService: ConfigService,
    private store: Store<AppState>,
    private authService: AuthService,
    private langService: LanguageService,
    private modalService: NgbModal,
    private userService: UserService,
    private ngxToastrService: NgxToastrService,
    private bookedService: BookedService,
    private groupService: GroupService,
    private companyService: CompanyService,
    public utilsService: UtilsService,
    private router: Router,
    private mediaService: MediaService,
    private excelService: ExcelService,
    private hubService: HubService,
    private agencyService: AgencyService,
  ) {
    this.getProfile();
    this.params["status"] = "request";
    this.utilsService.imageChanged$.subscribe((image) => {
      if (image && image !== "undefined") {
        this.subcriptions.push(
          this.mediaService.getMedia(image).subscribe((s) => {
            const reader = new FileReader();
            const reader1 = new FileReader();
            reader.readAsDataURL(s);
            let base64data;
            reader.onload = () => {
              base64data = reader.result;
              this.cardImageBase64 = base64data;
            };
            reader1.readAsArrayBuffer(s);
          })
        );
      } else {
        this.cardImageBase64 = null;
      }
    });

    this.utilsService.userChanged$.subscribe((changed) => {
      if (changed) {
        this.authService.getProfile().subscribe((res: User) => {
          this.authenticatedUser = res;
        });
      }
    });
  }

  ngOnInit() {
    this.language = this.langService.getLanguage();
    this.config = this.configService.templateConf;
    this.subcriptions.push(
      this.store.select("auth").subscribe((u: any) => {
        this.authenticatedUser = u.user;
        if (u.user && u.user.type?.toUpperCase() === "USER") {
          this.user = u.user;
          this.isUser = true;
          this.canUseTRM = u.user.type
            ? u.user.plan && u.user.plan.permissions.canUseTRM
            : u.user.user.plan && u.user.user.plan.permissions.canUseTRM;
          if (this.user.isGroupAdmin) {
            this.getAuthorizationList();
          }
        } else if (
          u.user &&
          (u.user.type
            ? u.user.type?.toUpperCase() === "COMPANY"
            : u.user.user.accessType?.toUpperCase() === "COMPANY")
        ) {
          this.companyUuid = u.user.companyUuid;
          this.canUseTRM = u.user.type
            ? u.user.plan && u.user.plan.permissions.canUseTRM
            : u.user.user.plan && u.user.user.plan.permissions.canUseTRM;
          this.canInvite = !this.canUseTRM;
          this.company = u.user;
          this.isCompany = true;
          this.groupUuid = u.user.groupRestrictionUuid
            ? u.user.groupRestrictionUuid
            : u.user.user.groupRestrictionUuid;
          this.canGoLocalsfy = u.user.type
            ? u.user.plan && u.user.plan.permissions.canUseLocalsfy
            : u.user.user.plan && u.user.user.plan.permissions.canUseLocalsfy;
          this.getAuthorizationList();
        } else if (
          u.user &&
          (u.user.type
            ? u.user.type?.toUpperCase() === "AGENCY"
            : u.user.user.accessType?.toUpperCase() === "AGENCY")
        ) {
          this.agency = u.user;
          this.canUseTRM = u.user.type
            ? u.user.plan && u.user.plan.permissions.canUseTRM
            : u.user.user.plan && u.user.user.plan.permissions.canUseTRM;
          this.canInvite = !this.canUseTRM;
          this.isAgency = true;
          this.canGoLocalsfy = u.user.type
            ? u.user.plan && u.user.plan.permissions.canUseLocalsfy
            : u.user.user.plan && u.user.user.plan.permissions.canUseLocalsfy;
            this.agencyService.getAgency(u.user.agencyUuid).subscribe(
              (agency: Agency) => {
                this.agencyImage = agency.image
                if (this.agencyImage) {
                  this.subcriptions.push(
                    this.mediaService.getMedia(this.agencyImage).subscribe((s) => {
                      const reader = new FileReader();
                      const reader1 = new FileReader();
                      reader.readAsDataURL(s);
                      let base64data;
                      reader.onload = () => {
                        base64data = reader.result;
                        this.cardImageBase64 = base64data;
                      };
                      reader1.readAsArrayBuffer(s);
                    })
                  );
                }
              }
            );
        } else if (
          u.user &&
          (u.user.type
            ? u.user.type?.toUpperCase() === "HUB"
            : u.user.user.accessType?.toUpperCase() === "HUB")
        ) {
          this.hub = u.user;
          this.canUseTRM = u.user.type
            ? u.user.plan && u.user.plan.permissions.canUseTRM
            : u.user.user.plan && u.user.user.plan.permissions.canUseTRM;
          this.canInvite = !this.canUseTRM;
          this.isHub = true;
          this.canGoLocalsfy = u.user.type
            ? u.user.plan && u.user.plan.permissions.canUseLocalsfy
            : u.user.user.plan && u.user.user.plan.permissions.canUseLocalsfy;
            
          this.hubService.getHub(u.user.hubUuid).subscribe(
            (hub: Hub) => {
              this.hubImage = hub.image
              if (this.hubImage) {
                this.subcriptions.push(
                  this.mediaService.getMedia(this.hubImage).subscribe((s) => {
                    const reader = new FileReader();
                    const reader1 = new FileReader();
                    reader.readAsDataURL(s);
                    let base64data;
                    reader.onload = () => {
                      base64data = reader.result;
                      this.cardImageBase64 = base64data;
                    };
                    reader1.readAsArrayBuffer(s);
                  })
                );
              }
            }
          );
        } else if (
          u.user &&
          (u.user.type
            ? u.user.type?.toUpperCase() === "EMPLOYEE"
            : u.user.user.accessType?.toUpperCase() === "EMPLOYEE")
        ) {
          this.employee = u.user;
          this.isEmployee = true;
          this.canUseTRM = u.user.type
            ? u.user.plan && u.user.plan.permissions.canUseTRM
            : u.user.user.plan && u.user.user.plan.permissions.canUseTRM;
          this.canInvite = !this.canUseTRM;
          this.canGoLocalsfy = u.user.type
            ? u.user.plan && u.user.plan.permissions.canUseLocalsfy
            : u.user.user.plan && u.user.user.plan.permissions.canUseLocalsfy;
        }

        if (u.user && u.user.image && u.user.image !== "undefined") {
          this.subcriptions.push(
            this.mediaService.getMedia(u.user.image).subscribe((s) => {
              const reader = new FileReader();
              const reader1 = new FileReader();
              reader.readAsDataURL(s);
              let base64data;
              reader.onload = () => {
                base64data = reader.result;
                this.cardImageBase64 = base64data;
              };
              reader1.readAsArrayBuffer(s);
            })
          );
        }
      })
    );
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      const dir = this.config.layout.dir;
      if (dir === "rtl") {
        this.placement = "bottom-left";
      } else if (dir === "ltr") {
        this.placement = "bottom-right";
      }
    }
  }

  getAuthorizationList() {
    const company: any = this.company;
    if (
      (this.user && this.user.accessType?.toLowerCase() === "company") ||
      (company && company.accessType?.toLowerCase() === "agency")
    ) {
      this.params["companyUuid"] = this.user.companyUuid;
    }
    if (
      (this.user && this.user.isGroupAdmin) ||
      (company && company.isGroupAdmin)
    ) {
      this.params["groupRestrictionUuid"] =
        this.authenticatedUser.groupRestrictionUuid;
    }
    this.subcriptions.push(
      this.bookedService.getAuthorization(this.params).subscribe((result) => {
        if (result && result.length > 0) {
          const requestAuths = result.filter(
            (authorization) => authorization.status === "request"
          );
          if (requestAuths.length > 0) {
            this.authService.totalAuthorizations = result.length;
            this.utilsService.haveRequest = true;
          } else {
            this.utilsService.haveRequest = false;
          }
        } else {
          this.authService.totalAuthorizations = 0;
          this.utilsService.haveRequest = false;
        }
      })
    );
  }
  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  toggleNavbar(){
    const optionsNav = document.getElementsByClassName("options")[0];
    if(optionsNav.classList.contains("show-options")) {
      optionsNav.classList.remove("show-options");
    } else {
      optionsNav.classList.add("show-options");
    }
  }

  logout() {
    const action = new fromAuthActions.LogoutAction();
    this.store.dispatch(action);
    this.authService.userCompanyPlan = null;
    localStorage.removeItem;
  }

  getProfile() {
    this.authService
      .getProfile()
      .subscribe((res: User | Employee | Company | Agency | Hub) => {
        this.userOrEmployee = res;
        this.isGroupAdmin =
          res.type === "USER" && res.isGroupAdmin ? res.isGroupAdmin : false;
      });
  }
  onEditUser(group: GroupRestriction) {
    const navigationUser: NavigationExtras = {
      state: {
        loading: false,
        company: this.company,
        group: group,
        user: this.userOrEmployee,
        fromCompanyDetails: true,
      },
    };

    if (this.userOrEmployee.accessType?.toLowerCase() === "agency") {
      navigationUser.state['agency'] = this.userOrEmployee.uuid;
    }

    if (this.userOrEmployee.accessType?.toLowerCase() === "hub") {
      navigationUser.state['hub'] = this.userOrEmployee.uuid;
    }

    this.router.navigate(["user/user-profile"], navigationUser);

    // this.modalService.open(UserFormComponent, {
    //   size: "lg",
    //   centered: true,
    // });
    // modalRef.componentInstance.loading = false;
    // // modalRef.componentInstance.company = this.company;
    // modalRef.componentInstance.group = group;
    // modalRef.componentInstance.user = this.user;
    // modalRef.componentInstance.fromCompanyDetails = true;

    // if (lodash.get(this.userOrEmployee, "birthdate")) {
    //   this.user.birthdate = momentTz(this.user.birthdate).tz("Europe/Madrid").format("YYYY-MM-DD");
    // }
    // if (lodash.get(this.userOrEmployee, "passportExpires")) {
    //   this.user.passportExpires = momentTz(this.user.passportExpires).tz("Europe/Madrid").format("YYYY-MM-DD");
    // }

    // modalRef.componentInstance.sendInfo.subscribe((userInfo) => {
    //   modalRef.componentInstance.loading = true;
    //   delete userInfo.companyUuid;
    //   delete userInfo.agencyUuid;
    //   delete userInfo.hubUuid;
    //   const groupRestrictionUuid = userInfo.group.uuid;
    //   delete userInfo.group;
    //   userInfo.groupRestrictionUuid = groupRestrictionUuid;
    //   this.subcriptions.push(this.userService.putUser(this.userOrEmployee.uuid, userInfo).subscribe(
    //     (res) => {
    //       modalRef.componentInstance.loading = false;
    //       modalRef.dismiss("Send Info");
    //       this.translate
    //         .get("company.company-detail.user-edit")
    //         .subscribe((result) => {
    //           this.ngxToastrService.typeSuccess(null, result);
    //         });
    //       this.getProfile();
    //       this.user = res;
    //       this.loadGroups(this.user.companyUuid);
    //     },
    //     (err) => {
    //       modalRef.componentInstance.loading = false;
    //       this.translate
    //         .get("company.company-detail.error-user-edit")
    //         .subscribe((result) => {
    //           this.ngxToastrService.typeInfo(result, err.error.message);
    //         });
    //     }
    //   ));
    // });
  }

  onEditCompany() {
    this.subcriptions.push(
      this.companyService
        .getCompany(this.companyUuid)
        .subscribe((company: Company) => {
          this.company = company;
          const extras: NavigationExtras = {
            state: {
              loading: false,
              company: this.company,
              maxHotelPercentage: 100,
              maxFlightPercentage: 100,
            },
          };
          this.router.navigate(["/my-company/edit-company/"], extras);
        })
    );
  }

  onSendEmailUser() {
    const modalRef = this.modalService.open(SendEmailUsersComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.sendInfo.subscribe(
      (emails) => {
        modalRef.componentInstance.loading = true;
        const body = {
          emailList: emails,
          companyUuid: this.company.uuid,
          groupRestrictionUuid: this.groupUuid,
        };
        this.userService.sendEmailsExternalUsers(body).subscribe(() => {
          modalRef.componentInstance.loading = false;
          modalRef.dismiss();
          this.translate
            .get([
              "common.understood",
              "company.company-detail.user-external.email-pass",
              "company.company-detail.user-external.subtitle",
            ])
            .subscribe((result) => {
              swal
                .fire({
                  html: `<div id="understood-container" style="display: flex; flex-direction: column"><p>${result["company.company-detail.user-external.subtitle"]}</p><img data-enlargeable src="assets/img/examples/trm/user-enabled.png" alt="Imagen ejemplo de usuario habilitado"/></div>`,
                  allowOutsideClick: false,
                  confirmButtonText: result["common.understood"],
                  title:
                    result["company.company-detail.user-external.email-pass"],
                })
                .then((res) => {
                  this.loadGroups(this.company.uuid);
                });
            });
        });
      },
      (err) => {
        modalRef.componentInstance.loading = false;
        this.translate
          .get("hub.hub-detail.error-created-user")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.message);
          });
      }
    );
  }

  loadGroups(uuid) {
    this.groupSubscription = this.groupService
      .getGroups(uuid)
      .pipe(take(1))
      .subscribe(
        (groups: GroupRestriction[]) => {
          this.groups = groups;
        },
        (err) => {
          this.translate
            .get("company.company-detail.error-get-groups")
            .pipe(take(1))
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.error);
            });
        }
      );
  }
  // Cambio el idioma de la aplicacion
  changeLanguage(language: string) {
    this.language = language;
    this.translate.use(language);
    this.langService.saveLanguage(language);
  }

  // goToAuthorizations() {
  //   const route = "my-company/requests"
  //   this.router.navigateByUrl(route);
  // }

  openUserStorage() {
    const navigationextras: NavigationExtras = {
      state: {
        user: this.user,
      },
    };
    this.router.navigate(["/user/my-documents"], navigationextras);
    // const dialogRef = this.dialog.open(DialogUserFilesComponent, {height: '90vh', width: '90vw', data: {user: userRes}});
  }

  // goToAHubPartners(){
  //   if(this.router.url.includes("agencies") && this.router.url.includes("hubs")){
  //     this.hubId = this.router.url.split("/")[2];
  //   }
  //   this.router.navigate(["/hubs/"+this.hubId+"/agencies"]);
  // }

  // goToAPartnerCompanies(){
  //   if(this.router.url.includes("agencies") && this.router.url.includes("companies")){
  //     this.partnerId = this.router.url.split("/")[2];
  //   }
  //   this.router.navigate(["/agencies/"+this.partnerId+"/companies"]);
  // }

  openGraphModal(name, type, graphic, uuid) {
    const modalRef = this.modalService.open(GraphComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.name = name;
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.graphic = graphic;
    if (uuid) {
      modalRef.componentInstance.uuid = uuid;
    }
  }

  goToDashboard(isSuperadmin: boolean) {
    const dataDashboard = {
      isSuperAdmin: isSuperadmin,
    };
    if (!isSuperadmin) {
      const company: any = this.company;
      dataDashboard["uuid"] = company.company.uuid;
      dataDashboard["name"] = company.company.name;
    }
    this.router.navigateByUrl(
      isSuperadmin
        ? "companies/dashboard"
        : "companies/" + this.companyUuid + "/dashboard",
      {
        state: { dataDashboard },
      }
    );
  }

  editConfig() {
    const modalRef = this.modalService.open(ConfigComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.sendInfo.subscribe((configInfo) => {
      modalRef.componentInstance.loading = true;
      modalRef.dismiss("send info");
    });
  }

  exportExcel() {
    this.isLoadingExcel = true
    this.companyService.getAllCompaniesBookeds().subscribe(
      (res) => {
        this.excelService.createBookingsExcel("Todas las empresas", res);
        this.isLoadingExcel = false
      },
      (err) => {
        this.translate
          .get("graph.error-downloading")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.message);
          });
        this.isLoadingExcel = false
      }
    );
  }

  goMainPage() {
    if (this.authenticatedUser.type?.toUpperCase() === "USER") {
      this.router.navigate(["/home"]);
    } else if (this.authenticatedUser.type?.toUpperCase() === "COMPANY") {
      if (this.authService.userCompanyPlan.permissions.canUseTRM) {
        this.router.navigate(["/my-company/details"]);
      } else {
        this.router.navigate(["/home"]);
      }
    } else {
      this.router.navigate(["/companies"]);
    }
  }

  goToRequests() {
    this.router.navigate([
      `/companies/${this.user.companyUuid}/authorizations`,
    ]);
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }
}
