import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Agency } from "@core/models";
import { environment } from "@env/environment";
import { map } from "rxjs/operators";
import { TokenService } from "./token.service";

@Injectable({
  providedIn: "root",
})
export class AgencyService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getAgency(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/agencies/${uuid}`, {
        headers: reqHeaders,
      })
      .pipe(map((res: Agency) => res));
  }

  getAgencies(params?: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    let queryParams;
    params ? (queryParams = params) : (queryParams = {});
    return this.http
      .get<any>(`${environment.baseUrlApi}/agencies`, {
        headers: reqHeaders,
        params: queryParams,
      })
      .pipe(
        map((res) => {
          const agencies: Agency[] = res;
          return agencies;
        })
      );
  }

  postAgency(body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/agencies`, body, {
        headers: reqHeaders,
      })
      .pipe(map((res: Agency) => res));
  }

  putAgency(agency, uuid) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/agencies/${uuid}`, agency, {
        headers: reqHeaders,
      })
      .pipe(map((res: Agency) => res));
  }

  deleteAgency(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.delete<any>(`${environment.baseUrlApi}/agencies/${uuid}`, {
      headers: reqHeaders,
    });
  }

  getFlights(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/agencies/${uuid}/flights`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res.docs));
  }
  getTrains(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/agencies/${uuid}/trains`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res.docs));
  }

  postAgencyPayComet(uuid: string, body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/agencies/${uuid}/addCard`, body, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  removeCard(agency) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .put<any>(
        `${environment.baseUrlApi}/agencies/${agency.uuid}/removeCard`,
        { headers: reqHeaders }
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getTransactions(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/agencies/${uuid}/transactions`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  getBills(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/bills/${uuid}`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  getBill(uuid: string, agencyUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get(
      `${environment.baseUrlApi}/bills/${agencyUuid}/${uuid}`,
      { headers: reqHeaders, responseType: "blob" }
    );
  }

  postBill(form: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(`${environment.baseUrlApi}/bills/`, form, {
      headers: reqHeaders,
    });
  }

  getSavings(agencyUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get(`${environment.baseUrlApi}/agencies/${agencyUuid}/savings`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  postSaving(agencyUuid: string, body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(
        `${environment.baseUrlApi}/agencies/${agencyUuid}/savings`,
        body,
        { headers: reqHeaders }
      )
      .pipe(map((res: any) => res));
  }
  putSaving(agencyUuid: string, uuid: string, body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .put<any>(
        `${environment.baseUrlApi}/agencies/${agencyUuid}/savings/${uuid}`,
        body,
        { headers: reqHeaders }
      )
      .pipe(map((res: any) => res));
  }
  getSatisfactions() {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get(`${environment.baseUrlApi}/agencies/satisfactions`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  postSatisfaction(body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/agencies/satisfactions`, body, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  putSatisfaction(uuid: string, body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .put<any>(
        `${environment.baseUrlApi}/agencies/satisfactions/${uuid}`,
        body,
        { headers: reqHeaders }
      )
      .pipe(map((res: any) => res));
  }
  getPhone(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/agencies/${uuid}/phone`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  getUsersByAccessType(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/agencies/${uuid}/accessType`, {
        headers: reqHeaders,
      })
  }
}
