import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { AuthService } from "@core/services";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.reducer";
import { AuthState } from "app/store/reducers/auth.reducer";
import { Observable } from "rxjs";
import { map, skipWhile } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CanActiveSearchCompanyGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private route: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (route.params.id) {
      return this.store.select("auth").pipe(
        skipWhile((authState: AuthState) => authState.skipping),
        map((authState: AuthState) => {
          if (authState.isAuthenticated) {
            const alloweds = ["COMPANY", "AGENCY", "HUB", "EMPLOYEE"];
            if (alloweds.includes(authState.user.type)) {
              return true;
            } else {
              this.route.navigate([""]);
              return false;
            }
          } else {
            this.authService.userCompanyPlan = null;
            this.route.navigate(["/login"]);
            return false;
          }
        })
      );
    } else {
      this.authService.userCompanyPlan = null;
      this.route.navigate(["/login"]);
      return false;
    }
  }
}
