import { Location } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { ActivatedRoute } from "@angular/router";
import { User } from "@models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { RequestService, RequestUtilsService, UtilsService } from "@services";
import { AppState } from "app/app.reducer";
import { Subject, Subscription } from "rxjs";
import { CustomRequestDetailsComponent } from "./custom-request-details/custom-request-details.component";

@Component({
  selector: "app-custom-requests",
  templateUrl: "./custom-requests.component.html",
  styleUrls: ["./custom-requests.component.scss"],
})
export class CustomRequestsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  requests: any[] = [];
  pagination: any;
  type: "hotel" | "flight" | "train" | "car" | "all";
  status: "active" | "completed";
  userName: string;
  userSurname: string;
  description: string;
  initFromDate: string;
  endFromDate: string;
  initToDate: string;
  endToDate: string;
  sort: string;
  page: number;
  params: any = {};
  statusOptions = [
    {
      value: "active",
      view: "common.pending",
    },
    {
      value: "completed",
      view: "common.completed",
    },
  ];
  typeOptions = [
    {
      value: "all",
      view: "common.all",
    },
    {
      value: "hotel",
      view: "common.hotel",
    },
    {
      value: "flight",
      view: "common.flight",
    },
    {
      value: "train",
      view: "common.train",
    },
    {
      value: "car",
      view: "common.car",
    },
  ];
  subject: Subject<any> = new Subject<any>();
  hasCleared = false;
  loading = false;
  hasPrivileges = false;
  @Input() company: string;
  companyUuid: string;
  user: User;
  nameFilters: string[] = ["user.name", "user.lastname", "city", "from", "to"];
  typeFilters: string[] = ["string", "status", "service"];
  filter = "";
  lowValue = 0;
  highValue = 5;
  @Input() isBookeds?= false;
  @Input() typeFromBookeds?: "hotel" | "flight" | "train" | "car";
  @Input() userUuid?: string;
  typeComponent: any[] = [];
  @Output() changeComponentEmit: EventEmitter<any> = new EventEmitter<any>();
  component: any;
  @ViewChild('paginator') paginator: MatPaginator;
  constructor(
    private requestService: RequestService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private location: Location,
    public utilsService: UtilsService,
    private modalService: NgbModal,
    private requestUtilsService: RequestUtilsService,
    private translateService: TranslateService
  ) {
    this.status = "active";
    this.subscriptions.push(
      this.translateService
        .get([
          "common.bookeds",
          "company.company-detail.request",
          "company.company-detail.authorizations",
        ])
        .subscribe((result) => {
          this.typeComponent = [
            { text: result["common.bookeds"], value: "booked" },
            {
              text: result["company.company-detail.request"],
              value: "request",
            },
            {
              text: result["company.company-detail.authorizations"],
              value: "authorization",
            },
          ];
          this.component = 1;
        })
    );
  }

  ngOnInit() {
    this.page = 1;
    this.status = "active";
    this.type = "all";
    this.getUserType();
    if (this.typeFromBookeds) {
      this.type = this.typeFromBookeds;
      if(this.typeFromBookeds === 'car') {
        this.subscriptions.push(
          this.translateService
            .get([
              "common.bookeds",
              "company.company-detail.request",
            ])
            .subscribe((result) => {
              this.typeComponent = [
                { text: result["common.bookeds"], value: "booked" },
                {
                  text: result["company.company-detail.request"],
                  value: "request",
                },
              ];
              this.component = 1;
            })
        );
      }
    }
  }

  getUserType() {
    this.subscriptions.push(
      this.store.select("auth").subscribe((s) => {
        const st: any = s;
        this.hasPrivileges = st.user.type?.toUpperCase() === "EMPLOYEE";
        this.companyUuid =
        this.hasPrivileges ? this.route.snapshot.paramMap.get("id") 
        : (st.user && 
          (st.user.user?.accessType?.toLowerCase() === "agency" ||
          st.user.user?.accessType?.toLowerCase() === "hub" || 
          st.user.accessType?.toLowerCase() === 'agency')) 
        ? this.route.snapshot.paramMap.get("id") || this.company 
        : st.user.companyUuid || this.company;
        this.user = st.user;
        this.getRequestList();
      })
    );
  }


  getRequestList() {
    if (this.hasPrivileges) {
      delete this.params["companyUuid"];
      if (this.companyUuid !== null) {
        this.params["companyUuid"] = this.companyUuid;
      }
    } else {
      if (this.companyUuid) {
        this.params["companyUuid"] = this.companyUuid;
      } else {
        this.params["companyUuid"] = this.user.companyUuid;
        if (!this.params["companyUuid"]) {
          delete this.params["companyUuid"];
        }
      }
    }
    if (this.userUuid) {
      this.params["userUuid"] = this.userUuid;
    }
    if (this.route.snapshot.paramMap.has("agencyUuid")) {
      delete this.params["companyUuid"];
      this.params["agencyUuid"] =
        this.route.snapshot.paramMap.get("agencyUuid");
    }
    if (this.type?.toLowerCase() === "all") {
      delete this.params["type"];
    }
    this.subscriptions.push(
      this.requestService
        .getRequests(this.params)
        .subscribe(async (result: any) => {
          this.requests = await result;
          this.pagination = this.requests;
          delete this.pagination.docs;
          this.requests.forEach((request) => {
            const initDate = request.initDate.split("-");
            if (initDate[2]) {
              const time = initDate[2].split(" ")[1];
              initDate[2] = initDate[2].split(" ")[0];
              if (time) {
                const timeSplit = time.split(":");
                timeSplit.forEach((t: any) => initDate.push(t));
              } else {
                initDate.push(0);
                initDate.push(0);
              }
            }
            if (initDate.length >= 3) {
              request.initDate = new Date(
                initDate[2],
                initDate[1] - 1,
                initDate[0],
                initDate[3],
                initDate[4]
              );
            }
            if (request.endDate) {
              const endDate = request.endDate.split("-");
              if (endDate[2]) {
                const time = endDate[2].split(" ")[1];
                endDate[2] = endDate[2].split(" ")[0];
                if (time) {
                  const timeSplit = time.split(":");
                  timeSplit.forEach((t: any) => endDate.push(t));
                } else {
                  endDate.push(0);
                  endDate.push(0);
                }
              }
              if (endDate.length >= 3) {
                request.endDate = new Date(
                  endDate[2],
                  endDate[1] - 1,
                  endDate[0],
                  endDate[3],
                  endDate[4]
                );
              }
            }
          });
        })
    );
  }

  isValidDate(date: any): boolean {
    return date && !isNaN(Date.parse(date));
  }

  clearFilters() {
    this.filter = "";
    this.status = "active";
    this.type = "all";
    this.hasCleared = true;
  }

  onPageChange(event) {
    this.page = event;
    this.getRequestList();
  }

  onDetails(request) {
    const modalRef = this.modalService.open(CustomRequestDetailsComponent, {
      size: "lg",
      centered: true,
      modalDialogClass: "details-component",
    });
    modalRef.componentInstance.request = request;
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.hasPrivileges = this.hasPrivileges;
    this.requestUtilsService.sendInfo.subscribe((data) => {
      if (data) {
        this.getRequestList();
        modalRef.close();
      }
    });
  }

  addBook(request: any) {
    switch (request.type?.toLowerCase()) {
      case "hotel":
        this.requestUtilsService.createCustomHotel(request, this.hasPrivileges);
        this.requestUtilsService.sendInfo.subscribe((data) => {
          if (data) {
            this.getRequestList();
          }
        });
        break;
      case "flight":
        this.requestUtilsService.createCustomFlightTrain(
          request,
          "flight",
          this.hasPrivileges
        );
        this.requestUtilsService.sendInfo.subscribe((data) => {
          if (data) {
            this.getRequestList();
          }
        });
        break;
      case "train":
        this.requestUtilsService.createCustomFlightTrain(
          request,
          "train",
          this.hasPrivileges
        );
        this.requestUtilsService.sendInfo.subscribe((data) => {
          if (data) {
            this.getRequestList();
          }
        });
        break;
      case "car":
        this.requestUtilsService.createCustomCar(request, this.hasPrivileges);
        this.requestUtilsService.sendInfo.subscribe((data) => {
          if (data) {
            this.getRequestList();
          }
        });
        break;
      case "airbnb":
        this.requestUtilsService.createCustomAirbnb(request, this.hasPrivileges);
        this.requestUtilsService.sendInfo.subscribe((data) => {
          if (data) {
            this.getRequestList();
          }
        });
        break;
      default:
        break;
    }
  }

  canClean() {
    return (
      this.filter !== "" || this.status !== "active"
    );
  }

  getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  changeComponent(event) {
    this.changeComponentEmit.emit(this.typeComponent[event]);
    this.component = this.typeComponent.indexOf(this.typeComponent[event]);
  }

  changeRequestStatus(request: any) {
    this.requestService.putStatusRequest({ requestUuid: request.uuid, status: request.status === 'active' ? 'completed' : 'active' }).subscribe((requestCompleted) => {
      this.getRequestList();
    })
  }

  goBack() {
    this.location.back();
  }

  setPage() {
    this.paginator.firstPage();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subject.unsubscribe();
  }
}
