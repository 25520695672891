import { Injectable } from "@angular/core";
import { CanLoad, Route, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.reducer";
import { AuthState } from "app/store/reducers/auth.reducer";
import { Observable } from "rxjs";
import { map, skipWhile, take } from "rxjs/operators";

@Injectable()
export class AuthUserGuard implements CanLoad {
  constructor(private router: Router, private store: Store<AppState>) {}
  canLoad(route: Route): Observable<boolean> {
    return this.store.select("auth").pipe(
      skipWhile((authState: AuthState) => authState.skipping),
      take(1),
      map((authState: AuthState) => {
        const auth: any = authState;
        const type: string = auth.user.type
          ? auth.user.type
          : auth.user.user.accessType?.toUpperCase();
        const canUseTRM: boolean = auth.user.type
          ? auth.user.plan && auth.user.plan.permissions.canUseTRM
          : auth.user.user.plan && auth.user.user.plan.permissions.canUseTRM;
        if (!authState.isAuthenticated) {
          this.router.navigate(["/login"]);
        } else if (type !== "USER" && canUseTRM) {
          this.router.navigate(["/"]);
        }
        return (
          authState.isAuthenticated &&
          (type === "USER" || (type === "COMPANY" && !canUseTRM))
        );
      })
    );
  }
}
