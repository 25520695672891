<div class="card mt-2 mb-3 border-card">
  <div class="card-header">
    <div class="row">
      <div class="col-8 col-sm-9">
        <h4 class="card-title display-inline-block mw-100 text-truncate">
          {{ company.name }}
        </h4>
      </div>
      <div class="col-4 col-sm-3">
        <div class="actions display-inline-block">
          <a
            class="ft-eye font-medium-2 text-right action-view ml-1 mr-1"
            title="{{ 'company.company-card.view-details' | translate }}"
            [routerLink]="['/companies', company.uuid]"
          ></a>
          <a
            class="ft-edit font-medium-2 text-right action-edit ml-1 mr-1"
            title="{{ 'company.company-card.edit' | translate }}"
            (click)="onEdit()"
          ></a>
          <a
            class="delete-icon font-medium-2 text-right action-delete ml-1 mr-1"
            title="{{ 'company.company-card.delete' | translate }}"
            (click)="onDelete()"
          ></a>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <h6 class="card-subtitle text-muted">{{ company.cif }}</h6>
      <h6 class="card-subtitle text-muted">{{ formatDate }}</h6>
    </div>
  </div>
  <div class="card-content" [routerLink]="['/companies', company.uuid]">
    <div class="card-body text-center">
      <img class="img-card mw-100" src="{{ image }}" alt="Card image cap" />
    </div>
  </div>
</div>
