import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { GroupRestriction, User } from "@models";
import { map } from "rxjs/operators";
import { TokenService } from "./token.service";

@Injectable()
export class GroupService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getGroups(uuid: string, params?: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const reqParams = new HttpParams({ fromObject: params });
    const url = `${environment.baseUrlApi}/companies/${uuid}/groups`
    return this.http
      .get<any>(url, {
        params: reqParams,
        headers: reqHeaders,
      })
      .pipe(
        map((res) => {
          const groups: GroupRestriction[] = res.docs;

          return groups.map((group: GroupRestriction) => {
            return {
              ...group,
              users: group.users.map((user: User) => {
                return {
                  ...user,
                  type: "USER",
                };
              }),
            };
          });
        })
      );
  }

  postGroup(uuidCompany: string, body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(
        `${environment.baseUrlApi}/companies/${uuidCompany}/groups`,
        body,
        { headers: reqHeaders }
      )
      .pipe(map((res: GroupRestriction) => res));
  }

  putGroup(uuidCompany: string, group: GroupRestriction) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const body = {
      name: group.name,
      filters: {
        ...group.filters,
      },
    };

    return this.http
      .put<any>(
        `${environment.baseUrlApi}/companies/${uuidCompany}/groups/${group.uuid}`,
        body,
        { headers: reqHeaders }
      )
      .pipe(map((res: GroupRestriction) => res));
  }

  deleteGroup(uuidCompany: string, uuidGroup: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.delete<any>(
      `${environment.baseUrlApi}/companies/${uuidCompany}/groups/${uuidGroup}`,
      { headers: reqHeaders }
    );
  }

  getGroup(uuidCompany: string, uuidGroup: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(
        `${environment.baseUrlApi}/companies/${uuidCompany}/groups/${uuidGroup}`,
        { headers: reqHeaders }
      )
      .pipe(map((res: GroupRestriction) => res));
  }
}
