import { Component, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AirbnbService } from "@core/services/airbnb.service";
import { RequestService } from "@core/services/request.service";
import { environment } from "@env/environment";
import { Agency, Company, Config, GroupRestriction, User } from "@models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AgencyService,
  AuthService,
  BookedService,
  CarService,
  CompanyService,
  ConfigService,
  ExpensesService,
  FlightService,
  GroupService,
  LanguageService,
  MediaService,
  NgxToastrService,
  TrainService,
  TravelUtilsService,
  UserService,
  UtilsService,
} from "@services";
import { DialogUserFilesComponent } from "@shared/component/dialog-user-files/dialog-user-files.component";
import { AppState } from "app/app.reducer";
import { SendEmailUsersComponent } from "app/modules/businessEntities/user/send-email-users/send-email-users.component";
import { CreateAirbnbComponent } from "app/modules/components/airbnb/custom/create-airbnb/create-airbnb.component";
import { CreateCarComponent } from "app/modules/components/cars/custom/create-car/create-car.component";
import { CarRequestComponent } from "app/modules/components/custom-services/components/car-request/car-request.component";
import { ExpensesComponent } from "app/modules/components/expenses/expenses.component";
import { CreateFlightTrainComponent } from "app/modules/components/flight-train/create-flight-train/create-flight-train.component";
import { FormFlightTrainComponent } from "app/modules/components/flight-train/form-flight-train/form-flight-train.component";
import { GraphComponent } from "app/modules/components/graph/graph/graph.component";
import { ResetFiltersAction } from "app/store/actions/search.actions";
import * as lodash from "lodash-es";
import * as momentTz from "moment-timezone";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import swal from "sweetalert2";
import { RequestCustomHotelComponent } from "../../../components/custom-services/components/request-custom-hotel/request-custom-hotel.component";
import { ViewPdfModalComponent } from "../../user/user-detail/view-pdf-modal/view-pdf-modal.component";
import { UserFormGroupComponent } from "../../user/user-form-group/user-form-group.component";
import { CreateCustomHotelComponent } from "./../../../components/hotel/create-custom-hotel/create-custom-hotel.component";

@Component({
  selector: "app-company-detail",
  templateUrl: "./company-detail.component.html",
  styleUrls: ["./company-detail.component.scss"],
})
export class CompanyDetailComponent implements OnInit, OnDestroy {
  userOrEmployee: User | Company | Agency;
  isGroupAdmin = false;
  company: Company;
  isAmadeusEnabled = environment.AMADEUS_ENABLED === 0 ? false : true;
  groups: GroupRestriction[] = [];
  uuid: string;
  subscription: Subscription[] = [];
  companySubscription: Subscription;
  groupSubscription: Subscription;
  isCompany = false;
  reloadCompanies = new EventEmitter();
  isAdmin = false;
  configHotels: Config;
  configFlights: Config;
  getConfigsubscription: Subscription[] = [];
  maxHotelPercentage;
  maxFlightPercentage;
  image: any;
  agencyImage = "";
  userType: string;
  agency: Agency;
  agenyPhone = "";
  agencyShow: Boolean = false;
  VYOOTRIP_NUMBER = "648 200 516";
  vyooLogo = "../../../../../assets/img/logo/text_logo_new.png";
  trUserName: string;
  trUserLastName: string;
  trGroupName: string;
  params: any = {};
  userName: string;
  userSurname: string;
  hasCleared = false;
  canLoadFilters = false;
  isSuperAdmin = false;
  companyUuid: string;
  pagination: any;
  authorizations: any[] = [];
  type: "booking" | "flight" | "train" | "all";
  status: "request" | "denied" | "accepted" | "no vacancy" | "all";
  page: number;
  haveRequest: Boolean = false;
  planData: any;
  nameFilters;
  typeFilters: any[];
  filter = "";
  userUuid: string;
  totalAuthorizations = 0;
  totalRequests = 0;
  requests: any;
  user: any;
  groupsCopy: GroupRestriction[] = [];
  allLoaded = false;
  totalUsers = 0;
  totalImages = 0;
  hasNoImage = true;
  fiscalYears = [];
  yearSelected = "";
  emailsGroupInvites: any;
  usersEmailsGroups: string[] = [];
  showContent = true;
  loading = false;
  constructor(
    private companyService: CompanyService,
    private groupService: GroupService,
    private userService: UserService,
    private flightService: FlightService,
    private trainService: TrainService,
    private utilsService: UtilsService,
    private mediaService: MediaService,
    private configService: ConfigService,
    private translateService: TranslateService,
    private ngxToastrService: NgxToastrService,
    private travelUtilsService: TravelUtilsService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public translate: TranslateService,
    private router: Router,
    private store: Store<AppState>,
    private expenseService: ExpensesService,
    private agencyService: AgencyService,
    private bookedService: BookedService,
    private langService: LanguageService,
    private carService: CarService,
    public authService: AuthService,
    private dialog: MatDialog,
    private requestService: RequestService,
    private airbnbService: AirbnbService
  ) {}

  ngOnInit() {
    this.typeFilters = ["string"];
    this.nameFilters = ["string"];
    this.page = 1;
    this.status = "request";
    this.type = "all";
    this.params["type"] = this.type;
    this.params["status"] = this.status;
    this.subscription.push(
      this.store.select("auth").subscribe((authState: any) => {
        this.userUuid = authState.user.uuid;
        this.user = authState.user;
        if (authState) {
          this.uuid = this.route.snapshot.paramMap.get("uuid");
          if (!this.uuid && authState.user) {
            this.uuid = authState.user.company.uuid;
            this.userType = authState.user.type;
            this.isCompany = true;
          }
          if (this.uuid) {
            if (authState.user) {
              this.userType = authState.user.type;
              if(this.userType === 'COMPANY'){
                this.isCompany = true;
              }
            }
            this.loadCompany(this.uuid);
            this.loadGroups(this.uuid);
          }
        }
      })
    );
    this.subscription.push(
      this.store.select("auth").subscribe((auth) => {
        const user: any = auth.user;
        if (auth.user != null) {
          if (
            auth.user.type
              ? auth.user.type?.toUpperCase() === "EMPLOYEE" ||
                auth.user.type?.toUpperCase() === "HUB" ||
                auth.user.type?.toUpperCase() === "AGENCY"
              : user.user.accessType?.toLowerCase() === "agency" ||
                user.user.accessType?.toLowerCase() === "hub" ||
                user.user.accessType?.toLowerCase() === "employee"
          ) {
            this.isAdmin = true;
            this.getPercentages();
          }
          if (auth.user.type?.toUpperCase() === "EMPLOYEE") {
            this.isSuperAdmin = true;
          }
        }
      })
    );

    this.getAuthorizationList();
    this.getRequestList();
    this.getTranslations();
    this.reloadCompanies.subscribe(() => this.loadCompany(this.uuid));
  }

  /**
   * Method triggered when a filter field changes. If it is an INPUT field, it calls our subject.
   * If it is not, it calls directly to our updateParams method and to the API caller method
   * @param param field name
   * @param event field value
   * @see updateParams()
   * @see getAuthorizationList()
   */
  filterParam(event?) {
    this.filter = event;
  }

  /**
   * Return all years that has passed from the company signup in Vyootrip
   */
  getFiscalYears() {
    const currentYear = new Date().getFullYear();
    let startYear = new Date(this.company.createdAt).getFullYear();
    while (startYear <= currentYear) {
      this.fiscalYears.push(startYear++);
    }
    this.fiscalYears.reverse();
  }

  /**
   * Clears the params object and call the API service with no params
   */
  clearFilters() {
    this.params = {};

    this.hasCleared = true;
  }
  ngOnDestroy() {
    this.subscription.forEach((s) => s.unsubscribe());
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
    if (this.groupSubscription) {
      this.groupSubscription.unsubscribe();
    }
  }
  /**
   * With this method, we get the translations for placeholders in html templates, and the status options array
   */
  getTranslations() {
    this.subscription.push(
      this.translateService
        .get([
          "authorization.labels.name",
          "authorization.labels.lastName",
          "authorization.labels.group",
        ])
        .pipe(take(1))
        .subscribe((result) => {
          // labels in html filters
          this.trUserName = result["authorization.labels.name"];
          this.trUserLastName = result["authorization.labels.lastName"];
          this.trGroupName = result["authorization.labels.group"];
          this.canLoadFilters = true;
        })
    );
  }

  loadCompany(uuid: string) {
    this.companySubscription = this.companyService.getCompany(uuid).subscribe(
      (company: Company) => {
        this.company = company;
        this.utilsService.storeParamInLocalStorage({
          title: "companyUuid",
          value: this.company.uuid,
        });
        this.getFiscalYears();
        if (this.authService.isAdmin()) {
          this.subscription.push(
            this.authService
              .getCompanyPlan(this.company.uuid)
              .subscribe((planRes) => {
                this.authService.userCompanyPlan = planRes;
                const cardData =
                  company.cardData != null &&
                  company.cardData.digitsCard != null &&
                  company.cardData.digitsCard !== "" &&
                  company.cardData.digitsCard !== " ";
                const bookedWithoutCard = company.bookedWithoutCard
                  ? true
                  : false;
                const companyStorage = {
                  hasCard: cardData,
                  allowBookingWithoutCard: bookedWithoutCard,
                  allowBookingRequest:
                    this.authService.userCompanyPlan.permissions
                      .canCreateBookingRequest,
                  canRequestWithoutCard:
                    planRes.permissions.canRequestWithoutCard,
                };
                this.utilsService.storeParamInLocalStorage({
                  title: "company",
                  value: companyStorage,
                });
              })
          );
        }
        if (this.company.image) {
          this.subscription.push(
            this.mediaService.getMedia(this.company.image).subscribe(
              (s) => {
                const reader = new FileReader();
                reader.readAsDataURL(s);
                let base64data;
                reader.onloadend = () => {
                  base64data = reader.result;
                  this.image = base64data;
                };
              },
              (err) => {
                this.translate
                  .get("company.company-detail.error-get-company")
                  .pipe(take(1))
                  .subscribe((result) => {
                    this.ngxToastrService.typeInfo(result, err.error.error);
                  });
              }
            )
          );
        }
        if (this.company.agencyUuid) {
          this.subscription.push(
            this.agencyService
              .getPhone(this.company.agencyUuid)
              .subscribe((p) => {
                this.agenyPhone = p;
              }),
            this.agencyService.getAgency(this.company.agencyUuid).subscribe(
              (i) => {
                this.agencyImage = i.image;
                if (this.agencyImage) {
                  this.subscription.push(
                    this.mediaService
                      .getMedia(this.agencyImage)
                      .subscribe((s) => {
                        this.agencyShow = true;
                        const reader = new FileReader();
                        reader.readAsDataURL(s);
                        let base64data;
                        reader.onloadend = () => {
                          base64data = reader.result;
                          this.agencyImage = base64data;
                        };
                      })
                  );
                } else {
                  this.agenyPhone = this.VYOOTRIP_NUMBER;
                  this.agencyShow = true;
                  this.agencyImage = this.vyooLogo;
                }
              },
              (err) => {
                this.translate
                  .get("company.company-detail.error-get-company")
                  .pipe(take(1))
                  .subscribe((result) => {
                    this.ngxToastrService.typeInfo(result, err.error.error);
                  });
              }
            )
          );
        } else {
          this.agencyShow = true;
          this.agencyImage = this.vyooLogo;
          this.agenyPhone = this.VYOOTRIP_NUMBER;
        }
      },
      (err) => {
        this.translate
          .get("company.company-detail.error-get-company")
          .pipe(take(1))
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  loadGroups(uuid: string, year?: number) {
    this.loading = true;
    this.allLoaded = false;
    if (year) {
      this.params.filterByYears = year;
      this.yearSelected = year.toString();
    } else {
      delete this.params.filterByYears;
      this.yearSelected = "";
    }
    this.groupSubscription = this.groupService
      .getGroups(uuid, this.params)
      .pipe(take(1))
      .subscribe(
        (groups: GroupRestriction[]) => {
          this.groups = this.utilsService.sortArrayByDate(groups).reverse();
          this.groups.forEach((g) => {
            if (g.users.length > 0) {
              g.users.forEach(async (u) => {
                this.usersEmailsGroups.push(u.email);
                this.totalUsers++;
                if (u.image) {
                  this.hasNoImage = false;
                }
                await this.getUserProfilePicture(u);
              });
            }
          });

          /* this.groups = groups; */
          this.groups.map((g) => {
            if (g.name === "Default") {
              if (this.langService.getLanguage() === "es") {
                g.name = "No asignado";
              }
              const index = this.groups.indexOf(g);
              this.groups.splice(index, 1);
              this.groups.push(g);
            }
          });
          this.groupsCopy = this.groups;
          this.loading = false;
          this.loadEmailsGroupInvites(this.company.uuid)
        },
        (err) => {
          this.translate
            .get("company.company-detail.error-get-groups")
            .pipe(take(1))
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err);
            });
        }
      );
  }


  loadEmailsGroupInvites(companyUuid: string){
    this.loading = true;

    this.groupSubscription = this.companyService.getEmailGroupsByCompany(companyUuid)
      .subscribe(res => {
        this.emailsGroupInvites = res;
        this.emailsGroupInvites = this.emailsGroupInvites?.filter(invite => !this.usersEmailsGroups?.includes(invite.email)) || null;
        this.loading = false;
      },
      (err) => {
        this.translate
          .get("company.company-detail.error-get-groups")
          .pipe(take(1))
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err);
          });
      })
  }


  onNewGroup() {
    if (
      this.authService.isAdmin() ||
      this.authService.userCompanyPlan.permissions.canCreateUnlimitedGroups ===
        -1 ||
      this.groups.length + 1 <=
        this.authService.userCompanyPlan.permissions.canCreateUnlimitedGroups
    ) {
      const navigationGroup: NavigationExtras = {
        state: {
          loading: false,
          company: this.company,
          user: this.user,
          fromCompanyDetails: true,
        },
      };

      this.router.navigate(["group/create-group"], navigationGroup);
      // const modalRef = this.modalService.open(GroupFormComponent, {
      //   size: "lg",
      //   centered: true,
      // });
      // modalRef.componentInstance.loading = false;
      // modalRef.componentInstance.company = this.company;
      // modalRef.componentInstance.sendInfo.subscribe((groupInfo) => {
      //   const companyUuid = groupInfo.company.uuid;
      //   delete groupInfo.company;
      //   modalRef.componentInstance.loading = true;
      //   this.groupService.postGroup(companyUuid, groupInfo).subscribe(
      //     () => {
      //       modalRef.componentInstance.loading = false;
      //       modalRef.dismiss("Send Info");
      //       this.translate
      //         .get("company.company-detail.created-group")
      //         .subscribe((result) => {
      //           this.ngxToastrService.typeSuccess(null, result);
      //         });
      //       this.loadGroups(this.company.uuid);
      //     },
      //     (err) => {
      //       modalRef.componentInstance.loading = false;
      //       this.translate
      //         .get("company.company-detail.error-create-group")
      //         .subscribe((result) => {
      //           this.ngxToastrService.typeInfo(result, err.error.message);
      //         });
      //     }
      //   );
      // });
    } else if (
      this.groups.length ===
      this.authService.userCompanyPlan.permissions.canCreateUnlimitedGroups
    ) {
      this.translate
        .get("company.company-detail.create-group-limit")
        .subscribe((result) => {
          this.ngxToastrService.typeInfo(null, result);
        });
    }
  }

  onEditGroup(group: GroupRestriction) {
    const navigationGroup: NavigationExtras = {
      state: {
        loading: false,
        company: this.company,
        group: group,
        user: this.user,
      },
    };
    this.router.navigate(["group/edit-group/", group.uuid], navigationGroup);
    // const modalRef = this.modalService.open(GroupFormComponent, {
    //       size: "lg",
    //       centered: true,
    //     });
    //     modalRef.componentInstance.loading = false;
    //     modalRef.componentInstance.group = group;
    //     modalRef.componentInstance.company = this.company;

    //   modalRef.componentInstance.sendInfo.subscribe((groupInfo) => {
    //       groupInfo.uuid = group.uuid;
    //   modalRef.componentInstance.loading = true;
    //   this.groupService.putGroup(this.company.uuid, groupInfo).subscribe(
    //     () => {
    //       modalRef.componentInstance.loading = false;
    //       modalRef.dismiss("Send Info");
    //       this.translate
    //         .get("company.company-detail.edit-group-succesful")
    //         .subscribe((result) => {
    //           this.ngxToastrService.typeSuccess(null, result);
    //         });
    //       this.loadGroups(this.company.uuid);
    //     },
    //     (err) => {
    //       modalRef.componentInstance.loading = false;
    //       this.translate
    //         .get("company.company-detail.error-create-group")
    //         .subscribe((result) => {
    //           this.ngxToastrService.typeInfo(result, err.error.message);
    //         });
    //     }
    //   );
    // });
  }

  onNewExpense(groupUuid: string, userUuid: string) {
    const modalRef = this.modalService.open(ExpensesComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.userUuid = userUuid;
    modalRef.componentInstance.groupRestrictionUuid = groupUuid;
    modalRef.componentInstance.companyUuid = this.company.uuid;
    modalRef.componentInstance.sendInfo.subscribe((body) => {
      const formData = new FormData();
      if(body.file){
        formData.append("file", body.file, body.file.name);
      }
      formData.append("groupRestrictionUuid", body.groupRestrictionUuid);
      formData.append("companyUuid", body.companyUuid);
      formData.append("userUuid", body.userUuid);
      formData.append("price", body.price);
      formData.append("beginDate", body.beginDate);
      formData.append("endDate", body.endDate);
      formData.append("type", body.type);
      formData.append("customCode", body.customCode);
      formData.append("costCenter", body.costCenter);
      formData.append("freeTaxes", body.freeTaxes);
      if (body.travelName) {
        formData.append("travelName", body.travelName);
      }
      const fte = {
        departDate: body.beginDate,
        returnDate: body.endDate,
        oneWay: false,
      };
      if (this.checkTravelAndBookDates(fte)) {
        this.expenseService.createExpense(formData).subscribe(
          (expense) => {
            this.translate.get("expenses.success").subscribe((result) => {
              this.ngxToastrService.typeSuccess(null, result);
              this.handleTravelFlow(expense, "expense", userUuid);
            });
            modalRef.componentInstance.loading = false;
            modalRef.dismiss();
            const navigationExtras: NavigationExtras = {
              state: {
                module: "expenses",
              },
            };
            if (this.hasPdf(expense.expenseCreated, "expense")) {
              this.onGetPDF(expense.expenseCreated);
            }
            if (!this.isAdmin) {
              this.router.navigate([`/my-bookeds`], navigationExtras);
            } else {
              this.router.navigate(
                [`/user/booked/${userUuid}`],
                navigationExtras
              );
            }
          },
          (err) => {
            modalRef.componentInstance.loading = false;
            modalRef.close();
            this.ngxToastrService.typeInfo("ERROR", err.error.message ? err.error.message : err.error.text);
          }
        );
      } else {
        modalRef.componentInstance.loading = false;
      }
    });
  }

  openGraphModal(name, type, graphic, uuid) {
    const modalRef = this.modalService.open(GraphComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.name = name;
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.graphic = graphic;
    if (uuid) {
      modalRef.componentInstance.uuid = uuid;
    }
  }

  onDeleteGroup(group: GroupRestriction) {
    if (group.name === "No asignado" || group.name === "Default") {
      this.translate.get("common.cannot-delete-group").subscribe((result) => {
        this.ngxToastrService.typeInfo(result, "");
      });
    } else {
      this.translate
        .get([
          "company.company-detail.remove-group",
          "company.company-detail.group-data-lost",
          "common.confirm",
          "common.cancel",
          "company.company-detail.group-removed",
          "company.company-detail.error-removed-group",
        ])
        .subscribe((result) => {
          swal
            .fire({
              title: result["company.company-detail.remove-group"],
              text: result["company.company-detail.group-data-lost"],
              showCancelButton: true,
              confirmButtonColor: "#0CC27E",
              cancelButtonColor: "#FF586B",
              confirmButtonText: result["common.confirm"],
              cancelButtonText: result["common.cancel"],
              customClass: {
                confirmButton: "swal-button-squared swal-button-confirm-green",
                cancelButton: "swal-button-squared swal-button-dismiss-red",
              },
              buttonsStyling: false,
            })
            .then((action) => {
              if (action.value) {
                this.groupService
                  .deleteGroup(this.company.uuid, group.uuid)
                  .subscribe(
                    () => {
                      this.ngxToastrService.typeSuccess(
                        null,
                        result["company.company-detail.group-removed"]
                      );
                      this.totalUsers = 0;
                      this.totalImages = 0;
                      this.hasNoImage = true;
                      this.allLoaded = false;
                      this.loadGroups(this.company.uuid);
                    },
                    (err) => {
                      this.ngxToastrService.typeInfo(
                        result["company.company-detail.error-removed-group"],
                        err.error.message
                      );
                    }
                  );
              }
            });
        });
    }
  }

  onNewUser() {
    const navigationUser: NavigationExtras = {
      state: {
        loading: false,
        company: this.company,
        fromCompanyDetails: true,
        externalUser: this.user,
      },
    };

    this.router.navigate(["user/create-user"], navigationUser);
    /*const modalRef = this.modalService.open(UserFormComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.company = this.company;
    modalRef.componentInstance.fromCompanyDetails = true;
    modalRef.componentInstance.sendInfo.subscribe((userInfo) => {
      modalRef.componentInstance.loading = true;
      const groupRestrictionUuid = userInfo.group.uuid;
      delete userInfo.group;
      userInfo.groupRestrictionUuid = groupRestrictionUuid;
      userInfo.companyUuid = this.company.uuid;
      this.userService.postUser(userInfo).subscribe(
        () => {
          modalRef.componentInstance.loading = false;
          modalRef.dismiss("Send Info");
          this.translate
            .get("company.company-detail.user-email-pass")
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess(null, result);
            });
          this.translate
            .get("company.company-detail.created-user")
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess(null, result);
            });
          this.loadGroups(this.company.uuid);
        },
        (err) => {
          modalRef.componentInstance.loading = false;
          this.translate
            .get("company.company-detail.error-created-user")
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.message);
            });
        }
      );
    });*/
  }

  onExternalUser(group?: GroupRestriction) {
    const modalRef = this.modalService.open(SendEmailUsersComponent, {
      centered: true,
    });
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.groups = group ? null : this.groups;
    modalRef.componentInstance.sendInfo.subscribe(
      (data) => {
        modalRef.componentInstance.loading = true;
        const body = {
          emailList: data.emails,
          companyUuid: this.uuid,
          groupRestrictionUuid: data.group ? data.group : group.uuid,
          userUuid: this.userUuid,
        };
        this.userService.sendEmailsExternalUsers(body).subscribe(
          (res) => {
            modalRef.componentInstance.loading = false;
            modalRef.dismiss();
            this.translate
              .get([
                "common.understood",
                "company.company-detail.user-external.email-pass",
                "company.company-detail.user-external.subtitle",
              ])
              .subscribe((result) => {
                swal
                  .fire({
                    html: `<div id="understood-container" style="display: flex; flex-direction: column"><p>${result["company.company-detail.user-external.subtitle"]}</p><img data-enlargeable src="assets/img/examples/trm/user-enabled.png" alt="Imagen ejemplo de usuario habilitado"/></div>`,
                    allowOutsideClick: false,
                    confirmButtonText: result["common.understood"],
                    title:
                      result["company.company-detail.user-external.email-pass"],
                  })
                  .then((res) => {
                    this.loadGroups(this.company.uuid);
                  });
              });
          },
          (err) => {
            this.translate
              .get("company.company-detail.error-created-user")
              .subscribe((result) => {
                this.ngxToastrService.typeInfo(result, err.error.message);
              });
          }
        );
      },
      (err) => {
        modalRef.componentInstance.loading = false;
        this.translate
          .get("company.company-detail.error-created-user")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.message);
          });
      }
    );
  }

  resendEmailRegister(emailGroup: any){
    this.loading = true;

    const body = {
      emailList: [emailGroup.email],
      companyUuid: emailGroup.companyUuid,
      groupRestrictionUuid: emailGroup.groupRestrictionUuid,
    };

    this.userService.sendEmailsExternalUsers(body).subscribe(
      (res) => {
        this.loading = false;
        this.translate
          .get([
            "common.understood",
            "company.company-detail.user-external.email-pass",
            "company.company-detail.user-external.subtitle",
          ])
          .subscribe((result) => {
            swal
              .fire({
                html: `<div id="understood-container" style="display: flex; flex-direction: column"><p>${result["company.company-detail.user-external.subtitle"]}</p><img data-enlargeable src="assets/img/examples/trm/user-enabled.png" alt="Imagen ejemplo de usuario habilitado"/></div>`,
                allowOutsideClick: false,
                confirmButtonText: result["common.understood"],
                title:
                  result["company.company-detail.user-external.email-pass"],
              })
          });
      },
      (err) => {
        this.translate
          .get("company.company-detail.error-created-user")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.message);
          });
      });
  }

  onDeleteEmailInvitation(invitation: any){
    this.translate
      .get([
        "common.delete-invitation",
        "common.confirm",
        "common.cancel",
        "common.deleted-invitation",
        "common.deleted-invitation-error",
      ])
      .subscribe((result) => {
        swal
          .fire({
            title: result["common.delete-invitation"],
            showCancelButton: true,
            confirmButtonColor: "#10B981",
            cancelButtonColor: "#ED5A46",
            confirmButtonText: result["common.confirm"],
            cancelButtonText: result["common.cancel"],
            customClass: {
              confirmButton: "swal-button-squared swal-button-confirm-green",
              cancelButton: "swal-button-squared swal-button-dismiss-red",
            },
            buttonsStyling: false,
          })
          .then((action) => {
            if (action.value) {
              this.loading = true;
              this.companyService.deleteInvitation(invitation.email, invitation.companyUuid)
                  .subscribe(res => {
                    this.ngxToastrService.typeSuccess(
                      null,
                      result["common.deleted-invitation"]
                    );
                    this.loading = false;
                    this.loadGroups(this.companyUuid)
                  },(err) => {
                    this.ngxToastrService.typeInfo(
                      result["common.deleted-invitation-error"],
                      err.error.message
                    );
                  })
            }});
          });
  }

  onEditUserGroup(user: User, group: GroupRestriction) {
    const modalRef = this.modalService.open(UserFormGroupComponent, {
      centered: true,
    });
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.company = this.company;
    modalRef.componentInstance.group = group;
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.sendInfo.subscribe((userInfo) => {
      modalRef.componentInstance.loading = true;
      const groupRestrictionUuid = userInfo.group.uuid;
      delete userInfo.group;
      userInfo.groupRestrictionUuid = groupRestrictionUuid;
      this.userService.putUserGroup(user.uuid, userInfo).subscribe(
        () => {
          modalRef.componentInstance.loading = false;
          modalRef.dismiss("Send Info");
          this.translate
            .get("company.company-detail.user-edit")
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess(null, result);
            });
          this.loadGroups(this.company.uuid);
        },
        (err) => {
          modalRef.componentInstance.loading = false;
          this.translate
            .get("company.company-detail.error-user-edit")
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.message);
            });
        }
      );
    });
  }

  onEditUser(user: User, group: GroupRestriction) {
    /*const modalRef = this.modalService.open(UserFormComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.company = this.company;
    modalRef.componentInstance.group = group;
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.fromCompanyDetails = true;*/

    const navigationUser: NavigationExtras = {
      state: {
        loading: false,
        company: this.company,
        group: group,
        user: user,
        externalUser: this.user,
        fromCompanyDetails: true,
      },
    };

    this.router.navigate(["user/edit-user/", user.uuid], navigationUser);

    if (lodash.get(user, "birthdate")) {
      user.birthdate = momentTz(user.birthdate)
        .tz("Europe/Madrid")
        .format("YYYY-MM-DD");
    }
    if (lodash.get(user, "passportExpires")) {
      user.passportExpires = momentTz(user.passportExpires)
        .tz("Europe/Madrid")
        .format("YYYY-MM-DD");
    }

    /*modalRef.componentInstance.sendInfo.subscribe((userInfo) => {
      modalRef.componentInstance.loading = true;
      delete userInfo.companyUuid;
      delete userInfo.agencyUuid;
      delete userInfo.hubUuid;
      const groupRestrictionUuid = userInfo.group.uuid;
      delete userInfo.group;
      userInfo.groupRestrictionUuid = groupRestrictionUuid;
      this.userService.putUser(user.uuid, userInfo).subscribe(
        () => {
          modalRef.componentInstance.loading = false;
          modalRef.dismiss("Send Info");
          this.translate
            .get("company.company-detail.user-edit")
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess(null, result);
            });
          this.loadGroups(this.company.uuid);
        },
        (err) => {
          modalRef.componentInstance.loading = false;
          this.translate
            .get("company.company-detail.error-user-edit")
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.message);
            });
        }
      );
    });*/
  }

  onDeleteUser(user: User) {
    this.translate
      .get([
        "company.company-detail.remove-user",
        "company.company-detail.user-data-lost",
        "common.confirm",
        "common.cancel",
        "company.company-detail.user-removed",
        "company.company-detail.error-user-removed",
      ])
      .subscribe((result) => {
        swal
          .fire({
            title: result["company.company-detail.remove-user"],
            text: result["company.company-detail.user-data-lost"],
            showCancelButton: true,
            confirmButtonColor: "#0CC27E",
            cancelButtonColor: "#FF586B",
            confirmButtonText: result["common.confirm"],
            cancelButtonText: result["common.cancel"],
            customClass: {
              confirmButton: "swal-button-squared swal-button-confirm-green",
              cancelButton: "swal-button-squared swal-button-dismiss-red",
            },
            buttonsStyling: false,
          })
          .then((action) => {
            if (action.value) {
              this.userService.deleteUser(user.uuid).subscribe(
                () => {
                  this.ngxToastrService.typeSuccess(
                    null,
                    result["company.company-detail.user-removed"]
                  );
                  this.totalUsers = 0;
                  this.totalImages = 0;
                  this.hasNoImage = true;
                  this.allLoaded = false;
                  this.loadGroups(this.company.uuid);
                },
                (err) => {
                  this.ngxToastrService.typeInfo(
                    result["company.company-detail.error-user-removed"],
                    err.error.message
                  );
                }
              );
            }
          });
      });
  }

  onSendActivateEmail(user: User){
    this.userService.sendActivateEmail(user.uuid).subscribe(res => {
      this.ngxToastrService.typeSuccess(
        null,
        this.translateService.instant('company.company-detail.user-external.email-pass')
      );
    },
    (err) => {
      this.ngxToastrService.typeInfo(
        this.translateService.instant('company.company-detail.user-external.email-pass-error'),
        err.error.message
      );
    })
}

  checkTravelAndBookDates(fte: any) {
    const travelData = this.travelUtilsService.getTravelLocalStorage();
    if (travelData) {
      let initDate = fte.departDate;
      let endDate = fte.oneWay ? fte.departDate : fte.returnDate;
      const beginDate = new Date(travelData.data.initDate).toDateString();
      const finalDate = new Date(travelData.data.endDate).toDateString();
      initDate = new Date(initDate).toDateString();
      endDate = new Date(endDate).toDateString();
      if (beginDate > initDate && finalDate < endDate) {
        this.translate
          .get(["project.error-between-dates"])
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(
              "",
              result["project.error-between-dates"]
            );
          });
        return false;
      }
      return true;
    } else {
      return true;
    }
  }

  onNewCar(userUuid?) {
    localStorage.setItem("userToBook", this.utilsService.encrypt(userUuid));

    const modalRef = this.modalService.open(CarRequestComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.isModal = true;
    modalRef.componentInstance.companyUuid = this.company.uuid;
    modalRef.componentInstance.userUuid = userUuid;

    if (userUuid) {
      modalRef.componentInstance.userUuid = userUuid;
    }
  }

  handleTravelFlow(fte: any, type: string, userUuid: string) {
    const travelData = this.travelUtilsService.getTravelLocalStorage();
    const fteData = {
      uuid:
        type === "flight"
          ? fte.entityCreated?.uuid
          : type === "train"
          ? fte.entityCreated.uuid
          : type === "hotel"
          ? fte.uuid
          : type === "car"
          ? fte.carCreated.uuid
          : fte.expenseCreated.uuid,
    };
    if (travelData) {
      if (travelData.type?.toLowerCase() === "new") {
        if (userUuid) {
          travelData.data["userUuid"] = userUuid;
          fteData["userUuid"] = userUuid;
        }
        this.travelUtilsService.createTravel(travelData.data, fteData, type);
      } else {
        const addBody = {
          item: {
            type: type,
            uuid:
              type === "flight"
                ? fte.flightCreated.uuid
                : type === "train"
                ? fte.trainCreated.uuid
                : type === "hotel"
                ? fte.uuid
                : type === "car"
                ? fte.carCreated.uuid
                : fte.expenseCreated.uuid,
          },
        };
        this.travelUtilsService.addItemToTravel(addBody, travelData.data.uuid);
      }
    }
    this.travelUtilsService.removeTravelLocalStorage();
  }

  createCustomBooking(uuid: string) {
    const modalRef = this.modalService.open(RequestCustomHotelComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.userUuid = uuid;
    modalRef.componentInstance.isModal = true;

    this.subscription.push(
      modalRef.componentInstance.closeEvent.subscribe((s) => {
        modalRef.close();
      })
    );
  }

  setUserFilters(userUuid) {
    this.groups.forEach((g) => {
      g.users.forEach((u) => {
        if (u.uuid === userUuid) {
          const groupUser = { ...g };
          delete groupUser.users;
          this.authService.userFilters = groupUser.filters;
        }
      });
    });
  }

  onNewFlightTrain(type, userUuid?, manual?: boolean) {
    localStorage.setItem("userToBook", this.utilsService.encrypt(userUuid));
    this.setUserFilters(userUuid);
    if (type === "train") {
      if (manual) {
        const modalRef = this.modalService.open(FormFlightTrainComponent, {
          size: "lg",
          centered: true,
        });
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.isModal = true;
        modalRef.componentInstance.companyUuid = this.company.uuid;
        modalRef.componentInstance.isManual = true;

        if (userUuid) {
          modalRef.componentInstance.userUuid = userUuid;
        }
        type = "custom-train";
      } else {
        const dataFromCompany = {
          userUuid: userUuid,
          companyUuid: this.company.uuid,
          type: "train",
        };
        this.router.navigateByUrl(`${type}`, { state: { dataFromCompany } });
      }
    } else {
      if (manual) {
        const modalRef = this.modalService.open(FormFlightTrainComponent, {
          size: "lg",
          centered: true,
        });
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.isModal = true;
        modalRef.componentInstance.companyUuid = this.company.uuid;
        modalRef.componentInstance.isManual = true;
        modalRef.componentInstance.userUuid = userUuid;
      } else {
        const dataFromCompany = {
          type: type,
          userUuid: userUuid,
          companyUuid: this.company.uuid,
          isModal: false,
          isManual: false,
        };

        this.router.navigateByUrl(`${type}`, { state: { dataFromCompany } });
      }
    }
  }

  getPercentages() {
    this.getConfigsubscription.push(
      this.configService.getPercentageVyoo("percentageCompany").subscribe(
        (data: Config) => {
          this.configHotels = data;
          if (!this.configHotels.value) {
            this.configHotels.value = [17];
          }
          this.maxHotelPercentage = this.configHotels.value
            ? this.configHotels.value[0]
            : 17;
        },
        (err) => {
          this.ngxToastrService.typeInfo(
            "Error",
            this.translate.instant("common.some-error")
          );
        }
      ),
      this.configService.getPercentageVyoo("flightCommissions").subscribe(
        (data: Config) => {
          this.configFlights = data;
          if (!this.configFlights.value) {
            this.configFlights.value = [
              {
                local_emission: 5,
                eu_emission: 7,
                world_emission: 9,
                percentage: 5,
              },
            ];
          }

          this.maxFlightPercentage = this.configFlights.value[0].percentage;
        },
        (err) => {
          this.ngxToastrService.typeInfo(
            "Error",
            this.translate.instant("common.some-error")
          );
        }
      )
    );
  }
  getProfile() {
    this.authService.getProfile().subscribe((res: User | Company | Agency) => {
      this.userOrEmployee = res;
      this.isGroupAdmin =
        res.type === "USER" && res.isGroupAdmin ? res.isGroupAdmin : false;
    });
  }

  editCompany(group: GroupRestriction) {
    const extras: NavigationExtras = {
      state: {
        loading: false,
        company: this.company,
        maxHotelPercentage: 100,
        maxFlightPercentage: 100,
      },
    };

    this.router.navigate(["/companies/edit-company/"], extras);

    /*const modalRef = this.modalService.open(CompanyFormComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.company = this.company;
    modalRef.componentInstance.maxHotelPercentage = 100;
    modalRef.componentInstance.maxFlightPercentage = this.maxFlightPercentage;
    modalRef.componentInstance.sendInfo.subscribe((companyInfo) => {
      if (companyInfo) {
        modalRef.componentInstance.loading = true;
        let attributes = {};
        const general = {
          name: this.company.name,
          cif: this.company.cif,
          email: this.company.email,
          address: this.company.address,
          city: this.company.city,
          province: this.company.province,
          postalCode: this.company.postalCode,
          country: this.company.country,
          discount: this.company,
          agencyUuid: this.company?.agencyUuid,
        };
        if (this.isCompany) {
          delete general.discount;
        }
        if (!_lodash.isEqual(this.company.billingData, companyInfo.billing)) {
          attributes["billingData"] = companyInfo.billing;
        }
        if (!_lodash.isEqual(general, companyInfo.general)) {
          attributes = { ...attributes, ...companyInfo.general };
        }
        if (!_lodash.isEmpty(attributes)) {
          if (attributes["discount"]) {
            attributes["discount"] = attributes["discount"].toString();
          }
          if (attributes["flightDiscount"] >= 0) {
            attributes["flightDiscount"] =
              attributes["flightDiscount"].toString();
          }
          if (attributes["hotelComission"] >= 0) {
            attributes["hotelComission"] =
              attributes["hotelComission"].toString();
          }
          if (attributes["trainComission"] >= 0) {
            attributes["trainComission"] =
              attributes["trainComission"].toString();
          }
          const image = attributes["image"];
          delete attributes["image"];
          let formData = new FormData();
          formData = this.utilsService.toFormData(attributes, formData);
          if (image && image.name) {
            formData.append("file", image, image.name);
          }
          const putCompany = this.companyService.putCompany(
            formData,
            this.company.uuid
          );
          putCompany.subscribe(
            () => {
              modalRef.componentInstance.loading = false;
              modalRef.dismiss("Send Info");
              this.translate
                .get("company.company-homepage.card.edited-company")
                .subscribe((result) => {
                  this.ngxToastrService.typeSuccess(null, result);
                  if (this.reloadCompanies) {
                    this.reloadCompanies.emit();
                  }
                });
            },
            (err) => {
              modalRef.componentInstance.loading = false;
              this.translate
                .get("company.company-homepage.card.error-edit-company")
                .subscribe((result) => {
                  this.ngxToastrService.typeInfo(result, err.error.message);
                });
            }
          );
        } else {
          modalRef.componentInstance.loading = false;
          modalRef.close();
        }
      } else {
        this.reloadCompanies.emit();
      }
    });*/
  }

  seeReports() {
    const route =
      this.userType?.toUpperCase() === "COMPANY"
        ? "my-company/reports"
        : `companies/${this.company.uuid}/reports`;
    this.router.navigate([route]);
  }

  seeUserBooked(userId) {
    this.router.navigate(["user/booked/", userId], {
      state: { isAdmin: this.userType === "COMPANY" ? false : true },
    });
  }

  createUserBooking(userId) {
    localStorage.setItem("userToBook", this.utilsService.encrypt(userId));
    this.setUserFilters(userId);
    this.store.dispatch(new ResetFiltersAction());
    this.router.navigate([`search/${userId}`]);
  }

  onNewCustomService(groupId, userId) {
    const groupUser = this.groups.filter((group) => group.uuid === groupId)[0];
    this.authService.userFilters = groupUser.filters;
    localStorage.setItem("userToBook", this.utilsService.encrypt(userId));
    this.store.dispatch(new ResetFiltersAction());
    const navigationExtra: NavigationExtras = {
      queryParams: {
        companyUuid: this.uuid,
      },
    };
    this.router.navigate([`custom-services/${userId}`], navigationExtra);
  }

  goToDashboard(name: string, type: string, uuid: string) {
    const dataDashboard = {
      name,
      type,
      uuid,
      fromSuperAdmin: this.userType === "COMPANY" ? false : true,
    };
    const route =
      this.userType === "COMPANY"
        ? "my-company/dashboard"
        : `companies/${this.company.uuid}/dashboard`;
    this.router.navigateByUrl(route, { state: { dataDashboard } });
  }

  seeAuthorizations() {
    const route = !this.isAdmin
      ? "my-company/authorizations"
      : `companies/${this.company.uuid}/authorizations`;
    this.router.navigateByUrl(route);
  }

  getAuthorizationList() {
    if (this.type?.toLowerCase() === "all") {
      delete this.params["type"];
    }
    if (this.isAdmin) {
      this.params["companyUuid"] = this.route.snapshot.paramMap.get("uuid");
    }
    this.subscription.push(
      this.bookedService.getAuthorization(this.params).subscribe((result) => {
        if (result && result.length > 0) {
          result.forEach((doc) => {
            if (doc.status == "request") {
              this.totalAuthorizations = result.length;
              this.haveRequest = true;
            } else {
              this.haveRequest = false;
            }
          });
        } else {
          this.haveRequest = false;
        }
        this.authorizations = result;
        this.pagination = result;
        delete this.pagination.docs;
      })
    );
  }

  changeActive(user: User) {
    if (this.isAdmin || this.isCompany) {
      this.userService
        .putUser(user.uuid, { dni: user.dni, active: !user.active })
        .subscribe(() => {
          this.totalUsers = 0;
          this.totalImages = 0;
          this.hasNoImage = true;
          this.allLoaded = false;
          this.loadGroups(this.company.uuid);
        });
    }
  }

  hasPdf(entity: any, type: string) {
    if (
      (entity.voucherFileUrl &&
        entity.voucherFileUrl !== "" &&
        entity.voucherFileUrl !== `/media/${type}/undefined`) ||
      (entity.voucherFileUuid && entity.voucherFileUuid !== "")
    ) {
      return true;
    } else {
      return false;
    }
  }

  onGetPDF(booked: any) {
    const url = booked.voucherFileUrl
      ? booked.voucherFileUrl
      : `/media/${booked["voucherFileUuid"]}/custom`;
    this.bookedService
      .getBookedsPdf(url)
      .pipe(take(1))
      .subscribe(
        (res) => {
          const modalRef = this.modalService.open(ViewPdfModalComponent, {
            size: "lg",
            centered: true,
          });
          modalRef.componentInstance.blobUrl = URL.createObjectURL(res);
          modalRef.componentInstance.fileName = booked.voucherFileUuid;
        },
        () =>
          this.translate
            .get(["common.error-pdf"])
            .pipe(take(1))
            .subscribe((value) =>
              this.ngxToastrService.typeInfo(null, value["common.error-pdf"])
            )
      );
  }

  openUserStorage(user) {
    this.userService.getUser(user.uuid).subscribe(
      (userRes) => {
        /* const dialogRef =  */ this.dialog.open(DialogUserFilesComponent, {
          height: "90vh",
          width: "90vw",
          data: { user: userRes },
          panelClass: 'files-dialog'
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }

  private readAsDataURL(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Invalid result type from FileReader.'));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async getUserProfilePicture(user: User) {
    try {
      if (user.image && user.image !== 'undefined') {
        const media = await this.mediaService.getMedia(user.image).toPromise();
        const base64data = await this.readAsDataURL(media);
        user.image = base64data;
        this.totalImages++;
        if (this.totalImages === this.totalUsers) {
          this.allLoaded = true;
        }
      } else {
        user.image = null;
        this.totalImages++;
      }
    } catch (error) {
      console.error('Error loading image:', error);
      user.image = null;
      this.totalImages++;
    }
  }

  getGroupAdmin(group: any) {
    const users = group.users.filter((u) => u.isGroupAdmin);
    return users;
  }

  getRequestList() {
    const param = {};
    if (this.isAdmin) {
      param["companyUuid"] = this.route.snapshot.paramMap.get("uuid");
    } else if (this.isCompany) {
      param["companyUuid"] = this.uuid;
    }
    this.subscription.push(
      this.requestService.getRequests(param).subscribe(async (result: any) => {
        this.requests = await result;
        this.totalRequests = this.requests.filter(
          (doc) => doc.status == "active"
        ).length;
      })
    );
  }

  goToRequests() {
    const route = !this.isAdmin
      ? "my-company/requests"
      : `companies/${this.company.uuid}/requests`;
    this.router.navigateByUrl(route);
  }

  adminFormFlightTrain(type, userUuid?) {
    const modalRef = this.modalService.open(CreateFlightTrainComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.isAdmin = this.isAdmin;
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.companyUuid = this.company.uuid;
    if (userUuid) {
      modalRef.componentInstance.userId = userUuid;
    }
    modalRef.componentInstance.sendInfo.subscribe((info) => {
      modalRef.componentInstance.loading = true;
      const formData = new FormData();
      if(info.file){
        formData.append("file", info.file, info.file.name);
      }
      formData.append("companyUuid", info.companyUuid);
      formData.append("userUuid", info.userId);
      formData.append("from", info.origin);
      formData.append("to", info.destination);
      formData.append("departDate", info.departure);
      formData.append("observations", info.annotations);
      formData.append("price", info.price);
      formData.append("oneWay", info.oneWay);
      formData.append("freeTaxes", info.freeTaxes);
      if (info.travelName) {
        formData.append("travelName", info.travelName);
      }
      if (!info.oneWay) {
        formData.append("returnDate", info.return);
      }
      if(info.traveller) {
        formData.append ("traveller", JSON.stringify(info.traveller))
      }
      formData.append("customCode", info.customCode);
      formData.append("costCenter", info.costCenter);
      const fte = {
        departDate: info.departure,
        returnDate: info.return,
        oneWay: false,
      };

      if(type === 'flight') {
        formData.append("newCustom", "true");
      }
      
      if (this.checkTravelAndBookDates(fte)) {
        const postCreate =
          type === "train"
            ? this.trainService.postCreateTrain(formData)
            : this.flightService.postCreateFlight(formData);
        postCreate.subscribe(
          (result) => {
            modalRef.componentInstance.loading = false;
            modalRef.dismiss("Send Info");
            this.handleTravelFlow(result, type, userUuid);
            this.translate
              .get(["common.congrat", `${type}.create-${type}.created-${type}`])
              .subscribe((resp) => {
                this.ngxToastrService.typeSuccess(
                  resp["common.congrat"],
                  resp[`${type}.create-${type}.created-${type}`]
                );
                const navigationExtras: NavigationExtras = {
                  state: {
                    module: type === "train" ? "trains" : "flights",
                  },
                };
                if (result.trainCreated) {
                  if (this.hasPdf(result.trainCreated, "train")) {
                    this.onGetPDF(result.trainCreated);
                  }
                } else if (result.flightCreated) {
                  if (this.hasPdf(result.flightCreated, "flight")) {
                    this.onGetPDF(result.flightCreated);
                  }
                }
                if (!this.isAdmin) {
                  this.router.navigate([`/my-bookeds`], navigationExtras);
                } else {
                  this.router.navigate(
                    [`/user/booked/${userUuid}`],
                    navigationExtras
                  );
                }
              });
          },
          (err) => {
            modalRef.componentInstance.loading = false;
            modalRef.close();
            this.ngxToastrService.typeInfo("ERROR", err.error.message ? err.error.message : err.error.text);
          }
        );
      } else {
        modalRef.componentInstance.loading = false;
      }
    });
  }

  adminFormCreateCar(userUuid?) {
    const modalRef = this.modalService.open(CreateCarComponent, {
      size: "lg",
      centered: true,
    });

    modalRef.componentInstance.isAdmin = this.isAdmin;
    modalRef.componentInstance.loading = false;
    if (userUuid) {
      modalRef.componentInstance.userId = userUuid;
    }
    modalRef.componentInstance.sendInfo.subscribe((info) => {
      modalRef.componentInstance.loading = true;
      const formData = new FormData();
      if(info.file){
        formData.append("file", info.file, info.file.name);
      }
      formData.append("userUuid", info.userUuid);
      formData.append("from", info.from);
      formData.append("to", info.to);
      formData.append("pickupPlaceFrom", info.pickupPlaceFrom);
      formData.append("pickupPlaceTo", info.pickupPlaceTo);
      formData.append("price", info.price);
      formData.append("age", info.age);
      if (info.travelName) {
        formData.append("travelName", info.travelName);
      }
      formData.append("freeTaxes", info.freeTaxes);
      formData.append("departDate", info.departDate);
      formData.append("returnDate", info.returnDate);
      formData.append("provider", info.provider);
      formData.append("carType", info.carType);
      formData.append("insuranceWithExcess", info.insuranceWithExcess);
      formData.append("comments", info.comments);
      formData.append("customCode", info.customCode);
      formData.append("costCenter", info.costCenter);
      if(info.traveller) {
        formData.append ("traveller", JSON.stringify(info.traveller));
      }
      const fte = {
        departDate: info.departDate,
        returnDate: info.returnDate,
        oneWay: false,
      };
      if (this.checkTravelAndBookDates(fte)) {
        this.subscription.push(
          this.carService.createCustomCar(formData).subscribe(
            (car) => {
              modalRef.componentInstance.loading = false;
              modalRef.dismiss("Send Info");
              this.handleTravelFlow(car, "car", userUuid);
              this.subscription.push(
                this.translate
                  .get(["car.create-car.created-car"])
                  .subscribe((result) => {
                    this.ngxToastrService.typeSuccess(
                      "",
                      result["car.create-car.created-car"]
                    );
                    modalRef.dismiss();
                    const navigationExtras: NavigationExtras = {
                      state: {
                        module: "cars",
                      },
                    };
                    if (this.hasPdf(car.carCreated, "car")) {
                      this.onGetPDF(car.carCreated);
                    }
                    if (!this.isAdmin) {
                      this.router.navigate([`/my-bookeds`], navigationExtras);
                    } else {
                      this.router.navigate(
                        [`/user/booked/${userUuid}`],
                        navigationExtras
                      );
                    }
                  })
              );
            },
            (err) => {
              modalRef.componentInstance.loading = false;
              modalRef.close();
              this.ngxToastrService.typeInfo("ERROR", err.error.message ? err.error.message : err.error.text);
            }
          )
        );
      } else {
        modalRef.componentInstance.loading = false;
      }
    });
  }

  onNewCustomBooking(userUuid: string) {
    const modalRef = this.modalService.open(CreateCustomHotelComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.userId = userUuid;
    modalRef.componentInstance.isAdmin = this.isSuperAdmin;
    modalRef.componentInstance.companyUuid = this.companyUuid;
    modalRef.componentInstance.sendInfo.subscribe((info) => {
      modalRef.componentInstance.loading = true;
      const formData = new FormData();
      if(info.file){
        formData.append("file", info.file, info.file.name);
      }
      formData.append("userUuid", userUuid);
      formData.append("checkIn", info.checkIn);
      formData.append("checkOut", info.checkOut);
      formData.append("price", info.price);
      formData.append("destinationName", info.city);
      formData.append("hotelName", info.hotel);
      formData.append("freeTaxes", info.freeTaxes);
      if (info.comments) {
        formData.append("comments", info.comments);
      }
      if (info.travelName) {
        formData.append("travelName", info.travelName);
      }
      formData.append("customCode", info.customCode);
      formData.append("costCenter", info.costCenter);
      if(info.people) {
        formData.append("people", info.people);
      }
      if(info.rooms) {
        formData.append("rooms", info.rooms);
      }
      if(info.holder) {
        formData.append ("holder", JSON.stringify(info.holder))
      }
      const fte = {
        departDate: info.checkIn,
        returnDate: info.checkOut,
        oneWay: false,
      };

      formData.append("newCustom", "true");
      formData.append('type', 'hotel');
      
      if (this.checkTravelAndBookDates(fte)) {
        this.subscription.push(
          this.bookedService.createCustomBooking(formData).subscribe(
            (s) => {
              this.handleTravelFlow(s, "hotel", userUuid);
              this.subscription.push(
                this.translate
                  .get(["common.congrat", "hotel.custom.created"])
                  .subscribe((resp) => {
                    modalRef.componentInstance.loading = false;
                    this.ngxToastrService.typeSuccess(
                      resp["common.congrat"],
                      resp["hotel.create-custom.success"]
                    );
                    modalRef.close();
                    const navigationExtras: NavigationExtras = {
                      state: {
                        module: "hotels",
                      },
                    };
                    if (this.hasPdf(s, "hotel")) {
                      this.onGetPDF(s);
                    }
                    if (!this.isAdmin) {
                      this.router.navigate([`/my-bookeds`], navigationExtras);
                    } else {
                      this.router.navigate(
                        [`/user/booked/${userUuid}`],
                        navigationExtras
                      );
                    }
                  })
              );
            },
            (err) => {
              modalRef.componentInstance.loading = false;
              modalRef.close();
              this.ngxToastrService.typeInfo("ERROR", err.error.message ? err.error.message : err.error.text);
            }
          )
        );
      } else {
        modalRef.componentInstance.loading = false;
      }
    });
  }

  areMoreUserOnGroup(group: GroupRestriction) {
    // Lista administradores
    const admins = this.getGroupAdmin(group);
    // Tengo que ver que la lista de usuario contenga algun usuario que no esté en admin
    const res = group.users.filter((user) => {
      if (!admins.includes(user)) {
        return true;
      }
    });
    return res.length > 0;
  }

  canRequestAirbnb(group: any) {
    return (
      group.filters.lodgingType === "all" ||
      group.filters.lodgingType === "room"
    );
  }

  toggleContent() {
    this.showContent = !this.showContent;
  }

  adminFormCreateAirbnb(userUuid: string, groupFilters: any) {
    const type = "airbnb";
    const modalRef = this.modalService.open(CreateAirbnbComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.isAdmin = this.isAdmin;
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.companyUuid = this.company.uuid;
    if (userUuid) {
      modalRef.componentInstance.userId = userUuid;
    }
    modalRef.componentInstance.filters = groupFilters;
    modalRef.componentInstance.sendInfo.subscribe((info) => {
      modalRef.componentInstance.loading = true;
      const formData = new FormData();
      if(info.file){
        formData.append("file", info.file, info.file.name);
      }
      formData.append("companyUuid", info.companyUuid);
      formData.append("userUuid", info.userId);
      formData.append("city", info.city);
      formData.append("link", info.link);
      formData.append("checkIn", info.checkIn);
      formData.append("checkOut", info.checkOut);
      formData.append("observations", info.annotations);
      formData.append("price", info.price);
      formData.append("freeTaxes", info.freeTaxes);
      if (info.travelName) {
        formData.append("travelName", info.travelName);
      }
      formData.append("customCode", info.customCode);
      formData.append("costCenter", info.costCenter);
      const fte = {
        checkIn: info.checkIn,
        returnDate: info.checkOut,
        oneWay: false,
      };
      if (this.checkTravelAndBookDates(fte)) {
        const postCreate = this.airbnbService.createCustomAirbnb(formData);
        postCreate.subscribe(
          (result) => {
            modalRef.componentInstance.loading = false;
            modalRef.dismiss("Send Info");
            this.handleTravelFlow(result, type, userUuid);
            this.translate
              .get(["common.congrat", `${type}.create-${type}.created-${type}`])
              .subscribe((resp) => {
                this.ngxToastrService.typeSuccess(
                  resp["common.congrat"],
                  resp[`${type}.create-${type}.created-${type}`]
                );
                const navigationExtras: NavigationExtras = {
                  state: {
                    module: type,
                  },
                };
                if (result.entityCreated) {
                  this.onGetPDF(result.entityCreated);
                }
                if (!this.isAdmin) {
                  this.router.navigate([`/my-bookeds`], navigationExtras);
                } else {
                  this.router.navigate(
                    [`/user/booked/${userUuid}`],
                    navigationExtras
                  );
                }
              });
          },
          (err) => {
            modalRef.componentInstance.loading = false;
            this.ngxToastrService.typeInfo("ERROR", err.error.message);
          }
        );
      } else {
        modalRef.componentInstance.loading = false;
      }
    });
  }

  goToProjects() {
    const route =
    this.userType?.toUpperCase() === "COMPANY"
      ? "my-company/projects"
      : `companies/${this.company.uuid}/projects`;
    this.router.navigate([route]);
  }

  goToBookings() {
    const route =
      this.userType?.toUpperCase() === "COMPANY"
        ? "my-company/bookings"
        : `companies/${this.company.uuid}/bookings`;
    this.router.navigate([route]);
  }
}
