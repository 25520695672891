import { ActionReducerMap } from "@ngrx/store";
import * as fromAgencyReducer from "./store/reducers/agency.reducer";
import * as fromAuthReducer from "./store/reducers/auth.reducer";
import * as fromHubReducer from "./store/reducers/hub.reducer";
import * as fromSearchReducer from "./store/reducers/search.reducer";
export interface AppState {
  auth?: fromAuthReducer.AuthState;
  search?: fromSearchReducer.SearchState;
  agency?: fromAgencyReducer.AgencyState;
  hub?: fromHubReducer.HubState;
}

export const appReducers: ActionReducerMap<AppState> = {
  auth: fromAuthReducer.AuthReducer,
};
