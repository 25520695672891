import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-accommodation-guests',
  templateUrl: './accommodation-guests.component.html',
  styleUrls: ['./accommodation-guests.component.scss']
})
export class AccommodationGuestsComponent implements OnInit, OnDestroy {
  @Output() sendInfo = new EventEmitter<any>()
  @Input() guests: any[] = [];
  
  constructor (
  ) {
  }

  ngOnDestroy (): void {}

  ngOnInit () {
  }

  onSubmit () {
    this.sendInfo.emit(this.guests)
  }

  trackByBookList(index: number, instance: any): any {
    return index;
  }

  changeGuest(event, g, attribute) {
    this.guests[g][attribute] = event.target.value;
  }
}
