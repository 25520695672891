import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GroupService, UtilsService } from "@services";
import { Subscription } from "rxjs";

@Component({
  selector: "app-travel-add-user",
  templateUrl: "./travel-add-user.component.html",
  styleUrls: ["./travel-add-user.component.scss"],
})
export class TravelAddUserComponent implements OnInit, OnDestroy {
  @Input() uuid: string;
  @Input() uuidUser: string;
  @Input() travel: any;
  @Output() sendInfo = new EventEmitter<any>();
  loaded = false;
  userSelectedUuid = "none";
  users = [];
  subscriptions: Subscription[] = [];

  constructor(
    private groupService: GroupService,
    private modalService: NgbActiveModal,
    public utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.groupService.getGroups(this.uuid).subscribe((groups: any) => {
        groups.forEach((g) => {
          g.users.forEach((u) => {
            if (!this.travel.usersUuid.includes(u.uuid)) {
              this.users.push(u);
            }
          });
        });
      })
    );
  }

  selectUser(userUuid: string) {
    this.userSelectedUuid = userUuid;
  }

  closeModal() {
    this.modalService.close();
  }

  onSubmit() {
    if (this.userSelectedUuid !== "none") {
      const body = {
        item: {
          type: "user",
          uuid: this.userSelectedUuid,
        },
        userUuid: this.userSelectedUuid,
      };
      this.sendInfo.emit(body);
    }
    this.closeModal();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
