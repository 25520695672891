import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-passenger-form",
  templateUrl: "./passenger-form.component.html",
  styleUrls: ["./passenger-form.component.scss"],
})
export class PassengerFormComponent implements OnInit {
  @Input() passengerForm: UntypedFormGroup;
  documentTypeOptions = [
    { value: "PASSPORT", text: "Pasaporte" },
    { value: "IDENTITY_CARD", text: "DNI/NIF" },
    { value: "NIE", text: "NIE" },
  ];

  constructor() {}

  ngOnInit(): void {}

  get formArr() {
    return this.passengerForm.get("users") as UntypedFormArray;
  }

  selectDocumentType(value: any, userIdx: number) {
    this.formArr.controls[userIdx].patchValue({ documentType: value.text });
  }
}
