import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CanNavigateGuard } from "@core/guards/can-navigate.guard";
import { TrainConfirmStepperContainerComponent } from "./train-confirm-stepper-container/train-confirm-stepper-container.component";
import { TrainSearchResultComponent } from "./train-search-result/train-search-result.component";
import { TrainsComponent } from "./trains.component";

const routes: Routes = [
  {
    path: "",
    component: TrainsComponent,
    canActivate: [CanNavigateGuard],
  },
  {
    path: "search-result",
    component: TrainSearchResultComponent,
  },
  {
    path: "confirm-form",
    component: TrainConfirmStepperContainerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanNavigateGuard],
})
export class TrainRoutingModule {}
