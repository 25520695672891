import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { UserService } from "@services";
import { AppState } from "app/app.reducer";
import { AuthState } from "app/store/reducers/auth.reducer";
import { Subscription } from "rxjs";

@Component({
  selector: "app-empty",
  templateUrl: "./empty.component.html",
})
export class EmptyComponent implements OnInit, OnDestroy {
  authSubscription: Subscription;
  groupSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private userService: UserService
  ) {}
  ngOnInit(): void {
    this.loadUser();
  }

  loadUser() {
    this.authSubscription = this.store
      .select("auth")
      .subscribe((authState: AuthState) => {
        if (authState.isAuthenticated) {
          switch (authState.user.type?.toUpperCase()) {
            case "USER":
              this.groupSubscription = this.userService
                .getGroupUser(authState.user.uuid)
                .subscribe((group) => {
                  const url = group.filters.requestBooked
                    ? "/search"
                    : "/my-bookeds";
                  this.router.navigate([url]);
                });
              break;
            case "COMPANY":
              const user: any = authState.user;
              const canUseTRM = user.type
                ? user.plan && user.plan.permissions.canUseTRM
                : user.user.plan && user.user.plan.permissions.canUseTRM;
              if (canUseTRM) {
                this.router.navigate(["/my-company/details"]);
              } else {
                this.groupSubscription = this.userService
                  .getGroupUser(authState.user.uuid)
                  .subscribe((group) => {
                    const url = group.filters.requestBooked
                      ? "/search"
                      : "/user/booked/" + user.type
                      ? user.uuid
                      : user.user.uuid;
                    this.router.navigate([url]);
                  });
              }
              break;
            case "AGENCY":
              this.router.navigate(["/companies"]);
              break;
            case "HUB":
              this.router.navigate(["/agencies"]);
              break;
            case "EMPLOYEE":
              this.router.navigate(["/companies"]);
              break;
            default:
              this.router.navigate(["/login"]);
              break;
          }
        } else {
          this.router.navigate(["/login"]);
        }
      });
  }
  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.groupSubscription) {
      this.groupSubscription.unsubscribe();
    }
  }
}
