<div class="modal-body modal-style">
  <div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div>
    <div class="row">
      <div class="col-12 title">
        <h5 class="heading-5">
          <div class="icon-bcg">
            <img src="assets/img/svg/clipboard.svg" />
          </div>
          {{ "company.company-booked.booked-details" | translate }}
        </h5>
      </div>
    </div>
    <div *ngIf="booking">
      <div>
        <!-- DETALLES DE LA RESERVA -->
        <div *ngIf="loading" style="display: block; margin: auto">
          <app-loading [userType]="'employee'"></app-loading>
        </div>
        <!--AUTOMATIZED HOTEL-->
        <ng-container
          *ngIf="type === 'flight' || type === 'train' || type === 'car'"
        >
          <div class="title-content-container">
            <div class="title">
              <div>
                {{ "my-bookeds.train-flight.origin" | translate }}
              </div>
            </div>
            <div class="content">
              <span class="labels">{{ booking.from }}</span>
              <a
                class="external-link"
                [href]="createGMapsLink(booking.from)"
                target="_blank"
                [title]="'common.external-link' | translate"
                ><img
                  src="assets/img/svg/open-external-link-icon.svg"
                  alt=""
                  width="10px"
              /></a>
            </div>
          </div>
          <div class="title-content-container">
            <div class="title">
              <div>
                {{ "my-bookeds.train-flight.destiny" | translate }}
              </div>
            </div>
            <div class="content">
              <span class="labels">{{ booking.to }}</span>
              <a
                class="external-link"
                [href]="createGMapsLink(booking.to)"
                target="_blank"
                [title]="'common.external-link' | translate"
                ><img
                  src="assets/img/svg/open-external-link-icon.svg"
                  alt=""
                  width="10px"
              /></a>
            </div>
          </div>
        </ng-container>
        <!--CAR-->
        <ng-container *ngIf="type === 'car'">
          <div class="title-content-container">
            <div class="title">
              {{ "car.vehicle-type" | translate }}
            </div>
            <div class="content">
              <div class="form-group mr-2">
                <span class="labels">{{
                  ( booking.carType === "suv"?
                  "car.custom.request.carType.suv"
                    : booking.carType === "touring"?
                    "car.custom.request.carType.touring"
                    : "car.custom.request.carType.van"
                  ) | translate
                }}</span>
              </div>
            </div>
          </div>
          <div class="title-content-container">
            <div class="title">
              {{ "car.custom.request.pickup.titleFrom" | translate }}
            </div>
            <div class="content">
              <span class="labels">{{
                (booking.pickupPlaceFrom === "airport"
                  ? "car.custom.request.pickup.airport"
                  : booking.pickupPlaceFrom === "trainStation"
                  ? "car.custom.request.pickup.trainStation"
                  : "car.custom.request.pickup.office"
                ) | translate
              }}</span>
            </div>
          </div>
          <div class="title-content-container">
            <div class="title">
              {{ "car.custom.request.pickup.titleTo" | translate }}
            </div>
            <div class="content">
              <span class="labels">{{
                (booking.pickupPlaceTo === "airport"
                  ? "car.custom.request.pickup.airport"
                  : booking.pickupPlaceFrom === "trainStation"
                  ? "car.custom.request.pickup.trainStation"
                  : "car.custom.request.pickup.office"
                ) | translate
              }}</span>
            </div>
          </div>
        </ng-container>
        <div class="title-content-container">
          <div class="title">
            {{ "car.custom.request.total" | translate }}
          </div>
          <div class="content">
            <span class="labels">{{
              booking.price || booking.amount | price
            }}</span>
          </div>
        </div>
        <!-- EXPENSE -->
        <div class="title-content-container" *ngIf="type === 'expense'">
          <div class="title">
            {{ "authorization.labels.from" | translate }}
          </div>
          <div class="content">
            <span class="labels">{{booking.beginDate | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY"}}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="type === 'expense'">
          <div class="title">
            {{ "authorization.labels.to" | translate }}
          </div>
          <div class="content">
            <span class="labels">{{booking.endDate | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY"}}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="type === 'expense' && isEmployee && booking.order">
          <div class="title">
            {{"company.booked.order-code" | translate}}
          </div>
          <div class="content">
            <span class="labels">{{booking.order}}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="type === 'expense' && booking.locator">
          <div class="title">{{"my-bookeds.train-flight.locator" | translate}}</div>
          <div class="content">
            <span>{{booking.locator}}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="type === 'expense' && booking.costCenter">
          <div class="title">
            {{"common.cost-center" | translate}}
          </div>
          <div class="content">
            <span class="labels">{{booking.costCenter}}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="type === 'expense' && booking.customCode">
          <div class="title">
            {{"common.custom-code" | translate}}
          </div>
          <div class="content">
            <span class="labels">{{booking.customCode}}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="type === 'expense'">
          <div class="title">
            {{ "my-bookeds.train-flight.status" | translate }}
          </div>
          <div
            class="content"
            [ngClass]="{
              danger: booking?.status && booking?.status?.toLowerCase() === 'cancelled',
              success: !booking?.status || (booking?.status && booking?.status?.toLowerCase() === 'active' || booking?.status?.toLowerCase() === 'confirmed'),
              info: booking?.status && booking?.status?.toLowerCase() === 'pending'
            }"
          >
            <span class="labels">{{
              (!booking?.status || (booking?.status && booking?.status?.toLowerCase() === 'active' || booking?.status?.toLowerCase() === 'confirmed')
                ? "common.confirmed"
                : "common." + booking?.status
              ) | translate
            }}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="type === 'expense'">
          <div class="title">
            Tipo
          </div>
          <div class="content">
            <span class="labels">{{booking.type}}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="type === 'expense'">
          <div class="title">
            {{'my-bookeds.flight.travellers' | translate}}
          </div>
          <div class="content">
            <span class="labels">{{(booking.user ? (booking.user.name | titlecase) + ' ' + (booking.user.lastname | titlecase) 
                                  : (booking.userName ? (booking.userName | titlecase) : (booking.holder ? (booking.holder.name + ' ' + booking.holder.surname) : '')))}}</span>
          </div>
        </div>
        <!--HOTEL FLIGHT TRAIN-->
        <div
          class="title-content-container"
          *ngIf="type !== 'car' && type !== 'expense'"
        >
          <div class="title">
            {{ "my-bookeds.train-flight.status" | translate }}
          </div>
          <div
            class="content"
            [ngClass]="{
              danger: booking?.status && booking?.status?.toLowerCase() === 'cancelled',
              success: !booking?.status || (booking?.status && booking?.status?.toLowerCase() === 'active' || booking?.status?.toLowerCase() === 'confirmed'),
              info: booking?.status && booking?.status?.toLowerCase() === 'pending'
            }"
          >
            <span class="labels">{{
              (!booking?.status || (booking?.status && booking?.status?.toLowerCase() === 'active' || booking?.status?.toLowerCase() === 'confirmed')
                  ? 'common.confirmed'
                  : 'common.' + booking?.status
              ) | translate
          }}</span>
          </div>
        </div>
        <div
          *ngIf="type === 'flight' || type === 'train'"
          class="title-content-container"
        >
          <div class="title">
            {{ "my-bookeds.train-flight.type-travel" | translate }}
          </div>
          <div class="content">
            <span class="labels">{{
              (booking.oneWay
                ? "my-bookeds.train-flight.oneWay"
                : "my-bookeds.train-flight.notOneWay"
              ) | translate
            }}</span>
          </div>
        </div>
        <!--HOTEL FLIGHT TRAIN CAR-->
        <ng-container *ngIf="type !== 'expense'">
          <div class="title-content-container">
            <div class="title" *ngIf="type !== 'flight'">
              {{ "my-bookeds.train-flight.dep-date" | translate }}
            </div>
            <div class="title" *ngIf="type === 'flight'">
              {{ "my-bookeds.train-flight.dep-date" | translate }} <span class="light-text">({{ "flight.result-flight.flight-timezone" | translate }})</span>
            </div>
            <div class="content">
              <span class="labels">{{
                booking.checkIn
                  ? (booking.checkIn | dateMoment : "YYYY-MM-DD")
                  : booking.departDate
                  ? getDate(booking.departDate)
                  : booking.beginDate
                  ? getDate(booking.beginDate)
                  : ""
              }}</span>
            </div>
          </div>
          <div
            class="title-content-container"
            *ngIf="booking.returnDate || booking.endDate || booking.checkOut"
          >
            <div class="title" *ngIf="type?.toLowerCase() !== 'flight'">
              {{ "my-bookeds.train-flight.ret-date" | translate }}
            </div>
            <div class="title" *ngIf="type?.toLowerCase() === 'flight'">
              {{ "my-bookeds.train-flight.ret-date" | translate }} <span class="light-text">({{ "flight.result-flight.flight-timezone" | translate }})</span>
            </div>
            <div class="content">
              <span class="labels">{{
                booking.checkOut
                  ? (booking.checkOut | dateMoment : "YYYY-MM-DD")
                  : booking.returnDate
                  ? getDate(booking.returnDate)
                  : booking.endDate
                  ? getDate(booking.endDate)
                  : ""
              }}</span>
            </div>
          </div>
        </ng-container>
        <!--FLIGHT TRAIN CAR-->
        <div *ngIf="type === 'flight' || type === 'train' || type === 'car'">
          <div class="title-content-container">
            <div class="title">
              {{ "my-bookeds.train-flight.create-date" | translate }}
            </div>
            <div class="content">
              <span class="labels">{{
                booking.createdAt | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY"
              }}</span>
            </div>
          </div>
        </div>

        <!-- CAR -->
        <div class="title-content-container" *ngIf=" type === 'car' && booking.provider">
          <div class="title">
            {{ "car.form-car.provider" | translate }}
          </div>
          <div class="content">
            <span class="labels">{{ booking.provider }}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="type === 'car' && booking.order && isEmployee">
          <div class="title">
            {{"company.booked.order-code" | translate}}
          </div>
          <div class="content">
            <span class="labels">{{ booking.order }}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="type === 'car' && booking.locator">
          <div class="title">{{"my-bookeds.train-flight.locator" | translate}}</div>
          <div class="content">
            <span>{{booking.locator}}</span>
          </div>
        </div>

        <div class="title-content-container" *ngIf="type === 'car'">
          <div class="title">
            {{ "car.insurance-excess" | translate }}
          </div>
          <div class="content">
            <span class="labels">{{(booking.insuranceWithExcess ? 'common.yes' : 'common.no') | translate }}</span>
          </div>
        </div>

        <div class="title-content-container" *ngIf="booking.traveller && type === 'car'">
          <div class="title">
            <span>{{'my-bookeds.flight.travellers' | translate}}</span>
          </div>
          <div class="content multiple" *ngFor="let t of booking.traveller">
            <span>{{t.name | titlecase}} {{t.surname | titlecase}}</span>
          </div>
        </div>
        <!-- FLIGHT TRAIN -->
        <div *ngIf="type === 'flight' || type === 'train'">
          <div class="title-content-container">
            <div class="title">
              {{
                (type === "flight"
                  ? "my-bookeds.train-flight.pnr"
                  : "my-bookeds.train-flight.locator"
                ) | translate
              }}
            </div>
            <div class="content">
              <span class="labels">{{
                booking.renfeLocalizador ??
                  booking.pnr ??
                  ("flight.result-flight.select-seat.unknown-terminal"
                    | translate)
              }}</span>
            </div>
          </div>
          <div class="title-content-container" *ngIf="booking.order && isEmployee">
            <div class="title">
              {{"company.booked.order-code" | translate}}
            </div>
            <div class="content">
              <span class="labels">{{booking.order}}</span>
            </div>
          </div>
          <div class="title-content-container" *ngIf="booking.extraInfo && booking.extraInfo.length > 0 && booking.extraInfo[0].ancillaries && booking.extraInfo[0].ancillaries.length > 0">
            <div class="title">
              <span>{{'my-bookeds.flight.baggage' | translate}}</span>
            </div>
            <div class="content multiple">
              <div *ngFor="let baggage of booking.extraInfo[0].ancillaries">
                <span>{{baggage}}</span>
              </div>
            </div>
          </div>
          <div class="title-content-container" *ngIf="(type === ('flight') && booking.extraInfo && booking.extraInfo.length > 0 && booking.extraInfo[0].fare && booking.extraInfo[0].fare.fareName) || (type === ('train') && booking.extraInfo && booking.extraInfo.length > 0 && booking.extraInfo[0].fare && booking.extraInfo[0].fare.length > 0)">
            <div class="title">
              <span>{{'my-bookeds.train.tax' | translate}}</span>
            </div>
            <div class="content" *ngIf="type === 'flight' ">
              <span>{{booking.extraInfo[0].fare.fareName}}</span>
            </div>

            <!-- TRAIN -->
            <div class="content multiple" *ngIf="type === ('train') && booking.extraInfo.length > 0">
              <div *ngFor="let fare of booking.extraInfo[0].fare; let i = index" class="one-way-and-return">
                <span class="subtitle" *ngIf="i === 0">{{'my-bookeds.train.way-going' | translate}}</span>
                <span class="subtitle" *ngIf="i === 1">{{'my-bookeds.train.come-back' | translate}}</span>
                <span class="text">{{fare.fareName}}</span>
              </div>
            </div>
          </div>
          <div class="title-content-container" *ngIf="booking.extraInfo && booking.extraInfo.length > 0">
            <div class="title">
              <span>{{'my-bookeds.flight.travellers' | translate}}</span>
            </div>
            <div class="content multiple">
              <div *ngIf="booking.extraInfo && booking.extraInfo.length > 0">
                <span *ngIf="type === 'flight' && booking.extraInfo[0].passengerName">{{booking.extraInfo[0].passengerName}}</span>
                <span *ngIf="type === 'flight' && booking.extraInfo[0].ffCard">{{booking.extraInfo[0].ffCard}}</span>
            
                <!-- TRAIN -->
                <span *ngIf="type === 'train' && booking.extraInfo[0].passengerFullName">{{booking.extraInfo[0].passengerFullName}}</span> 
              </div>
            </div>
          </div>
          <div class="title-content-container" *ngIf="booking.airlines && booking.airlines.length > 0">
            <div class="title">
              <span>{{'csv.flight.airline' | translate}}</span>
            </div>
            <div class="content multiple">
              <div class="airline" *ngFor="let airline of booking.airlines; let i = index">
                <span *ngIf="i === 0">{{'my-bookeds.train.way-going' | translate}}</span>
                <span *ngIf="i === 1">{{'my-bookeds.train.come-back' | translate}}</span>
                <img [src]="getLogo(airline)" alt="airline-logo">
              </div>
            </div>
          </div>
          <div class="title-content-container" *ngIf=" type === 'train' && booking.extraInfo && booking.extraInfo.length > 0 && booking.extraInfo[0].seats && booking.extraInfo[0].seats.length > 0 &&  booking.extraInfo[0].seats[0].outwardSeats && booking.extraInfo[0].seats[0].outwardSeats.length > 0 ">
            <div class="title">
              <span>{{'my-bookeds.train-flight.seat' | translate}}</span>
            </div>
            <div class="content multiple">
              <div class="seats" *ngFor="let seat of booking.extraInfo[0].seats[0].outwardSeats && booking.extraInfo[0].seats; let i = index">
                <div *ngIf="seat.outwardSeats.length > 0">
                  <span *ngIf="seat.outwardSeats.length > 0">{{'my-bookeds.train.way-going' | translate}}</span>
                  <span style="font-weight: 300;" *ngFor="let outwardSeats of seat.outwardSeats" >{{outwardSeats.seat_code}}</span>
                </div>
                <div *ngIf="seat.returnSeats.length > 0">
                  <span *ngIf="seat.returnSeats.length > 0">{{'my-bookeds.train.come-back' | translate}}</span>
                  <span style="font-weight: 300;" *ngFor="let returnSeats of seat.returnSeats" >{{returnSeats.seat_code}}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="title-content-container"
            *ngIf="
              booking.seatTicketIds &&
              booking.seatTicketIds.length > 0 &&
              booking.seatTicketIds[0].seatTicketAndNumber
            "
          >
            <div class="title">
              {{ "my-bookeds.train-flight.seat" | translate }}
            </div>
            <div class="content">
              <span class="labels">{{
                booking.seatTicketIds[0].seatTicketAndNumber
              }}</span>
            </div>
          </div>
          <div
            class="title-content-container"
            *ngIf="hasRenfeCardOrFlight() != -1"
          >
            <div class="title">
              {{
                (type === "flight"
                  ? "my-bookeds.train-flight.ff-card"
                  : "my-bookeds.train-flight.renfe-card"
                ) | translate
              }}
            </div>
            <div class="content">
              <span class="labels">{{
                hasRenfeCardOrFlight() === 1
                  ? booking.extraInfo[0].renfeCard
                  : booking.extraInfo[0].ffCard
              }}</span>
            </div>
          </div>
        </div>
        <!--AUTOMATIZED HOTEL-->
        <ng-container *ngIf="type === 'bookingApiReference' || type === 'custom-booking'">
          <div class="title-content-container">
            <div class="title">
              {{ "my-bookeds.view.bookedMadePerson" | translate }}
            </div>
            <div class="content">
              <span class="labels">{{ booking.author }}</span>
            </div>
          </div>
          <div class="title-content-container" *ngIf="rooms">
            <div class="title">
              {{ "my-bookeds.view.totalRooms" | translate }}
            </div>
            <div class="content">
              <span class="labels">{{ rooms?.length ?? booking.rooms}}</span>
            </div>
          </div>
          <div class="title-content-container" *ngIf="totalAdults || booking.people">
            <div class="title">
              {{ "my-bookeds.view.totalAdults" | translate }}
            </div>
            <div class="content">
              <span class="labels">{{ totalAdults > 0 ? totalAdults : booking.people }}</span>
            </div>
          </div>
          <div class="title-content-container" *ngIf="totalChildren">
            <div class="title">
              {{ "my-bookeds.view.totalChildrens" | translate }}
            </div>
            <div class="content">
              <span class="labels">{{ totalChildren }}</span>
            </div>
          </div>
          <div class="title-content-container" *ngIf="booking.holder">
            <div class="title">
              <span>{{'my-bookeds.flight.travellers' | translate}}</span>
            </div>
            <div class="content multiple">
              <!-- HOTEL -->
              <span *ngIf="(type === 'bookingApiReference' || 'custom-booking') && booking.holder">{{booking.holder.name | titlecase}} {{booking.holder.surname | titlecase}}</span>
            </div>
          </div>
          <div class="title-content-container" *ngIf="booking.apiReference && type === 'bookingApiReference'">
            <div class="title">{{"my-bookeds.train-flight.locator" | translate}}</div>
            <div class="content">
              <span>{{booking.apiReference}}</span>
            </div>
          </div>
          <div class="title-content-container" *ngIf="booking.locator && type === 'custom-booking'">
            <div class="title">{{"my-bookeds.train-flight.locator" | translate}}</div>
            <div class="content">
              <span>{{booking.locator}}</span>
            </div>
          </div>
          <div class="title-content-container" *ngIf="booking.order && isEmployee && (type === 'bookingApiReference' || 'custom-booking')">
            <div class="title">{{"company.booked.order-code" | translate}}</div>
            <div class="content">
              <span>{{booking.order}}</span>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row mt-3" *ngIf="type === 'bookingApiReference' || type === 'custom-booking'">
        <div class="col-12 hotel-details">
          <h5 class="heading-6">{{ "my-bookeds.view.hotel-details" | translate }}</h5>
        </div>
      </div>
      <div *ngIf="booking && (type === 'bookingApiReference' || type === 'custom-booking')">
        <div class="title-content-container">
          <div class="title">{{ "common.name" | translate }}</div>
          <div class="content">
            <span class="labels">{{ booking.hotel?.name ?? booking.hotelName }}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="rooms && rooms.length > 0">
          <div class="title">{{ "my-bookeds.view.room" | translate }}</div>
          <div class="content">
            <div *ngFor="let r of rooms">
              <span class="labels">{{ r.name }} </span>
            </div>
          </div>
        </div>
        <div class="title-content-container" *ngIf="booking.hotel?.stars">
          <div class="title">{{ "common.stars" | translate }}</div>
          <div class="content">
            <span class="labels">{{ booking.hotel?.stars }}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="booking.hotel?.email">
          <div class="title">{{ "common.email" | translate }}</div>
          <div class="content">
            <span class="labels string-long">{{ booking.hotel?.email }}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="booking.hotel?.phone">
          <div class="title">{{ "common.phone" | translate }}</div>
          <div class="content">
            <span class="labels">{{ booking.hotel?.phone }}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="booking.hotel?.address || booking.destinationName">
          <div class="title">{{ "common.address" | translate }}</div>
          <div class="content">
            <span class="labels">{{ booking.hotel?.address ?? booking.destinationName }}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="booking.hotel?.destinationName">
          <div class="title">{{ "common.city" | translate }}</div>
          <div class="content">
            <span class="labels">{{ booking.hotel?.destinationName}}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="booking.hotel?.postalCode">
          <div class="title">{{ "common.postal-code" | translate }}</div>
          <div class="content">
            <span class="labels">{{ booking.hotel?.postalCode }}</span>
          </div>
        </div>
        <div class="title-content-container" *ngIf="rooms && rooms.length > 0">
          <div class="title">{{ "my-bookeds.view.room-type" | translate }}</div>
          <div class="content">
            <div *ngFor="let r of rooms">
              <span class="labels">{{
                r.rates[0].boardName
              }}</span>
            </div>
          </div>
        </div>
        <div class="title-content-container" *ngIf="rooms[0]?.rates[0]?.rateComments">
          <div class="title">{{ "my-bookeds.view.comments" | translate }}</div>
          <div class="content" id="contentHotelComments">
            <span class="labels">{{
              rooms[0]?.rates[0]?.rateComments
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
