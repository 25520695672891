import { Injectable } from "@angular/core";
import { CanLoad, Route, Router } from "@angular/router";
import { AuthService } from "@core/services";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.reducer";
import { AuthState } from "app/store/reducers/auth.reducer";
import { Observable } from "rxjs";
import { map, skipWhile, take } from "rxjs/operators";

@Injectable()
export class AuthHubGuard implements CanLoad {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService
  ) {}
  canLoad(route: Route): Observable<boolean> {
    return this.store.select("auth").pipe(
      skipWhile((authState: AuthState) => authState.skipping),
      take(1),
      map((authState: AuthState) => {
        let auth: any = authState.user;
        auth = auth.user;
        if (
          !authState.isAuthenticated ||
          (!auth ? authState.user.type?.toUpperCase() !== "HUB" && authState.user.type?.toUpperCase() !== "EMPLOYEE" : auth.accessType?.toLowerCase() !== "hub" && auth.accessType?.toLowerCase() !== "employee")
        ) {
          this.authService.userCompanyPlan = null;
          this.router.navigate(["/login"]);
        }
        return authState.isAuthenticated;
      })
    );
  }
}
