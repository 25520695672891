import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Fares } from "@core/models";

@Component({
  selector: "app-fare-services-list",
  templateUrl: "./fare-services-list.component.html",
  styleUrls: ["./fare-services-list.component.scss"],
})
export class FareServicesListComponent implements OnInit {
  fare: Fares;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.fare = this.data.fare;
  }

  getMiscellaneousSvg(service) {
    if (service.name?.toLowerCase().includes("early")) {
      return "check-priority";
    } else if (service.name?.toLowerCase().includes("prior")) {
      return "lounge";
    } else if (service.name?.toLowerCase().includes("meal")) {
      return "meal";
    } else if (service.name?.toLowerCase().includes("avios")) {
      return "avios";
    } else if (service.name?.toLowerCase().includes("freq")) {
      return "frecuent";
    } else {
      return "extras";
    }
  }

  transformFareString(words: string) {
    const wordMod = words.replace(
      words.charAt(0),
      words.charAt(0)?.toUpperCase()
    );
    return wordMod;
  }

  close() {
    this.dialog.closeAll();
  }
}
