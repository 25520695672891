import {
  AfterViewChecked,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { Agency } from "@core/models";
import { environment } from "@env/environment";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AgencyService,
  HubService,
  MediaService,
  NgxToastrService,
  UtilsService,
} from "@services";
import { AppState } from "app/app.reducer";
import * as _lodash from "lodash-es";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import countryJson from "../../../../../assets/utils/amadeus/countries.json";

@Component({
  selector: "app-agency-form",
  templateUrl: "./agency-form.component.html",
  styleUrls: ["./agency-form.component.scss"],
})
export class AgencyFormComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  agency: Agency;
  loading: Boolean;
  isAdmin: boolean;
  @Output() sendInfo = new EventEmitter<any>();
  payCometScript;
  formGeneral: UntypedFormGroup;
  formBilling: UntypedFormGroup;
  formCard: UntypedFormGroup;
  countriesList = countryJson;
  previewImagePath: string;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  profileImage: any;
  subscriptions: Subscription[] = [];
  isLoading = false;
  iban_no: string;
  hasCreditCard: boolean;
  maxHotelPercentage;
  maxFlightPercentage;
  userType: string;

  hubsList: any[] = [];
  hubUuid: string;
  hubUuidSelected: string;
  @ViewChild("stepper") stepper: MatStepper;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private agencyService: AgencyService,
    private ngxToastrService: NgxToastrService,
    private mediaService: MediaService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private router: Router,
    private utilsService: UtilsService,
    public route: ActivatedRoute,
    private hubService: HubService
  ) {
    this.store
      .select("auth")
      .pipe(take(1))
      .subscribe((auth) => {
        if (auth.user.type?.toUpperCase() === "EMPLOYEE") {
          this.isAdmin = true;
          this.userType = "EMPLOYEE";
        } else if (auth.user.type?.toUpperCase() === "HUB") {
          this.hubUuid = auth.user.uuid;
        }
      });
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      if (this.router.getCurrentNavigation().extras.state.agency) {
        this.agency = this.router.getCurrentNavigation().extras.state.agency;
      }
      this.loading = this.router.getCurrentNavigation().extras.state.loading
        ? this.router.getCurrentNavigation().extras.state.loading
        : false;
      if (this.router.getCurrentNavigation().extras.state.maxHotelPercentage) {
        this.maxHotelPercentage =
          this.router.getCurrentNavigation().extras.state.maxHotelPercentage;
      }
      if (this.router.getCurrentNavigation().extras.state.maxFlightPercentage) {
        this.maxFlightPercentage =
          this.router.getCurrentNavigation().extras.state.maxFlightPercentage;
      }
      if (this.router.getCurrentNavigation().extras.state.hubUuid) {
        this.hubUuid = this.router.getCurrentNavigation().extras.state.hubUuid;
      }
    }
  }

  ngOnInit() {
    if (this.agency && this.agency.image) {
      this.subscriptions.push(
        this.mediaService.getMedia(this.agency.image).subscribe((s) => {
          const reader = new FileReader();
          reader.readAsDataURL(s);
          let base64data;
          reader.onloadend = () => {
            base64data = reader.result;
            this.isImageSaved = true;
            this.cardImageBase64 = base64data;
          };
          reader.readAsArrayBuffer(s);
        })
      );
    } else {
      this.cardImageBase64 = "assets/img/gallery/image_not_available.png";
    }
    if (this.isAdmin) {
      this.hubService.getHubs().subscribe((hubs) => {
        this.hubsList = hubs;
      });
    }
    this.formGeneral = this.formBuilder.group({
      image: [this.agency ? this.agency.image : null],
      name: [
        this.agency && this.agency.name ? this.agency.name : "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
        ],
      ],
      cif: [
        this.agency && this.agency.cif ? this.agency.cif : "",
        [
          Validators.required,
          Validators.pattern("[a-zA-Z0-9]*"),
          Validators.minLength(8),
          Validators.maxLength(9),
        ],
      ],
      email: [
        this.agency && this.agency.email ? this.agency.email : "",
        [Validators.required, Validators.email],
      ],
      address: [
        this.agency && this.agency.address ? this.agency.address : "",
        [Validators.required, Validators.maxLength(50)],
      ],
      cian: [
        this.agency && this.agency.cian ? this.agency.cian : "",
        [Validators.required, Validators.maxLength(50)],
      ],
      city: [
        this.agency && this.agency.city ? this.agency.city : "",
        [Validators.required, Validators.maxLength(30)],
      ],
      province: [
        this.agency && this.agency.province ? this.agency.province : "",
        [Validators.required, Validators.maxLength(30)],
      ],
      postalCode: [
        this.agency && this.agency.postalCode ? this.agency.postalCode : "",
        [Validators.required, Validators.maxLength(100)],
      ],
      country: [
        this.agency && this.agency.country ? this.agency.country : "",
        [Validators.required, Validators.maxLength(30)],
      ],
      phone: [
        this.agency && this.agency.phone ? this.agency.phone : "",
        [Validators.required, Validators.pattern("^[0-9]*$")],
      ],
    });

    if (this.isAdmin) {
      this.formGeneral.addControl(
        "bookedWithoutCard",
        this.formBuilder.control(
          this.agency
            ? this.agency.bookedWithoutCard !== undefined
              ? this.agency.bookedWithoutCard
              : false
            : false
        )
      );
      this.formGeneral.addControl(
        "hotelComission",
        this.formBuilder.control(
          this.agency && this.agency.hotelComission
            ? this.agency.hotelComission.toString()
            : "0",
          [Validators.min(0), Validators.max(this.maxHotelPercentage)]
        )
      );
      this.formGeneral.addControl(
        "flightDiscount",
        this.formBuilder.control(
          this.agency && this.agency.flightDiscount
            ? this.agency.flightDiscount.toString()
            : "0",
          [Validators.min(0), Validators.max(this.maxFlightPercentage)]
        )
      );
      this.formGeneral.addControl(
        "trainComission",
        this.formBuilder.control(
          this.agency && this.agency.trainComission
            ? this.agency.trainComission.toString()
            : "0",
          [Validators.min(0), Validators.max(this.maxHotelPercentage)]
        )
      );
      this.formGeneral.addControl(
        "hubUuid",
        this.formBuilder.control(
          this.agency && this.agency?.hubUuid
            ? this.agency?.hubUuid
            : ''
        )
      );

    }
    this.formBilling = this.formBuilder.group({
      identificator: [
        this.agency && this.agency.billingData
          ? this.agency.billingData.identificator
          : "",
        [Validators.required, Validators.maxLength(30)],
      ],
      businessName: [
        this.agency && this.agency.billingData
          ? this.agency.billingData.businessName
          : "",
        [Validators.required, Validators.maxLength(100)],
      ],
      address: [
        this.agency && this.agency.billingData
          ? this.agency.billingData.address
          : "",
        [Validators.required, Validators.maxLength(50)],
      ],
      town: [
        this.agency && this.agency.billingData
          ? this.agency.billingData.town
          : "",
        [Validators.required, Validators.maxLength(30)],
      ],
      province: [
        this.agency && this.agency.billingData
          ? this.agency.billingData.province
          : "",
        [Validators.required, Validators.maxLength(30)],
      ],
      postalCode: [
        this.agency && this.agency.billingData
          ? this.agency.billingData.postalCode
          : "",
        [Validators.required, Validators.maxLength(100)],
      ],
      country: [
        this.agency && this.agency.billingData
          ? this.agency.billingData.country
          : "",
        [Validators.required, Validators.maxLength(30)],
      ],
    });
    if (this.agency) {
      if (this.agency.cardData && this.agency.cardData.digitsCard) {
        this.hasCreditCard = true;
      } else {
        this.generateFormCard();
      }
    }
    let hasValidatorsBilling = false;
    this.formBilling.valueChanges.subscribe(() => {
      if (!hasValidatorsBilling && this.isEmpty("billing")) {
        hasValidatorsBilling = true;
        this.setValidators("billing", Validators.required);
        this.updateValueAndValidity("billing");
      } else if (hasValidatorsBilling && !this.isEmpty("billing")) {
        hasValidatorsBilling = false;
        this.setValidators("billing");
        this.updateValueAndValidity("billing");
      }
    });
  }

  ngAfterViewChecked() {
    let hasValidatorsCard = false;
    if (this.formCard) {
      this.formCard.valueChanges.subscribe(() => {
        if (!hasValidatorsCard && this.isEmpty("card")) {
          hasValidatorsCard = true;
          this.setValidators("card", Validators.required);
          this.updateValueAndValidity("card");
        } else if (hasValidatorsCard && !this.isEmpty("card")) {
          hasValidatorsCard = false;
          this.setValidators("card");
          this.updateValueAndValidity("card");
        }
      });
    }
  }

  setValidators(type: string, validator?) {
    const v = validator ? validator : null;
    if (type === "billing") {
      this.formBilling.get("identificator").setValidators(v);
      this.formBilling.get("address").setValidators(v);
      this.formBilling.get("businessName").setValidators(v);
      this.formBilling.get("town").setValidators(v);
      this.formBilling.get("province").setValidators(v);
      this.formBilling.get("postalCode").setValidators(v);
      this.formBilling.get("country").setValidators(v);
    } else {
      this.formCard.get("cardHolderName").setValidators(v);
      this.formCard.get("paNumber").setValidators(v);
      this.formCard.get("cvc2").setValidators(v);
      this.formCard.get("dateMonth").setValidators(v);
      this.formCard.get("dateYear").setValidators(v);
    }
  }

  updateValueAndValidity(type: string) {
    if (type === "billing") {
      this.formBilling.get("identificator").updateValueAndValidity();
      this.formBilling.get("address").updateValueAndValidity();
      this.formBilling.get("businessName").updateValueAndValidity();
      this.formBilling.get("town").updateValueAndValidity();
      this.formBilling.get("province").updateValueAndValidity();
      this.formBilling.get("postalCode").updateValueAndValidity();
      this.formBilling.get("country").updateValueAndValidity();
    } else {
      this.formCard.get("cardHolderName").updateValueAndValidity();
      this.formCard.get("paNumber").updateValueAndValidity();
      this.formCard.get("cvc2").updateValueAndValidity();
      this.formCard.get("dateMonth").updateValueAndValidity();
      this.formCard.get("dateYear").updateValueAndValidity();
    }
  }
  changePhoto() {
    document.getElementById("upload-photo").click();
  }

  isEmpty(type) {
    const formValues =
      type === "billing"
        ? this.formBilling.getRawValue()
        : this.formCard.getRawValue();
    return _lodash.values(formValues).some((value) => value !== "");
  }
  generarToken() {
    // @ts-ignore
    const x = new PAYTPV.Tokenizator();
    document.forms["paytpvPaymentForm"].paNumber.value = document.forms[
      "paytpvPaymentForm"
    ].paNumber.value
      .split(" - ")
      .join("");
    x.getToken(document.forms["paytpvPaymentForm"], this.responseToken);
    const token = document.forms["paytpvPaymentForm"].paytpvToken.value;
    const paNumber: number = document.forms["paytpvPaymentForm"].paNumber.value;
    const lastDigits = paNumber
      .toString()
      .slice(paNumber.toString().length - 4, paNumber.toString().length);
    if (token) {
      this.agencyService
        .postAgencyPayComet(this.agency.uuid, { token, lastDigits })
        .subscribe(
          (result) => {
            this.translate.get("agency.card.card-added").subscribe((resp) => {
              this.ngxToastrService.typeSuccess(null, resp);
            });
            this.sendInfo.emit();
            this.activeModal.dismiss("Cross click");
          },
          (err) => {
            this.translate.get("agency.card.error").subscribe((resp) => {
              this.ngxToastrService.typeInfo(null, resp);
            });
          }
        );
      document.forms["paytpvPaymentForm"].paNumber.value = document.forms[
        "paytpvPaymentForm"
      ].paNumber.value
        .match(new RegExp(".{1,4}", "g"))
        .join(" - ");
      return false;
    } else {
      this.translate.get("agency.card.error-form").subscribe((resp) => {
        this.ngxToastrService.typeInfo(null, resp);
      });
      document.forms["paytpvPaymentForm"].paNumber.value = document.forms[
        "paytpvPaymentForm"
      ].paNumber.value
        .match(new RegExp(".{1,4}", "g"))
        .join(" - ");
    }
  }

  onImageChange(event) {
    this.imageError = null;
    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2000000;
      const allowed_types = ["image/png", "image/jpeg"];
      const max_height = 15200;
      const max_width = 25600;
      if (event.target.files[0].size > max_size) {
        this.imageError =
          "Maximum size allowed is " + max_size / 1000000 + "Mb";

        return false;
      }

      if (!_lodash.includes(allowed_types, event.target.files[0].type)) {
        this.imageError = "Only Images are allowed ( JPG | PNG )";
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget["height"];
          const img_width = rs.currentTarget["width"];

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              "Maximum dimentions allowed " +
              max_height +
              "*" +
              max_width +
              "px";
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.profileImage = event.target.files[0];
            this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  setHubUuid(event) {
    this.hubUuidSelected = event.value;
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.profileImage = null;
  }

  onSubmit() {
    this.isLoading = true;
    const parameters = [];
    parameters["general"] = this.formGeneral.getRawValue();
    parameters["general"]["image"] = this.profileImage;
    parameters["billing"] = this.formBilling.getRawValue();
    const attributes = { ...parameters["general"] };
    if (
      _lodash
        .values(parameters["billing"])
        .every((value) => !_lodash.isEmpty(value))
    ) {
      attributes["billingData"] = parameters["billing"];
    }
    if (attributes["discount"]) {
      attributes["discount"] = attributes["discount"].toString();
    }
    if (attributes["flightDiscount"]) {
      attributes["flightDiscount"] = attributes["flightDiscount"].toString();
    }
    if (attributes["hotelComission"]) {
      attributes["hotelComission"] = attributes["hotelComission"].toString();
    }
    if (attributes["trainComission"]) {
      attributes["trainComission"] = attributes["trainComission"].toString();
    }

    if (this.route.snapshot.paramMap.has("hubUuid")) {
      attributes["hubUuid"] = this.route.snapshot.paramMap.get("hubUuid");
    } else if (this.hubUuid) {
      attributes["hubUuid"] = this.hubUuid;
    } else if (this.isAdmin && this.hubUuidSelected) {
      attributes["hubUuid"] = this.hubUuidSelected;
    }

    const image = attributes.image;
    delete attributes.image;
    let formData = new FormData();
    formData = this.utilsService.toFormData(attributes, formData);
    if (image && image.name) {
      formData.append("file", image, image.name);
    }
    this.subscriptions.push(
      this.agencyService.postAgency(formData).subscribe((agency: Agency) => {
        /**
       * const modalRefSecond = this.modalService.open(UserFormComponent, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
          modalRefSecond.componentInstance.loading = false;
          modalRefSecond.componentInstance.firstUser = true;
          modalRefSecond.componentInstance.sendInfo.subscribe((userFormInfo) => {
            userFormInfo['accessType'] = 'agency';
            userFormInfo['agencyUuid'] = agency.uuid;

            if (this.author.hubUuid) {
              userFormInfo['hubUuid'] = this.author.hubUuid;
            } else if (this.userType === 'EMPLOYEE' && this.route.snapshot.paramMap.has("hubUuid")) {
              userFormInfo['hubUuid'] = this.route.snapshot.paramMap.get('hubUuid');
            }
            delete userFormInfo['group'];


            this.userService.postUser(userFormInfo).subscribe(() => {
              this.translate.get('company.company-homepage.created-company').subscribe(result => {
                modalRefSecond.dismiss();
                this.ngxToastrService.typeSuccess(null, result);
              });
       */
              this.isLoading = false;
              const navigationExtra: NavigationExtras = {
          state: {
            agency: agency.uuid,
            loading: false,
            firstUser: true,
          },
        };
        this.router.navigate(["user/create-user"], navigationExtra);
      }, (error) => {
        this.isLoading = false;
      })
    );
  }

  onEdit() {
    this.isLoading = true;
    const parameters = [];
    parameters["general"] = this.formGeneral.getRawValue();
    parameters["general"]["image"] = this.profileImage;
    parameters["billing"] = this.formBilling.getRawValue();
    let attributes = { ...parameters["general"] };
    const general = {
      name: this.agency.name,
      cif: this.agency.cif,
      cian: this.agency.cian ? this.agency.cian : '',
      email: this.agency.email,
      address: this.agency.address,
      city: this.agency.city,
      province: this.agency.province,
      postalCode: this.agency.postalCode,
      country: this.agency.country,
      discount: this.agency,
    };
    if (this.userType === "AGENCY") {
      delete general.discount;
    }
    if (!_lodash.isEqual(this.agency.billingData, parameters["billing"])) {
      attributes["billingData"] = parameters["billing"];
    }
    if (!_lodash.isEqual(general, parameters["general"])) {
      attributes = { ...attributes, ...parameters["general"] };
    }
    if (!_lodash.isEmpty(attributes)) {
      if (attributes["discount"]) {
        attributes["discount"] = attributes["discount"].toString();
      }
      if (attributes["flightDiscount"]?.toString()) {
        attributes["flightDiscount"] = attributes["flightDiscount"].toString();
      }
      if (attributes["hotelComission"]?.toString()) {
        attributes["hotelComission"] = attributes["hotelComission"].toString();
      }
      if (attributes["trainComission"]?.toString()) {
        attributes["trainComission"] = attributes["trainComission"].toString();
      }

      if (this.route.snapshot.paramMap.has("hubUuid")) {
        attributes["hubUuid"] = this.route.snapshot.paramMap.get("hubUuid");
      } else if (this.hubUuid) {
        attributes["hubUuid"] = this.hubUuid;
      } else if (this.isAdmin && this.hubUuidSelected) {
        attributes["hubUuid"] = this.hubUuidSelected;
      }
  
      const image = attributes["image"];
      delete attributes["image"];
      let formData = new FormData();
      formData = this.utilsService.toFormData(attributes, formData);
      if (image && image.name) {
        formData.append("file", image, image.name);
      }

      this.subscriptions.push(
        this.agencyService.putAgency(formData, this.agency.uuid).subscribe(
          (agency: Agency) => {
            this.isLoading = false;
            this.translate
            .get("agency.agency-homepage.card.edited-agency")
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess("OK", result);
            });
            if (this.isAdmin) {
              this.router.navigate(["agencies"]);
            } else {
              this.router.navigate(["agencies/" + this.agency.uuid]);
            }
          },
          (error) => {
            this.isLoading = false;
            this.translate
              .get("agency.agency-homepage.card.error-edit-agency")
              .subscribe((result) => {
                this.ngxToastrService.typeInfo(result, error.error.message);
              });
          }
        )
      );
    }
  }

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }

  responseToken(passenger) {
    const father = document.getElementById("paytpvPaymentForm");
    const child = document.getElementById("paytpvTokenGenerate");
    if (child !== null) {
      father.removeChild(child);
    }
    const newInputField = document.createElement("input");
    newInputField.type = "hidden";
    newInputField.name = "paytpvToken";
    newInputField.id = "paytpvTokenGenerate";
    newInputField.value = passenger.paytpvToken;
    const paytpvPaymentForm = document.forms["paytpvPaymentForm"];
    paytpvPaymentForm.appendChild(newInputField);
  }

  isNumber($event): boolean {
    return (
      $event.key === "0" ||
      $event.key === "1" ||
      $event.key === "2" ||
      $event.key === "3" ||
      $event.key === "4" ||
      $event.key === "5" ||
      $event.key === "6" ||
      $event.key === "7" ||
      $event.key === "8" ||
      $event.key === "9" ||
      $event.key === "Backspace" ||
      $event.key === "Tab"
    );
  }

  isMonth($event) {
    if (Number($event) > 12) {
      this.formCard.controls["dateMonth"].setValue("");
    }
  }

  isCreditCard($event: string) {
    if ($event) {
      let chIbn = $event.split(" - ").join("");
      if (chIbn.length > 0) {
        chIbn = chIbn.match(new RegExp(".{1,4}", "g")).join(" - ");
        // this.formCard.controls['paNumber'].setValue(chIbn);
        this.iban_no = chIbn;
      }
    }
  }

  generateFormCard() {
    this.hasCreditCard = false;
    this.formCard = this.formBuilder.group({
      cardHolderName: ["", [Validators.maxLength(30)]],
      paNumber: ["", [Validators.maxLength(100)]],
      cvc2: ["", [Validators.maxLength(4)]],
      dateMonth: ["", [Validators.maxLength(2)]],
      dateYear: ["", [Validators.maxLength(2)]],
    });
    if (this.payCometScript) {
      document.head.removeChild(this.payCometScript);
    }
    this.payCometScript = document.createElement("script");
    this.payCometScript.async = true;
    this.payCometScript.src = `https://api.paycomet.com/gateway/jet-paytpv.js?id=${environment.jetId}&language=es`;
    document.head.appendChild(this.payCometScript);
  }

  removeCard() {
    this.translate
      .get(["agency.card.remove-card-success", "agency.card.error-remove-card"])
      .subscribe((translate) => {
        this.agencyService.removeCard(this.agency).subscribe(
          (res) => {
            this.generateFormCard();
            this.ngxToastrService.typeSuccess(
              null,
              translate["agency.card.remove-card-success"]
            );
            this.sendInfo.emit();
          },
          () => {
            this.sendInfo.emit();
            this.ngxToastrService.typeInfo(
              null,
              translate["agency.card.error-remove-card"]
            );
          }
        );
      });
  }

  goNextStepper() {
    this.stepper.next();
  }
  goBackStepper() {
    this.stepper.previous();
  }

  ngOnDestroy() {
    if (this.payCometScript) {
      document.head.removeChild(this.payCometScript);
    }
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
