<div class="modal-body p-3">
  <div class="row">
    <div style="width: 100%; height: 800px">
      <ng2-pdfjs-viewer
        [pdfSrc]="blobUrl"
        zoom="page-width"
        [downloadFileName]="fileName"
      ></ng2-pdfjs-viewer>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="btn-container">
        <button
          class="custom-btn mb-0 mr-2"
          (click)="downloadPDFBooked()"
        >
          {{ "common.download" | translate }}
        </button>
        <button
          class="custom-btn custom-btn--close mb-0"
          (click)="closeModal()"
        >
          {{ "common.close" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
