<div class="row">
  <div class="col-12">
    <div class="card profile-with-cover border-card">
      <div class="media profil-cover-details row">
        <div class="col-12 text-center">
          <div class="align-self-center halfway-fab text-center my-1">
            <a class="profile-image">
              <img
                src="assets/img/gallery/image_not_available.png"
                class="rounded-circle img-border gradient-summer width-100"
                alt="Card image"
              />
            </a>
          </div>
        </div>
        <div class="oneOrder col-12 text-center mb-2">
          <span class="font-medium-2 text-uppercase">{{
            company ? company.name : ""
          }}</span>
        </div>
      </div>
      <div class="profile-section">
        <div class="row flex tabMenu">
          <div class="twoOrder col-xs-12 text-center" [ngClass]="'col-md-3'">
            <ul class="p-0 profile-menu no-list-style">
              <li>
                <a
                  (click)="selectTab('info')"
                  class="primary font-medium-2 font-weight-600"
                  [ngClass]="{ active: currentPage === 'info' }"
                  >{{ "company.booked.info-company" | translate }}</a
                >
              </li>
            </ul>
          </div>

          <div class="threeOrder col-xs-12 text-center" [ngClass]="'col-md-3'">
            <ul class="p-0 profile-menu no-list-style">
              <li>
                <a
                  (click)="selectTab('bookeds')"
                  class="primary font-medium-2 font-weight-600"
                  [ngClass]="{ active: currentPage === 'bookeds' }"
                  >{{ "common.bookeds" | translate }}</a
                >
              </li>
            </ul>
          </div>

          <div class="threeOrder col-xs-12 text-center" [ngClass]="'col-md-3'">
            <ul class="p-0 profile-menu no-list-style">
              <li>
                <a
                  (click)="selectTab('transaction')"
                  class="primary font-medium-2 font-weight-600"
                  [ngClass]="{ active: currentPage === 'transaction' }"
                  >{{ "common.transactions" | translate }}</a
                >
              </li>
            </ul>
          </div>
          <div
            class="threeOrder col-lg-3 col-md-3 col-xs-12 text-center"
            *ngIf="
              (authService.userCompanyPlan &&
                authService.userCompanyPlan.permissions.canGetBills) ||
              authService.isAdmin()
            "
          >
            <ul class="p-0 profile-menu no-list-style">
              <li>
                <a
                  (click)="selectTab('bills')"
                  class="primary font-medium-2 font-weight-600"
                  [ngClass]="{ active: currentPage === 'bills' }"
                  >{{ "common.bills" | translate }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
