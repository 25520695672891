<div class="master-container border-radius-30">
  <h4 class="semi-bold">
    {{ "train.confirm-stepper-container.confirm.title" | translate }}
  </h4>
  <form
    id="extraInfo"
    [formGroup]="extraInfoForm"
    *ngIf="extraInfoForm != null"
  >
    <div>
      <p class="semi-bold">
        {{ "common.custom-code" | translate }}
      </p>
      <label class="border-radius-20">
        <input
          id="customCode"
          type="text"
          formControlName="customCode"
          placeholder="(PPT...)"
        />
      </label>
    </div>
    <div>
      <p class="semi-bold">
        {{ "common.cost-center" | translate }}
      </p>
      <label class="border-radius-20">
        <input id="costCenter" type="text" formControlName="costCenter" />
      </label>
    </div>
  </form>
  <div>
    <p style="font-size: 10px; font-weight: 300; margin-top: 15px">
      {{ "common.policies.clic" | translate
      }}<a href="https://www.vyootrip.com/aviso-legal/" target="_blank">{{
        "common.policies.terms-of-use" | translate
      }}</a
      >{{ "common.policies.confirm" | translate
      }}<a
        href="https://www.vyootrip.com/politica-de-cookies/"
        target="_blank"
        >{{ "common.policies.cookie-policy" | translate }}</a
      >
      {{ "common.policies.and" | translate }}
      <a
        href="https://www.vyootrip.com/politica-de-privacidad/"
        target="_blank"
        >{{ "common.policies.privacy-policy" | translate }}</a
      >
    </p>
  </div>
  <h4 class="semi-bold">
    {{ "train.confirm-stepper-container.confirm.subtitle" | translate }}
  </h4>
  <div class="actions-container">
    <button class="btn cancelButton semi-bold" (click)="cancel()">
      {{ "common.cancel" | translate }}
    </button>
    <button
      *ngIf="canRequest || isAdmin; else requestTrain"
      class="btn semi-bold"
      [disabled]="utilsService.loadingTrain"
      (click)="accept()"
    >
      {{ "train.accept" | translate }}
    </button>
    <ng-template #requestTrain>
      <button
        class="btn semi-bold"
        [disabled]="utilsService.loadingTrain"
        (click)="requestAuthorization()"
      >
        {{ "common.request" | translate }}
      </button>
    </ng-template>
  </div>
</div>
