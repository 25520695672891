import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { AuthService } from "@core/services";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.reducer";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CustomServicesGuard implements CanActivate {
  userUuid: string;
  canBookHotel: boolean;
  canRequestFlight: boolean;
  canRequestTrain: boolean;
  canRequestCar: boolean;
  canRequestAirbnb: boolean;
  userType: string;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private router: Router // private userService: UserService
  ) {
    this.store
      .select("auth")
      .pipe(take(1))
      .subscribe((authState) => {
        this.userUuid = authState.user.uuid
        this.userType = authState.user?.type;
      });

    this.canBookHotel = this.authService.userFilters?.requestBooked;
    this.canRequestFlight = this.authService.userFilters?.requestCustomFlight;
    this.canRequestTrain = this.authService.userFilters?.requestCustomTrain;
    this.canRequestCar = this.authService.userFilters?.requestCar;
    this.canRequestAirbnb =
      this.authService.userFilters?.lodgingType === "all" ||
      this.authService.userFilters?.lodgingType === "room";
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.userCompanyPlan.permissions.canRequestCustomBookings) {
      if (this.userType !== 'USER') {
        return true;
      }
      if (state.url.toString().includes("hotel") && this.authService.userFilters?.requestBooked) {
        return true;
      } else if (
        state.url.toString().includes("flight") &&
        this.authService.userFilters?.requestCustomFlight
      ) {
        return true;
      } else if (
        state.url.toString().includes("train") &&
        this.authService.userFilters?.requestCustomTrain
      ) {
        return true;
      } else if (state.url.toString().includes("car") && this.authService.userFilters?.requestCar) {
        return true;
      } else if (
        state.url.toString().includes("airbnb") &&
        this.canRequestAirbnb
      ) {
        return true;
      } else if (
        state.url.toString().includes("/custom-services") &&
        (this.authService.userFilters?.requestBooked ||
          this.authService.userFilters?.requestCustomFlight ||
          this.authService.userFilters?.requestCustomTrain ||
          this.authService.userFilters?.requestCar)
      ) {
        return true;
      } else {
        this.router.navigate(["/"]);
        return false;
      }
    } else {
      this.router.navigate(["/"]);
      return false;
    }
  }
}
