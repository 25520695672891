<div *ngIf="isLoading" style="display: block; margin: auto; z-index: 9999">
  <app-loading [userType]="'employee'"></app-loading>
</div>
<!-- TABLA DE MIS VUELOS -->
<div *ngIf="authorizations">
  <div *ngIf="isCompanyBookeds">
    <h2 class="heading-5">{{ "common.authorizations" | translate }}</h2>
  </div>
  <div id="container-customize">
    <div class="row" [ngClass]="{ empty: authorizations.length <= 0 }">
      <div id="goBackButtonContainer" *ngIf="isCompanyBookeds">
        <button mat-button mat-flat-button (click)="goBack()">
          <img src="assets/img/svg/arrow-back.svg" alt="" width="24px" />
        </button>
      </div>

      <div id="filters">
        <div>
          <!-- User First name filter -->
          <input id="search" class="form-control form-control--squared form-control--small search-input" [(ngModel)]="filter"
            (input)="filterParam('userName', $event.target.value)" placeholder="{{ 'common.search' | translate }}" />
        </div>
        <div>
          <!-- Cambio la posicion de los select para concondar el diseño -->

          <mat-select class="form-control form-control--squared form-control--small select-input" *ngIf="isCompanyBookeds" placeholder="{{ trSelect }}" [(value)]="status"
            (selectionChange)="filterParam('status')">
            <mat-option *ngFor="let status of statusOptions" [value]="status.value">
              {{ status.view }}
            </mat-option>
          </mat-select>

          <!-- Cambio la posicion de los select para concondar el diseño en las reservas de un usuario -->

          <ng-container *ngIf="!isCompanyBookeds">
            <select [(ngModel)]="component" (change)="changeComponent($event.target.value)" id="typeOptions"
              class="form-control form-control--squared form-control--small select-input">
              <option hidden value="" disabled selected>
                {{ "company.company-booked.booking-type" | translate }}
              </option>
              <option *ngFor="let component of typeComponent; let i = index" value="{{ i }}">
                {{ component.text }}
              </option>
            </select>
          </ng-container>
        </div>
        <div>
          <mat-select *ngIf="isCompanyBookeds" class="form-control form-control--squared form-control--small select-input" placeholder="{{ trSelect }}" [(value)]="type"
            (selectionChange)="filterParam('type')">
            <mat-option *ngFor="let type of typeOptions" [value]="type.value">
              {{ type.view }}
            </mat-option>
          </mat-select>

          <!-- Cambio la posicion de los select para concondar el diseño en las reservas de un usuario -->
          <ng-container *ngIf="!isCompanyBookeds">
            <mat-select class="form-control form-control--squared form-control--small select-input" placeholder="{{ trSelect }}" [(value)]="status"
              (selectionChange)="filterParam('status')">
              <mat-option *ngFor="let status of statusOptions" [value]="status.value">
                {{ status.view }}
              </mat-option>
            </mat-select>
          </ng-container>
        </div>
        <div>
          <!-- Datepicker INIT FROM DATE filter-->
          <input class="form-control form-control--squared form-control--small filter-dates" readonly placeholder="{{ 'my-bookeds.user.dep-date' | translate }}"
            name="fromDate" id="fromDate" ngbDatepicker #fromDate="ngbDatepicker" #fromInput
            (dateSelect)="filterParam('fromDate', $event)" (click)="fromDate.toggle()"
            [ngClass]="!fromDate.value ? 'filter-dates-placeholder' : ''" />
        </div>
        <div>
          <button type="button" class="clear-btn" (click)="clearFilters()" [disabled]="!canClean()"
            [ngClass]="canClean() ? 'btn-outline-danger' : ''">
            {{ "common.clean" | translate }}
            <img src="{{
                !canClean()
                  ? 'assets/img/svg/grey-cross.svg'
                  : 'assets/img/svg/red-cross.svg'
              }}" />
          </button>
        </div>
      </div>
    </div>
    <!-- EMPTY STATE -->
    <div class="text-center" *ngIf="authorizations.length <= 0 && !loading">
      <img class="not-found-image" src="assets/img/gallery/flights-not-found.svg" alt="No hay archivos" />
      <h3 class="mt-3">
        <b>{{
          (status === "no vacancy"
          ? "authorization.text-no-vacancy"
          : status === "request"
          ? "authorization.no-pending"
          : "authorization.text-" + status
          ) | translate
          }}</b>
      </h3>
    </div>
    <div class="table-container">
      <div class="table-responsive" *ngIf="authorizations.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">
              {{ "authorization.labels.employee" | translate }}
            </th>
            <th scope="col" class="text-center">
              {{ "company.transaction.filters.type" | translate }}
            </th>
            <th scope="col" class="text-center" *ngIf="hasPrivileges">
              {{ "company.transaction.filters.company" | translate }}
            </th>
            <th scope="col" id="concept">
              {{ "authorization.concept" | translate }}
            </th>
            <th scope="col" class="text-center">
              {{ "common.status" | translate }}
            </th>
            <th scope="col" class="text-center dates">
              {{ "my-bookeds.user.dep-date" | translate }}
            </th>
            <th scope="col" class="text-center dates">
              {{ "my-bookeds.user.ret-date" | translate }}
            </th>
            <th scope="col" class="text-center dates">
              {{ "my-bookeds.user.create-date" | translate }}
            </th>
            <th scope="col" class="text-center" id="price">
              {{ "authorization.price" | translate }}
            </th>
            <th scope="col" class="text-center" id="actions">
              {{ "my-bookeds.user.actions" | translate }}
            </th>
            <!-- <th></th> -->
          </tr>
        </thead>
        <tbody>
          <!-- <tr *ngFor="
              let auth of authorizations | slice: lowValue:highValue;
              trackBy: utilsService.getUuidTracking
            "> -->
          <tr *ngFor="
            let auth of authorizations
            trackBy: utilsService.getUuidTracking
          ">
            <td class="text-left">
              {{ auth.userName + " " + auth.userSurname }}
            </td>

            <td class="text-center">
              {{
              (auth.type?.toLowerCase() === "booking"
              ? "common.hotel"
              : auth.type?.toLowerCase() === "flight"
              ? "common.flight"
              : "common.train"
              ) | translate
              }}
            </td>
            <td class="text-center" *ngIf="hasPrivileges">
              {{ auth?.company?.name }}
            </td>

            <td id="concept-td">
              <!-- <ng-container *ngIf="auth.type === 'booking' else trainFlightBlock">
                {{ auth.requestData.destinationName}}
              </ng-container>
              <ng-template #trainFlightBlock>
                {{ auth.requestData.destination }}
              </ng-template> -->
              {{ auth.description }}
            </td>

            <td class="text-center">
              <span *ngIf="auth.status === 'no vacancy'" class="blue-font">{{
                "authorization.no-vacancy" | translate
                }}</span>

              <span *ngIf="auth.status === 'accepted'" class="green-font">{{
                "common.approved" | translate
                }}</span>

              <span *ngIf="auth.status === 'request'" class="yellow-font">{{
                "common.pending" | translate
                }}</span>

              <span *ngIf="auth.status === 'denied'" class="red-font">{{
                "common.denied" | translate
                }}</span>
            </td>

            <td class="text-center">
              {{ auth.fromDate | dateHourMoment }}
            </td>

            <td class="text-center">
              {{ auth.toDate | dateHourMoment }}
            </td>

            <td class="text-center">
              {{ auth.createdAt | dateMoment: "YYYY-MM-DD":"DD/MM/YYYY" }}
            </td>

            <td class="text-center">
              <span *ngIf="auth.requestData.query">{{ auth.requestData.query.vyooPrice | price }}</span>
              <span *ngIf="!auth.requestData.query">{{ auth.price | price }}</span>
            </td>

            <td class="text-center" id="container-buttons">
              <a title="{{ 'flight-train.see-book' | translate }}" class="btn" (click)="onDetails(auth)">
                <img src="assets/img/svg/eye.svg" alt="see-book" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <!-- <div *ngIf="authorizations.length > 0" class="d-flex justify-content-center mt-2 mb-2 col-12">
        <mat-paginator [length]="authorizations.length" [pageSize]="10" (page)="onPageChange($event)" showFirstLastButtons
          [hidePageSize]="true" style="border-radius: 15px">
        </mat-paginator>
      </div> -->
    </div>
  </div>
</div>