import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Plan, PlanPermissions } from "@core/models/plan";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
@Component({
  selector: "app-plan-form",
  templateUrl: "./plan-form.component.html",
  styleUrls: ["./plan-form.component.scss"],
})
export class PlanFormComponent implements OnInit, OnDestroy {
  planForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  @Input() plan: Plan;
  permission: PlanPermissions;
  @Output() sendInfo = new EventEmitter<any>();
  isUnlimitedGroups = false;
  maxGroups = 0;
  colorsArr = [
    "#bccbcb",
    "#8a99fc",
    "#4cbecb",
    "#f69977",
    "#f8d05e",
    "#b694fb",
    "#f46f69",
    "#37d197",
    "#205954",
    "#ef82d2",
  ];
  selectedColor: string;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.maxGroups = this.plan
      ? this.plan.permissions.canCreateUnlimitedGroups
      : 0;
    this.isUnlimitedGroups =
      this.plan && this.plan.permissions.canCreateUnlimitedGroups === -1
        ? true
        : false;
    this.planForm = this.formBuilder.group({
      name: [this.plan ? this.plan.name : '', [Validators.required]],
      price: [
        this.plan ? this.plan.price.toFixed(2) : '',
        [Validators.required, Validators.pattern("^[0-9]+(.?[0-9]+)?$")],
      ],
      permissions: this.formBuilder.group({
        assistancePhone: [
          this.plan ? this.plan.permissions.assistancePhone : '',
          [Validators.required, Validators.pattern("^[0-9]*$")],
        ],
        initCallHour: [
          this.plan ? this.plan.permissions.initCallHour : '',
          [
            Validators.required,
            Validators.pattern("^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$"),
          ],
        ],
        endCallHour: [
          this.plan ? this.plan.permissions.endCallHour : '',
          [
            Validators.required,
            Validators.pattern("^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$"),
          ],
        ],
        canRequestAutomatizedBookings: [
          this.plan
            ? this.plan.permissions.canRequestAutomatizedBookings
            : true,
          [Validators.required],
        ],
        canRequestCustomBookings: [
          this.plan ? this.plan.permissions.canRequestCustomBookings : false,
          [Validators.required],
        ],
        canUseApp: [
          this.plan ? this.plan.permissions.canUseApp : true,
          [Validators.required],
        ],
        canUseTRM: [
          this.plan ? this.plan.permissions.canUseTRM : true,
          [Validators.required],
        ],
        canGetBills: [
          this.plan ? this.plan.permissions.canGetBills : true,
          [Validators.required],
        ],
        canCreateTravelPolicies: [
          this.plan ? this.plan.permissions.canCreateTravelPolicies : false,
          [Validators.required],
        ],
        canCreateBookingRequest: [
          this.plan ? this.plan.permissions.canCreateBookingRequest : false,
          [Validators.required],
        ],
        canCreateUnlimitedGroups: [
          this.plan ? this.plan.permissions.canCreateUnlimitedGroups : false,
          [Validators.required],
        ],
        canUseAlfred: [
          this.plan ? this.plan.permissions.canUseAlfred : false,
          [Validators.required],
        ],
        canUseLocalsfy: [
          this.plan ? this.plan.permissions.canUseLocalsfy : false,
          [Validators.required],
        ],
        canUploadCreditCard: [
          this.plan ? this.plan.permissions.canUploadCreditCard : true,
          [Validators.required],
        ],
        canRequestWithoutCard: [
          this.plan ? this.plan.permissions.canRequestWithoutCard : false,
          [Validators.required],
        ],
        canPayFlexible: [
          this.plan ? this.plan.permissions.canPayFlexible : false,
          [Validators.required],
        ],
      }),
      color: [this.plan ? this.plan.color : this.colorsArr[0]],
    });
    if ((this.plan && this.plan.color == null) || this.plan == null) {
      this.selectedColor = this.colorsArr[0];
    } else {
      this.selectColor(this.plan.color);
    }
  }

  selectColor(color: string) {
    this.selectedColor = color;
    this.planForm.get("color").setValue(this.selectedColor);
  }

  onSubmit() {
    this.planForm.value.permissions["canCreateUnlimitedGroups"] = this
      .isUnlimitedGroups
      ? -1
      : this.maxGroups;
    const body = this.planForm.value;
    this.sendInfo.emit(body);
    this.activeModal.dismiss(body);
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
