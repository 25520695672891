import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { StoreModule } from "@ngrx/store";
import { BookedService, UserService } from "@services";
import { MatchHeightModule } from "@shared/directives/match-height.directive";
import { SharedModule } from "@shared/shared.module";
import { UserReducer } from "app/store/reducers/user.reducer";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { ExternalUserFormComponent } from "./external-user-form/external-user-form.component";
import { SendEmailUsersComponent } from "./send-email-users/send-email-users.component";
import { UserDetailComponent } from "./user-detail/user-detail.component";
import { ViewPdfModalComponent } from "./user-detail/view-pdf-modal/view-pdf-modal.component";
import { UserFormGroupComponent } from "./user-form-group/user-form-group.component";
import { UserFormComponent } from "./user-form/user-form.component";
import { UserRoutingModule } from "./user-routing.module";

@NgModule({
    imports: [
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatCheckboxModule,
        CommonModule,
        UserRoutingModule,
        MatchHeightModule,
        SharedModule,
        StoreModule.forFeature("user", UserReducer),
        ReactiveFormsModule,
        PdfJsViewerModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatTooltipModule,
    ],
    exports: [UserDetailComponent],
    declarations: [
        UserDetailComponent,
        UserFormComponent,
        UserFormGroupComponent,
        ViewPdfModalComponent,
        ExternalUserFormComponent,
        SendEmailUsersComponent,
    ],
    providers: [UserService, BookedService]
})
export class UserModule {}
