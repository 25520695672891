import {
  FacilitiesConfig,
  HotelsConfig,
  PaginationConfig,
  SearchConfig,
} from "@interfaces";
import * as fromAppReducer from "app/app.reducer";
import * as fromSearchActions from "../actions/search.actions";

export interface SearchState extends fromAppReducer.AppState {
  paginationConfig: PaginationConfig;
  searchConfig: SearchConfig;
  hotelsConfig: HotelsConfig;
  facilitiesConfig: FacilitiesConfig;
}

export const initialState: SearchState = {
  paginationConfig: {
    page: 1,
    pageSize: 10,
    collectionSize: 0,
  },
  searchConfig: {
    uuid: "",
    locationText: "",
    latitude: "",
    longitude: "",
    checkIn: null,
    checkOut: null,
    occupancies: [
      {
        rooms: 1,
        adults: 1,
        children: 0,
        paxes: [],
      },
    ],
    nextMinElements: "",
  },
  hotelsConfig: {
    loading: false,
    error: null,
    hotels: [],
    mapHotels: [],
  },
  facilitiesConfig: {
    loading: false,
    error: null,
    facilities: [],
  },
};

export function SearchReducer(
  state: SearchState = initialState,
  action: fromSearchActions.SearchActions
): SearchState {
  switch (action.type) {
    case fromSearchActions.SEARCH_PAGE:
      return {
        ...state,
        paginationConfig: {
          ...action.paginationConfig,
        },
      };

    case fromSearchActions.HOTEL_LIST:
      return {
        ...state,
        hotelsConfig: {
          loading: true,
          error: null,
          hotels: [],
          mapHotels: [],
        },
        paginationConfig: {
          page: 1,
          pageSize: 10,
          collectionSize: 0,
        },
        searchConfig: {
          ...action.searchConfig,
        },
      };

    case fromSearchActions.HOTEL_LIST_SUCCESS:
      return {
        ...state,
        hotelsConfig: {
          loading: false,
          error: null,
          hotels: action.hotels,
        },
        paginationConfig: {
          ...state.paginationConfig,
          collectionSize: action.hotels.length,
        },
      };
    case fromSearchActions.HOTEL_LIST_FAILURE:
      return {
        ...state,
        hotelsConfig: {
          loading: false,
          error: action.payload,
          hotels: [],
        },
        paginationConfig: {
          ...state.paginationConfig,
          collectionSize: 0,
        },
      };
    case fromSearchActions.GET_FACILITIES:
      return {
        ...state,
        facilitiesConfig: {
          loading: true,
          error: null,
          facilities: [],
        },
      };
    case fromSearchActions.GET_FACILITIES_SUCCESS:
      return {
        ...state,
        facilitiesConfig: {
          loading: false,
          error: null,
          facilities: action.facilities,
        },
      };
    case fromSearchActions.GET_FACILITIES_FAILURE:
      return {
        ...state,
        facilitiesConfig: {
          loading: false,
          error: action.payload,
          facilities: [],
        },
      };

    case fromSearchActions.APPLY_FILTERS:
      return {
        ...state,
        hotelsConfig: {
          loading: true,
          error: null,
          hotels: [],
        },
        paginationConfig: {
          ...state.paginationConfig,
        },
        searchConfig: {
          ...action.applyFilterConfig,
        },
      };

    case fromSearchActions.APPLY_FILTERS_SUCCESS:
      return {
        ...state,
        hotelsConfig: {
          loading: false,
          error: null,
          hotels: action.hotels,
        },
        paginationConfig: {
          ...state.paginationConfig,
          collectionSize: action.hotels.length,
        },
      };
    case fromSearchActions.APPLY_FILTERS_FAILURE:
      return {
        ...state,
        hotelsConfig: {
          loading: false,
          error: action.payload,
          hotels: [],
        },
        paginationConfig: {
          ...state.paginationConfig,
          collectionSize: 0,
        },
      };
    case fromSearchActions.RESET_FILTERS:
      return {
        ...initialState,
        searchConfig: {
          checkIn: null,
          checkOut: null,
          occupancies: [
            {
              rooms: 1,
              adults: 1,
              children: 0,
              paxes: [],
            },
          ],
          order: null,
          latitude: null,
          locationText: "",
          longitude: null,
          uuid: "",
        },
      };
    default:
      return state;
  }
}
