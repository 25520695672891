import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class NgxToastrService {
  constructor(private toastrService: ToastrService) {}

  typeError(title: string, message: string) {
    setTimeout(() =>
      this.toastrService.error(message, title, { timeOut: 7000 })
    );
  }

  typeSuccess(title: string, message: string) {
    setTimeout(() =>
      this.toastrService.success(message, title, { timeOut: 5000 })
    );
  }

  typeInfo(title: string, message: string, timeout: number = 7000) {
    setTimeout(() =>
      this.toastrService.info(message, title, { timeOut: timeout })
    );
  }
}
