import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Config } from "@models";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService, NgxToastrService } from "@services";
import { Subscription } from "rxjs";

@Component({
  selector: "app-config",
  templateUrl: "./config.component.html",
  styleUrls: ["./config.component.scss"],
})
export class ConfigComponent implements OnInit, OnDestroy {
  search = "";
  configHotels: Config;
  configFlights: Config;
  percentage: string;
  formPercentage: UntypedFormGroup;
  canEdit = false;
  @Output() sendInfo = new EventEmitter<any>();

  getConfigsubscription: Subscription[] = [];
  putConfigsubscription: Subscription[] = [];

  constructor(
    private configService: ConfigService,
    private formBuilder: UntypedFormBuilder,
    private ngxToastrService: NgxToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.loadConfig();
    this.formPercentage = this.formBuilder.group({
      percentageCompany: [null, [Validators.required]],
      localEmission: [null, [Validators.required]],
      euEmission: [null, [Validators.required]],
      worldEmission: [null, [Validators.required]],
    });
  }

  get f() {
    return this.formPercentage.controls;
  }

  loadConfig() {
    this.getConfigsubscription.push(
      this.configService.getPercentageVyoo("percentageCompany").subscribe(
        (data: Config) => {
          this.configHotels = data;
          if (!this.configHotels.value) {
            this.configHotels.value = [17];
          }
          this.f.percentageCompany.setValue(
            this.configHotels.value ? this.configHotels.value[0] : 17
          );
        },
        (err) => {
          this.ngxToastrService.typeInfo(
            "Error",
            this.translate.instant("common.some-error")
          );
        }
      ),
      this.configService.getPercentageVyoo("flightCommissions").subscribe(
        (data: Config) => {
          this.configFlights = data;
          if (!this.configFlights.value) {
            this.configFlights.value = [
              {
                local_emission: 5,
                eu_emission: 7,
                world_emission: 9,
              },
            ];
          }
          this.f.localEmission.setValue(
            this.configFlights.value[0].local_emission
          );
          this.f.euEmission.setValue(this.configFlights.value[0].eu_emission);
          this.f.worldEmission.setValue(
            this.configFlights.value[0].world_emission
          );
        },
        (err) => {
          this.ngxToastrService.typeInfo(
            "Error",
            this.translate.instant("common.some-error")
          );
        }
      )
    );
  }

  editConfig() {
    if (this.f.percentageCompany.value !== this.configHotels.value[0]) {
      const percentage = {
        percentageCompany: this.f.percentageCompany.value,
      };
      this.putConfigsubscription.push(
        this.configService
          .putPercentageVyoo("percentageCompany", percentage)
          .subscribe(
            () => {
              this.ngxToastrService.typeSuccess(
                null,
                this.translate.instant("employee.config.update_percentageVyoo")
              );
              this.sendInfo.emit({
                percentage: this.f.percentageCompany.value,
              });
            },
            (err) => {
              this.ngxToastrService.typeInfo(
                this.translate.instant(
                  "employee.config.update_percentageVyoo_error"
                ),
                err.error.message
              );
            }
          )
      );
    }
    if (
      this.f.localEmission.value !==
        this.configFlights.value[0].local_emission ||
      this.f.euEmission.value !== this.configFlights.value[0].eu_emission ||
      this.f.worldEmission.value !==
        this.configFlights.value[0].world_emission
    ) {
      const flightCommissions = {
        flightCommissions: {
          local_emission: this.f.localEmission.value,
          eu_emission: this.f.euEmission.value,
          world_emission: this.f.worldEmission.value,
        },
      };
      this.putConfigsubscription.push(
        this.configService
          .putPercentageVyoo("flightCommissions", flightCommissions)
          .subscribe(
            () => {
              this.ngxToastrService.typeSuccess(
                null,
                this.translate.instant("employee.config.update_percentageVyoo")
              );
              this.sendInfo.emit({
                local_emission: this.f.localEmission.value,
                eu_emission: this.f.euEmission.value,
                world_emission: this.f.worldEmission.value,
              });
            },
            (err) => {
              this.ngxToastrService.typeInfo(
                this.translate.instant(
                  "employee.config.update_percentageVyoo_error"
                ),
                err.error.message
              );
            }
          )
      );
    }
  }

  checkEnableSend(value) {
    if (value !== "") {
      this.canEdit = true;
    }
  }

  ngOnDestroy(): void {
    this.getConfigsubscription.forEach((s) => s.unsubscribe());
    this.putConfigsubscription.forEach((s) => s.unsubscribe());
  }
}
