<div>
  <div>
    <div class="text-center" *ngIf="!configHotels && !configFlights">
      <img
        src="assets/img/gallery/empty-companies.svg"
        width="300"
        height="300"
        alt="No hay configuraciones"
      />
      <h3 class="mt-4">
        <b>{{ "employee.config.no-config" | translate }}</b>
      </h3>
    </div>
    <div class="" *ngIf="configHotels && configFlights">
      <div class="card-header text-center">
        <h4 class="card-title display-inline-block">
          {{ "employee.config.change-config" | translate }}
        </h4>
      </div>
      <div class="card-content">
        <div class="text-center">
          <form [formGroup]="formPercentage">
            <div class="form-row">
              <div class="col-12">
                <h5>
                  {{ "employee.config.change-config-hotels" | translate }}
                </h5>
              </div>
              <div class="ofsset-lg-4 offset-md-4 col-lg-4 col-md-4 col-xs-6">
                <input
                  class="text-center"
                  (keyup)="checkEnableSend($event.target.value)"
                  [ngClass]="{
                    invalid:
                      formPercentage.controls['percentageCompany'].hasError()
                  }"
                  formControlName="percentageCompany"
                  type="number"
                  step="0.01"
                  id="percentageCompany"
                  class="form-control rounded-1"
                />
              </div>
            </div>
            <hr />
            <div class="form-row">
              <div class="col-12">
                <h5>
                  {{ "employee.config.change-config-flights" | translate }}
                </h5>
              </div>
              <div class="col-6">
                <label>{{
                  "employee.config.change-config-local" | translate
                }}</label>
                <input
                  class="text-center"
                  [ngClass]="{
                    invalid: formPercentage.controls['localEmission'].hasError()
                  }"
                  formControlName="localEmission"
                  type="number"
                  step="0.01"
                  id="localEmission"
                  class="form-control rounded-1"
                  (keyup)="checkEnableSend($event.target.value)"
                />
              </div>
              <div class="col-6">
                <label>{{
                  "employee.config.change-config-eu" | translate
                }}</label>
                <input
                  class="text-center"
                  [ngClass]="{
                    invalid: formPercentage.controls['euEmission'].hasError()
                  }"
                  formControlName="euEmission"
                  type="number"
                  step="0.01"
                  id="euEmission"
                  class="form-control rounded-1"
                  (keyup)="checkEnableSend($event.target.value)"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-6">
                <label>{{
                  "employee.config.change-config-world" | translate
                }}</label>
                <input
                  class="text-center"
                  [ngClass]="{
                    invalid: formPercentage.controls['worldEmission'].hasError()
                  }"
                  formControlName="worldEmission"
                  type="number"
                  step="0.01"
                  id="worldEmission"
                  class="form-control rounded-1"
                  (keyup)="checkEnableSend($event.target.value)"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div
            class="ofsset-lg-3 offset-md-3 col-lg-6 col-md-6 col-xs-12 text-center"
          >
            <button
              class="btn btn-vyoo-primary custom-background text-white rounded-1 mb-0"
              (click)="editConfig()"
              ngbAutofocus
              mat-button
              [disabled]="loading || !canEdit"
            >
              {{ "common.send" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
