import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService, UtilsService } from "@services";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AppState } from "./app.reducer";
import * as fromAuthActions from "./store/actions/auth.actions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["../assets/app.scss"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  subscription: Subscription;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    public translate: TranslateService,
    private langService: LanguageService,
    private ccService: NgcCookieConsentService,
    public utilsService: UtilsService,
  ) {}
  ngAfterViewInit() {
    
  }

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga("set", "page", event.urlAfterRedirects);
        (<any>window).ga("send", "pageview");
      }
    });

    const lang = this.translate.getBrowserLang();
    // TODO regionalizar i18n
    //const lang = this.langService.getLanguage();
    this.translate.setDefaultLang("es");
    if (lang) {
      this.langService.saveLanguage(lang);
      this.translate.use(this.translate.getBrowserLang());
    } else {
      // const browserLang: string = this.translate.getBrowserLang();
      // const auxLang = browserLang.match(/en|es/) ? browserLang : "es";
      // TODO regionalizar i18n
      /* const auxLang =
        navigator.languages != null && navigator.languages.length > 0
          ? navigator.languages[0]
          : navigator.language ? navigator.language : "es"; 
        this.langService.saveLanguage(auxLang);
          */
      this.langService.saveLanguage(this.translate.getBrowserLang());
      this.translate.use(this.translate.getBrowserLang());
    }

    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));

    const action = new fromAuthActions.PopulateAction();
    this.store.dispatch(action);
    this.translate
      .get(["cookies.alert", "cookies.accept", "cookies.read-more"])
      .subscribe((data) => {
        this.ccService.getConfig().content =
          this.ccService.getConfig().content || {};

        this.ccService.getConfig().content.message = data["cookies.alert"];
        this.ccService.getConfig().content.dismiss = data["cookies.accept"];
        this.ccService.getConfig().content.link = data["cookies.read-more"];
        this.ccService.destroy();
        this.ccService.init(this.ccService.getConfig());
      });
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }

  checkOS() {
    
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
