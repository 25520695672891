import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TrainSearchResponse } from "@core/models/trenymas/train-search-response";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TrainSearchDto } from "./../models/trenymas/train-search-dto";
import { TokenService } from "./token.service";

@Injectable()
export class TrainService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  postTrainRequest(trainReq: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/train/request`,
      trainReq,
      { headers: reqHeaders }
    );
  }
  postCreateTrain(form: FormData, requestUuid?: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const url = requestUuid
      ? `${environment.baseUrlApi}/train?requestUuid=${requestUuid}`
      : `${environment.baseUrlApi}/train`;
    return this.http.post<any>(url, form, {
      headers: reqHeaders,
    });
  }
  getUserTrain() {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/train`, { headers: reqHeaders })
      .pipe(map((res: any) => res.docs));
  }
  cancelTrain(trainId: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const body = { status: "cancelled" };
    return this.http.put<any>(
      `${environment.baseUrlApi}/train/status/${trainId}`,
      body,
      { headers: reqHeaders }
    );
  }
  editTrain(trainId: string, body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/train/update/${trainId}`,
      body,
      { headers: reqHeaders }
    );
  }
  changeStatusAuthorization(body: any, uuid: string, type: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.put<any>(
      `${environment.baseUrlApi}/authorization/${uuid}/train/${type}`,
      body,
      { headers: reqHeaders }
    );
  }
  /*Tren y MAS*/
  postTrainRequestTrainMore(
    trainReq: TrainSearchDto
  ): Observable<TrainSearchResponse> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<TrainSearchResponse>(
      `${environment.baseUrlApi}/train/search`,
      trainReq,
      { headers: reqHeaders }
    );
  }

  postTrainBookTrainMore(body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(`${environment.baseUrlApi}/train/book`, body, {
      headers: reqHeaders,
    });
  }

  postTrainBuyTrainMore(body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(`${environment.baseUrlApi}/train/buy`, body, {
      headers: reqHeaders,
    });
  }

  cancelTicketsPrice(body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/train/cancelTicketsPrice`,
      body,
      { headers: reqHeaders }
    );
  }

  cancelTicketsPartial(body: any, uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/train/cancelTicketsPartial/${uuid}`,
      body,
      { headers: reqHeaders }
    );
  }

  cancelTickets(body: any, uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/train/cancelTickets/${uuid}`,
      body,
      { headers: reqHeaders }
    );
  }

  getTrainsComplements(body: any): Observable<any> {
    // apiSession
    // outwardId
    // retId - not required
    // classCodeOutward
    // classSalesGroupCoodeOutward
    // classCodeReturn - not required
    // classSalesGroupCodeReturn - not required
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/train/getTrainsComplements`,
      body,
      { headers: reqHeaders }
    );
  }

  getGraph(body: any): Observable<any> {
    // apiSession
    // outwardId
    // retId - not required
    // classCodeOutward
    // classSalesGroupCoodeOutward
    // classCodeReturn - not required
    // classSalesGroupCodeReturn - not required
    // userUuid - not required
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/train/getGraph`,
      body,
      { headers: reqHeaders }
    );
  }

  bookTrainsGraph(body: any) {
    // apiSession
    // outwardId
    // retId - not required
    // classCodeOutward
    // classSalesGroupCoodeOutward
    // classCodeReturn - not required
    // classSalesGroupCodeReturn - not required
    // outwardSeats
    // returnSeats
    // userUuid - not required
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/train/bookTrainsGraph`,
      body,
      { headers: reqHeaders }
    );
  }

  createLetItGo(body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/train/letitgo`,
      body,
      { headers: reqHeaders }
    );
  }
}
