import { PaginationConfig } from "@interfaces";
import { Action } from "@ngrx/store";
export const COMPANY_SEARCH = "[Company] Search";
export const COMPANY_PAGE = "[Company] Page";

export class CompanySearchAction implements Action {
  readonly type = COMPANY_SEARCH;

  constructor(public search: string) {}
}

export class CompanyPageAction implements Action {
  readonly type = COMPANY_PAGE;

  constructor(public paginationConfig: PaginationConfig) {}
}

export type CompanyActions = CompanySearchAction | CompanyPageAction;
