import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms'
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { CompanyService, NgxToastrService, TravelUtilsService, UtilsService } from '@services'
import { AppState } from 'app/app.reducer'
import moment from 'moment'
import { Subscription, take } from 'rxjs'

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit, OnDestroy {
  @Input() companyUuid: string
  @Input() userUuid: string
  @Input() groupRestrictionUuid: string
  @Input() expense
  @Output() sendInfo = new EventEmitter<any>()
  editing = false;
  loading = false
  subcriptions: Subscription[] = []
  form: UntypedFormGroup
  bodyTravel: any = {}
  file: File
  minDateNgStruct: any
  maxDateNgStruct: any
  departDataDate: any
  returnDataDate: any
  isInvalid: boolean = false
  userType: any;
  companyUsers: { name: string; lastname: string; uuid: string }[] = [];

  constructor (
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    private travelUtilsService: TravelUtilsService,
    private utilsService: UtilsService,
    private store: Store<AppState>,
    private ngbDateParser: NgbDateParserFormatter,
    private companyService: CompanyService
  ) {}

  ngOnInit () {
    this.expense && this.expense !== null
      ? (this.editing = true)
      : (this.editing = false);
    if (this.expense) {
      const departDate = this.expense.beginDate.split('T')
      this.departDataDate = {
        year: parseInt(departDate[0].split('-')[0], 10),
        month: parseInt(departDate[0].split('-')[1], 10),
        day: parseInt(departDate[0].split('-')[2], 10)
      }

      const returnDate = this.expense.endDate.split('T')
      this.returnDataDate = {
        year: parseInt(returnDate[0].split('-')[0], 10),
        month: parseInt(returnDate[0].split('-')[1], 10),
        day: parseInt(returnDate[0].split('-')[2], 10)
      }
    }

    this.store
    .select("auth")
    .pipe(take(1))
    .subscribe((res) => {
      if (res.isAuthenticated) {
        this.userType = res.user.type;
      }
    });

    this.form = this.formBuilder.group({
      file: [this.expense && this.expense !== null ? this.expense.file : null],
      type: [
        { value: this.expense && this.expense !== null ? this.expense.type : '', disabled: this.userType != "EMPLOYEE"},
        [Validators.required]
      ],
      price: [
        { value: 
          this.expense && this.expense !== null
          ? this.expense.price.toFixed(2) || this.expense.amount.toFixed(2)
          : '',
          disabled: this.expense?.payment || this.expense?.refund || this.userType != "EMPLOYEE"
        },
        [Validators.required],
      ],
      freeTaxes: [
        { value: this.expense && this.expense !== null && this.expense.freeTaxes
          ? this.expense.freeTaxes
          : false, disabled: this.userType != "EMPLOYEE"},
        [Validators.required]
      ],
      beginDate: [
        this.editing ? this.ngbDateParser.parse(
          moment(this.expense.beginDate).toString()
        ) : "",
        [Validators.required]
      ],
      endDate: [
        this.editing ? this.ngbDateParser.parse(
          moment(this.expense.endDate).toString()
        ) : "",
        [Validators.required],
      ],
      customCode: [
        this.expense && this.expense !== null && this.expense.customCode
          ? this.expense.customCode
          : ''
      ],
      costCenter: [
        this.expense && this.expense !== null && this.expense.costCenter
          ? this.expense.costCenter
          : ''
      ],
    });

    if (this.editing) {
      this.form.addControl('passenger', new FormControl({ value: this.expense ? this.expense.userUuid : '', disabled: !(this.userType?.toLowerCase() === 'employee' || this.userType?.toLowerCase() === 'company') }));
    }

    if (this.form.value.beginDate) {
      this.form.patchValue({ beginDate: { day: this.form.value.beginDate.day, month: this.form.value.beginDate.month + 1, year: this.form.value.beginDate.year } })
    }
    if (this.form.value.endDate) {
      this.form.patchValue({ endDate: { day: this.form.value.endDate.day, month: this.form.value.endDate.month + 1, year: this.form.value.endDate.year } })
    }

    if(this.editing && this.utilsService.isAdmin(this.userType?.toLowerCase())){
      this.getCompanyUsers();
    }
  }

  get f () {
    return this.form.controls
  }

  onFileChange (event) {
    if (event.target.files.length > 0 && this.userType === 'EMPLOYEE') {
      this.file = event.target.files[0]
    }
  }

  handleTravel (dataTravel: any) {
    this.bodyTravel = dataTravel
    this.travelUtilsService.setTravelLocalStorage(this.bodyTravel)
    this.translate.get(['project.form.saved']).subscribe(resp => {
      this.ngxToastrService.typeSuccess(resp['project.form.saved'], '')
    })
  }

  onSubmit () {
    const travel = this.travelUtilsService.getTravelLocalStorage()
    
    this.isInvalid = false
    this.loading = true
    const body = {
      groupRestrictionUuid: this.groupRestrictionUuid,
      userUuid: this.userUuid,
      companyUuid: this.companyUuid,
      file: this.file,
      price: this.form.get('price').value.toString().replace(',', '.'),
      type: this.form.get('type').value,
      freeTaxes: this.form.get('freeTaxes').value,
      customCode: this.form.get('customCode').value,
      costCenter: this.form.get('costCenter').value,
      beginDate:
        this.form.get('beginDate').value.year +
        '-' +
        this.form.get('beginDate').value.month +
        '-' +
        this.form.get('beginDate').value.day,
      endDate:
        this.form.get('endDate').value.year +
        '-' +
        this.form.get('endDate').value.month +
        '-' +
        this.form.get('endDate').value.day
    }

    if(this.editing){
      body["passenger"] = this.f.passenger.value;
    }

    if (travel && travel.data.title) {
      body['travelName'] = travel.data.title
    }
    this.sendInfo.emit(body);
  }

  closeModal () {
    this.activeModal.dismiss('Cross click')
  }

  getOutMinDate () {
    return this.utilsService.getOutDateSame(
      this.minDateNgStruct,
      this.form.controls['beginDate'].value
    )
  }

  getOutMaxDate () {
    return this.utilsService.getOutDateSame(
      this.maxDateNgStruct,
      this.form.controls['endDate'].value
    )
  }

  getCompanyUsers() {
    this.companyService.getCompanyUsers(this.companyUuid).subscribe(
      (res) => {
        this.companyUsers = res.map(user => ({
          name: user.name,
          lastname: user.lastname,
          uuid: user.uuid
        }));
        if (this.expense) {
          this.form.patchValue({
            passenger: this.expense.userUuid
          });
        }
      },
      (err) => {
        console.error('Error fetching users:', err);
      }
    );
  }

  ngOnDestroy () {
    this.subcriptions.forEach(s => s.unsubscribe())
  }
}
