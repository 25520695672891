<router-outlet></router-outlet>
<div
  *ngIf="utilsService.loadingFlights"
  class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
>
  <div class="page-loading" style="opacity: 1; z-index: 98">
    <app-loader-plane></app-loader-plane>
  </div>
</div>
<div
  *ngIf="utilsService.loadingTrains"
  class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
>
  <div class="page-loading" style="opacity: 1; z-index: 98">
    <app-loader-train></app-loader-train>
  </div>
</div>
<div
  *ngIf="utilsService.loadingHotels"
  class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
>
  <div class="page-loading" style="opacity: 1; z-index: 98">
    <app-loader-hotel></app-loader-hotel>
  </div>
</div>
