import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { MediaService } from "@core/services/media.service";
import { Company } from "@models";
import { TranslateService } from "@ngx-translate/core";
import { CompanyService, NgxToastrService, UtilsService } from "@services";
import { Subscription } from "rxjs";
import swal from "sweetalert2";
@Component({
  selector: "app-company-card",
  templateUrl: "./company-card.component.html",
  styleUrls: ["./company-card.component.scss"],
})
export class CompanyCardComponent implements OnInit, OnDestroy {
  @Input() company: Company;
  @Output() reloadCompanies = new EventEmitter<any>();
  subscription: Subscription[] = [];
  image: any;
  formatDate: String;
  constructor(
    private companyService: CompanyService,
    private ngxToastrService: NgxToastrService,
    public translate: TranslateService,
    public mediaService: MediaService,
    private utilsService: UtilsService,
    private router: Router
  ) {}

  ngOnInit() {
    const creationDate = new Date(this.company.createdAt);
    const formatDate = `${creationDate.getDate()}-${creationDate.getMonth() + 1}-${creationDate.getFullYear()}`;
    this.formatDate = formatDate;

    if (this.company.image) {
      this.subscription.push(
        this.mediaService.getMedia(this.company.image).subscribe((s) => {
          const reader = new FileReader();
          reader.readAsDataURL(s);
          let base64data;
          reader.onloadend = () => {
            base64data = reader.result;
            this.image = base64data;
          };
        })
      );
    } else {
      this.image = "assets/img/gallery/image_not_available.png";
    }
  }

  onEdit() {
    // this.utilsService.editCompany(this.company, false, this.reloadCompanies);

    const extras: NavigationExtras = {
      state: {
        loading: false,
        company: this.company,
        maxHotelPercentage: 100,
        maxFlightPercentage: this.utilsService.maxFlightPercentage,
      },
    };

    this.router.navigate(["companies/edit-company"], extras);
  }

  onDelete() {
    this.translate
      .get([
        "company.company-homepage.card.remove-company",
        "company.company-homepage.card.lost-data-company",
        "common.confirm",
        "common.cancel",
        "company.company-homepage.card.removed-company",
        "company.company-homepage.card.error-remove-company",
      ])
      .subscribe((result) => {
        swal
          .fire({
            title: result["company.company-homepage.card.remove-company"],
            text: result["company.company-homepage.card.lost-data-company"],
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0CC27E",
            cancelButtonColor: "#FF586B",
            confirmButtonText: result["common.confirm"],
            cancelButtonText: result["common.cancel"],
            customClass: {
              confirmButton: "btn btn-success btn-raised",
              cancelButton: "btn btn-danger btn-raised  mr-5",
            },
            buttonsStyling: false,
          })
          .then((action) => {
            if (action.value) {
              this.companyService.deleteCompany(this.company.uuid).subscribe(
                () => {
                  this.ngxToastrService.typeSuccess(
                    null,
                    result["company.company-homepage.card.removed-company"]
                  );
                  this.reloadCompanies.emit();
                },
                (err) => {
                  this.ngxToastrService.typeInfo(
                    result[
                      "company.company-homepage.card.error-remove-company"
                    ],
                    err.error.message
                  );
                }
              );
            }
          });
      });
  }
  ngOnDestroy() {
    this.subscription.forEach((s) => s.unsubscribe());
  }
}
