<app-loading [userType]="'user'" *ngIf="loading"></app-loading>
<div class="modal-header mt-4">
  <button
    *ngIf="showCreate && !create"
    class="btn-add"
    (click)="clickAction('assign')"
  >
    <img src="assets/img/svg/add-circle.svg" />
  </button>
  <button *ngIf="showEdit" class="btn-add" (click)="clickAction('assign')">
    <img src="assets/img/svg/project.svg" />
  </button>
  <h4 class="modal-title" id="modal-basic-title" *ngIf="showCreate && !create">
    {{ "project.title-modal" | translate }}
  </h4>
  <h4 class="modal-title" id="modal-basic-title" *ngIf="showEdit">
    {{ "project.title-modal-create" | translate }}
  </h4>
</div>
<div class="modal-body">
  <div class="row" *ngIf="!loading">
    <div class="col-12" *ngIf="showEdit && travelList.length > 0">
      <select
        [(ngModel)]="selected"
        (change)="changeSelected($event.target.value)"
        class="form-add d-flex m-auto"
      >
        <option [value]="'none'">{{ "project.none" | translate }}</option>
        <option *ngFor="let item of travelList" [value]="item.value">
          {{ item.viewValue }}
        </option>
      </select>
    </div>
    <div class="col-12 mt-4" *ngIf="showEdit && travelList.length <= 0">
      <h4>{{ "project.empty-assign-project-list" | translate }}</h4>
    </div>
    <div class="col-12" *ngIf="showCreate">
      <form [formGroup]="form">
        <input
          [formControl]="form.controls['title']"
          placeholder="{{ 'project.form.title' | translate }}"
          class="form-control form-add"
        />
        <div class="hotel-date-field" [formGroup]="form">
          <input
            #bookedRange
            readonly
            class="form-control form-add"
            (dateSelect)="onDateSelection($event)"
            placeholder="{{ 'hotel.custom.dates' | translate }}"
            #dp
            ngbDatepicker
            [dayTemplate]="t1"
            [autoClose]="'outside'"
            [displayMonths]="2"
            #d1="ngbDatepicker"
            readonly
            (click)="d1.toggle()"
            [minDate]="minDateNgStruct"
            [maxDate]="maxDateNgStruct"
          />
          <ng-template #t1 let-date let-focused="focused">
            <span
              class="custom-day"
              [ngClass]="{
                activeFrom: f.initDate.value === date,
                activeTo: f.endDate.value === date
              }"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>
        </div>
        <textarea
          [formControl]="form.controls['description']"
          placeholder="{{ 'project.form.description' | translate }}"
          class="form-control form-add-text"
        ></textarea>
      </form>
    </div>
    <div
      class="col-12 offset-4 button-group mt-4"
      *ngIf="!previousBooking && !bookingToAssign"
    >
      <button
        *ngIf="showCreate"
        type="button"
        class="mat-focus-indicator m-2 btn-assign mat-raised-button mat-button-base ng-star-inserted"
        (click)="closeModal()"
      >
        {{ "common.cancel" | translate }}
      </button>
      <button
        mat-raised-button
        *ngIf="showEdit"
        class="m-2 btn-assign"
        (click)="clickAction('create')"
      >
        {{ "project.createButton" | translate }}
      </button>
      <button
        mat-raised-button
        *ngIf="showCreate"
        class="m-2 btn-request text-white"
        (click)="createTravel()"
      >
        {{ "project.accept" | translate }}
      </button>
      <button
        mat-raised-button
        *ngIf="showEdit"
        class="m-2 btn-request text-white"
        (click)="addItemToTravel()"
      >
        {{ "project.accept" | translate }}
      </button>
    </div>
    <div class="col-12 offset-4" *ngIf="previousBooking">
      <button
        mat-raised-button
        color="primary"
        class="m-2"
        (click)="editTravel()"
      >
        {{ "project.edit" | translate }}
      </button>
    </div>
    <div class="col-12 offset-4" *ngIf="bookingToAssign">
      <button
        mat-raised-button
        class="m-2 btn-assign"
        (click)="addItemToTravel()"
      >
        {{ "project.assign" | translate }}
      </button>
    </div>
  </div>
</div>
