import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import {
  AuthService,
  FlightService,
  NgxToastrService,
  UserService,
} from "@services";
import * as moment from "moment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-pending-flight-modal",
  templateUrl: "./pending-flight-modal.component.html",
  styleUrls: ["./pending-flight-modal.component.scss"],
})
export class PendingFlightModalComponent implements OnInit, OnDestroy {
  @Input() booking: any;
  @Input() isUser: any;
  @Input() type: string;
  @Input() flightUuid?: string;
  @Output() sendInfo = new EventEmitter<any>();
  subscriptions: Subscription[] = [];
  checkDate = false;
  user;
  file: File;
  loading = false;
  totalAdults = 0;
  totalChildren = 0;
  returnFile: boolean;
  //Solo se usa cuando el vuelo es el mismo día
  uploadReturnCheckin: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private userService: UserService,
    private flightService: FlightService,
    private translate: TranslateService,
    private ngxToastrService: NgxToastrService
  ) { }

  ngOnInit() {
    this.checkPriceDate();
    const getUser = this.isUser
      ? this.authService.getProfile()
      : this.userService.getUser(this.booking.userUuid);
    getUser.subscribe((user) => {
      this.user = user;
    });
    if (this.booking.hotel) {
      this.booking.hotel.room.forEach((r) => {
        this.totalAdults += r.rates[0].adults;
        this.totalChildren += r.rates[0].children;
      });
    }

    this.returnFile = this.booking.returnCheckin ?? "false";
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }
  closeModal(s?: string) {
    if (s && this.file != null) {
      this.loading = true;
      this.flightService.uploadDocumentPending(this.flightUuid, this.file, this.returnFile ?? "false").subscribe(
        (result) => {
          this.loading = false;
          this.translate
            .get([
              "common.congrat",
              `${this.type?.toLowerCase()}.create-${this.type?.toLowerCase()}.created-${this.type?.toLowerCase()}`,
            ])
            .subscribe((resp) => {
              this.ngxToastrService.typeSuccess(
                resp["common.congrat"],
                resp[""]
              );
            });
          this.activeModal.dismiss("Cross click");
          this.sendInfo.emit(true);
        },
        (error) => {
          this.subscriptions.push(
            this.translate.get(`common.oops-error`).subscribe((resp) => {
              this.ngxToastrService.typeError(resp, "");
            })
          );
          this.activeModal.dismiss("Cross click");
        }
      );
    } else {
      this.activeModal.dismiss("Cross click");
      this.sendInfo.emit(false);
    }
  }

  formatTime(date) {
    return moment(date).format('HH:mm')
  }

  checkPriceDate() {
    this.checkDate = moment(this.booking.createdAt).isBefore(
      moment(new Date("2022-02-01"))
    );
  }

  isSame(departDate: any, returnDate: any) {
    departDate = moment(departDate).format("DD/MM/YYYY");
    returnDate = moment(returnDate).format("DD/MM/YYYY");

    return departDate === returnDate ? true : false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
