<app-user-detail
  *ngIf="(isAdmin && canUseTRM) || isEmployee"
  [userUuid]="userToBook"
></app-user-detail>
<div *ngIf="loadingFlight" class="col-12">
  <app-loading [userType]="'user'"></app-loading>
</div>
<div class="seat-selector-container" *ngIf="currentSegment != null">
  <div id="seatSelectorInfoContainer">
    <div id="seatSelectorActionsContainer">
      <div>
        <button
          id="previous-journey"
          mat-button
          mat-flat-button
          *ngIf="currentSegment.id > 0"
          [disabled]="currentSegment.id === segmentIds[0]"
          (click)="goPreviousJourney()"
        >
          {{ "flight.result-flight.select-seat.previous-journey" | translate }}
        </button>
      </div>
      <button
        id="next-journey"
        mat-button
        mat-flat-button
        *ngIf="currentSegment.id < segmentIds.length - 1"
        [disabled]="currentSegment.id === segmentIds[segmentIds.length - 1]"
        (click)="goNextJourney()"
      >
        {{ "flight.result-flight.select-seat.next-journey" | translate }}
      </button>
    </div>
  </div>
  <div id="seatSelectorPlaneContainer">
    <div class="seat-guide">
      <h2>{{ "my-bookeds.flight.seat-selection" | translate }}</h2>
      <div class="seat-guide-container">
        <div class="seat" style="grid-area: avilable">
          <img src="assets/img/svg/seat_available_v2.svg" alt="available" />
          <p>{{ "my-bookeds.flight.available" | translate }}</p>
        </div>
        <div class="seat" style="grid-area: selected">
          <img src="assets/img/svg/seat_selected_v2.svg" alt="selected" />
          <p>{{ "my-bookeds.flight.selected" | translate }}</p>
        </div>
        <div class="seat" style="grid-area: seat_taken">
          <img src="assets/img/svg/seat_taken_v2.svg" alt="seat_taken" />
          <p>{{ "my-bookeds.flight.noAvailable" | translate }}</p>
        </div>
      </div>
    </div>
    <div class="empty"></div>
    <mat-grid-list
      rowHeight="2em"
      *ngIf="transformedColumnsWithAisles[displayIndex] != null"
      [cols]="transformedColumnsWithAisles[displayIndex].length"
      class="columnTitleContainer"
    >
      <mat-grid-tile
        *ngFor="let column of transformedColumnsWithAisles[displayIndex]"
        >{{ column.code }}</mat-grid-tile
      >
    </mat-grid-list>
    <!-- <div class="rowAxisContainer">
      <mat-grid-list
        *ngFor="let row of currentSegmentRows"
        rowHeight="4em"
        cols="1"
      >
        <mat-grid-tile>Row {{ row }}</mat-grid-tile>
      </mat-grid-list>
    </div> -->
    <div
      class="seatMapContainer"
      *ngIf="currentSegment.seat_map != null; else noSeatsAvail"
    >
      <ng-container *ngFor="let availSeatRow of currentSegment.seat_map.avail">
        <mat-grid-list
          rowHeight="4em"
          [cols]="transformedColumnsWithAisles[displayIndex].length"
        >
          <mat-grid-tile *ngFor="let availSeat of availSeatRow.object">
            <div
              class="image-container"
              [ngClass]="{ taken: availSeat.taken }"
              *ngIf="
                (availSeat.aisle != null && availSeat.aisle !== true) ||
                  availSeat.aisle == null;
                else isAisle
              "
              (click)="selectSeat(availSeat)"
            >
              <img
                [src]="getIcon(availSeat)"
                alt="available seat"
                width="35"
                class="seat"
                [ngClass]="{
                  selected: availSeat.selected,
                  available: availSeat.selected === false
                }"
              />
              <!-- <span>{{
                availSeat.object.short_name ? availSeat.object.short_name : "X"
              }}</span> -->
            </div>
            <ng-template #isAisle>
              <div class="aisle" style="pointer-events: none">
                {{ availSeat.row }}
              </div>
            </ng-template>
          </mat-grid-tile>
        </mat-grid-list>
      </ng-container>
    </div>
    <ng-template #noSeatsAvail>
      <div class="seatMapContainer">
        {{ "flights.form.seatsUnavailable" | translate }}
      </div>
    </ng-template>
  </div>
  <div id="seatSelectorTimerContainer">
    <div id="timer">
      <h2>
        <countdown
          [config]="{ leftTime: 300, format: 'mm:ss' }"
          (event)="onTimerFinished($event)"
        ></countdown>
      </h2>
    </div>
    <div id="origin" class="details-flight-card">
      <div class="segment-container">
        <div class="segment-origin">
          <h2>{{ "my-bookeds.user.origin" | translate }}</h2>
          <!-- <p *ngIf="
            currentSegment.origin.city_name &&
            currentSegment.origin.country_name
          ">
            <small>{{ currentSegment.origin.city_name }},
              {{ currentSegment.origin.country_name }}</small>

          </p> -->
          <p>
            {{ originName }}
            ({{ currentSegment.origin.iata }})
          </p>
        </div>
        <p class="terminal-p">
          Terminal
          <span
            *ngIf="
              currentSegment.origin_terminal != null &&
                currentSegment.origin_terminal !== '';
              else unknownTerminalOrigin
            "
          >
            {{ currentSegment.origin_terminal }}
          </span>
          <ng-template #unknownTerminalOrigin>
            {{
              "flight.result-flight.select-seat.unknown-terminal" | translate
            }}
          </ng-template>
        </p>
      </div>
      <div class="segment-container">
        <div class="segment-departure">
          <p>
            {{ "my-bookeds.flight.dep-date" | translate }}
          </p>
          <p>
            {{ currentSegment.departure_date_time | date: "dd/MM/yyyy HH:mm" }}
          </p>
        </div>
        <img
          class="airlineLogo"
          [src]="getLogo(currentSegment.company.iata)"
          width="110px"
          alt="logo"
        />
      </div>
    </div>
    <div id="destination" class="details-flight-card">
      <div class="segment-container">
        <div class="segment-origin">
          <h2>{{ "my-bookeds.user.destiny" | translate }}</h2>
          <!-- <p>
            <small *ngIf="
            currentSegment.destination.city_name &&
            currentSegment.destination.country_name
          ">
              {{ currentSegment.destination.city_name }},
              {{ currentSegment.destination.country_name }}
            </small>
          </p> -->
          <p>
            {{ this.departureName }} ({{ currentSegment.destination.iata }})
          </p>
        </div>
        <p class="terminal-p">
          Terminal
          <span
            *ngIf="
              currentSegment.destination_terminal != null &&
                currentSegment.destination_terminal !== '';
              else unknownTerminalDestination
            "
          >
            {{ currentSegment.destination_terminal }}
          </span>
          <ng-template #unknownTerminalDestination>
            {{
              "flight.result-flight.select-seat.unknown-terminal" | translate
            }}
          </ng-template>
        </p>
      </div>
      <div class="segment-container">
        <div class="segment-departure" style="align-items: initial">
          <p>
            {{ "my-bookeds.flight.ret-date" | translate }}
          </p>
          <p>
            {{ currentSegment.arrival_date_time | date: "dd/MM/yyyy HH:mm" }}
          </p>
        </div>
        <img
          class="airlineLogo"
          [src]="getLogo(currentSegment.company.iata)"
          width="110px"
          alt="logo"
        />
      </div>
    </div>
    <div class="space-buttons">
      <button
        id="confirmSeatSelection"
        (click)="confirmSeats()"
        matTooltip="{{
          'flight.result-flight.select-seat.confirm-seat-selection-tooltip'
            | translate
        }}"
        id="confirmSeatSelection"
        class="btn btn-success btn-raised mt-2"
      >
        {{
          "flight.result-flight.select-seat.confirm-seat-selection" | translate
        }}
      </button>
      <button
        id="cancelSeatSelection"
        (click)="goToUserBookings(true)"
        matTooltip="{{
          'flight.result-flight.select-seat.cancel-seat-selection-tooltip'
            | translate
        }}"
        id="cancelSeatSelection"
        class="btn btn-danger btn-raised mt-2"
      >
        {{
          "flight.result-flight.select-seat.cancel-seat-selection" | translate
        }}
      </button>
    </div>
  </div>
</div>
