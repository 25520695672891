import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { TokenService } from "./token.service";

@Injectable()
export class TransactionService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  doPayment(paymentFlowBody: any): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/transactions/cash`,
      paymentFlowBody,
      { headers: reqHeaders }
    );
  }
}
