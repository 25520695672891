import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import {
  ConfirmSeatsQuery,
  ConfirmSeatsRQ,
  Fares,
  SeatAvail,
  SeatAvailColumn,
  SeatAvailRow,
  SeatAvailSegment,
  SeatMap,
  SeatQuery,
  SeatsAvailabilityRes,
  Segment,
} from "@core/models/flight-llego";
import { LetItGoType } from "@core/models/letitgo-type";
import {
  FlightLleegoService,
  NgxToastrService,
  StateService,
  UtilsService,
} from "@core/services";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "app/app.reducer";
import airports from "assets/utils/airports.json";
import { default as mockThreeThree } from "assets/utils/mockup/flight-three-three-seat-map-mockup.json";
import { default as mockTwoFour } from "assets/utils/mockup/flight-two-four-seat-map-mockup.json";
import * as _ from "lodash-es";
import moment from "moment";
import { CountdownEvent } from "ngx-countdown";
import { Subscription } from "rxjs";
import swal from "sweetalert2";

@Component({
  selector: "app-flight-seats-selection",
  templateUrl: "./flight-seats-selection.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./flight-seats-selection.component.scss"],
})
export class FlightSeatsSelectionComponent implements OnInit {
  isUser: boolean;
  userUuid: string;
  selectedFlights: Segment[][];
  travelersAge: number[];
  seatAvailabilityRes: SeatsAvailabilityRes;
  currentSegment: SeatAvailSegment;
  segments: any[];
  segmentIds: string[] = [];
  currentSegmentRows: number[] = [];
  currentSegmentColumns: SeatAvailColumn[][] = [];
  currentSegmentSelectedSeats: any[] = [];
  totalSelectedSeats: any[] = [];
  transformedSegments: SeatAvailSegment[] = [];
  transformedColumnsWithAisles: SeatAvailColumn[][] = [];
  displayIndex = 0;
  passengersArray: number[] = [];
  expected_amount = 0;
  selectedSeats: SeatQuery[] = [];
  passengersCopy: number[] = [];
  subcriptions: Subscription[] = [];
  token: string;
  bookingID: string;
  isMock = false;
  flattenedSegments: any[];
  totalPrice: any;
  locator: string;
  paymentId: number;
  loadingFlight = false;
  ancillaries: any[] = [];
  isAdmin = false;
  isEmployee = false;
  canUseTRM: boolean;
  userToBook: string;
  airports: any;
  originName: string;
  departureName: string;
  fareSelected: Fares;

  /**
   * Get current navigation data, start timer (because user can only expend 5 minutes selectien seats) and get the seats availability.
   * Also, fill the passengers array with the length of the number of passengers and with the value of the seats that can be selected
   * on each segment.
   * @param translate Translation service
   * @param router Router service
   * @param ngxToastrService Toast service
   * @param flightLleegoService Flight service
   */
  constructor(
    public translate: TranslateService,
    private router: Router,
    private ngxToastrService: NgxToastrService,
    private flightLleegoService: FlightLleegoService,
    public utilsService: UtilsService,
    private store: Store<AppState>,
    private stateService: StateService
  ) {
    this.subcriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() !== "USER") {
          this.isAdmin = true;
          if (s.user.type?.toUpperCase() === "EMPLOYEE") {
            this.isEmployee = true;
          }
          this.userToBook = localStorage.getItem("userToBook")
            ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
            : null;
          this.canUseTRM = s.user.type
            ? s.user.plan && s.user.plan.permissions.canUseTRM
            : s.user.user.plan && s.user.user.plan.permissions.canUseTRM;
        }
      })
    );

    this.userUuid = this.router.getCurrentNavigation().extras.state.userUuid;
    this.isUser = this.router.getCurrentNavigation().extras.state.isUser;
    this.selectedFlights =
      this.router.getCurrentNavigation().extras.state.selectedFlights;
    this.travelersAge =
      this.router.getCurrentNavigation().extras.state.travelersAge;
    this.seatAvailabilityRes =
      this.router.getCurrentNavigation().extras.state.seatAvailabilityRes;
    this.token = this.router.getCurrentNavigation().extras.state.token;
    this.bookingID = this.router.getCurrentNavigation().extras.state.bookingID;
    this.locator = this.router.getCurrentNavigation().extras.state.locator;
    this.totalPrice =
      this.router.getCurrentNavigation().extras.state.totalPrice;
    this.paymentId = this.router.getCurrentNavigation().extras.state.paymentId;
    this.ancillaries =
      this.router.getCurrentNavigation().extras.state.ancillaries;
    this.fareSelected =
      this.router.getCurrentNavigation().extras.state.fareSelected;
    if (this.seatAvailabilityRes == null || this.bookingID == null) {
      this.isMock = true;
      this.seatAvailabilityRes = {
        version: 2,
        token: this.token,
        solutions: [
          {
            line_i_d: null,
            locator: this.locator,
            segments: [],
          },
        ],
      };
      const totalSegmentsPerSol = this.selectedFlights.map(
        (segments) => segments
      );
      this.flattenedSegments = _.flatten(totalSegmentsPerSol);

      for (let index = 0; index < this.flattenedSegments.length; index++) {
        const segment = this.flattenedSegments[index];
        const transformedSegment: SeatAvailSegment = {
          id: index.toString(),
          source_i_d: index.toString(),
          origin: {
            iata: segment.object.departure,
          },
          destination: {
            iata: segment.object.arrival,
          },
          departure_date_time: segment.object.departure_date,
          arrival_date_time: segment.object.arrival_date,
          duration: this.calcPartialSegmentTime(segment),
          origin_terminal: segment.object.arrival_terminal,
          destination_terminal: segment.object.arrival_terminal,
          company: {
            iata: segment.object.marketing_company,
          },
          seat_map:
            index % 2 === 0
              ? JSON.parse(JSON.stringify(mockThreeThree))
              : JSON.parse(JSON.stringify(mockTwoFour)),
        };
        transformedSegment.seat_map.avail.forEach((row) => {
          row.object.forEach((seat) => {
            seat.object.seg_references = [index.toString()];
          });
        });
        this.seatAvailabilityRes.solutions[0].segments.push(transformedSegment);
      }
    }
    this.segments = this.seatAvailabilityRes.solutions[0].segments;
    for (let i = 0; i < this.segments.length; i++) {
      const numberOfPassengers =
        this.stateService.flightTravelers.length > 0
          ? this.stateService.flightTravelers.length
          : 1;
      this.passengersArray.push(numberOfPassengers);
    }
    this.transformedSegments = [...this.segments];
    this.updateCurrSegment();
    this.passengersCopy = [...this.passengersArray];

    this.setOriginAndDeparture();
  }

  ngOnInit(): void {}

  calcPartialSegmentTime(segment: Segment): number {
    const departDatetime = moment(
      segment.object.departure_date,
      moment.ISO_8601
    );
    const returnDatetime = moment(segment.object.arrival_date, moment.ISO_8601);
    const duration = moment.duration(returnDatetime.diff(departDatetime));
    return duration.asSeconds();
  }

  /**
   * Complete the columns rows with aisle seat and seat row with the number of seats that can be selected.
   */
  updateCurrSegment() {
    // this.fillCurrentSegmentRows();
    this.getColumns();
    this.fillWithAisles();
    this.transformSegments();
  }

  /**
   * Fill with aisle columns the columns of all segment.
   */
  fillWithAisles() {
    this.transformedColumnsWithAisles = [];
    const checkEvery = 3;
    let transformedColumns: string[] = [];
    if (this.currentSegmentColumns.length > 0) {
      this.currentSegmentColumns.forEach((column: SeatAvailColumn[]) => {
        if (column != null) {
          for (let columnIdx = 0; columnIdx < column.length; columnIdx++) {
            // const column = column[columnIdx];
            const threeColumns = column.slice(
              columnIdx,
              checkEvery + columnIdx
            );
            const threeColumnPositions = threeColumns.map((col) => {
              if (col.position === "") {
                col.position = "9";
              }
              return col.position;
            });
            const threeColumnPositionsAsString = threeColumnPositions.join("");
            let threeColumnAndAisle: string;
            switch (threeColumnPositionsAsString) {
              case "AA9":
              case "AAW":
                // PASILLO ENTRE A's
                threeColumnAndAisle =
                  threeColumnPositionsAsString[0] +
                  "|" +
                  threeColumnPositionsAsString[1] +
                  threeColumnPositionsAsString[2];
                break;
              case "9AA":
              case "WAA":
                // PASILLO ENTRE A's
                threeColumnAndAisle =
                  threeColumnPositionsAsString[0] +
                  threeColumnPositionsAsString[1] +
                  "|" +
                  threeColumnPositionsAsString[2];
                break;
              case "99A":
              case "W9A":
                // PASILLO AL FINAL
                threeColumnAndAisle = threeColumnPositionsAsString + "|";
                break;
              case "A9A":
                // PASILLO A IZQ Y DER
                threeColumnAndAisle = "|" + threeColumnPositionsAsString + "|";
                break;
              default:
                threeColumnAndAisle = threeColumnPositionsAsString;
                break;
            }
            columnIdx += checkEvery - 1;
            transformedColumns.push(threeColumnAndAisle);
          }

          const transformedColumnsAsString = transformedColumns.join("");
          transformedColumns = [];
          let numOfIteratedAisles = 0;
          const currentTransformedColumn: SeatAvailColumn[] = [];
          for (
            let letterIdx = 0;
            letterIdx < transformedColumnsAsString.length;
            letterIdx++
          ) {
            let code: string;
            if (transformedColumnsAsString[letterIdx] === "|") {
              code = this.translate.instant("flight.custom.request.aisle");
              numOfIteratedAisles++;
            } else {
              let variableIdx: number = letterIdx;
              if (letterIdx > column.length - 1 || numOfIteratedAisles > 0) {
                variableIdx = letterIdx - numOfIteratedAisles;
              }
              code = column[variableIdx].code;
            }
            currentTransformedColumn.push({
              position: transformedColumnsAsString[letterIdx],
              code: code,
            });
          }
          for (let i = 0; i < currentTransformedColumn.length; i++) {
            const col = currentTransformedColumn[i];
            if (currentTransformedColumn[i + 1]) {
              if (
                col.position === "|" &&
                currentTransformedColumn[i + 1].position === "|"
              ) {
                currentTransformedColumn.splice(i, 1);
              }
            }
          }
          this.transformedColumnsWithAisles.push(currentTransformedColumn);
        } else {
          this.transformedColumnsWithAisles.push(null);
        }
      });
    }
  }

  /**
   * Transform each segment in order to have the full row filled with seats and aisle seats.
   * Also, set selected atribute of seat to false in order to be able to select them.
   */
  transformSegments() {
    for (
      let indexOfSegment = 0;
      indexOfSegment < this.transformedSegments.length;
      indexOfSegment++
    ) {
      const segment = this.transformedSegments[indexOfSegment];
      if (segment.seat_map != null) {
        this.completeRow(segment.seat_map, indexOfSegment);
        this.addAisleObject(segment.seat_map.avail, indexOfSegment);
        this.setSelected(segment.seat_map.avail);
      }
    }
    this.currentSegment = this.transformedSegments[0];
    this.segmentIds = this.transformedSegments.map((segment) => segment.id);
  }

  /**
   * Set all seats to selected false.
   * @param avail Avail to be modified
   */
  setSelected(avail: SeatAvailRow[]) {
    avail.forEach((availRow: SeatAvailRow) => {
      availRow.object.forEach((seat) => {
        seat.selected = false;
      });
    });
  }

  /**
   * Fill the current segment columns array with the columns of the current segment.
   * Length of the columns array is the number segment to be displayed.
   */
  getColumns() {
    this.segments.forEach((segment: SeatAvailSegment) => {
      if (segment.seat_map != null) {
        this.currentSegmentColumns.push(segment.seat_map.columns);
      } else {
        this.currentSegmentColumns.push(null);
      }
    });
  }

  /**
   * Check is the current seat is selected if not set selected to true and push it to. Else set selected to false.
   * @param seat Seat to be selected
   */
  selectSeat(seat: SeatAvail) {
    // Asiento no ocupado
    // Es vuelo solo de ida y selecciona

    if (
      this.passengersArray[this.displayIndex] !== 0 &&
      seat.selected === false &&
      (seat.taken == null || seat.taken === false)
    ) {
      seat.selected = true;
      const seatSelected: SeatQuery = {
        column: seat.object.column,
        row: seat.object.row,
        pax: seat.object.pax_references[0],
        segment: seat.object.seg_references[0],
      };
      this.selectedSeats.push(seatSelected);
      this.passengersArray[this.displayIndex]--;
      this.expected_amount += seat.object.price;

      // Desselecciona el asiento
    } else if (seat.selected) {
      seat.selected = false;
      const seatFinded = this.selectedSeats.find(
        (seatIterate: SeatQuery) =>
          seatIterate.column === seat.object.column &&
          seatIterate.row === seat.object.row
      );
      const indexRemove = this.selectedSeats.indexOf(seatFinded);
      this.selectedSeats.splice(indexRemove, 1);
      this.expected_amount -= seat.object.price;
      this.checkCopyPassengers(this.displayIndex);
    }
  }

  /**
   * Set all not available seat to the given seat_map
   * @param seat_map Seat map to be modified
   * @param segmentIndex Index of the segment to be modified
   */
  completeRow(seat_map: SeatMap, segmentIndex: number) {
    seat_map.avail.forEach((rowSeat) => {
      let columnIndex = 0;
      for (let index = 0; index < rowSeat.object.length; index++) {
        const seat = rowSeat.object[index];
        const key = rowSeat.object.indexOf(seat);
        if (
          seat.object != null &&
          seat.object.column !== seat_map.columns[columnIndex].code
        ) {
          rowSeat.object.splice(key, 0, {
            taken: true,
            object: { column: seat_map.columns[columnIndex].code },
          });
        }
        if (columnIndex + 1 < seat_map.columns.length) {
          columnIndex++;
        }
      }
      columnIndex = 0;
      if (rowSeat.object.length < seat_map.columns.length) {
        const numOfColumnsLeft =
          seat_map.columns.length - rowSeat.object.length;
        const leftColumns = this.currentSegmentColumns[segmentIndex].slice(
          seat_map.columns.length - numOfColumnsLeft,
          seat_map.columns.length
        );
        for (let i = 0; i < leftColumns.length; i++) {
          rowSeat.object.push({
            taken: true,
            object: { column: leftColumns[i].code },
          });
        }
      }
    });
  }

  /**
   * Add aisle seats to the given seat_map
   * @param avail Array of seats to be modified
   * @param segmentIndex Index of the segment to be modified
   */
  addAisleObject(avail: SeatAvailRow[], segmentIndex: number) {
    const transformedColumnsWithAislesSegment =
      this.transformedColumnsWithAisles[segmentIndex];
    if (transformedColumnsWithAislesSegment != null) {
      avail.forEach((seatRow) => {
        seatRow.object.forEach((seat) => {
          if (seat.object != null) {
            const seatColumn = seat.object.column;
            const realColumnCode =
              transformedColumnsWithAislesSegment.findIndex(
                (column) => column.code === seatColumn
              );
            if (realColumnCode > -1) {
              const prev =
                transformedColumnsWithAislesSegment[realColumnCode - 1];
              const indexOfSeat = seatRow.object.indexOf(seat);

              if (
                prev != null &&
                prev.position === "|" &&
                indexOfSeat > 0 &&
                seatRow.object[indexOfSeat - 1].aisle &&
                seatRow.object[indexOfSeat - 1].aisle !== true
              ) {
                seatRow.object.splice(indexOfSeat, 0, {
                  aisle: true,
                });
              }
              const next =
                transformedColumnsWithAislesSegment[realColumnCode + 1];
              if (
                next != null &&
                next.position === "|" &&
                indexOfSeat < seatRow.object.length - 1
              ) {
                if (
                  (seatRow.object[indexOfSeat + 1].aisle != null &&
                    seatRow.object[indexOfSeat + 1].aisle !== true) ||
                  seatRow.object[indexOfSeat + 1].aisle == null
                ) {
                  seatRow.object.splice(indexOfSeat + 1, 0, {
                    aisle: true,
                  });
                }
              }
            }
          }
        });
      });
    } else {
    }
  }

  createSwalTimeout() {
    this.translate
      .get([
        "train.user-form.selectSeat",
        "flight.result-flight.select-seat.time-expired",
        "common.confirm",
        "common.cancel",
      ])
      .subscribe((result) => {
        swal
          .fire({
            position: "center",
            icon: "warning",
            title: result["flight.result-flight.select-seat.time-expired"],
            showConfirmButton: false,
            timer: 3000,
          })
          .then((action) => {
            if (action.value) {
              this.router.navigate([`/flight/seats-selection`]);
            } else {
              this.goToUserBookings();
            }
          });
      }),
      (err) => {
        this.translate
          .get("flight.form-flight.seat-error")
          .subscribe((resp) => {
            this.ngxToastrService.typeInfo(resp, "");
          });
      };
  }

  /**
   * By changing the display index, the segment array is updated to previous segment
   */
  goPreviousJourney() {
    const index = this.segmentIds.indexOf(this.currentSegment.id);
    if (index > 0) {
      const previousSegment = this.segments[index - 1];
      this.currentSegment = previousSegment;
      this.displayIndex--;
      this.setOriginAndDeparture();
    }
  }

  /**
   *  By changing the display index, the segment array is updated to next segment
   */
  goNextJourney() {
    const index = this.segmentIds.indexOf(this.currentSegment.id);
    if (index < this.segments.length - 1) {
      const nextSegment = this.segments[index + 1];
      this.currentSegment = nextSegment;
      this.displayIndex++;
      this.setOriginAndDeparture();
    }
  }

  fillCurrentSegmentRows() {
    this.currentSegmentRows = [];
    for (
      let i = this.currentSegment.seat_map.first_row;
      i <= this.currentSegment.seat_map.last_row;
      i++
    ) {
      this.currentSegmentRows.push(i);
    }
  }

  getLogo(value: string) {
    return `assets/img/airlines/small/${value}.png`;
  }

  goToUserBookings(isCancel?: boolean) {
    if (isCancel != null && isCancel && this.isMock) {
      this.letItGo(this.bookingID, this.locator);
    } else {
      const navigationExtras: NavigationExtras = {
        state: {
          module: "flights",
        },
      };
      if (this.isUser) {
        this.router.navigate([`/my-bookeds`], navigationExtras);
      } else {
        this.router.navigate(
          [`/user/booked/${this.userUuid}`],
          navigationExtras
        );
      }
    }
  }

  getIcon(availSeat: any, availSeatRow: any) {
    if (availSeat.taken === true) {
      return "assets/img/svg/seat_taken_v2.svg";
    } else {
      return availSeat.selected === true
        ? "assets/img/svg/seat_selected_v2.svg"
        : "assets/img/svg/seat_available_v2.svg";
    }
  }

  filterSelectedSeats() {
    let currentSegmentSelectedRows: any = this.currentSegment.seat_map.avail
      .map((row) => row.object.filter((seat) => seat.selected === true))
      .filter((row) => row.length > 0);
    currentSegmentSelectedRows = currentSegmentSelectedRows.flat();
    return currentSegmentSelectedRows;
  }

  confirmSeats() {
    if (this.isMock) {
      this.letItGo(this.bookingID, this.locator);
    } else {
      this.utilsService.loadingFlights = true;
      const confirmSeatsQuery: ConfirmSeatsQuery = {
        token: this.token,
        seats: this.selectedSeats,
        expected_amount: this.expected_amount,
      };
      const confirmSeatRQ: ConfirmSeatsRQ = {
        query: confirmSeatsQuery,
        paymentId: this.paymentId,
      };

      this.subcriptions.push(
        this.flightLleegoService
          .putSeatAvailabilityConfirm(
            this.bookingID,
            confirmSeatRQ,
            this.userUuid
          )
          .subscribe(
            (res) => {
              this.translate
                .get(["common.congrat", "user.form.success"])
                .subscribe((response) => {
                  this.utilsService.loadingFlights = false;
                  swal
                    .fire({
                      position: "center",
                      icon: "success",
                      title: response["common.congrat"],
                      text: response["user.form.success"],
                      showConfirmButton: false,
                      timer: 3000,
                    })
                    .then(() => {
                      this.goToUserBookings();
                    });
                });
            },
            (err) => {
              // this.translate
              //   .get("flight.form-flight.seat-error")
              //   .subscribe((resp) => {
              //     this.ngxToastrService.typeInfo(resp, "");
              //   });
              this.utilsService.loadingFlights = false;
              swal.close();
              // Se elimina el mensaje de error para poner el swal con el envío del email a operaciones
              /* this.translate.get("flight.form-flight.error").subscribe((resp) => {
              this.ngxToastrService.typeInfo(resp, "");
            }); */
              const goingStops = this.selectedFlights[0].slice(
                1,
                this.selectedFlights[0].length
              );
              let goingText = "Escala Ida:";
              if (goingStops.length > 1) {
                goingText = "Escalas Ida:";
                const body = {
                  locator: this.locator,
                  lleegoBookingID: this.bookingID,
                  itinerary: `Ida: (IATA aerolinea: ${
                    this.selectedFlights[0][0].object.marketing_company
                  }) ${this.selectedFlights[0][0].object.departure}  - ${
                    this.selectedFlights[0][this.selectedFlights[0].length - 1]
                      .object.arrival
                  }`,
                  stops: null,
                  stopsText: null,
                  seats: confirmSeatsQuery.seats
                    .map(
                      (seat) =>
                        `${seat.row}${seat.column} - Trayecto ${seat.segment}`
                    )
                    .join("\n"),
                  dates: this.selectedFlights[0][0].object.departure_date,
                  expectedAmount: confirmSeatsQuery.expected_amount,
                  price: this.totalPrice,
                  fare: this.fareSelected.cabin_name,
                };
                if (goingStops.length > 0) {
                  body.stopsText = `${goingText} ${goingStops
                    .map(
                      (seg) =>
                        `${seg.object.departure}-${seg.object.arrival} (IATA aerolinea: ${seg.object.marketing_company})`
                    )
                    .join(", ")}`;
                } else {
                  body.stopsText = `${goingText} ninguna`;
                }
                body.stops = goingStops.length;
                if (this.selectedFlights.length > 1) {
                  body.dates =
                    moment(
                      this.selectedFlights[0][0].object.departure_date
                    ).format("DD/MM/YYYY HH:mm") +
                    " - " +
                    moment(
                      this.selectedFlights[this.selectedFlights.length - 1][
                        this.selectedFlights[this.selectedFlights.length - 1]
                          .length - 1
                      ].object.departure_date
                    ).format("DD/MM/YYYY HH:mm");
                  const returnStops = this.selectedFlights[
                    this.selectedFlights.length - 1
                  ].slice(
                    1,
                    this.selectedFlights[this.selectedFlights.length - 1].length
                  );
                  let returnText = "Escala Vuelta:";
                  if (returnStops.length > 1) {
                    returnText = "Escalas Vuelta:";
                  }
                  body.itinerary += `\nVuelta: (IATA aerolinea: ${
                    this.selectedFlights[this.selectedFlights.length - 1][0]
                      .object.marketing_company
                  }) ${
                    this.selectedFlights[this.selectedFlights.length - 1][0]
                      .object.departure
                  }-${
                    this.selectedFlights[this.selectedFlights.length - 1][
                      this.selectedFlights[this.selectedFlights.length - 1]
                        .length - 1
                    ].object.arrival
                  }`;
                  if (returnStops.length > 0) {
                    body.stopsText += `\n${returnText} ${returnStops
                      .map(
                        (seg) =>
                          `${seg.object.departure}-${seg.object.arrival} (IATA aerolinea: ${seg.object.marketing_company})`
                      )
                      .join(", ")}`;
                  } else {
                    body.stopsText += `\n${returnText} ninguna`;
                  }
                  body.stops += returnStops.length;
                }
                this.utilsService.sendEmailOperations(
                  LetItGoType.flight,
                  body,
                  this.userUuid
                );
                this.translate
                  .get(["common.congrat", "common.operations"])
                  .subscribe((response) => {
                    swal
                      .fire({
                        position: "center",
                        icon: "success",
                        title: response["common.congrat"],
                        text: response["common.operations"],
                        showConfirmButton: false,
                        timer: this.utilsService.letItGoTimer,
                        timerProgressBar: true,
                      })
                      .then(() => {
                        this.goToUserBookings();
                      });
                  });
              }
            }
          )
      );
    }
  }

  letItGo(lleegoBookingID?: string, locator?: string) {
    let letItGoTravel;
    if (
      this.stateService.flightTravelers &&
      this.stateService.flightTravelers.length > 0
    ) {
      letItGoTravel =
        this.stateService.flightTravelers.length > this.travelersAge.length
          ? this.stateService.flightTravelers.length
          : this.travelersAge.length;
    } else {
      letItGoTravel = this.travelersAge.length;
    }
    const goingStops = this.selectedFlights[0].slice(
      1,
      this.selectedFlights[0].length
    );
    let goingText = "Escala Ida:";
    if (goingStops.length > 1) {
      goingText = "Escalas Ida:";
    }
    let body = {
      itinerary: `Ida: (IATA aerolinea: ${
        this.selectedFlights[0][0].object.marketing_company
      }) ${this.selectedFlights[0][0].object.departure}-${
        this.selectedFlights[0][this.selectedFlights[0].length - 1].object
          .arrival
      }`,
      travellers: letItGoTravel,
      dates: moment(this.selectedFlights[0][0].object.departure_date).format(
        "DD/MM/YYYY HH:mm"
      ),
      price: this.totalPrice,
      stops: null,
      stopsText: null,
      seats: [],
      lleegoBookingID,
      locator,
      baggages: this.ancillaries,
      fare: this.fareSelected.cabin_name,
    };
    if (goingStops.length > 0) {
      body.stopsText = `${goingText} ${goingStops
        .map(
          (seg) =>
            `${seg.object.departure}-${seg.object.arrival} (IATA aerolinea: ${seg.object.marketing_company})`
        )
        .join(", ")}`;
    } else {
      body.stopsText = `${goingText} ninguna`;
    }
    body.stops = goingStops.length;
    if (this.selectedFlights.length > 1) {
      body.dates =
        moment(this.selectedFlights[0][0].object.departure_date).format(
          "DD/MM/YYYY HH:mm"
        ) +
        " - " +
        moment(
          this.selectedFlights[this.selectedFlights.length - 1][
            this.selectedFlights[this.selectedFlights.length - 1].length - 1
          ].object.departure_date
        ).format("DD/MM/YYYY HH:mm");
      const returnStops = this.selectedFlights[
        this.selectedFlights.length - 1
      ].slice(1, this.selectedFlights[this.selectedFlights.length - 1].length);
      let returnText = "Escala Vuelta:";
      if (returnStops.length > 1) {
        returnText = "Escalas Vuelta:";
      }
      body.itinerary += `\nVuelta: (IATA aerolinea: ${
        this.selectedFlights[this.selectedFlights.length - 1][0].object
          .marketing_company
      }) ${
        this.selectedFlights[this.selectedFlights.length - 1][0].object
          .departure
      }-${
        this.selectedFlights[this.selectedFlights.length - 1][
          this.selectedFlights[this.selectedFlights.length - 1].length - 1
        ].object.arrival
      }`;
      if (returnStops.length > 0) {
        body.stopsText += `\n${returnText} ${returnStops
          .map(
            (seg) =>
              `${seg.object.departure}-${seg.object.arrival} (IATA aerolinea: ${seg.object.marketing_company})`
          )
          .join(", ")}`;
      } else {
        body.stopsText += `\n${returnText} ninguna`;
      }
      body.stops += returnStops.length;
    }
    const copy = JSON.parse(JSON.stringify(this.selectedSeats));
    copy.forEach((seat) => {
      seat.segment = `${
        this.flattenedSegments[seat.segment].object.departure
      }-${this.flattenedSegments[seat.segment].object.arrival}`;
    });
    body.seats = copy;
    this.utilsService.sendEmailOperations(
      LetItGoType.flight,
      body,
      this.userUuid
    );
    this.translate
      .get(["common.congrat", "common.operations"])
      .subscribe((response) => {
        swal
          .fire({
            position: "center",
            icon: "success",
            title: response["common.congrat"],
            text: response["common.operations"],
            showConfirmButton: false,
            timer: this.utilsService.letItGoTimer,
            timerProgressBar: true,
          })
          .then(() => {
            this.goToUserBookings();
          });
      });
  }

  /**
   * Check if the passenger number is the same as the passenger number in the copy array, if not,
   * plus one to the actual passenger array
   * @param actualSegment Segment to check the passengers
   */
  private checkCopyPassengers(actualSegment: number) {
    if (
      this.passengersArray[actualSegment] < this.passengersCopy[actualSegment]
    ) {
      this.passengersArray[actualSegment] += 1;
    }
  }

  onTimerFinished(e: CountdownEvent) {
    if (e.action == "done") {
      this.createSwalTimeout();
    }
  }

  getNameAirport(iata: string) {
    let nameAirport = "";
    airports["Table1"].forEach((ai) => {
      if (ai["IATA"] === iata) {
        nameAirport = ai["city"];
      }
    });
    return nameAirport;
  }

  setOriginAndDeparture() {
    this.originName = this.getNameAirport(this.currentSegment.origin.iata);
    this.departureName = this.getNameAirport(
      this.currentSegment.destination.iata
    );
  }
}
