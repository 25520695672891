<div *ngIf="loading" style="display: block; margin: auto">
  <app-loading [userType]="'employee'"></app-loading>
</div>
<!-- TABLA DE MIS VUELOS -->
<h4 class="title-pending-flights heading-5">
  {{ "company.company-homepage.pendings" | translate }}
</h4>

<div class="container-customize">
  <!-- <form [formGroup]="form"> -->

  <div class="button-row">
    <button class="back-button" (click)="goCompanies()"></button>
  </div>
  <div class="filter-row">

    <!-- COMMENT FILTERS -->
     <!-- <input class="search-input" [(ngModel)]="filterSearch" (input)="filterFlights()"
      placeholder="{{ 'common.search' | translate }}..." /> -->
    <div class="next-flight-and-filter">
      <!-- <button type="button" [ngClass]="{
            'clean-filters': filterSearch == '' && bookStatus == '',
            'clean-filters-active': !(filterSearch == '') || !(bookStatus == '')
          }" (click)="clearFilters()">
        {{ "common.clean" | translate }}
        <img *ngIf="filterSearch == '' && bookStatus == ''" src="assets/img/svg/grey-cross.svg" />
        <img *ngIf="!(filterSearch == '') || !(bookStatus == '')" src="assets/img/svg/red-cross.svg" />
      </button> -->
      <button [ngClass]="nextFlight ? 'custom-btn--yellow custom-btn' : 'custom-btn'" type="button" (click)="getNextCheckinFlights(nextFlight)">
        {{(!nextFlight ?
        "my-bookeds.user.next-flights" : "common.return") | translate}}</button>
    </div>
  </div>

  <table *ngIf="flightsToView.length > 0">
    <thead>
      <tr>
        <th scope="col">Checkin Ida</th>
        <th scope="col">Checkin Vuelta</th>
        <th scope="col">{{ "my-bookeds.user.user-name" | translate }}</th>
        <th scope="col">{{ "my-bookeds.user.origin" | translate }}</th>
        <th scope="col">{{ "my-bookeds.user.destiny" | translate }}</th>
        <th scope="col">{{ "my-bookeds.user.company-name" | translate }}</th>
        <th scope="col">
          {{ "my-bookeds.user.dep-date" | translate }}
        </th>
        <th scope="col">
          {{ "my-bookeds.user.ret-date" | translate }}
        </th>
        <th scope="col">
          {{ "my-bookeds.user.create-date" | translate }}
        </th>
        <th scope="col"></th>
      </tr>
    </thead>

    <tbody>


      <!-- DATA TABLE CHECKING -->

      <tr *ngFor="
          let booked of flightsToView | slice: lowValue:highValue;
          trackBy: utilsService.getUuidTracking
        " [ngClass]="{'pastFlight': booked.pastFlight}">

        <!-- CHECK PAST FLIGHT DONT HAVE CHECKING -->
        <td *ngIf="booked.pastFlight && !booked.departureCheckin && !booked.nextFlight">
          <img *ngIf="booked.departureCheckin" src="../../../../../assets/img/svg/on-check.svg" />
          <img *ngIf="!booked.departureCheckin" src="../../../../../assets/img/svg/off-check.svg" />
        </td>
        <td
          *ngIf="booked.pastFlight && (booked.returnCheckin || booked.departureCheckin || currentDate < formatDate(booked.returnDate) || booked.oneWay)">
        </td>
        <td
          *ngIf="booked.pastFlight && !booked.returnCheckin && currentDate > formatDate(booked.returnDate) && !booked.nextFlight && !booked.oneWay">
          <img *ngIf="booked.returnCheckin" src="../../../../../assets/img/svg/on-check.svg" />
          <img *ngIf="!booked.returnCheckin" src="../../../../../assets/img/svg/off-check.svg" />
        </td>

        <!-- NEXT FLIGHTS -->
        <td *ngIf="nextCurrentDate === formatDate(booked.departDate) && booked.nextFlight">
          <img *ngIf="booked.departureCheckin" src="../../../../../assets/img/svg/on-check.svg" />
          <img *ngIf="!booked.departureCheckin" src="../../../../../assets/img/svg/off-check.svg" />
        </td>
        <td
          *ngIf="(nextCurrentDate >= formatDate(booked.returnDate)) && booked.nextFlight && (formatDate(booked.departDate) !== formatDate(booked.returnDate))">
        </td>
        <td *ngIf="nextCurrentDate <= formatDate(booked.returnDate) && booked.nextFlight && !booked.oneWay">
          <img *ngIf="booked.returnCheckin" src="../../../../../assets/img/svg/on-check.svg" />
          <img *ngIf="!booked.returnCheckin" src="../../../../../assets/img/svg/off-check.svg" />
        </td>

        <!-- NORMAL FLIGHT -->
        <td *ngIf="currentDate === formatDate(booked.departDate) && !booked.nextFlight">
          <img *ngIf="booked.departureCheckin" src="../../../../../assets/img/svg/on-check.svg" />
          <img *ngIf="!booked.departureCheckin" src="../../../../../assets/img/svg/off-check.svg" />
        </td>
        <td
          *ngIf="(currentDate !== formatDate(booked.returnDate) || currentDate !== formatDate(booked.departDate)) && !booked.pastFlight && !booked.nextFlight ">
        </td>
        <td *ngIf="currentDate === formatDate(booked.returnDate) && !booked.nextFlight">
          <img *ngIf="booked.returnCheckin" src="../../../../../assets/img/svg/on-check.svg" />
          <img *ngIf="!booked.returnCheckin" src="../../../../../assets/img/svg/off-check.svg" />
        </td>

        <td>
          {{ booked.user?.name }} {{booked.user?.lastname}}
        </td>
        <td>{{ booked.from }}</td>
        <td>{{ booked.to }}</td>
        <td>
          {{ booked.company?.name }}
        </td>
        <td>
          {{ booked.departDate | dateHourMoment }}
        </td>
        <td>
          {{ booked.returnDate ? (booked.returnDate | dateHourMoment) : "-" }}
        </td>
        <td>
          {{ booked.createdAt | dateMoment: "YYYY-MM-DD":"DD/MM/YYYY" }}
        </td>
        <td>
          <mat-select #flightOptions>
            <mat-option (click)="onView(booked)">
              <div class="watch-booked"></div>
              <span class="watch-booked">{{
                "flight-train.upload-checkin" | translate
                }}</span>
            </mat-option>
            <mat-option (click)="onGetPdf(booked)"
              *ngIf="booked.voucherFileUuid">
              <div class="watch-booked"></div>
              <span class="see-bonus">{{
                "flight-train.see-bonus" | translate
                }}</span>
            </mat-option>
            <mat-option *ngIf="
                !isUser &&
                booked.status !== 'cancelled' &&
                checkDateCancel(booked.departDate)
              " (click)="onCancel(booked)">
              <div class="remove-booked"></div>
              <span class="remove-booked">{{
                "flight-train.cancel-book" | translate
                }}</span>
            </mat-option>
          </mat-select>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- EMPTY STATE -->
  <div class="text-center" *ngIf="flightsToView.length <= 0 && !loading">
    <img src="assets/img/gallery/flights_not_found.png" class="no_found_img" alt="No hay archivos" />
    <h3 class="not_found_text">
      <b>{{ "my-bookeds.user.no-bookeds" | translate }}</b>
    </h3>
  </div>
  <div *ngIf="flightsToView.length > 0" class="d-flex justify-content-center mt-4">
    <mat-paginator [length]="flightsToView.length" [pageSize]="10" (page)="getPaginatorData($event)"
      showFirstLastButtons [hidePageSize]="true" style="border-radius: 15px">
    </mat-paginator>
  </div>
</div>