<!--Forgot Password Starts-->
<section id="request-pass" [ngClass]="getClassBackground()">
  <div class="container-fluid forgot-password-bg">
    <div class="row full-height-vh m-0 d-flex align-items-center justify-content-center">
      <div class="col-md-6 col-xs-10">
        <div class="row">
          <div class="card col-12">
            <!-- <div>
            <img
              src="assets/img/gallery/forgot.png"
              alt="img-password"
              height="400"
            />
          </div> -->
            <div [ngClass]="{ 'loading-background': loading }" style="margin: auto">
              <h4 class="heading-5">
                {{ "login.request-pass.recovery-pass" | translate }}
              </h4>
              <p class="card-text">
                {{ "login.request-pass.give-mail" | translate }}
              </p>
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-group mb-3">
                  <input formControlName="email" type="email" id="email" class="form-control"
                    placeholder="{{ 'common.email' | translate }}" />
                </div>
                <div class="row justify-content-end mx-0">
                    <button class="custom-btn custom-btn--close mr-1" type="button" [routerLink]="goToLogin()">
                      <span>
                        {{ "login.request-pass.back-login" | translate }}
                      </span>
                    </button>
                    <button class="custom-btn" type="submit"
                      [disabled]="!form.valid || loading">
                      <span class="text-decoration-none text-white">
                        {{ "login.request-pass.recovery" | translate }}
                      </span>
                    </button>
                </div>
              </form>
            </div>
            <div *ngIf="loading" class="row">
              <app-loading [userType]="typeUser"></app-loading>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Forgot Password Ends-->