export const environment = {
  production: true,
  baseUrlApi: 'https://api.vyootrip.com/api/v1',
  socketUrlApi: 'https://api-socket.vyootrip.com',
  googleApiKEY: 'AIzaSyA8YFvJWqPSBEEH5S7kUKtx7c5FEotQ7ec',
  gaUser: 'UA-145864468-3',
  smartsuppKey: 'd34f634339a88f6bf06d7187db090199effaf4e9',
  smartsuppGroup: 'w0bpTY0QZz',
  hotJarCode: '1456439',
  versionTranslate: '1.4.2',
  jetId: 'wKG5ibquz1DYj6rTgfAt04yWNQvdJLU3',
  AMADEUS_ENABLED: 1
};