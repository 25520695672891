export class Car {
  uuid: string;
  userUuid: string;
  companyUuid: string;
  agencyUuid: string;
  hubUuid: string;
  from: string;
  to: string;
  pickupPlaceFrom: string;
  pickupPlaceTo: string;
  departDate: string;
  returnDate: string;
  age: number;
  voucherFileUuid?: string;
  voucherFileUrl?: string;
  voucherData?: any;
  amount: number;
  okTicketId?: string;
  reportOkticketId?: string;
  provider?: string;
  freeTaxes?: boolean;
  travelName?: string;
  insuranceWithExcess: boolean;
  carType: string;
  createdAt?: string;
  user?: any;
}

export class CarCreate {
  file: any;
  userUuid: string;
  from: string;
  to: string;
  pickupPlaceFrom: string;
  pickupPlaceTo: string;
  amount: number;
  age: number;
  travelName?: string;
  freeTaxes?: boolean;
  departDate: Date;
  returnDate: Date;
  provider: string;
  carType: string;
  insuranceWithExcess: boolean;
  comments?: string;
}
