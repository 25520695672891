import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-travelers",
  templateUrl: "./dialog-travelers.component.html",
  styleUrls: ["./dialog-travelers.component.scss"],
})
export class DialogTravelersComponent implements OnInit {
  travelerList: any[] = [];
  travelerForm: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<DialogTravelersComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private formBuilder: UntypedFormBuilder
  ) {
    if (data.travelerList != null) {
      this.travelerList = data.travelerList;
    }
  }

  ngOnInit(): void {
    this.travelerForm = this.formBuilder.group({
      travelers: this.formBuilder.array([]),
    });
    if (this.travelerList.length > 0) {
      this.travelerList.forEach((traveler) => {
        (this.travelerForm.get("travelers") as UntypedFormArray).push(
          this.formBuilder.group({
            age: [traveler.age, [Validators.min(0), Validators.required]],
          })
        );
      });
    }
  }

  addTraveler() {
    (this.travelerForm.get("travelers") as UntypedFormArray).push(
      this.formBuilder.group({
        age: [18, [Validators.min(0), Validators.required]],
      })
    );
  }

  removeTraveler() {
    const travelerFormArr = this.getTravelers();
    if (travelerFormArr.value.length > 1) {
      travelerFormArr.removeAt(travelerFormArr.value.length - 1);
    }
  }

  getTravelers(): UntypedFormArray {
    return this.travelerForm.get("travelers") as UntypedFormArray;
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    this.travelerList = [];
    this.getTravelers().value.forEach((traveler) => {
      this.travelerList.push(traveler);
    });
    this.dialogRef.close(this.travelerList);
  }
}
