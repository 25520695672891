import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Fares, MultifamilyRS } from "@core/models";
import { FareServicesListComponent } from "./components/fare-services-list/fare-services-list.component";

@Component({
  selector: "app-flight-fares-list",
  templateUrl: "./flight-fares-list.component.html",
  styleUrls: ["./flight-fares-list.component.scss"],
})
export class FlightFaresListComponent implements OnInit {
  @Input() multiFamily: MultifamilyRS;
  @Output() nextStep: EventEmitter<any> = new EventEmitter();
  @Output() openPolicy: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  getMiscellaneousSvg(service) {
    if (service.name?.toLowerCase().includes("early")) {
      return "check-priority";
    } else if (service.name?.toLowerCase().includes("prior")) {
      return "lounge";
    } else if (service.name?.toLowerCase().includes("meal")) {
      return "meal";
    } else if (service.name?.toLowerCase().includes("avios")) {
      return "avios";
    } else if (service.name?.toLowerCase().includes("freq")) {
      return "frecuent";
    } else {
      return "extras";
    }
  }

  touchAndUntouch(fare: Fares) {
    this.multiFamily.fares.forEach((fare) => {
      fare.touched = false;
    });
    fare.touched = true;
  }

  openServicesModal(fare: Fares) {
    /* const dialogRef =  */ this.dialog.open(FareServicesListComponent, {
      height: "auto",
      width: "40%",
      maxHeight: "85%",
      minHeight: "75%",
      panelClass: "fare-list-container",
      data: { fare: fare },
    });
  }

  emitNextStep(fare: Fares, fareName: string) {
    this.nextStep.emit({ fare: fare, fareName: fareName });
  }

  transformFareString(words: string) {
    const wordMod = words.replace(
      words.charAt(0),
      words.charAt(0)?.toUpperCase()
    );
    return wordMod;
  }

  checkMoreServices(services: any[]) {
    const remainService: any[] = [];
    if (services.length > 1) {
      services.forEach((service) => {
        if (
          service.name &&
          service.name !== "" &&
          service.indicator !== "NOF"
        ) {
          remainService.push(service);
        }
      });
      if (remainService.length > 1) {
        return true;
      }
    } else {
      return false;
    }
  }

  getMultifamilyServiceIcon(services: any, name: string) {
    let res = '';
    if (!services) {
      return 'assets/img/svg/dash.svg';
    }
    services = services.find((s) => s.category === name);
    if (!services) {
      return 'assets/img/svg/dash.svg';
    }
    switch (services.indicator) {
      case 'MIX':
        res = "assets/img/svg/yellow-check.svg"
        break;
      case 'CHA':
        res = "assets/img/svg/blue-euro.svg"
        break;
      case 'NOF':
        res = "assets/img/svg/red-cross.svg"
        break;
      case 'INC':
        res = "assets/img/svg/green-check.svg"
        break;
      case 'NIN':
      default:
        res = 'assets/img/svg/dash.svg';
    }
    return res;
  }

  seeIndicatorInfo(fare: any, name: string) {
    let res = fare.journeys[0] + ": ";
    if (!fare.services) {
      return 'Sin información';
    }
    let services = fare.services.find((s) => s.category === name);
    if (!services) {
      return 'Sin información';
    }
    switch (services.journeys[0]) {
      case 'CHA':
        res += "Con cargos: " + services.names[0] + "\n";
        break;
      case 'NOF':
        res += "No incluido" + "\n";
        break;
      case 'INC':
        res += "Incluido: " + services.names[0] + "\n";
        break;
      case 'NIN':
      default:
        res += 'Sin información' + "\n";
    }
    if (fare.journeys[1]) {
      res += fare.journeys[1] + ": ";
      switch (services.journeys[1]) {
        case 'CHA':
          res += "Con cargos: " + services.names[1] + "\n";
          break;
        case 'NOF':
          res += "No incluido" + "\n";
          break;
        case 'INC':
          res += "Incluido: " + services.names[1] + "\n";
          break;
        case 'NIN':
        default:
          res += 'Sin información' + "\n";
      }
    }
    return res;
  }

  openPolicies(multi: any) {
    this.openPolicy.emit(multi);
  }

}
