import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as Highcharts from "highcharts";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import Variablepie from "highcharts/modules/variable-pie";
HC_exporting(Highcharts);
HC_exporting_data(Highcharts);
Variablepie(Highcharts);
@Component({
  selector: "app-bar-optm",
  templateUrl: "./bar-optm.component.html",
  styleUrls: ["./bar-optm.component.scss"],
})
export class BarOptmComponent implements OnInit {
  @Input() satisfaction;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.loadBar();
  }

  loadBar() {
    Highcharts.setOptions({
      colors: [
        "#52c9ad",
        "#9271f6",
        "#17362f",
        "#058DC7",
        "#50B432",
        "#ED561B",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
      ],
    });
    this.translate
      .get([
        "company.bar-optm.title",
        "company.bar-optm.grade",
        "company.bar-optm.info",
        "company.bar-optm.reduce",
        "company.bar-optm.save",
        "company.bar-optm.method",
        "company.bar-optm.competitor",
        "company.transaction.filters.amount"
      ])
      .subscribe((res) => {
        Highcharts.chart("bar", {
          chart: {
            type: "column",
          },

          title: {
            text: res["company.bar-optm.title"],
          },

          subtitle: {
            text: res["company.bar-optm.grade"],
          },

          legend: {
            align: "right",
            verticalAlign: "middle",
            layout: "vertical",
          },

          xAxis: {
            categories: [
              res["company.bar-optm.info"],
              res["company.bar-optm.reduce"],
              res["company.bar-optm.save"],
            ],
            labels: {
              x: -10,
            },
          },

          yAxis: {
            allowDecimals: false,
            title: {
              text: res["company.transaction.filters.amount"],
            },
          },

          series: [
            {
              type: undefined,
              name: res["company.bar-optm.method"],
              data: [
                this.satisfaction &&
                this.satisfaction !== null &&
                this.satisfaction.commonValue1
                  ? this.satisfaction.commonValue1
                  : 1,
                this.satisfaction &&
                this.satisfaction !== null &&
                this.satisfaction.commonValue2
                  ? this.satisfaction.commonValue2
                  : 3,
                this.satisfaction &&
                this.satisfaction !== null &&
                this.satisfaction.commonValue3
                  ? this.satisfaction.commonValue3
                  : 1,
              ],
            },
            {
              type: undefined,
              name: res["company.bar-optm.competitor"],
              data: [
                this.satisfaction &&
                this.satisfaction !== null &&
                this.satisfaction.mainValue1
                  ? this.satisfaction.mainValue1
                  : 6,
                this.satisfaction &&
                this.satisfaction !== null &&
                this.satisfaction.mainValue2
                  ? this.satisfaction.mainValue2
                  : 4,
                this.satisfaction &&
                this.satisfaction !== null &&
                this.satisfaction.mainValue3
                  ? this.satisfaction.mainValue3
                  : 2,
              ],
            },
            {
              type: undefined,
              name: "VyooTrip",
              data: [
                this.satisfaction &&
                this.satisfaction !== null &&
                this.satisfaction.vyooValue1
                  ? this.satisfaction.vyooValue1
                  : 9,
                this.satisfaction &&
                this.satisfaction !== null &&
                this.satisfaction.vyooValue2
                  ? this.satisfaction.vyooValue2
                  : 7,
                this.satisfaction &&
                this.satisfaction !== null &&
                this.satisfaction.vyooValue3
                  ? this.satisfaction.vyooValue3
                  : 5,
              ],
            },
          ],
          exporting: {
            csv: {},
            buttons: {
              contextButton: {
                menuItems: ["downloadPNG", "downloadJPEG"],
              },
            },
          },
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: null,
                  maxHeight: null,
                },
                chartOptions: {
                  legend: {
                    align: "center",
                    verticalAlign: "bottom",
                    layout: "horizontal",
                  },
                  yAxis: {
                    labels: {
                      align: "left",
                      x: 0,
                      y: -5,
                    },
                    title: {
                      text: null,
                    },
                  },
                  subtitle: {
                    text: null,
                  },
                  credits: {
                    enabled: false,
                  },
                },
              },
            ],
          },
        });
      });
  }
}
