<!--Forgot Password Starts-->
<section id="reset-password" [ngClass]="getClassBackground()">
  <div class="container-fluid forgot-password-bg">
    <div
      class="row full-height-vh m-0 d-flex align-items-center justify-content-center"
    >
      <div class="col-md-7 col-sm-12">
        <div class="card">
          <div class="card-content">
            <div class="card-body fg-image">
              <div
                class="row m-0"
                [ngClass]="{ 'loading-background': loading }"
              >
                <div class="col-12 px-4 pt-3">
                  <h4 class="card-title mb-2">
                    {{ "common.new-pass" | translate }}
                  </h4>
                  <br />
                  <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group mb-3">
                      <p style="margin-bottom: 0 !important">
                        {{
                          "login.request-pass.reset.select-new-pass"
                            | translate
                        }}*
                      </p>
                      <input
                        formControlName="password"
                        type="password"
                        id="password"
                        class="form-control rounded-1"
                      />
                    </div>

                    <div class="form-group mb-3">
                      <p style="margin-bottom: 0 !important">
                        {{ "common.write-pass-again" | translate }}*
                      </p>
                      <input
                        formControlName="confirmPassword"
                        type="password"
                        id="confirmPassword"
                        class="form-control rounded-1"
                      />
                    </div>

                    <div class="row fg-actions d-flex justify-content-between">
                      <div class="col-12">
                        <button
                          class="btn btn-block rounded-1"
                          [ngClass]="getClassRecoveryButton()"
                          mat-button
                          [disabled]="!form.valid || loading"
                        >
                          <a class="text-decoration-none text-white">
                            {{ "common.send" | translate }}
                          </a>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <!--TODO: redimensionar imágenes  -->                
                <!-- <div class="col-lg-6 d-none d-lg-block text-center py-3">
                  <img
                    src="assets/img/gallery/lock.png"
                    alt=""
                    class="img-fluid"
                    width="300"
                    height="230"
                  />
                </div> -->
              </div>
              <div *ngIf="loading" class="row">
                <app-loading [userType]="typeUser"></app-loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Forgot Password Ends-->
