import { ResidentType } from ".";
import { Plan } from "./plan";

export class User {
  uuid: string;
  type: string;
  email: string;
  name: string;
  lastname: string;
  dni?: string;
  dniIssuanceDate?: string;
  dniExpirationDate?: string;
  passport?: string;
  passportIssuanceDate?: string;
  passportExpires?: string;
  phone?: string;
  birthdate?: string;
  gender: "male" | "female";
  address?: string;
  city?: string;
  image?: string;
  province?: string;
  postalCode?: string;
  country: string;
  groupRestrictionUuid: string;
  active: boolean;
  accessType?: string;
  nationality?: string;
  currentCountry?: string;
  passportCountryEmitter?: string;
  resident?: ResidentType;
  employeeNumber?: string;
  companyUuid?: string;
  isGroupAdmin?: boolean;
  plan?: Plan;
  renfeCard?: string;
  ffCard?: string;
  annotations?: string;
  notifications?: any;
  nieIssuanceDate?: any;
  identificationType?: any;
  nie?: any;
  nieExpirationDate?: string;
}
