<div id="global-container">
  <div id="button-cont">
    <button class="close" (click)="close()">
    <img src="assets/img/svg/close.svg" alt="close" />
    </button>
  </div>

  <div class="row" id="first">
    <div class="col-12">
      <h3 class="heading-5">
        {{ "my-bookeds.view.authorization-details" | translate }}
      </h3>
      <div class="row-data">
        <div class="icon-bcg icon-bcg--small">
          <img src="assets/img/svg/sidebar/profile.svg" alt="employee-icon" />
        </div>
        <div class="row-data-text">
          <span class="bold">{{ "authorization.labels.employee" | translate }}:
          </span>
          <span class="auth-text">{{
            authorization.userName + " " + authorization.userSurname
            }}</span>
        </div>  
      </div>
      <div class="row-data">
        <div class="icon-bcg icon-bcg--small">
          <img src="assets/img/svg/pen-black.svg" alt="pencil-icon" />
        </div>
        <div class="row-data-text">
          <span class="bold">{{ "authorization.concept" | translate }}: </span>
          <span class="auth-text">{{ authorization.description }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row" id="second-row">
    <div class="booking-data" *ngIf="authorization.type?.toLowerCase() === 'booking'">
      <div class="row-data">
        <div class="icon-bcg icon-bcg--small">
          <img src="assets/img/svg/sidebar/hotel.svg" alt="hotel-green" />
        </div>
        <div class="row-data-text">
          <span class="bold mr-1">{{ "search.search-homepage.destiny" | translate }}:
          </span>
          <span class="auth-text">
            {{ authorization.requestData.destinationName }}
          </span>
        </div>
      </div>
      <div class="row-data">
        <div class="icon-bcg icon-bcg--small">
          <img src="assets/img/svg/calendar-black.svg" alt="calendar" />
        </div>
        <span class="bold">{{ "my-bookeds.user.entry-date" | translate }}: </span>
        <span class="auth-text">{{ fromD }}</span>
      </div>
      <div *ngIf="toD != null" class="row-data">
        <div class="icon-bcg icon-bcg--small">
          <img src="assets/img/svg/calendar-black.svg" alt="calendar" />
        </div>
        <span class="bold">{{ "my-bookeds.user.dep-date" | translate }}:</span>
        <span class="auth-text">{{ toD }}</span>
      </div>
    </div>
    <div class="no-booking-data" *ngIf="authorization.type?.toLowerCase() !== 'booking'">
      <div>
        <div class="row-data">
          <div class="icon-bcg icon-bcg--small">
            <img src="assets/img/svg/calendar-black.svg" alt="calendar-icon-green" />
          </div>  
          <div class="d-flex flex-wrap">
            <span class="bold">{{ "my-bookeds.user.dep-date"| translate }}: </span>
            <span class="auth-text">{{ fromD }}</span>
          </div>
        </div>
        <div class="row-data">
          <div class="icon-bcg icon-bcg--small">
            <img *ngIf="authorization.type?.toLowerCase() === 'train'" src="assets/img/svg/sidebar/train.svg" alt="train" />
            <img *ngIf="authorization.type?.toLowerCase() === 'flight'" src="assets/img/svg/flight-black.svg" alt="flight" />
          </div>
          <span class="bold">{{
            getHours(authorization, true)
            }}</span>
          <span class="auth-text">
            {{ authorization.requestData.origin ? authorization.requestData.origin : authorization.requestData.from }} 
          </span>
        </div>
      </div>
      <div>
        <div class="row-data" *ngIf="toD != null">
          <div class="icon-bcg icon-bcg--small">
            <img src="assets/img/svg/calendar-black.svg" alt="calendar" />
          </div>
          <div class="d-flex flex-wrap">
            <span class="bold">{{ "my-bookeds.user.ret-date" | translate }}:</span>
            <span class="auth-text">{{ toD }}</span>
          </div>
        </div>
        <div class="row-data">
          <div class="icon-bcg icon-bcg--small">
            <img *ngIf="authorization.type?.toLowerCase() === 'train'" src="assets/img/svg/sidebar/train.svg" alt="train" />
            <img *ngIf="authorization.type?.toLowerCase() === 'flight'" src="assets/img/svg/flight-black.svg" alt="flight" />
          </div>
          <span class="bold">{{ getHours(authorization, false) }}</span>
          <span class="auth-text">{{ authorization.requestData.destination ? authorization.requestData.destination :
              authorization.requestData.to }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="title-content-container" *ngIf="authorization.type === 'flight' && authorization.requestData.airlines.length > 0">
    <div class="title">
      <span>{{'csv.flight.airline' | translate}}: </span>
    </div>
    <div class="content multiple">
      <div class="airline" *ngFor="let airline of authorization.requestData.airlines; let i = index">
        <span *ngIf="i === 0">{{'my-bookeds.train.way-going' | translate}}</span>
        <span *ngIf="i === 1">{{'my-bookeds.train.come-back' | translate}}</span>
        <img [src]="getLogo(airline)" alt="airline-logo">
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-12">
      <div>
        <span class="bold">{{ "company.company-booked.booking-type" | translate }}:</span>
        <span class="auth-text">
          {{
          (authorization.type?.toLowerCase() === "booking"
          ? "common.hotel"
          : authorization.type?.toLowerCase() === "flight"
          ? "common.flight"
          : "common.train"
          ) | translate
          }}</span>
      </div>

      <!-- Boton de información sobre los detalles de las tarifas -->
      <div *ngIf="authorization.requestData.fareSelected" class="fare-information-button">
        
        <button class="btn btn-outline-secondary me-2" placement="left" 
        [ngbPopover]="popover">
          <img id="click-trigger" src="assets/img/svg/information.svg" />
        </button>

        <ng-template #popover>
          <li *ngFor="let service of authorization.requestData.fareSelected.services">{{service.name}}</li>
        </ng-template>
        <span class="bold">{{"my-bookeds.view.fare-name" | translate}}:</span>
        <span class="auth-text">{{authorization.requestData.fareSelected.cabin_name}}</span>

      </div>
      <div *ngIf="authorization.type?.toLowerCase() === 'booking'">
        <div *ngIf="authorization.requestData.rooms[0].nameRoom">
          <span class="bold">{{'my-bookeds.view.room-name' | translate}}: </span>
          <span class="auth-text">{{authorization.requestData.rooms[0].nameRoom}}</span>
        </div>
        <div *ngIf="authorization.requestData.rooms[0].cancellationDate && authorization.requestData.rooms[0].cancellationDate[0]?.cancellationDateText">
          <span class="bold">{{'my-bookeds.view.cancellation-detail' | translate}}: </span>
          <span class="auth-text">{{authorization.requestData.rooms[0].cancellationDate && authorization.requestData.rooms[0].cancellationDate[0]?.cancellationDateText}}</span>
        </div>
        <div *ngIf="authorization?.totalAdults">
          <span class="bold">{{ "my-bookeds.view.totalAdults" | translate }}: </span>
          <span class="text">{{authorization?.totalAdults}}</span>
        </div>
        <div *ngIf="authorization?.totalRooms">
          <span class="bold">{{ "my-bookeds.view.totalRooms" | translate }}: </span>
          <span class="text">{{authorization?.totalRooms}}</span>
        </div>
      </div>
      <div>
        <span class="bold">{{ "company.company-booked.status" | translate }}:</span>

        <span *ngIf="authorization.status === 'no vacancy'" class="blue-font auth-text">
          {{ "authorization.no-vacancy" | translate }}
        </span>

        <span *ngIf="authorization.status === 'accepted'" class="green-font auth-text">
          {{ "common.approved" | translate }}
        </span>

        <span *ngIf="authorization.status === 'request'" class="yellow-font auth-text">
          {{ "common.pending" | translate }}
        </span>

        <span *ngIf="authorization.status === 'denied'" class="red-font auth-text">
          {{ "common.denied" | translate }}
        </span>
      </div>
      <div>
        <span class="bold">{{ "my-bookeds.view.price" | translate }}:</span>
        <span class="auth-text" *ngIf="authorization.requestData.query"> {{ authorization.requestData.query.vyooPrice | price
          }} €</span>
        <span class="auth-text" *ngIf="!authorization.requestData.query"> {{ authorization.price | price }} €</span>
      </div>
      <div *ngIf="authorization.type.toLowerCase() === 'flight' && travellersWithFFDocuments.length > 0">
        <span class="bold">
          {{ "my-bookeds.train-flight.ff-card" | translate }}:
        </span>
        <ul>
          <li *ngFor="let traveller of travellersWithFFDocuments">
            {{ traveller.fullName }}: {{ traveller.documentNumber }}
          </li>
        </ul>
      </div>
      <div *ngIf="authorization.comments !== ''">
        <span class="bold">
          {{ "my-bookeds.view.comments" | translate }}:
        </span>
        <span>{{ authorization.comments }}</span>
      </div>
      <div>
        <span class="bold">{{ "common.creation-date" | translate }}: </span>
        <span class="auth-text">{{
          authorization.createdAt | dateMoment: "YYYY-MM-DD":"DD/MM/YYYY"
          }}</span>
      </div>
      <div *ngIf="authorization.response">
        <span class="bold">{{'my-bookeds.view.response' | translate}}: </span>
        <span class="auth-text">{{authorization.response}}</span>
      </div>
      <div *ngIf="authorization.requestData.adminAuthorizatorData">
        <span class="bold">{{'my-bookeds.view.admin-authorization' | translate}}: </span>
        <div style="display: flex; flex-direction: row; gap: 5px;">
          <span class="auth-text">{{authorization.requestData.adminAuthorizatorData.name}}</span>
          <span class="auth-text"
            *ngIf="authorization.requestData.adminAuthorizatorData.lastname">{{authorization.requestData.adminAuthorizatorData.lastname}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="authorization.status.toLowerCase() === 'request' && (user.accessType?.toLowerCase() !== 'user' || user.isGroupAdmin)" class="col-12" id="text-area">
      <div class="textarea-container">
        <textarea required="true" class="form-control form-control--squared" #textarea placeholder="{{ 'authorization.swal.response' | translate }}"></textarea>
      </div>
    </div>
    <div class="col-12 buttons-container"
      *ngIf="authorization.status?.toLowerCase() === 'request' && (user.accessType?.toLowerCase() !== 'user' || user.isGroupAdmin)">
      <button class="custom-btn mt-2 mr-3" (click)="onDeny(authorization)">
        {{ "common.toReject" | translate }}
      </button>
      <button class="custom-btn mt-2" (click)="onAccept(authorization)">
        {{ "common.toApproved" | translate }}
      </button>
    </div>
  </div>
</div>
