<!--Forgot Password Starts-->
<section id="reset-password-complete-user" [ngClass]="getClassBackground()">
  <div class="container-fluid forgot-password-bg">
    <div class="row full-height-vh m-0 d-flex align-items-center justify-content-center">
      <div class="col-md-7 col-sm-12">
        <div class="card">
          <div class="card-content">
            <div class="card-body fg-image">
              <div class="row m-0">
                <!--TODO: redimensionar imágenes  -->
                <!-- <div class="col-lg-6 d-none d-lg-block text-center py-3">
                  <img
                    src="assets/img/gallery/login.png"
                    alt=""
                    class="img-fluid"
                    width="300"
                    height="230"
                  />
                </div> -->
                <div class="col-12 px-4 pt-3">
                  <h4 class="card-title mb-2">
                    <b>{{
                      "login.request-pass.reset-complete.pass-changed"
                        | translate
                    }}</b>
                  </h4>
                  <br />
                  <p>
                    {{
                      "login.request-pass.reset-complete.pass-changed-link"
                        | translate
                    }}
                  </p>
                  <br />
                  <div class="fg-actions d-flex justify-content-between">
                    <button [routerLink]="goToLogin()" routerLinkActive="router-link-active"
                      class="btn btn-block text-decoration-none mb-1 rounded-1" [ngClass]="getClassButton()">
                      {{
                        "login.request-pass.reset-complete.go-main-page"
                          | translate
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Forgot Password Ends-->