import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { NgxToastrService } from "./ngx.toastr.service";
import { UtilsService } from "./utils.service";

@Injectable({
  providedIn: "root",
})
export class AllowService implements OnDestroy, OnInit {
  subcriptions: Subscription[] = [];
  isEmployee = false;
  constructor(
    private translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.subcriptions.forEach((sub) => sub.unsubscribe());
  }

  canBookOrRequest(isRequest: boolean) {
    const company = this.utilsService.getLocalStorageItem("company").value;

    const allowBookingWithoutCard = !company["company"]
      ? company["allowBookingWithoutCard"]
      : company["company"]["allowBookingWithoutCard"];
    const canRequestWithoutCard = !company["plan"]
      ? company["canRequestWithoutCard"]
      : company["plan"]["permissions"]["canRequestWithoutCard"];
    const withoutCard = allowBookingWithoutCard || canRequestWithoutCard;
    const hasCard = !company["company"]
      ? company["hasCard"]
      : company["company"]["hasCard"];

    return withoutCard || (!withoutCard && hasCard);
  }

  adviceMissingCard(isRequest: boolean) {
    this.subcriptions.push(
      this.translate
        .get(
          isRequest
            ? [
                "search.search-booked.advice-cant-request-title",
                "search.search-booked.advice-cant-request",
              ]
            : [
                "search.search-booked.advice-missing-card-title",
                "search.search-booked.advice-missing-card",
              ]
        )
        .pipe(take(1))
        .subscribe((result) => {
          this.ngxToastrService.typeInfo(
            isRequest
              ? result["search.search-booked.advice-cant-request-title"]
              : result["search.search-booked.advice-missing-card-title"],
            isRequest
              ? result["search.search-booked.advice-cant-request"]
              : result["search.search-booked.advice-missing-card"]
          );
        })
    );
  }
}
