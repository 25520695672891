<div class="input-group">
  <div class="input-group-append">
    <button class="btn btn-outline-secondary" type="button">
      <img src="assets/img/svg/location.svg"/>
    </button>
  </div>
  <input
    id="address-input"
    [readonly]="readOnly"
    [(ngModel)]="locationTxt"
    type="text"
    (keypress)="disableEnter($event)"
    placeholder="{{ 'search.search-homepage.enter-location' | translate }}"
    class="form-control"
    #addresstext
  />
</div>
