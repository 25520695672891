import { Billingdata } from "./billingdata";

export class Company {
  uuid: string;
  name: string;
  phone?: string;
  cif: string;
  address: string;
  city: string;
  province?: string;
  postalCode: string;
  country: string;
  email: string;
  contactEmail: string;
  type: "COMPANY";
  billingData: Billingdata;
  cardData: any;
  cardRedsys: any;
  bookedWithoutCard?: boolean;
  discount: string;
  flightDiscount?: any;
  agencyUuid?: string;
  hubUuid?: string;
  image?: string;
  suppliers?: string[];
  hotelComission?: any;
  trainComission?: any;
  planUuid?: string;
  annotations?: string;
  createdAt?: Date;
  accessType?: "company";
}
