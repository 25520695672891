import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService, NgxToastrService } from "@services";

@Component({
  selector: "app-request-password-reset",
  templateUrl: "./request-password-reset.component.html",
  styleUrls: ["./request-password-reset.component.scss"],
})
export class RequestPasswordResetComponent implements OnInit {
  form: UntypedFormGroup;
  loading = false;
  typeUser: "user" | "employee";
  mobile = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private ngxToastrService: NgxToastrService,
    public translate: TranslateService
  ) {
    if (this.router.url.includes("/login")) {
      this.typeUser = "user";
    } else {
      this.typeUser = "employee";
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    if (window.screen.width > 1024) { // 768px portrait
      this.mobile = true;
    }
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.loading = true;
    const forgotPost =
      this.typeUser === "user"
        ? this.authService.forgotUser(this.f.email.value)
        : this.authService.forgotEmployee(this.f.email.value);

    return forgotPost.subscribe(
      () => {
        this.loading = false;
        let routePasswordSent =
          this.typeUser === "user" ? "/login" : "/admin-login";
        routePasswordSent += "/reset-password-sent";
        this.router.navigate([routePasswordSent]);
      },
      (err) => {
        this.loading = false;
        this.translate.get("common.some-error").subscribe((result) => {
          this.ngxToastrService.typeInfo("Message", result);
        });
      }
    );
  }

  goToLogin() {
    return ["/login"];
  }

  getClassBackground() {
    switch (this.typeUser) {
      case "user":
        return "user-background";
      case "employee":
        return "employee-background";
    }
  }

  getClassLoginButton() {
    switch (this.typeUser) {
      case "user":
        return "btn-back-login-user";
      case "employee":
        return "btn-back-login-employee";
    }
  }

  getClassRecoveryButton() {
    switch (this.typeUser) {
      case "user":
        return "btn-primary";
      case "employee":
        return "btn-vyoo-primary";
    }
  }
}
