import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { FlightDto, FlightSelectDto, PreOrderDto } from "@models";
import { FlightPending } from "@shared/interfaces/amadeus/pnr/flight-pending";
import { FlightPendingList } from "@shared/interfaces/amadeus/pnr/flight-pending-list";
import { PreOrderResponse } from "@shared/interfaces/amadeus/preorder/preorder";
import { FlightList } from "@shared/interfaces/amadeus/search/flight-list";
import { SelectFlightResponse } from "@shared/interfaces/amadeus/select/select-flight-response";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TokenService } from "./token.service";

@Injectable()
export class FlightService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  postFlightRequest(flightReq: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/flight/request`,
      flightReq,
      { headers: reqHeaders }
    );
  }

  postCreateFlight(form: FormData, requestUuid?: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const url = requestUuid
      ? `${environment.baseUrlApi}/flight?requestUuid=${requestUuid}`
      : `${environment.baseUrlApi}/flight`;
    return this.http.post<any>(url, form, {
      headers: reqHeaders,
    });
  }

  getUserFlight() {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/flight`, { headers: reqHeaders })
      .pipe(map((res: any) => res.docs));
  }

  cancelFlight(flightId: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const body = { status: "cancelled" };
    return this.http.put<any>(
      `${environment.baseUrlApi}/flight/status/${flightId}`,
      body,
      { headers: reqHeaders }
    );
  }

  editFlight(flightId: string, body: FormData) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/flight/update/${flightId}`,
      body,
      { headers: reqHeaders }
    );
  }

  flightList(flightDto: FlightDto): Observable<FlightList> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<FlightList>(
      `${environment.baseUrlApi}/flightOrder/flightOffersSearch/`,
      flightDto,
      { headers: reqHeaders }
    );
  }

  selectFlight(
    selectFlightDto: FlightSelectDto
  ): Observable<SelectFlightResponse> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<SelectFlightResponse>(
      `${environment.baseUrlApi}/flightOrder/selectFlight/`,
      selectFlightDto,
      { headers: reqHeaders }
    );
  }

  preOrderFlight(preOrderDto: PreOrderDto): Observable<PreOrderResponse> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<PreOrderResponse>(
      `${environment.baseUrlApi}/flightOrder/preorderFlight/`,
      preOrderDto,
      { headers: reqHeaders }
    );
  }

  confirmFlight(confirmDto: any): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/flightOrder/orderFlight`,
      confirmDto,
      { headers: reqHeaders }
    );
  }

  // PNR services

  getFlightPendingList(): Observable<FlightPendingList> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get<FlightPendingList>(
      `${environment.baseUrlApi}/flight/pendings`,
      { headers: reqHeaders }
    );
  }

  getFlightPendingListCount(): any {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get<any>(
      `${environment.baseUrlApi}/flight/pendings/count`,
      { headers: reqHeaders }
    );
  }
  getOneFlightPending(pnr: string): Observable<FlightPending> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get<FlightPending>(
      `${environment.baseUrlApi}/flight/pnr/${pnr}`,
      { headers: reqHeaders }
    );
  }

  getPastFlightDontHaveChekin(): Observable<FlightPendingList> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get<FlightPendingList>(
      `${environment.baseUrlApi}/flight/pastFlight`,
      { headers: reqHeaders }
    );
  }

  getNextCheckinFlights(): any {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get<FlightPendingList>(
      `${environment.baseUrlApi}/flight/nextCheckinFlights`,
      { headers: reqHeaders }
    );
  }

  changeStatusAuthorization(body: any, uuid: string, type: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.put<any>(
      `${environment.baseUrlApi}/authorization/${uuid}/flight/${type}`,
      body,
      { headers: reqHeaders }
    );
  }

  uploadDocumentPending(pnr: string, doc: File, returnFile?) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const formData = new FormData();
    formData.append("file", doc);
    formData.append("returnFile", returnFile)
    return this.http.post<any>(
      `${environment.baseUrlApi}/flight/upload/${pnr}`,
      formData,
      { headers: reqHeaders }
    );
  }
}
