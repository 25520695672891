<h4 mat-dialog-title>
  {{ "flight.form-flight.traveler-selection" | translate }}
</h4>
<br />
<mat-dialog-content>
  <form [formGroup]="travelersAgeForm">
    <div class="travelerType">
      <i
        class="ft-minus-circle fa-2x"
        *ngIf="travelers - 1 > 0"
        (click)="removeTraveler()"
      ></i>
      <h4 class="mx-2 h-100 my-auto">
        {{ travelers }} {{ "flight.form-flight.travelers" | translate }}
      </h4>
      <i
        class="ft-plus-circle fa-2x"
        *ngIf="travelers >= 0 && travelers < 10"
        (click)="addTraveler()"
      ></i>
    </div>
    <br />

    <h3>TOTAL: {{ travelers }}</h3>
    <h3>{{ "flight.form-flight.travelers-age" | translate }}</h3>

    <div class="travelersAge">
      <div *ngFor="let traveler of agesFormGroups; let i = index">
        <div [formGroup]="traveler">
          <span style="margin-right: 1.2rem"
            >{{ "flight.detail-flight.passenger" | translate }}
            {{ i + 1 }}</span
          >
          <mat-form-field class="example-full-width">
            <input
              formControlName="age"
              type="number"
              id="{{ i }}"
              value="{{ travelersAge[i] }}"
              matInput
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<div class="modal-footer flex justify-content-center">
  <mat-dialog-actions>
    <button
      type="submit"
      [disabled]="!travelersAgeForm.valid"
      class="btn btn-primary buttonAccept button-style m-0"
      (click)="onSubmit()"
    >
      {{ "train.accept" | translate }}
    </button>
  </mat-dialog-actions>
</div>
