import { Pipe, PipeTransform } from "@angular/core";
import { LanguageService } from "@core/services";

@Pipe({ name: "translator", pure: false })
export class TranslatorPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(text: string) {
    let hour = "";
    let price: string;
    let date: string[];
    const spanish = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    const english = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const lang = this.languageService.getLanguage();
    if (text) {
      if (lang === "en" && text.includes("Cancelación GRATIS antes de")) {
        hour = text.split(" las ")[1].split(" del ")[0];
        date = text.split(" del ")[1].split(" de ");
        date[1] = english[spanish.indexOf(date[1]?.toLowerCase())];
        text =
          "FREE cancellation before " +
          date[0] +
          " " +
          date[1] +
          " " +
          date[2] +
          " " +
          hour;
      } else if (lang === "en" && text.includes("La cancelación depués de")) {
        hour = text.split(" las ")[1].split(" del ")[0];
        date = text.split(" del ")[1].split(" tiene ")[0].split(" de ");
        date[1] = english[spanish.indexOf(date[1]?.toLowerCase())];
        price = text.split(" coste de ")[1];
        text =
          "Cancellation after " +
          date[0] +
          " " +
          date[1] +
          " " +
          date[2] +
          " " +
          hour +
          " has a cost of " +
          price;
      } else if (lang === "es" && text.includes("La cancelación depués de")) {
        text = text.replace("depués", "después");
      }
    }
    return text;
  }
}
