import { Agency, Company, Employee, Hub, User } from "@models";
import * as fromAuthActions from "../actions/auth.actions";

export interface AuthState {
  loading: boolean;
  skipping: boolean;
  isAuthenticated: boolean;
  user: User | Employee | Company | Agency | Hub;
  error: any;
}

export const initialState: AuthState = {
  loading: false,
  skipping: true,
  isAuthenticated: false,
  user: null,
  error: null,
};

export function AuthReducer(
  state: AuthState = initialState,
  action: fromAuthActions.AuthActions
): AuthState {
  switch (action.type) {
    case fromAuthActions.LOGIN_USER: {
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
        skipping: true,
        user: null,
        error: null,
      };
    }
    case fromAuthActions.LOGIN_EMPLOYEE: {
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
        skipping: true,
        user: null,
        error: null,
      };
    }
    case fromAuthActions.LOGIN_COMPANY: {
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
        skipping: true,
        user: null,
        error: null,
      };
    }
    case fromAuthActions.POPULATE: {
      return {
        ...state,
        loading: true,
        skipping: true,
        error: null,
      };
    }
    case fromAuthActions.LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        error: null,
        user: action.user,
        skipping: false,
      };
    }
    case fromAuthActions.LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
        skipping: false,
      };
    }
    case fromAuthActions.LOGOUT: {
      return {
        ...state,
        skipping: true,
      };
    }
    case fromAuthActions.LOGOUT_SUCCESS:
    case fromAuthActions.LOGOUT_SUCCESS_REDIRECTION: {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        skipping: false,
        user: null,
        error: null,
      };
    }
    default: {
      return state;
    }
  }
}
