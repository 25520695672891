import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { GroupDetailComponent } from "./group-detail/group-detail.component";
import { GroupFormComponent } from "./group-form/group-form.component";

const routes: Routes = [
  { path: "create-group", component: GroupFormComponent },
  { path: "edit-group/:id", component: GroupFormComponent },
  { path: ":uuid/delete", component: GroupDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GroupRoutingModule {}
