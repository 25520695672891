<div class="modal-header">
  <h4 class="modal-title heading-5" id="modal-basic-title">
    {{
      (editing
        ? type + ".edit-" + type + ".edit-" + type
        : type + ".create-" + type + ".create-" + type
      ) | translate
    }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-4">
  <!-- <div class="row" *ngIf="isAdmin">
        <div class="col-12">
            <app-create-travel-modal [customBooking]="isAdmin ? true : false" [userUuid]="isAdmin ? userId : null"
                (sendInfo)="handleTravel($event)"></app-create-travel-modal>
        </div>
    </div>
    <hr /> -->
  <form
    [formGroup]="form"
    [ngClass]="{ 'loading-background': loading }"
    (ngSubmit)="form.valid && !loading ? onSubmit() : (isInvalid = true)"
  >
    <div class="row mt-2" *ngIf="editing && (userType?.toLowerCase() === 'employee' || userType?.toLowerCase() === 'company')">
      <div class="col-12">
        <div class="form-group">
          <label class="pt-0" for="passenger">{{ "common.traveller" | translate }}</label>
          <select
            formControlName="passenger"
            id="passenger"
            class="form-control custom-select"
          >
          <option *ngFor="let user of companyUsers" [value]="user.uuid">
            {{ user.name }} {{ user.lastname }}
          </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-6">
        <div class="form-group">
          <label
            >{{ type + ".create-" + type + ".booked-file" | translate }}*</label
          >
          <div>
            <input
              formControlName="file"
              type="file"
              id="file"
              class="inputFile"
              (change)="onFileChange($event)"
              accept="application/pdf"
            />
            <label for="file" class="px-3 py-1 labelFile" [class.disabled]="userType !== 'EMPLOYEE' && isEdit">{{
              type + ".create-" + type + ".choose-file" | translate
            }}</label>
            <label class="pl-3" *ngIf="file || (blobUrl && fileBack)">{{
              file ? file.name : fileBack.name
            }}</label>
          </div>
        </div>
      </div>
      <div class="col-6 pd" *ngIf="isAdmin">
        <div class="form-group">
          <div>
            <input
              formControlName="price"
              type="text"
              placeholder="{{ type + '.form-' + type + '.price' | translate }}"
              id="price"
              class="form-control"
              [ngClass]="{
                'input-error':
                  !form.controls.price.valid &&
                  isInvalid &&
                  !form.controls.price.disabled
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-6">
        <div class="form-group">
          <input
            formControlName="from"
            placeholder="{{ type + '.form-' + type + '.origin' | translate }}"
            type="text"
            id="from"
            class="form-control"
            matInput
            [matAutocomplete]="fromAuto"
            (input)="getCities($event.target.value)"
            [ngClass]="{
              'input-error': !form.controls.from.valid && isInvalid
            }"
          />
          <mat-autocomplete
            #fromAuto="matAutocomplete"
            panelWidth="20%"
            (optionSelected)="cleanCitiesList()"
          >
            <mat-option *ngFor="let option of citiesList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <input
            formControlName="to"
            placeholder="{{
              type + '.form-' + type + '.destination' | translate
            }}"
            type="text"
            id="to"
            class="form-control"
            matInput
            [matAutocomplete]="toAuto"
            (input)="getCities($event.target.value)"
            [ngClass]="{
              'input-error': !form.controls.to.valid && isInvalid
            }"
          />
          <mat-autocomplete
            #toAuto="matAutocomplete"
            panelWidth="20%"
            (optionSelected)="cleanCitiesList()"
          >
            <mat-option *ngFor="let option of citiesList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-6">
        <div class="form-group">
          <input
            class="form-control filter-dates-placeholder"
            [disabled]="this.userType != 'EMPLOYEE' && isEdit"
            readonly
            placeholder="{{
              type + '.form-' + type + '.date-departure' | translate
            }}"
            name="departDate"
            id="departDate"
            formControlName="departDate"
            ngbDatepicker
            #dDeparture="ngbDatepicker"
            (click)="dDeparture.toggle()"
            [minDate]="minDateNgStruct"
            [maxDate]="maxDateNgStruct"
            [ngClass]="{
              'input-error': !form.controls.departDate.valid && isInvalid
            }"
          />
          <div class="mt-2">
            <ngb-timepicker
              formControlName="timeDepart"
              [spinners]="false"
            ></ngb-timepicker>
            <div *ngIf="!form.controls.timeDepart.valid && isInvalid">
              {{"common.required" | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <input
            readonly
            [disabled]="f.departDate.hasError('required') || (this.userType != 'EMPLOYEE' && isEdit)"
            [ngClass]="{ dates: !f.departDate.hasError('required') }"
            class="form-control filter-dates-placeholder"
            placeholder="{{
              type + '.form-' + type + '.date-return' | translate
            }}"
            name="returnDate"
            id="returnDate"
            formControlName="returnDate"
            ngbDatepicker
            #dReturn="ngbDatepicker"
            (click)="dReturn.toggle()"
            [minDate]="getOutDate()"
            [maxDate]="maxDateNgStruct"
            [ngClass]="{
              'input-error': !form.controls.returnDate.valid && isInvalid && !form.controls.returnDate.disabled
            }"
          />
          <div class="mt-2">
            <ngb-timepicker
              [readonlyInputs]="f.departDate.hasError('required')"
              formControlName="timeReturn"
              [spinners]="false"
            ></ngb-timepicker>
            <div *ngIf="!form.controls.timeReturn.valid && isInvalid && !form.controls.timeReturn.disabled">
              {{"common.required" | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-6">
        <div class="form-group createCarSelectContainer">
          <mat-select
            class="form-control"
            placeholder="{{ 'car.custom.request.carType.holder' | translate }}"
            formControlName="carType"
            [ngClass]="{
              'input-error': !form.controls.carType.valid && isInvalid
            }"
          >
            <mat-option *ngFor="let type of carType" [value]="type.value">
              {{ type.viewValue }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <div>
            <input
              formControlName="age"
              placeholder="{{ type + '.form-' + type + '.age' | translate }}"
              type="number"
              id="age"
              class="form-control"
              [ngClass]="{
                'input-error': !form.controls.age.valid && isInvalid
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-6">
        <div class="form-group createCarSelectContainer">
          <mat-select
            class="form-control"
            placeholder="{{
              'car.custom.request.pickup.titleFrom-holder' | translate
            }}"
            formControlName="pickupPlaceFrom"
            [ngClass]="{
              'input-error': !form.controls.pickupPlaceFrom.valid && isInvalid
            }"
          >
            <mat-option
              *ngFor="let place of pickupOptions"
              [value]="place.value"
            >
              {{ place.viewValue }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group createCarSelectContainer">
          <mat-select
            class="form-control"
            placeholder="{{
              'car.custom.request.pickup.titleTo-holder' | translate
            }}"
            formControlName="pickupPlaceTo"
            [ngClass]="{
              'input-error': !form.controls.pickupPlaceTo.valid && isInvalid
            }"
          >
            <mat-option
              *ngFor="let place of pickupOptions"
              [value]="place.value"
            >
              {{ place.viewValue }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-6">
        <div class="form-group">
          <input
            formControlName="provider"
            placeholder="{{ type + '.form-' + type + '.provider' | translate }}"
            type="text"
            id="provider"
            class="form-control"
            [ngClass]="{
              'input-error': !form.controls.provider.valid && isInvalid
            }"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="checkbox-container">
          <mat-checkbox
            class="example-margin"
            formControlName="insuranceWithExcess"
          >
            <small>{{ "car.insuranceWithExcess" | translate }}</small>
          </mat-checkbox>
          <mat-checkbox class="example-margin" formControlName="freeTaxes">
            <small>{{ "car.free-taxes" | translate }}</small>
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <input
            formControlName="customCode"
            placeholder="{{ 'common.custom-code' | translate }}"
            type="text"
            id="customCode"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <input
            formControlName="costCenter"
            placeholder="{{ 'common.cost-center' | translate }}"
            type="text"
            id="costCenter"
            class="form-control"
          />
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-12">
        <div class="form-group">
          <textarea
            formControlName="comments"
            id="comments"
            class="textarea form-control"
            placeholder="{{
              type + '.form-' + type + '.annotations-request' | translate
            }}"
            rows="4"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            class="custom-btn mb-0"
            ngbAutofocus
            mat-button
            [ngStyle]="{
              cursor: !form.valid || loading ? 'not-allowed' : 'pointer'
            }"
            [ngClass]="{
              'disabled-button': !form.valid || loading
            }"
          >
            {{ "common.send" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="row" *ngIf="loading">
    <app-loading [userType]="'employee'"></app-loading>
  </div>
</div>
