import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { ConfigService } from "@services";
import { AppState } from "app/app.reducer";

@Component({
  selector: "app-full-layout",
  templateUrl: "./full-layout.component.html",
})
export class FullLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild("sidebarBgImage", { static: true }) sidebarBgImage: ElementRef;
  @ViewChild("appSidebar", { static: true }) appSidebar: ElementRef;
  @ViewChild("wrapper", { static: true }) wrapper: ElementRef;

  options = {
    direction: "ltr",
    bgColor: "black",
    bgImage: "",
  };

  hideSidebar: boolean;
  isCollapsed = false;
  isSidebar_sm = false;
  isSidebar_lg = false;
  bgColor = "black";
  bgImage = "";

  userType: string;

  public config: any = {};

  constructor(
    private configService: ConfigService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    // SE HACE LA COMPROBACIÓN DE USUARIO SI ESTÁ AUTENTICADO, SI LO ESTÁ SE COMPRUEBA QUE TIPO DE USUARIO ES Y SE PONDRÁ UN ESTILO U OTRO
    this.store.select("auth").subscribe((res: any) => {
      if (res.isAuthenticated) {
        this.userType = res.user.type
          ? res.user.type
          : res.user.user.accessType?.toUpperCase();
        if (
          this.userType === "COMPANY" &&
          res.user.plan.permissions.canUseTRM === false
        ) {
          this.userType = "USER";
        }
      }
    });

    this.config = this.configService.templateConf;
    // Cambiamos el fondo del side bar dependiendo de cual es el usuario
    switch (this.userType) {
      case "EMPLOYEE":
        this.bgColor = "bg-sidebar-employee";
        break;
      case "COMPANY":
        this.bgColor = "bg-sidebar-company";
        break;
      case "USER":
        this.bgColor = "bg-sidebar-user";
        break;
      case "AGENCY":
        this.bgColor = "bg-sidebar-agency";
        break;
      case "HUB":
        this.bgColor = "bg-sidebar-hub";
        break;
      default:
        this.bgColor = "bg-sidebar-user";
    }

    // if (this.userType === 'EMPLOYEE') {
    //
    // } else if (this.userType === 'COMPANY') {
    //        //} else {
    //
    // }

    if (!this.config.layout.sidebar.backgroundImage) {
      this.bgImage = "";
    } else {
      this.bgImage = this.config.layout.sidebar.backgroundImageURL;
    }

    if (this.config.layout.variant === "Transparent") {
      if (this.config.layout.sidebar.backgroundColor.toString().trim() === "") {
        this.bgColor = "bg-glass-1";
      }
    } else {
      if (this.config.layout.sidebar.backgroundColor.toString().trim() === "") {
        this.bgColor = "black";
      }
    }

    setTimeout(() => {
      if (this.config.layout.sidebar.size === "sidebar-lg") {
        this.isSidebar_sm = false;
        this.isSidebar_lg = true;
      } else if (this.config.layout.sidebar.size === "sidebar-sm") {
        this.isSidebar_sm = true;
        this.isSidebar_lg = false;
      } else {
        this.isSidebar_sm = false;
        this.isSidebar_lg = false;
      }
      this.isCollapsed = this.config.layout.sidebar.collapsed;
    }, 0);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.config.layout.dir) {
        this.options.direction = this.config.layout.dir;
      }

      if (this.config.layout.variant === "Dark") {
        this.renderer.addClass(this.document.body, "layout-dark");
      } else if (this.config.layout.variant === "Transparent") {
        this.renderer.addClass(this.document.body, "layout-dark");
        this.renderer.addClass(this.document.body, "layout-transparent");
        if (this.config.layout.sidebar.backgroundColor) {
          this.renderer.addClass(
            this.document.body,
            this.config.layout.sidebar.backgroundColor
          );
        } else {
          this.renderer.addClass(this.document.body, "bg-glass-1");
        }
        this.bgColor = "black";
        this.options.bgColor = "black";
        this.bgImage = "";
        this.options.bgImage = "";
        this.bgImage = "";
        this.renderer.setAttribute(
          this.sidebarBgImage.nativeElement,
          "style",
          "display: none"
        );
      }
    }, 0);
  }

  toggleHideSidebar($event: boolean): void {
    setTimeout(() => {
      this.hideSidebar = $event;
    }, 0);
  }

  getOptions($event): void {
    this.options = $event;
  }
}
