import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NavigationExtras, Router } from "@angular/router";
import { LetItGoType } from "@core/models/letitgo-type";
import {
  BookedService,
  NgxToastrService,
  TrainService,
  TravelUtilsService,
  UtilsService,
  AuthService,
} from "@core/services";
import { NgbDateParserFormatter, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { DialogAssignProjectComponent } from "@shared/component/dialog-assign-project/dialog-assign-project.component";
import { ViewPdfModalComponent } from "app/modules/businessEntities/user/user-detail/view-pdf-modal/view-pdf-modal.component";
import moment from "moment";
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import swal from "sweetalert2";

@Component({
  selector: "app-confirm-booking",
  templateUrl: "./confirm-booking.component.html",
  styleUrls: ["./confirm-booking.component.scss"],
})
export class ConfirmBookingComponent implements OnInit, OnDestroy {
  @Input() isMocked: boolean;
  @Input() params: any;
  @Input() uuidUser: any;
  @Input() searchConfig: any;
  @Input() confirmedSelection: any;
  @Input() passengerForm: any;
  @Input() outwardSeats: any;
  @Input() returnSeats: any;
  @Input() isUser: any;
  @Input() isAdmin: boolean;
  @Input() userType: string;
  @Input() amount: any;
  @Input() farePriceMap: any;
  @Input() canRequest: boolean;
  @Input() trainsComplements: any;
  @Input() bookFailed: boolean;
  private subscriptions: Subscription[] = [];
  extraInfoForm: UntypedFormGroup;
  navigationExtras: any;

  constructor(
    private trainService: TrainService,
    private travelUtilsService: TravelUtilsService,
    private utilsService: UtilsService,
    private translate: TranslateService,
    private router: Router,
    private modalService: NgbModal,
    private ngbDateParser: NgbDateParserFormatter,
    private bookingService: BookedService,
    private authService: AuthService,
    private ngxToastrService: NgxToastrService,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.extraInfoForm = this.fb.group({
      customCode: [null],
      costCenter: [null],
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  cancel() {
    history.back();
  }

  accept() {
    this.utilsService.loadingTrains = true;
    this.params.customCode = this.extraInfoForm.value.customCode;
    this.params.costCenter = this.extraInfoForm.value.costCenter;
    this.params.oneWay = this.confirmedSelection.length > 1 && this.confirmedSelection[1] != null
      ? false
      : true;
    this.params.price = this.calcTotal() * 1;
    this.params.departDate = new Date(
      parseInt(
        this.confirmedSelection[0].travelDate.substring(0, 4),
        10
      ),
      parseInt(
        this.confirmedSelection[0].travelDate.substring(4, 6),
        10
      ) - 1,
      parseInt(
        this.confirmedSelection[0].travelDate.substring(6, 8),
        10
      ),
      this.confirmedSelection[0].departTime.split(":")[0],
      this.confirmedSelection[0].departTime.split(":")[1]
    );
    if (
      this.confirmedSelection.length > 1 &&
      this.confirmedSelection[1] != null
    ) {
      this.params.returnDate = new Date(
        parseInt(
          this.confirmedSelection[1].travelDate.substring(0, 4),
          10
        ),
        parseInt(
          this.confirmedSelection[1].travelDate.substring(4, 6),
          10
        ) - 1,
        parseInt(
          this.confirmedSelection[1].travelDate.substring(6, 8)
        ),
        this.confirmedSelection[1].departTime.split(":")[0],
        this.confirmedSelection[1].departTime.split(":")[1]
      );
    }
    this.params.from = this.confirmedSelection[0].departureStationName;
    this.params.to = this.confirmedSelection[0].arrivalStationName;
    if (this.bookFailed) {
      delete this.params.apiSession;
      delete this.params.train;
      delete this.params.oneWay;
      this.trainService.createLetItGo(this.params).subscribe((res) => {
        this.letItGo();
      }, (error) => {
        this.letItGo();
      })
    } else {
      if (this.params.oneWay) {
        this.params.rates.pop();
      }
      if (this.isMocked === false) {
        this.subscriptions.push(
          this.trainService.postTrainBuyTrainMore(this.params).subscribe(
            (r) => {
              this.utilsService.loadingTrains = false;
              this.translate
                .get([
                  "common.congrat",
                  "search.search-booked.success-room-booked",
                ])
                .subscribe((response) => {
                  swal
                    .fire({
                      position: "center",
                      imageUrl: "../../../../../assets/img/svg/check-circle.svg",
                      title: response["common.congrat"],
                      text: response["search.search-booked.success-room-booked"],
                      showConfirmButton: false,
                      timer: 300000000000,
                    })
                    .then(() => {

                      //this.onGetPDF(r);
                      const navigationExtras: NavigationExtras = {
                        state: {
                          module: "trains",
                        },
                      };
                      if (this.userType === "USER") {
                        this.router.navigate(
                          [`/my-bookeds`],
                          navigationExtras
                        );
                      } else {
                        this.router.navigate(
                          [`/user/booked/${this.uuidUser}`],
                          navigationExtras
                        );
                      }

                      // this.handleTravelFlow(r.uuid).subscribe(
                      //   (res) => {
                      //     if (res != null) {
                      //       if (res.isCreate) {
                      //         this.travelUtilsService.createTravel(
                      //           res.travelData,
                      //           res.addBody,
                      //           res.travelData.type
                      //         );
                      //       } else {
                      //         this.travelUtilsService.addItemToTravel(
                      //           res.addBody,
                      //           res.travelData.uuid
                      //         );
                      //       }
                      //     }
                      //     this.onGetPDF(r);
                      //     const navigationExtras: NavigationExtras = {
                      //       state: {
                      //         module: "trains",
                      //       },
                      //     };
                      //     if (this.userType === "USER") {
                      //       this.router.navigate(
                      //         [`/my-bookeds`],
                      //         navigationExtras
                      //       );
                      //     } else {
                      //       this.router.navigate(
                      //         [`/user/booked/${this.uuidUser}`],
                      //         navigationExtras
                      //       );
                      //     }
                      //   },
                      //   (err) => {}
                      // );
                    });
                });
            },
            (err) => {
              // Se elimina el mensaje de error para poner el swal con el envío del email a operaciones
              /*           this.subscriptions.push(
                          this.translate
                            .get(["train.trenymas.messages.error"])
                            .subscribe((result) => {
                              this.loading = false;
                              this.ngxToastrService.typeInfo(
                                "Error",
                                result["train.trenymas.messages.error"]
                              );
                            })
                        );
                        this.router.navigate(["/train"]); */
              this.letItGo();
            }
          )
        );
      } else {
        this.letItGo();
      }
    }
  }

  requestAuthorization() {
    this.utilsService.loadingTrains = true;

    const companyUuid = localStorage.getItem("company")
      ? JSON.parse(this.utilsService.decrypt(localStorage.getItem("company")))
        .value.uuid
      : null;

    const params = {
      origin: this.confirmedSelection[0].departureStationName,
      destination: this.confirmedSelection[0].arrivalStationName,
      departDate: `${moment(
        `${this.confirmedSelection[0].travelDate} ${this.confirmedSelection[0].departTime}`,
        "YYYYMMDD HH:mm"
      ).toISOString()}`,
      returnDate:
        this.confirmedSelection.length > 1 && this.confirmedSelection[1] != null
          ? `${moment(
            `${this.confirmedSelection[1].travelDate} ${this.confirmedSelection[1].departTime}`,
            "YYYYMMDD HH:mm"
          ).toISOString()}`
          : "",
      oneWay:
        this.confirmedSelection.length > 1 && this.confirmedSelection[1] != null
          ? false
          : true,
      annotations: "Solicitud de tren con preautorización",
      travelers: this.passengerForm.value.users.map(
        (u) => `${u.name} ${u.surname} - Renfe Card: ${u.renfeCard}`
      ),
      seats:
        this.outwardSeats != null || this.returnSeats != null
          ? { outwardSeats: this.outwardSeats, returnSeats: this.returnSeats }
          : "",
      userUuid: this.uuidUser,
      companyUuid,
      passengers: this.params.passengers,
      rates: this.confirmedSelection
    };

    const authorization = {
      type: "train",
      price: this.calcTotal(),
      requestData: params,
      fromDate: `${moment(
        `${this.confirmedSelection[0].travelDate} ${this.confirmedSelection[0].departTime}`,
        "YYYYMMDD HH:mm"
      ).toISOString()}`,
      toDate:
        this.confirmedSelection.length > 1 && this.confirmedSelection[1] != null
          ? `${moment(
            `${this.confirmedSelection[1].travelDate} ${this.confirmedSelection[1].departTime}`,
            "YYYYMMDD HH:mm"
          ).toISOString()}`
          : "",
      customCode: this.extraInfoForm.value.customCode,
      costCenter: this.extraInfoForm.value.costCenter,
    };
    if (this.uuidUser) {
      authorization["userUuid"] = this.uuidUser;
    }
    this.subscriptions.push(
      this.bookingService.requestAuthorization(authorization).subscribe(
        () => {
          this.utilsService.loadingTrains = false;
          this.translate
            .get(["common.congrat", "authorization.train"])
            .subscribe((response) => {
              swal
                .fire({
                  title: response["common.congrat"],
                  text: response["authorization.train"],
                  position: "center",
                  showConfirmButton: false,
                  icon: "success",
                  timer: 5000,
                })
                .then(() => {
                  const navigationExtras: NavigationExtras = {
                    state: {
                      module: "trains",
                    },

                  };
                  this.authService.totalAuthorizations++;
                  this.router.navigate([`/my-bookeds`], navigationExtras);
                });
              if (response.Amount != null && response.Amount.isNan()) {
                swal.close();
                this.utilsService.loadingTrains = false;
                this.translate.get("authorization.error").subscribe((resp) => {
                  this.ngxToastrService.typeError(resp, "error");
                });
              }
            });
        },
        (err) => {
          swal.close();
          this.utilsService.loadingTrains = false;
          this.translate.get("authorization.error").subscribe((resp) => {
            this.ngxToastrService.typeError(resp, err.error.message);
          });
        }
      )
    );
  }

  onGetPDF(booked: any) {
    this.bookingService
      .getBookedsPdf(booked.voucherFileUrl)
      .pipe(take(1))
      .subscribe(
        (res) => {
          const modalRef = this.modalService.open(ViewPdfModalComponent, {
            size: "lg",
            centered: true,
          });
          modalRef.componentInstance.blobUrl = URL.createObjectURL(res);
          modalRef.componentInstance.fileName = booked.voucherFileUuid;
        },
        () =>
          this.translate
            .get(["common.error-pdf"])
            .pipe(take(1))
            .subscribe((value) =>
              this.ngxToastrService.typeInfo(null, value["common.error-pdf"])
            )
      );
  }

  handleTravelFlow(bookingUuid: string): Observable<any> {
    const checkInAux = moment(
      this.ngbDateParser.format(this.searchConfig.departDate),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const checkOutAux =
      this.searchConfig.returnDate != null
        ? moment(
          this.ngbDateParser.format(this.searchConfig.returnDate),
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD")
        : null;

    const bookingData = {
      initDate: checkInAux,
      bookingUuid: bookingUuid,
      type: "train",
    };

    if (checkOutAux !== null) {
      bookingData["endDate"] = checkOutAux;
    }

    bookingData["userUuid"] = this.uuidUser;
    const dialogAssignProject = this.dialog.open(DialogAssignProjectComponent, {
      data: { bookingData, isUser: this.isUser, isNewBooking: true },
      panelClass: "new-dialog",
    });
    return dialogAssignProject.afterClosed();
  }

  letItGo(isFinal: boolean = true) {
    if (isFinal) {
      const parseStringDate = (stringDate) => {
        const date = moment(stringDate, "YYYYMMDD");

        return date.format("DD-MM-YYYY");
      };
      const departDate = parseStringDate(this.confirmedSelection[0].travelDate);
      const body = {
        itinerary: `${this.confirmedSelection[0].departureStationName} - ${this.confirmedSelection[0].arrivalStationName}`,
        travelers: this.passengerForm.value.users.map(
          (u) => `${u.name} ${u.surname}, `
        ),
        dates: `${departDate} ${this.confirmedSelection[0].departTime}`,
        price: this.calcTotal(),
        seats:
          this.outwardSeats != null || this.returnSeats != null
            ? { outwardSeats: this.outwardSeats, returnSeats: this.returnSeats }
            : "",
        customCode: this.params.customCode,
        costCenter: this.params.costCenter,
        passengers: this.params.passengers,
        rates: this.params.rates,
      };
      if (
        this.confirmedSelection.length > 1 &&
        this.confirmedSelection[1] != null
      ) {
        const returnDate = parseStringDate(
          this.confirmedSelection[1].travelDate
        );
        body.dates =
          `${departDate} ${this.confirmedSelection[0].departTime}` +
          " - " +
          `${returnDate} ${this.confirmedSelection[1].departTime}`;
      }
      this.travelUtilsService.removeTravelLocalStorage();
      this.utilsService.sendEmailOperations(
        LetItGoType.train,
        body,
        this.uuidUser
      );
      this.translate
        .get(["common.congrat", "common.operations"])
        .subscribe((response) => {
          swal
            .fire({
              position: "center",
              icon: "success",
              title: response["common.congrat"],
              text: response["common.operations"],
              showConfirmButton: false,
              timer: this.utilsService.letItGoTimer,
              timerProgressBar: true,
            })
            .then(() => {
              const bookBody: any = {};
              bookBody["userUuid"] = this.uuidUser;
              bookBody["price"] = body.price * 1;

              if (body.passengers) {
                bookBody["passengers"] = body.passengers;
              }

              if(body.rates) {
                bookBody["rates"] = body.rates;
              }

              if(body.seats) {
                bookBody["seats"] = body.seats;
              }
              
              bookBody["from"] =
                this.confirmedSelection[0].departureStationName;
              bookBody["to"] = this.confirmedSelection[0].arrivalStationName;
              bookBody["departDate"] = new Date(
                parseInt(
                  this.confirmedSelection[0].travelDate.substring(0, 4),
                  10
                ),
                parseInt(
                  this.confirmedSelection[0].travelDate.substring(4, 6),
                  10
                ) - 1,
                parseInt(
                  this.confirmedSelection[0].travelDate.substring(6, 8),
                  10
                ),
                this.confirmedSelection[0].departTime.split(":")[0],
                this.confirmedSelection[0].departTime.split(":")[1]
              );
              if (
                this.confirmedSelection.length > 1 &&
                this.confirmedSelection[1] != null
              ) {
                bookBody["returnDate"] = new Date(
                  parseInt(
                    this.confirmedSelection[1].travelDate.substring(0, 4),
                    10
                  ),
                  parseInt(
                    this.confirmedSelection[1].travelDate.substring(4, 6),
                    10
                  ) - 1,
                  parseInt(
                    this.confirmedSelection[1].travelDate.substring(6, 8)
                  ),
                  this.confirmedSelection[1].departTime.split(":")[0],
                  this.confirmedSelection[1].departTime.split(":")[1]
                );
              }
              this.utilsService.loadingTrains = false;
              this.goToUserBookings();
            });
        });
    } else {
      this.isMocked = true;
      this.utilsService.loadingTrains = false;
    }
  }

  goToUserBookings() {
    const navigationExtras: NavigationExtras = {
      state: {
        module: "trains",
      },
    };
    if (this.isUser) {
      this.router.navigate([`/my-bookeds`], navigationExtras);
    } else {
      this.router.navigate([`/user/booked/${this.uuidUser}`], navigationExtras);
    }
  }

  calcTotal() {
    const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;
    let total = parseFloat(this.amount);
    if (total == null || isNaN(total)) {
      total =
        parseFloat(this.confirmedSelection[0].price) +
        this.calcSeatsPrice(
          this.outwardSeats.length,
          this.confirmedSelection[0],
          true
        );
      if (this.confirmedSelection[1] != null) {
        total +=
          parseFloat(this.confirmedSelection[1].price) +
          this.calcSeatsPrice(
            this.returnSeats.length,
            this.confirmedSelection[1],
            false
          );
      }
    }
    return round(total);
  }

  calcSeatsPrice(
    selectedSeats: number,
    selectedTrain: any,
    isFirstTrain: boolean
  ) {
    let seatPrice;
    if (this.isMocked && this.trainsComplements == null) {
      seatPrice =
        selectedSeats *
        this.farePriceMap.find(
          (farePrice) => farePrice.fare === selectedTrain.code
        ).pricePerSeat;
    } else if (isFirstTrain) {
      seatPrice =
        selectedSeats *
        this.trainsComplements.ComplementosIda[0].complements.find(
          (complement) => complement.code === "SEAT"
        ).price;
    } else {
      seatPrice =
        selectedSeats *
        this.trainsComplements.ComplementosVuelta[0].complements.find(
          (complement) => complement.code === "SEAT"
        ).price;
    }
    return seatPrice;
  }
}
