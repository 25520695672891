<div class="container-fluid">
  <button
    type="button"
    class="close mr-3 mt-2 font-large-1"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="row container-main">
    <div class="col-12 d-flex container-text">
      <h3 class="static-header">{{ "statics.cookies.tittle" | translate }}</h3>

      <p>
        {{ "statics.cookies.paragraph-0" | translate }}
      </p>

      <strong class="paraf-header">
        {{ "statics.cookies.header-1" | translate }}
      </strong>
      <p>
        {{ "statics.cookies.paragraph-1" | translate }}
      </p>

      <strong class="paraf-header">
        {{ "statics.cookies.header-2" | translate }}
      </strong>
      <p>
        {{ "statics.cookies.paragraph-2" | translate }}
      </p>
      <ul>
        <li>
          <strong class="paraf-header">
            {{ "statics.cookies.header-2-1" | translate }}
          </strong>
          <p>
            {{ "statics.cookies.paragraph-2-1" | translate }}
          </p>
        </li>
        <li>
          <strong class="paraf-header">
            {{ "statics.cookies.header-2-2" | translate }}
          </strong>
          <p>
            {{ "statics.cookies.paragraph-2-2-2" | translate }}
          </p>
        </li>
      </ul>

      <p>
        {{ "statics.cookies.paragraph-2-2-2-1" | translate }}
      </p>

      <ul>
        <li>
          <strong class="paraf-header">
            {{ "statics.cookies.header-2-3" | translate }}
          </strong>
          <p>
            {{ "statics.cookies.paragraph-2-3-1" | translate }}
          </p>
        </li>
        <li>
          <strong class="paraf-header">
            {{ "statics.cookies.header-2-4" | translate }}
          </strong>
          <p>
            {{ "statics.cookies.paragraph-2-4-1" | translate }}
          </p>
        </li>
      </ul>
      <p>
        {{ "statics.cookies.paragraph-3" | translate }}
      </p>

      <ul>
        <li>
          <strong class="paraf-header">
            {{ "statics.cookies.header-4" | translate }}
          </strong>
          <p>
            {{ "statics.cookies.paragraph-4" | translate }}
          </p>
        </li>
        <li>
          <strong class="paraf-header">
            {{ "statics.cookies.header-5" | translate }}
          </strong>
          <p>
            {{ "statics.cookies.paragraph-5" | translate }}
          </p>
        </li>
        <li>
          <strong class="paraf-header">
            {{ "statics.cookies.header-6" | translate }}
          </strong>
          <p>
            {{ "statics.cookies.paragraph-6" | translate }}
          </p>
        </li>
        <li>
          <strong class="paraf-header">
            {{ "statics.cookies.header-7" | translate }}
          </strong>
          <p>
            {{ "statics.cookies.paragraph-7" | translate }}
          </p>
        </li>
        <li>
          <strong class="paraf-header">
            {{ "statics.cookies.header-8" | translate }}
          </strong>
          <p>
            {{ "statics.cookies.paragraph-8" | translate }}
          </p>
        </li>
        <li>
          <strong class="paraf-header">
            {{ "statics.cookies.header-9" | translate }}
          </strong>
          <p>
            {{ "statics.cookies.paragraph-9" | translate }}
          </p>
        </li>
      </ul>

      <strong class="paraf-header">
        {{ "statics.cookies.header-10" | translate }}
      </strong>
      <p>
        {{ "statics.cookies.paragraph-10" | translate }}
      </p>
      <p>{{ "statics.cookies.paragraph-10" | translate }}</p>
      <ul>
        <li>
          <p>{{ "statics.cookies.paragraph-11" | translate }}</p>
        </li>
        <li>
          <p>{{ "statics.cookies.paragraph-12" | translate }}</p>
        </li>
        <li>
          <p>{{ "statics.cookies.paragraph-13" | translate }}</p>
        </li>
        <li>
          <p>{{ "statics.cookies.paragraph-14" | translate }}</p>
        </li>
        <li>
          <p>{{ "statics.cookies.paragraph-15" | translate }}</p>
        </li>
        <li>
          <p>{{ "statics.cookies.paragraph-16" | translate }}</p>
        </li>
        <li>
          <p>{{ "statics.cookies.paragraph-17" | translate }}</p>
        </li>
      </ul>

      <strong class="paraf-header">
        {{ "statics.cookies.header-18" | translate }}
      </strong>
      <p>
        {{ "statics.cookies.paragraph-18" | translate }}
      </p>
      <ul>
        <li>
          <p>{{ "statics.cookies.paragraph-18-1" | translate }}</p>
        </li>
      </ul>

      <strong class="paraf-header">
        {{ "statics.cookies.header-19" | translate }}
      </strong>
      <p>
        {{ "statics.cookies.paragraph-19" | translate }}
      </p>
      <ul>
        <li>
          <p>{{ "statics.cookies.paragraph-19-1" | translate }}</p>
        </li>
        <li>
          <p>{{ "statics.cookies.paragraph-19-2" | translate }}</p>
        </li>
        <li>
          <p>{{ "statics.cookies.paragraph-19-3" | translate }}</p>
        </li>
      </ul>
      <strong class="paraf-header">
        {{ "statics.cookies.header-20" | translate }}
      </strong>
      <p>
        {{ "statics.cookies.paragraph-20" | translate }}
      </p>
    </div>
  </div>
</div>
