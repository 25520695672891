import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { SearchRoutingModule } from "../search/search-routing.module";
import { SearchModule } from "../search/search.module";
import { StaticsConditionsComponent } from "./statics-conditions/statics-conditions.component";
import { StaticsCookiesComponent } from "./statics-cookies/statics-cookies.component";
import { StaticsLegalComponent } from "./statics-legal/statics-legal.component";
import { StaticsPrivacityComponent } from "./statics-privacity/statics-privacity.component";

const routes: Routes = [
  { path: "", redirectTo: "cookies" },
  { path: "legal", component: StaticsLegalComponent },
  { path: "conditions", component: StaticsConditionsComponent },
  { path: "cookies", component: StaticsCookiesComponent },
  { path: "privacity", component: StaticsPrivacityComponent },
];
@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    TranslateModule,
    SearchRoutingModule,
    SearchModule,
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
  declarations: [
    StaticsLegalComponent,
    StaticsPrivacityComponent,
    StaticsConditionsComponent,
    StaticsCookiesComponent,
  ],
})
export class StaticsModule {}
