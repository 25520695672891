<div class="master-container border-radius-30">
  <div class="info-container">
    <h4>
      {{ "train.confirm-stepper-container.seat-selection.title" | translate }}
      {{
        (firstTrain
          ? "train.confirm-stepper-container.seat-selection.outward"
          : "train.confirm-stepper-container.seat-selection.return"
        ) | translate
      }}
    </h4>
    <div>
      <p>
        <b>P: </b>
        <span>{{
          "train.confirm-stepper-container.seat-selection.aisle" | translate
        }}</span>
      </p>
      <p>
        <b>T: </b>
        <span>{{
          "train.confirm-stepper-container.seat-selection.table" | translate
        }}</span>
      </p>
      <p>
        <b>V: </b>
        <span>{{
          "train.confirm-stepper-container.seat-selection.window" | translate
        }}</span>
      </p>
      <p>
        <b>X: </b>
        <span>{{
          "train.confirm-stepper-container.seat-selection.reduced-mobility"
            | translate
        }}</span>
      </p>
    </div>
    <div>
      <div class="seat-info-status-container">
        <img
          src="assets/img/svg/train/seat-available.svg"
          alt="available seat"
        />
        {{
          "train.confirm-stepper-container.seat-selection.available" | translate
        }}
      </div>
      <div class="seat-info-status-container">
        <img src="assets/img/svg/train/seat-selected.svg" alt="selected seat" />
        {{
          "train.confirm-stepper-container.seat-selection.selected" | translate
        }}
      </div>
      <div class="seat-info-status-container">
        <img
          src="assets/img/svg/train/seat-unavailable.svg"
          alt="unavailable seat"
        />
        {{
          "train.confirm-stepper-container.seat-selection.unavailable"
            | translate
        }}
      </div>
    </div>
  </div>
  <div id="seatsContainer">
    <div class="slidersButtons">
      <img
        (click)="wagonChange(true)"
        class="slderIcon"
        src="assets/img/svg/train/arrow-right.svg"
      />
      <p *ngIf="firstTrain" id="wagonTitle">
        {{ "train.wagon" | translate }}
        {{ outwardWagonList[wagon].carNumber }}
      </p>
      <p *ngIf="!firstTrain">
        {{ "train.wagon" | translate }} {{ returnWagonList[wagon].carNumber }}
      </p>
      <img
        (click)="wagonChange(false)"
        class="slderIcon"
        src="assets/img/svg/train/arrow-right.svg"
      />
    </div>

    <div id="seats-inner-container">
      <div class="rotated-text">
        {{
          "train.confirm-stepper-container.seat-selection.back"
            | translate
            | uppercase
        }}
      </div>
      <div
        *ngIf="
          (firstTrain &&
            outwardWagonList[wagon] &&
            outwardWagonList[wagon].classCode === 'SRV') ||
          (!firstTrain &&
            returnWagonList.length > 0 &&
            returnWagonList[wagon] &&
            returnWagonList[wagon].classCode === 'SRV')
        "
        id="cafeteriaWagon"
      >
        <img width="24" src="assets/img/svg/cafeteria.svg" alt="cafe icon" />
        <span>
          {{ "train.cafeteria-wagon" | translate }}
        </span>
      </div>
      <div
        class="trainSeatsContainer"
        *ngIf="firstTrain && outwardWagonList[wagon].classCode !== 'SRV'"
      >
        <div
          *ngFor="
            let rowSeat of outwardWagonList[wagon].rowSeats;
            let indexRow = index
          "
          class="rowSeat"
        >
          <div
            *ngFor="let seat of rowSeat.seats; let seatIndex = index"
            class="seat"
          >
            <div
              *ngIf="
                seat.seatNumber !== '@' &&
                seat.seatNumber !== ' ' &&
                seat.type?.toUpperCase() !== 'TABLE'
              "
              class="image-container"
              [ngClass]="{ taken: seat.isFreeSeat === false }"
              (click)="selectSeat(seat, indexRow, seatIndex)"
            >
              <img
                [src]="getIcon(seat)"
                alt="available seat"
                width="48"
                class="seat"
                [ngClass]="{
                  selected: seat.selected,
                  available: seat.selected === false && seat.isFreeSeat,
                  directionLeft: seat.seatDirection === false
                }"
              />
              <span
                class="semi-bold"
                [ngClass]="{
                  selected: seat.selected
                }"
              >
                {{ seat.seatNumber }}
                {{ seat.type ? seat.type : "" }}</span
              >
            </div>
            <img
              *ngIf="seat?.toUpperCase() === 'TABLE'"
              width="48"
              class="notFree"
              src="assets/img/svg/table.svg"
            />
          </div>
        </div>
      </div>
      <div
        class="trainSeatsContainer"
        *ngIf="!firstTrain && returnWagonList[wagon].classCode !== 'SRV'"
      >
        <div
          *ngFor="
            let rowSeat of returnWagonList[wagon].rowSeats;
            let indexRow = index
          "
          class="rowSeat"
        >
          <div
            *ngFor="let seat of rowSeat.seats; let seatIndex = index"
            class="seat"
          >
            <div
              *ngIf="
                seat.seatNumber !== '@' &&
                seat.seatNumber !== ' ' &&
                seat.type?.toUpperCase() !== 'TABLE'
              "
              class="image-container"
              [ngClass]="{ taken: seat.isFreeSeat === false }"
              (click)="selectSeat(seat, indexRow, seatIndex)"
            >
              <img
                [src]="getIcon(seat)"
                alt="available seat"
                width="48"
                class="seat"
                [ngClass]="{
                  selected: seat.selected,
                  available: seat.selected === false && seat.isFreeSeat,
                  directionLeft: seat.seatDirection === false
                }"
              />
              <span
                class="semi-bold"
                [ngClass]="{
                  selected: seat.selected
                }"
              >
                {{ seat.seatNumber }}
                {{ seat.type ? seat.type : "" }}</span
              >
            </div>
            <img
              *ngIf="seat.type?.toUpperCase() === 'TABLE'"
              width="48"
              class="notFree"
              src="assets/img/svg/table.svg"
            />
          </div>
        </div>
      </div>
      <div class="rotated-text">
        {{
          "train.confirm-stepper-container.seat-selection.front"
            | translate
            | uppercase
        }}
      </div>
    </div>
  </div>
</div>
