import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { AuthService } from "@core/services";

@Injectable({
  providedIn: "root",
})
export class PlanResolveService implements Resolve<any> {
  constructor(private authService: AuthService) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (
      this.authService.userCompanyPlan == null &&
      this.authService.isAdmin() === false
    ) {
      return this.authService
        .getCompanyPlan()
        .forEach((val) => (this.authService.userCompanyPlan = val));
    }
  }
}
