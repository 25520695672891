import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";

@Injectable()
export class HelpService {
  constructor(private http: HttpClient) {}

  postHelp(text: string) {
    const body = {
      text: text,
    };

    return this.http.post<any>(
      `${environment.baseUrlApi}/help/help-mail`,
      body
    );
  }
}
