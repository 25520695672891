import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-statics-conditions",
  templateUrl: "./statics-conditions.component.html",
  styleUrls: ["./statics-conditions.component.scss"],
})
export class StaticsConditionsComponent implements OnInit {
  @Input() isSignUp: boolean;

  constructor(private router: Router, public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  close() {
    if (this.isSignUp) {
      this.activeModal.dismiss("Cross click");
    } else {
      this.router.navigate(["/"]);
    }
  }
}
