import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { CompanyPlanService } from "@core/services/company-plan.service";
import { StoreModule } from "@ngrx/store";
import { BookedService, CompanyService, GroupService } from "@services";
import { MatchHeightModule } from "@shared/directives/match-height.directive";
import { SharedModule } from "@shared/shared.module";
import { ExpensesComponent } from "app/modules/components/expenses/expenses.component";
import { FlightTrainModule } from "app/modules/components/flight-train/flight-train.module";
import { GroupModule } from "app/modules/components/group/group.module";
import { UserMyBookedsModule } from "app/modules/components/my-bookeds/user-my-bookeds.module";
import { CompanyReducer } from "app/store/reducers/company.reducer";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { UserModule } from "../user/user.module";
import { HotelModule } from "./../../components/hotel/hotel.module";
import { NavbarComponent } from "./../../shared/navbar/navbar.component";
import { DetailsComponent } from "./authorizations/components/details/details.component";
import { BookedHomepageComponent } from "./booked-homepage/booked-homepage.component";
import { TabsComponent } from "./booked-homepage/tabs/tabs.component";
import { ViewPdfModalCompanyComponent } from "./booked-homepage/view-pdf-modal-company/view-pdf-modal-company.component";
import { CompanyDetailComponent } from "./company-detail/company-detail.component";
import { CompanyExternalSignUpComponent } from "./company-external-signup/company-external-signup.component";
import { CompanyFormComponent } from "./company-form/company-form.component";
import { CompanyCardComponent } from "./company-homepage/company-card/company-card.component";
import { CompanyHomepageComponent } from "./company-homepage/company-homepage.component";
import { CompanyRoutingModule } from "./company-routing.module";
import { CustomRequestDetailsComponent } from "./custom-requests/custom-request-details/custom-request-details.component";
import { PlanFormComponent } from "./plans/plan-form/plan-form.component";
import { PlanComponent } from "./plans/plan.component";
import { SavingsSatisfactionFormComponent } from "./savings-satisfaction-form/savings-satisfaction-form.component";
@NgModule({
    imports: [
        CompanyRoutingModule,
        CommonModule,
        MatchHeightModule,
        StoreModule.forFeature("company", CompanyReducer),
        SharedModule,
        GroupModule,
        UserModule,
        HotelModule,
        ReactiveFormsModule,
        FlightTrainModule,
        PdfJsViewerModule,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        FormsModule,
        UserMyBookedsModule,
        MatPaginatorModule,
        MatBadgeModule,
    ],
    exports: [CompanyExternalSignUpComponent],
    declarations: [
        CompanyHomepageComponent,
        BookedHomepageComponent,
        CompanyDetailComponent,
        CompanyCardComponent,
        CompanyFormComponent,
        ViewPdfModalCompanyComponent,
        ExpensesComponent,
        TabsComponent,
        SavingsSatisfactionFormComponent,
        PlanFormComponent,
        PlanComponent,
        CompanyExternalSignUpComponent,
        DetailsComponent,
        CustomRequestDetailsComponent,
    ],
    providers: [
        CompanyService,
        GroupService,
        BookedService,
        CompanyPlanService,
        NavbarComponent,
    ]
})
export class CompanyModule {}
