import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PlanResolveService } from "@core/guards/PlanResolveService";
import { DialogUserFilesComponent } from "@shared/component/dialog-user-files/dialog-user-files.component";
import { UserMyBookedsComponent } from "app/modules/components/my-bookeds/user-my-bookeds/user-my-bookeds.component";
import { UserFormComponent } from "./user-form/user-form.component";

const routes: Routes = [
  {
    path: "booked/:uuid",
    component: UserMyBookedsComponent,
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "my-documents",
    component: DialogUserFilesComponent,
    resolve: { plans: PlanResolveService },
  },
  {
    path: "user-profile/:id",
    component: UserFormComponent,
  },
  {
    path: "user-profile",
    component: UserFormComponent,
  },
  {
    path: "create-user",
    component: UserFormComponent,
  },
  {
    path: "edit-user/:id",
    component: UserFormComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
