import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PlanResolveService } from "@core/guards/PlanResolveService";
import { UserMyBookedsComponent } from "./user-my-bookeds/user-my-bookeds.component";

const routes: Routes = [
  {
    path: "",
    component: UserMyBookedsComponent,
    resolve: {
      plans: PlanResolveService,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserMyBookedsRoutingModule {}
