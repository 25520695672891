import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: "bookedType" })
export class BookedTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: string) {
    this.translate
      .get(["common.room", "pipes.apartment", "pipes.some"])
      .subscribe((resp) => {
        switch (value) {
          case "room":
            return resp["room"];
          case "apartment":
            return resp["apartment"];
          case "all":
            return resp["some"];
          default:
            return value;
        }
      });
  }
}
