import { Routes } from "@angular/router";
import { CompanyExternalSignUpComponent } from "./modules/businessEntities/company/company-external-signup/company-external-signup.component";
import { ExternalUserFormComponent } from "./modules/businessEntities/user/external-user-form/external-user-form.component";

export const ContentLayoutRoutes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./modules/components/login/login.module").then(
        (m) => m.LoginModule
      ),
  },

  {
    path: "admin-login",
    loadChildren: () =>
      import("./modules/components/employee-login/employee-login.module").then(
        (m) => m.EmployeeLoginModule
      ),
  },

  { path: "signup", component: ExternalUserFormComponent },

  { path: "signup-companies", component: CompanyExternalSignUpComponent },

  {
    path: "company-login",
    loadChildren: () =>
      import("./modules/components/company-login/company-login.module").then(
        (m) => m.CompanyLoginModule
      ),
  },

  {
    path: "error",
    loadChildren: () =>
      import("./modules/components/error/error.module").then(
        (m) => m.ErrorModule
      ),
  },

  {
    path: "statics",
    loadChildren: () =>
      import("./modules/components/statics/statics.module").then(
        (m) => m.StaticsModule
      ),
  },
];
