<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "company.optm-form.title" | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="form">
        <div *ngIf="!isSuperAdmin">
          <h5>{{ "company.optm-form.savings.title" | translate }}</h5>
          <div class="form-row mb-3">
            <div class="col-lg-6 col-md-6 col-xs-12">
              <label class="labels" for="hotel">{{
                "company.optm-form.savings.hotel" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="savingHotel"
                type="number"
                id="hotel"
                class="form-control rounded-1"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12">
              <label class="labels" for="flight">{{
                "company.optm-form.savings.flight" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="savingFlight"
                type="number"
                id="flight"
                class="form-control rounded-1"
              />
            </div>
          </div>
          <div class="form-row mb-3">
            <div class="col-lg-6 col-md-6 col-xs-12">
              <label class="labels" for="train">{{
                "company.optm-form.savings.train" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="savingTrain"
                type="number"
                id="train"
                class="form-control rounded-1"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12">
              <label class="labels" for="management">{{
                "company.optm-form.savings.management" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="savingManagement"
                type="number"
                id="management"
                class="form-control rounded-1"
              />
            </div>
          </div>
          <div class="form-row mb-3">
            <div class="col-lg-6 col-md-6 col-xs-12">
              <label class="labels" for="CO2">{{
                "company.optm-form.savings.CO2" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="savingCO2"
                type="number"
                id="CO2"
                class="form-control rounded-1"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12">
              <label class="labels" for="support">{{
                "company.optm-form.savings.support" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="savingSupport"
                type="number"
                id="support"
                class="form-control rounded-1"
              />
            </div>
          </div>
          <div class="form-row mb-3">
            <div class="col-lg-6 col-md-6 col-xs-12">
              <label class="labels" for="time">{{
                "company.optm-form.savings.time" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="savingTime"
                type="number"
                id="time"
                class="form-control rounded-1"
              />
            </div>
          </div>
        </div>
        <div *ngIf="isSuperAdmin">
          <hr />
          <h5>{{ "company.optm-form.satisfaction.title" | translate }}</h5>
          <div class="form-row mb-3">
            <div class="col-lg-4 col-md-4 col-xs-12">
              <label class="labels" for="covid1">{{
                "company.optm-form.satisfaction.covid1" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="commonValue1"
                type="number"
                id="covid1"
                class="form-control rounded-1"
              />
            </div>
            <div class="col-lg-4 col-md-4 col-xs-12">
              <label class="labels" for="covid2">{{
                "company.optm-form.satisfaction.covid2" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="mainValue1"
                type="number"
                id="covid2"
                class="form-control rounded-1"
              />
            </div>
            <div class="col-lg-4 col-md-4 col-xs-12">
              <label class="labels" for="co23">{{
                "company.optm-form.satisfaction.covid3" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="vyooValue1"
                type="number"
                id="covid3"
                class="form-control rounded-1"
              />
            </div>
          </div>
          <div class="form-row mb-3">
            <div class="col-lg-4 col-md-4 col-xs-12">
              <label class="labels" for="co21">{{
                "company.optm-form.satisfaction.co21" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="commonValue2"
                type="number"
                id="co21"
                class="form-control rounded-1"
              />
            </div>
            <div class="col-lg-4 col-md-4 col-xs-12">
              <label class="labels" for="co22">{{
                "company.optm-form.satisfaction.co22" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="mainValue2"
                type="number"
                id="co22"
                class="form-control rounded-1"
              />
            </div>
            <div class="col-lg-4 col-md-4 col-xs-12">
              <label class="labels" for="co23">{{
                "company.optm-form.satisfaction.co23" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="vyooValue2"
                type="number"
                id="co23"
                class="form-control rounded-1"
              />
            </div>
          </div>
          <div class="form-row mb-3">
            <div class="col-lg-4 col-md-4 col-xs-12">
              <label class="labels" for="b2c1">{{
                "company.optm-form.satisfaction.b2c1" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="commonValue3"
                type="number"
                id="b2c1"
                class="form-control rounded-1"
              />
            </div>
            <div class="col-lg-4 col-md-4 col-xs-12">
              <label class="labels" for="b2c2">{{
                "company.optm-form.satisfaction.b2c2" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="mainValue3"
                type="number"
                id="b2c2"
                class="form-control rounded-1"
              />
            </div>
            <div class="col-lg-4 col-md-4 col-xs-12">
              <label class="labels" for="b2c3">{{
                "company.optm-form.satisfaction.b2c3" | translate
              }}</label>
              <input
                [readOnly]="loading"
                formControlName="vyooValue3"
                type="number"
                id="b2c3"
                class="form-control rounded-1"
              />
            </div>
          </div>
        </div>
      </form>
      <div class="text-center">
        <button
          class="btn btn-vyoo-primary text-white rounded-1 mb-0"
          (click)="onSubmit()"
          ngbAutofocus
          mat-button
          [disabled]="!form.valid"
        >
          {{ "common.send" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
