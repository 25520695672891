import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SearchService } from "@services";
import { MatchHeightModule } from "@shared/directives/match-height.directive";
import { NoimagePipe } from "@shared/pipes/noimage.pipe";
import { SharedModule } from "@shared/shared.module";
import { UserModule } from "app/modules/businessEntities/user/user.module";
import { SearchReducer } from "app/store/reducers/search.reducer";
import { RoomsComponent } from "../hotel/rooms/rooms.component";
import { SearchCardComponent } from "./search-card/search-card.component";
import { SearchFormComponent } from "./search-form/search-form.component";
import { GoogleAutocompleteComponent } from "./search-homepage/google-autocomplete/google-autocomplete.component";
import { GoogleMapsComponent } from "./search-homepage/google-maps/google-maps.component";
import { SearchHomepageComponent } from "./search-homepage/search-homepage.component";
import { SearchRoutingModule } from "./search-routing.module";
import { GalleryModalComponent } from "./search-see-booked/gallery-modal/gallery-modal.component";
import { SearchSeeBookedComponent } from "./search-see-booked/search-see-booked.component";
import { effectsAArr } from "./search.effects";
import { SummaryBookedComponent } from "./search-see-booked/summary-booked/summary-booked.component";
import { NgMapsCoreModule } from "@ng-maps/core"
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import { environment } from "@env/environment";
import { AccommodationGuestsComponent } from "./accommodation-guests/accommodation-guests.component";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    MatchHeightModule,
    FormsModule,
    NgxSliderModule,
    MatPaginatorModule,
    SharedModule,
    StoreModule.forFeature('search', SearchReducer),
    EffectsModule.forFeature(effectsAArr),
    ReactiveFormsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    SearchRoutingModule,
    UserModule,
    NgMapsCoreModule,
    NgMapsGoogleModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [],
  declarations: [
    SearchHomepageComponent,
    SearchCardComponent,
    NoimagePipe,
    SearchSeeBookedComponent,
    GalleryModalComponent,
    GoogleAutocompleteComponent,
    GoogleMapsComponent,
    RoomsComponent,
    SearchFormComponent,
    SummaryBookedComponent,
    AccommodationGuestsComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers: [SearchService,
    { provide: GOOGLE_MAPS_API_CONFIG, useValue: { apiKey: environment.googleApiKEY, libraries: ['places'] } },],
})
export class SearchModule {}
