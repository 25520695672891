import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Plan } from "@core/models/plan";
import { environment } from "@env/environment";
import { map } from "rxjs/operators";
import { TokenService } from "./token.service";

@Injectable({
  providedIn: "root",
})
export class CompanyPlanService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getPlans() {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/plans`, {
        headers: reqHeaders,
      })
      .pipe(map((res: Plan[]) => res));
  }

  getPlan(planUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/plans/${planUuid}`, {
        headers: reqHeaders,
      })
      .pipe(map((res) => res));
  }

  postPlan(body: Plan) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(`${environment.baseUrlApi}/plans`, body, {
      headers: reqHeaders,
    });
  }

  putPlan(plan: Plan, uuid) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.put<any>(`${environment.baseUrlApi}/plans/${uuid}`, plan, {
      headers: reqHeaders,
    });
  }

  deletePlan(planUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.delete<any>(
      `${environment.baseUrlApi}/plans/${planUuid}`,
      { headers: reqHeaders }
    );
  }

  getPlanCompanies(planUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/plans/${planUuid}/companies`, {
        headers: reqHeaders,
      })
      .pipe(map((res) => res));
  }
}
