<div class="container-fluid">
  <button
    type="button"
    class="close mr-3 mt-2 font-large-1"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="row container-main">
    <div class="col-12 d-flex container-text">
      <h3 class="static-header">
        {{ "statics.privacity.tittle" | translate }}
      </h3>

      <p class="pt-2">
        {{ "statics.privacity.paragraph-0" | translate }}
      </p>
      <strong class="paraf-header">
        {{ "statics.privacity.header-1" | translate }}
      </strong>
      <p>
        {{ "statics.privacity.paragraph-1" | translate }}
      </p>
      <strong class="paraf-header">
        {{ "statics.privacity.header-2" | translate }}
      </strong>
      <p>
        {{ "statics.privacity.paragraph-2-1" | translate }}
      </p>
      <ul>
        <li>{{ "statics.privacity.list-2-1" | translate }}</li>
        <li>{{ "statics.privacity.list-2-2" | translate }}</li>
      </ul>
      <p>
        {{ "statics.privacity.paragraph-2-2" | translate }}
      </p>
      <strong class="paraf-header">
        {{ "statics.privacity.header-3" | translate }}
      </strong>
      <p>
        {{ "statics.privacity.paragraph-3" | translate }}
      </p>
      <ul>
        <li>{{ "statics.privacity.list-3-1" | translate }}</li>
        <li>{{ "statics.privacity.list-3-2" | translate }}</li>
        <li>{{ "statics.privacity.list-3-3" | translate }}</li>
        <li>{{ "statics.privacity.list-3-4" | translate }}</li>
      </ul>

      <strong class="paraf-header">
        {{ "statics.privacity.header-4" | translate }}
      </strong>
      <p>
        {{ "statics.privacity.paragraph-4" | translate }}
      </p>

      <strong class="paraf-header">
        {{ "statics.privacity.header-5" | translate }}
      </strong>
      <p>
        {{ "statics.privacity.paragraph-5" | translate }}
      </p>

      <strong class="paraf-header">
        {{ "statics.privacity.header-6" | translate }}
      </strong>
      <p>
        {{ "statics.privacity.paragraph-6" | translate }}
      </p>
      <ol>
        <li>{{ "statics.privacity.list-6-1" | translate }}</li>
        <li>{{ "statics.privacity.list-6-2" | translate }}</li>
        <li>{{ "statics.privacity.list-6-3" | translate }}</li>
        <li>{{ "statics.privacity.list-6-4" | translate }}</li>
        <li>{{ "statics.privacity.list-6-5" | translate }}</li>
        <li>{{ "statics.privacity.list-6-6" | translate }}</li>
      </ol>

      <strong class="paraf-header">
        {{ "statics.privacity.header-7" | translate }}
      </strong>
      <p>
        {{ "statics.privacity.paragraph-7-1" | translate }}
      </p>
      <p>
        {{ "statics.privacity.paragraph-7-2" | translate }}
      </p>
      <strong class="paraf-header">
        {{ "statics.privacity.header-8" | translate }}
      </strong>
      <p>
        {{ "statics.privacity.paragraph-8-1" | translate }}
      </p>
    </div>
  </div>
</div>
