import {
  ApplyFiltersConfig,
  PaginationConfig,
  SearchConfig,
} from "@interfaces";
import { Hotel } from "@models";
import { Action } from "@ngrx/store";

export const SEARCH_PAGE = "[Search] Page";
export const HOTEL_COMPANY_LIST = "[Search] Hotel Company List";
export const HOTEL_LIST = "[Search] Hotel List";
export const HOTEL_LIST_SUCCESS = "[Search] Hotel List success";
export const HOTEL_LIST_FAILURE = "[Search] Hotel List error";
export const GET_FACILITIES = "[Search] Get Facilities";
export const GET_FACILITIES_SUCCESS = "[Search] Get Facilities success";
export const GET_FACILITIES_FAILURE = "[Search] Get Facilities error";

export const APPLY_FILTERS = "[Search] Apply filters";
export const APPLY_FILTERS_SUCCESS = "[Search] Apply filters success";
export const APPLY_FILTERS_FAILURE = "[Search] Apply filters error";
export const RESET_FILTERS = "[Search] Reset all filters";
export class SearchPageAction implements Action {
  readonly type = SEARCH_PAGE;

  constructor(public paginationConfig: PaginationConfig) {}
}

export class HotelListAction implements Action {
  readonly type = HOTEL_LIST;

  constructor(public searchConfig: SearchConfig) {}
}

export class HotelCompanyListAction implements Action {
  readonly type = HOTEL_COMPANY_LIST;

  constructor(public searchConfig: SearchConfig) {}
}

export class HotelListSuccessAction implements Action {
  readonly type = HOTEL_LIST_SUCCESS;

  constructor(public hotels: Hotel[]) {}
}

export class HotelListFailureAction implements Action {
  readonly type = HOTEL_LIST_FAILURE;

  constructor(public payload: any) {}
}

export class FacilityListAction implements Action {
  readonly type = GET_FACILITIES;

  constructor() {}
}

export class FacilitySuccessAction implements Action {
  readonly type = GET_FACILITIES_SUCCESS;

  constructor(public facilities: any[]) {}
}

export class FacilityFailureAction implements Action {
  readonly type = GET_FACILITIES_FAILURE;

  constructor(public payload: any) {}
}

export class ApplyFiltersAction implements Action {
  readonly type = APPLY_FILTERS;

  constructor(public applyFilterConfig: ApplyFiltersConfig) {}
}

export class ApplyFiltersSuccessAction implements Action {
  readonly type = APPLY_FILTERS_SUCCESS;

  constructor(public hotels: Hotel[]) {}
}

export class ApplyFiltersFailureAction implements Action {
  readonly type = APPLY_FILTERS_FAILURE;

  constructor(public payload: any) {}
}

export class ResetFiltersAction implements Action {
  readonly type = RESET_FILTERS;

  constructor() {}
}

export type SearchActions =
  | SearchPageAction
  | HotelListAction
  | HotelCompanyListAction
  | HotelListSuccessAction
  | HotelListFailureAction
  | FacilityListAction
  | FacilitySuccessAction
  | FacilityFailureAction
  | ApplyFiltersAction
  | ApplyFiltersSuccessAction
  | ApplyFiltersFailureAction
  | ResetFiltersAction;
