import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "/my-bookeds",
    title: "sidebar.my-bookeds",
    icon: "tent",
    selected: false,
    iconSelected: "tent",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/search",
    title: "sidebar.hotels",
    icon: "hotel",
    selected: false,
    iconSelected: "hotel",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/flight",
    title: "sidebar.flight",
    icon: "flight",
    selected: false,
    iconSelected: "flight",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/train",
    title: "sidebar.train",
    icon: "train",
    selected: false,
    iconSelected: "train",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  // CAMBIAR ATRIBUTO PATH PARA PARA QUE LLEVA A LA PAGINA PRINCIPAL DE SERVICIOS PERSONALIZADOS
  {
    path: "/custom-services",
    title: "sidebar.customs",
    icon: "custom",
    selected: false,
    iconSelected: "custom",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
];

export const OPTIONS_ROUTES: RouteInfo[] = [
  {
    path: "user/my-documents",
    title: "sidebar.my-documents",
    icon: "briefcase",
    iconSelected: "briefcase",
    selected: false,
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "user/user-profile",
    title: "sidebar.my-profile",
    icon: "profile",
    iconSelected: "profile",
    selected: false,
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "https://localsfy.com/",
    title: "sidebar.localsfy",
    icon: "info",
    iconSelected: "info",
    selected: false,
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: true,
    submenu: [],
  },
  /* {
    path: '/help', title: 'sidebar.help',
    icon: 'help', selected: false, iconSelected: 'help-green', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  } */
];
