import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Flight, Train } from "@models";
import { TranslateService } from "@ngx-translate/core";
import { CompanyService, ExcelService, NgxToastrService } from "@services";
import { Subscription } from "rxjs";

@Component({
  selector: "app-booked",
  templateUrl: "./booked.component.html",
  styleUrls: ["./booked.component.scss"],
})
export class BookedComponent implements OnInit, OnDestroy {
  @Input() uuidBusinessEntity;
  @Input() businessEntityBookings;
  @Input() statusOptions;
  @Input() form;
  @Input() formFlight;
  @Input() formTrain;
  @Input() formExpenses;
  @Input() formTravel;
  @Input() paginationConf;
  @Input() paginationConfFlight;
  @Input() paginationConfTrain;
  @Input() travelPagination;
  @Input() bookeds;
  @Input() flights;
  @Input() trains;
  @Input() expenses;
  @Input() travels;
  @Input() isAdmin;
  @Input() businessEntityType?;
  @Input() entity?;
  navActive = 1;
  subscriptions: Subscription[] = [];
  constructor(
    private translate: TranslateService,
    private companyService: CompanyService,
    private ngxToastrService: NgxToastrService,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.loadFlights();
    this.loadTrains();
  }

  loadFlights() {
    switch (this.businessEntityType) {
      case "company":
        this.companyService.getFlights(this.uuidBusinessEntity).subscribe(
          (flight: Flight[]) => {
            this.flights = flight.reverse();
            this.paginationConfFlight.collectionSize = flight.length;
          },
          (err) => {
            this.translate
              .get("company.booked.error-get-flight")
              .subscribe((result) => {
                this.ngxToastrService.typeInfo(result, err.error.error);
              });
          }
        );
        break;
      case "agency":
        break;
      case "hub":
        break;
      default:
        break;
    }
  }
  loadTrains() {
    switch (this.businessEntityType) {
      case "company":
        this.companyService.getTrains(this.uuidBusinessEntity).subscribe(
          (train: Train[]) => {
            this.trains = train.reverse();
            this.paginationConfTrain.collectionSize = train.length;
          },
          (err) => {
            this.translate
              .get("company.booked.error-get-train")
              .subscribe((result) => {
                this.ngxToastrService.typeInfo(result, err.error.error);
              });
          }
        );
        break;
      case "agency":
        break;
      case "hub":
        break;
      default:
        break;
    }
  }

  exportExcel() {
    const totalBookings = {
      bookings: this.businessEntityBookings,
      flights: this.flights,
      trains: this.trains,
      expenses: this.expenses,
    };
    this.subscriptions.push(
      this.companyService
        .getCompanyBookeds(this.uuidBusinessEntity)
        .subscribe((res) => {
          Object.keys(res).forEach((key) => {
            res[key].map((e, i) => {
              if (
                !res[key][i]["hotel"] &&
                !res[key][i]["destinationName"] &&
                key !== "cars"
              ) {
                const object = totalBookings[key].find(
                  (book) => book.apiReference === res[key][i].bookedApiReference
                );
                if (object) {
                  res[key][i]["destinationName"] = object.hotel
                    ? object.hotel.destinationName
                    : object.destinationName;
                } else {
                  res[key][i]["destinationName"] = "-";
                }
              }
            });
          });
          this.excelService.createBookingsExcel(this.entity.name, res);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
