import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Hub } from "@models";
import { map } from "rxjs/operators";
import { TokenService } from "./token.service";

@Injectable()
export class HubService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getHub(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/hubs/${uuid}`, {
        headers: reqHeaders,
      })
      .pipe(map((res: Hub) => res));
  }

  getHubs() {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/hubs`, { headers: reqHeaders })
      .pipe(
        map((res) => {
          const hubs: Hub[] = res;
          return hubs;
        })
      );
  }

  postHub(body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/hubs`, body, {
        headers: reqHeaders,
      })
      .pipe(map((res: Hub) => res));
  }

  putHub(hub, uuid) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/hubs/${uuid}`, hub, {
        headers: reqHeaders,
      })
      .pipe(map((res: Hub) => res));
  }

  deleteHub(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.delete<any>(`${environment.baseUrlApi}/hubs/${uuid}`, {
      headers: reqHeaders,
    });
  }

  getFlights(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/hubs/${uuid}/flights`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res.docs));
  }
  getTrains(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/hubs/${uuid}/trains`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res.docs));
  }

  postHubPayComet(uuid: string, body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/hubs/${uuid}/addCard`, body, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  removeCard(hub) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .put<any>(`${environment.baseUrlApi}/hubs/${hub.uuid}/removeCard`, {
        headers: reqHeaders,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getTransactions(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/hubs/${uuid}/transactions`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  getBills(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/bills/${uuid}`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  getBill(uuid: string, hubUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get(`${environment.baseUrlApi}/bills/${hubUuid}/${uuid}`, {
      headers: reqHeaders,
      responseType: "blob",
    });
  }

  postBill(form: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(`${environment.baseUrlApi}/bills/`, form, {
      headers: reqHeaders,
    });
  }

  getSavings(hubUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get(`${environment.baseUrlApi}/hubs/${hubUuid}/savings`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  postSaving(hubUuid: string, body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/hubs/${hubUuid}/savings`, body, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  putSaving(hubUuid: string, uuid: string, body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .put<any>(
        `${environment.baseUrlApi}/hubs/${hubUuid}/savings/${uuid}`,
        body,
        { headers: reqHeaders }
      )
      .pipe(map((res: any) => res));
  }
  getSatisfactions() {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get(`${environment.baseUrlApi}/hubs/satisfactions`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  postSatisfaction(body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/hubs/satisfactions`, body, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  putSatisfaction(uuid: string, body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .put<any>(`${environment.baseUrlApi}/hubs/satisfactions/${uuid}`, body, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  getUsersByAccessType(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/hubs/${uuid}/accessType`, {
        headers: reqHeaders,
      })
  }

}
