import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "@shared/shared.module";
import { AuthorizationsComponent } from "app/modules/businessEntities/company/authorizations/authorizations.component";
import { CustomRequestsComponent } from "app/modules/businessEntities/company/custom-requests/custom-requests.component";
import { UserModule } from "app/modules/businessEntities/user/user.module";
import { MyBookedsReducer } from "app/store/reducers/my-bookeds.reducer";
import { UserMyBookedsRoutingModule } from "./user-my-bookeds-routing.module";
import { TravelAddUserComponent } from "./user-my-bookeds/travel-container/travel-add-user/travel-add-user.component";
import { UserMyBookedsComponent } from "./user-my-bookeds/user-my-bookeds.component";
import { TravelBookingsModalComponent } from "./user-my-bookeds/travel-container/travel-bookings-modal/travel-bookings-modal.component";

@NgModule({
    declarations: [
        UserMyBookedsComponent,
        TravelAddUserComponent,
        AuthorizationsComponent,
        CustomRequestsComponent,
        TravelBookingsModalComponent
    ],
    imports: [
        UserMyBookedsRoutingModule,
        StoreModule.forFeature("myBookeds", MyBookedsReducer),
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        MatButtonModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        MatSelectModule,
        UserModule,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatPaginatorModule,
    ],
    exports: [UserMyBookedsComponent]
})
export class UserMyBookedsModule {}
