<div class="row">
  <div class="col-12">
    <div class="card border-card mb-4">
      <div class="card-body border-groups">
        <!-- Tabs Reservas -->
        <div class="nav-container">
          <ul ngbNav #nav="ngbNav" [(activeId)]="navActive" class="nav-tabs">
            <li [ngbNavItem]="5">
              <a ngbNavLink>{{ "common.projects" | translate }} </a>
              <ng-template ngbNavContent>
                <app-travel-container
                  [travels]="travels"
                  [form]="formTravel"
                  [companyUuid]="uuidBusinessEntity"
                  (loadTravels)="loadTravels(uuidBusinessEntity)"
                  [pagination]="travelPagination"
                ></app-travel-container>
              </ng-template>
            </li>
            <li [ngbNavItem]="1">
              <a ngbNavLink>{{ "user.details.booked-hotel" | translate }} </a>
              <ng-template ngbNavContent>
                <app-booked-container
                  [bookeds]="businessEntityBookings"
                  [statusOptions]="statusOptions"
                  [form]="form"
                  [paginationConf]="paginationConf"
                  [uuid]="uuidCompany"
                  place="company"
                ></app-booked-container>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>{{ "user.details.booked-flight" | translate }} </a>
              <ng-template ngbNavContent>
                <!-- <app-flight-train-container
                  [paginationConf]="paginationConfFlight"
                  [form]="formFlight"
                  [statusOptions]="statusOptions"
                  [bookeds]="flights"
                  [isAdmin]="isAdmin"
                  typeBooked="Flight"
                  (loadFlight)="loadFlights()"
                  (loadTrain)="loadTrains()"
                ></app-flight-train-container> -->
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>{{ "user.details.booked-train" | translate }} </a>
              <ng-template ngbNavContent>
                <!-- <app-flight-train-container
                  (loadTrain)="loadTrains()"
                  (loadFlight)="loadFlights()"
                  [bookeds]="trains"
                  [statusOptions]="statusOptions"
                  [paginationConf]="paginationConfTrain"
                  [form]="formTrain"
                  typeBooked="Train"
                ></app-flight-train-container> -->
              </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink>{{ "expenses.title" | translate }} </a>
              <ng-template ngbNavContent>
                <!-- <app-expenses-container
                  [expenses]="expenses"
                  [form]="formExpenses"
                  [paginationConf]="paginationConf"
                >
                </app-expenses-container> -->
              </ng-template>
            </li>
          </ul>
          <ul>
            <button
              class="btn btn-outline mb-0 btn-sm btn-success ml-1 button-download"
              (click)="exportExcel()"
            >
              {{ "common.download" | translate }}
            </button>
          </ul>
        </div>
        <!-- Tabs Reservas -->
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</div>
