<ul mat-dialog-content>
  <div class="policies-header">
  <h3 class="heading-5">{{ 'flight.multifamily.policies' | translate }}</h3>
  <button class="close" (click)="close()">X</button>
</div>
  <li *ngFor="let policyPenalty of policiesSolutionPenalties">
    <h4 class="heading-6">{{ policyPenalty.short_name }}:</h4>
    <p>
      {{ policyPenalty.message ? policyPenalty.message : '-' }}
    </p>
  </li>
</ul>
