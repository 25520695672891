import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-reset-password-complete",
  templateUrl: "./reset-password-complete.component.html",
  styleUrls: ["./reset-password-complete.component.scss"],
})
export class ResetPasswordCompleteComponent implements OnInit {
  typeUser: "user" | "company" | "employee";

  constructor(private router: Router) {
    if (this.router.url.includes("/login")) {
      this.typeUser = "user";
    } else if (this.router.url.includes("/company-login")) {
      this.typeUser = "company";
    } else {
      this.typeUser = "employee";
    }
  }

  ngOnInit() {}

  getClassBackground() {
    switch (this.typeUser) {
      case "user":
        return "user-background";
      case "company":
        return "company-background";
      case "employee":
        return "employee-background";
    }
  }

  goToLogin() {
    switch (this.typeUser) {
      case "user":
        return ["/login"];
      case "company":
        return ["/login"];
      case "employee":
        return ["/admin-login"];
    }
  }

  getClassButton() {
    switch (this.typeUser) {
      case "user":
        return "btn-primary";
      case "company":
        return "btn-company";
      case "employee":
        return "btn-vyoo-primary";
    }
  }
}
