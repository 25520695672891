<app-user-detail
  *ngIf="isAdmin && !isCompanyBookeds"
  [userUuid]="userUuid"
  [specialAction]="specialAction"
  [module]="module"
></app-user-detail>
<div *ngIf="isAdmin && isCompanyBookeds" id="content-title">
  <div id="goBackButtonContainer">
    <button mat-button mat-flat-button (click)="goBack()">
      <img src="assets/img/svg/arrow-back.svg" alt="" width="24px" />
    </button>
  </div>
  <div id="logo-container">
    <img src="{{ companyImage ? companyImage : 'assets/img/gallery/image_not_available.png' }}" class="company-logo" alt="Company logo" />
  </div>
</div>
<div class="row mt-2">
  <div class="col-12">
    <div class="row mt-2" *ngIf="request && !canBook">
      <div class="col-12">
        <div class="alert alert-vyoo text-center" role="alert">
          {{ "common.info-not-booking-allowed" | translate }}
        </div>
      </div>
    </div>
    <div *ngIf="loading" class="row">
      <app-loading [userType]="'user'"></app-loading>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-card mb-4">
          <div class="card-body borders-groups">
            <!-- TABS -->
            <div class="nav-container">
              <div class="nav">
                <ul
                  ngbNav
                  #nav="ngbNav"
                  [(activeId)]="navActive"
                  class="nav-tabs"
                >
                  <!-- TAB ALL -->
                  <li [ngbNavItem]="1" [ngClass]="{'disabled': loading || !utilsService.allLoaded}">
                    <a ngbNavLink>
                      {{ "authorization.status.all" | translate }}</a
                    >
                    <ng-template ngbNavContent>
                      <app-booked-container
                        [groupRestrictionUuid]="groupRestrictionUuid"
                        (reloadTypeEmit)="reloadType($event)"
                        [paginationConf]="paginationConf"
                        [bookeds]="all ? utilsService.sortArrayByDate(all) : []"
                        [form]="form"
                        [statusOptions]="statusOptions"
                        place="user"
                        [isUser]="!isAdmin"
                        [companyUuid]="companyUuid"
                        [service]="'all'"
                        [userUuid]="userUuid"
                        [isEmployee]="isEmployee"
                        [nextTravelsChecked]="nextTravelsChecked"
                        (changeComponentEmit)="changeComponent($event)"
                        (selectChangeToGrandparent)="onSelectChangeToGrandparent($event)"
                        *ngIf="showBookeds"
                      >
                      </app-booked-container>
                      <ng-container *ngIf="showAuths">
                        <app-authorizations
                          *ngIf="showAuths"
                          [userUuid]="userUuid"
                          [companyUuid]="companyUuid"
                          [isCompanyBookeds]="false"
                          [typeFromBookeds]="'all'"
                          (changeComponentEmit)="changeComponent($event)"
                        >
                        </app-authorizations>
                      </ng-container>

                      <ng-container *ngIf="showRequests">
                        <app-custom-requests
                          [userUuid]="isCompanyBookeds ? null : userUuid"
                          [company]="companyUuid"
                          [isBookeds]="true"
                          [typeFromBookeds]="'all'"
                          (changeComponentEmit)="changeComponent($event)"
                        >
                        </app-custom-requests>
                      </ng-container>
                    </ng-template>
                  </li>
                  <!-- TAB HOTEL -->
                  <li [ngbNavItem]="2" [ngClass]="{'disabled': loading || !utilsService.allLoaded}">
                    <a ngbNavLink> {{ "graph.hotels" | translate }}</a>
                    <ng-template ngbNavContent>
                      <app-booked-container
                        [groupRestrictionUuid]="groupRestrictionUuid"
                        (reloadTypeEmit)="reloadType($event)"
                        (changeComponentEmit)="changeComponent($event)"
                        *ngIf="showBookeds"
                        [paginationConf]="paginationConf"
                        [bookeds]="
                          bookeds ? utilsService.sortArrayByDate(bookeds) : []
                        "
                        [form]="form"
                        [statusOptions]="statusOptions"
                        place="user"
                        [isUser]="!isAdmin"
                        [companyUuid]="companyUuid"
                        [service]="'hotel'"
                        [userUuid]="userUuid"
                        [isEmployee]="isEmployee"
                        [nextTravelsChecked]="nextTravelsChecked"
                        (selectChangeToGrandparent)="onSelectChangeToGrandparent($event)"
                      ></app-booked-container>
                      <ng-container *ngIf="showAuths">
                        <app-authorizations
                          *ngIf="showAuths"
                          [userUuid]="userUuid"
                          [companyUuid]="companyUuid"
                          [isCompanyBookeds]="false"
                          [typeFromBookeds]="'booking'"
                          (changeComponentEmit)="changeComponent($event)"
                        >
                        </app-authorizations>
                      </ng-container>

                      <ng-container *ngIf="showRequests">
                        <app-custom-requests
                          [userUuid]="isCompanyBookeds ? null : userUuid"
                          [company]="companyUuid"
                          [isBookeds]="true"
                          [typeFromBookeds]="'hotel'"
                          (changeComponentEmit)="changeComponent($event)"
                        >
                        </app-custom-requests>
                      </ng-container>
                    </ng-template>
                  </li>
                  <!-- TAB FLIGHT -->
                  <li [ngbNavItem]="3" [ngClass]="{'disabled': loading || !utilsService.allLoaded}">
                    <a ngbNavLink> {{ "graph.flights" | translate }}</a>
                    <ng-template ngbNavContent>
                      <app-booked-container
                        [groupRestrictionUuid]="groupRestrictionUuid"
                        (reloadTypeEmit)="reloadType($event)"
                        (changeComponentEmit)="changeComponent($event)"
                        *ngIf="showBookeds"
                        [paginationConf]="paginationConf"
                        [bookeds]="
                          flights ? utilsService.sortArrayByDate(flights) : []
                        "
                        [form]="form"
                        [statusOptions]="statusOptions"
                        place="user"
                        [isUser]="!isAdmin"
                        [companyUuid]="companyUuid"
                        [service]="'flight'"
                        [userUuid]="userUuid"
                        [isEmployee]="isEmployee"
                        [nextTravelsChecked]="nextTravelsChecked"
                        (selectChangeToGrandparent)="onSelectChangeToGrandparent($event)"
                      ></app-booked-container>
                      <ng-container *ngIf="showAuths">
                        <app-authorizations
                          [userUuid]="userUuid"
                          [companyUuid]="companyUuid"
                          [isCompanyBookeds]="false"
                          [typeFromBookeds]="'flight'"
                          (changeComponentEmit)="changeComponent($event)"
                        >
                        </app-authorizations>
                      </ng-container>

                      <ng-container *ngIf="showRequests">
                        <app-custom-requests
                          [userUuid]="isCompanyBookeds ? null : userUuid"
                          [company]="companyUuid"
                          [isBookeds]="true"
                          [typeFromBookeds]="'flight'"
                          (changeComponentEmit)="changeComponent($event)"
                        >
                        </app-custom-requests>
                      </ng-container>
                    </ng-template>
                  </li>
                  <!-- TAB TRAIN -->
                  <li [ngbNavItem]="4" [ngClass]="{'disabled': loading || !utilsService.allLoaded}">
                    <a ngbNavLink> {{ "graph.trains" | translate }}</a>
                    <ng-template ngbNavContent>
                      <app-booked-container
                        [groupRestrictionUuid]="groupRestrictionUuid"
                        (reloadTypeEmit)="reloadType($event)"
                        (changeComponentEmit)="changeComponent($event)"
                        *ngIf="showBookeds"
                        [paginationConf]="paginationConf"
                        [bookeds]="
                          trains ? utilsService.sortArrayByDate(trains) : []
                        "
                        [form]="form"
                        [statusOptions]="statusOptions"
                        place="user"
                        [isUser]="!isAdmin"
                        [companyUuid]="companyUuid"
                        [service]="'train'"
                        [userUuid]="userUuid"
                        [isEmployee]="isEmployee"
                        [nextTravelsChecked]="nextTravelsChecked"
                        (selectChangeToGrandparent)="onSelectChangeToGrandparent($event)"
                      ></app-booked-container>
                      <ng-container *ngIf="showAuths">
                        <app-authorizations
                          [userUuid]="userUuid"
                          [companyUuid]="companyUuid"
                          [isCompanyBookeds]="false"
                          [typeFromBookeds]="'train'"
                          (changeComponentEmit)="changeComponent($event)"
                        ></app-authorizations>
                      </ng-container>

                      <ng-container *ngIf="showRequests">
                        <app-custom-requests
                          [userUuid]="isCompanyBookeds ? null : userUuid"
                          [company]="companyUuid"
                          [isBookeds]="true"
                          [typeFromBookeds]="'train'"
                          (changeComponentEmit)="changeComponent($event)"
                        >
                        </app-custom-requests>
                      </ng-container>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="5" [ngClass]="{'disabled': loading || !utilsService.allLoaded}">
                    <a ngbNavLink> {{ "graph.cars" | translate }} </a>
                    <ng-template ngbNavContent>
                      <app-booked-container
                        [groupRestrictionUuid]="groupRestrictionUuid"
                        (reloadTypeEmit)="reloadType($event)"
                        [paginationConf]="paginationConf"
                        [bookeds]="
                          cars ? utilsService.sortArrayByDate(cars) : []
                        "
                        [form]="form"
                        [statusOptions]="statusOptions"
                        place="user"
                        [isUser]="!isAdmin"
                        [companyUuid]="companyUuid"
                        [service]="'car'"
                        [userUuid]="userUuid"
                        [isEmployee]="isEmployee"
                        [nextTravelsChecked]="nextTravelsChecked"
                        *ngIf="showBookeds || showAuths"
                        (changeComponentEmit)="changeComponent($event)"
                        (selectChangeToGrandparent)="onSelectChangeToGrandparent($event)"
                      ></app-booked-container>
                      <ng-container *ngIf="showRequests">
                        <app-custom-requests
                          [userUuid]="isCompanyBookeds ? null : userUuid"
                          [company]="companyUuid"
                          [isBookeds]="true"
                          [typeFromBookeds]="'car'"
                          (changeComponentEmit)="changeComponent($event)"
                        >
                        </app-custom-requests>
                      </ng-container>
                    </ng-template>
                  </li>
                  <!-- EXPENSES TAB -->
                  <li [ngbNavItem]="6" [ngClass]="{'disabled': loading || !utilsService.allLoaded}">
                    <a ngbNavLink>{{ "graph.others" | translate }} </a>
                    <ng-template ngbNavContent>
                      <app-booked-container
                        [groupRestrictionUuid]="groupRestrictionUuid"
                        (reloadTypeEmit)="reloadType($event)"
                        [paginationConf]="paginationConf"
                        [bookeds]="
                          expenses ? utilsService.sortArrayByDate(expenses) : []
                        "
                        [form]="form"
                        [statusOptions]="statusOptions"
                        place="user"
                        [isUser]="!isAdmin"
                        [companyUuid]="companyUuid"
                        [service]="'expense'"
                        [userUuid]="userUuid"
                        [isEmployee]="isEmployee"
                        [nextTravelsChecked]="nextTravelsChecked"
                        (changeComponentEmit)="changeComponent($event)"
                        (selectChangeToGrandparent)="onSelectChangeToGrandparent($event)"
                      >
                      </app-booked-container>
                    </ng-template>
                  </li>
                </ul>
                <div class="d-flex justify-content-end ml-auto" *ngIf="isDownloading">
                  <p class="mt-2 mr-2">{{ "common.downloading" | translate }}... <i class="ft-loader"></i></p>
                </div>
                <ul
                  ngbNav
                  #nav1="ngbNav"
                  [(activeId)]="navActive"
                  class="nav-tabs"
                >
                  <li [ngbNavItem]="8" *ngIf="!isCompanyBookeds" [ngClass]="{'disabled': loading || !utilsService.allLoaded}">
                    <a ngbNavLink class="button project">{{
                      "project.my-projects.mine" | translate
                    }}</a>
                    <ng-template ngbNavContent>
                      <app-travel-container
                        [pagination]="travelPagination"
                        [travels]="travels"
                        [form]="formTravel"
                        [uuid]="userUuid"
                        [companyUuid]="companyUuid"
                        (loadTravels)="loadTravels()"
                        [isLoadingTravel]="isLoadingTravel"
                        [isCompanyBookeds]="isCompanyBookeds"
                      >
                      </app-travel-container>
                    </ng-template>
                  </li>
                  <li
                    ngbDropdown
                    class="button excel"
                    placement="bottom-right"
                    [ngClass]="{'disabled': loading || !utilsService.allLoaded, 'admin-excel': isAdmin}"
                  >
                    <a ngbDropdownToggle
                      >{{ "common.download" | translate }}
                    </a>
                    <div
                      ngbDropdownMenu
                      class="dropdown-menu"
                      aria-labelledby="dropdownBasic1"
                    >
                      <p (click)="exportExcel('travel')">
                        {{ "project.my-projects.mine" | translate }}
                      </p>
                      <p (click)="exportExcel('all')">
                        {{ "authorization.status.all" | translate }}
                      </p>
                      <p (click)="exportExcel('hotel')">
                        {{ "graph.hotels" | translate }}
                      </p>
                      <p (click)="exportExcel('flight')">
                        {{ "graph.flights" | translate }}
                      </p>
                      <p (click)="exportExcel('train')">
                        {{ "graph.trains" | translate }}
                      </p>
                      <p (click)="exportExcel('car')">
                        {{ "graph.cars" | translate }}
                      </p>
                      <p (click)="exportExcel('expense')">
                        {{ "graph.others" | translate }}
                      </p>
                    </div>
                  </li>
                  <li class="custom-btn custom-btn--yellow" *ngIf="!isCompanyBookeds" [ngClass]="{'disabled': loading || !utilsService.allLoaded}">
                    <a (click)="addTravel()">{{ "project.add" | translate }}</a>
                  </li>
                </ul>
              </div>
              <!-- END TABS -->
              <div [ngbNavOutlet]="nav"></div>
              <div [ngbNavOutlet]="nav1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
