import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { CarService } from "@services";
import { SharedModule } from "@shared/shared.module";
import { UserModule } from "app/modules/businessEntities/user/user.module";
import { CarRoutingModule } from "./car-routing.module";
import { CreateCarComponent } from "./custom/create-car/create-car.component";

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        SharedModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatInputModule,
        CarRoutingModule,
        UserModule,
    ],
    declarations: [CreateCarComponent],
    providers: [CarService]
})
export class CarModule {}
