<div class="dialog-container">
  <div class="create-container" *ngIf="isOnlyCreate === false">
    <button
      (click)="changeTab()"
      class="custom-btn custom-btn--yellow"
    >
      <img
        src="assets/img/svg/add.svg"
        alt=""
        *ngIf="isCreate === false"
      />
      {{ buttonTitle | translate }}
    </button>
  </div>
  <div class="title-container">
    <h2 class="heading-5">
      <div class="icon-bcg" *ngIf="isCreate === false">
        <img
          src="assets/img/svg/sidebar/briefcase.svg"
          alt=""
        />
      </div>
      {{ title | translate }}
    </h2>
  </div>
  <div class="assign-create-container">
    <div *ngIf="isCreate === false; else createProject" class="add-project">
      <div *ngIf="isLoading">
        <app-loading></app-loading>
      </div>
      <select
        *ngIf="isLoading === false"
        name=""
        id=""
        [(ngModel)]="selectedProject"
        class="form-control form-control--squared select-input"
      >
        <option *ngFor="let project of projects" [ngValue]="project">
          {{ project.title }}
        </option>
      </select>
    </div>
    <ng-template #createProject>
      <form [formGroup]="createProjectForm" action="">
        <input
          formControlName="title"
          type="text"
          placeholder="{{ 'project.form.title' | translate }}"
          class="form-control"
        />
        <div class="form-row-date">
          <div class="project-date-field">
            <input
              #bookedRange
              class="form-control filter-dates-placeholder"
              (dateSelect)="onDateSelection($event)"
              placeholder="{{ 'project.form.travelDates' | translate }}"
              #dp
              ngbDatepicker
              [dayTemplate]="t1"
              [autoClose]="'outside'"
              [displayMonths]="1"
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              [minDate]="minDateNgStruct"
              [maxDate]="maxDateNgStruct"
            />
            <ng-template #t1 let-date let-focused="focused">
              <span
                class="custom-day"
                [ngClass]="{
                  activeFrom:
                    createProjectForm.controls['initDate'].value === date,
                  activeTo: createProjectForm.controls['endDate'].value === date
                }"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
        <textarea
          formControlName="description"
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="{{ 'project.form.description' | translate }}"
          class="form-control"
        ></textarea>
      </form>
    </ng-template>
  </div>
  <div class="actions-container">
    <button class="custom-btn custom-btn--close" (click)="close(false)">
      {{
        (isOnlyCreate || isEdit || isCreate ? "project.cancel" : "common.no")
          | translate
      }}</button
    ><button
      class="custom-btn"
      (click)="close(true)"
      [disabled]="checkDisable() && isCreate"
    >
      {{ (isCreate || isEdit ? "project.accept" : "common.yes") | translate }}
    </button>
  </div>
</div>
