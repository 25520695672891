import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { User } from "@core/models";
import { BookedService, NgxToastrService, UserService, UtilsService } from "@core/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
})
export class DetailsComponent implements OnInit {
  @Input() authorization: any;
  @Input() user: any;
  @Input() fromD: string;
  @Input() toD: string;
  @Output() sendInfo = new EventEmitter<any>();
  @ViewChild("textarea") textarea;
  authorizationUser: User;
  subscriptions: Subscription[] = [];
  travellersWithFFDocuments: { fullName: string, documentNumber: string }[] = [];
  constructor(
    private ngxToastrService: NgxToastrService,
    private translateService: TranslateService,
    private modal: NgbModal,
    private userService: UserService,
    private bookedService: BookedService,
    private utilsService: UtilsService,
  ) {
    this.utilsService.socket.on("check-rate-authorization", (result, err) => {
      if (this.authorization.requestData.rooms[0].correlationId === result.correlationId) {
        let paxes = this.authorization.requestData.rooms[0].paxes
            
        this.authorization.requestData.rooms[0] = result.data.accommodation.rooms[0].rates ? result.data.accommodation.rooms[0]?.rates[0] : result.data.accommodation.rooms[0];
    
        if (!this.authorization?.requestData?.rooms[0]?.paxes) {
          this.authorization.requestData.rooms[0].paxes = paxes;
        }
      }
    })
  }

  ngOnInit(): void { 

    if (this.authorization.type.toLowerCase() === "flight") {
      const travellers = this.authorization?.requestData?.query?.travellers;
      if (travellers && Array.isArray(travellers)) {
        travellers.forEach(traveller => {
          const documents = traveller?.documents;
          if (documents && Array.isArray(documents)) {
            const document = documents.find(doc => doc.type === 'FF');
            if (document && document?.number) {
              const travellerName = traveller.name;
              const travellerSurnames = traveller.surnames;
              const documentNumber = document.number;
              
              this.travellersWithFFDocuments.push({
                fullName: `${travellerName} ${travellerSurnames.join(' ')}`,
                documentNumber: documentNumber
              });
            }
          }
        });
      }
    }

    if (this.authorization.type === "booking") {
      const parsedRoom = {...this.authorization.requestData?.rooms[0]};
      parsedRoom.nameRoom = this.authorization.requestData?.rooms[0].name;
      parsedRoom.net = this.authorization.requestData?.rooms[0].rates[0].net;
      parsedRoom.rateType = this.authorization.requestData?.rooms[0].rates[0].rateType;
      parsedRoom.rateKey = this.authorization.requestData?.rooms[0].rates[0].rateKey;
      parsedRoom.cancellation = !!this.authorization.requestData?.rooms[0].rates[0].cancellationPolicies
      parsedRoom.cancellationPolicies = this.authorization.requestData?.rooms[0].rates[0].cancellationPolicies
      parsedRoom.cancellationPolicies.forEach((item, index, array) => {
        array[index] = {
          amount: item.amount,
          from: item.from,
          text: item.text,
        }
      });
      const body = {
        userUuid: this.authorization.userUuid,
        checkIn: moment(this.authorization.fromDate).format('YYYY-MM-DD'),
        checkOut: moment(this.authorization.toDate).format('YYYY-MM-DD'),
        rooms: [parsedRoom],
        guests: this.authorization.requestData?.guests,
        isAuthorization: true
      }
      const correlation = crypto.randomUUID();
      this.utilsService.correlationId.push(correlation);
      body['correlationId'] = correlation;
      this.bookedService.checkRate(body).subscribe((rate) => {
        this.authorization.requestData.rooms[0].correlationId = correlation;
      })
    }
    this.subscriptions.push(
      this.userService.getUser(this.authorization.userUuid).subscribe((user: User) => {
        this.authorizationUser = user;
      })
    )
  }

  getHours(authorization, departure: boolean) {
    let departHour: string;
    let returnHour: string;
    if (authorization.type?.toLowerCase() === "train") {
      departHour = moment(authorization.requestData.departDate).format("HH:mm");
      returnHour =
        authorization.requestData.returnDate != null
          ? moment(authorization.requestData.returnDate).format("HH:mm")
          : "";
    } else {
      if (authorization.requestData.departureDate) {
        departHour = moment(authorization.requestData.departureDate).format("HH:mm");
      } else {
        departHour = authorization.requestData.departDate.split(" ")[1];
      }

      if (authorization.requestData.returnDate && authorization.requestData.returnDate.match(/^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/)) {
        returnHour = authorization.requestData.returnDate.split(" ")[1];
      } else {
        returnHour = moment(authorization.requestData.returnDate).format("HH:mm");
      }
    }

    return departure ? departHour : returnHour;
  }

  onDeny(authorization) {
    const action = this.textarea.nativeElement;

    if (!action.value || action.value === "") {
      this.translateService
        .get(["common.error", "authorization.swal.comment-required"])
        .subscribe((transl) => {
          this.ngxToastrService.typeInfo(
            transl["common.error"],
            transl["authorization.swal.comment-required"]
          );
        });
    } else {
      const info = {
        action: action.value,
        deny: true,
        authorization: authorization,
        loading: true
      };
      this.sendInfo.emit(info);
    }
  }

  onAccept(authorization) {
    const action = this.textarea.nativeElement;
    const info = {
      action: action.value,
      deny: false,
      authorization: authorization,
      loading: true,
      authorizationUser: this.authorizationUser
    };
    this.sendInfo.emit(info);
  }

  getLogo(value: string) {
    return `assets/img/airlines/small/${value}.png`;
  }

  close() {
    this.modal.dismissAll();
  }
}
