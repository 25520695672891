import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import {
  CompanyService,
  GraphService,
  NgxToastrService,
  UtilsService,
} from "@services";
import { SavingsSatisfactionFormComponent } from "app/modules/businessEntities/company/savings-satisfaction-form/savings-satisfaction-form.component";
import * as Highcharts from "highcharts";
import HC_exporting_data from "highcharts/modules/export-data";
import HC_exporting from "highcharts/modules/exporting";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
HC_exporting(Highcharts);
HC_exporting_data(Highcharts);
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  isSuperAdmin = false;
  fromSuperAdmin = false;
  data: any[];
  categories;
  from: string;
  to: string;
  showError: boolean;
  name: string;
  type = "company";
  uuid: string;
  savings: any;
  satisfactions: any;
  optimizationSubscriptions: Subscription[] = [];

  constructor(
    private graphService: GraphService,
    private companyService: CompanyService,
    private ngxToastrService: NgxToastrService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {
    this.from = "01-2020";
    this.to = moment().format("MM-YYYY");
  }

  ngOnInit() {
    if (window.history.state.dataDashboard != null) {
      window.history.state.dataDashboard.isSuperAdmin
        ? (this.isSuperAdmin = window.history.state.dataDashboard.isSuperAdmin)
        : (this.isSuperAdmin = false);
      window.history.state.dataDashboard.fromSuperAdmin
        ? (this.fromSuperAdmin =
            window.history.state.dataDashboard.fromSuperAdmin)
        : (this.fromSuperAdmin = false);
      if (window.history.state.dataDashboard.uuid) {
        this.name = window.history.state.dataDashboard.name;
        this.uuid = window.history.state.dataDashboard.uuid;
        this.getSavings();
      }
      this.storeParams();
    } else {
      this.getFromLocalStorage();
    }
    if (this.isSuperAdmin) {
      this.type = "all";
    }
    this.getSatisfactions();
    this.getDataGraphic("all");
    this.getDataGraphic("service");
  }

  storeParams() {
    this.utilsService.storeParamInLocalStorage({
      title: "isSuperAdmin",
      value: this.isSuperAdmin,
    });
    this.utilsService.storeParamInLocalStorage({
      title: "fromSuperAdmin",
      value: this.fromSuperAdmin,
    });
    this.utilsService.storeParamInLocalStorage({
      title: "name",
      value: this.name,
    });
    this.utilsService.storeParamInLocalStorage({
      title: "uuid",
      value: this.uuid,
    });
  }

  getFromLocalStorage() {
    this.isSuperAdmin =
      this.utilsService.getLocalStorageItem("isSuperAdmin").value;
    this.fromSuperAdmin =
      this.utilsService.getLocalStorageItem("fromSuperAdmin").value;
    this.name = this.utilsService.getLocalStorageItem("name").value;
    this.uuid = this.utilsService.getLocalStorageItem("uuid").value;
  }

  getSavings() {
    this.optimizationSubscriptions.push(
      this.companyService.getSavings(this.uuid).subscribe(
        (s) => {
          this.savings = s;
        },
        (err) => {
          this.translate.get("optimization.error").subscribe((resp) => {
            this.ngxToastrService.typeInfo(null, resp);
          });
        }
      )
    );
  }
  getSatisfactions() {
    this.optimizationSubscriptions.push(
      this.companyService.getSatisfactions().subscribe(
        (s) => {
          this.satisfactions = s[0];
        },
        (err) => {
          this.translate.get("optimization.error").subscribe((resp) => {
            this.ngxToastrService.typeInfo(null, resp);
          });
        }
      )
    );
  }

  getDataGraphic(graphic) {
    this.graphService
      .getDataGraphic({
        type: this.type,
        graphic: graphic,
        uuid: this.uuid,
        from: this.from,
        to: this.to,
      })
      .pipe(take(1))
      .subscribe((graph: any) => {
        this.createGraph(graph, graphic, "all");
        this.createGraph(graph, graphic, "services");
      });
  }

  createGraph(graph, graphic, type?: string) {
    const params = [];
    if (graphic === "service") {
      graph.data.forEach((value) => {
        params.push(`graph.${value.name}`);
      });
    }
    params.push("graph.expenses");
    params.push("graph.months");
    this.translate.get(params).subscribe((result: any[]) => {
      this.data = graph.data.map((value) => {
        if (value.general === null) {
          value.general = 0;
        }
        return value.general;
      });
      let series = [];
      Highcharts.setOptions({
        colors: [
          "#52c9ad",
          "#9271f6",
          "#17362f",
          "#058DC7",
          "#50B432",
          "#ED561B",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
      });
      if (graphic === "service") {
        graph.data.forEach((value, index) => {
          series.push({
            name: result[`graph.${value.name}`],
            data: graph.data[index].data,
            animation: {
              duration: 2000,
              // Uses Math.easeOutBounce
              easing: "easeOutBounce",
            },
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [
                [0, Highcharts.getOptions().colors[index]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[index])
                    .setOpacity(0)
                    .get("rgba"),
                ],
              ],
            },
            marker: {
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          });
        });
      } else {
        series = [
          {
            name: result["graph.expenses"],
            data: this.data,
            animation: {
              duration: 2000,
              // Uses Math.easeOutBounce
              easing: "easeOutBounce",
            },
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0)
                    .get("rgba"),
                ],
              ],
            },
          },
        ];
      }

      Highcharts.chart(graphic === "all" ? "full" : "services", {
        chart: {
          type: "areaspline",
        },
        title: {
          text: this.name,
        },
        xAxis: {
          categories: graph.name,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: result["graph.expenses"],
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} €</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: true,
              symbol: "circle",
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: series,
        exporting: {
          csv: {
            columnHeaderFormatter: function (item, key) {
              if (!key) {
                return result["graph.months"];
              }
              return false;
            },
          },
          buttons: {
            contextButton: {
              menuItems: [
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadXLS",
              ],
            },
          },
        },
      });
    });
  }

  checkDate($event) {
    if ($event) {
      let checkDate = $event.split("-").join("");
      if (checkDate.length > 0) {
        if (checkDate.length < 5) {
          checkDate = checkDate.match(new RegExp(".{1,2}", "g")).join("-");
          return checkDate;
        } else if ($event.match(new RegExp(".{2}-.{3,4}", "g"))) {
          return $event;
        }
        return $event;
      }
    }
  }

  openModal(action: string) {
    if (action === "add") {
      const modalRef = this.modalService.open(
        SavingsSatisfactionFormComponent,
        { centered: true }
      );
      modalRef.componentInstance.companyUuid = this.uuid;
      modalRef.componentInstance.isSuperAdmin = this.isSuperAdmin;
    } else {
      const modalRef = this.modalService.open(
        SavingsSatisfactionFormComponent,
        { centered: true }
      );
      modalRef.componentInstance.companyUuid = this.uuid;
      modalRef.componentInstance.savings = this.savings;
      modalRef.componentInstance.satisfaction = this.satisfactions;
      modalRef.componentInstance.isSuperAdmin = this.isSuperAdmin;
    }
  }

  getDataValidate(graph) {
    if (this.correctDates()) {
      this.showError = false;
      this.getDataGraphic(graph);
    } else {
      this.showError = true;
    }
  }
  correctDates() {
    const checkRegExpFrom = this.from
      ? this.from.match(new RegExp("((0[1-9]|1[0-2])-[2]\\d{3})"))
      : null;
    const checkRegExpTo = this.to
      ? this.to.match(new RegExp("((0[1-9]|1[0-2])-[2]\\d{3})"))
      : null;
    const fromAux = checkRegExpFrom ? moment(this.from, "MM-YYYY") : null;
    const toAux = checkRegExpTo ? moment(this.to, "MM-YYYY") : null;
    const validFrom = fromAux
      ? Number(fromAux.format("YYYY")) >= 2019 &&
        Number(fromAux.format("YYYY")) <= Number(moment().format("YYYY"))
      : null;
    const validTo = toAux
      ? Number(toAux.format("YYYY")) >= 2019 &&
        Number(toAux.format("YYYY")) <= Number(moment().format("YYYY"))
      : null;
    return validFrom && validTo ? !fromAux.isAfter(toAux) : false;
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.optimizationSubscriptions.forEach((s) => s.unsubscribe());
    this.utilsService.removeItemFromLocalStorage("isSuperAdmin");
    this.utilsService.removeItemFromLocalStorage("fromSuperAdmin");
    this.utilsService.removeItemFromLocalStorage("name");
    this.utilsService.removeItemFromLocalStorage("uuid");
  }
}
