import { UtilsService } from "./../../../core/services/utils.service";
import { Location } from "@angular/common";
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, NgModel } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Booked, Car, Company, Flight, Train } from "@models";
import {
  NgbDateStruct,
  NgbInputDatepicker,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AgencyService,
  AuthService,
  BookService,
  BookedService,
  CarService,
  CompanyService,
  ExcelService,
  ExpensesService,
  MediaService,
  NgxToastrService,
  TravelService,
} from "@services";
import { UploadBill } from "@shared/component/bookeds/billing/bills/uploadBill/uploadBill.component";
import { DialogAssignProjectComponent } from "@shared/component/dialog-assign-project/dialog-assign-project.component";
import { PaginationConfig } from "@shared/interfaces";
import { AuthState } from "app/store/reducers/auth.reducer";
import { CompanyState } from "app/store/reducers/company.reducer";
import moment from "moment";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { AppState } from "../company.reducer";

@Component({
  selector: "app-booked-homepage",
  templateUrl: "./booked-homepage.component.html",
  styleUrls: ["./booked-homepage.component.scss"],
})
export class BookedHomepageComponent implements OnInit, OnDestroy {
  currentPage = "Reservas";
  company: Company;
  paginationConf: PaginationConfig = {
    page: 1,
    pageSize: 9,
    collectionSize: 120,
  };
  paginationConfFlight: PaginationConfig = {
    page: 1,
    pageSize: 5,
    collectionSize: 120,
  };
  paginationConfTrain: PaginationConfig = {
    page: 1,
    pageSize: 5,
    collectionSize: 120,
  };
  travels: any[] = [];
  travelPagination = {};

  companyBookeds: Booked[] = [];
  flights: Flight[] = [];
  trains: Train[] = [];
  cars: Car[] = [];
  expenses = [];
  uuidCompany: string;
  isCompany = false;
  isAdmin = false;
  authSubscription = new Subscription();
  loading = false;
  isLoading = true;
  // FILTROS
  form: UntypedFormGroup;
  formFlight: UntypedFormGroup;
  formTrain: UntypedFormGroup;
  formExpenses: UntypedFormGroup;
  formTravel: UntypedFormGroup;

  statusOptions = [];

  // RANGE
  hoveredDate: NgbDateStruct;
  fromDate: any;
  toDate: any;
  model: any;
  userType = "company";
  @ViewChild("d") input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild("rangeInput") rangeInput: ElementRef;

  subscriptions: Subscription[] = [];
  image: any;
  agencyPhone: string;
  agencyImage: string;
  agencyShow = false;
  VYOOTRIP_NUMBER = "648 200 516";
  vyooLogo = "../../../../../../assets/img/logo/text_logo_new.png";
  navActive = 1;
  isCompanyBookeds: boolean;
  transactions: any[];
  isLoadingTravel : boolean = false;
  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private bookedService: BookedService,
    private expensesService: ExpensesService,
    private ngxToastrService: NgxToastrService,
    private store: Store<AppState>,
    public translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private travelService: TravelService,
    private mediaService: MediaService,
    private agencyService: AgencyService,
    public authService: AuthService,
    private _location: Location,
    private modalService: NgbModal,
    private excelService: ExcelService,
    private dialog: MatDialog,
    private carService: CarService,
    private utilsService: UtilsService,
    private bookService: BookService
  ) {}

  ngOnInit() {
    this.translate
      .get(["common.active", "common.canceled"])
      .subscribe((result) => {
        this.statusOptions = [
          { value: "active", text: result["common.active"] },
          { value: "cancelled", text: result["common.canceled"] },
        ];
      });

    this.paginationConf.collectionSize = this.companyBookeds.length;
    this.paginationConfFlight.collectionSize = this.flights.length;
    this.paginationConfTrain.collectionSize = this.trains.length;

    this.form = this.formBuilder.group({
      modelUser: "",
      modelHotel: "",
      modelDestiny: "",
      statusOptions: "",
    });

    this.formFlight = this.formBuilder.group({
      originFlight: "",
      destinyFlight: "",
      statusOptions: "",
    });
    this.formTrain = this.formBuilder.group({
      originTrain: "",
      destinyTrain: "",
      statusOptions: "",
    });
    this.formExpenses = this.formBuilder.group({
      type: "",
      price: "",
    });
    const path: string = this.route.snapshot.routeConfig.path;
    this.isCompanyBookeds =
      this.route.snapshot.routeConfig.path === "reports" ||
      this.route.snapshot.routeConfig.path === ":id/reports";
    if (path === "bookeds") {
      this.isCompany = true;
      this.authSubscription = this.store
        .select("auth")
        .subscribe((authState: AuthState) => {
          if (authState.user && authState.user?.type.toUpperCase() !== "EMPLOYEE") {
            this.uuidCompany = authState?.user["companyUuid"];
            this.currentPage = "bookeds";
            this.userType = authState.user?.type;
          }
        });
        this.loadData()
    } else {
      this.authSubscription = this.store
        .select("auth")
        .subscribe((authState: AuthState) => {
            this.userType = authState.user?.type;
            this.uuidCompany = this.route.snapshot.paramMap.get("id");
            this.isAdmin = true;
            this.currentPage = "bookeds";
        })
        this.loadData();
    }

    this.store.select("company").subscribe((companyState: CompanyState) => {
      this.paginationConf.page = companyState.paginationConfig.page;
    });
  }

  loadData(){
    this.loadCompany();
    /* this.loadBookeds();
    this.loadFlights();
    this.loadTrains();
    this.loadExpenses();
    this.loadTravels();
    this.loadCars(); */
    this.loadTransactions();
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.utilsService.userBookingsFilters.fromDate = null;
    this.utilsService.userBookingsFilters.toDate = null;
    this.utilsService.userBookingsFilters.createdFromDate = null;
    this.utilsService.userBookingsFilters.createdToDate = null;
    this.bookService.clearCache();
  }

  goBack() {
    this._location.back();
  }

  loadCompany() {
    this.companyService.getCompany(this.uuidCompany).subscribe(
      (company: Company) => {
        this.company = company;
        if (this.company.image) {
          this.subscriptions.push(
            this.mediaService.getMedia(this.company.image).subscribe(
              (s) => {
                const reader = new FileReader();
                reader.readAsDataURL(s);
                let base64data;
                reader.onloadend = () => {
                  base64data = reader.result;
                  this.image = base64data;
                  this.bookService.setData('companyImg', this.image);
                };
              },
              (err) => {
                this.translate
                  .get("company.company-detail.error-get-company")
                  .pipe(take(1))
                  .subscribe((result) => {
                    this.ngxToastrService.typeInfo(result, err.error.error);
                  });
              }
            )
          );
        }
        if (this.company.agencyUuid) {
          this.subscriptions.push(
            this.agencyService
              .getPhone(this.company.agencyUuid)
              .subscribe((p) => {
                this.agencyPhone = p;
              }),
            this.agencyService.getAgency(this.company.agencyUuid).subscribe(
              (i) => {
                this.agencyImage = i.image;
                if (this.agencyImage) {
                  this.subscriptions.push(
                    this.mediaService
                      .getMedia(this.agencyImage)
                      .subscribe((s) => {
                        this.agencyShow = true;
                        const reader = new FileReader();
                        reader.readAsDataURL(s);
                        let base64data;
                        reader.onloadend = () => {
                          base64data = reader.result;
                          this.agencyImage = base64data;
                        };
                      })
                  );
                } else {
                  this.agencyPhone = this.VYOOTRIP_NUMBER;
                  this.agencyShow = true;
                  this.agencyImage = this.vyooLogo;
                }
              },
              (err) => {
                this.translate
                  .get("company.company-detail.error-get-company")
                  .pipe(take(1))
                  .subscribe((result) => {
                    this.ngxToastrService.typeInfo(result, err.error.error);
                  });
              }
            )
          );
        }
      },
      (err) => {
        this.translate
          .get("company.booked.error-get-company")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  loadTravels() {   
    this.isLoadingTravel = true;
    this.travelService
      .getTravelsWithParams({ companyUuid: this.uuidCompany })
      .subscribe(
        (travels: any) => {
          this.isLoading = false;
          this.isLoadingTravel = false;
          this.travels = travels.docs;
          this.travelPagination = travels;
          this.bookService.setData('travels', this.travels);
          delete this.travelPagination["docs"];
        },
        (err) => {
          this.isLoading = false;
          this.isLoadingTravel = false;
          this.translate
            .get("company.booked.error-get-booked")
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.error);
            });
        }
      );
  }

  loadBookeds() {
    this.bookedService.getBookedsByCompany(this.uuidCompany).subscribe(
      (bookeds: Booked[]) => {
        this.companyBookeds = bookeds.reverse();
        this.paginationConf.collectionSize = bookeds.length;
        this.bookService.setData('bookeds', this.companyBookeds);
      },
      (err) => {
        this.translate
          .get("company.booked.error-get-booked")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  loadFlights() {
    this.companyService.getFlights(this.uuidCompany).subscribe(
      (flights: Flight[]) => {
        this.flights = flights.reverse();
        this.paginationConf.collectionSize = flights.length;
        this.bookService.setData('flights', this.flights);
      },
      (err) => {
        this.translate
          .get("company.booked.error-get-booked")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  loadTrains() {
    this.companyService.getTrains(this.uuidCompany).subscribe(
      (trains: Train[]) => {
        this.trains = trains.reverse();
        this.paginationConf.collectionSize = trains.length;
        this.bookService.setData('trains', this.trains);
      },
      (err) => {
        this.translate
          .get("company.booked.error-get-booked")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  loadExpenses() {
    this.expensesService.getCompanyExpenses(this.uuidCompany).subscribe(
      (expenses: any[]) => {
        this.expenses = expenses["docs"]
          ? expenses["docs"].reverse()
          : expenses.reverse();
        this.paginationConf.collectionSize = expenses.length;
        this.bookService.setData('expenses', this.expenses);
      },
      (err) => {
        this.translate
          .get("company.booked.error-get-booked")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  loadCars() {
    this.carService.getCompanyCars(this.uuidCompany).subscribe(
      (cars) => {
        this.cars = cars["docs"] ? cars["docs"].reverse() : cars.reverse();
        this.bookService.setData('cars', this.cars);
      },
      (err) => {
        this.translate
          .get("my-bookeds.user.error-get-cars")
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  loadTransactions() {
    this.isLoading = true;
    this.companyService
      .getTransactions(this.uuidCompany)
      .subscribe((transactions) => {
        this.transactions = transactions;
        this.transactions.reverse();
        this.bookService.setData('transactions', this.transactions);
        this.isLoading = false;
      });
  }

  addTravel() {
    const modalRef = this.dialog.open(DialogAssignProjectComponent, {
      data: {
        create: true,
        isOnlyCreate: true,
      },
      panelClass: "new-dialog",
    });

    modalRef.afterClosed().subscribe((dataTravel) => {
      if (dataTravel != null) {
        if (this.uuidCompany !== null) {
          dataTravel.travelData["companyUuid"] = this.uuidCompany;
        }
        if (dataTravel.travelData["endDate"] === "" && dataTravel.travelData["initDate"] === "") {
          delete dataTravel.travelData["endDate"];
          delete dataTravel.travelData["initDate"];
        }
        this.subscriptions.push(
          this.travelService.postTravel(dataTravel.travelData).subscribe(
            () => {
              this.subscriptions.push(
                this.translate
                  .get(`project.my-projects.created`)
                  .subscribe((resp) => {
                    this.ngxToastrService.typeSuccess(null, resp);
                    setTimeout(() => {
                      this.loadTravels();
                    }, 3000);
                  })
              );
            },
            (err) => {
              this.isLoadingTravel = false;
              this.subscriptions.push(
                this.translate.get("common.error").subscribe((resp) => {
                  this.ngxToastrService.typeInfo(resp, err.error.message);
                })
              );
            }
          )
        );
      }
    });
  }

  exportTransactions() {
    let headers = [];
    this.subscriptions.push(
      this.translate
        .get([
          "company.transaction.filters.user",
          "company.transaction.filters.department",
          "company.transaction.filters.type",
          "company.transaction.filters.booked",
          "company.transaction.filters.auth_code",
          "company.transaction.filters.created",
          "company.transaction.filters.amount",
          "company.transaction.filters.type-payment",
          "company.transaction.filters.type-refund",
          "company.transaction.filters.type-pending",
          "expenses.title",
          "company.transaction.flight",
          "company.transaction.train",
          "company.transaction.filters.no-booked-create",
          "common.car",
          "common.room",
        ])
        .subscribe((resp) => {
          headers = [
            resp["company.transaction.filters.department"],
            resp["company.transaction.filters.user"],
            resp["company.transaction.filters.type"],
            resp["company.transaction.filters.booked"],
            resp["company.transaction.filters.auth_code"],
            resp["company.transaction.filters.created"],
            resp["company.transaction.filters.amount"],
          ];
          const filterUser: string = "";
          const filterCompany: string = "";
          const filterBooking: string = "";

          const arrayTransactions = this.transactions
            .filter((transaction) => {
              if (
                filterUser !== "" &&
                !(
                  transaction["user"]
                    .toLowerCase()
                    .indexOf(filterUser.toLowerCase()) > -1
                )
              ) {
                return false;
              }
              if (
                filterCompany !== "" &&
                !(
                  transaction["company"]
                    .toLowerCase()
                    .indexOf(filterCompany.toLowerCase()) > -1
                )
              ) {
                return false;
              }
              if (
                filterBooking !== "" &&
                !(
                  transaction["booked"]
                    .toLowerCase()
                    .indexOf(filterBooking.toLowerCase()) > -1
                )
              ) {
                return false;
              }
              return true;
            })
            .map((element) => {
              const price = element.amount;

              return {
                [headers[0]]: element.department,
                [headers[1]]: element.user,
                [headers[2]]:
                  element.type?.toLowerCase() === "payment"
                    ? resp["company.transaction.filters.type-payment"]
                    : element.type?.toLowerCase() === "pending"
                    ? resp["company.transaction.filters.type-pending"]
                    : resp["company.transaction.filters.type-refund"],
                [headers[3]]:
                  element.booked === ""
                    ? resp["company.transaction.filters.no-booked-create"]
                    : element.booked === "flight"
                    ? resp["company.transaction.flight"]
                    : element.booked === "train"
                    ? resp["company.transaction.train"]
                    : element.booked === "expense"
                    ? resp["expenses.title"]
                    : element.booked === "car"
                    ? resp["common.car"]
                    : element.booked === "room"
                    ? resp["common.room"]
                    : element.booked,
                [headers[4]]: element.code,
                [headers[5]]: moment(element.createdAt).format("DD/MM/YYYY"),
                [headers[6]]: price.toFixed(2).replace(".", ","),
              };
            });
          this.excelService.exportAsExcelFile(
            arrayTransactions,
            "Vyoo-Report-Transactions",
            true
          );
        })
    );
  }
  exportExcel(service: string) {
    let bookingsExcel = this.companyBookeds;
    let flightsExcel = this.flights;
    let trainsExcel = this.trains;
    let carsExcel = this.cars;
    let expensesExcel = this.expenses;
    let travelsExcel = this.travels;
    const fromDate = this.utilsService.userBookingsFilters.fromDate;
    const toDate = this.utilsService.userBookingsFilters.toDate;
    const createdFromDate =
      this.utilsService.userBookingsFilters.createdFromDate;
    const createdToDate = this.utilsService.userBookingsFilters.createdToDate;
    if (fromDate && toDate) {
      bookingsExcel = bookingsExcel.filter((element) => {
        return (
          moment(new Date(element.checkIn)).isSameOrAfter(
            moment(new Date(fromDate.value))
          ) &&
          moment(new Date(element.checkOut)).isSameOrBefore(
            moment(new Date(toDate.value))
          )
        );
      });
      flightsExcel = flightsExcel.filter((element) => {
        if (element.returnDate) {
          return (
            moment(new Date(element.departDate)).isSameOrAfter(
              moment(new Date(fromDate.value))
            ) &&
            moment(new Date(element.returnDate)).isSameOrBefore(
              moment(new Date(toDate.value))
            )
          );
        } else {
          return moment(new Date(element.departDate)).isSameOrAfter(
            moment(new Date(fromDate.value))
          );
        }
      });
      trainsExcel = trainsExcel.filter((element) => {
        if (element.returnDate) {
          return (
            moment(new Date(element.departDate)).isSameOrAfter(
              moment(new Date(fromDate.value))
            ) &&
            moment(new Date(element.returnDate)).isSameOrBefore(
              moment(new Date(toDate.value))
            )
          );
        } else {
          return moment(new Date(element.departDate)).isSameOrAfter(
            moment(new Date(fromDate.value))
          );
        }
      });
      carsExcel = carsExcel.filter((element) => {
        if (element.returnDate) {
          return (
            moment(new Date(element.departDate)).isSameOrAfter(
              moment(new Date(fromDate.value))
            ) &&
            moment(new Date(element.returnDate)).isSameOrBefore(
              moment(new Date(toDate.value))
            )
          );
        } else {
          return moment(new Date(element.departDate)).isSameOrAfter(
            moment(new Date(fromDate.value))
          );
        }
      });
      expensesExcel = expensesExcel.filter((element) => {
        if (element.returnDate) {
          return (
            moment(new Date(element.beginDate)).isSameOrAfter(
              moment(new Date(fromDate.value))
            ) &&
            moment(new Date(element.endDate)).isSameOrBefore(
              moment(new Date(toDate.value))
            )
          );
        } else if (element.beginDate) {
          return moment(new Date(element.beginDate)).isSameOrAfter(
            moment(new Date(fromDate.value))
          );
        } else {
          return true;
        }
      });
      travelsExcel = travelsExcel.filter((element) => {
        if (element.returnDate) {
          return (
            moment(new Date(element.initDate)).isSameOrAfter(
              moment(new Date(fromDate.value))
            ) &&
            moment(new Date(element.endDate)).isSameOrBefore(
              moment(new Date(toDate.value))
            )
          );
        } else {
          return moment(new Date(element.initDate)).isSameOrAfter(
            moment(new Date(fromDate.value))
          );
        }
      });
    }
    if (createdFromDate && createdToDate) {
      bookingsExcel = bookingsExcel.filter((element) =>
        moment(new Date(element.createdAt)).isBetween(
          moment(new Date(createdFromDate.value)),
          moment(new Date(createdToDate.value)),
          "day",
          "[]"
        )
      );
      flightsExcel = flightsExcel.filter((element) =>
        moment(new Date(element.createdAt)).isBetween(
          moment(new Date(createdFromDate.value)),
          moment(new Date(createdToDate.value)),
          "day",
          "[]"
        )
      );
      trainsExcel = trainsExcel.filter((element) =>
        moment(new Date(element.createdAt)).isBetween(
          moment(new Date(createdFromDate.value)),
          moment(new Date(createdToDate.value)),
          "day",
          "[]"
        )
      );
      carsExcel = carsExcel.filter((element) =>
        moment(new Date(element.createdAt)).isBetween(
          moment(new Date(createdFromDate.value)),
          moment(new Date(createdToDate.value)),
          "day",
          "[]"
        )
      );
      expensesExcel = expensesExcel.filter((element) =>
        moment(new Date(element.createdAt)).isBetween(
          moment(new Date(createdFromDate.value)),
          moment(new Date(createdToDate.value)),
          "day",
          "[]"
        )
      );
      travelsExcel = travelsExcel.filter((element) =>
        moment(new Date(element.createdAt)).isBetween(
          moment(new Date(createdFromDate.value)),
          moment(new Date(createdToDate.value)),
          "day",
          "[]"
        )
      );
    }
    switch (service) {
      case "all":
        const totalBookings = {
          bookings: bookingsExcel,
          flights: flightsExcel,
          trains: trainsExcel,
          expenses: expensesExcel,
          cars: carsExcel,
        };
        this.excelService.createBookingsExcel("Admin", totalBookings);

        break;
      case "hotel":
        this.excelService.createBookingsExcel("Vyoo-Hotel Report", {
          bookings: bookingsExcel,
        });
        break;
      case "flight":
        this.excelService.createBookingsExcel("Vyoo-Flight Report", {
          flights: flightsExcel,
        });
        break;
      case "train":
        this.excelService.createBookingsExcel("Vyoo-Train Report", {
          trains: trainsExcel,
        });
        break;
      case "expense":
        this.excelService.createBookingsExcel("Vyoo-Expense Report", {
          expenses: expensesExcel,
        });
        break;
      case "travel":
        this.excelService.createProjectsExcel(
          "Vyoo-Project Report",
          travelsExcel,
          true,
          null
        );
        break;
      case "car":
        this.excelService.createBookingsExcel("Vyoo-Car Report", {
          cars: carsExcel,
        });
        break;
      default:
        break;
    }
  }

  onUploadBill() {
    const modalRef = this.modalService.open(UploadBill, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.companyUuid = this.uuidCompany;
    modalRef.componentInstance.sendInfo.subscribe((billInfo) => {
      modalRef.componentInstance.loading = true;
      const formData = new FormData();
      formData.append("file", billInfo.file, billInfo.file.name);
      formData.append("date", billInfo.date);
      // TODO ver este parámetro
      formData.append("companyUuid", this.uuidCompany);
      formData.append("reference", billInfo.reference);

      this.companyService.postBill(formData).subscribe(
        (result) => {
          modalRef.componentInstance.loading = false;
          modalRef.dismiss("Send Info");
          this.utilsService.triggerReload();
          this.translate
            .get(["common.congrat", "company.bills.upload-bill-success"])
            .subscribe((resp) => {
              this.ngxToastrService.typeSuccess(
                resp["common.congrat"],
                resp["company.bills.upload-bill-success"]
              );
            });
        },
        (err) => {
          modalRef.componentInstance.loading = false;
          this.translate
            .get("company.bills.upload-bill-error")
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.message);
            });
        }
      );
    });
  }

  // Tabs
  showPage(page: string) {
    this.currentPage = page;
  }
}
