import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService, TokenService } from "@services";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private router: Router,
    public translate: TranslateService,
    private langService: LanguageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {
      Accept: "application/json",
    };

    if (
      !(
        (req.url.endsWith("/flight") ||
          req.url.includes("flight/upload") ||
          req.url.endsWith("/bills/") ||
          req.url.endsWith("/train") ||
          !req.url.includes("/signup")) &&
        req.method === "POST"
      )
    ) {
      headersConfig["Content-Type"] = "application/json";
    }

    const token = this.tokenService.getToken();
    const language = this.langService.getLanguage();
    if (
      token &&
      !req.url.startsWith("https://places") &&
      !req.url.startsWith("https://geocode")
    ) {
      headersConfig["Authorization"] = `JWT ${token}`;
      headersConfig["Accept-Language"] = language;
    }
    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            } else {
              this.tokenService.destroyToken();
              this.router.navigate(["login"]);
            }
          }
        }
      )
    );
  }
}
