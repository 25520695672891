<div class="custom-dialog-container">
  <h2>{{ title | translate }}</h2>
  <h4 class="semi-bold">{{ subtitle | translate }}</h4>
  <div class="button-container">
    <button class="button-cancel semi-bold" (click)="cancel()">
      {{ cancelText | translate }}
    </button>
    <button class="button-accept semi-bold" (click)="confirm()">
      {{ confirmText | translate }}
    </button>
  </div>
</div>
