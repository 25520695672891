import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService, LanguageService, NgxToastrService } from "@core/services";
import { TranslateService } from "@ngx-translate/core";
import { UtilsService } from "@services";
import * as _lodash from "lodash-es";
import countryJson from "../../../../../assets/utils/amadeus/countries.json";

@Component({
  selector: "app-company-external-signup",
  templateUrl: "./company-external-signup.component.html",
  styleUrls: ["./company-external-signup.component.scss"],
})
export class CompanyExternalSignUpComponent implements OnInit, OnDestroy {
  planNumber = 0;
  language: string;
  loading: Boolean;
  formGeneral: UntypedFormGroup;
  imageError: string;
  cardImageBase64: string;
  isImageSaved: boolean;
  profileImage: any;
  previewImagePath: string;
  formBilling: UntypedFormGroup;
  countriesList = countryJson;
  hasAcceptConditions = false;
  userForm: UntypedFormGroup;
  isCompanyCreated = false;
  company: any;
  genderOptions = [
    { value: "male", text: "common.male" },
    { value: "female", text: "common.female" },
  ];
  residentOptions = [
    { value: "none", text: "Ninguno" },
    { value: "CN", text: "Canarias" },
    { value: "CE", text: "Ceuta" },
    { value: "BL", text: "Baleares" },
    { value: "ML", text: "Melilla" },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private langService: LanguageService,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private utilsService: UtilsService,
    private ngxToastrService: NgxToastrService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.language = this.langService.getLanguage();
    this.planNumber = this.activatedRoute.snapshot.queryParams.plan;
    this.formGeneral = this.formBuilder.group({
      agencyUuid: [null],
      image: [null],
      name: ["", [Validators.required]],
      cif: ["", [Validators.required, Validators.pattern("[a-zA-Z0-9]*")]],
      phone: ["", [Validators.required, Validators.pattern("[- +()0-9]+")]],
      email: ["", [Validators.required, Validators.email]],
      address: ["", [Validators.required]],
      city: ["", [Validators.required, Validators.maxLength(30)]],
      province: ["", [Validators.required, Validators.maxLength(30)]],
      postalCode: ["", [Validators.required, Validators.maxLength(100)]],
      country: ["", [Validators.required, Validators.maxLength(30)]],
      suppliers: [],
    });
    this.formBilling = this.formBuilder.group({
      identificator: ["", [Validators.required, Validators.maxLength(30)]],
      businessName: ["", [Validators.required, Validators.maxLength(100)]],
      address: ["", [Validators.required]],
      town: ["", [Validators.required, Validators.maxLength(30)]],
      province: ["", [Validators.required, Validators.maxLength(30)]],
      postalCode: ["", [Validators.required, Validators.maxLength(100)]],
      country: ["", [Validators.required, Validators.maxLength(30)]],
    });

    let hasValidatorsBilling = false;
    this.formBilling.valueChanges.subscribe(() => {
      if (!hasValidatorsBilling && this.isEmpty()) {
        hasValidatorsBilling = true;
        this.setValidators(Validators.required);
        this.updateValueAndValidity();
      } else if (hasValidatorsBilling && !this.isEmpty()) {
        hasValidatorsBilling = false;
        this.setValidators();
        this.updateValueAndValidity();
      }
    });

    this.userForm = this.formBuilder.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
        ],
      ],
      lastname: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
        ],
      ],
      dni: ["", [Validators.required]],
      dniIssuanceDate: [null],
      dniExpirationDate: [null],
      passportIssuanceDate: [null],
      passport: [""],
      passportExpires: [null],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", [Validators.required, Validators.pattern("[- +()0-9]+")]],
      birthdate: [null, [Validators.required]],
      gender: ["", [Validators.required]],
      address: [""],
      city: [""],
      province: [""],
      postalCode: [""],
      nationality: ["", [Validators.required]],
      isGroupAdmin: [false, [Validators.required]],
      isManager: [true, [Validators.required]],
    });
  }

  get uf() {
    return this.userForm.controls;
  }

  onSubmitCompany() {
    const parameters: any = [];
    parameters["general"] = this.formGeneral.getRawValue();
    parameters["general"]["image"] = this.profileImage;
    parameters["billing"] = this.formBilling.getRawValue();
    parameters["general"].agencyUuid =
      parameters["general"].agencyUuid === ""
        ? null
        : parameters["general"].agencyUuid;

    if (parameters) {
      const attributes = { ...parameters.general };
      if (
        _lodash
          .values(parameters.billing)
          .every((value) => !_lodash.isEmpty(value))
      ) {
        attributes["billingData"] = parameters.billing;
      }
      delete attributes.suppliers;
      delete attributes.agencyUuid;
      const image = attributes.image;
      delete attributes.image;
      let formData = new FormData();
      formData = this.utilsService.toFormData(attributes, formData);
      if (image && image.name) {
        formData.append("file", image, image.name);
      }
      this.authService.signupCompany(formData, "0").then(
        async (res) => {
          this.company = await res;
          this.isCompanyCreated = true;
          this.cardImageBase64 = null;
          this.isImageSaved = false;
          this.profileImage = null;
        },
        (err) => {
          this.translate
            .get("company.company-homepage.error-create-company")
            .subscribe((result) => {
              this.ngxToastrService.typeError(result, err.error.message);
            });
        }
      );
    }
  }

  onSubmitUser() {
    // const image = this.profileImage;
    const formData = new FormData();

    const userInfo: any = {
      name: this.uf.name.value,
      lastname: this.uf.lastname.value,
      email: this.uf.email.value,
      dni: this.uf.dni.value,
      dniIssuanceDate:
        this.uf.dniIssuanceDate.value && this.uf.dniIssuanceDate.value !== ""
          ? this.uf.dniIssuanceDate.value.year +
          "-" +
          this.uf.dniIssuanceDate.value.month +
          "-" +
          this.uf.dniIssuanceDate.value.day
          : "",
      dniExpirationDate:
        this.uf.dniExpirationDate.value &&
          this.uf.dniExpirationDate.value !== ""
          ? this.uf.dniExpirationDate.value.year +
          "-" +
          this.uf.dniExpirationDate.value.month +
          "-" +
          this.uf.dniExpirationDate.value.day
          : "",
      passport: this.uf.passport.value,
      passportIssuanceDate:
        this.uf.passportIssuanceDate.value &&
          this.uf.passportIssuanceDate.value !== ""
          ? this.uf.passportIssuanceDate.value.year +
          "-" +
          this.uf.passportIssuanceDate.value.month +
          "-" +
          this.uf.passportIssuanceDate.value.day
          : "",
      passportExpires:
        this.uf.passportExpires.value && this.uf.passportExpires.value !== ""
          ? this.uf.passportExpires.value.year +
          "-" +
          this.uf.passportExpires.value.month +
          "-" +
          this.uf.passportExpires.value.day
          : "",
      phone: this.uf.phone.value,
      gender: this.uf.gender.value,
      resident: this.uf.resident && this.uf.resident.value ? this.uf.resident.value : "none",
      country: this.uf.country && this.uf.country.value? this.uf.country.value : "",
      nationality: this.uf.nationality && this.uf.nationality.value ? this.uf.nationality.value : "",
      currentCountry: this.uf.currentCountry && this.uf.currentCountry.value
        ? this.uf.currentCountry.value
        : "",
      passportCountryEmitter: this.uf.passportCountryEmitter && this.uf.passportCountryEmitter.value
        ? this.uf.passportCountryEmitter.value
        : "",
      birthdate:
        this.uf.birthdate && this.uf.birthdate.value !== ""
          ? this.uf.birthdate.value.year +
          "-" +
          this.uf.birthdate.value.month +
          "-" +
          this.uf.birthdate.value.day
          : "",
      employeeNumber: this.uf.employeeNumber && this.uf.employeeNumber.value
        ? this.uf.employeeNumber.value.toString()
        : "",
      isGroupAdmin: true,
    };
    _lodash.get(this.uf, "city.value", "") === null
      ? (userInfo.city = "")
      : (userInfo.city = _lodash.get(this.uf, "city.value"));
    _lodash.get(this.uf, "province.value", "") === null
      ? (userInfo.province = "")
      : (userInfo.province = _lodash.get(this.uf, "province.value"));
    _lodash.get(this.uf, "city.postalCode", "") === null
      ? (userInfo.postalCode = "")
      : (userInfo.postalCode = _lodash.get(this.uf, "postalCode.value"));
    _lodash.get(this.uf, "address.value", "") === null
      ? (userInfo.address = "")
      : (userInfo.address = _lodash.get(this.uf, "address.value"));
    _lodash.get(this.uf, "postalCode.value", "") === null
      ? (userInfo.postalCode = "")
      : (userInfo.postalCode = _lodash.get(this.uf, "postalCode.value"));
    userInfo["accessType"] = "company";
    userInfo.companyUuid = this.company.uuid;
    userInfo["accessType"] = "company";
    if(userInfo.group && userInfo.group.uuid){
      userInfo["groupRestrictionUuid"] = userInfo.group.uuid;
    }
    userInfo["companyUuid"] = this.company.uuid;
    delete userInfo["group"];
    for (const property in userInfo) {
      if (userInfo[property] !== null) {
        formData.append(`${property}`, `${userInfo[property]}`);
      }
    }

    if (this.profileImage && this.profileImage.name) {
      formData.append("file", this.profileImage, this.profileImage.name);
    }
    this.authService.postCompanyAdmin(formData).then((res) => {
      this.router.navigate(["/login"]);
    });
  }

  changeLanguage(language: string) {
    this.language = language;
    this.translate.use(language);
    this.langService.saveLanguage(language);
  }

  onImageChange(event) {
    this.imageError = null;
    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2000000;
      const allowed_types = ["image/png", "image/jpeg"];
      const max_height = 15200;
      const max_width = 25600;
      if (event.target.files[0].size > max_size) {
        this.imageError =
          "Maximum size allowed is " + max_size / 1000000 + "Mb";

        return false;
      }

      if (!_lodash.includes(allowed_types, event.target.files[0].type)) {
        this.imageError = "Only Images are allowed ( JPG | PNG )";
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget["height"];
          const img_width = rs.currentTarget["width"];

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              "Maximum dimentions allowed " +
              max_height +
              "*" +
              max_width +
              "px";
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.profileImage = event.target.files[0];
            this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  isEmpty() {
    const formValues = this.formBilling.getRawValue();
    return _lodash.values(formValues).some((value) => value !== "");
  }

  setValidators(validator?) {
    const v = validator ? validator : null;
    this.formBilling.get("identificator").setValidators(v);
    this.formBilling.get("address").setValidators(v);
    this.formBilling.get("businessName").setValidators(v);
    this.formBilling.get("town").setValidators(v);
    this.formBilling.get("province").setValidators(v);
    this.formBilling.get("postalCode").setValidators(v);
    this.formBilling.get("country").setValidators(v);
  }

  updateValueAndValidity() {
    this.formBilling.get("identificator").updateValueAndValidity();
    this.formBilling.get("address").updateValueAndValidity();
    this.formBilling.get("businessName").updateValueAndValidity();
    this.formBilling.get("town").updateValueAndValidity();
    this.formBilling.get("province").updateValueAndValidity();
    this.formBilling.get("postalCode").updateValueAndValidity();
    this.formBilling.get("country").updateValueAndValidity();
  }

  copyData(event) {
    if (event.checked) {
      this.formBilling.setControl(
        "identificator",
        new UntypedFormControl(this.formGeneral.get("cif")).value
      );
      this.formBilling.setControl(
        "address",
        new UntypedFormControl(this.formGeneral.get("address")).value
      );
      this.formBilling.setControl(
        "town",
        new UntypedFormControl(this.formGeneral.get("city")).value
      );
      this.formBilling.setControl(
        "province",
        new UntypedFormControl(this.formGeneral.get("province")).value
      );
      this.formBilling.setControl(
        "postalCode",
        new UntypedFormControl(this.formGeneral.get("postalCode")).value
      );
      this.formBilling.setControl(
        "country",
        new UntypedFormControl(this.formGeneral.get("country")).value
      );
    } else {
      this.formBilling.setControl("identificator", new UntypedFormControl(""));
      this.formBilling.setControl("address", new UntypedFormControl(""));
      this.formBilling.setControl("town", new UntypedFormControl(""));
      this.formBilling.setControl("province", new UntypedFormControl(""));
      this.formBilling.setControl("postalCode", new UntypedFormControl(""));
      this.formBilling.setControl("country", new UntypedFormControl(""));
    }
  }

  acceptConditions(event) {
    this.hasAcceptConditions = event.checked;
  }

  changePhoto() {
    document.getElementById("upload-photo").click();
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.profileImage = null;
  }

  ngOnDestroy() { }
}
