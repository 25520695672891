import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { HubService } from "@core/services";
import {
  AuthAgencyGuard,
  AuthCompanyGuard,
  AuthEmployeeCompanyGuard,
  AuthEmployeeGuard,
  AuthenticatedGuard,
  AuthRestrictedCompanyGuard,
  AuthUserGuard,
  CanActiveSearchCompanyGuard,
} from "@guards";
import { TranslateModule } from "@ngx-translate/core";
import {
  AgencyService,
  AuthService,
  BookedService,
  CompanyPlanService,
  CompanyService,
  ConfigService,
  ExcelService,
  ExpensesService,
  FlightService,
  ForgotService,
  GoogleMaps,
  GraphService,
  GroupService,
  HelpService,
  LanguageService,
  NgxToastrService,
  RequestService,
  SearchService,
  StateService,
  TokenService,
  TrainService,
  TravelService,
  UserService,
  UtilsService,
} from "@services";
import { AuthHubGuard } from "./guards/auth-hub.guard";
import { AirbnbService } from "./services/airbnb.service";
import { TransactionService } from "./services/transaction.service";

@NgModule({
  declarations: [],
  imports: [HttpClientModule, TranslateModule.forChild(), CommonModule],
  providers: [
    // Services
    AirbnbService,
    AgencyService,
    AuthService,
    BookedService,
    CompanyService,
    ConfigService,
    ExcelService,
    ExpensesService,
    FlightService,
    ForgotService,
    GoogleMaps,
    GraphService,
    GroupService,
    HelpService,
    HubService,
    LanguageService,
    NgxToastrService,
    SearchService,
    StateService,
    TokenService,
    TrainService,
    TransactionService,
    TravelService,
    UserService,
    UtilsService,
    CompanyPlanService,
    RequestService,
    // Guards
    AuthAgencyGuard,
    AuthCompanyGuard,
    AuthEmployeeGuard,
    AuthEmployeeCompanyGuard,
    AuthHubGuard,
    AuthUserGuard,
    AuthenticatedGuard,
    CanActiveSearchCompanyGuard,
    AuthRestrictedCompanyGuard,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        "CoreModule is already loaded. Import it in the AppModule only"
      );
    }
  }
}
