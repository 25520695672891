import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TravelService {
  constructor(private http: HttpClient) {}

  getTravels(uuidUser?: string) {
    const params = {};
    if (uuidUser) {
      params["userUuid"] = uuidUser;
    }
    return this.http
      .get<any>(`${environment.baseUrlApi}/travel`, { params: params })
      .pipe(map((res: any) => res));
  }

  getTravelsWithParams(query: any) {
    return this.http
      .get<any>(`${environment.baseUrlApi}/travel`, { params: query })
      .pipe(map((res: any) => res));
  }

  getTravel(travelUuid: string) {
    return this.http
      .get<any>(`${environment.baseUrlApi}/travel/${travelUuid}`)
      .pipe(map((res: any) => res));
  }

  getTravelBonus(travelUuid: string) {
    return this.http
      .get<any>(`${environment.baseUrlApi}/travel/${travelUuid}/bonus`)
      .pipe(map((res: any) => res));
  }

  postTravel(data: any) {
    return this.http
      .post<any>(`${environment.baseUrlApi}/travel`, data)
      .pipe(map((res: any) => res));
  }

  putTravel(data: any, travelUuid: string, userUuid?: string) {
    const params = userUuid ? { userUuid } : {};
    return this.http
      .put<any>(`${environment.baseUrlApi}/travel/edit/${travelUuid}`, data, {
        params: params,
      })
      .pipe(map((res: any) => res));
  }

  addItemTravel(data: any, travelUuid: string) {
    return this.http
      .put<any>(`${environment.baseUrlApi}/travel/${travelUuid}/addItem`, data)
      .pipe(map((res: any) => res));
  }

  removeItemTravel(data: any, travelUuid: string) {
    return this.http
      .put<any>(
        `${environment.baseUrlApi}/travel/${travelUuid}/removeItem`,
        data
      )
      .pipe(map((res: any) => res));
  }

  deleteTravel(travelUuid: string) {
    return this.http
      .delete<any>(`${environment.baseUrlApi}/travel/${travelUuid}`)
      .pipe(map((res: any) => res));
  }
}
