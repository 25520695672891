import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CanNavigateGuard } from "@core/guards/can-navigate.guard";
import { PlanResolveService } from "@core/guards/PlanResolveService";
import { SearchHomepageComponent } from "./search-homepage/search-homepage.component";
import { SearchSeeBookedComponent } from "./search-see-booked/search-see-booked.component";

const routes: Routes = [
  {
    path: "",
    component: SearchHomepageComponent,
    resolve: {
      plans: PlanResolveService,
    },
    canActivate: [CanNavigateGuard],
  },
  {
    path: "hotels/:code",
    component: SearchSeeBookedComponent,
    resolve: {
      plans: PlanResolveService,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [PlanResolveService, CanNavigateGuard],
})
export class SearchRoutingModule {}
