import { Role } from "./role";

export class Employee {
  uuid: string;
  type: "EMPLOYEE";
  email: string;
  name: string;
  role: Role;
  accessType: "employee"
}
