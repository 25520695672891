import { Location } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NavigationExtras, Router } from "@angular/router";
import { User } from "@core/models";
import { NgxToastrService, UtilsService } from "@core/services";
import { NgbDateParserFormatter, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { CarService, TravelUtilsService, UserService } from "@services";
import { CustomDateParserFormatter } from "@shared/component/date-parser";
import { AppState } from "app/app.reducer";
import cities from "assets/utils/cities.json";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
@Component({
  selector: "app-car-request",
  templateUrl: "./car-request.component.html",
  styleUrls: ["./car-request.component.scss"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class CarRequestComponent implements OnInit, OnDestroy {
  public get ngbDateParser(): NgbDateParserFormatter {
    return this._ngbDateParser;
  }
  public set ngbDateParser(value: NgbDateParserFormatter) {
    this._ngbDateParser = value;
  }
  @Input() isModal?;
  @Input() userUuid?;
  @Input() companyUuid?;
  @Output() closeEvent? = new EventEmitter();
  subscriptions: Subscription[] = [];
  canBookHotel: boolean;
  canRequestCustomFlight: boolean;
  canRequestCustomTrain: boolean;
  canRequestCustomCar: boolean;
  canRequestAirbnb: boolean;
  loading = false;
  user: User;
  authUserUuid = "";
  userType: string;
  form: UntypedFormGroup;
  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };

  city = "";
  citiesList = [];
  carType: any[] = [];

  titleSelect = "";
  pickupOptions = [];
  translationsDone = false;
  userImageBase64: string;
  isAdmin = false;
  isEmployee = false;
  canUseTRM: boolean;
  userToBook: string;
  bookedUserAccessType: string;
  showHoursDeparture = false;
  showHoursReturn = false;
  isInvalid: boolean = false

  constructor(
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    private _ngbDateParser: NgbDateParserFormatter,
    private utilsService: UtilsService,
    private router: Router,
    private modalService: NgbModal,
    private carService: CarService,
    private store: Store<AppState>,
    private travelUtilsService: TravelUtilsService,
    private userService: UserService,
    private location: Location
  ) {
    this.subscriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() !== "USER") {
          this.isAdmin = true;
          if (s.user.type?.toUpperCase() === "EMPLOYEE") {
            this.isEmployee = true;
          }
          this.userToBook = localStorage.getItem("userToBook")
            ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
            : null;
          this.canUseTRM = s.user.type
            ? s.user.plan && s.user.plan.permissions.canUseTRM
            : s.user.user.plan && s.user.user.plan.permissions.canUseTRM;
        }
        this.userType = s.user.type
        this.authUserUuid = s.user.uuid;
      })
    );

    this.subscriptions.push(
      this.translate
        .get([
          "car.custom.request.carType.suv",
          "car.custom.request.carType.touring",
          "car.custom.request.carType.van",
          "car.custom.request.pickup.title",
          "car.custom.request.pickup.airport",
          "car.custom.request.pickup.trainStation",
          "car.custom.request.pickup.office",
          "car.custom.request.carType.touring",
          "car.custom.request.carType.suv",
          "car.custom.request.carType.van",
        ])
        .subscribe((result) => {
          this.pickupOptions = [
            {
              value: "airport",
              viewValue: result["car.custom.request.pickup.airport"],
            },
            {
              value: "trainStation",
              viewValue: result["car.custom.request.pickup.trainStation"],
            },
            {
              value: "office",
              viewValue: result["car.custom.request.pickup.office"],
            },
          ];

          this.carType = [
            {
              value: "suv",
              viewValue: result["car.custom.request.carType.suv"],
            },
            {
              value: "touring",
              viewValue: result["car.custom.request.carType.touring"],
            },
            {
              value: "van",
              viewValue: result["car.custom.request.carType.van"],
            },
          ];

          this.titleSelect = result["car.custom.request.pickup.title"];

          this.translationsDone = true;
        })
    );
  }

  ngOnInit(): void {
    if (
      window.history.state.canBookHotel !== null &&
      window.history.state.canRequestCustomCar !== null &&
      window.history.state.canRequestCustomFlight !== null &&
      window.history.state.canRequestCustomTrain !== null &&
      window.history.state.canRequestAirbnb !== null
    ) {
      this.canBookHotel = window.history.state.canBookHotel;
      this.canRequestCustomCar = window.history.state.canRequestCustomCar;
      this.canRequestCustomFlight = window.history.state.canRequestCustomFlight;
      this.canRequestCustomTrain = window.history.state.canRequestCustomTrain;
      this.canRequestAirbnb = window.history.state.canRequestAirbnb;
      this.userUuid = window.history.state.userUuid;
      this.companyUuid = window.history.state.companyUuid;
      this.userImageBase64 = window.history.state.userImageBase64;
    } else {
      this.router.navigate(["/custom-services"]);
    }

    if (
      this.userUuid &&
      (this.userUuid !== null || this.userUuid !== undefined)
    ) {
      this.userService
        .getUser(this.userUuid)
        .pipe(take(1))
        .subscribe((user: User) => {
          this.bookedUserAccessType = user.type;
          this.user = user;
        });
    }

    this.form = this.formBuilder.group({
      from: [null, [Validators.required]],
      to: [null, [Validators.required]],
      pickupPlaceFrom: [null, [Validators.required]],
      pickupPlaceTo: [null, [Validators.required]],
      age: [null, [Validators.required, Validators.min(16)]],
      departDate: [null, [Validators.required]],
      returnDate: [null, [Validators.required]],
      timeReturn: [null, [Validators.required]],
      timeDepart: [null, [Validators.required]],
      carType: [null, [Validators.required]],
      insuranceWithExcess: [false, [Validators.required]],
      comments: [""],
      userUuid: this.userUuid,
      companyUuid: this.companyUuid,
    });
  }

  get f() {
    return this.form.controls;
  }

  closeModalCross() {
    this.modalService.dismissAll();
  }

  // Buscador de ciudades
  getCities(value: string) {
    this.citiesList = [];
    if (value !== "" && value.length > 2) {
      cities.Table1.forEach((v) => {
        if (
          v["name"]?.toLowerCase().includes(value?.toLowerCase()) &&
          !this.citiesList.includes(v["name"])
        ) {
          this.citiesList.push(v["name"]);
        }
      });
    } else {
      this.citiesList = [];
    }
  }

  goBack() {
    this.location.back();
  }

  getOutMinDate() {
    return this.utilsService.getOutDate(
      this.minDateNgStruct,
      this.form.controls.departDate.value
    );
  }

  navigate(route: string) {
    const navigationExtra: NavigationExtras = {
      state: {
        canBookHotel: this.canBookHotel,
        canRequestCustomFlight: this.canRequestCustomFlight,
        canRequestCustomTrain: this.canRequestCustomTrain,
        canRequestCustomCar: this.canRequestCustomCar,
        canRequestAirbnb: this.canRequestAirbnb,
        userUuid: this.userToBook ? this.userToBook : this.authUserUuid,
        companyUuid: this.companyUuid,
        userImageBase64: this.userImageBase64,
      },
    };

    this.router.navigate(["custom-services/" + route], navigationExtra);
  }

  exchangeUbications() {
    const origin = this.form.get("from").value;
    const destination = this.form.get("to").value;
    this.form.get("from").setValue(destination);
    this.form.get("to").setValue(origin);
  }

  cleanCitiesList(option) {
    this.city = option;
    this.citiesList = [];
  }

  onSubmit() {
    this.isInvalid = false
    this.loading = true;
    const body = this.form.getRawValue();
    const travel = this.travelUtilsService.getTravelLocalStorage();
    const dateDepartureAux = moment(
      this.ngbDateParser.format(this.f.departDate.value),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const dateReturnAux = moment(
      this.ngbDateParser.format(this.f.returnDate.value),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    body[
      "departDate"
    ] = `${dateDepartureAux} ${body["timeDepart"].hour}:${body["timeDepart"].minute}:00`;
    body[
      "returnDate"
    ] = `${dateReturnAux} ${body["timeReturn"].hour}:${body["timeReturn"].minute}:00`;
    if (travel && travel.title) {
      body["travelName"] = travel.title;
    }

    if (this.user) {
      body['traveller'] = {name: this.user.name, surname: this.user.lastname};
    }
    delete body.timeDepart;
    delete body.timeReturn;
    body["userUuid"] = this.userUuid;
    this.subscriptions.push(
      this.carService.sendCustomCarRequest(body).subscribe(
        (s) => {
          this.subscriptions.push(
            this.translate
              .get(["common.congrat", "car.custom.request.success"])
              .subscribe((result) => {
                this.loading = false;
                this.ngxToastrService.typeSuccess(
                  result["common.congrat"],
                  result["car.custom.request.success"]
                );
                if (this.isModal) {
                  this.closeEvent.emit(s);
                } else {
                  if(this.userType?.toUpperCase() === 'USER') {
                    this.router.navigateByUrl("my-bookeds");
                  } else { 
                    this.router.navigateByUrl(`/user/booked/${this.user.uuid}`)
                  }
                }
              })
          );
        },
        (err) => {
          this.translate.get("common.error").subscribe((result) => {
            this.loading = false;
            this.ngxToastrService.typeInfo(result, err.error.message);
          });
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
