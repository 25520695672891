<app-user-detail
  *ngIf="userType !== 'USER'"
  [userUuid]="userToBook"
></app-user-detail>

<div class="container">
  <span class="component-info heading-5">{{
    "hotel.custom.lets-search" | translate
  }}</span>

  <div class="bg-card">
    <div class="service-card-container">
      <div class="selector">
        <span class="selector-item-selected" *ngIf="canBookHotel || isAdmin">{{
          "common.hotels" | translate
        }}</span>
        <span
          class="selector-item"
          (click)="navigate('flight')"
          *ngIf="canRequestCustomFlight || isAdmin"
          >{{ "common.flights" | translate }}</span
        >
        <span
          class="selector-item"
          (click)="navigate('train')"
          *ngIf="canRequestCustomTrain || isAdmin"
          >{{ "common.trains" | translate }}</span
        >
        <span
          class="selector-item"
          (click)="navigate('car')"
          *ngIf="canRequestCustomCar || isAdmin"
          >{{ "common.cars" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestAirbnb || isAdmin"
          (click)="navigate('airbnb')"
          >{{ "airbnb.custom.request.option" | translate }}</span
        >
      </div>

      <form
        [formGroup]="form"
        [ngClass]="{ 'loading-background': loading }"
        (ngSubmit)="form.valid && !loading ? onSubmit() : (isInvalid = true)"
      >
        <div class="row" *ngIf="isModal && !userUuid">
          <div class="col-12">
            <div class="form-group">
              <mat-label
                >{{
                  type + ".create-" + type + ".choose-employee" | translate
                }}*</mat-label
              >
              <select
                class="form-control rounded-1 w-25"
                formControlName="userUuid"
              >
                <option
                  *ngFor="
                    let user of users;
                    trackBy: utilsService.getUuidTracking
                  "
                  [ngValue]="user"
                >
                  {{ user.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-row">
          <input
            formControlName="city"
            type="text"
            id="destination"
            class="ubication-input form-control form-control--no-border"
            matInput
            [matAutocomplete]="city"
            (input)="getCities($event.target.value)"
            [ngClass]="{
              'input-error': !form.controls.city.valid && isInvalid
            }"
            placeholder="{{ 'hotel.custom.city' | translate }}"
          />
          <mat-autocomplete
            #city="matAutocomplete"
            panelWidth="20%"
            (optionSelected)="cleanCitiesList($event.option.value)"
          >
            <mat-option *ngFor="let option of citiesList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
          <input
            formControlName="hotel"
            [matAutocomplete]="hotel"
            (input)="getHotels($event.target.value)"
            type="text"
            id="origin"
            class="hotel-name form-control form-control--no-border"
            [ngClass]="{
              'input-error': !form.controls.hotel.valid && isInvalid
            }"
            placeholder="{{ 'hotel.custom.hotel-name' | translate }}"
          />
          <mat-autocomplete
            #hotel="matAutocomplete"
            panelWidth="20%"
            (optionSelected)="cleanHotelsList()"
          >
            <mat-option *ngFor="let option of hotelsList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="form-row">
          <div
            class="hotel-date-field form-control form-control--no-border"
            [formGroup]="form"
            [ngClass]="{
              'input-error':
                (!form.controls.checkIn.valid ||
                  !form.controls.checkOut.valid) &&
                isInvalid
            }"
          >
            <input
              #bookedRange
              class="date-input"
              (dateSelect)="onDateSelection($event)"
              placeholder="{{ 'hotel.custom.select-date' | translate }}"
              #dp
              ngbDatepicker
              [dayTemplate]="t1"
              [autoClose]="'outside'"
              [displayMonths]="1"
              #d1="ngbDatepicker"
              readonly
              (click)="d1.toggle()"
              [minDate]="minDateNgStruct"
              [maxDate]="maxDateNgStruct"
            />
            <ng-template #t1 let-date let-focused="focused">
              <span
                class="custom-day"
                [ngClass]="{
                  activeFrom: form.controls['checkIn'].value === date,
                  activeTo: form.controls['checkOut'].value === date
                }"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>

          <div class="hotel-rooms-field">
            <input
              type="number"
              min="1"
              max="99"
              name="rooms"
              class="rooms-input"
              ngModel
            />{{ "hotel.custom.rooms" | translate }}
          </div>

          <div class="hotel-people-field">
            <input
              type="number"
              min="1"
              max="99"
              name="people"
              class="people-input"
              ngModel
            />{{ "hotel.custom.people" | translate }}
          </div>
        </div>

        <div class="form-row">
          <div class="annotations">
            <mat-label class="annotations-label">{{
              "hotel.custom.annotations" | translate
            }}</mat-label>
            <textarea
              class="annotation-textarea form-control form-control--no-border"
              formControlName="comments"
              placeholder="{{
                'hotel.custom.annotations-placeholder' | translate
              }}"
            ></textarea>
          </div>
        </div>

        <div class="button-row">
          <button
            [ngStyle]="{
              cursor: !form.valid || loading ? 'not-allowed' : 'pointer'
            }"
            [ngClass]="{
              'custom-btn disabled-button': !form.valid || loading, 'custom-btn': form.valid || !loading
            }"
          >
            {{ "common.send-request" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
