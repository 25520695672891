import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { Company } from "@core/models";
import { CompanyService } from "@core/services";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { PaginationConfig } from "@shared/interfaces";
import { AppState } from "app/app.reducer";
import { Subscription } from "rxjs";

@Component({
  selector: "app-stats",
  templateUrl: "./stats.component.html",
  styleUrls: ["./stats.component.scss"],
})
export class StatsComponent implements OnInit {
  subcriptions: Subscription[] = [];
  userType: string;
  author: any;
  companies: Company[] = [];
  stats: any[] = [];
  lowValue = 0;
  highValue = 10;
  // Filters
  form: UntypedFormGroup;
  statusOptions: { value: boolean; text: string }[] = [];
  deletedOptions: { value: boolean; text: string }[] = [];
  filters: any = {};

  // Pagination
  paginationConf: PaginationConfig = {
    page: 1,
    pageSize: 10,
    collectionSize: 120,
  };

  constructor(
    private store: Store<AppState>,
    private companyService: CompanyService,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService
  ) {
    this.subcriptions.push(
      this.store.select("auth").subscribe((s) => {
        this.userType = s.user.type;
        this.author = s.user;
      })
    );
    this.form = this.formBuilder.group({
      name: "",
      active: "",
      employeesNumber: null,
      deleted: "",
    });
  }

  ngOnInit(): void {
    this.translate
      .get(["stats.active", "stats.inactive"])
      .subscribe((result) => {
        this.statusOptions = [
          { value: true, text: result["stats.active"] },
          { value: false, text: result["stats.inactive"] },
        ];
      });
    this.translate
      .get(["stats.deleted", "stats.notDeleted"])
      .subscribe((result) => {
        this.deletedOptions = [
          { value: true, text: result["stats.deleted"] },
          { value: false, text: result["stats.notDeleted"] },
        ];
      });
    this.getCompanyStats();
    this.filters = this.getFilters();
    this.paginationConf.collectionSize = this.companies.length;
  }

  get f() {
    return this.form.controls;
  }

  cleanFilters() {
    this.f.name.setValue("");
    this.f.active.setValue("");
    this.f.employeesNumber.setValue(null);
    this.f.deleted.setValue("");
  }

  getFilters() {
    return [
      this.f.name.value?.toLowerCase() || "",
      this.f.active.value || "",
      this.f.employeesNumber.value || null,
      this.f.deleted.value || "",
    ];
  }

  getCompanyStats() {
    this.subcriptions.push(
      this.companyService.getCompanyStats().subscribe((r) => {
        this.companies = r.docs;
      })
    );
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }
}
