import { Agency, Company, Employee, Hub, User } from "@models";
import { Action } from "@ngrx/store";

export const LOGIN_USER = "[Auth] Login User";
export const LOGIN_EMPLOYEE = "[Auth] Login Employee";
export const LOGIN_COMPANY = "[Auth] Login Company";
export const POPULATE = "[Auth] Populate";
export const LOGIN_SUCCESS = "[Auth] Login Success";
export const LOGIN_FAILURE = "[Auth] Login Failure";
export const LOGOUT = "[Auth] Logout";
export const LOGOUT_SUCCESS = "[Auth] Logout Success";
export const LOGOUT_SUCCESS_REDIRECTION = "[Auth] Logout Success Redirection";

export class LoginUserAction implements Action {
  readonly type = LOGIN_USER;

  constructor(public email: string, public password: string) {}
}

export class LoginEmployeeAction implements Action {
  readonly type = LOGIN_EMPLOYEE;

  constructor(public email: string, public password: string) {}
}

export class LoginCompanyAction implements Action {
  readonly type = LOGIN_COMPANY;

  constructor(public email: string, public password: string) {}
}

export class PopulateAction implements Action {
  readonly type = POPULATE;
}

export class LoginSuccessAction implements Action {
  readonly type = LOGIN_SUCCESS;

  constructor(public user: User | Employee | Company | Agency | Hub) {}
}

export class LoginFailureAction implements Action {
  readonly type = LOGIN_FAILURE;

  constructor(public payload: any) {}
}

export class LogoutAction implements Action {
  readonly type = LOGOUT;
}

export class LogoutSuccessAction implements Action {
  readonly type = LOGOUT_SUCCESS;
}

export class LogoutSuccessRedirectionAction implements Action {
  readonly type = LOGOUT_SUCCESS_REDIRECTION;
  constructor(public payload: any) {}
}

export type AuthActions =
  | LoginUserAction
  | LoginEmployeeAction
  | LoginCompanyAction
  | PopulateAction
  | LoginSuccessAction
  | LoginFailureAction
  | LogoutAction
  | LogoutSuccessAction
  | LogoutSuccessRedirectionAction;
