import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Company, GroupRestriction, User } from "@models";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GroupService, UtilsService } from "@services";

@Component({
  selector: "app-user-form-group",
  templateUrl: "./user-form-group.component.html",
  styleUrls: ["./user-form-group.component.scss"],
})
export class UserFormGroupComponent implements OnInit {
  @Input() user: User;
  @Input() group: GroupRestriction;
  @Input() company: Company;
  @Input() loading: Boolean;
  @Output() sendInfo = new EventEmitter<any>();

  groups: GroupRestriction[] = [];
  form: UntypedFormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private groupService: GroupService,
    public utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      group: [this.group ? this.group : "", [Validators.required]],
    });
    if (!this.group.filters.onlyAuthorization) {
      this.group.filters.onlyAuthorization = false;
    }
    if (this.company) {
      this.groupService
        .getGroups(this.company.uuid)
        .subscribe((data: GroupRestriction[]) => {
          this.groups = data;
          this.groups.forEach((g) => {
            if (!g.filters.onlyAuthorization) {
              g.filters.onlyAuthorization = false;
            }
          });
        });
    }
  }

  compareFn(a: GroupRestriction, b: GroupRestriction) {
    return a && b && a.uuid === b.uuid;
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    const userInfo: any = {
      group: this.f.group.value,
    };

    this.sendInfo.emit(userInfo);
  }

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }
}
