import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class GraphService {
  constructor(private http: HttpClient) {}

  getDataGraphic(body) {
    return this.http
      .get(`${environment.baseUrlApi}/graphic`, { params: body })
      .pipe(map((res) => res));
  }
}
