import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { User } from "@core/models";
import { MediaService, UserService } from "@core/services";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.reducer";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

@Component({
  selector: "app-custom-services",
  templateUrl: "./custom-services.component.html",
  styleUrls: ["./custom-services.component.scss"],
})
export class CustomServicesComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  authUserUuid: string;
  canBookHotel: boolean;
  canRequestCustomFlight: boolean;
  canRequestCustomTrain: boolean;
  canRequestCustomCar: boolean;
  userToBook: string;
  user: User;
  subcriptions: Subscription[] = [];
  companyUuid: string;
  userType: string;
  userImageBase64: string;
  bookedUserAccessType: string;
  canRequestAirbnb: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private mediaService: MediaService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.userToBook = this.route.snapshot.paramMap.get("id");
    this.subscriptions.push(
      this.store.select("auth").subscribe((s) => {
        const auth: any = s;
        this.authUserUuid = s.user.uuid;
        this.companyUuid = auth.user.companyUuid || auth.user.company?.uuid || this.route.snapshot.queryParams.companyUuid;
        this.userType = auth.user.type;
      })
    );

    if (
      this.userToBook &&
      (this.userToBook !== null || this.userToBook !== undefined)
    ) {
      this.userService
        .getUser(this.userToBook)
        .pipe(take(1))
        .subscribe((user: User) => {
          this.user = user;
          this.bookedUserAccessType = user.type;
          this.subcriptions.push(
            this.mediaService.getMedia(this.user.image).subscribe((s) => {
              const reader = new FileReader();
              const reader1 = new FileReader();
              reader.readAsDataURL(s);
              let base64data;
              reader.onload = () => {
                base64data = reader.result;
                this.userImageBase64 = base64data;
              };
              reader1.readAsArrayBuffer(s);
            })
          );
        });
    }

    this.subscriptions.push(
      this.userService
        .getGroupUser(this.authUserUuid)
        .pipe(take(1))
        .subscribe((m) => {
          this.canBookHotel = m.filters.requestBooked;
          this.canRequestCustomFlight = m.filters.requestCustomFlight;
          this.canRequestCustomTrain = m.filters.requestCustomTrain;
          this.canRequestCustomCar = m.filters.requestCar;
          this.canRequestAirbnb =
            m.filters.lodgingType === "all" || m.filters.lodgingType === "apartment";
        })
    );
  }

  navigate(route: string) {
    const navigationExtra: NavigationExtras = {
      state: {
        canBookHotel: this.canBookHotel,
        canRequestCustomFlight: this.canRequestCustomFlight,
        canRequestCustomTrain: this.canRequestCustomTrain,
        canRequestCustomCar: this.canRequestCustomCar,
        canRequestAirbnb: this.canRequestAirbnb,
        userUuid: this.userToBook ? this.userToBook : this.authUserUuid,
        companyUuid: this.companyUuid,
        userImageBase64: this.userImageBase64,
      },
    };

    this.router.navigate([this.router.url + "/" + route], navigationExtra);
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
