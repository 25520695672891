import { MapsAPILoader } from "@ng-maps/core";
import { Injectable } from "@angular/core";
import { Hotel } from "@core/models";
import { from, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class GoogleMaps {
  latitude;
  longitude;
  hotels: Hotel[];
  private geocoder: any;
  constructor(private mapLoader: MapsAPILoader) {}
  private initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }
  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return from(this.mapLoader.load()).pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }
  geocodeAddress(location: string): Observable<any> {
    return this.waitForMapsToLoad().pipe(
      // filter(loaded => loaded),
      switchMap(() => {
        return new Observable((observer) => {
          this.geocoder.geocode({ address: location }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              });
            } else {
              observer.next({ lat: 0, lng: 0 });
            }
            observer.complete();
          });
        });
      })
    );
  }
  setCoordinates(lat, lon) {
    this.latitude = lat;
    this.longitude = lon;
  }

  getCoordinates() {
    return { latitude: this.latitude, longitude: this.longitude };
  }

  setListHotels(hotels: Hotel[]) {
    this.hotels = hotels;
  }

  getListHotels() {
    return this.hotels;
  }
}
