<section id="main-section">
  <section id="content-section" [ngStyle]="{padding: modal ? '3rem' : '0'}">
    <div class="first-row">
      <div id="buttons-container" class="cursor-pointer">
        <div (click)="returnTrip(false)" [ngClass]="{ checked: !oneWay }">
          {{ "flights.form.roundtrip" | translate }}
        </div>
        <div (click)="returnTrip(true)" [ngClass]="{ checked: oneWay }">
          {{ "flights.form.noRoundtrip" | translate }}
        </div>
      </div>
      <div class="resident-discount" (click)="checkIfResident()">
        {{ "flight.form-flight.apply-resident" | translate }}
      </div>
    </div>

    <div id="form-container">
      <form [formGroup]="formSearchFlight">
        <div class="row origin-destination-container" id="places-row">
          <div class="col-5 input-containers">
            <mat-form-field appearance="none">
              <label for="origin"
                >{{ "train.form-train.origin" | translate }}*</label
              >
              <input
                id="origin"
                placeholder="{{
                  'flight.form-flight.insert-ubication-airport' | translate
                }}"
                class="form-control form-control--no-border border-radius-20"
                matInput
                (input)="getAirports($event.target.value)"
                [formControl]="formSearchFlight.controls['origin']"
                [matAutocomplete]="origin"
              />
              <mat-autocomplete
                #origin="matAutocomplete"
                (optionSelected)="optionSelected('origin', $event.option.value)"
              >
                <mat-option
                  *ngFor="let option of airportList"
                  [value]="option.title"
                >
                  {{ option.title }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <button class="col-1 button-secondary" (click)="swipeDestination()">
            <img src="assets/img/svg/flight/swipe.svg" alt="swipe" />
          </button>
          <div class="col-5 input-containers">
            <mat-form-field appearance="none">
              <label for="destination"
                >{{ "train.form-train.destination" | translate }}*</label
              >
              <input
                id="destination"
                placeholder="{{
                  'flight.form-flight.insert-ubication-airport' | translate
                }}"
                class="form-control form-control--no-border border-radius-20"
                matInput
                [matAutocomplete]="destination"
                (input)="getAirports($event.target.value)"
                [formControl]="formSearchFlight.controls['destination']"
              />
              <mat-autocomplete
                #destination="matAutocomplete"
                (optionSelected)="
                  optionSelected('destination', $event.option.value)
                "
              >
                <mat-option *ngFor="let des of airportList" [value]="des.title">
                  {{ des.title }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row" id="options-row">
          <div class="date">
            <div>
              <mat-form-field appearance="none">
                <label for="destination"
                  >{{ "flights.form.dateIn" | translate }}*</label
                >
                <input type="text" matInput style="display: none" />
                <input
                  id="dateIn"
                  name="dateInput"
                  ngbDatepicker
                  #dateInput="ngbDatepicker"
                  class="form-control form-control--no-border"
                  [minDate]="minDateNgStruct"
                  (click)="dateInput.toggle()"
                  formControlName="dateIn"
                  placeholder="dd/mm/yyyy"
                  />
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="!oneWay" class="date">
            <div>
              <mat-form-field appearance="none">
                <label for="dateOutput"
                  >{{ "flights.form.dateOut" | translate }}*</label
                >
                <input type="text" matInput style="display: none" />
                <input
                  id="dateOut"
                  name="dateOutput"
                  ngbDatepicker
                  #dateOutput="ngbDatepicker"
                  (click)="dateOutput.toggle()"
                  class="form-control form-control--no-border"
                  formControlName="dateOut"
                  [minDate]="getOutMinDate()"
                  placeholder="dd/mm/yyyy"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="options">
            <div id="passengers">
              <div>
                <label>
                  {{ "flight.form-flight.passenger-selection" | translate }}
                </label>
                <div class="passenger-counter">
                  <button class="counter-btn" id="decrease" [disabled]="stateService.flightTravelers.length <= 1" (click)="changeTravellers('remove')">-</button>
                  <div class="counter-display" *ngIf="stateService && stateService.flightTravelers">
                    {{ stateService.flightTravelers.length }}
                    {{ "train.form-train.travelers" | translate }}
                  </div>
                  <button class="counter-btn" id="increase" (click)="changeTravellers('add')">+</button>
                </div>
              </div>
            </div>
          </div>
          <div class="options">
            <!-- <mat-select [formControl]="formSearchFlight.controls['type']">
                                <mat-option *ngFor="let seat of seats" [value]="seat.value">
                                    {{ seat.viewValue }}
                                </mat-option>
                            </mat-select> -->
            <!-- <select [formControl]="formSearchFlight.controls['type']">
                                <option *ngFor="let seat of seats" [value]="seat.value"> {{seat.viewValue}} </option>
                            </select> -->
            <div>
              <mat-form-field appearance="none">
                <label>
                  {{ "train.trenymas.title" | translate }}
                </label>
                <input style="display: none" type="text" matInput />
                <div ngbDropdown>
                  <div
                    ngbDropdownToggle
                    class="dropdown-toggle cursor-pointer icon-arrow-rtl form-control--no-border"
                  >
                    {{
                      formSearchFlight.controls["type"].value != null
                        ? getSeatSelected()
                        : seats[seats.length - 1].viewValue
                    }}
                  </div>
                  <div
                    ngbDropdownMenu
                    class="dropdown-menu border-radius-20"
                    aria-labelledby="dropdownBasic1"
                  >
                    <p
                      ngbDropdownItem
                      *ngFor="let seat of seats; let i = index"
                      (click)="selectSeat(seat)"
                      [ngClass]="{
                        selected:
                          seat.value ===
                          formSearchFlight.controls['type'].value.value,
                        last: seats.length - 1 === i
                      }"
                    >
                      {{ seat.viewValue }}
                    </p>
                  </div>
                </div>
              </mat-form-field>
            </div>
          </div>
          
          <div class="container-fluid">
            <div>
              <label>{{'my-bookeds.flight.travellers' | translate}}</label>
            </div>
            <div class="row">
              <div class="col-4">
                <mat-form-field appearance="fill" class="travellers full-width">
                  <mat-label>{{ ("common.traveller" | translate) + ' ' + 1 }}</mat-label>
                  <input class="custom-input" matInput [value]="defaultUser?.name + ' ' + defaultUser?.lastname" readonly>
                </mat-form-field>
              </div>
              
              <div *ngFor="let traveler of travelers; let i = index; trackBy: trackByIndex" class="col-4 traveler-dropdown">
                <mat-form-field appearance="fill" class="travellers full-width">
                  <mat-label>
                    {{ travelers[i] ? ('common.traveller' | translate) + ' ' + (i + 2) : ('common.add-passenger' | translate) }}
                  </mat-label>
                  <mat-select #select id="traveler-{{ i }}" [(value)]="travelers[i]" (closed)="onPanelClose()" (openedChange)="onOpenedChange($event, input)">
                    <mat-option>
                      <input class="custom-mat-input" #input matInput (input)="applyFilter($event.target.value)" (click)="$event.stopPropagation()" placeholder="{{ 'common.search-user' | translate }}" />
                    </mat-option>
                    <mat-option *ngFor="let user of filteredUsers" [value]="user.uuid">
                      {{ user.name }} {{ user.lastname }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div id="search-button-container">
      <div class="row">
        <div class="col-12">
          <button
            class="custom-btn"
            id="search-button"
            (click)="searchFlights()"
            [ngClass]="{
              disabled:
                !formSearchFlight.valid ||
                (!oneWay &&
                  (!formSearchFlight.controls['dateOut'].value || !destination))
            }"
            [disabled]="
              !formSearchFlight.valid ||
              (!oneWay &&
                (!formSearchFlight.controls['dateOut'].value || !destination) || !allTravelersSelected())
            "
          >
            {{ "flights.form.search" | translate }}
          </button>
          <div *ngIf="searchHasFailed" class="search-has-failed-container">
            <p>
              {{ "common.search-fail-custom-service.title" | translate }}
            </p>
            <button
              class="button-secondary custom-btn"
              (click)="
                groupFilters.requestCustomFlight
                  ? goToCreateCustomFlight()
                  : utilsService.openChat()
              "
            >
              {{
                (groupFilters.requestCustomFlight
                  ? "common.search-fail-custom-service.button"
                  : "common.open-chat"
                ) | translate
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div style="opacity: 1; z-index: 98">
    <app-loader-plane *ngIf="loadingFlights && modal"></app-loader-plane>
  </div>
</section>
