<div class="container-fluid">
  <button
    type="button"
    class="close mr-3 mt-2 font-large-1"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="row container-main">
    <div class="col-12 d-flex container-text">
      <h3 class="static-header">{{ "statics.legal.tittle" | translate }}</h3>
      <strong class="paraf-header">
        {{ "statics.legal.header-1" | translate }}
      </strong>
      <p>
        {{ "statics.legal.paragraph-1" | translate }}
      </p>
      <strong class="paraf-header">
        {{ "statics.legal.header-2" | translate }}
      </strong>
      <p>
        {{ "statics.legal.paragraph-2" | translate }}
      </p>
      <strong class="paraf-header">
        {{ "statics.legal.header-3" | translate }}
      </strong>
      <p>
        {{ "statics.legal.paragraph-3" | translate }}
      </p>
      <strong class="paraf-header">
        {{ "statics.legal.header-4" | translate }}
      </strong>
      <p>
        {{ "statics.legal.paragraph-4" | translate }}
      </p>
      <strong class="paraf-header">
        {{ "statics.legal.header-5" | translate }}
      </strong>
      <p>
        {{ "statics.legal.paragraph-5" | translate }}
      </p>
      <strong class="paraf-header">
        {{ "statics.legal.header-6" | translate }}
      </strong>
      <p>
        {{ "statics.legal.paragraph-6" | translate }}
      </p>
      <strong class="paraf-header">
        {{ "statics.legal.header-7" | translate }}
      </strong>
      <p>
        {{ "statics.legal.paragraph-7" | translate }}
      </p>
    </div>
  </div>
</div>
