import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-travelers",
  templateUrl: "./travelers.component.html",
  styleUrls: ["./travelers.component.scss"],
})
export class TravelersComponent implements OnInit {
  @Input() isTrain?;
  travelers = {
    seniors: 0,
    adults: 1,
    youngs: 0,
    children: 0,
    infants: 0,
    total: 0,
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TravelersComponent>
  ) {}

  ngOnInit() {
    if (this.data) {
      this.travelers = this.data;
    }
  }

  addTraveler(value: string) {
    this.travelers[value]++;
    this.travelers.total++;
  }
  removeTraveler(value: string) {
    this.travelers[value]--;
    this.travelers.total--;
  }

  closeModal() {
    this.dialogRef.close({ data: this.travelers });
  }
}
