<div class="container-fluid">
  <button
    type="button"
    class="close mr-3 mt-2 font-large-1"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="row container-main">
    <div class="col-12 d-flex container-text">
      <h3 class="static-header">
        {{ "statics.conditions.tittle" | translate }}
      </h3>

      <strong class="paraf-header">
        {{ "statics.conditions.header-0" | translate }}
      </strong>
      <p>
        {{ "statics.conditions.paragraph-0" | translate }}
      </p>

      <p>
        {{ "statics.conditions.header-0-1" | translate }}
      </p>
      <ul>
        <li>{{ "statics.conditions.list-0-1" | translate }}</li>
        <li>{{ "statics.conditions.list-0-2" | translate }}</li>
        <li>{{ "statics.conditions.list-0-3" | translate }}</li>
        <li>{{ "statics.conditions.list-0-4" | translate }}</li>
      </ul>
      <p>
        {{ "statics.conditions.paragraph-0-2" | translate }}
      </p>

      <strong class="paraf-header">
        {{ "statics.conditions.header-1" | translate }}
      </strong>
      <p>
        {{ "statics.conditions.paragraph-1" | translate }}
      </p>

      <strong class="paraf-header">
        {{ "statics.conditions.header-2" | translate }}
      </strong>
      <p>
        {{ "statics.conditions.paragraph-2" | translate }}
      </p>

      <strong class="paraf-header">
        {{ "statics.conditions.header-3" | translate }}
      </strong>
      <p>
        {{ "statics.conditions.paragraph-3" | translate }}
      </p>

      <strong class="paraf-header">
        {{ "statics.conditions.header-4" | translate }}
      </strong>
      <p>
        {{ "statics.conditions.paragraph-4" | translate }}
      </p>

      <strong class="paraf-header">
        {{ "statics.conditions.header-5" | translate }}
      </strong>
      <p>
        {{ "statics.conditions.paragraph-5" | translate }}
      </p>

      <strong class="paraf-header">
        {{ "statics.conditions.header-6" | translate }}
      </strong>
      <p>
        {{ "statics.conditions.paragraph-6" | translate }}
      </p>
      <strong class="paraf-header">
        {{ "statics.conditions.header-7" | translate }}
      </strong>
      <p>
        {{ "statics.conditions.paragraph-7" | translate }}
      </p>
      <strong class="paraf-header">
        {{ "statics.conditions.header-8" | translate }}
      </strong>
      <p>
        {{ "statics.conditions.paragraph-8" | translate }}
      </p>
    </div>
  </div>
</div>
