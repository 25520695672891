import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-policies",
  templateUrl: "./dialog-policies.component.html",
  styleUrls: ["./dialog-policies.component.scss"],
})
export class DialogPoliciesComponent implements OnInit {
  public policiesSolutionPenalties: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.policiesSolutionPenalties = this.data;
  }

  close() {
    this.dialog.closeAll();
  }
}
