import { Pipe, PipeTransform } from "@angular/core";
import { LanguageService } from "@core/services";
import moment from "moment";
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
 */
@Pipe({ name: "customDateFormat" })
export class OnlyDatePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(date: string, format: string): string {
    const lang = this.languageService.getLanguage();
    const momentDate = moment(date, moment.ISO_8601);
    let parsedDate;
    switch (format) {
      case "date":
        parsedDate = momentDate.locale(lang).format("L");
        break;
      case "time":
        parsedDate = momentDate.format("H:mm");
        break;
      default:
        break;
    }
    return parsedDate;
  }
}
