<h4 mat-dialog-title>Selección de viajeros</h4>
<br />
<mat-dialog-content>
  <div class="travelerType" *ngIf="!isTrain">
    <i
      class="ft-minus-circle fa-2x"
      *ngIf="travelers.seniors > 0"
      (click)="removeTraveler('seniors')"
    ></i>
    <h4 class="mx-2 h-100 my-auto">{{ travelers.seniors }} ancianos</h4>
    <i
      class="ft-plus-circle fa-2x"
      *ngIf="travelers.seniors >= 0 && travelers.total < 10"
      (click)="addTraveler('seniors')"
    ></i>
  </div>
  <br />
  <div class="travelerType">
    <i
      class="ft-minus-circle fa-2x"
      *ngIf="travelers.adults > 0"
      (click)="removeTraveler('adults')"
    ></i>
    <h4 class="mx-2 h-100 my-auto">{{ travelers.adults }} adultos</h4>
    <i
      class="ft-plus-circle fa-2x"
      *ngIf="travelers.adults >= 0 && travelers.total < 10"
      (click)="addTraveler('adults')"
    ></i>
  </div>
  <br />
  <div class="travelerType" *ngIf="!isTrain">
    <i
      class="ft-minus-circle fa-2x"
      *ngIf="travelers.youngs > 0"
      (click)="removeTraveler('youngs')"
    ></i>
    <h4 class="mx-2 h-100 my-auto">{{ travelers.youngs }} jóvenes</h4>
    <i
      class="ft-plus-circle fa-2x"
      *ngIf="travelers.youngs >= 0 && travelers.total < 10"
      (click)="addTraveler('youngs')"
    ></i>
  </div>
  <br />
  <div class="travelerType">
    <i
      class="ft-minus-circle fa-2x"
      *ngIf="travelers.children > 0"
      (click)="removeTraveler('children')"
    ></i>
    <h4 class="mx-2 h-100 my-auto">{{ travelers.children }} niños</h4>
    <i
      class="ft-plus-circle fa-2x"
      *ngIf="travelers.children >= 0 && travelers.total < 10"
      (click)="addTraveler('children')"
    ></i>
  </div>
  <br />
  <div class="travelerType">
    <i
      class="ft-minus-circle fa-2x"
      *ngIf="travelers.infants > 0"
      (click)="removeTraveler('infants')"
    ></i>
    <h4 class="mx-2 h-100 my-auto">{{ travelers.infants }} bebés</h4>
    <i
      class="ft-plus-circle fa-2x"
      *ngIf="travelers.infants >= 0 && travelers.total < 10"
      (click)="addTraveler('infants')"
    ></i>
  </div>
  <br />
  <h3 style="font-weight: bold">TOTAL: {{ travelers.total }}</h3>
</mat-dialog-content>
<div class="modal-footer flex justify-content-center">
  <mat-dialog-actions>
    <button
      type="button"
      class="btn btn-primary buttonAccept button-style m-0"
      [mat-dialog-close]="travelers"
    >
      Aceptar
    </button>
  </mat-dialog-actions>
</div>
