<div class="modal-content">
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="modal-title heading-6" id="modal-basic-title">
      {{ "company.company-form.external" | translate }}
    </h5>
    <form
      [formGroup]="form"
      [ngClass]="{ 'loading-background': loading }"
      (ngSubmit)="onSubmit()"
    >
      <div class="row">
        <div class="col-12 d-flex justify-content-end flex-column">
          <div class="form-group">
            <label *ngIf="groups" for="group">{{
              "common.choose-group" | translate
            }}</label>
            <select
              *ngIf="groups"
              class="form-control icon-arrow-rtl"
              formControlName="group"
              id="group"
              (change)="onGroupChange($event.target.value)"
            >
              <option *ngFor="let group of groups" [value]="group.uuid">
                {{ group.name }}
              </option>
            </select>
            <label for="emails">{{
              "company.company-form.externalPlease" | translate
            }}</label>
            <mat-form-field class="example-chip-list">
              <mat-chip-list #chipList aria-label="Email">
                <mat-chip
                  *ngFor="let email of emails"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(email)"
                >
                  {{ email }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
              <input
                [readOnly]="loading"
                formControlName="emails"
                type="email"
                class="form-control rounded-1"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)"
              />
            </mat-form-field>
            <!-- <input [readOnly]="loading" formControlName="emails" type="email" id="emails"
            class="form-control rounded-1" /> -->
          </div>
          <button
            class="custom-btn"
            ngbAutofocus
            mat-button
            [disabled]="!form.valid || loading"
          >
            {{ "common.send" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
