import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({ name: "dateMoment" })
export class DateMomentPipe implements PipeTransform {
  transform(
    value: string,
    formatInput: string = "YYYY-MM-DD",
    formatOutput: string = "DD/MM/YYYY"
  ): string {
    value = moment(value, formatInput).format(formatOutput);

    return value;
  }
}
