import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { GroupRestriction } from "@models";
import { UserService } from "@services";
import * as _lodash from "lodash-es";
import { Subject } from "rxjs";
import countryJson from "../../../../../assets/utils/amadeus/countries.json";

@Component({
  selector: "app-external-user-form",
  templateUrl: "./external-user-form.component.html",
  styleUrls: ["./external-user-form.component.scss"],
})
export class ExternalUserFormComponent implements OnInit {
  @Output() sendInfo = new EventEmitter<any>();
  canSignUp = false;
  groups: GroupRestriction[] = [];
  form: UntypedFormGroup;
  focusGroup$ = new Subject<string>();
  clickGroup$ = new Subject<string>();
  genderOptions = [
    { value: "male", text: "Hombre" },
    { value: "female", text: "Mujer" },
  ];
  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear() - 100,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  countriesList = countryJson;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.countriesList = countryJson;
    this.userService
      .getTempToken(this.router.url.split("signup?t=")[1])
      .subscribe((c) => {
        if (c == null) {
          this.router.navigateByUrl("error");
        } else {
          this.canSignUp = true;
          this.form = this.formBuilder.group({
            name: [
              "",
              [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(30),
              ],
            ],
            lastname: [
              "",
              [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(30),
              ],
            ],
            identificationType: ["DNI", [Validators.required]],
            dni: [""],
            nie: [""],
            dniIssuanceDate: [null],
            dniExpirationDate: [null],
            nieIssuanceDate: [null],
            nieExpirationDate: [null],
            passportIssuanceDate: [null],
            passport: [""],
            passportExpires: [null],
            phone: ["", [Validators.required, Validators.pattern("[- +()0-9]+")]],
            birthdate: [null, [Validators.required]],
            gender: ["", [Validators.required]],
            address: [""],
            city: [""],
            province: [""],
            postalCode: [""],
            nationality: ["", [Validators.required]],
          });

          this.onIdentificationTypeChange();
        }
      });
  }

  get f() {
    return this.form.controls;
  }

  formatGroup(group) {
    return group.name ? group.name : group;
  }

  onSubmit() {
    const token = this.router.url.split("signup?t=")[1];
    const userInfo: any = {
      token: token,
      name: this.f.name.value,
      lastname: this.f.lastname.value,
      passport: this.f.passport.value,
      passportIssuanceDate:
        this.f.passportIssuanceDate.value &&
        this.f.passportIssuanceDate.value !== null
          ? this.f.passportIssuanceDate.value.year +
            "-" +
            this.f.passportIssuanceDate.value.month +
            "-" +
            this.f.passportIssuanceDate.value.day
          : "",
      passportExpires:
        this.f.passportExpires.value && this.f.passportExpires.value !== null
          ? this.f.passportExpires.value.year +
            "-" +
            this.f.passportExpires.value.month +
            "-" +
            this.f.passportExpires.value.day
          : "",
      phone: this.f.phone.value,
      gender: this.f.gender.value,
      nationality: this.f.nationality.value,
      birthdate:
        this.f.birthdate.value && this.f.birthdate.value !== ""
          ? this.f.birthdate.value.year +
            "-" +
            this.f.birthdate.value.month +
            "-" +
            this.f.birthdate.value.day
          : "",
      identificationType: this.f.identificationType.value
    };

    if (this.f.identificationType.value === 'DNI') {
      userInfo.dni = this.f.dni.value;
      userInfo.dniIssuanceDate =
        this.f.dniIssuanceDate.value && this.f.dniIssuanceDate.value !== ""
          ? this.f.dniIssuanceDate.value.year +
            "-" +
            this.f.dniIssuanceDate.value.month +
            "-" +
            this.f.dniIssuanceDate.value.day
          : "";
      userInfo.dniExpirationDate =
        this.f.dniExpirationDate.value && this.f.dniExpirationDate.value !== ""
          ? this.f.dniExpirationDate.value.year +
            "-" +
            this.f.dniExpirationDate.value.month +
            "-" +
            this.f.dniExpirationDate.value.day
          : "";
      delete userInfo.nie;
      delete userInfo.nieIssuanceDate;
      delete userInfo.nieExpirationDate;
    } else if (this.f.identificationType.value === 'NIE') {
      userInfo.nie = this.f.nie.value;
      userInfo.nieIssuanceDate =
        this.f.nieIssuanceDate.value && this.f.nieIssuanceDate.value !== ""
          ? this.f.nieIssuanceDate.value.year +
            "-" +
            this.f.nieIssuanceDate.value.month +
            "-" +
            this.f.nieIssuanceDate.value.day
          : "";
      userInfo.nieExpirationDate =
        this.f.nieExpirationDate.value && this.f.nieExpirationDate.value !== ""
          ? this.f.nieExpirationDate.value.year +
            "-" +
            this.f.nieExpirationDate.value.month +
            "-" +
            this.f.nieExpirationDate.value.day
          : "";
      delete userInfo.dni;
      delete userInfo.dniIssuanceDate;
      delete userInfo.dniExpirationDate;
    }

    _lodash.get(this.f, "city.value", "") === null
      ? (userInfo.city = "")
      : (userInfo.city = _lodash.get(this.f, "city.value"));
    _lodash.get(this.f, "province.value", "") === null
      ? (userInfo.province = "")
      : (userInfo.province = _lodash.get(this.f, "province.value"));
    _lodash.get(this.f, "city.postalCode", "") === null
      ? (userInfo.postalCode = "")
      : (userInfo.postalCode = _lodash.get(this.f, "postalCode.value"));
    _lodash.get(this.f, "address.value", "") === null
      ? (userInfo.address = "")
      : (userInfo.address = _lodash.get(this.f, "address.value"));
    _lodash.get(this.f, "postalCode.value", "") === null
      ? (userInfo.postalCode = "")
      : (userInfo.postalCode = _lodash.get(this.f, "postalCode.value"));

    this.userService.postExternalUser(userInfo).subscribe((res: any) => {
      this.router.navigateByUrl("login/check-new-user");
    });
  }

  compareFn(a: GroupRestriction, b: GroupRestriction) {
    return a && b && a.uuid === b.uuid;
  }

  onIdentificationTypeChange(): void {
    const idType = this.form.get('identificationType').value;
    if (idType === 'DNI') {
      this.form.get('dni').setValidators([Validators.required]);
      this.form.get('nie').clearValidators();
    } else if (idType === 'NIE') {
      this.form.get('nie').setValidators([Validators.required]);
      this.form.get('dni').clearValidators();
    }
    this.form.get('dni').updateValueAndValidity();
    this.form.get('nie').updateValueAndValidity();
  }

}
