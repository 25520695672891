import { Pipe, PipeTransform } from "@angular/core";
import * as _lodash from "lodash-es";

@Pipe({
  name: "filterRequests",
})
export class RequestsPipe implements PipeTransform {
  public transform(value, keys: string[], terms: any[], types: any[]) {
    if (!terms) {
      return value;
    }

    if (keys.length === 0 || terms.length === 0 || types.length === 0) {
      return value;
    }
    return (value || []).filter((item) => {
      let stringRes = true;
      let statusRes = true;
      let serviceRes = true;

      for (let i = 0; i < types.length; i++) {
        if (
          terms[i] &&
          terms[i] !== "" &&
          terms[i] !== null &&
          (Array.isArray(terms[i])
            ? terms[i].every((j) => j && j !== null && j !== "")
            : true)
        ) {
          switch (types[i]) {
            case "string":
              for (const key of keys) {
                const itemTest = _lodash.get(item, key, null);
                if (itemTest) {
                  if (
                    !new RegExp(
                      terms[i].normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                      "gi"
                    ).test(
                      itemTest.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    )
                  ) {
                    stringRes = false;
                  } else {
                    stringRes = true;
                    break;
                  }
                }
              }
              break;
            case "status":
              if (item.status === terms[i]) {
                statusRes = true;
              } else {
                statusRes = false;
              }
              break;
            case "service":
              if (item.type === terms[i] || terms[i] === "all") {
                serviceRes = true;
              } else {
                serviceRes = false;
              }
              break;
          }
        }
      }
      return stringRes && statusRes && serviceRes;
    });
  }
}
