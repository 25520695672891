import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NavigationExtras, NavigationStart, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AuthService, BookedService, ConfigService, UserService, UtilsService } from "@services";
import { AppState } from "app/modules/businessEntities/company/company.reducer";
import { ConfigComponent } from "app/modules/components/config/config.component";
import { Subscription } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { customAnimations } from "../animations/custom-animations";
import { OPTIONS_ROUTES, ROUTES } from "./sidebar-routes.config";
import { Company } from "@core/models";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  animations: customAnimations,
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("toggleIcon") toggleIcon: ElementRef;
  public menuItems: any[];
  menuItemsPath: string[] = [];
  public optionItems: any[] = [];
  optionItemsPath: string[] = [];
  depth: number;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = "assets/img/logo.png";
  public config: any = {};

  userType: string;
  requestFlight: boolean;
  requestTrain: boolean;
  requestBooked: boolean;
  requestCustomFlight: boolean;
  requestCustomTrain: boolean;
  requestCustomCar: boolean;
  mainUrlUser: string;
  activeIndex: number;
  canUseTRM: boolean;
  subscriptions: Subscription[] = [];
  userUuid: string;
  user: any;
  activatedUrl: string;

  authenticatedUser: any;
  isUser = false;
  company: Company;
  params: any = {};
  isGroupAdmin = false;

  constructor(
    private renderer: Renderer2,
    public translate: TranslateService,
    private configService: ConfigService,
    private store: Store<AppState>,
    private userService: UserService,
    private modalService: NgbModal,
    public authService: AuthService,
    private router: Router,
    private bookedService: BookedService,
    public utilsService: UtilsService,

  ) {
    this.mainUrlUser = "/";
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }

    this.activatedUrl = this.router.url;

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        map((event) => event as NavigationStart)
      )
      .subscribe((event) => {
        if (this.activatedUrl !== event.url) {
          this.activatedUrl = event.url;
          setTimeout(() => {
            let url = "";
            if (event.url.split("/").length > 2) {
              url = event.url.split("/")[1] + "/" + event.url.split("/")[2];
            } else {
              url = event.url;
            }
            if (
              this.menuItemsPath.includes(url) ||
              this.optionItemsPath.includes(url)
            ) {
              this.selectRoute();
            } else {
              this.menuItems.forEach((item) => {
                item.selected = false;
              });
              this.optionItems.forEach((item) => {
                item.selected = false;
              });
            }
          }, 200);
        }
      });
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
    this.menuItems = [];
    this.optionItems = [];

    this.subscriptions.push(
      this.store.select("auth").subscribe((res) => {
        const auth: any = res;
        if (res.isAuthenticated) {
          this.user = auth.user;
          this.userType = auth.user.type
            ? auth.user.type
            : auth.user.user.accessType?.toUpperCase();
          this.userUuid = auth.user.uuid;

          // TODO quitar employee de aquí cuando estén agency y hub
          this.optionItems = OPTIONS_ROUTES;
          if (this.userType === "AGENCY") {
            this.menuItems = ROUTES.filter(
              (item) => item.path === "/companies" || item.path === "/config"
            );
          } else if (this.userType === "COMPANY") {
            const user: any = res.user;
            const userUuid = user.companyUuid ? user.uuid : user.user.uuid;
            this.canUseTRM = user.type
              ? user.plan && user.plan.permissions.canUseTRM
              : user.user.plan && user.user.plan.permissions.canUseTRM;
            if (this.canUseTRM) {
              this.menuItems = ROUTES.filter(
                (item) =>
                  item.path === "/my-company/details" || item.path === "/help"
              );
            } else {
              this.setUserSidebar(userUuid);
            }
          } else if (this.userType === "HUB") {
            this.menuItems = ROUTES.filter(
              (item) => item.path === "/agencies" || item.path === "/config"
            );
          } else if (this.userType === "EMPLOYEE") {
            this.menuItems = ROUTES.filter(
              (item) =>
                item.path === "/hubs" ||
                item.path === "/agencies" ||
                item.path === "/companies" ||
                item.path === "/stats" ||
                item.path === "/config" ||
                item.path === "/companies/plans"
            );
          } else {
            this.setUserSidebar(res.user.uuid);
          }
          this.menuItems.forEach((item) => {
            this.menuItemsPath.push(item.path);
            item.selected = false;
          });

          this.optionItems.forEach((item) => {
            this.optionItemsPath.push(item.path);
            item.selected = false;
          });
        }
      })
    );

    this.subscriptions.push(
      this.store.select("auth").subscribe((u: any) => {
        this.authenticatedUser = u.user;
        if (u.user && u.user.type === "USER") {
          this.user = u.user;
          this.isUser = true;
          this.canUseTRM = u.user.type
            ? u.user.plan && u.user.plan.permissions.canUseTRM
            : u.user.user.plan && u.user.user.plan.permissions.canUseTRM;
          if (this.user.isGroupAdmin) {
            this.isGroupAdmin = true;
            this.getAuthorizationList();
          }
        }
      }));

    if (this.config.layout.sidebar.backgroundColor === "white") {
      this.logoUrl = "assets/img/logo/logo-dark.png";
    } else {
      this.logoUrl = "assets/img/logo/logo.png";
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed !== undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(this.toggleIcon.nativeElement, "toogle-icon");
          this.renderer.removeClass(
            this.toggleIcon.nativeElement,
            "toogle-icon"
          );
          this.nav_collapsed_open = true;
        } else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          this.renderer.removeClass(
            this.toggleIcon.nativeElement,
            "toogle-icon"
          );
          this.renderer.addClass(this.toggleIcon.nativeElement, "toogle-icon");
          this.nav_collapsed_open = false;
        }
        this.selectRoute();
      }
    }, 1000);
  }

  selectRoute() {
    /* const menuSelected = this.menuItems.find((item) => item.selected === true);
    if(menuSelected) this.setIcon(this.menuItems.indexOf(menuSelected), false);
    const optionSelected = this.optionItems.find((item) => item.selected === true);
    if(optionSelected) this.setIcon(this.optionItems.indexOf(optionSelected), true); */
    for (
      let i = 0;
      i < document.getElementsByClassName("sidebar-link").length;
      i++
    ) {
      const li = document.getElementsByClassName("sidebar-link")[i];
      if (li.classList.length > 1) {
        li.classList.forEach((liClass) => {
          if (liClass === "active") {
            if (li.firstElementChild.id === "option") {
              this.setIcon(parseInt(li.id.split("option-")[1], 10), true);
            } else {
              this.setIcon(parseInt(li.id.split("menu-")[1], 10), false);
            }
          }
        });
      }
    }
  }

  setIcon(index: number, isOptions: boolean) {
    if (isOptions) {
      this.menuItems.forEach((item) => {
        item.selected = false;
      });

      for (let i = 0; i < this.optionItems.length; i++) {
        const element = this.optionItems[i];
        if (i === index) {
          element.selected = true;
        } else {
          element.selected = false;
        }
      }
    } else {
      this.optionItems.forEach((option) => {
        option.selected = false;
      });

      for (let i = 0; i < this.menuItems.length; i++) {
        // const element = this.menuItems[i];
        if (i === index) {
          this.menuItems[i].selected = true;
        } else {
          this.menuItems[i].selected = false;
        }
      }
    }
  }

  setUserSidebar(userUuid) {
    this.subscriptions.push(
      this.userService
        .getGroupUser(userUuid)
        .pipe(take(1))
        .subscribe((value) => {
          if (value) {
            this.requestFlight = value.filters.requestFlight;
            this.requestCustomFlight = value.filters.requestCustomFlight;
            this.requestCustomTrain = value.filters.requestCustomTrain;
            this.requestTrain = value.filters.requestTrain;
            this.requestBooked = value.filters.requestBooked;
            this.requestCustomCar = value.filters.requestCar;
            this.mainUrlUser = value.filters.requestBooked
              ? "/search"
              : this.userType === "COMPANY" && !this.canUseTRM
                ? "/user/booked/" + userUuid
                : "/my-bookeds";
            const validMenu = value.filters.requestBooked
              ? this.userType === "COMPANY" && !this.canUseTRM
                ? ["/user/booked/" + userUuid]
                : ["/my-bookeds"]
              : ["/my-bookeds"];
            this.menuItems = ROUTES.filter((item) => {
              validMenu.push("/home");
              if (
                this.authService.userCompanyPlan?.permissions
                  ?.canRequestAutomatizedBookings
              ) {
                if (item.path === "/search") {
                  item["enabled"] = this.requestBooked;
                }
                validMenu.push("/search");
              }
              if (
                this.authService.userCompanyPlan?.permissions
                  ?.canRequestAutomatizedBookings
              ) {
                if (item.path === "/flight") {
                  item["enabled"] = this.requestFlight;
                }
                validMenu.push("/flight");
              }
              if (
                this.authService.userCompanyPlan?.permissions
                  ?.canRequestAutomatizedBookings
              ) {
                if (item.path === "/train") {
                  item["enabled"] = this.requestTrain;
                }
                validMenu.push("/train");
              }
              if (
                this.authService.userCompanyPlan?.permissions
                  ?.canRequestCustomBookings
              ) {
                if (item.path === "/custom-services") {
                  item["enabled"] = this.requestBooked || this.requestCustomFlight ||
                    this.requestCustomTrain ||
                    this.requestCustomCar;
                }
                validMenu.push("/custom-services");
              }
              if (this.authService.userCompanyPlan?.permissions?.canUseAlfred) {
                validMenu.push("/help");
              }
              return validMenu.includes(item.path);
            });
            if (this.userType === "COMPANY" && !this.canUseTRM) {
              this.menuItems.unshift({
                path: "/user/booked/" + userUuid,
                title: "sidebar.my-bookeds",
                icon: "tent",
                iconSelected: "tent-green",
                class: "",
                badge: "",
                badgeClass: "",
                isExternalLink: false,
                submenu: [],
              });
            }
          }
          this.menuItems.forEach((item) => {
            this.menuItemsPath.push(item.path);
            if (item.class === "active") {
              item.selected = true;
            }
          });
        })
    );
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  editConfig() {
    const modalRef = this.modalService.open(ConfigComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.sendInfo.subscribe((configInfo) => {
      modalRef.componentInstance.loading = true;
      modalRef.dismiss("send info");
    });
  }

  navigate(route: string) {
    if (route === "user/my-documents") {
      const navigationExtras: NavigationExtras = {
        state: { user: this.user },
      };

      this.router.navigate([route], navigationExtras);
    } else {
      this.router.navigate([route]);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getAuthorizationList() {
    const company: any = this.company;

    if (
      (this.user && this.user.isGroupAdmin)
      ||
      (company && company.isGroupAdmin)
    ) {
      this.params["groupRestrictionUuid"] =
        this.authenticatedUser.groupRestrictionUuid;
    }
    this.subscriptions.push(
      this.bookedService.getAuthorization(this.params).subscribe((result) => {
        if (result.docs.length > 0) {
          const requestAuths = result.docs.filter(
            (authorization) => authorization.status === "request"
          );
          if (requestAuths.length > 0) {
            this.authService.totalAuthorizations = result.totalDocs;
            this.utilsService.haveRequest = true;
          } else {
            this.utilsService.haveRequest = false;
          }
        } else {
          this.authService.totalAuthorizations = 0;
          this.utilsService.haveRequest = false;
        }
      })
    );
  }

  goToRequests() {
    this.router.navigate([
      `/companies/${this.user.companyUuid}/authorizations`,
    ]);
  }
}
