import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  NgbActiveModal,
  NgbDate,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

@Component({
  selector: "app-uploadBill",
  templateUrl: "./uploadBill.component.html",
  styleUrls: ["./uploadBill.component.scss"],
})
export class UploadBill implements OnInit {
  @Input() loading: Boolean;
  @Input() companyUuid: string;
  @Output() sendInfo = new EventEmitter<any>();

  form: UntypedFormGroup;
  file: File;
  listMonths: string[];
  month: string;
  reference: string;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private ngbDateParser: NgbDateParserFormatter
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      file: [null, [Validators.required]],
      date: [null, [Validators.required]],
      reference: [null, [Validators.required]],
    });

    this.loading = false;
  }
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    const send = {
      file: this.file,
      date: moment(this.f.date.value, "DD/MM/YYYY").format("YYYY-MM-DD"),
      reference: this.f.reference.value,
    };
    this.sendInfo.emit(send);
  }

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }

  onDateSelection(date: NgbDate) {
    this.form.get("date").setValue(this.ngbDateParser.format(date));
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }
}
