import { PaginationConfig } from "@interfaces";
import * as fromUserActions from "../actions/user.actions";

export interface UserState {
  paginationConfig: PaginationConfig;
}

export const initialState: UserState = {
  paginationConfig: {
    page: 1,
    pageSize: 5,
    collectionSize: 120,
  },
};

export function UserReducer(
  state: UserState = initialState,
  action: fromUserActions.UserActions
): UserState {
  switch (action.type) {
    case fromUserActions.USER_PAGE:
      return {
        ...state,
        paginationConfig: { ...action.paginationConfig },
      };
    default:
      return state;
  }
}
