import { Pipe, PipeTransform } from "@angular/core";
import * as _lodash from "lodash-es";

@Pipe({
  name: "filterTRM",
})
export class FilterTRMPipe implements PipeTransform {
  constructor() {}
  public transform(value, original: any[], term: string, allLoaded: boolean) {
    if (allLoaded) {
      value = JSON.parse(JSON.stringify(original));
      if (!term) {
        return value;
      }

      if (original.length === 0 || term.length === 0) {
        return value;
      }
      return (value || []).filter((item) => {
        term = term?.toLowerCase();
        const groupName = _lodash.get(item, "name", null)?.toLowerCase();
        const groupRes = new RegExp(
          term.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          "gi"
        ).test(groupName.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        item.users = item.users.filter((user) => {
          const userName = _lodash.get(user, "name", null)?.toLowerCase();
          const userLastName = _lodash
            .get(user, "lastname", null)
            ?.toLowerCase();
          const concatName =
            userName.normalize("NFD").replace(/[\u0300-\u036f]/g, "") +
            " " +
            userLastName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const userNameRes = new RegExp(
            term.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            "gi"
          ).test(concatName);
          return !groupRes ? userNameRes : true;
        });
        return groupRes ? true : item.users.length > 0;
      });
    }
  }
}
