import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-error-dialog",
  templateUrl: "./error-dialog.component.html",
  styleUrls: ["./error-dialog.component.scss"],
})
export class ErrorDialogComponent implements OnInit {
  title = "";
  body = "";
  route = "/";
  constructor(
    private dialog: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getError();
  }

  getError() {
    this.translate
      .get([
        "train.error.seat",
        "train.error.seatTitle",
        "train.error.unhandled",
        "train.error.unhandledTitle",
      ])
      .subscribe((result) => {
        switch (this.data.error) {
          case "seat":
            this.title = result["train.error.seatTitle"];
            this.body = result["train.error.seat"];
            this.route = "/train";
            break;
          default:
            this.title = result["train.error.unhandledTitle"];
            this.body = result["train.error.unhandled"];
            break;
        }
      });
  }
  close() {
    this.dialog.close();
    this.router.navigate([this.route]);
  }
}
