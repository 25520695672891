import { NgModule } from "@angular/core";
import { NoPreloading, RouterModule, Routes } from "@angular/router";
import { AuthenticatedGuard } from "@core/guards/auth.guard";
import { PlanResolveService } from "@core/guards/PlanResolveService";
import { ContentLayoutComponent } from "@core/layouts/content/content-layout.component";
import { EmptyComponent } from "@core/layouts/empty/empty.component";
import { FullLayoutComponent } from "@core/layouts/full/full-layout.component";
import { ContentLayoutRoutes } from "./content-layout.routes";
import { FullLayoutRoutes } from "./full-layout.routes";

const appRoutes: Routes = [
  {
    path: "",
    component: EmptyComponent,
    canLoad: [AuthenticatedGuard],
    pathMatch: "full",
  },
  {
    path: "",
    component: FullLayoutComponent,
    resolve: {
      plans: PlanResolveService,
    },
    children: FullLayoutRoutes,
  },
  {
    path: "",
    component: ContentLayoutComponent,
    children: ContentLayoutRoutes,
  },
  {
    path: "statics",
    resolve: {
      plans: PlanResolveService,
    },
    loadChildren: () => import('./modules/components/statics/statics.module').then(m => m.StaticsModule),
  },

  { path: "**", redirectTo: "/error" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: NoPreloading,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
