import { PaginationConfig } from "@interfaces";
import * as fromMyBookedsActions from "../actions/my-bookeds.actions";

export interface MyBookedsState {
  paginationConfig: PaginationConfig;
}

export const initialState: MyBookedsState = {
  paginationConfig: {
    page: 1,
    pageSize: 5,
    collectionSize: 120,
  },
};

export function MyBookedsReducer(
  state: MyBookedsState = initialState,
  action: fromMyBookedsActions.MyBookedsActions
): MyBookedsState {
  switch (action.type) {
    case fromMyBookedsActions.MY_BOOKEDS_PAGE:
      return {
        ...state,
        paginationConfig: { ...action.paginationConfig },
      };
    default:
      return state;
  }
}
