<!-- EMPTY STATE -->
<div class="text-center" *ngIf="companies.length <= 0">
  <img
    src="assets/img/gallery/empty-companies.svg"
    width="200"
    height="200"
    alt="No hay archivos"
  />
  <h3 class="mt-4">
    <b>{{ "stats.no-companies" | translate }}</b>
  </h3>
</div>
<!-- TABLA DE MIS EMPRESAS -->
<div *ngIf="companies.length > 0">
  <div class="container-customize">
    <div class="row mt-1">
      <div class="table-responsive">
        <form [formGroup]="form">
          <table class="table table-hover mb-0 table-groups">
            <thead>
              <tr>
                <th scope="col">{{ "stats.companyName" | translate }}</th>
                <th scope="col" class="text-center">
                  {{ "stats.active" | translate }}
                </th>
                <th scope="col" class="text-center">
                  {{ "stats.numberOfEmployees" | translate }}
                </th>
                <th scope="col" class="text-center">
                  {{ "stats.activeEmployees" | translate }}
                </th>
                <th scope="col" class="text-center">
                  {{ "stats.inactiveEmployees" | translate }}
                </th>
                <th scope="col" class="text-center">
                  {{ "stats.create-date" | translate }}
                </th>
                <th scope="col">
                  {{ "stats.deleted" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- FILTER -->
              <tr>
                <td class="name">
                  <input
                    class="form-control"
                    formControlName="name"
                    placeholder="{{ 'stats.companyName' | translate }}"
                  />
                </td>
                <td class="todos">
                  <select
                    class="form-control"
                    formControlName="active"
                  >
                    <option value="" selected>
                      {{ "authorization.status.all" | translate }}
                    </option>
                    <option
                      *ngFor="let statusOption of statusOptions"
                      [value]="statusOption.value"
                    >
                      {{ statusOption.text }}
                    </option>
                  </select>
                </td>
                <td class="employees">
                  <input
                    class="form-control"
                    type="number"
                    formControlName="employeesNumber"
                    placeholder="{{ 'stats.numberOfEmployees' | translate }}"
                  />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="duo">
                  <select
                    class="form-control selectHalf"
                    formControlName="deleted"
                  >
                    <option value="" selected>
                      {{ "authorization.status.all" | translate }}
                    </option>
                    <option
                      *ngFor="let deletedOption of deletedOptions"
                      [value]="deletedOption.value"
                    >
                      {{ deletedOption.text }}
                    </option>
                  </select>

                  <button
                    type="button"
                    (click)="cleanFilters()"
                    class="custom-btn custom-btn--close"
                  >
                    {{ "common.clean" | translate }}
                  </button>
                </td>
              </tr>

              <!-- DATA TABLE -->
              <tr
                *ngFor="
                  let company of companies
                    | filter
                      : ['name', 'active', 'employeesNumber', 'deleted']
                      : getFilters()
                      : ['rg', 'rg', 'number', 'rg']
                    | slice: lowValue:highValue
                "
              >
                <td>{{ company.name }}</td>
                <td *ngIf="!company.active" class="text-center">
                  <span class="badge badge-danger">{{
                    "stats.inactive" | translate
                  }}</span>
                </td>
                <td *ngIf="company.active" class="text-center">
                  <span class="badge badge-success">{{
                    "stats.active" | translate
                  }}</span>
                </td>
                <td class="text-center">{{ company.employeesNumber }}</td>
                <td class="text-center">{{ company.employeesActive }}</td>
                <td class="text-center">{{ company.employeesInactive }}</td>
                <td class="text-center">
                  {{
                    company.createdAt | dateMoment: "YYYY-MM-DD":"DD/MM/YYYY"
                  }}
                </td>
                <td *ngIf="company.deleted">
                  <span class="badge badge-danger">{{
                    "stats.deleted" | translate
                  }}</span>
                </td>
                <td *ngIf="!company.deleted">
                  <span class="badge badge-success">{{
                    "stats.notDeleted" | translate
                  }}</span>
                </td>
                <!-- <td class="text-center">
                                    {{ "stats.no-actions" | translate }}
                                </td> -->
              </tr>
            </tbody>
          </table>
        </form>
        <div
          class="col-12 d-flex justify-content-center my-5"
          *ngIf="
            (
              companies
              | filter
                : ['name', 'active', 'employeesNumber', 'deleted']
                : getFilters()
                : ['rg', 'rg', 'number', 'rg']
            ).length === 0
          "
        >
          <div class="icon-bcg icon-bcg--large">
            <img
              src="assets/img/svg/empty-search.svg"
              alt="emptySearch"
              class="empty-search"
            />
          </div>
          <h2 class="ml-3 my-auto">{{ "search.not-data" | translate }}</h2>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        (
          companies
          | filter
            : ['name', 'active', 'employeesNumber', 'deleted']
            : getFilters()
            : ['rg', 'rg', 'number', 'rg']
        ).length > 0
      "
      class="d-flex justify-content-center mt-2 mb-2 col-12"
    >
      <mat-paginator
        [length]="
          (
            companies
            | filter
              : ['name', 'active', 'employeesNumber', 'deleted']
              : getFilters()
              : ['rg', 'rg', 'number', 'rg']
          ).length
        "
        [pageSize]="10"
        (page)="getPaginatorData($event)"
        showFirstLastButtons
        [hidePageSize]="true"
        style="border-radius: 15px"
      >
      </mat-paginator>
    </div>
  </div>
</div>
