<div class="row">
  <div class="col-lg-8 col-md-7 col-xs-12">
    <h1 class="heading-4">{{ "dashboard.title" | translate }}</h1>
  </div>
  <div
    class="col-lg-4 col-md-5 col-xs-12"
    *ngIf="fromSuperAdmin || isSuperAdmin"
  >
    <button
      *ngIf="
        (isSuperAdmin && !satisfactions) ||
        (fromSuperAdmin && !savings && savings === null)
      "
      (click)="openModal('new')"
      class="btn btn-outline-vyoo-primary ml-1"
    >
      <i class="ft-book"></i>
      {{ "dashboard.add" | translate }}
    </button>
    <button
      *ngIf="
        (isSuperAdmin && satisfactions && satisfactions !== null) ||
        (fromSuperAdmin && savings && savings !== null)
      "
      (click)="openModal('edit')"
      class="btn btn-outline-vyoo-primary ml-1"
    >
      <i class="ft-book"></i>
      {{ "dashboard.edit" | translate }}
    </button>
  </div>
</div>

<div class="row">
  <div class="col-lg-6 col-md-6 col-sm-12">
    <pie-optm
      *ngIf="savings !== null && savings"
      [savings]="savings"
      style="height: 100%; display: block"
    ></pie-optm>
    <pie-optm
      *ngIf="savings == null || !savings"
      style="height: 100%; display: block"
    ></pie-optm>
  </div>
  <div class="col-6">
    <app-bar-optm
      *ngIf="satisfactions !== null && satisfactions"
      [satisfaction]="satisfactions"
      style="height: 100%; display: block"
    ></app-bar-optm>
    <app-bar-optm
      *ngIf="satisfactions == null || !satisfactions"
      [satisfaction]="satisfactions"
      style="height: 100%; display: block"
    ></app-bar-optm>
  </div>
</div>
<div class="row">
  <div class="col-lg-6 col-md-6 col-sm-12">
    <figure class="highcharts-figure">
      <div id="full"></div>
    </figure>
  </div>
  <div class="col-6">
    <figure class="highcharts-figure">
      <div id="services"></div>
    </figure>
  </div>
</div>
