<div id="dialogContainer">
  <div id="header" *ngIf="user">
    <div id="title">
      <h5 class="heading-6">
        {{ "common.summary-upload-files" | translate }}
      </h5>
    </div>
  </div>
  <div id="action-container">
    <div id="search">
      <input
        type="text"
        [(ngModel)]="searchInput"
        [placeholder]="('common.search-files' | translate) + '...'"
        class="form-control-files form-control search-input filterInput icon-search"
        (input)="search($event.target.value)"
      />
    </div>
    <div id="actions">
      <div id="downloadAll">
        <button
          [disabled]="
            originalUserFiles == null || originalUserFiles.length === 0
          "
          class="btn-files-download custom-btn"
          (click)="downloadAll()"
        >
          <img
            src="../../../../../assets/img/svg/download-white.svg"
            alt="download-file-icon"
            class="download-file-icon"
          />

          {{ "common.download-all" | translate }}
        </button>
      </div>
      <div id="upload" class="cursor-pointer">
        <label for="file-upload" class="btn-files-upload custom-btn cursor-pointer">
          <img
            src="../../../../../assets/img/svg/upload-white.svg"
            alt="upload-file-icon"
            class="upload-file-icon"
          />
          {{ "common.upload-files" | translate }}
          <input
            class="cursor-pointer"
            type="file"
            id="file-upload"
            #fileUpload
            accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.jpg,.jpeg,.gif,.heic,.mp3,.wav,.ogg,.flac,.aac"
            (change)="confirmFileUpload($event)"
          />
        </label>
      </div>
    </div>
  </div>
  <div id="filesContainer">
    <div *ngIf="originalUserFiles == null || originalUserFiles.length === 0">
      <h3 class="no-files-uploaded">
        {{ "common.no-files.uploaded" | translate }}
      </h3>
    </div>
    <div class="table-head" *ngIf="user != null">
      <p class="m-0">{{ "common.name" | translate }}</p>
      <!-- <p class="m-0">{{"common.upload-date" | translate}}</p> -->
    </div>
    <div
      class="no-files-found"
      *ngIf="
        originalUserFiles != null &&
        originalUserFiles.length !== 0 &&
        user.files != null &&
        user.files.length === 0 &&
        (searchInput != null || searchInput != '')
      "
    >
      <h3>
        {{ "common.no-files.found" | translate }}
      </h3>
    </div>
    <ng-container *ngIf="user != null">
      <div
        *ngFor="
          let file of user.files | slice: lowValue:highValue;
          let i = index
        "
      >
        <div class="file" [matTooltip]="file">
          <img [src]="getFiletype(file)" alt="" width="35%" />
          <p class="filename text-truncate">
            {{
              file.length > 23
                ? file.substring(0, 23) +
                  "..." +
                  file.substring(file.length - 4, file.length)
                : file
            }}
            <!-- {{file | titlecase}} -->
          </p>
          <div class="more-btn-position" ngbDropdown style="cursor: pointer">
            <button
              type="button"
              class="btn-more"
              ngbDropdownToggle
              aria-labelledby="dropdownBasic1"
              (click)="selectFile(file, i)"
            >
              <img
                src="../../../../../assets/img/svg/feather-more-vertical.svg"
              />
            </button>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <button
                [disabled]="selectedFile == null"
                (click)="downloadSelectedFile()"
                ngbDropdownItem
              >
                {{ "common.download-document" | translate }}
              </button>
              <button
                [disabled]="selectedFile == null"
                (click)="deleteSelectedFile(file)"
                ngbDropdownItem
              >
                {{ "common.delete-document" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <mat-paginator
          [length]="user.files.length"
          [pageSize]="10"
          (page)="getPaginatorData($event)"
          showFirstLastButtons
          [hidePageSize]="true"
          style="border-radius: 15px"
        >
        </mat-paginator>
      </div>
    </ng-container>
  </div>
  <div *ngIf="isLoading">
    <app-loading></app-loading>
  </div>
</div>

<!-- <div id="footer" [class]="
      selectedFile != null
        ? 'shown'
        : 'hidden' || fileToUpload != null
        ? 'shown'
        : 'hidden'

    ">

  <div class="buttons" *ngIf="selectedFile != null; else fileToUploadButtons">
  </div>

  <ng-template #fileToUploadButtons>
    <div class="buttons" *ngIf="fileToUpload != null">
      <h6 id="filename" class="fileToUpload-name">{{ fileToUpload ? fileToUpload.name : "" }}</h6>
      <button (click)="cancelFileUpload()" class="btn btn-cancel-upload-file btn-raised mt-2 mr-3">
        {{ "common.cancel" | translate }}
      </button>
      <button (click)="confirmFileUpload()" class="btn btn-confirm-upload-file btn-raised mt-2 mr-3">
        {{ "common.confirm" | translate }}
      </button>
    </div>
  </ng-template>
</div> -->
