<section *ngIf="canSignUp">
  <div class="row signup-container">
    <div id="full-wrapper" class="col-md-6 offset-md-6 col-xs-12 p-4">
      <div id="container">
        <div id="header">
          <img
            src="assets/img/logo/logo_accent.png"
            class="login-img rounded-1 mx-auto d-block mb-2"
          />
          <h4 class="card-title mb-2 text-center">
            {{ "common.register" | translate }}
          </h4>
        </div>
        <div id="body">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <h5>{{ "common.required-field" | translate }}</h5>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="name">{{ "common.name" | translate }}*</label>
                  <input
                    [readOnly]="loading"
                    formControlName="name"
                    type="text"
                    id="name"
                    class="form-control-user rounded-1"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="lastname"
                    >{{ "user.form.surname" | translate }}*</label
                  >
                  <input
                    [readOnly]="loading"
                    formControlName="lastname"
                    type="text"
                    id="lastname"
                    class="form-control-user rounded-1"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="birthdate"
                    >{{ "user.form.birthdate" | translate }}*</label
                  >
                  <input
                    mat-button
                    [disabled]="loading"
                    readonly
                    formControlName="birthdate"
                    type="text"
                    id="birthdate"
                    class="form-control-user birthdate rounded-1 icon-rtl"
                    ngbDatepicker
                    #datePicker="ngbDatepicker"
                    (click)="datePicker.toggle()"
                    [minDate]="minDateNgStruct"
                    [maxDate]="maxDateNgStruct"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="gender"
                    >{{ "user.form.gender" | translate }}*</label
                  >
                  <select
                    [attr.disabled]="loading ? '' : null"
                    formControlName="gender"
                    id="gender"
                    class="form-control-user rounded-1"
                  >
                    <option
                      *ngFor="let genderOption of genderOptions"
                      [ngValue]="genderOption.value"
                    >
                      {{ genderOption.text | translate }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="phone"
                    >{{ "user.form.phone" | translate }}*</label
                  >
                  <input
                    [readOnly]="loading"
                    formControlName="phone"
                    type="text"
                    id="phone"
                    class="form-control-user rounded-1"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="identificationType">{{ "user.form.documentType" | translate }}*</label>
                  <select
                    id="identificationType"
                    formControlName="identificationType"
                    (change)="onIdentificationTypeChange()"
                    class="form-control-user rounded-1"
                  >
                  <option value="" disabled selected hidden>{{ "user.form.documentType" | translate }}</option>
                  <option value="DNI">{{ "user.form.dni" | translate }}</option>
                  <option value="NIE">{{ "user.form.nie" | translate }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12" *ngIf="f.identificationType.value === 'DNI'">
                <div class="form-group">
                  <label for="dni">{{ "user.form.dni" | translate }}*</label>
                  <input
                    [readOnly]="loading"
                    formControlName="dni"
                    type="text"
                    id="dni"
                    class="form-control-user rounded-1"
                    [ngClass]="{ 'input-error': !form.controls.dni.valid && isInvalid }"
                  />
                  <div *ngIf="form.get('dni').touched && !form.controls.dni.valid && isInvalid">
                    {{ "user.form.error.dni" | translate }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12" *ngIf="f.identificationType.value === 'NIE'">
                <div class="form-group">
                  <label for="nie">{{ "user.form.nie" | translate }}*</label>
                  <input [readOnly]="loading" formControlName="nie" type="text" id="nie" class="form-control-user rounded-1" [ngClass]="{ 'input-error': !form.controls.nie.valid && isInvalid }" />
                  <div *ngIf="form.get('nie').touched && !form.controls.nie.valid && isInvalid">
                    {{ "user.form.error.nie" | translate }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group-user">
                  <label class="tittle-form-control-user" for="nationality"
                    >{{ "user.form.nationality" | translate }}*</label
                  >
                  <select
                    formControlName="nationality"
                    type="text"
                    id="nationality"
                    class="form-control-user rounded-1 icon-arrow-rtl"
                  >
                    <option disabled selected hidden></option>
                    <option *ngFor="let c of countriesList" [ngValue]="c.name">
                      {{ c.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <h5 id="optional">
              {{ "common.optional-field" | translate }}
              <img src="assets/img/svg/info.svg" alt="info-icon" />
            </h5>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12" *ngIf="f.identificationType.value === 'DNI'">
                <div class="form-group">
                  <label for="dniIssuanceDate">{{
                    "user.form.dniIssuanceDate" | translate
                  }}</label>
                  <input
                    mat-button
                    [disabled]="loading"
                    readonly
                    formControlName="dniIssuanceDate"
                    type="text"
                    id="dniIssuanceDate"
                    class="form-control-user icon-rtl rounded-1"
                    ngbDatepicker
                    #dniIssuanceDate="ngbDatepicker"
                    (click)="dniIssuanceDate.toggle()"
                    [minDate]="minDateNgStruct"
                    [maxDate]="maxDateNgStruct"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12" *ngIf="f.identificationType.value === 'NIE'">
                <div class="form-group">
                  <label for="nieIssuanceDate">{{ "user.form.nieIssuanceDate" | translate }}</label>
                  <input
                    mat-button 
                    [disabled]="loading"
                    readonly
                    formControlName="nieIssuanceDate"
                    type="text"
                    id="nieIssuanceDate" 
                    class="form-control-user icon-rtl rounded-1" 
                    ngbDatepicker 
                    #nieIssuanceDate="ngbDatepicker" 
                    (click)="nieIssuanceDate.toggle()" 
                    [minDate]="minDateNgStruct" 
                    [maxDate]="maxDateNgStruct" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12" *ngIf="f.identificationType.value === 'DNI'">
                <div class="form-group">
                  <label for="dniExpirationDate">{{
                    "user.form.dniExpirationDate" | translate
                  }}</label>
                  <input
                    mat-button
                    [disabled]="loading"
                    readonly
                    formControlName="dniExpirationDate"
                    type="text"
                    id="dniExpirationDate"
                    class="form-control-user icon-rtl rounded-1"
                    ngbDatepicker
                    #dniExpirationDate="ngbDatepicker"
                    (click)="dniExpirationDate.toggle()"
                    [minDate]="maxDateNgStruct"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12" *ngIf="f.identificationType.value === 'NIE'">
                <div class="form-group">
                  <label for="nieExpirationDate">{{
                    "user.form.nieExpirationDate" | translate
                  }}</label>
                  <input
                    mat-button
                    [disabled]="loading"
                    readonly
                    formControlName="nieExpirationDate"
                    type="text"
                    id="nieExpirationDate"
                    class="form-control-user icon-rtl rounded-1"
                    ngbDatepicker
                    #nieExpirationDate="ngbDatepicker"
                    (click)="nieExpirationDate.toggle()"
                    [minDate]="maxDateNgStruct"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="passport">{{
                    "user.form.passport" | translate
                  }}</label>
                  <input
                    [readOnly]="loading"
                    formControlName="passport"
                    type="text"
                    id="passport"
                    class="form-control-user rounded-1"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="passportExpires">{{
                    "user.form.passportExpires" | translate
                  }}</label>
                  <input
                    mat-button
                    [disabled]="loading"
                    readonly
                    formControlName="passportExpires"
                    type="text"
                    id="passportExpires"
                    class="form-control-user icon-rtl rounded-1"
                    ngbDatepicker
                    #passportExpires="ngbDatepicker"
                    (click)="passportExpires.toggle()"
                    [minDate]="maxDateNgStruct"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="address">{{
                    "common.address" | translate
                  }}</label>
                  <input
                    [readOnly]="loading"
                    formControlName="address"
                    type="text"
                    id="address"
                    class="form-control-user rounded-1"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="postalCode">{{
                    "common.postal-code" | translate
                  }}</label>
                  <input
                    [readOnly]="loading"
                    formControlName="postalCode"
                    type="text"
                    id="postalCode"
                    class="form-control-user rounded-1"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="city">{{ "common.city" | translate }}</label>
                  <input
                    [readOnly]="loading"
                    formControlName="city"
                    type="text"
                    id="city"
                    class="form-control-user rounded-1"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="address">{{
                    "common.province" | translate
                  }}</label>
                  <input
                    [readOnly]="loading"
                    formControlName="province"
                    type="text"
                    id="province"
                    class="form-control-user rounded-1"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="passportIssuanceDate">{{
                    "user.form.passportIssuanceDate" | translate
                  }}</label>
                  <input
                    mat-button
                    [disabled]="loading"
                    readonly
                    formControlName="passportIssuanceDate"
                    type="text"
                    id="passportIssuanceDate"
                    class="form-control-user icon-rtl rounded-1"
                    ngbDatepicker
                    #passportIssuanceDate="ngbDatepicker"
                    (click)="passportIssuanceDate.toggle()"
                    [minDate]="minDateNgStruct"
                    [maxDate]="maxDateNgStruct"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="text-left mt-3">
                  <button
                    id="submit-btn"
                    class="custom-btn btn btn-vyoo-primary text-white round-1 mb-0 rounded-2"
                    ngbAutofocus
                    mat-button
                    [disabled]="!form.valid || loading"
                  >
                    {{ "common.send" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
