<div *ngIf="loading" style="display: block; margin: auto; z-index: 9999">
  <app-loading [userType]="'employee'"></app-loading>
</div>
<div *ngIf="requests">
  <div *ngIf="!isBookeds">
    <h2>{{ "common.requests" | translate }}</h2>
  </div>
  <div id="container-customize">
    <div class="row">
      <div id="goBackButtonContainer" *ngIf="!isBookeds">
        <button mat-button mat-flat-button (click)="goBack()">
          <img src="assets/img/svg/arrow-back.svg" alt="" width="20px" />
        </button>
      </div>

      <div id="filters">
        <div>
          <input
            (input)="setPage()"
            id="search"
            class="form-control form-control--squared form-control--small search-input"
            [(ngModel)]="filter"
            (input)="filter = $event.target.value"
            placeholder="{{ 'common.search' | translate }}"
          />
        </div>
        <ng-container *ngIf="isBookeds">
          <div>
            <select
              [(ngModel)]="component"
              (change)="changeComponent($event.target.value)"
              id="typeOptions"
              class="form-control form-control--squared form-control--small select-input"
            >
              <option hidden value="" disabled selected>
                {{ "company.company-booked.booking-type" | translate }}
              </option>
              <option
                *ngFor="let component of typeComponent; let i = index"
                value="{{ i }}"
              >
                {{ component.text }}
              </option>
            </select>
          </div>
        </ng-container>
        <div>
          <select class="form-control form-control--squared form-control--small select-input" [(ngModel)]="status">
            <option *ngFor="let status of statusOptions" [value]="status.value">
              {{ status.view | translate }}
            </option>
          </select>
        </div>
        <div *ngIf="!isBookeds">
          <select class="form-control form-control--squared form-control--small icon-arrow-rtl" [(ngModel)]="type">
            <option *ngFor="let type of typeOptions" [value]="type.value">
              {{ type.view | translate }}
            </option>
          </select>
        </div>
        <div>
          <button
            type="button"
            class="clear-btn"
            (click)="clearFilters()"
            [disabled]="!canClean()"
            [ngClass]="canClean() ? 'btn-outline-danger' : ''"
          >
            {{ "common.clean" | translate }}
            <img
              src="{{
                !canClean()
                  ? 'assets/img/svg/grey-cross.svg'
                  : 'assets/img/svg/red-cross.svg'
              }}"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="table-container row">
      <div
        class="no-requests"
        *ngIf="
          (
            requests
            | filterRequests
              : nameFilters
              : [this.filter, this.status, this.type]
              : typeFilters
          ).length <= 0
        "
      >
        <img
          class="not_found_image"
          src="assets/img/gallery/flights-not-found.svg"
          alt="No hay archivos"
        />
        <h5>{{ "company.company-detail.no-requests" | translate }}</h5>
      </div>
      <table
        class="table"
        *ngIf="
          (
            requests
            | filterRequests
              : nameFilters
              : [this.filter, this.status, this.type]
              : typeFilters
          ).length > 0
        "
      >
        <thead>
          <tr>
            <th scope="col">
              {{ "authorization.labels.employee" | translate }}
            </th>
            <th scope="col">
              {{ "company.transaction.filters.type" | translate }}
            </th>
            <th scope="col">
              {{ "my-bookeds.user.origin" | translate }}
            </th>
            <th scope="col">
              {{ "my-bookeds.user.destiny" | translate }}
            </th>
            <th scope="col" *ngIf="hasPrivileges">
              {{ "company.transaction.filters.company" | translate }}
            </th>
            <th scope="col" style="width: 10%">
              {{ "common.status" | translate }}
            </th>
            <th scope="col">
              {{ "common.date" | translate }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let request of requests
                | filterRequests
                  : nameFilters
                  : [this.filter, this.status, this.type]
                  : typeFilters
                | slice: lowValue:highValue
            "
          >
            <td>
              {{ request.user ? request.user.name + " " + request.user.lastname : '' }}
            </td>
            <td>
              {{
                (request.type?.toLowerCase() === "hotel"
                  ? "common.hotel"
                  : request.type?.toLowerCase() === "flight"
                  ? "common.flight"
                  : request.type?.toLowerCase() === "train"
                  ? "common.train"
                  : request.type?.toLowerCase() === "car"
                  ? "common.car"
                  : "airbnb.custom.request.option"
                ) | translate
              }}
            </td>
            <td>
              {{ request.from }}
            </td>
            <td>
              {{ request.to || request.city }}
            </td>
            <td *ngIf="hasPrivileges">
              {{ request.company?.name }}
            </td>
            <td>
              <span *ngIf="request.status === 'completed'" class="green-font">{{
                "common.completed" | translate
              }}</span>
              <span *ngIf="request.status === 'active'" class="yellow-font">{{
                "common.pending" | translate
              }}</span>
            </td>
            <td>
              {{ request?.initDate && isValidDate(request.initDate) 
                  ? (request.initDate| date: "dd/MM/yyyy") 
                  : ""}}
              {{
                request?.endDate && isValidDate(request.endDate)
                  ? "- " + (request.endDate | date: "dd/MM/yyyy")
                  : ""
              }}
            </td>
            <td id="container-buttons" ngbDropdown>
              <a
                *ngIf="!hasPrivileges"
                title="{{ 'flight-train.see-book' | translate }}"
                class="btn see-request"
                (click)="onDetails(request)"
              >
                <img src="assets/img/svg/eye.svg" alt="see-book" />
              </a>
              <a *ngIf="hasPrivileges" ngbDropdownToggle>
                <img class="pointer" src="assets/img/svg/black-kebab.svg" />
                <div ngbDropdownMenu>
                  <div class="menu-btn" (click)="onDetails(request)">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/boarding-pass.svg" />
                    </div>
                    <p class="text-left">
                      {{ "common.see-request" | translate }}
                    </p>
                  </div>
                  <div class="menu-btn"  *ngIf="request.status === 'active'"
                  (click)="addBook(request)">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/add-black-icon.svg" />
                    </div>
                    <p class="text-left">
                      {{ "common.create-booking" | translate }}
                    </p>
                  </div>
                  <div class="menu-btn" (click)="changeRequestStatus(request)">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/pen-black.svg" />
                    </div>
                    <p class="text-left" >
                      {{ "common.change-request-status" | translate }}
                    </p>
                  </div>
                </div>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        *ngIf="requests.length > 0 && pagination"
        class="d-flex justify-content-center mt-2 mb-2 col-12"
      >
        <mat-paginator
          #paginator
          [length]="
            (
              requests
              | filterRequests
                : nameFilters
                : [this.filter, this.status, this.type]
                : typeFilters
            ).length
          "
          [pageSize]="5"
          (page)="getPaginatorData($event)"
          showFirstLastButtons
          [hidePageSize]="true"
          style="border-radius: 15px"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
