import { Component, Input, OnInit } from "@angular/core";
import { CompanyService } from "@core/services/company.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { saveAs } from "file-saver";

@Component({
  selector: "app-view-pdf-modal-company",
  templateUrl: "./view-pdf-modal-company.component.html",
  styleUrls: ["./view-pdf-modal-company.component.scss"],
})
export class ViewPdfModalCompanyComponent implements OnInit {
  @Input() blobUrl: any;
  @Input() fileName: string;
  @Input() companyUuid?: string;
  image: any;

  constructor(
    public activeModal: NgbActiveModal,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    if (this.companyUuid) {
      this.companyService
        .getBill(this.blobUrl, this.companyUuid)
        .subscribe((s) => {
          const reader = new FileReader();
          reader.readAsDataURL(s);
          let base64data;
          reader.onloadend = () => {
            base64data = reader.result;
            this.image = base64data;
          };
        });
    }
  }

  downloadPDFBooked() {
    this.companyUuid
      ? saveAs(this.image, this.fileName)
      : window.open(this.blobUrl, "_blank");
  }

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }
}
