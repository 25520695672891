<section id="container">
  <div class="nav-item" ngbDropdown placement="bottom-right">
    <a class="nav-link position-relative" id="dropdownLang" ngbDropdownToggle>
      <img [src]="
          language === 'es'
            ? './assets/img/flags/es.svg'
            : './assets/img/flags/uk.svg'
        " alt="Spanish" class="langimg" />
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownLang" class="dropdownLang text-left">
      <a class="dropdown-item py-1 lang" href="javascript:;" (click)="changeLanguage('es')">
        <img src="./assets/img/flags/es.svg" alt="Spanish" class="langimg" />
        <span [ngClass]="{ 'font-weight-bold': language === 'es' }">{{
          "navbar.spanish" | translate
          }}</span>
      </a>
      <a class="dropdown-item py-1 lang" href="javascript:;" (click)="changeLanguage('en')">
        <img src="./assets/img/flags/uk.svg" alt="English" class="langimg" />
        <span [ngClass]="{ 'font-weight-bold': language === 'en' }">{{
          "navbar.english" | translate
          }}</span>
      </a>
    </div>
  </div>
  <div class="modal-content">
    <div class="modal-body p-4" [ngClass]="{ 'loading-background': loading }">
      <div class="row" *ngIf="!isCompanyCreated">
        <div class="col-12">
          <form [formGroup]="formGeneral">
            <!-- Upload photo -->
            <div class="modify-image-wrapper" *ngIf="isImageSaved" (click)="modifySelect.open()">
              <div class="modify-image">
                <label class="labels">{{
                  "company.company-form.modify-image" | translate
                  }}</label>
                <mat-select class="modify-image-select" #modifySelect>
                  <mat-option (click)="changePhoto()">{{
                    "company.company-form.change-image" | translate
                    }}</mat-option>
                  <mat-option (click)="removeImage()">{{
                    "company.company-form.remove-image" | translate
                    }}</mat-option>
                </mat-select>
              </div>
            </div>
            <div class="form-row mb-3 image-container">
              <div class="col-6">
                <div class="form-group">
                  <p>{{ imageError }}</p>
                  <div class="custom-file fileInputProfileWrap" [ngClass]="{
                      'invalid-image':
                        formGeneral.controls['image'].hasError('required')
                    }">
                    <div class="img-space">
                      <input type="file" (change)="onImageChange($event)" class="fileInputProfile" id="upload-photo" />
                      <ng-container *ngIf="isImageSaved; else elseTemplate">
                        <img [src]="cardImageBase64" />
                      </ng-container>
                      <ng-template #elseTemplate>
                        <img class="upload-photo" src="../../../../../assets/img/svg/upload-photo.svg" />
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5>{{ "common.general" | translate }}</h5>
            <div class="form-row mb-3">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="name">{{ "company.name" | translate }}*</label>
                <input [readOnly]="loading" [ngClass]="{
                    invalid: formGeneral.controls['name'].hasError('required')
                  }" formControlName="name" type="text" id="name" class="form-control rounded-1 form-input" />
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="email">{{ "common.email" | translate }}*</label>
                <input [readOnly]="loading" [ngClass]="{
                    invalid: formGeneral.controls['email'].hasError('required')
                  }" formControlName="email" type="text" id="email" class="form-control rounded-1 form-input" />
              </div>
            </div>
            <div class="form-row mb-3">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="phone">{{ "user.form.phone" | translate }}*</label>
                <input [readOnly]="loading" required formControlName="phone" type="phone" id="phone"
                  class="form-control rounded-1 form-input" />
              </div>
            </div>
            <hr />
            <div class="form-row mb-3">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="cif">{{ "common.cif" | translate }}*</label>
                <input [readOnly]="loading" [ngClass]="{
                    invalid: formGeneral.controls['cif'].hasError('required')
                  }" formControlName="cif" type="cif" id="cif" class="form-control rounded-1 form-input" />
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="address">{{ "common.address" | translate }}*</label>
                <input [readOnly]="loading" formControlName="address" type="text" id="address"
                  class="form-control rounded-1 form-input" />
              </div>
            </div>
            <div class="form-row mb-3">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="city">{{ "common.city" | translate }}*</label>
                <input [readOnly]="loading" formControlName="city" type="text" id="city"
                  class="form-control rounded-1 form-input" />
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="city">{{ "common.province" | translate }}*</label>
                <input [readOnly]="loading" formControlName="province" type="text" id="province"
                  class="form-control rounded-1 form-input" />
              </div>
            </div>
            <div class="form-row mb-3">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="postalCode">{{ "common.postal-code" | translate }}*</label>
                <input [readOnly]="loading" formControlName="postalCode" type="text" id="postalCode"
                  class="form-control rounded-1 form-input" />
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="country">{{ "common.country" | translate }}*</label>
                <mat-select formControlName="country" type="text" id="country"
                  class="form-control rounded-1 form-input">
                  <mat-option *ngFor="let c of countriesList" value="{{ c.name }}">
                    {{ c.name }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12">
          <form [formGroup]="formBilling">
            <h5>{{ "common.billing-title" | translate }}</h5>
            <div class="custom-control custom-checkbox">
              <mat-checkbox class="section" (change)="copyData($event)">
                {{ "company.company-form.copy-data" | translate }}
              </mat-checkbox>
            </div>
            <div class="form-row mb-3">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="identificator">{{ "common.identificator" | translate }}*</label>
                <input [readOnly]="loading" [ngClass]="{
                    invalid:
                      formBilling.controls['identificator'].hasError('required')
                  }" formControlName="identificator" type="text" id="identificator"
                  class="form-control rounded-1 form-input" />
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="businessName">{{ "common.business" | translate }}*</label>
                <input [readOnly]="loading" [ngClass]="{
                    invalid:
                      formBilling.controls['businessName'].hasError('required')
                  }" formControlName="businessName" type="text" id="businessName"
                  class="form-control rounded-1 form-input" />
              </div>
            </div>
            <div class="form-row mb-3">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="addressBilling">{{ "common.address" | translate }}*</label>
                <input [readOnly]="loading" [ngClass]="{
                    invalid:
                      formBilling.controls['address'].hasError('required')
                  }" formControlName="address" type="text" id="addressBilling"
                  class="form-control rounded-1 form-input" />
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="cityBilling">{{ "common.city" | translate }}*</label>
                <input [readOnly]="loading" [ngClass]="{
                    invalid: formBilling.controls['town'].hasError('required')
                  }" formControlName="town" type="text" id="cityBilling" class="form-control rounded-1 form-input" />
              </div>
            </div>
            <div class="form-row mb-3">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="provinceBilling">{{ "common.province" | translate }}*</label>
                <input [readOnly]="loading" [ngClass]="{
                    invalid:
                      formBilling.controls['province'].hasError('required')
                  }" formControlName="province" type="text" id="provinceBilling"
                  class="form-control rounded-1 form-input" />
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="postalCodeBilling">{{ "common.postal-code" | translate }}*</label>
                <input [readOnly]="loading" [ngClass]="{
                    invalid:
                      formBilling.controls['postalCode'].hasError('required')
                  }" formControlName="postalCode" type="text" id="postalCodeBilling"
                  class="form-control rounded-1 form-input" />
              </div>
            </div>
            <div class="form-row mb-3">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <label class="labels" for="countryBilling">{{ "common.country" | translate }}*</label>
                <mat-select formControlName="country" type="text" id="country"
                  class="form-control rounded-1 form-input">
                  <mat-option *ngFor="let c of countriesList" value="{{ c.name }}">
                    {{ c.name }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </form>
          <div class="terms custom-control custom-checkbox">
            <mat-checkbox class="section" (change)="acceptConditions($event)">
              {{ "common.accept-terms-1" | translate }}
              <a href="https://www.vyootrip.com/terminos-de-condiciones-y-uso-de-servicios-de-viajes-de-negocios/"
                target="_blank" class="terms-conditions">{{ "common.accept-terms-2" | translate }}</a>
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isCompanyCreated">
        <div class="col-12">
          <!-- <form
            [formGroup]="userForm"
            [ngClass]="{ 'loading-background': loading }"
          >
            <div
              class="modify-image-wrapper"
              *ngIf="isImageSaved"
              (click)="modifySelect.open()"
            >
              <div class="modify-image">
                <label class="labels">{{
                  "company.company-form.modify-image" | translate
                }}</label>
                <mat-select class="modify-image-select" #modifySelect>
                  <mat-option (click)="changePhoto()">{{
                    "company.company-form.change-image" | translate
                  }}</mat-option>
                  <mat-option (click)="removeImage()">{{
                    "company.company-form.remove-image" | translate
                  }}</mat-option>
                </mat-select>
              </div>
            </div>
            <div class="form-row mb-3 image-container">
              <div class="col-6">
                <div class="form-group">
                  <p>{{ imageError }}</p>
                  <div
                    class="custom-file fileInputProfileWrap"
                    [ngClass]="{
                      'invalid-image':
                        userForm.controls['image'].hasError('required')
                    }"
                  >
                    <div class="img-space">
                      <input
                        type="file"
                        (change)="onImageChange($event)"
                        class="fileInputProfile"
                        id="upload-photo"
                      />
                      <ng-container *ngIf="isImageSaved; else elseTemplate">
                        <img [src]="cardImageBase64" />
                      </ng-container>
                      <ng-template #elseTemplate>
                        <img
                          class="upload-photo"
                          src="../../../../../assets/img/svg/upload-photo.svg"
                        />
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5>{{ "company.create-company-admin" | translate }}</h5>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="name">{{ "common.name" | translate }}*</label>
                  <input
                    [readOnly]="loading"
                    formControlName="name"
                    type="text"
                    id="name"
                    class="form-control rounded-1"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="lastname"
                    >{{ "user.form.surname" | translate }}*</label
                  >
                  <input
                    [readOnly]="loading"
                    formControlName="lastname"
                    type="text"
                    id="lastname"
                    class="form-control rounded-1"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="email">{{ "common.email" | translate }}*</label>
                  <input
                    [readOnly]="loading"
                    formControlName="email"
                    type="email"
                    id="email"
                    class="form-control rounded-1"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="birthdate"
                    >{{ "user.form.birthdate" | translate }}*</label
                  >
                  <input
                    mat-button
                    [disabled]="loading"
                    readonly
                    formControlName="birthdate"
                    type="text"
                    id="birthdate"
                    class="form-control birthdate rounded-1 icon-rtl"
                    ngbDatepicker
                    #datePicker="ngbDatepicker"
                    (click)="datePicker.toggle()"
                    [minDate]="minDateNgStruct"
                    [maxDate]="maxDateNgStruct"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="dni">{{ "user.form.dni" | translate }}*</label>
                  <input
                    [readOnly]="loading"
                    formControlName="dni"
                    type="text"
                    id="dni"
                    class="form-control rounded-1"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="passport">{{
                    "user.form.passport" | translate
                  }}</label>
                  <input
                    [readOnly]="loading"
                    formControlName="passport"
                    type="text"
                    id="passport"
                    class="form-control rounded-1"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="dniIssuanceDate"
                    >{{ "user.form.dniIssuanceDate" | translate }}*</label
                  >
                  <input
                    mat-button
                    [disabled]="loading"
                    readonly
                    formControlName="dniIssuanceDate"
                    type="text"
                    id="dniIssuanceDate"
                    class="form-control birthdate rounded-1 icon-rtl"
                    ngbDatepicker
                    #dniIssuanceDate="ngbDatepicker"
                    (click)="dniIssuanceDate.toggle()"
                    [minDate]="minDateNgStruct"
                    [maxDate]="maxDateNgStruct"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="dniExpirationDate"
                    >{{ "user.form.dniExpirationDate" | translate }}*</label
                  >
                  <input
                    mat-button
                    [disabled]="loading"
                    readonly
                    formControlName="dniExpirationDate"
                    type="text"
                    id="dniExpirationDate"
                    class="form-control birthdate rounded-1 icon-rtl"
                    ngbDatepicker
                    #dniExpirationDate="ngbDatepicker"
                    (click)="dniExpirationDate.toggle()"
                    [minDate]="maxDateNgStruct"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="passportIssuanceDate">{{
                    "user.form.passportIssuanceDate" | translate
                  }}</label>
                  <input
                    mat-button
                    [disabled]="loading"
                    readonly
                    formControlName="passportIssuanceDate"
                    type="text"
                    id="passportIssuanceDate"
                    class="form-control birthdate rounded-1 icon-rtl"
                    ngbDatepicker
                    #passportIssuanceDate="ngbDatepicker"
                    (click)="passportIssuanceDate.toggle()"
                    [minDate]="minDateNgStruct"
                    [maxDate]="maxDateNgStruct"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="passportExpires">{{
                    "user.form.passportExpires" | translate
                  }}</label>
                  <input
                    mat-button
                    [disabled]="loading"
                    readonly
                    formControlName="passportExpires"
                    type="text"
                    id="passportExpires"
                    class="form-control birthdate rounded-1 icon-rtl"
                    ngbDatepicker
                    #passportExpires="ngbDatepicker"
                    (click)="passportExpires.toggle()"
                    [minDate]="maxDateNgStruct"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="passportCountryEmitter">{{
                    "user.form.passportCountryEmitter" | translate
                  }}</label>
                  <input
                    [readOnly]="loading"
                    formControlName="passportCountryEmitter"
                    type="text"
                    id="passportCountryEmitter"
                    class="form-control rounded-1"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="phone"
                    >{{ "user.form.phone" | translate }}*</label
                  >
                  <input
                    [readOnly]="loading"
                    formControlName="phone"
                    type="text"
                    id="phone"
                    class="form-control rounded-1"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="employeeNumber"
                    >{{ "user.form.employeeNumber" | translate }}*</label
                  >
                  <input
                    [readOnly]="loading"
                    formControlName="employeeNumber"
                    type="text"
                    id="employeeNumber"
                    class="form-control rounded-1"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="gender"
                    >{{ "user.form.gender" | translate }}*</label
                  >
                  <select
                    [attr.disabled]="loading ? '' : null"
                    formControlName="gender"
                    id="gender"
                    class="form-control rounded-1"
                  >
                    <option
                      *ngFor="let genderOption of genderOptions"
                      [ngValue]="genderOption.value"
                    >
                      {{ genderOption.text | translate }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="resident"
                    >{{ "user.form.resident" | translate }}*</label
                  >
                  <select
                    [attr.disabled]="loading ? '' : null"
                    formControlName="resident"
                    id="resident"
                    class="form-control rounded-1"
                  >
                    <option
                      *ngFor="let residentOption of residentOptions"
                      [ngValue]="residentOption.value"
                    >
                      {{ residentOption.text | translate }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-xs-12">
                <div class="form-group">
                  <label for="address"
                    >{{ "common.address" | translate }}*</label
                  >
                  <input
                    [readOnly]="loading"
                    formControlName="address"
                    type="text"
                    id="address"
                    class="form-control rounded-1"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="city">{{ "common.city" | translate }}*</label>
                  <input
                    [readOnly]="loading"
                    formControlName="city"
                    type="text"
                    id="city"
                    class="form-control rounded-1"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="address"
                    >{{ "common.province" | translate }}*</label
                  >
                  <input
                    [readOnly]="loading"
                    formControlName="province"
                    type="text"
                    id="province"
                    class="form-control rounded-1"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="postalCode"
                    >{{ "common.postal-code" | translate }}*</label
                  >
                  <input
                    [readOnly]="loading"
                    formControlName="postalCode"
                    type="text"
                    id="postalCode"
                    class="form-control rounded-1"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="country"
                    >{{ "common.country" | translate }}*</label
                  >
                  <select
                    formControlName="country"
                    type="text"
                    id="country"
                    class="form-control rounded-1"
                  >
                    <option *ngFor="let c of countriesList" [ngValue]="c.name">
                      {{ c.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="nationality"
                    >{{ "user.form.nationality" | translate }}*</label
                  >
                  <select
                    formControlName="nationality"
                    type="text"
                    id="nationality"
                    class="form-control rounded-1"
                  >
                    <option *ngFor="let c of countriesList" [ngValue]="c.name">
                      {{ c.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="currentCountry"
                    >{{ "user.form.currentCountry" | translate }}*</label
                  >
                  <select
                    formControlName="currentCountry"
                    type="text"
                    id="currentCountry"
                    class="form-control rounded-1"
                  >
                    <option *ngFor="let c of countriesList" [ngValue]="c.name">
                      {{ c.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </form> -->
          <form [formGroup]="userForm">
            <h5>{{ "common.required-field" | translate }}</h5>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="name">{{ "common.name" | translate }}*</label>
                  <input [readOnly]="loading" formControlName="name" type="text" id="name"
                    class="form-control rounded-1" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="lastname">{{ "user.form.surname" | translate }}*</label>
                  <input [readOnly]="loading" formControlName="lastname" type="text" id="lastname"
                    class="form-control rounded-1" />
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <label for="email">{{ "common.email" | translate }}*</label>
                  <input [readOnly]="loading" formControlName="email" type="email" id="email"
                    class="form-control rounded-1" />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="birthdate">{{ "user.form.birthdate" | translate }}*</label>
                  <input mat-button [disabled]="loading" readonly formControlName="birthdate" type="text" id="birthdate"
                    class="form-control birthdate rounded-1 icon-rtl" ngbDatepicker #datePicker="ngbDatepicker"
                    (click)="datePicker.toggle()" [minDate]="minDateNgStruct" [maxDate]="maxDateNgStruct" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="gender">{{ "user.form.gender" | translate }}*</label>
                  <select [attr.disabled]="loading ? '' : null" formControlName="gender" id="gender"
                    class="form-control rounded-1">
                    <option *ngFor="let genderOption of genderOptions" [ngValue]="genderOption.value">
                      {{ genderOption.text | translate }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="phone">{{ "user.form.phone" | translate }}*</label>
                  <input [readOnly]="loading" formControlName="phone" type="text" id="phone"
                    class="form-control rounded-1" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="dni">{{ "user.form.dni" | translate }}*</label>
                  <input [readOnly]="loading" formControlName="dni" type="text" id="dni"
                    class="form-control rounded-1" />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group-user">
                  <label class="tittle-form-control" for="nationality">{{ "user.form.nationality" | translate
                    }}*</label>
                  <select formControlName="nationality" type="text" id="nationality"
                    class="form-control rounded-1 icon-arrow-rtl">
                    <option disabled selected hidden></option>
                    <option *ngFor="let c of countriesList" [ngValue]="c.name">
                      {{ c.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <h5 id="optional">
              {{ "common.optional-field" | translate }}
              <img src="assets/img/svg/info.svg"
                alt="info-icon" />
            </h5>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="dniIssuanceDate">{{
                    "user.form.dniIssuanceDate" | translate
                    }}</label>
                  <input mat-button [disabled]="loading" readonly formControlName="dniIssuanceDate" type="text"
                    id="dniIssuanceDate" class="form-control icon-rtl birthdate rounded-1" ngbDatepicker
                    #dniIssuanceDate="ngbDatepicker" (click)="dniIssuanceDate.toggle()" [minDate]="minDateNgStruct"
                    [maxDate]="maxDateNgStruct" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="dniExpirationDate">{{
                    "user.form.dniExpirationDate" | translate
                    }}</label>
                  <input mat-button [disabled]="loading" readonly formControlName="dniExpirationDate" type="text"
                    id="dniExpirationDate" class="form-control birthdate icon-rtl rounded-1" ngbDatepicker
                    #dniExpirationDate="ngbDatepicker" (click)="dniExpirationDate.toggle()"
                    [minDate]="maxDateNgStruct" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="passport">{{
                    "user.form.passport" | translate
                    }}</label>
                  <input [readOnly]="loading" formControlName="passport" type="text" id="passport"
                    class="form-control rounded-1" />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="passportExpires">{{
                    "user.form.passportExpires" | translate
                    }}</label>
                  <input mat-button [disabled]="loading" readonly formControlName="passportExpires" type="text"
                    id="passportExpires" class="form-control birthdate icon-rtl rounded-1" ngbDatepicker
                    #passportExpires="ngbDatepicker" (click)="passportExpires.toggle()" [minDate]="maxDateNgStruct" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="address">{{ "common.address" | translate }}</label>
                  <input [readOnly]="loading" formControlName="address" type="text" id="address"
                    class="form-control rounded-1" />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="postalCode">{{
                    "common.postal-code" | translate
                    }}</label>
                  <input [readOnly]="loading" formControlName="postalCode" type="text" id="postalCode"
                    class="form-control rounded-1" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="city">{{ "common.city" | translate }}</label>
                  <input [readOnly]="loading" formControlName="city" type="text" id="city"
                    class="form-control rounded-1" />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="address">{{ "common.province" | translate }}</label>
                  <input [readOnly]="loading" formControlName="province" type="text" id="province"
                    class="form-control rounded-1" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="passportIssuanceDate">{{
                    "user.form.passportIssuanceDate" | translate
                    }}</label>
                  <input mat-button [disabled]="loading" readonly formControlName="passportIssuanceDate" type="text"
                    id="passportIssuanceDate" class="form-control birthdate icon-rtl rounded-1" ngbDatepicker
                    #passportIssuanceDate="ngbDatepicker" (click)="passportIssuanceDate.toggle()"
                    [minDate]="minDateNgStruct" [maxDate]="maxDateNgStruct" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="button-container">
        <button class="submit-button" (click)="!isCompanyCreated ? onSubmitCompany() : onSubmitUser()" ngbAutofocus
          mat-button [disabled]="
            !isCompanyCreated
              ? (!formGeneral.valid && !hasAcceptConditions) || loading
              : !userForm.valid || loading
          ">
          {{ "common.send" | translate }}
        </button>
      </div>
    </div>
  </div>
</section>