import { Hotel } from "./hotel";

export class Booked {
  uuid: string;
  status: string;
  createdAt: string;
  checkIn: string;
  checkOut: string;
  hotel?: Hotel;
  totalPrice: number;
  currency: string;
  cancellationDate?: string;
  bonoFileUrl: string;
  maxCancellationDate?: string;
  cancellationAt?: string;
  bonoFileUuid: string;
  apiReference?: string;
  author?: string;
  hotelName?: string;
  bookedApiUuid?: string;
}
