import { Options } from "@angular-slider/ngx-slider";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  /*   MatDialogRef,   */
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NavigationExtras, Router } from "@angular/router";
import { User } from "@core/models";
import { AuthService, CompanyService, StateService, UserService, UtilsService } from "@core/services";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { CustomDateParserFormatter } from "@shared/component/date-parser";
import { AppState } from "app/app.reducer";
import trainStations from "assets/utils/train-stations.json";
import { Subscription } from "rxjs/internal/Subscription";
import { DialogTravelersComponent } from "../dialog-travelers/dialog-travelers.component";
import swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-train-search",
  templateUrl: "./train-search.component.html",
  styleUrls: ["./train-search.component.scss"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class TrainSearchComponent implements OnInit, OnDestroy {
  @Input() searchHasFailed: boolean;
  checkedOneway = false;
  trainCards = [];
  trainStationList = [];
  formSearchTrain: UntypedFormGroup;
  originCode = "";
  destinationCode = "";
  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 10,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  searchConfig: {
    departDate: string;
    returnDate: string;
    travelers: any[];
    origin: string;
    originText: string;
    destination: string;
    destinationText: string;
    travelCard: {
      value: string;
      viewValue: string;
    };
    oneWay: boolean;
    filters?: {
      departureTime?: string;
      arrivalTime?: string;
      returnDepartureTime?: string;
      returnArrivalTime?: string;
    };
  };
  subscriptions: Subscription[] = [];
  isEmployee = false;
  isAdmin = false;
  isUser = false;
  userToBook: string;
  userUuid: string;
  canUseTRM = false;
  companyUuid: string;
  groupFilters: any = {};
  isRequest: boolean;
  user: User;
  isDialog = false;
  @Output() searchSubmitEmitter = new EventEmitter<any>();
  optionsTimeDeparture: Options = {
    floor: 0,
    ceil: 24,
    step: 1,
    translate: (value: number): string => {
      return value + "h";
    },
  };
  minTimeDeparture = 0;
  maxTimeDeparture = 24;
  minReturnTimeDeparture = 0;
  maxReturnTimeDeparture = 24;
  timeFilters = {
    departureTime: null,
    arrivalTime: null,
    returnDepartureTime: null,
    returnArrivalTime: null,
  };

  companyUsers: { name: string; lastname: string; uuid: string }[] = [];
  defaultUser: any;
  travelers: string[] = [];
  @Input() travelersSelected: any;
  @Output() emitSelectedTravelersTrain: EventEmitter<any> = new EventEmitter<any>();
  public filterControl = new FormControl();
  public filteredUsers: { name: string; lastname: string; uuid: string }[];

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    public utilsService: UtilsService,
    private stateService: StateService,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    /* @Optional() private dialogRef: MatDialogRef<TrainSearchComponent>, */
    @Optional() @Inject(MAT_DIALOG_DATA) data: any,
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private companyService: CompanyService
  ) {
    this.subscriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() !== "USER") {
          this.isAdmin = true;
          if (s.user.type?.toUpperCase() === "EMPLOYEE") {
            this.isEmployee = true;
          }
          this.userToBook = localStorage.getItem("userToBook")
            ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
            : null;
          if (this.userToBook != null) {
            this.userUuid = this.userToBook;
          }
          this.canUseTRM = s.user.type
            ? s.user.plan && s.user.plan.permissions.canUseTRM
            : s.user.user.plan && s.user.user.plan.permissions.canUseTRM;
          if(!this.canUseTRM){
            this.canUseTRM = this.authService.userCompanyPlan?.permissions.canUseTRM;
          }
        } else {
          this.companyUuid = s.user.companyUuid;
          this.user = s.user;
          this.userUuid = this.user.uuid;
        }
      })
    );

    if (data != null) {
      this.isDialog = data.isDialog === true;
      this.searchConfig = data.searchConfig ? data.searchConfig : null;
      if (this.searchConfig?.filters != null) {
        if (this.searchConfig.filters.departureTime != null) {
          this.minTimeDeparture = Number.parseInt(
            this.searchConfig.filters.departureTime.split(":")[0]
          );
          this.maxTimeDeparture = Number.parseInt(
            this.searchConfig.filters.arrivalTime.split(":")[0]
          );
          this.timeFilters.departureTime =
            this.searchConfig.filters.departureTime;
          this.timeFilters.arrivalTime = this.searchConfig.filters.arrivalTime;
        }
        if (
          this.searchConfig.oneWay === false &&
          this.searchConfig.filters.returnDepartureTime != null
        ) {
          this.minReturnTimeDeparture = Number.parseInt(
            this.searchConfig.filters.returnDepartureTime.split(":")[0]
          );
          this.maxReturnTimeDeparture = Number.parseInt(
            this.searchConfig.filters.returnArrivalTime.split(":")[0]
          );
          this.timeFilters.returnDepartureTime =
            this.searchConfig.filters.returnDepartureTime;
          this.timeFilters.returnArrivalTime =
            this.searchConfig.filters.returnArrivalTime;
        }
      }
    }

    if (window.history.state.dataFromCompany) {
      const dataFromCompany = window.history.state.dataFromCompany;
      this.userUuid = dataFromCompany.userUuid;
      this.companyUuid = dataFromCompany.companyUuid;
      this.isUser = false;
      this.stateService.setDataFromCompany(dataFromCompany);
    } else {
      const dataFromState = this.stateService.dataFromCompany$.getValue();
      if (dataFromState && dataFromState.userUuid) {
        this.userUuid = dataFromState.userUuid;
        this.companyUuid = dataFromState.companyUuid;
        this.isUser = true;
      }
    }
  }

  ngOnInit(): void {
    this.getCompanyUsers();
    this.trainCards = this.trainCards.concat([
      {
        value: "plus50",
        viewValue: "Renfe Plus50",
      },
      {
        value: "goldenCard",
        viewValue: "Renfe Golden Card",
      },
    ]);
    if (this.searchConfig == null) {
      this.searchConfig = {
        departDate: null,
        returnDate: null,
        travelers: [],
        origin: null,
        originText: null,
        destination: null,
        destinationText: null,
        travelCard: null,
        oneWay: false,
      };
    } else {
      this.searchConfig.travelCard = this.trainCards.find(
        (card) => card.value === this.searchConfig.travelCard
      );
    }
    this.subscriptions.push(
      this.userService.getGroupUser(this.userUuid).subscribe((g) => {
        this.groupFilters = g.filters;
        this.isRequest = this.groupFilters.onlyTrainAuthorization;
      }),
      this.userService.getUser(this.userUuid).subscribe((u) => {
        this.user = u;
        if (
          this.user != null &&
          (this.searchConfig.travelers == null ||
            this.searchConfig.travelers.length <= 0)
        ) {
          const userBirthdate: any = new Date(this.user.birthdate);
          const currDate: any = new Date();
          const age = currDate - userBirthdate;
          const ageFinal = Math.floor(age / 31557600000);
          this.searchConfig.travelers.push({
            age: ageFinal,
          });
        }
        this.formSearchTrain = this.fb.group({
          origin: [this.searchConfig.origin, [Validators.required]],
          destination: [this.searchConfig.destination, [Validators.required]],
          departDate: [this.searchConfig.departDate, [Validators.required]],
          returnDate: [this.searchConfig.returnDate, [Validators.required]],
          travelers: [this.searchConfig.travelers, [Validators.required]],
          travelCard: [this.searchConfig.travelCard],
        });
        if (this.searchConfig.departDate != null) {
          this.setOneWaySearch(this.searchConfig.oneWay);
        }
      })
    );
    this.updateTravelers();
  }

  switchOriginDestination() {
    const origin = this.searchConfig.originText;
    const destination = this.searchConfig.destinationText;
    this.searchConfig.originText = destination;
    this.searchConfig.destinationText = origin;
    const originCode = this.formSearchTrain.value.origin;
    const destinationCode = this.formSearchTrain.value.destination;
    this.formSearchTrain.patchValue({ origin: destinationCode });
    this.formSearchTrain.patchValue({ destination: originCode });
  }

  setOneWaySearch(set: boolean) {
    if (set) {
      this.checkedOneway = true;
      this.formSearchTrain.get("returnDate").clearValidators();
      this.formSearchTrain.patchValue({ returnDate: null });
    } else {
      this.checkedOneway = false;
      this.formSearchTrain.get("returnDate").setValidators(Validators.required);
    }
    this.formSearchTrain.get("returnDate").updateValueAndValidity();
  }

  getTrainStations(value: string) {
    this.trainStationList = [];
    if (value !== "" && value.length > 2) {
      trainStations.Table1.forEach((e) => {
        if (
          e.CODIGO_PROVEEDOR.includes(value) ||
          e.CODIGO_PROVEEDOR.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(value?.toLowerCase()) ||
          e.NOMBRE.includes(value) ||
          e.NOMBRE?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              value
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
        ) {
          this.trainStationList.push({
            title: e.NOMBRE,
            value: e.CODIGO_PROVEEDOR,
          });
        }
      });
    } else {
      this.trainStationList = [];
    }
    return this.trainStationList;
  }

  optionSelected(field: string, value: string) {
    if (field === "origin") {
      this.trainStationList.forEach((s) => {
        if (s.title === value) {
          this.originCode = s.value;
          this.searchConfig.originText = value;
          this.formSearchTrain.patchValue({ origin: this.originCode });
        }
      });
      this.trainStationList = [];
    }
    if (field === "destination") {
      this.trainStationList.forEach((s) => {
        if (s.title === value) {
          this.destinationCode = s.value;
          this.searchConfig.destinationText = value;
          this.formSearchTrain.patchValue({
            destination: this.destinationCode,
          });
        }
      });
      this.trainStationList = [];
    }
  }

  openTravelerSelector() {
    const dialogTravelers = this.dialog.open(DialogTravelersComponent, {
      panelClass: "new-dialog",
      data: {
        travelerList: JSON.parse(
          JSON.stringify(this.formSearchTrain.value.travelers)
        ),
      },
    });
    dialogTravelers.afterClosed().subscribe((result) => {
      if (result != null) {
        this.formSearchTrain.patchValue({ travelers: result });
        this.searchConfig.travelers = result;
      }
      this.updateTravelers();
    });
  }

  selectCard(value: any) {
    this.formSearchTrain.patchValue({ travelCard: value });
  }

  checkOriginOrDestinationMatch() {
    if (
      this.formSearchTrain.value.origin ===
      this.formSearchTrain.value.destination
    ) {
      return true;
    } else {
      return false;
    }
  }

  submit() {
    // INIT: SWAL UNTIL TRAINLINE INTEGRATION
    this.translateService.get([
      "train.service-not-available.title",
      "train.service-not-available.content-1",
      "train.service-not-available.content-2",
      "common.access",
    ]).subscribe((response) => {
      swal.fire({
        title: response["train.service-not-available.title"],
        html: `<div class='dialog-container' style="white-space: pre-wrap; text-align: left; display: flex; flex-direction: column;">
            <p>${response["train.service-not-available.content-1"]}</p>
            <button class="not-available-button custom-btn"
              style="width: fit-content; align-self: center; margin: 0 0 1rem 0;border: none;
              width: 45%;">${response["common.access"]}</button>
            <p>${response["train.service-not-available.content-2"]}</p>
          </div>`,
        showConfirmButton: false,
        didOpen: (() => {
          const fireButton = document.querySelector('.not-available-button');
          fireButton.addEventListener('click', () => {
            swal.close();
            this.goToCreateCustomTrain();
          })
        }),
      });
    });
    // END: SWAL UNTIL TRAINLINE INTEGRATION

    /* if (this.checkOriginOrDestinationMatch() === false) {
      const formValue = this.formSearchTrain.value;
      const inputOrigin = document.getElementById("origin")["value"];
      if (formValue.origin != inputOrigin) {
        const originTrains = this.getTrainStations(inputOrigin);
        if (originTrains.length > 0) {
          const originTrainsNames = originTrains.map((s) => s.title);
          formValue.origin =
            originTrains[
              this.utilsService.inputIsInList(inputOrigin, originTrainsNames)
            ].value;
        }
      }
      const inputDestination = document.getElementById("destination")["value"];
      if (formValue.destination != inputDestination) {
        const destinationTrains = this.getTrainStations(inputDestination);
        if (destinationTrains.length > 0) {
          const destinationTrainsTrainsNames = destinationTrains.map(
            (s) => s.title
          );
          formValue.destination =
            destinationTrains[
              this.utilsService.inputIsInList(
                inputDestination,
                destinationTrainsTrainsNames
              )
            ].value;
        }
      }
      this.searchConfig = {
        departDate: formValue.departDate,
        returnDate: formValue.returnDate,
        travelers: formValue.travelers,
        origin: formValue.origin,
        originText: inputOrigin,
        destination: formValue.destination,
        destinationText: inputDestination,
        travelCard:
          formValue.travelCard != null ? formValue.travelCard.value : null,
        oneWay: this.checkedOneway,
        filters: {
          departureTime: null,
          arrivalTime: null,
          returnDepartureTime: null,
          returnArrivalTime: null,
        },
      };
      if (
        this.timeFilters.departureTime != null ||
        this.timeFilters.arrivalTime != null
      ) {
        this.searchConfig.filters.departureTime =
          this.timeFilters.departureTime;
        this.searchConfig.filters.arrivalTime = this.timeFilters.arrivalTime;
      }
      if (
        this.timeFilters.returnDepartureTime &&
        this.checkedOneway === false
      ) {
        this.searchConfig.filters.returnDepartureTime =
          this.timeFilters.returnDepartureTime;
        this.searchConfig.filters.returnArrivalTime =
          this.timeFilters.returnArrivalTime;
      }
      if (this.isDialog === true) {
        this.dialogRef.close(this.searchConfig);
      } else {
        let selectedUuids = this.travelers.slice();
        if (this.userUuid) {
          selectedUuids.unshift(this.userUuid);
        }
        this.emitSelectedTravelersTrain.emit(selectedUuids);
        this.searchSubmitEmitter.emit(this.searchConfig);
      }
    } */
  }

  getSliderValue(value: number, event: any) {
    switch (value) {
      case 1:
        this.timeFilters.departureTime = `${event.value
          .toString()
          .padStart(2, "0")}:00`;
        this.timeFilters.arrivalTime = `${event.highValue
          .toString()
          .padStart(2, "0")}:00`;
        break;
      case 2:
        this.timeFilters.returnDepartureTime = `${event.value
          .toString()
          .padStart(2, "0")}:00`;
        this.timeFilters.returnArrivalTime = `${event.highValue
          .toString()
          .padStart(2, "0")}:00`;
        break;
      default:
        break;
    }
  }

  goToCreateCustomTrain() {
    const navigationExtras: NavigationExtras = {
      state: {
        canBookHotel: this.groupFilters.requestBooked,
        canRequestCustomFlight: this.groupFilters.requestCustomFlight,
        canRequestCustomTrain: this.groupFilters.requestCustomTrain,
        canRequestCustomCar: this.groupFilters.requestCar,
        canRequestAirbnb: this.groupFilters.lodgingType === "all" || "room",
        userUuid: this.userToBook ? this.userToBook : this.userUuid,
        companyUuid: this.companyUuid,
        userImageBase64: this.user.image,
        isFromSearchFail: true,
        searchFailData: this.searchConfig,
      },
    };
    this.router.navigate(["/custom-services/train"], navigationExtras);
  }

  getCompanyUsers() {
    if (!this.companyUuid) {
      this.userService.getUser(this.userUuid).subscribe(
        (res) => {
          this.companyUuid = res.companyUuid;
          this.fetchCompanyUsers();
        },
        (err) => {}
      );
    } else {
      this.fetchCompanyUsers();
    }
  }

  fetchCompanyUsers() {
    if (this.companyUuid) {
      this.companyService.getCompanyUsers(this.companyUuid).subscribe(
        (res) => {
          this.companyUsers = res.map((user) => ({
            name: user.name,
            lastname: user.lastname,
            uuid: user.uuid,
          }));
          
          this.filteredUsers = this.companyUsers;
          this.setInitialTraveler();
        },
        (err) => {}
      );
    }
  }

  setInitialTraveler() {
    const user = this.companyUsers.find((user) => user.uuid === this.userUuid);
    if (user) {
      this.defaultUser = user;
    }
    if (!this.travelersSelected) {
      this.updateTravelers();
    }
  }

  updateTravelers() {
    const user = this.companyUsers.find((user) => user.uuid === this.userUuid);
    if (user) {
      this.defaultUser = user;
    }
    for (let i = this.travelers.length; i < this.searchConfig.travelers.length - 1; i++) {
      if (!this.travelers[i]) {
        this.travelers[i] = "";
      }
    }
    if (this.travelers.length > this.searchConfig.travelers.length - 1) {
      this.travelers = this.travelers.slice(0, this.searchConfig.travelers.length - 1);
    }
    setTimeout(() => {
      this.travelers = [...this.travelers];
    });
  }

  applyFilter(filterValue: string) {
    this.filteredUsers = this.companyUsers.filter(user =>
      `${user.name} ${user.lastname}`.toLowerCase().includes(filterValue.toLowerCase())
    );
  }
  
  onPanelClose() {
    this.filterControl.setValue('');
    this.filteredUsers = this.companyUsers;
  }
  
  onOpenedChange(event: boolean, input: HTMLInputElement) {
    if (event) {
      input.focus();
    }
  }

  allTravelersSelected(): boolean {
    return this.travelers.every(traveler => traveler !== null && traveler !== undefined && traveler !== '');
  }
  
  trackByIndex(index: number) {
    return index;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
