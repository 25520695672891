import { PaginationConfig } from "@interfaces";
import { Action } from "@ngrx/store";
export const MY_BOOKEDS_PAGE = "[MyBookeds] Page";

export class MyBookedsPageAction implements Action {
  readonly type = MY_BOOKEDS_PAGE;

  constructor(public paginationConfig: PaginationConfig) {}
}

export type MyBookedsActions = MyBookedsPageAction;
