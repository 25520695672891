<div id="back-button">
  <button type="button" class="close" aria-label="Close" (click)="goBack()">
    <img src="/assets/img/svg/arrow-back.svg"/>
  </button>
</div>
<div class="modal-header-departament">
  <h4 *ngIf="group" class="modal-title heading-5" id="modal-basic-title">
    {{ "group.group-form.edit-group" | translate }}
  </h4>
  <h4 *ngIf="!group" class="modal-title heading-5" id="modal-basic-title">
    {{ "departament.departament-form.create-departament" | translate }}
  </h4>
</div>
<div class="modal-header-explain pb-2">
  <h4 class="modal-title heading-5">
    {{ "departament.departament-form.explain-departament" | translate }}
  </h4>
</div>
<div class="modal-body p-3 pr-5 pl-5 pb-5">
  <form
    [formGroup]="form"
    [ngClass]="{ 'loading-background': loading }"
    (ngSubmit)="form.valid && !loading ? onSubmit() : (isInvalid = true)"
  >
    <div class="row">
      <div class="col-12 mb-3">
        <div class="form-group-name col-md-6 col-xs-12">
          <label class="tittle-form-control-departament" for="name"
            >{{ "common.name" | translate }}*</label
          >
          <input
            [ngClass]="{
              'input-error': !form.controls.name.valid && isInvalid
            }"
            class="form-control-departament form-control"
            [readOnly]="loading"
            formControlName="name"
            type="text"
            id="name"
          />
          <div *ngIf="form.get('name').touched && !form.controls.name.valid && isInvalid">
            {{"group.group-form.error.name" | translate}}
          </div>
        </div>

        <h4 class="heading-6">
          {{ "group.group-form.hotelFilters" | translate }}
        </h4>
        <div class="row form-group">
          <div class="col-md-4 col-xs-12">
            <div class="form-group-departament">
              <label class="tittle-form-control-departament" for="lodgingType"
                >{{ "group.group-form.room-type" | translate }}*</label
              >
              <select
                [attr.disabled]="loading ? '' : null"
                formControlName="lodgingType"
                id="lodgingType"
                class="form-control-departament form-control icon-arrow-rtl"
                [ngClass]="{
                  'input-error': !form.controls.lodgingType.valid && isInvalid
                }"
              >
                <option
                  *ngFor="let lodgingTypeOption of lodgingTypeOptions"
                  [ngValue]="lodgingTypeOption.value"
                >
                  {{ lodgingTypeOption.text | translate }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="form-group-departament-stars">
              <label
                class="tittle-form-control-departament"
                for="stars"
                [ngClass]="{
                  'stars-error': !form.controls.stars.valid && isInvalid
                }"
                >{{ "common.stars" | translate }}*</label
              >
              <ngb-rating
                id="stars"
                [max]="5"
                [(rate)]="starRating"
                [readonly]="loading"
                formControlName="stars"
                id="stars"
              ></ngb-rating>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <label class="tittle-form-control-departament" for="priceMin"
              >{{
                "departament.departament-form.range-price" | translate
              }}*</label
            >
            <div class="row col-md-12 col-xs-12 pl-0">
              <div class="col-md-6 col-xs-6">
                <div class="form-group">
                  <input
                    [readOnly]="loading"
                    formControlName="priceMin"
                    type="number"
                    id="priceMin"
                    class="form-control-departament form-control w-100"
                    [ngClass]="{
                      'input-error': !form.controls.priceMin.valid && isInvalid
                    }"
                  />
                </div>
              </div>
              <div class="col-md-6 col-xs-6">
                <div class="form-group">
                  <input
                    [readOnly]="loading"
                    formControlName="priceMax"
                    type="number"
                    id="priceMax"
                    class="form-control-departament form-control w-100"
                    [ngClass]="{
                      'input-error': !form.controls.priceMax.valid && isInvalid
                    }"
                  />
                  <div *ngIf="form.get('priceMax').touched && !form.controls.priceMax.valid && isInvalid">
                    {{"group.group-form.error.priceMax" | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="extra-info col-md-8 col-xs-12">
            <img src="assets/img/svg/information.svg" alt="info-icon" />
            {{ "group.group-form.hotel-price-info" | translate }}
          </div>
        </div>
        <div class="col-md-4 pl-0">
          <label class="tittle-form-control-departament">{{
            "departament.departament-form.permissions" | translate
          }}</label>
        </div>
        <div class="row">
          <div class="col-md-4 col-xs-12">
            <div class="form-group">
              <div class="custom-control custom-checkbox m-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="onlyAuthorization"
                  formControlName="onlyAuthorization"
                />
                <label class="custom-control-label" for="onlyAuthorization"
                  >{{ "common.onlyAuthorization" | translate }}
                  <i
                    class="fa fa-question-circle color-info"
                    matTooltip="{{
                      'group.group-form.authorization-info' | translate
                    }}"
                  ></i>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="form-group">
              <div class="custom-control custom-checkbox m-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="breakfast"
                  formControlName="breakfast"
                />
                <label class="custom-control-label" for="breakfast">{{
                  "common.breakfast" | translate
                }}</label>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="form-group">
              <div class="custom-control custom-checkbox m-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="requestBooked"
                  formControlName="requestBooked"
                />
                <label class="custom-control-label" for="requestBooked">{{
                  "group.group-form.book-allowed" | translate
                }}</label>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="form-group">
              <div class="custom-control custom-checkbox m-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="cancelBooking"
                  formControlName="cancelBooking"
                />
                <label class="custom-control-label" for="cancelBooking">{{
                  "group.group-form.cancel-booking-allowed" | translate
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <h4 class="heading-6">
          {{ "group.group-form.flightFilters" | translate }}
        </h4>
        <div class="row" *ngIf="form.value.sitType">
          <div class="col-md-4 col-xs-12">
            <div class="form-group-departament">
              <label
                class="tittle-form-control-departament"
                for="lodgingType"
                >{{ "group.group-form.sit-type" | translate }}</label
              >
              <select
                [attr.disabled]="loading ? '' : null"
                formControlName="sitType"
                id="sitType"
                class="form-control-departament form-control icon-arrow-rtl"
              >
                <option *ngFor="let s of sits" [ngValue]="s.value">
                  {{ s.viewValue }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-4 pt-3 pl-0">
          <label class="tittle-form-control-departament">{{
            "departament.departament-form.permissions" | translate
          }}</label>
        </div>

        <div class="row">
          <div class="row col-md-12">
            <div class="col-md-4 col-xs-12" style="padding-left: 38px">
              <div class="form-group">
                <div class="custom-control custom-checkbox m-0 p-0">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="allFlightsAllowed"
                    formControlName="allFlightsAllowed"
                  />
                  <label class="custom-control-label" for="allFlightsAllowed">{{
                    "group.group-form.allFlightsAllowed" | translate
                  }}</label>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xs-12" style="padding-left: 47px">
              <div class="form-group">
                <div class="custom-control custom-checkbox m-0 p-0">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="allowCheckedBag"
                    formControlName="allowCheckedBag"
                  />
                  <label class="custom-control-label" for="allowCheckedBag">{{
                    "group.group-form.checkedBagsAllowed" | translate
                  }}</label>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xs-12">
              <div class="form-group">
                <div
                  class="custom-control custom-checkbox m-0"
                  style="padding-left: 36px"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="onlyFlightAuthorization"
                    formControlName="onlyFlightAuthorization"
                  />
                  <label
                    class="custom-control-label"
                    for="onlyFlightAuthorization"
                    >{{ "common.onlyFlightAuthorization" | translate }}
                    <i
                      class="fa fa-question-circle color-info"
                      matTooltip="{{
                        'group.group-form.authorization-info' | translate
                      }}"
                    ></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="form-group">
              <div class="custom-control custom-checkbox m-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="flight"
                  formControlName="flight"
                />
                <label class="custom-control-label" for="flight">{{
                  "group.group-form.flight-allowed" | translate
                }}</label>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="form-group">
              <div class="custom-control custom-checkbox m-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="requestCustomFlight"
                  formControlName="requestCustomFlight"
                />
                <label class="custom-control-label" for="requestCustomFlight"
                  >{{ "group.group-form.customFlight-allowed" | translate }}
                  <i
                    class="fa fa-question-circle color-info"
                    matTooltip="{{
                      'group.group-form.request-info' | translate
                    }}"
                  ></i>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="form.value.allowCheckedBag">
          <div class="col-md-4 col-xs-12">
            <div class="form-group">
              <label class="tittle-form-control-departament" for="weight"
                >{{ "group.group-form.weight" | translate }}*</label
              >
              <input
                [readOnly]="loading"
                formControlName="weight"
                type="number"
                id="weight"
                class="form-control-departament form-control"
              />
            </div>
          </div>
          <div class="col-md-3 col-xs-12">
            <div class="form-group">
              <label class="tittle-form-control-departament" for="weight"
                >{{ "group.group-form.priceMaxBag" | translate }}*</label
              >
              <input
                [readOnly]="loading"
                formControlName="priceMaxBag"
                type="number"
                id="priceMaxBag"
                class="form-control-departament form-control"
              />
            </div>
          </div>
        </div>
        <h4 class="heading-6">
          {{ "group.group-form.train-filters" | translate }}
        </h4>
        <!-- FILTROS DE TREN -->
        <div class="col-md-4 pl-0">
          <label class="tittle-form-control-departament">{{
            "departament.departament-form.permissions" | translate
          }}</label>
        </div>
        <div class="row">
          <div class="col-md-4 col-xs-12">
            <div class="form-group">
              <div class="custom-control custom-checkbox m-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="onlyTrainAuthorization"
                  formControlName="onlyTrainAuthorization"
                />
                <label class="custom-control-label" for="onlyTrainAuthorization"
                  >{{ "common.onlyTrainAuthorization" | translate }}
                  <i
                    class="fa fa-question-circle color-info"
                    matTooltip="{{
                      'group.group-form.authorization-info' | translate
                    }}"
                  ></i>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="form-group">
              <div class="custom-control custom-checkbox m-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="train"
                  formControlName="train"
                />
                <label class="custom-control-label" for="train">{{
                  "group.group-form.train-allowed" | translate
                }}</label>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="form-group">
              <div class="custom-control custom-checkbox m-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="requestCustomTrain"
                  formControlName="requestCustomTrain"
                />
                <label class="custom-control-label" for="requestCustomTrain"
                  >{{ "group.group-form.customTrain-allowed" | translate }}
                  <i
                    class="fa fa-question-circle color-info"
                    matTooltip="{{
                      'group.group-form.request-info' | translate
                    }}"
                  ></i>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="form-group">
              <div class="custom-control custom-checkbox m-0">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="chooseTrainSeat"
                  formControlName="chooseTrainSeat"
                />
                <label class="custom-control-label" for="chooseTrainSeat">{{
                  "group.group-form.chooseTrainSeat" | translate
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <h4 class="heading-6">
          {{ "group.group-form.carFilters" | translate }}
        </h4>
        <div class="row">
          <div class="col-md-4 col-xs-12">
            <div class="form-group">
              <label
                class="tittle-form-control-departament"
                >{{
                  "departament.departament-form.cars-permission" | translate
                }}
                <i
                  class="fa fa-question-circle color-info"
                  matTooltip="{{ 'group.group-form.request-info' | translate }}"
                ></i>
              </label>
              <select
                [attr.disabled]="loading ? '' : null"
                formControlName="requestCar"
                id="requestCar"
                class="form-control-departament form-control icon-arrow-rtl"
              >
                <option *ngFor="let cars of carPermits" [value]="cars.value">
                  {{ cars.text | translate }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row-button">
      <div class="text-rigth mt-3">
        <button
          type="button"
          class="custom-btn custom-btn--close mb-0"
          aria-label="Close"
          (click)="goBack()"
        >
          {{ "common.return" | translate }}
        </button>
      </div>
      <div class="text-right mt-3">
        <button
          class="custom-btn mb-0"
          ngbAutofocus
          mat-button
          [ngStyle]="{
            cursor: !form.valid || loading ? 'not-allowed' : 'pointer'
          }"
          matTooltip="{{
            (!form.valid || loading ? 'user.form.invalid-form' : '') | translate
          }}"
        >
          {{ "common.send" | translate }}
        </button>
      </div>
    </div>
  </form>
  <div class="row" *ngIf="loading">
    <app-loading [userType]="'employee'"></app-loading>
  </div>
</div>
