<!-- <section id="user-profile" *ngIf="!(userType === 'USER')">
  <div class="row">
    <div class="col-12">
      <div class="card profile-with-cover border-card">
        <div class="col-12 text-left">
          <button
            mat-button
            class="back-button"
            mat-flat-button
            (click)="goBack()"
          >
            <img src="assets/img/svg/left-arrow.svg" alt="" width="24px" />
          </button>
        </div>
        <div class="media profil-cover-details row">
          <div class="col-12 text-center">
            <div
              class="align-self-center halfway-fab text-center mt-2 mb-2 d-flex justify-content-center"
              *ngIf="user"
            >
              <ng-container *ngIf="userImageBase64; else elseTemplate">
                <a class="profile-image-wrapper">
                  <img
                    [src]="userImageBase64"
                    class="profile-image"
                    alt="Card image"
                  />
                </a>
              </ng-container>
              <ng-template #elseTemplate>
                <div class="user-image">
                  <div
                    class="profile-initials text-white"
                    *ngIf="
                      bookedUserAccessType !== 'EMPLOYEE';
                      else elseTemplate1
                    "
                  >
                    {{ user.name[0] }}{{ user.lastname[0] }}
                  </div>
                </div>
              </ng-template>
              <ng-template #elseTemplate1>
                <div class="profile-initials text-white">A</div>
              </ng-template>
            </div>
          </div>
        </div>
        <div
          class="oneOrder col-lg-4 col-md-4 col-xs-12 align-self-center text-center mb-2"
        >
          <span class="font-medium-2"
            >{{ user ? user.name : "" }} {{ user ? user.lastname : "" }}</span
          >
        </div>
      </div>
    </div>
  </div>
</section> -->
<app-user-detail
  [userUuid]="userToBook"
  *ngIf="userType !== 'USER'"
></app-user-detail>
<div class="container">
  <span class="component-info heading-5">{{ "custom-service.title" | translate }}</span>

  <div class="bg-card">
    <span class="title heading-5">{{ "custom-service.request" | translate }}</span>

    <div class="service-card-container">
      <div class="row mb-3">
        <div
          class="col-lg-4 col-sm-12"
          (click)="navigate('hotel')"
          *ngIf="canBookHotel || userType !== 'USER'"
        >
          <div class="service-card">
            <div class="icon-bcg">
              <img
                src="../../../../assets/img/svg/sidebar/hotel.svg"
                alt="Custom hotel option icon"
              />
            </div>
            <div class="title-card">
              <span>{{"common.request" | translate}}</span>
              <span style="color: rgb(248, 95, 31)">{{ "hotel.custom.request.option" | translate }}</span>
            </div>
          </div>
        </div>

        <div
          class="col-lg-4 col-sm-12"
          (click)="navigate('flight')"
          *ngIf="canRequestCustomFlight || userType !== 'USER'"
        >
          <div class="service-card">
            <div class="icon-bcg">
              <img
                src="../../../../assets/img/svg/sidebar/flight.svg"
                alt="Custom flight option icon"
              />
            </div>
            <div class="title-card">
              <span>{{"common.request" | translate}}</span>
              <span style="color: rgb(248, 95, 31)">{{ "flight.custom.request.option" | translate }}</span>
            </div>
          </div>
        </div>

        <div
          class="col-lg-4 col-sm-12"
          (click)="navigate('train')"
          *ngIf="canRequestCustomTrain || userType !== 'USER'"
        >
          <div class="service-card">
            <div class="icon-bcg">
              <img
                src="../../../../assets/img/svg/sidebar/train.svg"
                alt="Custom train option icon"
              />
            </div>
            <div class="title-card">
              <span>{{"common.request" | translate}}</span>
              <span style="color: rgb(248, 95, 31)">{{ "train.form-train.request-train" | translate }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-lg-4 col-sm-12"
          (click)="navigate('car')"
          *ngIf="canRequestCustomCar || userType !== 'USER'"
        >
          <div class="service-card">
            <div class="icon-bcg">
              <img
                src="../../../../assets/img/svg/car-black.svg"
                alt="Custom car option icon"
              />
            </div>
            <div class="title-card">
              <span>{{"common.request" | translate}}</span>
              <span style="color: rgb(248, 95, 31)">{{ "car.custom.request.option" | translate }}</span>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-sm-12"
          (click)="navigate('airbnb')"
          *ngIf="canRequestAirbnb || userType !== 'USER'"
        >
          <div class="service-card">
            <div class="icon-bcg">
              <img
                src="../../../../assets/img/svg/airbnb.svg"
                alt="Custom airbnb option icon"
              />
            </div> 
            <div class="title-card">
              <span>{{"common.request" | translate}}</span>
              <span style="color: rgb(248, 95, 31)">{{ "airbnb.custom.request.option" | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
