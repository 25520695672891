import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder } from "@angular/forms";

@Component({
  selector: "app-rooms",
  templateUrl: "./rooms.component.html",
  styleUrls: ["./rooms.component.scss"],
})
export class RoomsComponent implements OnInit {
  room;
  @Input() r;
  idHab;
  agesChildren = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
  ];
  @Input() form;
  @Input() i;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    const length = this.form.getRawValue().room.length;
    if (length < 0) {
      this.idHab = length + 1;
      this.form.controls["room"].push(
        this.formBuilder.group({
          idHab: length + 1,
          numAdults: 1,
          numChildrens: 0,
          agesChildrens: [],
        })
      );
    }
    this.room = this.form.getRawValue().room;
  }

  restAdult(index) {
    if (this.form.getRawValue().room[index].numAdults > 1) {
      this.form.controls["room"].controls[index].setValue({
        idHab: this.form.getRawValue().room[index].idHab,
        numAdults: this.form.getRawValue().room[index].numAdults - 1,
        numChildrens: this.form.getRawValue().room[index].numChildrens,
        agesChildrens: this.form.getRawValue().room[index].agesChildrens,
      });
      this.room = this.form.getRawValue().room;
    }
  }

  addAdult(index) {
    if (this.form.getRawValue().room[index].numAdults < 4) {
      this.form.controls["room"].controls[index].setValue({
        idHab: this.form.getRawValue().room[index].idHab,
        numAdults: this.form.getRawValue().room[index].numAdults + 1,
        numChildrens: this.form.getRawValue().room[index].numChildrens,
        agesChildrens: this.form.getRawValue().room[index].agesChildrens,
      });
      this.room = this.form.getRawValue().room;
    }
  }
  getRooms() {
    return this.form.get("room") as UntypedFormArray;
  }

  getAgeChildren(indexRoom) {
    return this.getRooms().at(indexRoom).value.agesChildrens;
  }

  restChildren(index) {
    if (this.form.getRawValue().room[index].numChildrens > 0) {
      this.getAgeChildren(index).pop();
      this.form.controls["room"].controls[index].setValue({
        idHab: this.form.getRawValue().room[index].idHab,
        numAdults: this.form.getRawValue().room[index].numAdults,
        numChildrens: this.form.getRawValue().room[index].numChildrens - 1,
        agesChildrens: this.form.getRawValue().room[index].agesChildrens,
      });
      this.room = this.form.getRawValue().room;
    }
  }
  addChildren(index) {
    if (this.form.getRawValue().room[index].numChildrens < 3) {
      this.form.getRawValue().room[index].agesChildrens.push({
        type: "CH",
        age: 0,
      });
      this.form.controls["room"].controls[index].setValue({
        idHab: this.form.getRawValue().room[index].idHab,
        numAdults: this.form.getRawValue().room[index].numAdults,
        numChildrens: this.form.getRawValue().room[index].numChildrens + 1,
        agesChildrens: this.form.getRawValue().room[index].agesChildrens,
      });
      this.room = this.form.getRawValue().room;
    }
  }
  addAgeChildren(index) {
    this.form.controls["room"].controls[index].setValue({
      idHab: this.form.getRawValue().room[index].idHab,
      numAdults: this.form.getRawValue().room[index].numAdults,
      numChildrens: this.form.getRawValue().room[index].numChildrens,
      agesChildrens: this.form.getRawValue().room[index].agesChildrens,
    });
  }
}
