import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@services";
import { ValidatePassword } from "app/validators/password.validator";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  form: UntypedFormGroup;
  token: string;
  loading = false;
  typeUser: "user" | "company" | "employee";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {
    if (this.router.url.includes("/login")) {
      this.typeUser = "user";
    } else if (this.router.url.includes("/login")) {
      this.typeUser = "company";
    } else {
      this.typeUser = "employee";
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        password: ["", [Validators.required]],
        confirmPassword: ["", Validators.required],
      },
      { validator: ValidatePassword }
    );

    this.route.params.subscribe((params) => {
      this.token = params["token"];
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    if (this.token !== null) {
      this.loading = true;
      const params = {
        token: this.token,
        password: this.f.password.value,
        confirmPassword: this.f.confirmPassword.value,
      };
      const authRecovery =
        this.typeUser === "user"
          ? this.authService.recoveryUser(params)
          : this.typeUser === "company"
          ? this.authService.recoveryCompany(params)
          : this.authService.recoveryEmployee(params);

      authRecovery.subscribe(
        () => {
          this.loading = false;
          let url =
            this.typeUser === "user"
              ? "/login"
              : this.typeUser === "company"
              ? "/company-login"
              : "/admin-login";
          url += "/reset-password-complete";
          this.router.navigate([url]);
        },
        () => {
          this.loading = false;
        }
      );
    }
  }

  getClassBackground() {
    switch (this.typeUser) {
      case "user":
        return "user-background";
      case "company":
        return "company-background";
      case "employee":
        return "employee-background";
    }
  }

  getClassRecoveryButton() {
    switch (this.typeUser) {
      case "user":
        return "btn-primary";
      case "company":
        return "btn-company";
      case "employee":
        return "btn-vyoo-primary";
    }
  }
}
