<div class="modal-body p-4">
  <div class="row">
    <div id="delivery-note" class="page">
      <div class="container">
        <img src="assets/img/logo/logo_accent.svg" />
        <div class="vyootrip-info">
          <p>VYOOTRIP SOLUTIONS S.L.</p>
          <p>B23801467</p>
          <p>CALLE EGIDO, 2</p>
          <p>LOS VILLARES, JAÉN (23160), JAÉN, España</p>
        </div>
      </div>
      <div class="container company-info">
        <div class="company-row">
          <p class="bold">PROFORMA {{ booked.deliveryNote }}</p>
          <p>{{ booked.createdAt | dateMoment : "YYYY-MM-DD HH:mm" : "DD/MM/YYYY HH:mm" }}</p>
        </div>
        <div class="company-row">
          <div class="company-info details" style="gap: 0" *ngIf="company">
            <p class="bold">{{ company.name }}</p>
            <p>{{ company.cif }}</p>
            <p>{{ company.address }}</p>
            <p>{{ company.city }} ({{ company.postalCode }}), {{ company.country }}</p>
          </div>
          <p class="green-text">TOTAL {{booked.price | price}} €</p>
        </div>
      </div>
      <div class="booked-data">
        <div>
          <p>{{ 'delivery-note.concept' | translate }}</p>
          <p>{{ type | translate }}</p>
          <p *ngIf="booked.from">{{ booked.from }} {{ booked.to ? '- ' + booked.to : '' }}</p>
          <p *ngIf="booked.hotelName">{{ booked.hotelName }}{{ booked.destinationName ? ', ' + booked.destinationName : '' }}</p>
          <p *ngIf="booked.hotel">{{ booked.hotel.name }}{{ booked.hotel.destinationName ? ', ' + booked.hotel.destinationName : '' }}</p>
          <p *ngIf="!booked.hotel && !booked.hotelName && !booked.from">{{ booked.type }}</p>
          <p *ngIf="booked.departDate">{{ booked.departDate | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY" }} {{ booked.returnDate ? 'a ' + (booked.returnDate | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY") : '' }}</p>
          <p *ngIf="booked.beginDate">{{ booked.beginDate | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY" }} {{ booked.endDate ? 'a ' + (booked.endDate | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY") : '' }}</p>
          <p *ngIf="booked.checkIn">{{ booked.checkIn | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY" }} {{ booked.checkOut ? 'a ' + (booked.checkOut  | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY") : '' }}</p>
          <p *ngIf="user">{{ user.name }} {{ user.lastname }}</p>
        </div>
        <div>
          <p>{{ 'delivery-note.price' | translate }}</p>
          <p>{{ (!booked.freeTaxes ? (booked.price * 1 / 1.1) : booked.price) | price }}</p>
        </div>
        <div>
          <p>{{ 'delivery-note.subtotal' | translate }}</p>
          <p>{{ (!booked.freeTaxes ? (booked.price * 1 / 1.1) : booked.price) | price }}</p>
        </div>
        <div *ngIf="!booked.freeTaxes">
          <p>{{ 'delivery-note.iva' | translate }}</p>
          <p>10%</p>
        </div>
        <div>
          <p>{{ 'delivery-note.total' | translate }}</p>
          <p>{{ booked.price | price }}</p>
        </div>
      </div>
      <div class="price-info">
        <div>
          <p>{{ 'delivery-note.tax-base' | translate }}</p>
          <p *ngIf="!booked.freeTaxes">{{ 'delivery-note.iva' | translate }} 10%</p>
          <p>{{ 'delivery-note.total' | translate }}</p>
        </div>
        <div>
          <p>{{ (!booked.freeTaxes ? (booked.price * 1 / 1.1) : booked.price) | price }}</p>
          <p *ngIf="!booked.freeTaxes">{{ (booked.price * 1 / 1.1 * 0.1) | price }}</p>
          <p>{{ booked.price | price }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="btn-container">
        <button
          class="custom-btn mb-0 mr-2"
          (click)="downloadPDFBooked()"
        >
          {{ "common.download" | translate }}
        </button>
        <button
          class="custom-btn custom-btn--close mb-0"
          (click)="closeModal()"
        >
          {{ "common.close" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
