<div class="room mt-4 w-100">
  <h5>{{ "search.filters.rooms" | translate }} {{ r.idHab }}</h5>
  <div class="row">
    <div class="col col-6 text-right pr-0">
      <span>{{ "search.filters.adults" | translate }}</span>
    </div>
    <div class="col col-6 text-left pl-0">
      <div class="modalRoomButtons d-flex">
        <i class="ft-minus-circle pl-2 my-auto" (click)="restAdult(i)"></i>
        <span class="mx-auto">{{ r.numAdults }}</span>
        <i class="ft-plus-circle my-auto" (click)="addAdult(i)"></i>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col col-6 text-right p-0">
      <span>{{ "search.filters.childrens" | translate }}</span>
    </div>
    <div class="col col-6 text-left pl-0">
      <div class="modalRoomButtons d-flex">
        <i class="ft-minus-circle pl-2 my-auto" (click)="restChildren(i)"></i>
        <span class="mx-auto">{{ r.numChildrens }}</span>
        <i class="ft-plus-circle my-auto" (click)="addChildren(i)"></i>
      </div>
    </div>
    <div class="col-12 mt-2 pl-0" *ngIf="r.numChildrens > 0">
      <div style="display: inline-flex">
        <label class="mt-1 mr-1"
          >{{ "search.filters.ageChildren" | translate }} 1</label
        >

        <select
          class="form-control pr-0 selectAGeCHildrens"
          name="ageChildren1"
          [(ngModel)]="r.agesChildrens[0].age"
          (change)="addAgeChildren(i)"
        >
          <option
            *ngFor="let ageChildren of agesChildren"
            [ngValue]="ageChildren"
          >
            {{ ageChildren }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12 mt-2 pl-0" *ngIf="r.numChildrens > 1">
      <div style="display: inline-flex">
        <label class="mt-1 mr-1"
          >{{ "search.filters.ageChildren" | translate }} 2</label
        >
        <select
          class="form-control selectAGeCHildrens"
          [(ngModel)]="r.agesChildrens[1].age"
          name="ageChildren2"
          (change)="addAgeChildren(i)"
        >
          <option
            *ngFor="let ageChildren of agesChildren"
            [ngValue]="ageChildren"
          >
            {{ ageChildren }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12 mt-2 pl-0" *ngIf="r.numChildrens > 2">
      <div style="display: inline-flex">
        <label class="mt-1 mr-1"
          >{{ "search.filters.ageChildren" | translate }} 2</label
        >
        <select
          class="form-control selectAGeCHildrens"
          [(ngModel)]="r.agesChildrens[2].age"
          name="ageChildren3"
          (change)="addAgeChildren(i)"
        >
          <option
            *ngFor="let ageChildren of agesChildren"
            [ngValue]="ageChildren"
          >
            {{ ageChildren }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
