import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { BookedService } from "@services";
import { SharedModule } from "@shared/shared.module";
import { CreateCustomHotelComponent } from "./create-custom-hotel/create-custom-hotel.component";
import { HotelRoutingModule } from "./hotel-routing.module";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        SharedModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        HotelRoutingModule,
        MatCheckboxModule
    ],
    declarations: [CreateCustomHotelComponent],
    providers: [BookedService]
})
export class HotelModule {}
