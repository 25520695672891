<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "user.group.edit-user-group" | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="group">{{ "user.group.group" | translate }}*</label>
          <select
            formControlName="group"
            [(ngModel)]="group"
            [compareWith]="compareFn"
            class="form-control rounded-1"
            id="group"
          >
            <option
              *ngFor="let g of groups; trackBy: utilsService.getUuidTracking"
              [ngValue]="g"
            >
              {{ g.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="text-right mt-3">
          <button
            class="btn btn-vyoo-primary text-white rounded-1 mb-0"
            ngbAutofocus
            mat-button
            [disabled]="!form.valid || loading"
          >
            {{ "common.send" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
