<ng-container mat-dialog-content>
  <button class="close" (click)="close()">X</button>
  <div class="services-container row">
    <ng-container *ngFor="let service of fare.services">
      <div
        class="service col-6"
        *ngIf="
          service.name && service.name !== '' && service.indicator !== 'NOF'
        "
      >
        <img
          src="assets/img/svg/flight/{{
            service.category?.toLowerCase() === 'miscellaneous' ||
            service.category?.toLowerCase() === 'upgrademileagebonus' ||
            service.category?.toLowerCase() === 'wifi'
              ? getMiscellaneousSvg(service)
              : service.category?.toLowerCase()
          }}.svg"
          alt="{{ service.category }}"
        />
        <p>{{ transformFareString(service.name) }}</p>
      </div>
    </ng-container>
  </div>
</ng-container>
