import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-custom-confirm-dialog",
  templateUrl: "./custom-confirm-dialog.component.html",
  styleUrls: ["./custom-confirm-dialog.component.scss"],
})
export class CustomConfirmDialogComponent implements OnInit {
  title: string;
  subtitle: string;
  cancelText: string;
  confirmText: string;

  constructor(
    private dialogRef: MatDialogRef<CustomConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data != null) {
      this.title = data.title;
      this.subtitle = data.subtitle;
      this.cancelText = data.cancelText;
      this.confirmText = data.confirmText;
    }
  }

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
