import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';

@Component({
  selector: 'app-travel-bookings-modal',
  templateUrl: './travel-bookings-modal.component.html',
  styleUrls: ['./travel-bookings-modal.component.scss'],
  providers: [DatePipe]
})
export class TravelBookingsModalComponent implements OnInit {
  allReservations: any[] = [];
  travelTitle: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<TravelBookingsModalComponent>) { }

  ngOnInit(): void {
    this.allReservations = this.data.allReservations;
    this.travelTitle = this.data.travelTitle;

    this.allReservations.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }

  getValueOrDefault(value: any, defaultValue: string = '-'): string {
    return value ? value : defaultValue;
  }

  getReservationProperty(reservation: any, property: string): string {
    if (property === 'type') {
      return this.getTranslationKeyType(reservation.type);
    }
    if (property === 'status') {
      return this.getTranslationKeyStatus(reservation.status);
    }
    if (reservation.type === 'Hotel') {
      if (property === 'destination') return reservation.hotel?.destinationName;
      if (property === 'initDate') return reservation.checkIn;
      if (property === 'endDate') return reservation.checkOut;
    }
    if (reservation.type === 'Flight') {
      if (property === 'destination') return `${reservation.from} - ${reservation.to}`;
      if (property === 'initDate') return reservation.departDate;
      if (property === 'endDate') return reservation.returnDate;
    }
    if (reservation.type === 'CustomBooking') {
      if (property === 'destination') return reservation.destinationName;
      if (property === 'initDate') return reservation.checkIn;
      if (property === 'endDate') return reservation.checkOut;
    }
    if (reservation.type === 'Car') {
      if (property === 'destination') return `${reservation.from} - ${reservation.to}`;
      if (property === 'initDate') return reservation.departDate;
      if (property === 'endDate') return reservation.returnDate;
    }
    if (this.isExpense(reservation.type)) {
      if (property === 'destination') return reservation.destinationName;
      if (property === 'initDate') return reservation.beginDate;
      if (property === 'endDate') return reservation.endDate;
    }
    if (reservation.type === 'Train') {
      if (property === 'destination') return `${reservation.from} - ${reservation.to}`;
      if (property === 'initDate') return reservation.departDate;
      if (property === 'endDate') return reservation.returnDate;
    }
    return reservation[property];
  }

  getTranslationKeyType(type: string): string {
    const translationMap = {
      Hotel: 'common.hotel',
      Flight: 'common.flight',
      Car: 'common.car',
      CustomBooking: 'common.hotel',
      Train: 'common.train'
    };
    return translationMap[type] || type;
  }

  getTranslationKeyStatus(status: string): string {
    const translationMap = {
      active: 'common.confirmed',
      confirmed: 'common.confirmed',
      cancelled: 'common.cancelled',
    };
    return translationMap[status.toLowerCase()] || status;
  }

  getPrice(price: string): number {
    return parseFloat(price)
  }

  getRawPrice(price: string): number {
    return parseFloat(price) / 1.1;
  }

  getIvaPrice(price: string): number {
    return (parseFloat(price) / 1.1) * 0.1;
  }
  
  isExpense(type: string): boolean {
    const typesToTranslate = ['Hotel', 'CustomBooking', 'Flight', 'Train', 'Car'];
    return !typesToTranslate.includes(type);
  }

  transactionStatus(reservation: any): string {
    if (reservation.payment) {
      return 'agency.transaction.filters.status-payment';
    } else if (reservation.refund) {
      return 'agency.transaction.filters.status-refund';
    } else {
      return 'agency.transaction.filters.type-pending';
    }
  }

  formatDate(date: string): string {
    return date ? moment(date).format("DD/MM/YYYY HH:mm") : '-';
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
