<div class="modal-header">
  <div
    (click)="graphic = 'all'; getDataGraphic()"
    [ngClass]="graphic === 'all' ? 'active all' : ''"
  >
    {{ "graph.amount-expense" | translate }}
  </div>
  <div
    (click)="graphic = 'service'; getDataGraphic()"
    [ngClass]="graphic === 'service' ? 'active service' : ''"
  >
    {{ "graph.service-expense" | translate }}
  </div>
</div>
<div class="modal-body p-3">
  <app-loading *ngIf="isLoading"></app-loading>
  <div class="row d-flex justify-content-center">
    <div class="col-md-3 col-sm-12">
      <div class="form-group text-center input-long mb-1">
        <h6 for="date_from">{{ "graph.from" | translate }}</h6>
        <div class="input-group">
          <input
            id="date_from"
            class="form-control text-center"
            placeholder="MM-YYYY"
            name="dInput"
            [(ngModel)]="from"
            [maxLength]="7"
            (ngModelChange)="checkInDate($event)"
          />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-12">
      <div class="form-group text-center input-long mb-1">
        <h6 for="date_to">{{ "graph.to" | translate }}</h6>
        <div class="input-group">
          <input
            id="date_to"
            class="form-control text-center"
            placeholder="MM-YYYY"
            name="dpOutput"
            [(ngModel)]="to"
            [maxLength]="7"
            (ngModelChange)="checkOutDate($event)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="showError">
    <div class="col-12 text-center">
      <label class="text-danger">{{ "graph.error-format" | translate }}</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <figure class="highcharts-figure">
        <div
          id="container"
          [ngClass]="{ 'd-none-label': graphic === 'all' }"
        ></div>
      </figure>
    </div>
  </div>
</div>
