import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthCompanyGuard, AuthEmployeeGuard } from "@core/guards";
import { AuthRestrictedCompanyGuard } from "@core/guards/auth-restricted-company.guard";
import { PlanResolveService } from "@core/guards/PlanResolveService";
import { DashboardComponent } from "../../components/dashboard/dashboard.component";
import { AgencyFormComponent } from "../agency/agency-form/agency-form.component";
import { AuthAgencyGuard } from "./../../core/guards/auth-agency.guard";
import { AuthorizationsComponent } from "./authorizations/authorizations.component";
import { BookedHomepageComponent } from "./booked-homepage/booked-homepage.component";
import { CompanyDetailComponent } from "./company-detail/company-detail.component";
import { CompanyFormComponent } from "./company-form/company-form.component";
import { CompanyHomepageComponent } from "./company-homepage/company-homepage.component";
import { CustomRequestsComponent } from "./custom-requests/custom-requests.component";
import { PlanComponent } from "./plans/plan.component";
import { TravelContainerComponent } from "app/modules/components/my-bookeds/user-my-bookeds/travel-container/travel-container.component";
import { UserMyBookedsComponent } from "app/modules/components/my-bookeds/user-my-bookeds/user-my-bookeds.component";

const routes: Routes = [
  {
    path: "",
    component: CompanyHomepageComponent,
    canActivate: [AuthAgencyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "edit-company",
    component: CompanyFormComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "new",
    component: CompanyFormComponent,
    canActivate: [AuthAgencyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "reports",
    component: BookedHomepageComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "projects",
    component: TravelContainerComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "bookings",
    component: UserMyBookedsComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "plans",
    component: PlanComponent,
    canActivate: [AuthEmployeeGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "details",
    component: CompanyDetailComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "authorizations",
    component: AuthorizationsComponent,
    canActivate: [AuthCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "requests",
    component: CustomRequestsComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: ":id/authorizations",
    component: AuthorizationsComponent,
    canActivate: [AuthCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: ":id/dashboard",
    component: DashboardComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: ":uuid",
    component: CompanyDetailComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: ":id/reports",
    component: BookedHomepageComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: ":id/projects",
    component: TravelContainerComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: ":id/bookings",
    component: UserMyBookedsComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: ":id/requests",
    component: CustomRequestsComponent,
    canActivate: [AuthRestrictedCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: ":agencyUuid/edit",
    component: AgencyFormComponent,
    canActivate: [AuthAgencyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompanyRoutingModule {}
