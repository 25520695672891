export class FlightRequest {
  origin: string;
  destination: string;
  departure: string;
  return: string;
  departurePref: string;
  returnPref: string;
  annotations: string;
  price: string;
}
