import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Plan } from "@core/models/plan";
import { environment } from "@env/environment";
import { Agency, Company, Employee, Filters, Hub, User } from "@models";
import jwt_decode from "jwt-decode";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { ForgotService } from "./forgot.service";
import { TokenService } from "./token.service";
import { UtilsService } from "./utils.service";

@Injectable({ providedIn: "root" })
export class AuthService {
  token: string;
  userCompanyPlan: Plan;
  userFilters: Filters;
  totalAuthorizations = 0

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private utilsService: UtilsService,
    private forgotService: ForgotService
  ) {}

  loginUser(email: string, password: string) {
    return this.http
      .post<any>(`${environment.baseUrlApi}/auth/login`, { email, password })
      .pipe(
        map((res) => {
          if (res && res.token) {
            this.setAuth(res.token);
          }
          switch (res.accessType?.toLowerCase()) {
            case "user":
              const user: User = res;
              user.type = "USER";
              user.accessType = "user";
              return user;
            case "company":
              const company: Company = res;
              company.type = "COMPANY";
              company.accessType = "company";
              return company;
            case "agency":
              const agency: Agency = res;
              agency.type = "AGENCY";
              agency.accessType = "agency";
              return agency;
            case "hub":
              const hub: Hub = res;
              hub.type = "HUB";
              hub.accessType = "hub";
              return hub;
            default:
              break;
          }
        })
      );
  }

  loginEmployee(email: string, password: string) {
    return this.http
      .post<any>(`${environment.baseUrlApi}/auth/employee-login`, {
        email,
        password,
      })
      .pipe(
        map((res) => {
          if (res && res.token) {
            this.setAuth(res.token);
          }
          const employee: Employee = res;
          employee.type = "EMPLOYEE";
          employee.accessType = "employee";
          return employee;
        })
      );
  }

  loginCompany(email: string, password: string) {
    return this.http
      .post<any>(`${environment.baseUrlApi}/auth/company-login`, {
        email,
        password,
      })
      .pipe(
        map((res) => {
          if (res && res.token) {
            this.setAuth(res.token);
          }
          const company: Company = res;
          company.type = "COMPANY";
          company.accessType = "company";
          return company;
        })
      );
  }

  getProfile() {
    if (this.tokenService.getToken()) {
      return this.http.get<any>(`${environment.baseUrlApi}/auth/profile`).pipe(
        map((res) => {
          if (res.role) {
            const employee: Employee = res;
            employee.type = "EMPLOYEE";
            employee.accessType = "employee";
            return employee;
          } else if (res.accessType || (res.user && res.user.accessType)) {
            if (res.company || (res.user && res.user.company)) {
              res["company"]["canRequestWithoutCard"] =
                res["plan"]["permissions"]["canRequestWithoutCard"];
              this.utilsService.storeParamInLocalStorage({
                title: "company",
                value: res["company"],
              });
            }
            const accessType = res.accessType ? res.accessType : (res.user && res.user.accessType ? res.user.accessType : null);
            switch (accessType?.toLowerCase()) {
              case "user":
                const user: User = res;
                user.type = "USER";
                user.accessType = "user";
                return user;
              case "company":
                const company: Company = res;
                company.type = "COMPANY";
                company.accessType = "company";
                this.utilsService.storeParamInLocalStorage({
                  title: "company",
                  value: res,
                });
                return company;
              case "agency":
                const agency: Agency = res;
                agency.type = "AGENCY";
                agency.accessType = "agency";
                return agency;
              case "hub":
                const hub: Hub = res;
                hub.type = "HUB";
                hub.accessType = "hub";
                return hub;
              default:
                break;
            }
          } else if (res["company"]) {
            const user: User = res;
            this.utilsService.storeParamInLocalStorage({
              title: "company",
              value: res["company"],
            });
            user.accessType
              ? (user.type = user.accessType?.toUpperCase())
              : "USER";
            return user;
          }
        })
      );
    } else {
      return throwError(null);
    }
  }

  purgeAuth() {
    this.tokenService.destroyToken();
  }

  setAuth(token: string) {
    this.tokenService.saveToken(token);
  }

  putCompany(company: any) {
    return this.http.put<any>(
      `${environment.baseUrlApi}/auth/profile`,
      company
    );
  }

  putAgency(agency: any) {
    return this.http.put<any>(`${environment.baseUrlApi}/auth/profile`, agency);
  }

  putHub(hub: any) {
    return this.http.put<any>(`${environment.baseUrlApi}/auth/profile`, hub);
  }

  // ------------- CREATE PASSWORD EMPLOYEE --------------------
  createPasswordEmployee(params) {
    return this.http.post<any>(
      `${environment.baseUrlApi}/auth/employee-activate`,
      params
    );
  }

  // -------------- CREATE PASSWORD USER ------------------------
  createPasswordUser(params) {
    return this.http.post<any>(
      `${environment.baseUrlApi}/auth/activate`,
      params
    );
  }

  // ------------- CREATE PASSWORD COMPANY --------------------
  createPasswordCompany(params) {
    return this.http.post<any>(
      `${environment.baseUrlApi}/auth/company-activate`,
      params
    );
  }

  // ------------------ FORGOT EMPLOYEE ------------------

  forgotEmployee(email: string) {
    this.forgotService.saveEmail(email);
    return this.http.post<any>(
      `${environment.baseUrlApi}/auth/employee-forgot`,
      { email }
    );
  }

  recoveryEmployee(params) {
    return this.http.post<any>(
      `${environment.baseUrlApi}/auth/employee-recovery`,
      params
    );
  }

  // ------------------ FORGOT USER ------------------

  forgotUser(email: string) {
    this.forgotService.saveEmail(email);
    return this.http.post<any>(`${environment.baseUrlApi}/auth/forgot`, {
      email,
    });
  }

  recoveryUser(params) {
    return this.http.post<any>(
      `${environment.baseUrlApi}/auth/recovery`,
      params
    );
  }

  // ------------------ FORGOT COMPANY ------------------

  forgotCompany(email: string) {
    this.forgotService.saveEmail(email);
    return this.http.post<any>(
      `${environment.baseUrlApi}/auth/company-forgot`,
      { email }
    );
  }

  recoveryCompany(params) {
    return this.http.post<any>(
      `${environment.baseUrlApi}/auth/company-recovery`,
      params
    );
  }

  getCompanyPlan(companyUuid?: string): Observable<Plan> {
    if (companyUuid == null) {
      const token = this.tokenService.getToken();
      if (token != null) {
        const decodedToken = jwt_decode(token);
        companyUuid = decodedToken["companyUuid"];
      } else {
        companyUuid = localStorage.getItem("companyUuid");
      }
      return this.http.get<Plan>(
        `${environment.baseUrlApi}/plans/${companyUuid}`
      );
    } else {
      return this.http.get<Plan>(
        `${environment.baseUrlApi}/plans/${companyUuid}`
      );
    }
  }

  isAdmin(): boolean {
    const userType = jwt_decode(this.tokenService.getToken())["type"];
    return (
      userType === "employee" || userType === "hub" || userType === "agency"
    );
  }

  isEmployee(): boolean {
    const userType = jwt_decode(this.tokenService.getToken())["type"];
    return userType === "employee";
  }

  signupCompany(body: any, queryPlan?: any) {
    const headers = new Headers();
    headers["Content-Type"] = "multipart/form-data";
    const url = queryPlan
      ? `${environment.baseUrlApi}/auth/signup-company?plan=0`
      : `${environment.baseUrlApi}/auth/signup-company`;
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: body,
      redirect: "follow" as RequestRedirect,
    };
    const promise = fetch(url, requestOptions);
    return promise
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        return JSON.parse(data);
      });
  }

  postCompanyAdmin(user: any) {
    const headers = new Headers();
    headers["Content-Type"] = "multipart/form-data";
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: user,
      redirect: "follow" as RequestRedirect,
    };
    const promise = fetch(
      `${environment.baseUrlApi}/auth/signup-admin-company`,
      requestOptions
    );
    return promise
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        return JSON.parse(data);
      });
  }
}
