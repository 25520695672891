import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { seat } from "../../train-confirm-stepper-container/train-confirm-stepper-container.component";

@Component({
  selector: "app-train-seat-selection",
  templateUrl: "./train-seat-selection.component.html",
  styleUrls: ["./train-seat-selection.component.scss"],
})
export class TrainSeatSelectionComponent implements OnInit {
  @Input() graphRes: any;
  @Input() outwardWagonList: any[];
  @Input() returnWagonList: any[];
  @Input() passengers: number;
  @Input() firstTrain;
  @Input() events: Observable<void>;
  @Input() outwardSeats: any[] = [];
  @Input() returnSeats: any[] = [];
  @Input() classCodeG: string;
  @Input() classCodeR: string;
  passengersCopy: number;
  wagon = 0;
  wagonSelected = false;
  seatSelected = new Map();
  wagonNumber = "";
  seatClicked = false;
  secondWagon = false;
  selectedSeat: seat;
  @Output() selectSeatEvent: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.passengersCopy = this.passengers;
    this.filterWagons();
    this.selectWagon();
    this.events.subscribe(() => {
      this.backWagon();
    });
  }

  filterWagons() {
    this.outwardWagonList = this.outwardWagonList.filter(
      (wag) => wag.classCode === this.classCodeG
    );
    if (this.returnWagonList.length > 0) {
      this.returnWagonList = this.returnWagonList.filter(
        (wag) => wag.classCode === this.classCodeR
      );
    }
  }

  backWagon() {
    this.wagonSelected = false;
    this.passengers = this.passengersCopy;
    // this.firstTrain ? (this.outwardSeats = []) : (this.returnSeats = []);
    this.firstTrain = false;
    this.secondWagon = false;
    this.selectWagon();
  }

  wagonChange(previous: boolean) {
    if (this.firstTrain && this.wagon < this.outwardWagonList.length) {
      previous
        ? this.wagon === 0
          ? (this.wagon = this.outwardWagonList.length - 1)
          : (this.wagon -= 1)
        : this.wagon === this.outwardWagonList.length - 1
        ? (this.wagon = 0)
        : (this.wagon += 1);
    }
    if (!this.firstTrain && this.wagon < this.returnWagonList.length) {
      previous
        ? this.wagon === 0
          ? (this.wagon = this.returnWagonList.length - 1)
          : (this.wagon -= 1)
        : this.wagon === this.returnWagonList.length - 1
        ? (this.wagon = 0)
        : (this.wagon += 1);
    }
    if (
      (this.outwardWagonList.length === this.wagon && this.firstTrain) ||
      (!this.firstTrain &&
        this.returnWagonList.length > 0 &&
        this.returnWagonList.length === this.wagon)
    ) {
      this.wagon = 0;
    }
    this.selectWagon();
  }

  selectWagon() {
    this.selectedSeat = null;
    if (this.firstTrain) {
      this.wagonNumber = this.outwardWagonList[this.wagon].carCode;
      if (this.outwardSeats.length > 0) {
        this.selectedSeat = this.outwardSeats[0];
      }
    } else if (!this.firstTrain && this.returnWagonList.length > 0) {
      if (this.returnWagonList[this.wagon] == null) {
        this.wagon = this.returnWagonList.length - 1;
      }
      this.wagonNumber = this.returnWagonList[this.wagon].carCode;
      this.secondWagon = true;
      if (this.returnSeats.length > 0) {
        this.selectedSeat = this.returnSeats[0];
      }
    }
    this.wagonSelected = true;
  }

  getIcon(availSeat: any) {
    if (availSeat.isFreeSeat === false) {
      return "assets/img/svg/train/seat-unavailable.svg";
    } else {
      return availSeat.selected === true
        ? "assets/img/svg/train/seat-selected-fill.svg"
        : "assets/img/svg/train/seat-available.svg";
    }
  }

  selectSeat(seat: seat, indexRow: number, seatIndex: number) {
    if (seat.isFreeSeat) {
      let outwardSeat: seat = null;
      let returnSeat: seat = null;
      if (this.firstTrain) {
        outwardSeat =
          this.outwardWagonList[this.wagon].rowSeats[indexRow].seats[seatIndex];
      } else {
        returnSeat =
          this.returnWagonList.length > 0
            ? this.returnWagonList[this.wagon].rowSeats[indexRow].seats[
                seatIndex
              ]
            : null;
      }

      // Primer tren teniendo mas de 1 pasajero y sin selecionar el mismo asiento
      if (
        this.firstTrain &&
        this.passengers !== 0 &&
        this.outwardSeats.length < this.passengersCopy &&
        !outwardSeat.selected
      ) {
        seat.selected = true;
        this.outwardSeats.push({
          seat_code: seat.seatNumber,
          car_code: this.wagonNumber,
          type: seat.type,
          seatDirection: seat.seatDirection,
        });
        this.passengers -= 1;
        this.selectedSeat = seat;

        // Segundo tren teniendo mas de 1 pasajero y sin selecionar el mismo asiento
      } else if (
        !this.firstTrain &&
        this.passengers !== 0 &&
        this.returnSeats.length < this.passengersCopy &&
        !returnSeat.selected
      ) {
        seat.selected = true;
        this.returnSeats.push({
          seat_code: seat.seatNumber,
          car_code: this.wagonNumber,
          type: seat.type,
          seatDirection: seat.seatDirection,
        });
        this.passengers -= 1;
        this.selectedSeat = seat;

        // Primer tren habiendo seleccionado un asiento ya seleccionado
      } else if (this.firstTrain && outwardSeat.selected) {
        const foundSeatIdx = this.outwardSeats.find(
          (seatInside) => seatInside.seat_code === seat.seatNumber
        );
        this.outwardSeats.splice(foundSeatIdx, 1);
        seat.selected = false;
        if (
          this.passengers < this.passengersCopy &&
          this.passengers !== this.passengersCopy
        ) {
          this.passengers += 1;
        }
        this.selectedSeat = null;
        // Segundo tren habiendo seleccionado un asiento ya seleccionado
      } else if (!this.firstTrain && returnSeat.selected) {
        const foundSeatIdx = this.returnSeats.findIndex(
          (seatInside) => seatInside.seat_code === seat.seatNumber
        );
        this.returnSeats.splice(foundSeatIdx, 1);
        seat.selected = false;
        if (
          this.passengers < this.passengersCopy &&
          this.passengers !== this.passengersCopy
        ) {
          this.passengers += 1;
        }
        this.selectedSeat = null;
      }
      this.selectSeatEvent.emit({
        outwardSeats: this.outwardSeats,
        returnSeats: this.returnSeats,
        selectedSeat: this.selectedSeat,
        passengers: this.passengers,
      });
    }
  }
}
