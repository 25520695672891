import { Location } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute } from "@angular/router";
import { LetItGoType } from "@core/models/letitgo-type";
// import { User } from "@models";
import { NgbDateParserFormatter, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AuthService,
  BookedService,
  NgxToastrService,
  UtilsService,
} from "@services";
import { AppState } from "app/app.reducer";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, take } from "rxjs/operators";
import swal from "sweetalert2";
import { FlightService } from "../../../core/services/flight.service";
import { TrainService } from "../../../core/services/train.service";
import { DetailsComponent } from "./components/details/details.component";
import { User } from "@core/models";

@Component({
  selector: "app-authorizations",
  templateUrl: "./authorizations.component.html",
  styleUrls: ["./authorizations.component.scss"],
})
export class AuthorizationsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  authorizations: any[] = [];
  pagination: any;
  type: "booking" | "flight" | "train" | "all";
  status: "request" | "denied" | "accepted" | "no vacancy" | "all";
  userName: string;
  userSurname: string;
  description: string;
  initFromDate: string;
  endFromDate: string;
  initToDate: string;
  endToDate: string;
  sort: string;
  page: number;
  params: any = {};
  statusOptions = [];
  typeOptions = [];
  subject: Subject<any> = new Subject<any>();
  hasCleared = false;
  // traducciones
  canLoadFilters = false;
  trUserName: string;
  trUserLastName: string;
  trDescription: string;
  trFromDate: string;
  trToDate: string;
  trComments: string;
  trSelect: string;
  lowValue = 0;
  highValue = 10;
  loading: boolean = false;
  isLoading: boolean = false;
  hasPrivileges = false;
  @Input() companyUuid: string;
  canUseResident: any;
  user: any;
  @Input() isCompanyBookeds? = true;
  @Input() typeFromBookeds?: "booking" | "flight" | "train" | "all" = "all";
  @Input() userUuid?: string;
  typeComponent: any[] = [];
  @Output() changeComponentEmit: EventEmitter<any> = new EventEmitter<any>();
  component: any;
  length: number;
  filter = "";
  residentOptions = [
    { value: "none", text: "Ninguno" },
    { value: "CN", text: "Canarias" },
    { value: "CE", text: "Ceuta" },
    { value: "BL", text: "Baleares" },
    { value: "ML", text: "Melilla" },
  ];
  @ViewChild("fromInput") fromInput;
  authorization: any;
  /**
   * Let's initialize our subject for changes event in INPUT fields
   * @param bookedService service where API calls are stored
   * @param translateService service to get i18n values
   * @param ngbDateParser service to parse Ngb dates to strings like values;
   * @param ngxToastrService service to show an alert when the flow is done, either is a succesful result or not
   * @param location: angular location service
   */
  constructor(
    private bookedService: BookedService,
    private flightService: FlightService,
    private trainService: TrainService,
    private translateService: TranslateService,
    private ngbDateParser: NgbDateParserFormatter,
    private ngxToastrService: NgxToastrService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private location: Location,
    public utilsService: UtilsService,
    private modalService: NgbModal,
    private authService: AuthService,

  ) {
    this.utilsService.socket.on("create-booking-authorization", (result) => {
      if (this.authorization && this.authorization.requestData.correlationId === result.correlationId) {
        this.utilsService.loadingHotels = false;
        if((result.data.type && (result.data.type?.toLowerCase().includes("error") || result.data.type?.toLowerCase().includes("exception"))) || (result.data.bookingStatus && (result.data.bookingStatus?.toLowerCase().includes('unknown') || result.data.bookingStatus?.toLowerCase().includes('error')))) {
          swal.close();
          this.translateService
            .get([
              "common.congrat",
              "authorization.swal.accept-success",
            ])
            .subscribe((response) => {
              swal
                .fire({
                  position: "center",
                  icon: "success",
                  iconColor: "#69C9C1",
                  title: response["common.congrat"],
                  text: response[
                    "authorization.swal.accept-success"
                  ],
                  showConfirmButton: false,
                  timer: 3000,
                })
                .then(() => {
                  let price = 0;
                  let children = 0;
                  let adults = 0;
                  this.authorization.requestData.rooms.forEach((r) => {
                    price += parseFloat(r.totalWithPercentage || r.net);
                    r.paxes.forEach(p => {
                      if (p.type?.toUpperCase() === 'CH') {
                        children++;
                      } else if (p.type?.toUpperCase() === 'AD') {
                        adults++;
                      }
                    })
                  });
                  const body =
                  {
                    hotel: {
                      room: {
                        children: children,
                        adults: adults
                      },
                      totalRooms: this.authorization.requestData?.rooms?.length,
                      name: this.authorization.requestData.hotelName,
                      address: this.authorization.requestData.destinationName
                    },
                    checkIn: moment(
                      this.authorization.requestData.checkIn
                    ).format("DD-MM-YYYY"),
                    checkOut: moment(
                      this.authorization.requestData.checkOut
                    ).format("DD-MM-YYYY"),
                  totalPrice: price,
                }

                  if (this.authorization.requestData && this.authorization.requestData.comments) {
                    body['comments'] = this.authorization.requestData.comments;
                  }

                  if (this.authorization.requestData && this.authorization.requestData.customCode) {
                    body['customCode'] = this.authorization.requestData.customCode;
                  }

                  if (this.authorization.requestData && this.authorization.requestData.costCenter) {
                    body['costCenter'] = this.authorization.requestData.costCenter;
                  }

                  if (this.authorization.requestData && this.authorization.requestData.rooms && this.authorization.requestData.rooms.length > 0 && this.authorization.requestData.rooms[0].breakfast) {
                    body['breakfast'] = this.authorization.requestData.rooms[0].breakfast;
                  }

                  if (this.authorization?.requestData?.rooms[0]?.boardName) {
                    body['boardName'] = this.authorization.requestData.rooms[0].boardName;
                  }

                  if (this.authorization.requestData && this.authorization.requestData.rooms && this.authorization.requestData.rooms.length > 0 && (this.authorization.requestData.rooms[0].cancellation || this.authorization.requestData.rooms[0].cancellationDate)) {
                    body['cancellation'] = this.authorization.requestData.rooms[0].cancellation;
                    body['cancellationDate'] = this.authorization.requestData.rooms[0].cancellationDate ?? null;
                  }

                  if (this.authorization.requestData?.rooms?.length > 0 && (this.authorization.requestData.rooms[0].roomName || this.authorization.requestData.rooms[0].nameRoom)) {
                    body.hotel.room['roomName'] = this.authorization.requestData.rooms[0].roomName ?? this.authorization.requestData.rooms[0].nameRoom;
                  }

                  this.utilsService.sendEmailOperations(
                    LetItGoType.hotel,
                    body,
                    this.authorization["userUuid"]
                  );
                  this.loading = false;
                  this.getAuthorizationList();
                });
            });
        } else {
          this.translateService
            .get([
              "common.congrat",
              "authorization.swal.accept-success",
            ])
            .subscribe((response) => {
              swal
                .fire({
                  position: "center",
                  icon: "success",
                  iconColor: "#69C9C1",
                  title: response["common.congrat"],
                  text: response[
                    "authorization.swal.accept-success"
                  ],
                  showConfirmButton: false,
                  timer: 3000,
                })
                .then(() => {
                  this.loading = false;
                  this.getAuthorizationList();
                  this.authService.totalAuthorizations--;
                });
            });
        }
      }
    })
    this.subject
      .pipe(debounceTime(800), distinctUntilChanged())
      .subscribe((s) => {
        // llamada a método que actualiza el objeto params
        this.updateParams(s.param, s.value);
        this.getAuthorizationList();
      });

    this.subscriptions.push(
      this.translateService
        .get([
          "common.bookeds",
          "company.company-detail.request",
          "company.company-detail.authorizations",
        ])
        .subscribe((result) => {
          this.typeComponent = [
            { text: result["common.bookeds"], value: "booked" },
            {
              text: result["company.company-detail.request"],
              value: "request",
            },
            {
              text: result["company.company-detail.authorizations"],
              value: "authorization",
            },
          ];
          this.component = 2;
        })
    );
  }

  /**
   * Init life cycle, getting i18n translations and making our first call with default values:
   * pending request and booking type
   */
  ngOnInit() {
    this.getTranslations();
    this.page = 1;
    this.status = !this.isCompanyBookeds ? "all" : "request";
    this.type = this.typeFromBookeds !== null ? this.typeFromBookeds : "all";
    this.params["type"] = this.type;
    this.params["status"] = this.status;
    this.getUserType();
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  getUserType() {
    this.subscriptions.push(
      this.store.select("auth").subscribe((s) => {
        const st: any = s;
        this.hasPrivileges =
          st.user.type?.toLowerCase() === "employee" ||
            st.user.accessType?.toLowerCase() === "hub" ||
            st.user.accessType?.toLowerCase() === "agency"
            ? true
            : false;
        this.hasPrivileges
          ? (this.companyUuid = this.route.snapshot.paramMap.get("id") || this.companyUuid)
          : st.user.companyUuid;
        this.user = st.user;
        this.getAuthorizationList();
      })
    );
  }
  /**
   * With this method, we get the translations for placeholders in html templates, and the status options array
   */
  getTranslations() {
    this.subscriptions.push(
      this.translateService
        .get([
          "authorization.labels.name",
          "authorization.labels.lastName",
          "authorization.labels.description",
          "authorization.labels.from",
          "authorization.labels.to",
          "authorization.comments",
          "authorization.status.title",
          "authorization.status.denied",
          "authorization.status.accepted",
          "authorization.status.no-vacancy",
          "authorization.status.expired",
          "authorization.status.request",
          "authorization.status.all",
          "authorization.type.booking",
          "authorization.type.flight",
          "authorization.type.train",
          "authorization.type.all",
        ])
        .pipe(take(1))
        .subscribe((result) => {
          // labels in html filters
          this.trUserName = result["authorization.labels.name"];
          this.trUserLastName = result["authorization.labels.lastName"];
          this.trDescription = result["authorization.labels.description"];
          this.trFromDate = result["authorization.labels.from"];
          this.trToDate = result["authorization.labels.to"];
          this.trComments = result["authorization.comments"];
          this.trSelect = result["authorization.status.title"];
          // fill array of status options
          this.statusOptions.push(
            {
              view: result["authorization.status.all"],
              value: "all",
            },
            {
              view: result["authorization.status.request"],
              value: "request",
            },
            {
              view: result["authorization.status.accepted"],
              value: "accepted",
            },
            {
              view: result["authorization.status.denied"],
              value: "denied",
            },
            {
              view: result["authorization.status.expired"],
              value: "no vacancy",
            }
          );
          this.typeOptions.push(
            {
              view: result["authorization.type.all"],
              value: "all",
            },
            {
              view: result["authorization.type.booking"],
              value: "booking",
            },
            {
              view: result["authorization.type.flight"],
              value: "flight",
            },
            {
              view: result["authorization.type.train"],
              value: "train",
            }
          );
          this.canLoadFilters = true;
        })
    );
  }

  /**
   * Call to booking service GET list of authorizations, with the params object updated
   */
  getAuthorizationList() {
    if (this.hasPrivileges) {
      delete this.params["companyUuid"];
      if (this.companyUuid) {
        this.params["companyUuid"] = this.companyUuid;
      }
    } else {
      if (this.companyUuid) {
        this.params["companyUuid"] = this.companyUuid;
      } else {
        this.params["companyUuid"] = this.user.companyUuid || this.route.snapshot.paramMap.get("uuid");
      }
    }
    if (
      this.user &&
      this.user.isGroupAdmin &&
      this.user.accessType?.toLowerCase() !== "company"
    ) {
        this.params["groupRestrictionUuid"] = this.user.groupRestrictionUuid;
    }
    if (this.route.snapshot.paramMap.has("agencyUuid")) {
      delete this.params["companyUuid"];
      this.params["agencyUuid"] =
      this.route.snapshot.paramMap.get("agencyUuid");
    }
    if (this.type?.toLowerCase() === "all") {
      delete this.params["type"];
    }

    if (this.userUuid && this.user.accessType?.toLowerCase() === "employee" || this.route.snapshot.params.uuid) {
      this.params["userUuid"] = this.userUuid;
    }
    if (this.params["status"] === "all") {
      delete this.params["status"];
    }
    this.subscriptions.push(
      this.bookedService.getAuthorization(this.params).subscribe((result) => {
        this.authorizations = result;
        this.length = result.length;
        this.pagination = result;
        this.loading = false;
        this.isLoading = false;
        delete this.pagination.docs;
        if (this.authorizations && this.authorizations.length > 0 &&
          !this.authorizations.some(
            (authorization) => authorization.status === "request"
          )
        ) {
          this.utilsService.haveRequest = false;
        }
        if (this.authorizations && this.authorizations.length > 0 && this.status === "request") {
          this.authService.totalAuthorizations = this.length;
        }
      })
    );
  }

  /**
   * Method triggered when a filter field changes. If it is an INPUT field, it calls our subject.
   * If it is not, it calls directly to our updateParams method and to the API caller method
   * @param param field name
   * @param event field value
   * @see updateParams()
   * @see getAuthorizationList()
   */
  filterParam(param: string, event?) {
    // this.filter = event!!;
    this.hasCleared = false;
    if (
      param === "userName" ||
      param === "userLastName" ||
      param === "description"
    ) {
      this.subject.next({ param: param, value: event });
    } else {
      this.updateParams(param, event);
      this.getAuthorizationList();
    }
  }

  /**
   * Update the params object. If it is the status case, the value will be the status variable above.
   * @param param field name
   * @param value field value
   */
  updateParams(param: string, value?) {
    if (param !== "page") {
      delete this.params["page"];
      this.length = null;
      this.pagination = null;
    }
    switch (param) {
      case "userName":
        value === ""
          ? delete this.params["userName"]
          : (this.params["userName"] = value);
        break;
      case "userLastName":
        value === ""
          ? delete this.params["userSurname"]
          : (this.params["userSurname"] = value);
        break;
      case "status":
        this.status === "all"
          ? delete this.params["status"]
          : (this.params["status"] = this.status);
        break;
      case "type":
        this.type?.toLowerCase() === "all"
          ? delete this.params["type"]
          : (this.params["type"] = this.type);
        break;
      case "description":
        value === ""
          ? delete this.params["description"]
          : (this.params["description"] = value);
        break;
      case "fromDate":
        this.params["fromDate"] = moment(
          this.ngbDateParser.format(value),
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
        this.fromInput["nativeElement"]["value"] = moment(
          this.ngbDateParser.format(value),
          "DD/MM/YYYY"
        ).format("DD/MM/YYYY");
        break;
      case "toDate":
        this.params["toDate"] = moment(
          this.ngbDateParser.format(value),
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
        break;
      case "page":
        this.params["page"] = value.pageIndex + 1;
        break;
      default:
        break;
    }
  }

  /**
   * Clears the params object and call the API service with no params
   */
  clearFilters() {
    this.params = {};
    this.status = "request";
    this.filter = "";
    this.fromInput["nativeElement"]["value"] = "";
    this.type = this.typeFromBookeds !== null ? this.typeFromBookeds : "all";
    this.params["type"] = this.type;
    this.params["status"] = this.status;
    if (this.companyUuid !== null) {
      this.params["companyUuid"] = this.companyUuid;
    }
    this.getAuthorizationList();
    this.hasCleared = true;
  }

  redoFilters() {
    this.params = {};
    this.status = "all";
    this.filter = "";
    this.fromInput["nativeElement"]["value"] = "";
    this.type = this.typeFromBookeds !== null ? this.typeFromBookeds : "all";
    this.params["type"] = this.type;
    this.params["status"] = this.status;
    if (this.companyUuid !== null) {
      this.params["companyUuid"] = this.companyUuid;
    }
    this.getAuthorizationList();
    this.hasCleared = true;
  }

  canClean() {
    return (
      this.filter !== "" || this.status !== "request" || this.type !== 'all' || this.fromInput && this.fromInput["nativeElement"]["value"] !== ""
    );
  }
  /**
   * Method triggered when pagination change event is fired
   * @param event pagination change
   */
  onPageChange(event) {
    this.updateParams("page", event);
    this.getAuthorizationList();
  }

  /**
   * Open sweet alert to accept the request
   * @param authorization the object to accept
   */
  onAccept(authorization, action) {
    this.authorization=authorization
    this.loading = true;
    // const fromD = moment(authorization.fromDate).format("DD/MM/YYYY");
    // const toD = moment(authorization.toDate).format("DD/MM/YYYY");

      this.translateService
        .get([
          "authorization.swal.title.accept",
          "authorization.swal.response",
          "common.confirm",
          "common.cancel",
        ])
        .pipe(take(1))
        .subscribe((transl) => {
          const body = {
            response: action ? action : "",
            userUuid: authorization.userUuid,
          };
          if (this.hasPrivileges) {
            body["companyUuid"] = authorization.companyUuid;
          };
          if (this.user.accessType?.toLowerCase() !== 'user' || this.user.type?.toLowerCase() !== 'user') {
            if (this.user.type?.toLowerCase() === 'employee') {
              body["adminAuthorizedUuid"] = this.user.role.uuid ? this.user.role.uuid : null;
            } else {
              body["adminAuthorizedUuid"] = this.user.uuid ? this.user.uuid : null;
            }
          };
          this.isLoading = true;
          switch (authorization.type?.toLowerCase()) {
            case "booking":
              body["travellerUuid"] = authorization.requestData.userUuid || '';
              this.subscriptions.push(
                this.bookedService
                  .changeStatusAuthorization(body, authorization.uuid, "accept")
                  .subscribe(
                    (result) => {
                      
                    },
                    (err) => {
                      swal.close();
                      if (err.status === 403 && err.error && err.error.message === "Su empresa no dispone de una tarjeta válida, contacte con su administrador") {
                        this.loading = false;
                        this.isLoading = false;
                        this.utilsService.loadingFlights = false;
            
                        this.translateService.get(["common.card-permission-error"])
                          .pipe(take(1))
                          .subscribe((value) =>
                            this.ngxToastrService.typeInfo(null, value["common.card-permission-error"])
                          );
                      } else {
                        this.translateService
                          .get([
                            "common.congrat",
                            "authorization.swal.accept-success",
                          ])
                          .subscribe((response) => {
                            swal
                              .fire({
                                position: "center",
                                icon: "success",
                                iconColor: "#69C9C1",
                                title: response["common.congrat"],
                                text: response[
                                  "authorization.swal.accept-success"
                                ],
                                showConfirmButton: false,
                                timer: 3000,
                              })
                              .then(() => {
                                let price = 0;
                                let children = 0;
                                let adults = 0;
                                authorization.requestData.rooms.forEach((r) => {
                                  price += parseFloat(r.totalWithPercentage || r.net);
                                  r.paxes.forEach(p => {
                                    if (p.type?.toUpperCase() === 'CH') {
                                      children++;
                                    } else if (p.type?.toUpperCase() === 'AD') {
                                      adults++;
                                    }
                                  })
                                });

                              const unavail = err.status === 500 && err.error && err.error.message === "There's a stop sales for the date/s" ? true : false

                                const body =
                                {
                                  hotel: {
                                    room: {
                                      children: children,
                                      adults: adults
                                    },
                                    totalRooms: authorization.requestData?.rooms?.length,
                                    name: authorization.requestData.hotelName,
                                    address: authorization.requestData.destinationName
                                  },
                                  checkIn: moment(
                                    authorization.requestData.checkIn
                                  ).format("DD-MM-YYYY"),
                                  checkOut: moment(
                                    authorization.requestData.checkOut
                                  ).format("DD-MM-YYYY"),
                                totalPrice: price,
                                unavail: unavail
                              }

                                if (authorization.requestData && authorization.requestData.comments) {
                                  body['comments'] = authorization.requestData.comments;
                                }

                                if (authorization.requestData && authorization.requestData.customCode) {
                                  body['customCode'] = authorization.requestData.customCode;
                                }

                                if (authorization.requestData && authorization.requestData.costCenter) {
                                  body['costCenter'] = authorization.requestData.costCenter;
                                }

                                if (authorization.requestData && authorization.requestData.rooms && authorization.requestData.rooms.length > 0 && authorization.requestData.rooms[0].breakfast) {
                                  body['breakfast'] = authorization.requestData.rooms[0].breakfast;
                                }

                                if (authorization?.requestData?.rooms[0]?.boardName) {
                                  body['boardName'] = authorization.requestData.rooms[0].boardName;
                                }

                                if (authorization.requestData && authorization.requestData.rooms && authorization.requestData.rooms.length > 0 && (authorization.requestData.rooms[0].cancellation || authorization.requestData.rooms[0].cancellationDate)) {
                                  body['cancellation'] = authorization.requestData.rooms[0].cancellation;
                                  body['cancellationDate'] = authorization.requestData.rooms[0].cancellationDate ?? null;
                                }

                                if (authorization.requestData?.rooms?.length > 0 && (authorization.requestData.rooms[0].roomName || authorization.requestData.rooms[0].nameRoom)) {
                                  body.hotel.room['roomName'] = authorization.requestData.rooms[0].roomName ?? authorization.requestData.rooms[0].nameRoom;
                                }

                                this.utilsService.sendEmailOperations(
                                  LetItGoType.hotel,
                                  body,
                                  authorization["userUuid"]
                                );
                                this.loading = false;
                                this.getAuthorizationList();
                              });
                          });
                      }
                    }
                  )
              );
              this.authService.totalAuthorizations--;
              break;
            case "flight":
              this.subscriptions.push(
                this.flightService
                  .changeStatusAuthorization(body, authorization.uuid, "accept")
                  .subscribe(
                    (r) => {
                      this.translateService
                        .get([
                          "common.congrat",
                          "authorization.swal.accept-success",
                        ])
                        .subscribe((response) => {
                          swal
                            .fire({
                              position: "center",
                              icon: "success",
                              title: response["common.congrat"],
                              text: response[
                                "authorization.swal.accept-success"
                              ],
                              showConfirmButton: false,
                              timer: 3000,
                            })
                            .then(() => {
                              this.loading = false;
                              this.getAuthorizationList();
                            });
                        });
                    },
                    (err) => {
                      if (err.status === 403 && err.error && err.error.message === "Su empresa no dispone de una tarjeta válida, contacte con su administrador") {
                        this.loading = false;
                        this.isLoading = false;
                        this.utilsService.loadingFlights = false;
            
                        this.translateService.get(["common.card-permission-error"])
                          .pipe(take(1))
                          .subscribe((value) =>
                            this.ngxToastrService.typeInfo(null, value["common.card-permission-error"])
                          );
                      } else {
                        const unavail = err.status === 400 && err.error && err?.error?.message?.startsWith('Flight-Wrapper.flightOffersSeach: "ErrorRS: KO - AVAIL_OUT') ? true : false
                        this.translateService
                          .get([
                            "common.congrat",
                            "authorization.swal.accept-success",
                          ])
                          .subscribe((response) => {
                            swal
                              .fire({
                                position: "center",
                                icon: "success",
                                title: response["common.congrat"],
                                text: response[
                                  "authorization.swal.accept-success"
                                ],
                                showConfirmButton: false,
                                timer: 3000,
                              })
                              .then(() => {
                                const parsedAncillaries = [];
                                const ancillaries = authorization.requestData.query.ancillaries || '';
                                const { seats, stops, stopsText } = authorization.requestData.letItGoInfo;
                                const body2 = {
                                  lleegoBookingID: null,
                                  itinerary: authorization.requestData.letItGoInfo.itinerary,
                                  dates: `${authorization.requestData.departDate ?? moment(authorization.requestData.departureDate).format('DD/MM/YYYY HH:mm')
                                    } ${authorization.requestData.returnDate
                                      ? moment(authorization.requestData.returnDate).format("DD/MM/YYYY HH:mm")
                                      : ""
                                    }`,
                                  price: authorization.requestData.letItGoInfo.price || authorization.price || authorization.requestData.price || authorization.requestData.query.vyooPrice,
                                  seats,
                                  // baggages: authorization.requestData.query.ancillaries ,
                                  travelerList: authorization.requestData.query.travellers,
                                  travellers: authorization.requestData.query.travellers.length,
                                  stops,
                                  stopsText,
                                  fares: { fareName: authorization.requestData?.query?.fares?.fareName, fareCondition: authorization.requestData.query?.fares?.fareSelected?.services, price: authorization.requestData.query?.fares?.fareSelected?.total_price },
                                  unavail: unavail
                                };

                                if (ancillaries != null && ancillaries.length > 0) {
                                  ancillaries.forEach((baggage) => {
                                    {
                                      parsedAncillaries.push({
                                        price: baggage.total,
                                        name: baggage.short_name,
                                      });
                                    }
                                  });
                                  body2['baggages'] = parsedAncillaries;
                                }
                                if (this.canUseResident) {
                                  this.residentOptions.find((r) => {
                                    if (r.value === this.canUseResident) {
                                      body2["canUseResident"] = r.text;
                                    }
                                  })
                                }
                                this.utilsService.sendEmailOperations(
                                  LetItGoType.flight,
                                  body2,
                                  authorization["userUuid"]
                                );
                                this.loading = false;
                                this.getAuthorizationList();
                              });
                          });
                      }
                      this.authService.totalAuthorizations--;
                      swal.close();
                      this.loading = false;
                      // this.subscriptions.push(
                      //   this.translateService
                      //     .get("authorization.flight-expired")
                      //     .subscribe((resp) => {
                      //       this.ngxToastrService.typeInfo(
                      //         resp,
                      //         err.error.message
                      //       );
                      //     })
                      // );
                    }
                  )
              );
              break;
            case "train":
              this.subscriptions.push(
                this.trainService
                  .changeStatusAuthorization(body, authorization.uuid, "accept")
                  .subscribe(
                    (r) => {
                      this.translateService
                        .get([
                          "common.congrat",
                          "authorization.swal.accept-success",
                        ])
                        .subscribe((response) => {
                          swal
                            .fire({
                              position: "center",
                              icon: "success",
                              title: response["common.congrat"],
                              text: response[
                                "authorization.swal.accept-success"
                              ],
                              showConfirmButton: false,
                              timer: 3000,
                            })
                            .then(() => {
                              this.loading = false;
                              this.getAuthorizationList();
                            });
                        });
                    },
                    (err) => {
                      if (err.status === 403 && err.error && err.error.message === "Su empresa no dispone de una tarjeta válida, contacte con su administrador") {
                        this.loading = false;
                        this.isLoading = false;
                        this.utilsService.loadingFlights = false;
            
                        this.translateService.get(["common.card-permission-error"])
                          .pipe(take(1))
                          .subscribe((value) =>
                            this.ngxToastrService.typeInfo(null, value["common.card-permission-error"])
                          );
                      } else {
                        this.translateService
                          .get([
                            "common.congrat",
                            "authorization.swal.accept-success",
                          ])
                          .subscribe((response) => {
                            swal
                              .fire({
                                position: "center",
                                icon: "success",
                                title: response["common.congrat"],
                                text: response[
                                  "authorization.swal.accept-success"
                                ],
                                showConfirmButton: false,
                                timer: 3000,
                              })
                              .then(() => {
                                const body2 = {
                                  itinerary: `${authorization.requestData.origin} - ${authorization.requestData.destination}`,
                                  dates: `${moment(authorization.requestData.departDate).format('DD/MM/YYYY HH:mm')
                                    } ${authorization.requestData.returnDate
                                      ? moment(authorization.requestData.returnDate).format('DD/MM/YYYY HH:mm')
                                      : ""
                                    }`,
                                  price: authorization.requestData.price ?? authorization.price,
                                  seats: authorization.requestData.seats,
                                  travelers: [`${authorization.requestData.passengers[0].name} ${authorization.requestData.passengers[0].firstSurname} ${authorization.requestData.passengers[0].secondSurname ?? ''}`],
                                  rates: authorization.requestData.rates,
                                  passengers: authorization.requestData.passengers

                                };
                                // if (this.trains.length > 1) {
                                //   body.dates =
                                //     `${this.trains[0].travelDate} ${this.trains[0].departureTime}` +
                                //     " - " +
                                //     `${this.trains[1].travelDate} ${this.trains[1].departureTime}`;
                                // }
                                this.utilsService.sendEmailOperations(
                                  LetItGoType.train,
                                  body2,
                                  authorization["userUuid"]
                                );
                                this.loading = false;
                                this.getAuthorizationList();
                              });
                          });
                      }
                      swal.close();
                      /*this.subscriptions.push(
                        this.translateService
                          .get("authorization.train-expired")
                          .subscribe((resp) => {
                            this.ngxToastrService.typeInfo(
                              resp,
                              err.error.message
                            );
                          })
                      );*/
                    }
                  )
              );
              break;
            default:
              break;
          }
        })
    this.loading = false;
    this.authService.totalAuthorizations--;
  }

  /**
   * Open sweet alert to deny the request
   * @param authorization the object to deny
   */
  onDeny(authorization, action) {
    this.loading = true;
    // const fromD = moment(authorization.fromDate).format("DD/MM/YYYY");
    // const toD = moment(authorization.toDate).format("DD/MM/YYYY");
    const body = {
      response: action,
      userUuid: authorization.userUuid
    };
    if (this.hasPrivileges) {
      body["companyUuid"] = authorization.companyUuid;
    };
    if (this.user.accessType?.toLowerCase() !== 'user' || this.user.type?.toLowerCase() !== 'user') {
      if (this.user.type?.toLowerCase() === 'employee') {
        body["adminAuthorizedUuid"] = this.user.role.uuid ? this.user.role.uuid : null;
      } else {
        body["adminAuthorizedUuid"] = this.user.uuid ? this.user.uuid : null;

      }
    };
    this.subscriptions.push(
      this.bookedService
        .changeStatusAuthorization(body, authorization.uuid, "deny")
        .subscribe(
          (result) => {
            this.translateService
              .get(["common.canceled", "authorization.swal.deny-success"])
              .subscribe((response) => {
                swal
                  .fire({
                    position: "center",
                    icon: "error",
                    // iconColor:"#69C9C1",
                    title: response["common.canceled"],
                    text: response["authorization.swal.deny-success"],
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  .then(() => {
                    this.loading = false;
                    this.getAuthorizationList();
                  });
              });
          },
          (err) => {
            swal.close();
            this.subscriptions.push(
              this.translateService.get("common.error").subscribe((resp) => {
                this.ngxToastrService.typeInfo(resp, err.error.message);
              })
            );
          }
        )
    );
    this.authService.totalAuthorizations--;
  }

  onDetails(authorization) {
    const fromD = moment(authorization.fromDate).format("DD/MM/YYYY");
    let toD;
    if (authorization.toDate != null) {
      toD = moment(authorization.toDate).format("DD/MM/YYYY");
    }

    const modalRef = this.modalService.open(DetailsComponent, {
      size: "lg",
      centered: true,
      modalDialogClass: "auth-details-component",
    });

    modalRef.componentInstance.authorization = authorization;
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.fromD = fromD;
    modalRef.componentInstance.toD = toD;
    modalRef.componentInstance.sendInfo.subscribe(
      (r: { deny: any; authorization: any; action: any; loading: boolean; authorizationUser: User }) => {
        this.isLoading = r.loading;
        if (r.deny) {
          this.onDeny(r.authorization, r.action);
          modalRef.close();
        } else {
          this.canUseResident = (r.authorizationUser?.resident && r.authorizationUser?.resident !== "none") ? r.authorizationUser?.resident : undefined;
          this.onAccept(r.authorization, r.action);
          modalRef.close();
        }
      }
    );
  }

  /**
   * If there is no results while filtering, reload default table
   * If there is no results after that, go back
   */
  goBack() {
    this.location.back();
  }

  changeComponent(event) {
    this.changeComponentEmit.emit(this.typeComponent[event]);
    this.component = this.typeComponent.indexOf(this.typeComponent[event]);
  }

  /**
   * Last method of life cycle component, where we unsuscribe our subscriptions and subject.
   */
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subject.unsubscribe();
  }
}
