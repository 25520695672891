import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RequestCustomHotelComponent } from "../custom-services/components/request-custom-hotel/request-custom-hotel.component";

const routes: Routes = [{ path: "", component: RequestCustomHotelComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HotelRoutingModule {}
