import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { UserService } from "@core/services";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "app/app.reducer";
import { AuthState } from "app/store/reducers/auth.reducer";
import { saveAs } from "file-saver";
import { Subscription } from "rxjs";
import swal from "sweetalert2";

@Component({
  selector: "app-dialog-user-files",
  templateUrl: "./dialog-user-files.component.html",
  styleUrls: ["./dialog-user-files.component.scss"],
})
export class DialogUserFilesComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() nameFile;
  paginationConf;
  user;
  lowValue = 0;
  highValue = 10;
  pagination: any;
  selectedFile: string;
  fileToUpload: File;
  @ViewChild("fileUpload")
  private testElement: ElementRef;
  globalInstance: any;
  searchInput: String;
  originalUserFiles: String[];
  isLoading = false;
  subscriptions: Subscription[] = [];

  constructor(
    private userService: UserService,
    private renderer: Renderer2,
    private store: Store<AppState>,
    private translate: TranslateService,
    public dialog: MatDialogRef<DialogUserFilesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngAfterViewInit() {
    const fileInput = document.querySelector("input[type=file]");
    fileInput.addEventListener("click", function () {
      this.value = null;
    });
    this.globalInstance = this.renderer.listen(
      this.testElement.nativeElement,
      "change",
      () => {
        this.selectedFile = null;
        this.fileToUpload = null;
        if (document.getElementsByClassName("selected").length != 0) {
          document
            .getElementsByClassName("selected")[0]
            .classList.remove("selected");
        }
        this.fileToUpload = (fileInput as HTMLInputElement).files[0];
      }
    );
  }
  getUser() {
    this.store.select("auth").subscribe((authState: AuthState) => {
      this.user = JSON.parse(JSON.stringify(authState.user));
      this.originalUserFiles = this.user.files;
    });
  }

  ngOnInit(): void {
    if (
      (window.history && window.history.state && window.history.state.user) ||
      (this.data && this.data.user)
    ) {
      this.user = JSON.parse(
        JSON.stringify(window.history.state.user || this.data.user)
      );
      this.updateFiles();
      this.isLoading = false;
    } else {
      this.getUser();
      this.updateFiles();
      this.isLoading = false;
    }
    document.addEventListener('click', this.onDocumentClick.bind(this));
  }

  onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
  
    if (!targetElement.closest('.more-btn-position')) {
      this.selectedFile = null;
  
      const selectedElements = document.querySelectorAll('.file.selected');
      selectedElements.forEach(element => {
        element.classList.remove('selected');
      });
    }
  }

  getFiletype(filename: String) {
    let filetype = "unknown";
    const fileExtension = filename
      .substring(filename.lastIndexOf(".") + 1)
      ?.toLowerCase();
    if (fileExtension != null) {
      switch (fileExtension) {
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
        case "heic":
          filetype = "image";
          break;
        case "pdf":
          filetype = "pdf";
          break;
        case "doc":
        case "docx":
          filetype = "word";
          break;
        case "xls":
        case "xlsx":
          filetype = "excel";
          break;
        case "mp3":
        case "wav":
        case "ogg":
        case "flac":
        case "aac":
          filetype = "audio";
          break;
        default:
          break;
      }
    }
    return `assets/img/svg/doc_${filetype}_v2.svg`;
  }

  selectFile(file, i: number) {
    this.cancelFileUpload();
    if (this.selectedFile === file) {
      this.selectedFile = null;
      document.getElementsByClassName("file")[i].classList.remove("selected");
    } else {
      if (this.selectedFile != null) {
        if (document.getElementsByClassName("selected").length != 0) {
          document
            .getElementsByClassName("selected")[0]
            .classList.remove("selected");
        }
      }
      this.selectedFile = file;
      document.getElementsByClassName("file")[i].classList.add("selected");
    }
  }

  deleteSelectedFile(file: any) {
    const smallTitle =
      file.length > 20
        ? file.substring(0, 20) +
          "..." +
          file.substring(file.length - 4, file.length)
        : file;
    this.subscriptions.push(
      this.translate
        .get([
          "user.details.cancel-file",
          "user.details.action-no-undo-2",
          "common.confirm",
          "common.cancel",
        ])
        .subscribe((result) => {
          swal
            .fire({
              title: result["user.details.cancel-file"] + smallTitle + " ?",
              text: result["user.details.action-no-undo-2"],
              showCancelButton: true,
              confirmButtonColor: "#37D197",
              cancelButtonColor: "#F46F69",
              cancelButtonText: result["common.cancel"],
              customClass: {
                confirmButton: "btn btn-success btn-raised",
                cancelButton: "btn btn-danger btn-raised mr-4",
                container: "container-style",
              },
              buttonsStyling: false,
            })
            .then((action) => {
              if (action.value) {
                this.isLoading = true;
                this.subscriptions.push(
                  this.userService
                    .deleteUserFile(this.user.uuid, file)
                    .subscribe(
                      (res) => {
                        this.updateFiles();
                        this.isLoading = false;
                      },
                      (err) => {
                        console.error(err);
                        this.isLoading = false;
                      }
                    )
                );
              }
            });
        })
    );
  }

  updateFiles() {
    this.isLoading = true;
    this.subscriptions.push(
      this.userService.getUser(this.user.uuid).subscribe(
        (resUser) => {
          this.user = resUser;
          this.originalUserFiles = this.user.files;
          this.fileToUpload = null;
          this.selectedFile = null;
          this.searchInput = "";
        },
        (err2) => {
          console.error(err2);
        }
      )
    );
  }

  downloadAll() {
    this.isLoading = true;
    for (let index = 0; index < this.user.files.length; index++) {
      const file = this.user.files[index];
      this.downloadFile(file, index);
    }
  }

  downloadFile(file, index) {
    this.userService
      .getUserFile(this.user.uuid, file)
      .then((blob) => {
        saveAs(blob, file);
        if (index + 1 === this.user.files.length) {
          this.isLoading = false;
        }
      })
      .catch((err) => {
        console.error("download error ", err);
      });
  }

  downloadSelectedFile() {
    this.isLoading = true;
    this.userService
      .getUserFile(this.user.uuid, this.selectedFile)
      .then((blob) => {
        saveAs(blob, this.selectedFile);
        this.isLoading = false;
      })
      .catch((err) => {
        console.error("download error ", err);
      });
    // this.isLoading = false;
  }

  cancelFileUpload() {
    this.fileToUpload = null;
  }

  confirmFileUpload(event) {
    const file = event.target.files[0].name;
    const fileName =
      file.length > 20
        ? file.substring(0, 20) +
          "..." +
          file.substring(file.length - 4, file.length)
        : file;
    const fileFormData = new FormData();
    fileFormData.append("file", event.target.files[0]);
    this.isLoading = true;
    this.subscriptions.push(
      this.translate
        .get([
          "user.details.action-upload-file",
          "user.details.action-no-undo-2",
          "common.confirm",
          "common.cancel",
        ])
        .subscribe((result) => {
          swal
            .fire({
              title:
                result["user.details.action-upload-file"] + fileName + " ?",
              text: result["user.details.action-no-undo-2"],
              showCancelButton: true,
              confirmButtonColor: "#37D197",
              cancelButtonColor: "#F46F69",
              //            confirmButtonText: result["common.confirm"],
              cancelButtonText: result["common.cancel"],
              customClass: {
                confirmButton: "btn btn-success btn-raised",
                cancelButton: "btn btn-danger btn-raised mr-4",
                container: "container-style",
              },
              buttonsStyling: false,
            })
            .then((action) => {
              if (action.isDismissed === false) {
                this.subscriptions.push(
                  this.userService
                    .uploadUserFile(this.user.uuid, fileFormData)
                    .subscribe(
                      (res) => {
                        this.updateFiles();
                        this.isLoading = false;
                      },
                      (err) => {
                        console.error(err);
                        this.isLoading = false;
                      }
                    )
                );
              } else {
                this.isLoading = false;
              }
            });
        })
    );
  }

  search(value: string): void {
    if (this.originalUserFiles != null && this.originalUserFiles.length != 0) {
      const filteredFiles = this.originalUserFiles.filter((item) => {
        return (
          item?.toLowerCase().includes(value?.toLowerCase()) ||
          item
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            ?.toLowerCase()
            .includes(value?.toLowerCase())
        );
      });
      if (filteredFiles.length == 0) {
        this.selectedFile = null;
        this.user.files = [];
      } else {
        this.user.files = filteredFiles;
      }
    }
  }

  ngOnDestroy() {
    this.globalInstance();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    document.removeEventListener('click', this.onDocumentClick.bind(this));
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }
}
