import { Pipe, PipeTransform } from "@angular/core";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import * as _lodash from "lodash-es";
import * as moment from "moment";

@Pipe({
  name: "filterBooked",
})
export class FilterBookedPipe implements PipeTransform {
  constructor(private ngbDateParser: NgbDateParserFormatter) {}
  public transform(value, keys: string[], terms: any[], types: any[]) {
    const rgKeys = [
      "hotel.name",
      "hotelName",
      "hotel.destinationName",
      "destinationName",
      "hotel.address",
      "from",
      "to",
      "type",
      "pickupPlaceFrom",
      "pickupPlaceTo",
      "title",
      "name",
    ];

    const dateStartKeys = ["checkIn", "departDate", "beginDate", "initDate"];

    const dateEndKeys = ["checkOut", "returnDate", "endDate"];

    if (!terms) {
      return value;
    }

    if (keys.length === 0 || terms.length === 0 || types.length === 0) {
      return value;
    }
    return (value || []).filter((item) => {
      let stringRes = true;
      let statusRes = true;
      let dateRes = true;
      let dateStartRes = true;
      let dateEndRes = true;

      for (let i = 0; i < types.length; i++) {
        if (
          terms[i] &&
          terms[i] !== "" &&
          terms[i] !== null &&
          (Array.isArray(terms[i])
            ? terms[i].every((j) => j && j !== null && j !== "")
            : true)
        ) {
          switch (types[i]) {
            case "string":
              for (const key of rgKeys) {
                const itemTest = _lodash.get(item, key, null);
                if (itemTest) {
                  if (
                    !new RegExp(
                      terms[i].normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                      "gi"
                    ).test(
                      itemTest.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    )
                  ) {
                    stringRes = false;
                  } else {
                    stringRes = true;
                    break;
                  }
                }
              }
              break;
            case "status":
              if (item.status) {
                if (item.status?.toLowerCase() === "confirmed") {
                  item.status = "active"
                }
                if (item.status === terms[i]) {
                  statusRes = true;
                } else {
                  statusRes = false;
                }
              } else {
                if (terms[i]?.toLowerCase() === "active") {
                  statusRes = true;
                } else {
                  statusRes = false;
                }
              }
              break;
            case "date":
              const fromDateFormat = moment(
                this.ngbDateParser.format(terms[i][0]),
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD");
              const toDateFormat = moment(
                this.ngbDateParser.format(
                  terms[i][1] ? terms[i][1] : terms[i][0]
                ),
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD");
              dateRes = moment(_lodash.get(item, "createdAt", null)).isBetween(
                fromDateFormat,
                toDateFormat,
                "day",
                "[]"
              );
              break;
            case "dateStart":
              const dateStartFormat = moment(
                this.ngbDateParser.format(terms[i]),
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD");
              const endFormat = moment(
                this.ngbDateParser.format(terms[i + 1]),
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD");
              for (const key of dateStartKeys) {
                const itemTest = _lodash.get(item, key, null);
                if (
                  moment(itemTest).isSameOrAfter(dateStartFormat, "day") &&
                  moment(itemTest).isSameOrBefore(endFormat, "day")
                ) {
                  dateStartRes = true;
                  break;
                } else {
                  dateStartRes = false;
                }
              }

              break;
            case "dateEnd":
              const startFormat = moment(
                this.ngbDateParser.format(terms[i - 1]),
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD");
              const dateEndFormat = moment(
                this.ngbDateParser.format(terms[i]),
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD");
              let n = 0;
              for (const key of dateEndKeys) {
                const itValue = _lodash.get(item, key, null);
                if (itValue == null) {
                  n += 1;
                } else {
                  if (
                    moment(itValue).isSameOrBefore(dateEndFormat, "day") &&
                    moment(itValue).isSameOrAfter(startFormat, "day")
                  ) {
                    dateEndRes = true;
                    break;
                  } else {
                    dateEndRes = false;
                  }
                }
              }
              if (n === 3) {
                dateEndRes = true;
              }
              break;
          }
        }
      }
      return stringRes && statusRes && dateRes && dateStartRes && dateEndRes;
    });
  }
}
