import { Injectable } from "@angular/core";

@Injectable()
export class TokenService {
  key = "token";
  constructor() {}

  getToken(): string {
    return localStorage.getItem(this.key);
  }

  saveToken(token: string) {
    localStorage.setItem(this.key, token);
  }

  destroyToken() {
    localStorage.removeItem(this.key);
  }
}
