import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService, ForgotService, NgxToastrService } from "@services";

@Component({
  selector: "app-reset-password-sent",
  templateUrl: "./reset-password-sent.component.html",
  styleUrls: ["./reset-password-sent.component.scss"],
})
export class ResetPasswordSentComponent implements OnInit {
  email: string = null;
  loading = false;
  typeUser: "user" | "company" | "employee";
  constructor(
    private authService: AuthService,
    private forgotService: ForgotService,
    private router: Router,
    private ngxToastrService: NgxToastrService,
    public translate: TranslateService
  ) {
    if (this.router.url.includes("/login")) {
      this.typeUser = "user";
    } else if (this.router.url.includes("/company-login")) {
      this.typeUser = "company";
    } else {
      this.typeUser = "employee";
    }
  }

  ngOnInit() {
    this.email = this.forgotService.getEmail();
  }

  resendEmail() {
    if (this.email !== null) {
      this.loading = true;
      const forgotPost =
        this.typeUser === "user"
          ? this.authService.forgotUser(this.email)
          : this.typeUser === "company"
          ? this.authService.forgotCompany(this.email)
          : this.authService.forgotEmployee(this.email);
      return forgotPost.subscribe(
        () => {
          this.loading = false;
          this.translate
            .get([
              "common.ready",
              "login.request-pass.reset-send.mail-sended-again",
            ])
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess(
                result["common.ready"],
                result["login.request-pass.reset-send.mail-sended-again"]
              );
            });
        },
        (error) => {
          this.loading = false;
        }
      );
    }
  }

  goToLogin() {
    switch (this.typeUser) {
      case "user":
        return ["/login"];
      case "company":
        return ["/login"];
      case "employee":
        return ["/admin-login"];
    }
  }

  getClassBackground() {
    switch (this.typeUser) {
      case "user":
        return "user-background";
      case "company":
        return "company-background";
      case "employee":
        return "employee-background";
    }
  }

  getClassLoginButton() {
    switch (this.typeUser) {
      case "user":
        return "btn-back-login-user";
      case "company":
        return "btn-back-login-company";
      case "employee":
        return "btn-back-login-employee";
    }
  }

  getClassRecoveryButton() {
    switch (this.typeUser) {
      case "user":
        return "btn-primary";
      case "company":
        return "btn-company";
      case "employee":
        return "btn-vyoo-primary";
    }
  }
}
