import { HotelModule } from "./modules/components/hotel/hotel.module";
import { PlatformModule } from "@angular/cdk/platform";
import { registerLocaleData } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import localeEs from "@angular/common/locales/es";
import { LOCALE_ID, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "@core/core.module";
import { ContentLayoutComponent } from "@core/layouts/content/content-layout.component";
import { EmptyComponent } from "@core/layouts/empty/empty.component";
import { FullLayoutComponent } from "@core/layouts/full/full-layout.component";
import { environment } from "@env/environment";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ErrorInterceptor, JwtInterceptor, MobileInterceptor } from "@helpers";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SharedModule } from "@shared/shared.module";
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from "ngx-cookieconsent";
import { NgxHotjarModule } from "ngx-hotjar";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { effectsArr } from "./app.effects";
import { appReducers } from "./app.reducer";
import { CompanyModule } from "./modules/businessEntities/company/company.module";
import { UserModule } from "./modules/businessEntities/user/user.module";
import { AirbnbModule } from "./modules/components/airbnb/airbnb.module";
import { CarModule } from "./modules/components/cars/car.module";
import { CustomServicesModule } from "./modules/components/custom-services/custom-services.module";
import { FlightTrainModule } from "./modules/components/flight-train/flight-train.module";
import { StaticsModule } from "./modules/components/statics/statics.module";
import { StatsModule } from "./modules/components/stats/stats.module";
import { TrainsModule } from "./modules/components/trains/trains.module";
registerLocaleData(localeEs);
const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: "Vyootrip.com",
  },
  position: "bottom-left",
  theme: "block",
  palette: {
    popup: {
      background: "#3F4449",
      text: "white",
      link: "white",
    },
    button: {
      background: "#f5f5f5",
      text: "#white",
      border: "transparent",
    },
  },
  type: "info",
  content: {
    message:
      "This website uses cookies to ensure you get the best experience on our website.",
    dismiss: "Got it!",
  },
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    "./assets/i18n/",
    ".json?" + environment.versionTranslate
  );
}

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        EmptyComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        StoreModule.forRoot(appReducers),
        EffectsModule.forRoot(effectsArr),
        !environment.production
            ? StoreDevtoolsModule.instrument({
                maxAge: 5,
                logOnly: environment.production,
            })
            : [],
        MatAutocompleteModule,
        SharedModule,
        CoreModule,
        CustomServicesModule,
        HttpClientModule,
        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        PerfectScrollbarModule,
        UserModule,
        HotelModule,
        CompanyModule,
        FlightTrainModule,
        BrowserModule,
        // NgxCookieBannerModule.forRoot({
        //   cookieName: 'vyootrip-cookie-banner'
        // }),
        MatSelectModule,
        MatButtonModule,
        TrainsModule,
        StatsModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        StaticsModule,
        CarModule,
        NgxHotjarModule.forRoot(environment.hotJarCode),
        MatSlideToggleModule,
        PlatformModule,
    AirbnbModule,
    ],
    exports: [MatExpansionModule, MatDatepickerModule],
    providers: [
        NgbActiveModal,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MobileInterceptor, multi: true},
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: LOCALE_ID,
            useValue: "es-ES",
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
