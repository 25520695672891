import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { BookedService, NgxToastrService, UtilsService } from '@core/services'
import {
  NgbActiveModal,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import moment from 'moment'

@Component({
  selector: 'app-summary-booked',
  templateUrl: './summary-booked.component.html',
  styleUrls: ['./summary-booked.component.scss']
})
export class SummaryBookedComponent implements OnInit, OnDestroy {
  canRequest: boolean
  @Input() userBookingOption
  @Input() hotel
  @Input() totalAdults
  @Input() totalChildrens
  @Input() searchConfig
  @Input() roomsBooked
  @Input() code: string
  @Input() uuidUser: string
  @Input() isAdmin: boolean
  @Input() selectedRooms;
  @Input() selectedUsers;
  @Input() guests: [] = [];
  form: UntypedFormGroup
  loading: boolean = false
  disabled: boolean = false
  @Output() sendInfo = new EventEmitter<any>()
  @Output() selectedRoomChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() totalPrice: number
  actualRoom: number
  showPolicies: boolean
  actualGroupRoom: number
  roomsSelected: any[]
  constructor (
    private activatedRoute: ActivatedRoute,
    private ngbDateParser: NgbDateParserFormatter,
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private utilsService: UtilsService,
    private ngxToastrService: NgxToastrService,
    private bookedService: BookedService,
  ) {
    this.utilsService.socket.on("check-rate-booking", (result) => {
      if (!!this.selectedRooms.find((room) => room.correlationId === result.correlationId)) {
        this.loading = false;
        if((result.data.type && (result.data.type?.toLowerCase().includes("error") || result.data.type?.toLowerCase().includes("exception"))) || (result.data.bookingStatus && (result.data.bookingStatus?.toLowerCase().includes('unknown') || result.data.bookingStatus?.toLowerCase().includes('error')))) {
          this.translate
          .get(['hotel.errors.check-rate', 'common.errors.try-again'])
          .subscribe(res => {
            this.ngxToastrService.typeInfo(res['hotel.errors.check-rate'], res['common.errors.try-again'])
          })
          this.activeModal.dismiss()
        } else {
          let roomOriginal: any = {};
          if ((this.code.toString().charAt(0) === "E" && result.data.status === "Available")){
            roomOriginal = this.selectedRooms.filter((room) => room.code === result.data.reference && room.rates.find((rate) => rate.rateKey.split("E")[1] === result.data.accommodation.rooms[0].rates[0].rateKey /* && !rate.price */))
          } else if (this.code.toString().charAt(0) !== "E" && result.data.status === "Available") {
            roomOriginal = this.selectedRooms.filter((room) => room.code === result.data.reference && room.rates.find((rate) => rate.rateKey === result.data.accommodation.rooms[0].rates[0].rateKey /* && !rate.price */))
          }
          roomOriginal.map((r) => {
            r.rates[0].price = result.data.price
            r.correlationId = result.correlationId;
          });
        }
      }
    })
  }

  ngOnDestroy (): void {}

  ngOnInit () {
    this.canRequest =
      this.activatedRoute.snapshot.queryParams.canRequest === 'true'
        ? true
        : false
    this.form = this.formBuilder.group({
      customCode: [null],
      costCenter: [null],
      adminHotelComment: [null],
      requestComment: [null]
    })

    this.roomsSelected = [...this.selectedRooms];
    this.roomsSelected.forEach((rooms) => {
      const parsedRoom = {...rooms};
      parsedRoom.nameRoom = rooms.name;
      parsedRoom.net = rooms.rates[0].net;
      parsedRoom.rateType = rooms.rates[0].rateType;
      parsedRoom.rateKey = rooms.rates[0].rateKey;
      parsedRoom.cancellation = !!rooms.rates[0].cancellationPolicies
      parsedRoom.cancellationPolicies = rooms.rates[0].cancellationPolicies
      parsedRoom.cancellationPolicies.forEach((item, index, array) => {
        array[index] = {
          amount: item.amount,
          from: item.from,
          text: item.text,
        }
      });
    
      const body = {
        rooms: [parsedRoom],
        userUuid: this.uuidUser,
        checkIn: `${this.searchConfig.checkIn.year}-${this.searchConfig.checkIn.month}-${this.searchConfig.checkIn.day}`,
        checkOut: `${this.searchConfig.checkOut.year}-${this.searchConfig.checkOut.month}-${this.searchConfig.checkOut.day}`,
        guests: this.guests.splice(0, rooms.paxes.length),
      };
      const correlation = crypto.randomUUID();
      this.utilsService.correlationId.push(correlation)
      body['correlationId'] = correlation;
      rooms.correlationId = correlation
      this.bookedService.checkRate(body).subscribe((rate) => {
        this.loading = true;
        rooms.correlationId = correlation;
      })
    })
  }

  getTermsOfUseLink(isAgent): string {
    const language = this.translate.currentLang;
    return isAgent
      ? `https://developer.ean.com/terms/${language}`
      : `https://developer.ean.com/terms/agent/${language === 'es' ? 'es-es' : 'en'}`;
  }

  get f () {
    return this.form.controls
  }

  formatDate (date) {
    return this.ngbDateParser.format(date)
  }

  closeModal () {
    this.activeModal.dismiss('Cross click')
  }

  onSubmit () {
    this.disabled = true
    const send = {
      rooms: this.selectedRooms,
      customCode: this.f.customCode.value ?? '',
      costCenter: this.f.costCenter.value ?? ''
    }

    if (this.f.adminHotelComment?.value) {
      send['adminHotelComment'] = this.f.adminHotelComment.value
    }

    if (this.f.requestComment?.value) {
      send['requestComment'] = this.f.requestComment.value
    }

    this.sendInfo.emit(send)
  }

  isSameOrAfterToday(date) {
    return date ? moment(date).isSameOrAfter(moment()) : null;
  }

  getTotalPrice() {
    let totalPrice = 0;
    this.selectedRooms.forEach((room) => {
      room.rates.forEach((rate) => {
        totalPrice += rate.price && rate.price.totalPrice ? Number(rate.price.totalPrice.total) : Number(rate.totalWithPercentage);
      })
    })
    return totalPrice;
  }
}
