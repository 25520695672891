import { Room } from "./room";

export class Hotel {
  uuid: string;
  code: number;
  name: string;
  description: string;
  stars: string;
  phone: string;
  email: string;
  web: string;
  destinationCode: string;
  destinationName: string;
  address: string;
  postalCode: string;
  accommodationType: string;
  latitude: number;
  longitude: number;
  images: any[];
  rooms: Room[] | any[];
  room: Room;
  userBookingOption?: number;
  distanceToDestination: string;
  totalBooked: number;
  hovered? = false;
  facility: string[];
}
