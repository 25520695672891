<div class="row mt-2">
  <div class="col-12">
    <!-- TITULO -->
    <div [ngClass]="isModal ? 'modal-header' : 'row'">
      <div [ngClass]="{ 'col-12': !isModal }">
        <h3 class="heading-5">
          {{ (editing ? "hotel.custom.title-edit" : "hotel.custom.title") | translate }}
        </h3>
      </div>
      <button
        *ngIf="isModal"
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModalCross()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <!-- FORMULARIO -->
    <div [ngClass]="isModal ? 'modal-body' : 'row'">
      <div [ngClass]="{ 'col-12': !isModal }">
        <div [ngClass]="{ 'card border-search': !isModal }">
          <div class="card-body">
            <div
              *ngIf="loading"
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            >
              <app-loading [userType]="'user'"></app-loading>
            </div>
            <form
              [formGroup]="form"
              [ngClass]="{ 'loading-background': loading }"
              (ngSubmit)="
                form.valid && !loading ? onSubmit() : (isInvalid = true)
              "
            >
              <div class="row mt-2" *ngIf="editing && (userType?.toLowerCase() === 'employee' || userType?.toLowerCase() === 'company')">
                <div class="col-12">
                  <div class="form-group">
                    <label class="pt-0 pb-0" for="traveler">{{ "common.traveller" | translate }}</label>
                    <select
                      formControlName="traveler"
                      id="traveler"
                      class="form-control custom-select"
                    >
                    <option *ngFor="let user of companyUsers" [value]="user.uuid">
                      {{ user.name }} {{ user.lastname }}
                    </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="userType === 'EMPLOYEE'">
                <div class="col-6">
                  <div class="form-group">
                    <label
                      >{{
                        "flight" + ".create-" + "flight" + ".booked-file"
                          | translate
                      }}*</label
                    >
                    <div>
                      <input
                        formControlName="file"
                        type="file"
                        id="file"
                        class="inputFile"
                        (change)="onFileChange($event)"
                        accept="application/pdf"
                      />
                      <label for="file" class="labelFile" [class.disabled]="userType !== 'EMPLOYEE'">{{
                        "common.choose-file" | translate
                      }}</label>
                      <label
                        class="pl-3"
                        *ngIf="file || (blobUrl && fileBack)"
                        >{{ file ? file.name : fileBack.name }}</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 pd">
                  <div class="form-group">
                    <mat-checkbox class="example-margin" formControlName="freeTaxes" *ngIf="!(this.editing && this.data.codePetition)">
                      <small>{{ "expenses.freeTaxes" | translate }}</small>
                    </mat-checkbox>
                    <div>
                      <input
                        formControlName="price"
                        placeholder="{{
                          'company.company-booked.booked-price' | translate
                        }}"
                        type="number"
                        id="price"
                        class="form-control"
                        [disabled]="data?.refund || data?.payment"
                        [ngClass]="{
                          'input-error': !form.controls.price.valid && isInvalid && !form.controls.price.disabled
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="userType === 'EMPLOYEE'">
                <div class="col-lg-6 col-md-6 col-xs-12">
                  <div class="form-group">
                    <input
                      formControlName="hotel"
                      placeholder="{{ 'hotel.custom.hotel-name' | translate }}"
                      [matAutocomplete]="hotel"
                      (input)="getHotels($event.target.value)"
                      type="text"
                      id="origin"
                      class="form-control"
                      [ngClass]="{
                        'input-error': !form.controls.hotel.valid && isInvalid
                      }"
                    />
                    <mat-autocomplete
                      #hotel="matAutocomplete"
                      panelWidth="20%"
                      (optionSelected)="cleanHotelsList()"
                    >
                      <mat-option
                        *ngFor="let option of hotelsList"
                        [value]="option"
                      >
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12">
                  <div class="form-group">
                    <input
                      formControlName="city"
                      placeholder="{{ 'hotel.custom.city' | translate }}"
                      type="text"
                      id="destination"
                      class="form-control"
                      matInput
                      [matAutocomplete]="city"
                      (input)="getCities($event.target.value)"
                      [ngClass]="{
                        'input-error': !form.controls.city.valid && isInvalid
                      }"
                    />
                    <mat-autocomplete
                      #city="matAutocomplete"
                      panelWidth="20%"
                      (optionSelected)="cleanCitiesList($event.option.value)"
                    >
                      <mat-option
                        *ngFor="let option of citiesList"
                        [value]="option"
                      >
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="userType === 'EMPLOYEE'">
                <div class="col-lg-6 col-md-6 col-xs-12">
                  <div class="form-group d-flex align-items-center">
                    <div class="input-group inputDateTime">
                      <input
                        class="form-control filter-dates-placeholder"
                        [disabled]="this.userType != 'EMPLOYEE'"
                        readonly
                        placeholder="{{
                          'search.search-homepage.date-entry' | translate
                        }}"
                        name="checkIn"
                        id="checkIn"
                        formControlName="checkIn"
                        ngbDatepicker
                        #dDeparture="ngbDatepicker"
                        (click)="dDeparture.toggle()"
                        [minDate]="minDateNgStruct"
                        [maxDate]="maxDateNgStruct"
                        [ngClass]="{
                          'input-error':
                            !form.controls.checkIn.valid && isInvalid
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12">
                  <div class="form-group d-flex align-items-center">
                    <div class="input-group inputDateTime">
                      <input
                        mat-button
                        [disabled]="f.checkIn.hasError('required') || this.userType != 'EMPLOYEE'"
                        [ngClass]="{ dates: !f.checkIn.hasError('required') }"
                        class="form-control filter-dates-placeholder"
                        placeholder="{{
                          'search.search-homepage.date-departure' | translate
                        }}"
                        name="checkOut"
                        id="checkOut"
                        formControlName="checkOut"
                        (click)="dReturn.toggle()"
                        ngbDatepicker
                        #dReturn="ngbDatepicker"
                        [minDate]="getOutMinDateManual()"
                        [maxDate]="maxDateNgStruct"
                        [ngClass]="{
                          'input-error':
                            !form.controls.checkOut.valid && isInvalid
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-6 col-md-6 col-xs-12">
                  <div class="form-group">
                    <input
                      formControlName="customCode"
                      placeholder="{{ 'common.custom-code' | translate }}"
                      type="text"
                      id="customCode"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12">
                  <div class="form-group">
                    <input
                      formControlName="costCenter"
                      placeholder="{{ 'common.cost-center' | translate }}"
                      type="text"
                      id="costCenter"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="userType === 'EMPLOYEE'">
                <div class="col-12">
                  <div class="form-group">
                    <div class="form-group">
                      <textarea
                        formControlName="comments"
                        id="comments"
                        class="form-control"
                        placeholder="{{
                          'train.form-train.annotations-request' | translate
                        }}"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="w-100 d-flex justify-content-end">
                    <button
                      type="submit"
                      class="custom-btn mt-2 mb-0"
                      ngbAutofocus
                      mat-button
                      [ngStyle]="{
                        cursor:
                          !form.valid || loading ? 'not-allowed' : 'pointer'
                      }"
                      [ngClass]="{
                        'disabled-button': !form.valid || loading
                      }"
                    >
                      {{ "common.send" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
