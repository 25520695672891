import { Injectable } from "@angular/core";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { SearchService } from "@services";
import * as moment from "moment";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromSearchActions from "../actions/search.actions";

@Injectable()
export class SearchEffects {
  @Effect()
  loadHotels$ = this.actions$.pipe(
    ofType(fromSearchActions.HOTEL_LIST),
    switchMap((action: fromSearchActions.HotelListAction) => {
      // tslint:disable-next-line: max-line-length
      const checkInAux = moment(
        this.ngbDateParser.format(action.searchConfig.checkIn),
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      const checkOutAux = moment(
        this.ngbDateParser.format(action.searchConfig.checkOut),
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      const params = {
        latitude: action.searchConfig.latitude,
        longitude: action.searchConfig.longitude,
        checkIn: checkInAux,
        checkOut: checkOutAux,
        occupancies: action.searchConfig.occupancies,
        order: action.searchConfig.order,
        userUuid: action.searchConfig.userUuid,
      };
      const param: any = action.searchConfig
      if(param.facilities && param.facilities.length > 0) {
        params['facilities'] = param.facilities
      }
      return this.searchService.getListHotelsByCoordinate(params).pipe(
        map((res) => {
          localStorage.removeItem("nextMinElements");
          localStorage.setItem("nextMinElements", res.nextMinElements);
          return new fromSearchActions.HotelListSuccessAction(res.hotels);
        }),
        catchError((err) => {
          return of(new fromSearchActions.HotelListFailureAction(err.error));
        })
      );
    })
  );
  @Effect()
  loadFacilities$ = this.actions$.pipe(
    ofType(fromSearchActions.GET_FACILITIES),
    switchMap(() => {
      return this.searchService.getFacilities().pipe(
        map((res) => {
          return new fromSearchActions.FacilitySuccessAction(res);
        }),
        catchError((err) => {
          return of(new fromSearchActions.FacilityFailureAction(err.error));
        })
      );
    })
  );
  @Effect()
  loadHotelsApplyFilters$ = this.actions$.pipe(
    ofType(fromSearchActions.APPLY_FILTERS),
    switchMap((action: fromSearchActions.ApplyFiltersAction) => {
      // tslint:disable-next-line: max-line-length
      const checkInAux = moment(
        this.ngbDateParser.format(action.applyFilterConfig.checkIn),
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      const checkOutAux = moment(
        this.ngbDateParser.format(action.applyFilterConfig.checkOut),
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      const params = {
        latitude: action.applyFilterConfig.latitude,
        longitude: action.applyFilterConfig.longitude,
        checkIn: checkInAux,
        checkOut: checkOutAux,
        facilities: action.applyFilterConfig.facilities,
        occupancies: action.applyFilterConfig.occupancies,
        order: action.applyFilterConfig.order,
        userUuid: action.applyFilterConfig.userUuid,
      };
      
      return this.searchService
        .getListHotelsByCoordinateAndFilters(params)
        .pipe(
          map((res) => {
            return new fromSearchActions.HotelListSuccessAction(res.hotels);
          }),
          catchError((err) => {
            return of(new fromSearchActions.HotelListFailureAction(err.error));
          })
        );
    })
  );
  constructor(
    private actions$: Actions,
    private searchService: SearchService,
    private ngbDateParser: NgbDateParserFormatter
  ) {}
}
