import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { TokenService } from "./token.service";
@Injectable({
  providedIn: "root",
})
export class MediaService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getMedia(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get(`${environment.baseUrlApi}/media/image/${uuid}`, {
      headers: reqHeaders,
      responseType: "blob",
    });
  }
}
