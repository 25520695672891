import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanLoad, Route, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "@core/services";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.reducer";
import { AuthState } from "app/store/reducers/auth.reducer";
import { Observable } from "rxjs";
import { map, skipWhile, take } from "rxjs/operators";

@Injectable()
export class AuthRestrictedCompanyGuard implements CanLoad {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService
  ) {}
  canLoad(route: Route): Observable<boolean> {
    return this.store.select("auth").pipe(
      skipWhile((authState: AuthState) => authState.skipping),
      take(1),
      map((authState: AuthState) => {
        // const user: any = authState.user;
        if (
          !authState.isAuthenticated ||
          (authState.user.type?.toUpperCase() !== "HUB" &&
            authState.user.type?.toUpperCase() !== "EMPLOYEE" &&
            authState.user.type?.toUpperCase() !== "AGENCY" &&
            authState.user.type?.toUpperCase() !== "COMPANY")
        ) {
          this.authService.userCompanyPlan = null;
          this.router.navigate(["/login"]);
        }
        return authState.isAuthenticated;
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      return this.store.select("auth").pipe(
        skipWhile((authState: AuthState) => authState.skipping),
        take(1),
        map((authState: AuthState) => {
          // const user: any = authState.user;
          if (
            !authState.isAuthenticated ||
            (authState.user.type?.toUpperCase() !== "HUB" &&
              authState.user.type?.toUpperCase() !== "EMPLOYEE" &&
              authState.user.type?.toUpperCase() !== "AGENCY" &&
              authState.user.type?.toUpperCase() !== "COMPANY")
          ) {
            this.authService.userCompanyPlan = null;
            this.router.navigate(["/login"]);
          }
          return authState.isAuthenticated;
        })
      );
  }
}
