<div *ngIf="loading" style="display: block; margin: auto; z-index: 999999999">
  <app-loading [userType]="'employee'"></app-loading>
</div>
<div class="container col-12" *ngIf="authService.userCompanyPlan || authService.isAdmin()">
  <div class="row col-12 header-logos">
    <img src="{{ image ? image : 'assets/img/gallery/image_not_available.png' }}" class="company-logo" alt="Company logo" />
    <div>
      <img src="{{ agencyImage }}" *ngIf="agencyShow" alt="Agency logo" />
      <p *ngIf="authService?.userCompanyPlan?.permissions?.assistancePhone">
        {{ "agency.agencyPhone" | translate }}
        <span>
          {{ authService?.userCompanyPlan?.permissions?.assistancePhone }}
        </span>
      </p>
    </div>
  </div>
  <div class="row actions actions--top justify-content-end" style="margin-bottom: 1rem">
    <div class="buttons">
      <div (click)="goToProjects()" type="button" class="button custom-btn custom-btn--black">
        {{ "common.projects" | translate }}
      </div>
      <div (click)="goToBookings()" type="button" class="button custom-btn custom-btn--black">
        {{ "common.bookeds" | translate }}
      </div>
      <div ngbDropdown class="button custom-btn" placement="bottom-right">
        <a ngbDropdownToggle type="button">{{ "common.add" | translate }}
          <img class="dropdown-arrow" src="assets/img/svg/arrow-down-white.svg" /></a>
        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic1">
          <p (click)="onNewGroup()">{{ "common.department" | translate }}</p>
          <div ngbDropdown placement="right">
            <p class="line-arrow" ngbDropdownToggle>
              {{ "common.employee" | translate }}
              <img class="dropdown-arrow" src="assets/img/svg/arrow-right.svg" style="width: 8px !important" />
            </p>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic2">
              <p (click)="onExternalUser()">
                {{ "navbar.send-email-user" | translate }}
              </p>
              <p (click)="onNewUser()">
                {{ "user.form.create-user" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div (click)="editCompany()" type="button" class="button custom-btn custom-btn--yellow">
        {{ "company.company-detail.edit-Company" | translate }}
      </div>
      <div ngbDropdown class="button custom-btn custom-btn--black" placement="bottom-right">
        <a ngbDropdownToggle type="button" [matBadgeHidden]="
            !totalRequests &&
            !totalAuthorizations &&
            !(totalAuthorizations + totalRequests > 0)
          " [matBadge]="totalRequests + totalAuthorizations" matBadgePosition="after" matBadgePosition="above"
          matBadgeColor="#F69977" matBadgeSize="medium">{{ "common.see" | translate }}
          <img class="dropdown-arrow" src="assets/img/svg/arrow-down-white.svg" /></a>
        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic1">
          <p (click)="
              openGraphModal(company.name, 'company', 'all', company.uuid)
            ">
            {{ "common.graphics" | translate }}
          </p>
          <p (click)="seeReports()">
            {{ "company.company-detail.see-booked" | translate }}
          </p>
          <p (click)="goToRequests()">
            {{ "company.company-detail.request" | translate }}
            <span *ngIf="totalRequests && totalRequests > 0" class="notification">{{ totalRequests }}</span>
          </p>
          <p (click)="seeAuthorizations()">
            {{ "common.authorizations" | translate }}
            <span *ngIf="totalAuthorizations && totalAuthorizations > 0"
              class="notification">{{ totalAuthorizations }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row actions buttons actions--top">
    <input class="form-control search-input" *ngIf="canLoadFilters" (input)="filter = $event.target.value"
      placeholder="{{ 'search.filter' | translate }}" placeholder="  {{ 'common.search' | translate }}..." />
    <div ngbDropdown class="button custom-btn custom-btn--black" placement="bottom-right">
      <a ngbDropdownToggle type="button">
        <span *ngIf="yearSelected.length === 0">
          {{ "company.company-detail.year" | translate }}</span>
        <span *ngIf="yearSelected.length > 0">{{ yearSelected }}</span>
        <img class="dropdown-arrow" src="assets/img/svg/arrow-down-white.svg" /></a>
      <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic1" *ngIf="fiscalYears.length > 0">
        <p (click)="
            loadGroups(company.uuid)
          ">
          {{ "common.all" | translate }}
        </p>
        <p *ngFor="let year of fiscalYears" (click)="loadGroups(company.uuid, year)">
          {{ year }}
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="!loading && groups && groups.length > 0" class="col-12 departments">

    <div class="col-12 department" *ngFor="
        let group of groups
          | filterTRM: groupsCopy:filter:allLoaded || hasNoImage;
        trackBy: utilsService.getUuidTracking;
        let i = index
      ">
      <div class="row col-12 header-department actions">
        <p class="col-6 group-name">{{ group.name }}</p>
        <div class="col-6 buttons buttons--department">
          <div ngbDropdown class="button custom-btn group-action" placement="bottom-right">
            <a ngbDropdownToggle type="button">{{ "common.actions" | translate }}
              <img class="dropdown-arrow" src="assets/img/svg/arrow-down-white.svg" /></a>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic1">
              <p *ngIf="
                  (authService.userCompanyPlan &&
                    authService.userCompanyPlan.permissions
                      .canCreateTravelPolicies &&
                    userType !== 'EMPLOYEE') ||
                  authService.isAdmin()
                " (click)="onEditGroup(group)">
                {{ "common.edit-department" | translate }}
              </p>
              <p (click)="openGraphModal(group.name, 'group', 'all', group.uuid)">
                {{ "company.company-detail.see-graphics" | translate }}
              </p>
              <p *ngIf="group.name !== 'Default' || group.name !== 'No asignado'" (click)="onDeleteGroup(group)">
                {{ "company.group.group-form.delete" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row col-12 department-content empty" *ngIf="group.users.length <= 0">
        <img src="assets/img/svg/empty-data.svg" alt="Empty department" />
        <p>{{ "company.company-detail.no-user-group" | translate }}</p>
      </div>
      <div class="col-12 department-content" *ngIf="group.users.length > 0">
        <ng-container *ngIf="getGroupAdmin(group).length > 0">
          <p>{{ "company.group.group-admin" | translate }}</p>
          <div class="col-12 users">
            <div class="users-header row col-12">
              <div class="profile-picture"></div>
              <div class="user-id">
                {{ "company.company-detail.id" | translate }}
              </div>
              <div class="user-name">{{ "common.name" | translate }}</div>
              <div class="user-status">{{ "common.status" | translate }}</div>
              <div class="user-bills">
                {{ "common.accumulated-expense" | translate }}
              </div>
              <div class="user-projects">
                {{ "common.projects" | translate }}
              </div>
              <div class="user-graphics"></div>
              <div class="user-actions"></div>
            </div>
            <div class="users-container row col-12" *ngFor="let adminGroup of getGroupAdmin(group)">
              <div class="profile-picture">
                <img class="admin-border" src="{{
                    adminGroup.accessType?.toLowerCase() === 'company'
                      ? 'assets/img/svg/company-admin-border.svg'
                      : (user.accessType?.toLowerCase() === 'employee'
                        ? 'assets/img/svg/employee-border.svg'
                        : 'assets/img/svg/group-admin-border.svg')
                  }}" />
                <ng-container *ngIf="adminGroup.image; else elseTemplate">
                  <div class="user-image">
                    <img src="{{ adminGroup.image }}" />
                  </div>
                </ng-container>
                <ng-template #elseTemplate>
                  <div class="user-image">
                    <div class="profile-initials text-white">
                      {{ adminGroup.name[0] }}{{ adminGroup.lastname[0] }}
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="user-id">
                {{
                  adminGroup.employeeNumber ? adminGroup.employeeNumber : "N/A"
                }}
              </div>
              <div class="user-name">
                {{ adminGroup.name }} {{ adminGroup.lastname }}
              </div>
              <div class="user-status">
                <mat-slide-toggle [disabled]="!isAdmin && !isCompany"
                  (click)="isAdmin || isCompany ? changeActive(adminGroup) : ''" [checked]="adminGroup.active">
                </mat-slide-toggle>
              </div>
              <div class="user-bills">
                {{ adminGroup.totalExpenses | price }}
              </div>
              <div class="user-projects">
                {{ adminGroup.numProjects | number }}
              </div>
              <div ngbDropdown placement="left" class="user-graphics cursor-pointer">
                <div ngbDropdownToggle class="d-flex">
                  <img src="assets/img/svg/add-booking-circle.svg" />
                  <p class="create-booking">{{ "common.create-booking" | translate }}</p>
                </div>
                <div ngbDropdownMenu role="menu" class="dropdown-menu" aria-labelledby="dropdownBasic2">
                  <p *ngIf="
                      userType == 'EMPLOYEE' ||
                      (userType !== 'EMPLOYEE' &&
                        authService.userCompanyPlan &&
                        authService.userCompanyPlan.permissions
                          .canRequestAutomatizedBookings)
                    " (click)="createUserBooking(adminGroup.uuid)">
                    {{ "common.hotel" | translate }}
                  </p>
                  <p *ngIf="userType === 'EMPLOYEE'" (click)="onNewCustomBooking(adminGroup.uuid)">
                    {{ "common.custom-hotel" | translate }}
                  </p>
                  <p *ngIf="
                      isAmadeusEnabled &&
                      ((userType !== 'EMPLOYEE' &&
                        authService.userCompanyPlan &&
                        authService.userCompanyPlan.permissions
                          .canRequestAutomatizedBookings) ||
                        authService.isAdmin())
                    " (click)="onNewFlightTrain('flight', adminGroup.uuid, false)">
                    {{ "company.company-detail.flight" | translate }}
                  </p>
                  <p *ngIf="userType === 'EMPLOYEE'" (click)="adminFormFlightTrain('flight', adminGroup.uuid)">
                    {{ "company.company-detail.specialFlight" | translate }}
                  </p>
                  <p (click)="onNewFlightTrain('train', adminGroup.uuid)" *ngIf="
                      (userType !== 'EMPLOYEE' &&
                        authService.userCompanyPlan &&
                        authService.userCompanyPlan.permissions
                          .canRequestAutomatizedBookings) ||
                      authService.isAdmin()
                    ">
                    {{ "company.company-detail.train" | translate }}
                  </p>
                  <p *ngIf="userType === 'EMPLOYEE'" (click)="adminFormFlightTrain('train', adminGroup.uuid)">
                    {{ "company.company-detail.custom-train" | translate }}
                  </p>
                  <p *ngIf="
                      userType === 'EMPLOYEE' &&
                      authService.userCompanyPlan &&
                      authService.userCompanyPlan.permissions
                        .canRequestCustomBookings
                    " (click)="adminFormCreateCar(adminGroup.uuid)">
                    {{ "company.company-detail.custom-car" | translate }}
                  </p>
                  <!-- <p
                    *ngIf="
                      userType === 'EMPLOYEE' &&
                      authService.userCompanyPlan &&
                      canRequestAirbnb(group)
                    "
                    (click)="adminFormCreateAirbnb(user.uuid, group.filters)"
                  >
                    {{ "company.company-detail.custom-airbnb" | translate }}
                  </p> -->
                  <p *ngIf="userType === 'EMPLOYEE'" (click)="onNewExpense(group.uuid, adminGroup.uuid)">
                    {{ "expenses.title" | translate }}
                  </p>
                  <p *ngIf="userType !== 'EMPLOYEE'" (click)="onNewCustomService(group.uuid, adminGroup.uuid)">
                    {{ "expenses.title" | translate }}
                  </p>
                </div>
              </div>

              <div class="user-actions" ngbDropdown>
                <img class="pointer" src="assets/img/svg/black-kebab.svg" ngbDropdownToggle />
                <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
                  <div class="user-graphics-btn" (click)="seeUserBooked(adminGroup.uuid)">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/boarding-pass.svg" />
                    </div>
                    <p>
                      {{ "common.see-booked" | translate }}
                    </p>
                  </div>
                  <div class="user-graphics-btn" (click)="
                    openGraphModal(
                      adminGroup.name + ' ' + adminGroup.lastname,
                      'user',
                      'all',
                      adminGroup.uuid
                    )
                  ">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/bar-chart.svg" />
                    </div>
                    <p class="user-graphics-btn pointer">
                      {{
                        "company.company-detail.see-graphics" | translate
                      }}
                    </p>
                  </div>
                  <div class="user-graphics-btn" (click)="openUserStorage(adminGroup)">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/cloud-black.svg" />
                    </div>
                    <p>
                      {{ "common.see-documents" | translate }}
                    </p>
                  </div>
                  <div class="user-graphics-btn" (click)="onEditUser(adminGroup, group)">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/pen-black.svg" />
                    </div>
                    <p>
                      {{ "user.form.edit-user" | translate }}
                    </p>
                  </div>
                  <div class="user-graphics-btn" (click)="onDeleteUser(adminGroup)">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/trash-black.svg" />
                    </div>
                    <p>
                      {{
                        "project.my-projects.delete-user.delete-user" | translate
                      }}
                    </p>
                  </div>
                  <div class="user-graphics-btn" (click)="onSendActivateEmail(adminGroup)"
                    *ngIf="!adminGroup.active && !adminGroup.deleted && !adminGroup.hasPassword">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/bell.svg"/>
                    </div>
                    <p>{{ "company.company-detail.user-external.email-pass-resend" | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="group.users.length > 0 && areMoreUserOnGroup(group)">
          <p>
            {{ "company.group.team" | translate }}
          </p>
          <div class="col-12 users">
            <div class="users-header row col-12">
              <div class="profile-picture"></div>
              <div class="user-id">
                {{ "company.company-detail.id" | translate }}
              </div>
              <div class="user-name">{{ "common.name" | translate }}</div>
              <div class="user-status">{{ "common.status" | translate }}</div>
              <div class="user-bills">
                {{ "common.accumulated-expense" | translate }}
              </div>
              <div class="user-projects">
                {{ "common.projects" | translate }}
              </div>
              <div class="user-graphics"></div>
              <div class="user-actions"></div>
            </div>
            <ng-container *ngFor="let user of group.users; let index = i">
              <div class="users-container row col-12" *ngIf="!getGroupAdmin(group).includes(user)">
                <div class="profile-picture">
                  <img *ngIf="user && user.accessType && user.accessType?.toLowerCase() === 'company'" class="admin-border"
                    src="assets/img/svg/company-admin-border.svg" />
                  <img *ngIf="user && user.accessType && user.accessType?.toLowerCase() === 'employee'" class="admin-border"
                    src="assets/img/svg/employee-border.svg" />
                  <ng-container *ngIf="user.image; else elseTemplate">
                    <div class="user-image">
                      <img src="{{ user.image }}" />
                    </div>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <div class="user-image">
                      <div class="profile-initials text-white">
                        {{ user.name[0] }}{{ user.lastname[0] }}
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="user-id">
                  {{ user.employeeNumber ? user.employeeNumber : "N/A" }}
                </div>
                <div class="user-name">{{ user.name }} {{ user.lastname }}</div>
                <div class="user-status">
                  <mat-slide-toggle [disabled]="!isAdmin && !isCompany"
                    (click)="isAdmin || isCompany ? changeActive(user) : ''" [checked]="user.active"></mat-slide-toggle>
                </div>
                <div class="user-bills">
                  {{ user.totalExpenses | price }}
                </div>
                <div class="user-projects">
                  {{ user.numProjects | number }}
                </div>
                <div ngbDropdown placement="left" class="user-graphics cursor-pointer">
                  <div ngbDropdownToggle class="d-flex">
                    <img src="assets/img/svg/add-booking-circle.svg" />
                    <p class="create-booking">{{ "common.create-booking" | translate }}</p>
                  </div>
                  <div ngbDropdownMenu role="menu" class="dropdown-menu" aria-labelledby="dropdownBasic2">
                    <p *ngIf="
                        userType === 'EMPLOYEE' ||
                        (userType !== 'EMPLOYEE' &&
                          authService.userCompanyPlan &&
                          authService.userCompanyPlan.permissions
                            .canRequestAutomatizedBookings)
                      " (click)="createUserBooking(user.uuid)">
                      {{ "common.hotel" | translate }}
                    </p>
                    <p *ngIf="userType === 'EMPLOYEE'" (click)="onNewCustomBooking(user.uuid)">
                      {{ "common.custom-hotel" | translate }}
                    </p>
                    <p *ngIf="
                        isAmadeusEnabled &&
                        ((userType !== 'EMPLOYEE' &&
                          authService.userCompanyPlan &&
                          authService.userCompanyPlan.permissions
                            .canRequestAutomatizedBookings) ||
                          isSuperAdmin)
                      " (click)="onNewFlightTrain('flight', user.uuid, false)">
                      {{ "company.company-detail.flight" | translate }}
                    </p>
                    <p *ngIf="userType === 'EMPLOYEE'" (click)="adminFormFlightTrain('flight', user.uuid)">
                      {{ "company.company-detail.specialFlight" | translate }}
                    </p>
                    <p (click)="onNewFlightTrain('train', user.uuid)" *ngIf="
                        (userType !== 'EMPLOYEE' &&
                          authService.userCompanyPlan &&
                          authService.userCompanyPlan.permissions
                            .canRequestAutomatizedBookings) ||
                        isSuperAdmin
                      ">
                      {{ "company.company-detail.train" | translate }}
                    </p>
                    <p *ngIf="userType === 'EMPLOYEE'" (click)="adminFormFlightTrain('train', user.uuid)">
                      {{ "company.company-detail.custom-train" | translate }}
                    </p>
                    <p *ngIf="
                        userType === 'EMPLOYEE' &&
                        authService.userCompanyPlan &&
                        authService.userCompanyPlan.permissions
                          .canRequestCustomBookings
                      " (click)="adminFormCreateCar(user.uuid)">
                      {{ "company.company-detail.custom-car" | translate }}
                    </p>
                    <p *ngIf="userType === 'EMPLOYEE'" (click)="onNewExpense(group.uuid, user.uuid)">
                      {{ "expenses.title" | translate }}
                    </p>
                    <p *ngIf="userType !== 'EMPLOYEE'" (click)="onNewCustomService(group.uuid, user.uuid)">
                      {{ "expenses.title" | translate }}
                    </p>
                  </div>
                </div>
                <div class="user-actions" ngbDropdown placement="bottom-right">
                  <img class="pointer" src="assets/img/svg/black-kebab.svg" ngbDropdownToggle />
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic3">
                    <div class="user-graphics-btn" (click)="seeUserBooked(user.uuid)">
                      <div class="icon-bcg icon-bcg--small">
                        <img src="assets/img/svg/boarding-pass.svg" />
                      </div>
                      <p >
                        {{ "common.see-booked" | translate }}
                      </p>
                    </div>
                    <div class="user-graphics-btn pointer" (click)="
                      openGraphModal(
                        user.name + ' ' + user.lastname,
                        'user',
                        'all',
                        user.uuid
                      )
                    ">
                      <div class="icon-bcg icon-bcg--small">
                        <img src="assets/img/svg/bar-chart.svg" />
                      </div>
                      <p>
                      {{ "company.company-detail.see-graphics" | translate }}
                      </p>
                    </div>
                    <div class="user-graphics-btn" (click)="openUserStorage(user)">
                      <div class="icon-bcg icon-bcg--small">
                        <img src="assets/img/svg/cloud-black.svg" />
                      </div>
                      <p>
                        {{ "common.see-documents" | translate }}
                      </p>
                    </div>
                    <div class="user-graphics-btn" (click)="onEditUser(user, group)">
                      <div class="icon-bcg icon-bcg--small">
                        <img src="assets/img/svg/pen-black.svg" />
                      </div>
                      <p>
                        {{ "user.form.edit-user" | translate }}
                      </p>
                    </div>
                    <div class="user-graphics-btn" (click)="onDeleteUser(user)">
                      <div class="icon-bcg icon-bcg--small">
                        <img src="assets/img/svg/trash-black.svg" />
                      </div>
                      <p>
                        {{
                          "project.my-projects.delete-user.delete-user"
                            | translate
                        }}
                      </p>
                    </div>
                    <div class="user-graphics-btn" (click)="onSendActivateEmail(user)"
                    *ngIf="!user.active && !user.deleted && !user.hasPassword">
                      <div class="icon-bcg icon-bcg--small">
                        <img src="assets/img/svg/bell.svg"/>
                      </div>
                      <p>{{ "company.company-detail.user-external.email-pass-resend" | translate }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-12 department" *ngIf="!loading && emailsGroupInvites[0]">
      <div class="row col-12 header-department actions">
        <p class="col-6 group-name" (click)="toggleContent()">
          <span style="padding-right: 0.6rem;">{{ "common.invitations-sent" | translate }}</span>
          <img _ngcontent-iib-c284="" src="assets/img/svg/arrow-down.svg" [style.transform]="showContent ? '' : 'rotate(180deg)'">
        </p>
      </div>

      <div class="col-12 invitation-content" [ngClass]="{'hidden-content': !showContent}">
        <ng-container>
          <div class="col-12 users">
            <div class="emails-header row col-12">
              <div>
                {{ "csv.email" | translate }}
              </div>
              <div>
                {{ "agency.agency-detail.group" | translate }}
              </div>
            </div>
          
            <div class="emails-container row col-12" *ngFor="let invitation of emailsGroupInvites; let index = i">
              <div>{{ invitation.email }}</div>
              <div class="user-name">{{ invitation.groupRestrictionName }}</div>
              <div class="actions" style="padding: 0;">
                <div class="buttons" style="justify-content: center;">
                  <div (click)="resendEmailRegister(invitation)" type="button" class="button edit">
                    {{ "common.invitation-resent" | translate }}
                  </div>
                </div>
              </div>
              <div style="padding: 0;">
                <div style="justify-content: center;">
                  <div (click)="onDeleteEmailInvitation(invitation)" type="button">
                    <i
                    title="{{ 'common.delete' | translate }}"
                    class="delete-icon btn-sm ml-1" style="font-size: 1.5rem; font-style: normal;"
                  ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

    </div>
  </div>
</div>
