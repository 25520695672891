import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgbDropdownModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { FlightService, StateService, TrainService } from "@services";
import { SharedModule } from "@shared/shared.module";
import { UserModule } from "app/modules/businessEntities/user/user.module";
import { CountdownModule } from "ngx-countdown";
import { CreateFlightTrainComponent } from "./create-flight-train/create-flight-train.component";
import { FlightFormComponent } from "./flight-form/flight-form.component";
import { FlightPendingComponent } from "./flight-pending/flight-pending.component";
import { PendingFlightModalComponent } from "./flight-pending/pending-flight-modal/pending-flight-modal.component";
import { FlightTrainRoutingModule } from "./flight-train-routing.module";
import { FlightsCardResultComponent } from "./flights-card-result/flights-card-result.component";
import { FormFlightTrainComponent } from "./form-flight-train/form-flight-train.component";
import { DialogPoliciesComponent } from "./select-flight/dialog-policies/dialog-policies.component";
import { FareServicesListComponent } from "./select-flight/flight-fares-list/components/fare-services-list/fare-services-list.component";
import { FlightFaresListComponent } from "./select-flight/flight-fares-list/flight-fares-list.component";
import { OrderByPipe } from "./select-flight/flight-fares-list/order-by.pipe";
import { FlightSeatsSelectionComponent } from "./select-flight/flight-seats-selection/flight-seats-selection.component";
import { FlightStepDetailComponent } from "./select-flight/flight-step-detail/flight-step-detail.component";
import { FlightStepTravelerformComponent } from "./select-flight/flight-step-travelerform/flight-step-travelerform.component";
import { OnlyDatePipe } from "./select-flight/only-date.pipe";
import { SelectFlightComponent } from "./select-flight/select-flight.component";
import { TravelerLlegoComponent } from "./traveler-llego/traveler-llego.component";
import { TravelersComponent } from "./travelers/travelers.component";

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    SharedModule,
    TranslateModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSelectModule,
    MatButtonToggleModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatStepperModule,
    MatCardModule,
    MatSelectCountryModule.forRoot("es"),
    FlightTrainRoutingModule,
    NgxSliderModule,
    MatNativeDateModule,
    MatTooltipModule,
    CountdownModule,
    UserModule,
    MatSlideToggleModule,
  ],
  exports: [
    MatExpansionModule,
    MatDatepickerModule,
    MatGridListModule,
    MatStepperModule,
    NgbDropdownModule,
  ],
  declarations: [
    FormFlightTrainComponent,
    CreateFlightTrainComponent,
    TravelersComponent,
    FlightsCardResultComponent,
    SelectFlightComponent,
    FlightPendingComponent,
    TravelerLlegoComponent,
    FlightFormComponent,
    FlightStepDetailComponent,
    OnlyDatePipe,
    OrderByPipe,
    FlightStepTravelerformComponent,
    DialogPoliciesComponent,
    FlightSeatsSelectionComponent,
    FlightFormComponent,
    FlightFaresListComponent,
    FareServicesListComponent,
    PendingFlightModalComponent,
  ],
  providers: [
    TrainService,
    FlightService,
    StateService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
})
export class FlightTrainModule {}
