import { Routes } from "@angular/router";
import { PlanResolveService } from "@core/guards/PlanResolveService";
import {
  AuthCompanyGuard,
  AuthEmployeeGuard,
  AuthenticatedGuard,
  AuthHubGuard,
  AuthUserGuard,
  CanActiveSearchCompanyGuard,
} from "@guards";

export const FullLayoutRoutes: Routes = [
  {
    path: "my-company",
    loadChildren: () =>
      import("./modules/businessEntities/company/company.module").then(
        (m) => m.CompanyModule
      ),
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "companies",
    loadChildren: () =>
      import("./modules/businessEntities/company/company.module").then(
        (m) => m.CompanyModule
      ),
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "agencies",
    loadChildren: () =>
      import("./modules/businessEntities/agency/agency.module").then(
        (m) => m.AgencyModule
      ),
    canLoad: [AuthHubGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "hubs",
    loadChildren: () =>
      import("./modules/businessEntities/hub/hub.module").then(
        (m) => m.HubModule
      ),
    canLoad: [AuthEmployeeGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "user",
    loadChildren: () =>
      import("./modules/businessEntities/user/user.module").then(
        (m) => m.UserModule
      ),
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "group",
    loadChildren: () =>
      import("./modules/components/group/group.module").then(
        (m) => m.GroupModule
      ),
    canLoad: [AuthCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "search/:id",
    loadChildren: () =>
      import("./modules/components/search/search.module").then(
        (m) => m.SearchModule
      ),
    canActivate: [CanActiveSearchCompanyGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "search",
    loadChildren: () =>
      import("./modules/components/search/search.module").then(
        (m) => m.SearchModule
      ),
    canLoad: [AuthUserGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "home",
    loadChildren: () =>
      import("./modules/components/home/home.module").then((m) => m.HomeModule),
    canLoad: [AuthUserGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "my-bookeds",
    loadChildren: () =>
      import("./modules/components/my-bookeds/user-my-bookeds.module").then(
        (m) => m.UserMyBookedsModule
      ),
    canLoad: [AuthUserGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "help",
    loadChildren: () =>
      import("./modules/components/help/help.module").then((m) => m.HelpModule),
    canLoad: [AuthenticatedGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "flight",
    loadChildren: () =>
      import("./modules/components/flight-train/flight-train.module").then(
        (m) => m.FlightTrainModule
      ),
    canLoad: [AuthenticatedGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "car",
    loadChildren: () =>
      import("./modules/components/cars/car.module").then((m) => m.CarModule),
    canLoad: [AuthenticatedGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "train",
    loadChildren: () =>
      import("./modules/components/trains/trains.module").then(
        (m) => m.TrainsModule
      ),
    canLoad: [AuthenticatedGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "stats",
    loadChildren: () =>
      import("./modules/components/stats/stats.module").then(
        (m) => m.StatsModule
      ),
    canLoad: [AuthEmployeeGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "statics",
    loadChildren: () =>
      import("./modules/components/statics/statics.module").then(
        (m) => m.StaticsModule
      ),
    canLoad: [AuthenticatedGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "custom-services",
    loadChildren: () =>
      import(
        "./modules/components/custom-services/custom-services.module"
      ).then((m) => m.CustomServicesModule),
    canLoad: [AuthenticatedGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "airbnb",
    loadChildren: () =>
      import("./modules/components/airbnb/airbnb.module").then(
        (m) => m.AirbnbModule
      ),
    canLoad: [AuthenticatedGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
  {
    path: "custom-services/:id",
    loadChildren: () =>
      import(
        "./modules/components/custom-services/custom-services.module"
      ).then((m) => m.CustomServicesModule),
    canLoad: [AuthenticatedGuard],
    resolve: {
      plans: PlanResolveService,
    },
  },
];
