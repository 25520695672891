import { Injectable } from "@angular/core";

@Injectable()
export class LanguageService {
  key = "language";
  constructor() {}

  getLanguage(): string {
    return localStorage.getItem(this.key);
  }


  saveLanguage(lang: string) {
    localStorage.setItem(this.key, lang);
    let newLocaleLang;
    switch (lang) {
      case "en":
      case "en-GB":
      case "en-US":
      default:
        newLocaleLang = "en-US";
        break;
      case "es":
      case "es-ES":
      case "es-MX":
        newLocaleLang = "es-ES";
        break;
    }
    localStorage.setItem("locale", newLocaleLang);
  }

  destroyLanguage() {
    localStorage.removeItem(this.key);
  }

  getLocale(): string {
    let locale = localStorage.getItem("locale");
    if (locale == null) {
      const auxLang =
        navigator.languages != null
          ? navigator.languages[0]
          : navigator.language;
      this.saveLanguage(auxLang);
      locale = localStorage.getItem("locale");
    }
    return locale;
  }

  getLangFromJS(): string {
    return navigator.languages == null
      ? navigator.language
      : navigator.languages[0];
  }
}
