import { PaginationConfig } from "@interfaces";
import { Action } from "@ngrx/store";
export const USER_PAGE = "[User] Page";

export class UserPageAction implements Action {
  readonly type = USER_PAGE;

  constructor(public paginationConfig: PaginationConfig) {}
}

export type UserActions = UserPageAction;
