<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ room ? room.name : "" }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  <div class="row background-gallery">
    <div id="hotelImages" class="carousel slide" data-ride="carousel" wrap="true">
      <ol class="carousel-indicators">
        <li *ngFor="let image of room.images; let i = index" data-target="#hotelImages" [attr.data-slide-to]="i" [class.active]="i === 0"></li>
      </ol>
      <div class="carousel-inner">
        <div *ngFor="let image of room.images; let i = index" class="carousel-item" [class.active]="i === 0">
          <img [src]="image" class="d-block w-100" alt="...">
        </div>
      </div>
      <a class="carousel-control-prev" href="#hotelImages" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#hotelImages" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>
