import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "noimage",
})
export class NoimagePipe implements PipeTransform {
  transform(imagen: any): any {
    return !imagen.path || imagen.path.length < 1
      ? "../../assets/img/gallery/no-image.jpg"
      : imagen.path;
  }
}
