import { MapsAPILoader } from '@ng-maps/core'
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'

@Component({
  selector: 'app-google-autocomplete',
  templateUrl: './google-autocomplete.component.html',
  styleUrls: ['./google-autocomplete.component.scss']
})
export class GoogleAutocompleteComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() adressType: string
  @Input() locationTxt: any
  @Input() readOnly = false
  @Input() openModal: any
  @Output() setAddress: EventEmitter<any> = new EventEmitter()
  @Output() unFocusInput: EventEmitter<any> = new EventEmitter()
  @ViewChild('addresstext', { static: true }) addresstext: any

  constructor (private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}

  ngOnInit () {
    if (this.locationTxt) {
      this.addresstext.nativeElement.value = this.locationTxt
    }
    // Cargo Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.addresstext.nativeElement,
        {
          // componentRestrictions: { country: 'ES' }, --> Esta es una restricción por pais
          // types: ['geocode'] // 'establishment' / 'address' / 'geocode'
        }
      )
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // Obtengo el resultado (place)
          const place: google.maps.places.PlaceResult = autocomplete.getPlace()

          // Verifico que tengo resultado
          if (place.geometry === undefined || place.geometry === null) {
            return
          }

          // Mando el evento con el lugar
          this.setAddress.emit(place)
        })
      })
    })
  }

  ngAfterViewInit (): void {
    const elements = document.getElementsByClassName('cdk-global-scrollblock')
    if (elements.length > 0) {
      const firstElement = elements[0] as HTMLElement
      firstElement.style.top = '0'
    }
  }

  disableEnter (event) {
    if (event.code === 'Enter') {
      event.preventDefault()
      // this.unFocusInput.emit(event);
    }
  }

  ngOnDestroy (): void {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }
}
