<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "project.user" | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  <div class="row">
    <h5>{{ "project.select-user" | translate }}</h5>
    <div class="col-6 offset-3" *ngIf="users.length > 0">
      <select
        [value]="selected"
        (change)="selectUser($event.target.value)"
        style="width: 100%; margin: 5px"
      >
        <option [value]="none">{{ "project.none" | translate }}</option>
        <option *ngFor="let user of users" [value]="user.uuid">
          {{ user.name }} {{ user.lastname }} ( {{ user.email }} )
        </option>
      </select>
    </div>
    <div class="col-12 offset-4">
      <button
        mat-raised-button
        color="primary"
        class="m-2"
        (click)="onSubmit()"
      >
        {{ "common.confirm" | translate }}
      </button>
    </div>
  </div>
</div>
