import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { TokenService } from "./token.service";

@Injectable({
  providedIn: "root",
})
export class AirbnbService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  sendCustomAirbnbRequest(body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/hotels/request-airbnb`,
      body,
      { headers: reqHeaders }
    );
  }

  createCustomAirbnb(body: any, requestUuid?: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const url = requestUuid
      ? `${environment.baseUrlApi}/bookeds/admin/custom?requestUuid=${requestUuid}`
      : `${environment.baseUrlApi}/bookeds/admin/custom`;
    return this.http.post<any>(url, body, { headers: reqHeaders });
  }

  getUserAirbnbs(userUuid: string): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get<any>(`${environment.baseUrlApi}/airbnb/${userUuid}`, {
      headers: reqHeaders,
    });
  }

  getCompanyAirbnbs(companyUuid: string): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get<any>(
      `${environment.baseUrlApi}/airbnb/company/${companyUuid}`,
      { headers: reqHeaders }
    );
  }

  getAirbnbPdf(bonoFileUrl: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get(`${environment.baseUrlApi}${bonoFileUrl}`, {
      headers: reqHeaders,
      responseType: "blob",
    });
  }

  editAirbnb(airbnb: any, airbnbUuid: string): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/airbnb/custom/edit/${airbnbUuid}`,
      airbnb,
      { headers: reqHeaders }
    );
  }

  cancelAirbnb(airbnbUuid: string): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.delete<any>(
      `${environment.baseUrlApi}/airbnb/${airbnbUuid}`,
      {
        headers: reqHeaders,
      }
    );
  }
}
