<!--User Profile Starts-->
<!--Basic User Details-->
<!-- <section id="user-profile">
    <app-tabs [currentPage]="currentPage" (showPage)="showPage($event)" [company]="company"></app-tabs>
</section> -->
<!--Basic User Details-->

<!--Información Company-->
<!-- <section id="informacion" *ngIf="currentPage === 'info'">
    <app-info [businessEntity]="company"></app-info>
</section> -->
<!--Informacion Company-->

<!--Reservas-->
<!-- <section id="reservas" *ngIf="currentPage === 'bookeds'">
    <app-user-my-bookeds></app-user-my-bookeds>
</section> -->
<!--Reservas-->

<!--Facturación-->
<!-- <div>
    <section id="transacciones" *ngIf="currentPage === 'transaction'">
        <app-transaction [uuidBusinessEntity]="uuidCompany" [businessEntityType]="userType"></app-transaction>
    </section>
</div> -->
<!--Facturación-->

<!-- <section id="bills" *ngIf="currentPage === 'bills'">
    <app-bills [uuidBusinessEntity]="uuidCompany" [businessEntityType]="userType"></app-bills>
</section> -->

<section id="global-container">
  <div id="global-title">
    <h3 class="heading-5">{{ "company.company-detail.see-booked" | translate }}</h3>
  </div>
  <div id="content-container">
    <div id="goBackButtonContainer">
      <button mat-button mat-flat-button (click)="goBack()">
        <img src="assets/img/svg/arrow-back.svg" alt="" width="24px" />
      </button>
    </div>
    <div id="content-wrapper">
      <div id="content-title">
        <div id="logo-container">
          <img src="{{ image ? image : 'assets/img/gallery/image_not_available.png' }}" class="company-logo" alt="Company logo" />
        </div>
        <div *ngIf="agencyShow" class="agency-block">
          <img src="{{ agencyImage }}" alt="Agency logo" style="margin-left: auto;"/>
          <p style="font-weight: bold; margin-left: auto;" *ngIf="authService?.userCompanyPlan?.permissions?.assistancePhone">
            {{ "agency.agencyPhone" | translate }}
            <span style="font-weight: normal">{{
              authService?.userCompanyPlan?.permissions?.assistancePhone
            }}</span>
          </p>
        </div>
      </div>
      <div id="content-body">
        <div class="nav-container">
          <div class="nav">
            <ul ngbNav #nav="ngbNav" [(activeId)]="navActive" class="nav-tabs">
              <!-- <li [ngbNavItem]="1" [ngClass]="{'disabled': isLoading}">
                <a ngbNavLink>
                  {{ "common.projects" | translate }}
                </a>
                <ng-template ngbNavContent>
                  <app-travel-container
                    [pagination]="travelPagination"
                    [travels]="travels"
                    [isLoading]="isLoading"
                    [form]="formTravel"
                    [companyUuid]="uuidCompany"
                    [isEmployee]="isAdmin"
                    (loadTravels)="loadTravels()"
                    [isLoadingTravel]="isLoadingTravel"
                    [isCompanyBookeds]="isCompanyBookeds"
                  ></app-travel-container>
                </ng-template>
              </li>
              <li [ngbNavItem]="2" [ngClass]="{'disabled': isLoading}">
                <a ngbNavLink>
                  {{ "common.bookeds" | translate }}
                </a>
                <ng-template ngbNavContent>
                  <app-user-my-bookeds></app-user-my-bookeds>
                </ng-template>
              </li> -->
              <li [ngbNavItem]="1" [ngClass]="{'disabled': isLoading}">
                <a ngbNavLink>
                  {{ "common.transactions" | translate }}
                </a>
                <ng-template ngbNavContent>
                  <app-transaction
                    [uuidBusinessEntity]="uuidCompany"
                    [businessEntityType]="userType"
                    [loading]="isLoading"
                    [transactions]="transactions"
                  >
                  </app-transaction>
                </ng-template>
              </li>
              <li [ngbNavItem]="2" [ngClass]="{'disabled': isLoading}">
                <a ngbNavLink>
                  {{ "common.bills" | translate }}
                </a>
                <ng-template ngbNavContent>
                  <app-bills
                    [uuidBusinessEntity]="uuidCompany"
                    [businessEntityType]="userType"
                  >
                  </app-bills>
                </ng-template>
              </li>
            </ul>
            <ul
              ngbNav
              #nav1="ngbNav"
              [ngClass]="{ single: navActive !== 1, double: navActive === 1 }"
              [(activeId)]="navActive"
              class="nav-tabs"
            >
              <li class="button custom-btn custom-btn--black" *ngIf="navActive === 1" [ngClass]="{'disabled': isLoading}">
                <a
                  type="button"
                  style="justify-content: center"
                  (click)="exportTransactions()"
                  >{{ "common.download" | translate }}</a
                >
              </li>
              <li
                class="button custom-btn custom-btn--black"
                *ngIf="navActive === 2 && !isCompany && userType === 'EMPLOYEE'"
                [ngClass]="{'disabled': isLoading}"
              >
                <a
                  type="button"
                  style="justify-content: center"
                  (click)="onUploadBill()"
                  >{{ "company.bills.upload-bill" | translate }}</a
                >
              </li>
            </ul>
          </div>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</section>
