import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Company } from "@models";
import { map } from "rxjs/operators";
import { TokenService } from "./token.service";

@Injectable()
export class CompanyService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
  ) {}

  getCompany(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/companies/${uuid}`, {
        headers: reqHeaders,
      })
      .pipe(map((res: Company) => res));
  }

  getCompanies(params?: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    let queryParams;
    params ? (queryParams = params) : (queryParams = {});
    return this.http
      .get<any>(`${environment.baseUrlApi}/companies`, {
        headers: reqHeaders,
        params: queryParams,
      })
      .pipe(
        map((res) => {
          const companies: Company[] = res.docs;
          return companies;
        })
      );
  }

  postCompany(body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/companies`, body, {
        headers: reqHeaders,
      })
      .pipe(map((res: Company) => res));
  }

  putCompany(company: any, uuid) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/companies/${uuid}`, company, {
        headers: reqHeaders,
      })
      .pipe(map((res: Company) => res));
  }

  deleteCompany(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.delete<any>(
      `${environment.baseUrlApi}/companies/${uuid}`,
      { headers: reqHeaders }
    );
  }

  getFlights(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/companies/${uuid}/flights`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res.docs));
  }
  getTrains(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/companies/${uuid}/trains`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res.docs));
  }

  postCompanyPayComet(uuid: string, body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/companies/${uuid}/addCard`, body, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  postCompanyRedsys(companyUuid: string, body: any): any {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post(
      `${environment.baseUrlApi}/companies/${companyUuid}/addCardRedsys`,
      body,
      {
        headers: reqHeaders,
        responseType: 'text'
      },
    );
  }

  postCompanyNewTokenRedsys(companyUuid: string, body: any): any {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    try {
      const res = this.http.post(
        `${environment.baseUrlApi}/companies/${companyUuid}/processNewCardTokenRedsys`,
        body,
        {
          headers: reqHeaders,
        },
      );

      return res;
    } catch (error) {
      console.log(error)
    }
  }

  deleteImage (companyUuid){
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .delete<any>(
        `${environment.baseUrlApi}/companies/${companyUuid}/deletePhoto`,
        { headers: reqHeaders }
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  removeCard(company) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .put<any>(
        `${environment.baseUrlApi}/companies/${company.uuid}/removeCard`,
        { headers: reqHeaders }
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  removeCardRedsys(company) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .put<any>(
        `${environment.baseUrlApi}/companies/${company.uuid}/removeCardRedsys`,
        { headers: reqHeaders }
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getTransactions(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/companies/${uuid}/transactions`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  getSuppliers() {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/companies//suppliers`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  getBills(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/bills/${uuid}`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  getBill(uuid: string, companyUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get(
      `${environment.baseUrlApi}/bills/${companyUuid}/${uuid}`,
      { headers: reqHeaders, responseType: "blob" }
    );
  }

  postBill(form: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(`${environment.baseUrlApi}/bills/`, form, {
      headers: reqHeaders,
    });
  }
  deleteBill(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.delete<any>(`${environment.baseUrlApi}/bills/${uuid}`, {
      headers: reqHeaders,
    });
  }
  getSavings(companyUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get(`${environment.baseUrlApi}/companies/${companyUuid}/savings`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  postSaving(companyUuid: string, body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(
        `${environment.baseUrlApi}/companies/${companyUuid}/savings`,
        body,
        { headers: reqHeaders }
      )
      .pipe(map((res: any) => res));
  }
  putSaving(companyUuid: string, uuid: string, body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .put<any>(
        `${environment.baseUrlApi}/companies/${companyUuid}/savings/${uuid}`,
        body,
        { headers: reqHeaders }
      )
      .pipe(map((res: any) => res));
  }
  getSatisfactions() {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get(`${environment.baseUrlApi}/companies/satisfactions`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  postSatisfaction(body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/companies/satisfactions`, body, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }
  putSatisfaction(uuid: string, body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .put<any>(
        `${environment.baseUrlApi}/companies/satisfactions/${uuid}`,
        body,
        { headers: reqHeaders }
      )
      .pipe(map((res: any) => res));
  }

  getCompanyImage(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/companies/image/${uuid}`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  getCompanyStats() {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/companies/listEmployees/data`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  getCompanyBookeds(companyUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(
        `${environment.baseUrlApi}/companies/${companyUuid}/full-reservations`,
        {
          headers: reqHeaders,
        }
      )
      .pipe(map((res: any) => res));
  }

  getAllCompaniesBookeds() {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/companies/reservations/full-list`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  changeTransactionType(companyUuid, transactionUuid, type) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const body = {
      type: type,
    };

    return this.http
      .put<any>(
        `${environment.baseUrlApi}/companies/${companyUuid}/transactions/${transactionUuid}/status`,
        body,
        { headers: reqHeaders }
      )
      .pipe(map((res: any) => res));
  }

  getEmailGroupsByCompany(companyUuid){
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(
        `${environment.baseUrlApi}/companies/emailGroups/${companyUuid}`,
        {
          headers: reqHeaders,
        }
      )
      .pipe(map((res: any) => res));
  }

  deleteInvitation(emailInvitation: string, companyUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };

    return this.http
      .delete<any>(
        `${environment.baseUrlApi}/companies/deleteInvitation/${emailInvitation}/${companyUuid}`,
        { headers: reqHeaders }
      )
  }

  getCompanyUsers(companyUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(
        `${environment.baseUrlApi}/companies/${companyUuid}/users`,
        {
          headers: reqHeaders,
        }
      )
      .pipe(map((res: any) => res));
  }

}
