<!-- Navbar (Header) Starts -->
<nav
  class="header-navbar navbar navbar-expand-lg navbar-light bg-faded"
  *ngIf="user || company || hub || agency || employee"
>
  <div class="container-fluid container-fluid-customize d-flex">
    <div class="navbar-header" [ngClass]="{'margin-left-5': isUser}">
      <button
        type="button"
        class="navbar-toggle d-lg-none float-left"
        data-toggle="collapse"
        (click)="toggleSidebar()"
        *ngIf="isUser"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>

      <button
      type="button"
      class="navbar-toggle d-lg-none float-left"
      data-toggle="collapse"
      (click)="toggleNavbar()"
      *ngIf="isCompany || hub || agency || employee"
    >
      <span class="sr-only">Toggle navigation</span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
      <span
        class="nav-item mt-0 collapse navbar-collapse localsfy"
        [ngbCollapse]="isCollapsed"
      >
        <a class="nav-link position-relative" (click)="goMainPage()">
          <img
            src="assets/img/logo/logo_accent.svg"
            alt="vyootrip logo"
            style="max-height: 3.5vh"
          />
        </a>
      </span>
    </div>
    <div
      class="options"
      *ngIf="
        userOrEmployee &&
        userOrEmployee.type?.toUpperCase() !== 'USER' &&
        (userOrEmployee.type?.toUpperCase() === 'COMPANY' && canInvite ? false : true)
      "
    >
      <div
        class="menu pointer"
        *ngIf="userOrEmployee && userOrEmployee.type?.toUpperCase() !== 'COMPANY'"
        (click)="navigate('/companies')"
      >
        {{ "navbar.start" | translate }}
      </div>
      <div
        placement="bottom-right"
        class="d-inline-block"
        ngbDropdown
        *ngIf="isEmployee || isHub || isAgency || isCompany"
      >
        <div
          *ngIf="isCompany === false"
          class="menu pointer text-center text-margin"
          ngbDropdownToggle
        >
          {{ "navbar.entity" | translate }}
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <a
              *ngIf="isEmployee"
              (click)="navigate('hubs')"
              class="dropdown-item text-left"
            >
              <span>{{ "navbar.hubs" | translate }}</span>
            </a>
            <a
              *ngIf="isEmployee || isHub"
              (click)="navigate('agencies')"
              class="dropdown-item text-left"
            >
              <span>{{ "navbar.partners" | translate }}</span>
            </a>
            <a
              *ngIf="isEmployee || isHub || isAgency"
              (click)="navigate('companies')"
              class="dropdown-item text-left"
            >
              <span>{{ "navbar.companies" | translate }}</span>
            </a>
          </div>
        </div>
      </div>


      <div
        class="menu pointer"
        *ngIf="userOrEmployee && userOrEmployee.type?.toUpperCase() === 'COMPANY'"
        (click)="navigate('/my-company/details')"
      >
        {{ "navbar.start" | translate }}
      </div>
      <div
        class="menu pointer text-center"
        *ngIf="userOrEmployee && userOrEmployee.type?.toUpperCase() === 'COMPANY'"
        (click)="
          openGraphModal(
            company.company.name,
            'company',
            'all',
            company.company.uuid
          )
        "
      >
        {{ "navbar.graph" | translate }}
      </div>
      <div ngbDropdown placement="bottom-right" class="d-inline-block" *ngIf="isEmployee">
        <div
          class="menu pointer text-center text-margin"
          ngbDropdownToggle
          [style.font-weight]="isLoadingExcel ? '600' : '300'"
        >
          {{ isLoadingExcel ? ("common.downloading" | translate) : ("navbar.graph" | translate) }}
          <i class="ft-loader" *ngIf="isLoadingExcel"></i>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
            <a
              (click)="openGraphModal('Admin', whoAmI, 'all')"
              class="dropdown-item"
            >
              <span>{{ "graph.amount-expense" | translate }}</span>
            </a>
            <a
              (click)="openGraphModal('Admin', whoAmI, 'service')"
              class="dropdown-item"
            >
              <span>{{ "graph.service-statistics" | translate }}</span>
            </a>
            <a
              class="dropdown-item"
              *ngIf="isEmployee"
              (click)="goToDashboard(true)"
            >
              <span>{{ "dashboard.title" | translate }}</span>
            </a>
            <a class="dropdown-item" (click)="exportExcel()">
              <span>{{ "common.download-report" | translate }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="menu pointer" [routerLink]="'/stats'" *ngIf="isEmployee">
        {{ "navbar.statistics" | translate }}
      </div>
      <div
        class="menu pointer"
        *ngIf="userOrEmployee && userOrEmployee.type?.toUpperCase() === 'COMPANY'"
        (click)="
          goToDashboard(userOrEmployee.type?.toUpperCase() === 'COMPANY' ? false : true)
        "
      >
        {{ "navbar.statistics" | translate }}
      </div>


      <div
        class="menu pointer"
        *ngIf="isEmployee"
        [routerLink]="'/companies/plans'"
      >
        {{ "navbar.plans" | translate }}
      </div>
    <!--      
      <div class="menu pointer" *ngIf="!isUser">
            <a
              href="https://vyootrip.atlassian.net/servicedesk/customer/portals"
              target="_blank"
            >
              <span>{{ "common.support" | translate }}</span>
            </a>
      </div>
    -->
    </div>
    <div class="navbar-container navbar-container-customize">
      <div>
        <div *ngIf="isGroupAdmin || user != null" class="nav-options">
          <div
            *ngIf="isGroupAdmin"
            class="cursor-pointer d-flex flex-wrap"
            style="gap: 5px;"
            (click)="goToRequests()"
          >
            <b>
              {{ "common.authorizations" | translate }}
            </b>
            <span
              *ngIf="authService && authService.totalAuthorizations > 0"
              class="notification"
            >
              {{ authService.totalAuthorizations }}
            </span>
          </div>
          <div class="nav-item cursor-pointer ml-2"*ngIf="user != null">
            <b
              title="{{ 'common.storage' | translate }}"
              (click)="openUserStorage()"
            >
              {{ "common.storage" | translate }}
            </b>
          </div>
          <!-- 
            <div class="nav-item cursor-pointer" *ngIf="user != null">
            <a
              href="https://vyootrip.atlassian.net/servicedesk/customer/portals"
              target="_blank"
            >
              <b>
                {{ "common.support" | translate }}
              </b>
            </a>
          </div>
        -->
        </div>
        <div>
          <div class="nav-item">
            <span *ngIf="isEmployee && userOrEmployee">{{
              userOrEmployee.name
            }}</span>
            <span
              *ngIf="
                (userOrEmployee && userOrEmployee.type?.toUpperCase() === 'USER') ||
                (userOrEmployee && userOrEmployee.type?.toUpperCase() === 'ADMIN') ||
                (userOrEmployee && userOrEmployee.type?.toUpperCase() === 'COMPANY')
              "
              >{{ authenticatedUser?.name }} {{ authenticatedUser?.lastname }}
            </span>
            <span
              *ngIf="
                (authenticatedUser?.accessType &&
                  authenticatedUser?.accessType?.toLowerCase()=== 'agency') ||
                (authenticatedUser?.user &&
                  authenticatedUser?.user.accessType?.toLowerCase()=== 'agency') ||
                (authenticatedUser?.accessType &&
                  authenticatedUser?.accessType?.toLowerCase() === 'hub') ||
                (authenticatedUser?.user &&
                  authenticatedUser?.user.accessType?.toLowerCase() === 'hub')
              "
              >{{
                authenticatedUser?.user
                  ? authenticatedUser?.user.name
                  : authenticatedUser?.name
              }}
              {{
                authenticatedUser?.user
                  ? authenticatedUser?.user.lastname
                  : authenticatedUser?.lastname
              }}
            </span>
          </div>
          <div
            class="nav-item circle"
            ngbDropdown
            placement="bottom-right"
            display="dynamic"
          >
            <a id="dropdownBasic3" ngbDropdownToggle class="dropdown-name">
              <ng-container *ngIf="cardImageBase64; else elseTemplate">
                <div class="user-image">
                  <img [src]="cardImageBase64" />
                </div>
              </ng-container>
              <ng-template #elseTemplate>
                <div class="user-image">
                  <div
                    class="profile-initials text-white"
                    *ngIf="
                      userOrEmployee && userOrEmployee.type?.toUpperCase() !== 'EMPLOYEE';
                      else elseTemplate1
                    "
                  >
                    {{
                      authenticatedUser?.user
                        ? authenticatedUser?.user.name[0]
                        : authenticatedUser?.name[0]
                    }}{{
                      authenticatedUser?.user
                        ? authenticatedUser?.user.lastname[0]
                        : authenticatedUser?.lastname[0]
                    }}
                  </div>
                  <ng-template #elseTemplate1>
                    <div class="profile-initials text-white">A</div>
                  </ng-template>
                </div>
              </ng-template>
              <img
                src="assets/img/svg/arrow-down.svg"
                style="width: 15%; padding-left: 0.3rem"
              />
            </a>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownBasic3 actions-dropdown"
              class="text-left"
            >
              <a
                (click)="onEditUser()"
                *ngIf="isUser || isCompany || isAgency || isHub"
                class="dropdown-item"
                href="javascript:;"
              >
                <span>{{ "navbar.editUser" | translate }}</span>
              </a>
              <ng-container *ngIf="!canUseTRM && isCompany">
                <a
                  (click)="onEditCompany()"
                  class="dropdown-item"
                  href="javascript:;"
                >
                  <span>{{ "navbar.edit-company" | translate }}</span>
                </a>
                <a
                  (click)="onSendEmailUser()"
                  class="dropdown-item"
                  href="javascript:;"
                  *ngIf="canInvite"
                >
                  <span>{{ "navbar.send-email-user" | translate }}</span>
                </a>
                <a
                  *ngIf="isGroupAdmin"
                  (click)="navigate('/my-company/requests')"
                  class="dropdown-item"
                  href="javascript:;"
                >
                  <span>
                    {{ "common.authorizations" | translate }}
                  </span>
                  <span
                    *ngIf="totalAuthorizations && totalAuthorizations > 0"
                    class="notification"
                  >
                    {{ totalAuthorizations }}
                  </span>
                </a>
              </ng-container>
              <a
                target="blank"
                class="dropdown-item action-item"
                href="https://vyootrip.gitbook.io/vyootrip/"
              >
                <span>{{ "navbar.use-guide" | translate }}</span>
              </a>
              <a
                (click)="editConfig()"
                *ngIf="isEmployee"
                class="dropdown-item action-item"
                href="javascript:;"
              >
                <span>{{ "employee.config.config" | translate }}</span>
              </a>
              <a class="cursor-pointer" ngbDropdown [placement]="placement">
                <a
                  class="toggle-language action-item"
                  id="dropdownLang"
                  ngbDropdownToggle
                >
                  <span>{{ "common.language" | translate }}</span>
                  <img
                    src="assets/img/svg/arrow-down.svg"
                    style="width: 15%; padding-left: 0.3rem"
                  />
                </a>
                <div
                  ngbDropdownMenu
                  aria-labelledby="dropdownLang"
                  class="dropdownLang language-dropdown text-left"
                >
                  <a
                    class="dropdown-item py-1 lang"
                    href="javascript:;"
                    (click)="changeLanguage('es')"
                  >
                    <img
                      src="./assets/img/flags/es.svg"
                      class="language-icon"
                      alt="Spanish"
                    />
                    <span
                      [ngClass]="{ 'selected-language': language === 'es' }"
                      >{{ "navbar.spanish" | translate }}</span
                    >
                  </a>
                  <a
                    class="dropdown-item py-1 lang"
                    href="javascript:;"
                    (click)="changeLanguage('en')"
                  >
                    <img
                      src="./assets/img/flags/uk.svg"
                      class="language-icon"
                      alt="English"
                    />
                    <span
                      [ngClass]="{ 'selected-language': language === 'en' }"
                      >{{ "navbar.english" | translate }}</span
                    >
                  </a>
                </div>
              </a>
              <a
                (click)="logout()"
                class="dropdown-item action-item sign-out"
                href="javascript:;"
              >
                <img src="./assets/img/svg/sign-out-alt.svg" alt="language" />
                <span>{{ "common.sign-out" | translate }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<!-- Navbar (Header) Ends -->
