import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { User } from "@models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TokenService } from "./token.service";

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getUser(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/users/${uuid}`, {
        headers: reqHeaders,
      })
      .pipe(map((user: User) => user));
  }

  getUserByUuid(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/users/${uuid}/data`, {
        headers: reqHeaders,
      })
      .pipe(map((user: User) => user));
  }

  getTempToken(token: string) {
    return this.http.get<any>(
      `${environment.baseUrlApi}/auth/external?t=${token}`
    );
  }
  postUser(user: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/users`, user, {
        headers: reqHeaders,
      })
      .pipe(map((res: User) => res));
  }

  postExternalUser(user: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/auth/externalUser`, user, {
        headers: reqHeaders,
      })
      .pipe(map((res: User) => res));
  }
  putUser(userUuid, user: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(`${environment.baseUrlApi}/users/${userUuid}`, user, {
        headers: reqHeaders,
      })
      .pipe(map((res: User) => res));
  }

  putUserGroup(userUuid, user: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .put<any>(
        `${environment.baseUrlApi}/users/${userUuid}/changeGroup`,
        user,
        {
          headers: reqHeaders,
        }
      )
      .pipe(map((res: User) => res));
  }

  deleteUser(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.delete<any>(`${environment.baseUrlApi}/users/${uuid}`, {
      headers: reqHeaders,
    });
  }

  getFlights(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/users/${uuid}/flights`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res.docs));
  }

  sendEmailsExternalUsers(body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(
        `${environment.baseUrlApi}/users/signup/sendRegisterLink`,
        body,
        {
          headers: reqHeaders,
        }
      )
      .pipe(map((res: any) => res));
  }
  getTrains(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/users/${uuid}/trains`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res.docs));
  }

  getGroupUser(userUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/users/${userUuid}/group`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  getUserBookeds(userUuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(
        `${environment.baseUrlApi}/users/${userUuid}/full-reservations`,
        {
          headers: reqHeaders,
        }
      )
      .pipe(map((res: any) => res));
  }

  getUserFile(userUuid: any, file: String) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get(`${environment.baseUrlApi}/users/${userUuid}/get-file/${file}`, {
        headers: reqHeaders,
        responseType: "blob",
      })
      .toPromise();
  }

  deleteUserFile(userUuid: any, file: String): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.delete<any>(
      `${environment.baseUrlApi}/users/${userUuid}/remove-file/${file}`,
      {
        headers: reqHeaders,
      }
    );
  }

  uploadUserFile(userUuid: any, fileForm: FormData) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post(
      `${environment.baseUrlApi}/users/upload-file/${userUuid}`,
      fileForm,
      {
        headers: reqHeaders,
      }
    );
  }

  sendActivateEmail(uuid: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .post<any>(
        `${environment.baseUrlApi}/users/sendEmailActivate/${uuid}`,
        {
          headers: reqHeaders,
        })
      .pipe(map((res: any) => res));
  }

}
