import {
  AfterViewInit,
  Directive,
  HostBinding,
  HostListener,
  Input,
  Renderer2,
} from "@angular/core";

@Directive({ selector: "[appSidebarToggle]" })
export class SidebarToggleDirective implements AfterViewInit {
  @HostBinding("attr.data-toggle")
  @Input()
  get dataToggle(): string {
    return this._dataToggle;
  }
  set dataToggle(value: string) {
    this._dataToggle = value;
  }

  protected _dataToggle = "collapsed";
  protected $wrapper: Element;
  protected compact_menu_checkbox;
  protected collapseIcon: Element;
  protected optionText: Element;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.$wrapper = document.getElementsByClassName("wrapper")[0];
    this.collapseIcon = document.getElementById("collapse-icon");
    this.compact_menu_checkbox =
      this.$wrapper.querySelector(".cz-compact-menu");
  }

  @HostListener("click", ["$event"])
  onClick(e: any) {
    if (this.dataToggle === "expanded") {
      this.dataToggle = "collapsed";
      this.renderer.addClass(this.$wrapper, "nav-collapsed");
      this.renderer.removeClass(this.collapseIcon, "left-icon");
    } else {
      this.dataToggle = "expanded";
      this.renderer.removeClass(this.$wrapper, "nav-collapsed");
      this.renderer.removeClass(this.$wrapper, "menu-collapsed");
      this.renderer.addClass(this.collapseIcon, "left-icon");
    }

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };
}
