import { TranslationWidth } from "@angular/common";
import { Injectable } from "@angular/core";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

const ES_VALUES = {
  weekdays: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
  months: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
};

@Injectable()
export class NgbDatepickerES extends NgbDatepickerI18n {
  constructor() {
    super();
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return ES_VALUES.weekdays[weekday - 1];
  }

  getWeekdayShortName(weekday: number): string {
    return ES_VALUES.weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return ES_VALUES.months[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
