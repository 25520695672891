import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomServicesGuard } from "@core/guards/custom-services.guard";
import { AirbnbRequestComponent } from "./components/airbnb-request/airbnb-request.component";
import { CarRequestComponent } from "./components/car-request/car-request.component";
import { CustomFlightTrainComponent } from "./components/custom-flight-train/custom-flight-train.component";
import { RequestCustomHotelComponent } from "./components/request-custom-hotel/request-custom-hotel.component";
import { CustomServicesComponent } from "./custom-services.component";

const routes: Routes = [
  {
    path: "",
    component: CustomServicesComponent,
    canActivate: [CustomServicesGuard],
  },
  {
    path: "hotel",
    component: RequestCustomHotelComponent,
    canActivate: [CustomServicesGuard],
  },
  {
    path: "flight",
    component: CustomFlightTrainComponent,
    canActivate: [CustomServicesGuard],
  },
  {
    path: "train",
    component: CustomFlightTrainComponent,
    canActivate: [CustomServicesGuard],
  },
  {
    path: "car",
    component: CarRequestComponent,
    canActivate: [CustomServicesGuard],
  },
  {
    path: "airbnb",
    component: AirbnbRequestComponent,
    canActivate: [CustomServicesGuard],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomServicesRoutingModule {}
