<div class="content">
  <h5 class="modal-title heading-5">
    {{ "flight.detail-flight.detail-flight" | translate }}
  </h5>

  <div class="date-location-container">
    <div class="date-location-row">
      <div class="date-location">
        <div class="icon-bcg">
          <img src="../../../../../../assets/img/svg/flight-takeoff-black.svg" />
        </div>
        <span>{{ booking.from }}</span>
      </div>
      <div class="date-location">
        <div class="icon-bcg">
          <img src="../../../../../../assets/img/svg/flight-landing-black.svg" />
        </div>
        <span class="location">{{ booking.to }}</span>
      </div>
    </div>

    <div class="date-location-row">
      <div class="date-location">
        <div class="icon-bcg">
          <img src="../../../../../../assets/img/svg/calendar-black.svg" />
        </div>
        <span>{{ "my-bookeds.train-flight.dep-date" | translate }}:
          {{
          booking.departDate | dateMoment: "YYYY-MM-DDTHH:mm":"DD/MM/YYYY"
          }}</span>
      </div>
      <div class="date-location">
        <div class="icon-bcg">
          <img src="../../../../../../assets/img/svg/clock-black.svg" />
        </div>
        <span>{{ "flight.detail-flight.departure-time" | translate }}: {{
          formatTime(booking.departDate)
          }}</span>
      </div>
    </div>

    <div class="date-location-row" *ngIf="booking.oneWay === false">
      <div class="date-location">
        <div class="icon-bcg">
          <img src="../../../../../../assets/img/svg/calendar-black.svg" />
        </div>
        <span>{{ "my-bookeds.train-flight.dep-date" | translate }}:
          {{
          booking.returnDate | dateMoment: "YYYY-MM-DDTHH:mm":"DD/MM/YYYY"
          }}</span>
      </div>
      <div class="date-location">
        <div class="icon-bcg">
          <img src="../../../../../../assets/img/svg/clock-black.svg" />
        </div>
        <span>{{ "flight.detail-flight.departure-time" | translate }}: {{
          formatTime(booking.returnDate)
          }}</span>
      </div>
    </div>
  </div>

  <div class="type">
    {{ "my-bookeds.train-flight.type-travel" | translate }}:

    <span *ngIf="booking.oneWay">
      {{ "my-bookeds.train-flight.oneWay" | translate }}
    </span>

    <span *ngIf="!booking.oneWay">
      {{ "my-bookeds.train-flight.notOneWay" | translate }}
    </span>
  </div>

  <div class="status">
    {{ "common.status" | translate }}:

    <span *ngIf="booking.status === 'active'" class="pending">
      {{ "common.pending" | translate }}
    </span>

    <span *ngIf="booking.status === 'cancelled'" class="cancelled">
      {{ "common.cancelled" | translate }}
    </span>
  </div>

  <div class="status">
    {{ "common.creation-date" | translate }}:
    <span>
      {{ booking.createdAt | dateMoment: "YYYY-MM-DDTHH:mm":"DD/MM/YYYY" }}
    </span>
  </div>
  <div class="status" *ngIf="booking?.pnr">
    PNR:
    <span>
      {{ booking.pnr }}
    </span>
  </div>

  

  <div class="status">
    {{ "common.book-ticket" | translate }}:
    <input type="file" id="file" class="inputFile" (change)="onFileChange($event)" accept="application/pdf" />
    <label for="file" class="add-file"><img src="../../../../../../assets/img/svg/upload-file.svg" />{{
      "flight.create-flight.choose-file" | translate
      }}</label>
    <label class="pl-3" *ngIf="file">{{ file.name }}</label>
  </div>
  <div *ngIf="isSame(booking.departDate, booking.returnDate) && !booking.returnCheckin" class="return-bono-checkbox">
    <mat-checkbox [(ngModel)]="returnFile">{{"flight-train.upload-return-file" | translate}}</mat-checkbox>
  </div>
  <div class="save-button-row">
    <button class="custom-btn" (click)="closeModal('withPdf')">
      {{ "common.save" | translate }}
    </button>
  </div>
</div>