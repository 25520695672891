import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService, NgxToastrService } from "@services";
import { ValidatePassword } from "app/validators/password.validator";
import Swal from "sweetalert2";

@Component({
  selector: "app-create-password",
  templateUrl: "./create-password.component.html",
  styleUrls: ["./create-password.component.scss"],
})
export class CreatePasswordComponent implements OnInit {
  form: UntypedFormGroup;
  token: string;
  loading = false;
  typeUser: "user" | "company" | "employee";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private ngxToastrService: NgxToastrService,
    public translate: TranslateService
  ) {
    if (this.router.url.includes("/login")) {
      this.typeUser = "user";
    } else if (this.router.url.includes("/company-login")) {
      this.typeUser = "company";
    } else {
      this.typeUser = "employee";
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        password: ["", [Validators.required]],
        confirmPassword: ["", Validators.required],
      },
      { validator: ValidatePassword }
    );

    this.route.params.subscribe((params) => {
      this.token = params["token"];
    });
  }

  get f() {
    return this.form.controls;
  }

  urlLogin() {
    switch (this.typeUser) {
      case "user":
        return ["/login"];
      case "company":
        return ["/login"];
      case "employee":
        return ["/admin-login"];
    }
  }

  onSubmit() {
    if (this.token !== null) {
      this.loading = true;
      const params = {
        token: this.token,
        password: this.f.password.value,
        confirmPassword: this.f.confirmPassword.value,
      };
      const authCreate =
        this.typeUser === "user"
          ? this.authService.createPasswordUser(params)
          : this.typeUser === "company"
          ? this.authService.createPasswordCompany(params)
          : this.authService.createPasswordEmployee(params);
      authCreate.subscribe(
        () => {
          this.loading = false;

          this.translate
            .get(["common.congrat", "common.created-pass"])
            .subscribe((result) => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: result["common.congrat"],
                text: result["common.created-pass"],
                showConfirmButton: false,
                timer: 2000,
              }).then(() => this.router.navigate([this.urlLogin()]));
            });
        },
        (err) => {
          this.loading = false;
          this.translate.get("common.some-error").subscribe((result) => {
            this.ngxToastrService.typeInfo("Message", result);
          });
        }
      );
    }
  }

  getClassRecoveryButton() {
    switch (this.typeUser) {
      case "user":
        return "btn-primary";
      case "company":
        return "btn-company";
      case "employee":
        return "btn-vyoo-primary";
    }
  }

  getClassBackground() {
    switch (this.typeUser) {
      case "user":
        return "user-background";
      case "company":
        return "company-background";
      case "employee":
        return "employee-background";
    }
  }
}
