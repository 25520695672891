import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { TokenService } from "./token.service";
@Injectable({
  providedIn: "root",
})
export class CarService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  sendCustomCarRequest(body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/car/request-car`,
      body,
      { headers: reqHeaders }
    );
  }

  createCustomCar(body: any, requestUuid?: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const url = requestUuid
      ? `${environment.baseUrlApi}/car/custom?requestUuid=${requestUuid}`
      : `${environment.baseUrlApi}/car/custom`;
    return this.http.post<any>(url, body, { headers: reqHeaders });
  }

  getUserCars(userUuid: string): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get<any>(`${environment.baseUrlApi}/car/${userUuid}`, {
      headers: reqHeaders,
    });
  }

  getCompanyCars(companyUuid: string): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get<any>(
      `${environment.baseUrlApi}/car/company/${companyUuid}`,
      { headers: reqHeaders }
    );
  }

  getCarPdf(bonoFileUrl: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get(`${environment.baseUrlApi}${bonoFileUrl}`, {
      headers: reqHeaders,
      responseType: "blob",
    });
  }

  editCar(car: any, carUuid: string): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/car/custom/edit/${carUuid}`,
      car,
      { headers: reqHeaders }
    );
  }

  cancelCar(carUuid: string): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.delete<any>(`${environment.baseUrlApi}/car/${carUuid}`, {
      headers: reqHeaders,
    });
  }

  updateCarStatus(carUuid: string): Observable<any> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.put<any>(`${environment.baseUrlApi}/car/status/${carUuid}`, {}, {
      headers: reqHeaders,
    });
  }

}
