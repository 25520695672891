import { UntypedFormGroup } from "@angular/forms";

export function ValidatePrice(formGroup: UntypedFormGroup) {
  const f = formGroup.controls;
  if (
    !isNaN(Number(f.priceMin.value)) &&
    !isNaN(Number(f.priceMax.value)) &&
    Number(f.priceMin.value) < Number(f.priceMax.value)
  ) {
    return null;
  }
  return { validatePrice: true };
}
