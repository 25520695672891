<div class="master-container border-radius-30">
  <div class="add-plan">
    <div class="filters-container">
      <input
        #searchPlanInput
        type="text"
        class="form-control form-control--squared form-control--small search-input"
        placeholder="{{ 'company.company-homepage.search' | translate }}..."
        (input)="searchPlan($event.target.value)"
      />
      <select
        #selectFilter
        type="text"
        class="form-control form-control--squared form-control--small"
        (change)="onSortSelected(selectFilter.value)"
      >
        <option
          [value]="sortOption.value"
          *ngFor="let sortOption of sortOptions"
        >
          {{ sortOption.displayName | translate }}
        </option>
      </select>
      <button
        id="delete-filters"
        type="text"
        class="clear-btn"
        (click)="removeFilters()"
      >
        <span>
          {{ "plans.delete-filters" | translate }}
        </span>
        <span>x</span>
      </button>
    </div>
    <button class="custom-btn" (click)="onNewPlan()">
      {{ "plans.add-plan" | translate }}
    </button>
  </div>
  <section>
    <div
      class="plan-card border-radius-30"
      *ngFor="let plan of plans; trackBy: utilsService.getUuidTracking"
      [style]="{ 'background-color': plan.color + '21' }"
    >
      <div class="plan-card-header">
        <div>
          <div
            class="plan-name-icon"
            [style]="{ 'background-color': plan.color }"
          >
            <span>
              {{
                (plan.name.split(" ").length > 1
                  ? getInitials(plan.name)
                  : plan.name.charAt(0)
                ) | uppercase
              }}
            </span>
          </div>
          <h4 class="text-truncate">
            {{ plan.name }}
          </h4>
        </div>
        <div
          ngbDropdown
          placement="bottom-right"
          class="d-inline-block dropdown"
        >
          <span class="material-icons" ngbDropdownToggle> more_vert </span>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="menu">
            <span
              ngbDropdownItem
              class="dropdown-item text-center menu-item"
              (click)="onEdit(plan, plan.uuid)"
            >
              {{ "plans.edit" | translate }}</span
            >
            <span
              ngbDropdownItem
              class="dropdown-item text-center menu-item"
              (click)="onDelete(plan.uuid)"
            >
              {{ "plans.delete" | translate }}</span
            >
          </div>
        </div>
      </div>
      <div class="plan-card-content">
        <div class="plan-card-body">
          <div>
            <span>{{ "plans.form.price" | translate | uppercase }}</span>
            <span class="value">{{ plan.price | price }}€</span>
          </div>
          <div>
            <span>{{ "plans.created-at" | translate | uppercase }}</span>
            <span class="value">{{ plan.createdAt | dateMoment }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
