import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CanNavigateGuard } from "@core/guards/can-navigate.guard";
import { FlightPendingComponent } from "./flight-pending/flight-pending.component";
import { FormFlightTrainComponent } from "./form-flight-train/form-flight-train.component";
import { FlightSeatsSelectionComponent } from "./select-flight/flight-seats-selection/flight-seats-selection.component";
import { SelectFlightComponent } from "./select-flight/select-flight.component";

const routes: Routes = [
  {
    path: "",
    component: FormFlightTrainComponent,
    canActivate: [CanNavigateGuard],
  },
  { path: "order", component: SelectFlightComponent },
  { path: "pending", component: FlightPendingComponent },
  { path: "seats-selection", component: FlightSeatsSelectionComponent },
  // { path: '', component: FlightFormComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanNavigateGuard],
})
export class FlightTrainRoutingModule {}
