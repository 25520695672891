import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "description",
})
export class DescriptionPipe implements PipeTransform {
  transform(description: string, value: number): any {
    return description.length < value
      ? description
      : description.substring(0, value).concat("...");
  }
}
