import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({ name: "dateHourMoment" })
export class DateHourMomentPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return null;
    } else {
      value = moment(value).format("DD/MM/YYYY HH:mm");
      return value;
    }
  }
}
