<div class="card horizontal" [ngClass]="{'map-opened': mapView}">
  <div id="cardContent" class="row mx-0 card-content">
    <div
      id="hotelImage"
      class="card-img"
      [ngClass]="mapView ? 'collapse-img' : ''"
    >
      <div [attr.id]="'hotelImages' + index" class="carousel slide w-100" data-ride="carousel" *ngIf="hotel && hotel.images">
        <ol class="carousel-indicators">
          <li *ngFor="let image of hotel.images; let i = index" data-target="#hotelImages" [attr.data-slide-to]="i" [class.active]="i === 0"></li>
        </ol>
        <div class="carousel-inner">
          <div *ngFor="let image of hotel.images; let i = index" class="carousel-item" [class.active]="i === 0">
            <img [src]="image.path || image" class="d-block w-100" style="max-height: 230px;" alt={{hotel.name}} [ngStyle]="{'height': mapView ? '230px' : '100%'}">
          </div>
        </div>
        <a class="carousel-control-prev" [attr.href]="'#hotelImages' + index" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" [attr.href]="'#hotelImages' + index" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
    <div id="hotelData" class="col-12 card-stacked">
      <div class="row">
        <h4 class="card-title">
          {{ hotel.name }}
        </h4>

        <div class="text-left divStarts pl-1">
          <i
            *ngFor="let i of countStars"
            class="fa fa-star font-medium-1 yellow darken-2"
          ></i>
        </div>
      </div>
      <div class="row">
        <div class="row info-container">
          <div>
            <p class="card-text">{{ hotel.address }}</p>
            <p class="card-text">
              {{ hotel.distanceToDestination | number : "1.2-2" }}
              {{ "search.card.distanceToDestination" | translate }}
            </p>
          </div>
          <div class="col-5 facilities-container">
            <p>
              <small
                >{{ "hotel.reference" | translate }}: {{ hotel.code }}</small
              >
            </p>
            <div class="facilities">
              <p
                *ngIf="
                  hotel &&
                  hotel.facility &&
                  hotel.facility.includes('GREENHOTEL')
                "
              >
                <img src="assets/img/svg/green-tree.svg" />{{
                  "search.card.green-hotel" | translate
                }}
              </p>
              <p
                *ngIf="
                  hotel &&
                  hotel.facility &&
                  hotel.facility.includes('24HRECEPTION')
                "
              >
                <img src="assets/img/svg/cloche-orange.svg" />{{
                  "search.card.24h-reception" | translate
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="row price">
          <div class="my-auto py-1 px-2">
            <h5 class="text-bold-600 my-auto">
              <small>{{ "graph.from" | translate }}</small>
              {{ hotel.totalBooked | price }}{{ currency }}
            </h5>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="hotel.userBookingOption === 2 || hotel.userBookingOption === 3 || !canRequest"
      >
        <span
          *ngIf="!canRequest || hotel.userBookingOption === 2"
          class="no-preauthorized"
        >
          {{ "search.card.noPreAuthorized" | translate }}
        </span>
        <span
          *ngIf="canRequest && hotel.userBookingOption === 3"
          class="half-preauthorized"
        >
          {{ "search.card.halfPreAuthorized" | translate }}
        </span>
      </div>
      <div class="row" id="colButtonGetHotel">
        <div class="dropdown custom-btn custom-btn--close">
          <a (click)="mapView ? (mapView = false) : (mapView = true)">
            <img src="assets/img/svg/map-black.svg" />{{
              "search.search-homepage.showMap" | translate
            }}<img src="assets/img/svg/ionic-ios-arrow-down.svg"
          /></a>
        </div>
        <div class="text-right">
          <a
            class="custom-btn my-auto"
            (click)="getHotel()"
            >{{ "search.card.see-rooms" | translate }}</a
          >
        </div>
      </div>
      <div *ngIf="mapView" class="row map">
        <app-google-maps
          [hotelsMapCopy]="hotelsMapCopy"
          [hotelsListCopy]="hotelsListCopy"
          [listHotels]="[hotel]"
          [singleSearch]="true"
          [firstSearch]="firstSearch"
          [mapHasBeenDragged]="mapHasBeenDragged"
          [searchConfig]="searchConfig"
          [fromCard]="true"
          style="height: 100%; width: 100%"
        ></app-google-maps>
      </div>
    </div>
  </div>
</div>
