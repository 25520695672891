import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { CompanyService, UserService } from "@core/services";
import { GroupService } from "@core/services/group.service";
import { NgxToastrService } from "@core/services/ngx.toastr.service";
import { TravelUtilsService } from "@core/services/travel-utils.service";
import { UtilsService } from "@core/services/utils.service";
import { User } from "@models";
import {
  NgbActiveModal,
  NgbDateParserFormatter,
  NgbTypeahead,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "app/app.reducer";
import cities from "assets/utils/cities.json";
import * as moment from "moment";
import { take } from "rxjs";

@Component({
  selector: "app-create-flight-train",
  templateUrl: "./create-flight-train.component.html",
  styleUrls: ["./create-flight-train.component.scss"],
})
export class CreateFlightTrainComponent implements OnInit {
  @Input() isAdmin;
  @Input() type: "flight" | "train";
  @Input() loading: Boolean;
  @Input() companyUuid: string;
  @Input() userId: string;
  @Output() sendInfo = new EventEmitter<any>();
  editing = false;
  @Input() data;
  request: any;
  @Input() blobUrl;
  @Input() fileBack;
  userType: any;

  @ViewChild("instanceUsers") instanceUsers: NgbTypeahead;

  form: UntypedFormGroup;
  prefOptions = [];
  users: User[] = [];
  file: File;
  user: User;
  citiesList = [];
  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear() - 1,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  checkedOneWay = false;
  checkedReturn = true;
  bodyTravel: any = {};
  isInvalid: boolean = false;
  companyUsers: { name: string; lastname: string; uuid: string }[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private groupService: GroupService,
    private ngxToastrService: NgxToastrService,
    private ngbDateParser: NgbDateParserFormatter,
    private travelUtilsService: TravelUtilsService,
    private utilsService: UtilsService,
    private userService: UserService,
    private store: Store<AppState>,
    private companyService: CompanyService
  ) { }

  ngOnInit() {
    this.data && this.data !== null
      ? (this.editing = true)
      : (this.editing = false);
    this.translate
      .get([
        `${this.type?.toLowerCase()}.form-${this.type?.toLowerCase()}.anyone`,
        `${this.type?.toLowerCase()}.form-${this.type?.toLowerCase()}.morning`,
        `${this.type?.toLowerCase()}.form-${this.type?.toLowerCase()}.afternoon`,
        `${this.type?.toLowerCase()}.form-${this.type?.toLowerCase()}.night`,
      ])
      .subscribe((result) => {
        this.prefOptions = [
          {
            value: "anyone",
            text: result[`${this.type?.toLowerCase()}.form-${this.type?.toLowerCase()}.anyone`],
          },
          {
            value: "morning",
            text: result[`${this.type?.toLowerCase()}.form-${this.type?.toLowerCase()}.morning`],
          },
          {
            value: "evening",
            text: result[`${this.type?.toLowerCase()}.form-${this.type?.toLowerCase()}.afternoon`],
          },
          {
            value: "noon",
            text: result[`${this.type?.toLowerCase()}.form-${this.type?.toLowerCase()}.night`],
          },
        ];
      });
    this.checkedOneWay = this.data
      ? this.data && this.data.oneWay === true
      : false;
    this.checkedReturn = this.checkedOneWay ? false : true;
    if (this.userId) {
      this.userService
        .getUser(this.userId)
        .pipe(take(1))
        .subscribe((user: User) => {
          this.user = user;
        });
    }
    this.store
    .select("auth")
    .pipe(take(1))
    .subscribe((res) => {
      if (res.isAuthenticated) {
        this.userType = res.user.type;
      }
    });

    // Inicializo el formulario
    this.form = this.formBuilder.group({
      file: [
        this.editing ? this.data.file : null,
        this.fileBack && this.blobUrl ? [] : [],
      ],
      origin: [
        { value: this.editing ? this.data.from : this.request ? this.request.from : '', disabled: this.userType != "EMPLOYEE"},
        [Validators.required],
      ],
      destination: [
        { value: this.editing ? this.data.to : this.request ? this.request.to : '', disabled: this.userType != "EMPLOYEE" },
        [Validators.required],
      ],
      dateDeparture: [
        this.editing
          ? this.ngbDateParser.parse(
            moment(this.data.departDate).toString()
          )
          : '',
        [Validators.required],
      ],
      dateReturn: [
        this.editing && this.data.returnDate
          ? this.ngbDateParser.parse(
            moment(this.data.returnDate).toString()
          )
          : null,
      ],
      timeDeparture: [
        { value: this.editing
          ? {
            hour: new Date(this.data.departDate).getHours(),
            minute: new Date(this.data.departDate).getMinutes(),
          }
          : '', disabled: this.userType != "EMPLOYEE" },
        [Validators.required],
      ],
      timeReturn: [
       { value: this.editing && this.data.returnDate
          ? {
            hour: new Date(this.data.returnDate).getHours(),
            minute: new Date(this.data.returnDate).getMinutes(),
          }
          : '', disabled: this.userType != "EMPLOYEE"}
      ],
      notes: [{ value: this.editing ? this.data.observations : "", disabled: this.userType != "EMPLOYEE" }],
      price: [
        {
          value: this.editing
            ? this.data.lleegoReservationUuid || this.data.trenymasTicketId
              ? this.data.price.toFixed(2)
              : this.data.price.toFixed(2)
            : '',
          disabled: this.data?.payment || this.data?.refund || this.userType != "EMPLOYEE",
        },
        [Validators.required],
      ],
      
      freeTaxes: [
        { value: this.editing && this.data !== null && this.data.freeTaxes
          ? this.data.freeTaxes
          : false, disabled: this.userType != "EMPLOYEE" },
        [Validators.required],
      ],
      customCode: [
        this.editing
          ? this.data.customCode != null
            ? this.data.customCode
            : null
          : '',
      ],
      costCenter: [
        this.editing
          ? this.data.costCenter != null
            ? this.data.costCenter
            : null
          : '',
      ],
    });
    
    const validator = this.checkedOneWay ? null : [Validators.required];
    this.form.get("dateReturn").setValidators(validator);
    this.form.get("timeReturn").setValidators(validator);
    this.form.get("dateReturn").updateValueAndValidity();
    this.form.get("timeReturn").updateValueAndValidity();

    if (this.editing) {
      this.form.addControl('passenger', new FormControl({ value: this.data ? this.data.userUuid : '', disabled: !(this.userType?.toLowerCase() === 'employee' || this.userType?.toLowerCase() === 'company') }));
    }

    if (this.form.value.dateDeparture) {
      this.form.patchValue({ dateDeparture: { day: this.form.value.dateDeparture.day, month: this.form.value.dateDeparture.month + 1, year: this.form.value.dateDeparture.year } })
    }
    if (this.form.value.dateReturn) {
      this.form.patchValue({ dateReturn: { day: this.form.value.dateReturn.day, month: this.form.value.dateReturn.month + 1, year: this.form.value.dateReturn.year } })
    }
    if (this.request) {
      this.request.initDate = moment(this.request.initDate).format(
        "DD/MM/YYYY HH:mm"
      );
      const valueInit = {
        year: this.request.initDate.split("/")[2].split(" ")[0] * 1,
        month: this.request.initDate.split("/")[1] * 1,
        day: this.request.initDate.split("/")[0] * 1,
      };
      this.form.patchValue({ dateDeparture: valueInit });
      const timeInit = {
        hour:
          this.request.initDate
            .toString()
            .split("/")[2]
            .split(" ")[1]
            .split(":")[0] * 1,
        minute:
          this.request.initDate
            .toString()
            .split("/")[2]
            .split(" ")[1]
            .split(":")[1] * 1,
      };
      this.form.patchValue({ timeDeparture: timeInit });
      if (this.request.endDate) {
        this.request.endDate = moment(this.request.endDate).format(
          "DD/MM/YYYY HH:mm"
        );
        const valueEnd = {
          year: this.request.endDate.split("/")[2].split(" ")[0] * 1,
          month: this.request.endDate.split("/")[1] * 1,
          day: this.request.endDate.split("/")[0] * 1,
        };
        this.form.patchValue({ dateReturn: valueEnd });
        const timeEnd = {
          hour:
            this.request.endDate
              .toString()
              .split("/")[2]
              .split(" ")[1]
              .split(":")[0] * 1,
          minute:
            this.request.endDate
              .toString()
              .split("/")[2]
              .split(" ")[1]
              .split(":")[1] * 1,
        };
        this.form.patchValue({ timeReturn: timeEnd });
      }
      this.checkedOneWay = this.request ? this.request.oneWay === true : false;
      this.checkedReturn = this.request ? this.request.oneWay === false : false;
    }
    // Cargo los empleados de la compañia
    if (!this.userId) {
      this.form.addControl(
        "userId",
        new UntypedFormControl(null, [Validators.required])
      );
      this.loadGroups(this.companyUuid);
    }

    if(this.editing && this.utilsService.isAdmin(this.userType?.toLowerCase())){
      this.getCompanyUsers();
    }
  }
  get f() {
    return this.form.controls;
  }

  departureBeforeReturn(dateDepartureAux, dateReturnAux) {
    return this.utilsService.departureBeforeReturn(
      dateDepartureAux,
      dateReturnAux,
      this.f
    );
  }

  onSubmit() {
    this.isInvalid = false
    const travel = this.travelUtilsService.getTravelLocalStorage();
    const dateDepartureAux = moment(
      this.ngbDateParser.format(this.f.dateDeparture.value),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const dateReturnAux = moment(
      this.ngbDateParser.format(this.f.dateReturn.value),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    if (this.departureBeforeReturn(dateDepartureAux, dateReturnAux)) {
      if (
        !moment(this.f.dateReturn.value).isBefore(
          moment(this.f.dateDeparture.value)
        )
      ) {
        if (!this.userId && !this.f.userId.value) {
          this.translate
            .get([
              "common.error",
              `${this.type?.toLowerCase()}.create-${this.type?.toLowerCase()}.error-employee`,
            ])
            .subscribe((resp) => {
              this.ngxToastrService.typeInfo(
                resp["common.error"],
                resp[`${this.type?.toLowerCase()}.create-${this.type?.toLowerCase()}.error-employee`]
              );
            });
        } else {
          if (this.fileBack) {
            delete this.fileBack.name;
          }
          const req: any = {
            userId: this.userId ? this.userId : this.f.userId.value.uuid,
            companyUuid: this.companyUuid,
            file: this.file ? this.file : this.fileBack,
            origin: this.f.origin.value,
            destination: this.f.destination.value,
            departure: moment(
              dateDepartureAux +
              " " +
              (
                "0" +
                (this.f.timeDeparture.value
                  ? this.f.timeDeparture.value.hour
                  : "00")
              ).slice(-2) +
              ":" +
              (
                "0" +
                (this.f.timeDeparture.value
                  ? this.f.timeDeparture.value.minute
                  : "00")
              ).slice(-2)
            ),
            annotations: this.f.notes.value || '',
            price: parseFloat(this.f.price.value.toString().replace(",", ".")).toFixed(2),
            freeTaxes: this.f.freeTaxes.value,
            oneWay: this.checkedOneWay,
            customCode: this.f.customCode.value,
            costCenter: this.f.costCenter.value,
          };
          if (!this.checkedOneWay) {
            req.return = moment(
              dateReturnAux +
              " " +
              (
                "0" +
                (this.f.timeReturn.value
                  ? this.f.timeReturn.value.hour
                  : "00")
              ).slice(-2) +
              ":" +
              (
                "0" +
                (this.f.timeReturn.value
                  ? this.f.timeReturn.value.minute
                  : "00")
              ).slice(-2)
            );
          }
          if (travel && travel.data.title) {
            req["travelName"] = travel.data.title;
          }

          if(this.editing){
            req["passenger"] = this.f.passenger.value;
          }

          if (this.request?.traveller) {
            req["traveller"] = this.request.traveller;
          } else if (this.user) {
            let traveller = []
            switch (this.type?.toLowerCase()) {
              case 'flight':
                traveller.push({ name: `${this.user.name} ${this.user.lastname}` });
                req["traveller"] = traveller;
                break;
              case 'train':
                traveller.push({ name: `${this.user.name} ${this.user.lastname}`, renfeCard: this.user.renfeCard ?? null })
                req["traveller"] = traveller;
                break;
            }
          }

          this.sendInfo.emit(req);
        }
      } else {
        this.translate
          .get(["common.error", `${this.type?.toLowerCase()}.create-${this.type?.toLowerCase()}.error-dates`])
          .subscribe((resp) => {
            this.ngxToastrService.typeInfo(
              resp["common.error"],
              resp[`${this.type?.toLowerCase()}.create-${this.type?.toLowerCase()}.error-dates`]
            );
          });
      }
    } else {
      this.translate
        .get(["common.error", `${this.type?.toLowerCase()}.create-${this.type?.toLowerCase()}.error-dates`])
        .subscribe((resp) => {
          this.ngxToastrService.typeInfo(
            resp["common.error"],
            resp[`${this.type?.toLowerCase()}.create-${this.type?.toLowerCase()}.error-dates`]
          );
        });
    }
  }
  handleTravel(dataTravel: any) {
    this.bodyTravel = dataTravel;
    this.travelUtilsService.setTravelLocalStorage(this.bodyTravel);
    this.translate.get(["project.form.saved"]).subscribe((resp) => {
      this.ngxToastrService.typeSuccess(resp["project.form.saved"], "");
    });
  }
  closeModal() {
    this.activeModal.dismiss("Cross click");
  }

  loadGroups(uuid) {
    this.loading = true;
    this.groupService.getGroups(uuid).subscribe(
      (result: any[]) => {
        this.loading = false;
        result
          .filter((group) =>
            this.type?.toLowerCase() === "flight"
              ? group.filters.requestFlight
              : group.filters.requestTrain
          )
          .forEach((group) => (this.users = this.users.concat(group.users)));
      },
      (err) => {
        this.loading = false;
        this.translate
          .get(`${this.type?.toLowerCase()}.create-${this.type?.toLowerCase()}.error-load-employees`)
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  // Buscador de ciudades
  getCities(value: string) {
    this.citiesList = [];
    if (value !== "" && value.length > 2) {
      cities.Table1.forEach((v) => {
        if (
          v["name"]?.toLowerCase().includes(value?.toLowerCase()) &&
          !this.citiesList.includes(v.name)
        ) {
          this.citiesList.push(v["name"]);
        }
      });
    } else {
      this.citiesList = [];
    }
  }

  cleanCitiesList() {
    this.citiesList = [];
  }
  onFileChange(event) {
    if (event.target.files.length > 0 && this.userType === 'EMPLOYEE') {
      this.file = event.target.files[0];
    }
  }

  getOutDate() {
    return this.f.dateDeparture
      ? this.f.dateDeparture.value
      : this.minDateNgStruct;
  }

  returnTrip(type) {
    this.checkedOneWay = type === "oneWay";
    this.checkedReturn = type === "return";
    const validator = this.checkedOneWay ? null : [Validators.required];
    this.form.get("dateReturn").setValidators(validator);
    this.form.get("timeReturn").setValidators(validator);
    this.form.get("dateReturn").updateValueAndValidity();
    this.form.get("timeReturn").updateValueAndValidity();
  }

  getCompanyUsers() {
    this.companyService.getCompanyUsers(this.companyUuid).subscribe(
      (res) => {
        this.companyUsers = res.map(user => ({
          name: user.name,
          lastname: user.lastname,
          uuid: user.uuid
        }));
        if (this.data) {
          this.form.patchValue({
            passenger: this.data.userUuid
          });
        }
      },
      (err) => {
        console.error('Error fetching users:', err);
      }
    );
  }

}
