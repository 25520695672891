import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationExtras, Router } from "@angular/router";
import { TrainSearchDto } from "@core/models/trenymas/train-search-dto";
import {
  OutwardTrain,
  SalesGroup,
} from "@core/models/trenymas/train-search-response";
import { NgxToastrService, TrainService, UtilsService } from "@core/services";
import { TranslateService } from "@ngx-translate/core";
import { CustomConfirmDialogComponent } from "@shared/component/custom-confirm-dialog/custom-confirm-dialog.component";
import { TrainUI } from "@shared/interfaces/trains-ui.interface";
import trainStations from "assets/utils/train-stations.json";
import { CountdownComponent, CountdownEvent } from "ngx-countdown";
import { Subscription } from "rxjs";
import swal from "sweetalert2";
import { TrainSearchComponent } from "../train-search/train-search.component";

@Component({
  selector: "app-train-search-result",
  templateUrl: "./train-search-result.component.html",
  styleUrls: ["./train-search-result.component.scss"],
})
export class TrainSearchResultComponent implements OnInit, OnDestroy {
  navigationExtras: any;
  trainsList: {
    outwardTrains: any[];
    returnTrains: any[];
  };
  showingTrainsReturn = false;
  filteredTrains: any[] = [];
  firstRequestDone = true;
  requestDone = true;
  userUuid: any;
  trainDates: any;
  subscriptions: Subscription[] = [];
  notOneWay: boolean;
  apiSession: string;
  trainUI: TrainUI = {};
  trainsGoingUI: TrainUI[] = [];
  trainsReturningUI: TrainUI[] = [];
  searchConfig: {
    departDate: any;
    returnDate: any;
    travelers: any[];
    origin: string;
    originText: string;
    destination: string;
    destinationText: string;
    travelCard: {
      value: string;
      viewValue: string;
    };
    oneWay: boolean;
  };
  trainStationList = [];
  departDate: Date;
  returnDate: Date;
  selectedClass: string;
  selectedIdx: number;
  selectedFareIdx: number;
  showFare: { [key: number]: boolean } = {};
  selectedClassFares: any[];
  renfeClasses = [
    {
      title: "Estandar",
      classCode: "T",
      perks: {
        included: [],
        notIncluded: [
          { title: "train.taxes.changes" },
          { title: "train.taxes.cancelation" },
          { title: "train.taxes.restoration" },
          { title: "train.taxes.lostTrain" },
          { title: "train.taxes.extraSeat" },
        ],
        extras: [
          { title: "train.taxes.selectSeat", description: "8 €" },
          { title: "train.taxes.pets", description: "20 €" },
          { title: "train.taxes.cancelAndChanges", description: "30 €" },
        ],
      },
    },
    {
      title: "Confort",
      classCode: "P",
      perks: {
        included: [
          {
            title: "train.taxes.selectSeat",
            description: "train.taxes.eligeSeat",
          },
          { title: "train.taxes.pets", description: "train.taxes.eligePets" },
          {
            title: "train.taxes.cancelAndChanges",
            description: "train.taxes.eligeCancel",
          },
        ],
        notIncluded: [],
        extras: [
          { title: "train.taxes.selectSeat", description: "5 €" },
          { title: "train.taxes.pets", description: "10 €" },
          { title: "train.taxes.cancelAndChanges", description: "20 €" },
          {
            title: "train.taxes.restoration",
            description: "train.taxes.extraPay",
          },
        ],
      },
    }
  ];
  confirmedSelection: any[] = [];
  selectedFare: any;
  groupFilters: any;
  oldSelection = { return: null, outward: null };
  outwardT: any;
  returnT: any;
  notAvailable = false;
  @ViewChild("countdownElem") countdown: CountdownComponent;
  complements = {
    ComplementosIda: null,
    ComplementosVuelta: null,
  };
  loading: boolean = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private utilsService: UtilsService,
    private trainService: TrainService,
    public translate: TranslateService,
    private ngxToastrService: NgxToastrService
  ) {
    this.navigationExtras = this.router.getCurrentNavigation().extras.state;
    if (this.navigationExtras != null) {
      this.apiSession = this.navigationExtras.apiSession;
      this.searchConfig = this.navigationExtras.searchConfig;
      this.notOneWay = this.searchConfig.oneWay === false;
      this.groupFilters = this.navigationExtras.groupFilters;
      this.updateDates();
      this.getTrainStations();
      this.trainsList = {
        outwardTrains: this.navigationExtras.trainsListResult.OutwardTrains,
        returnTrains: this.navigationExtras.trainsListResult.ReturnTrains,
      };
      this.userUuid = this.navigationExtras.userUuid;
    } else {
      this.router.navigate(["train"]);
    }
  }

  ngOnInit(): void {
/*     this.fixEligeFares(this.trainsList.outwardTrains);
 */    this.transformTrainList(
      this.trainsList["outwardTrains"],
      this.trainsGoingUI
    );
    if (this.trainsList.returnTrains?.length > 0) {
/*       this.fixEligeFares(this.trainsList.returnTrains);
 */      this.transformTrainList(
        this.trainsList["returnTrains"],
        this.trainsReturningUI
      );
    }
    this.filteredTrains = this.trainsGoingUI;
  }

  updateDates() {
    this.departDate = new Date(
      this.searchConfig.departDate.year,
      this.searchConfig.departDate.month,
      this.searchConfig.departDate.day
    );
    if (this.searchConfig.returnDate != null) {
      this.returnDate = new Date(
        this.searchConfig.returnDate.year,
        this.searchConfig.returnDate.month,
        this.searchConfig.returnDate.day
      );
    }
  }

  onTimerFinished(e: CountdownEvent) {
    if (e.action == "done") {
      this.translate
        .get([
          "train.search-result.timer.end",
          "train.search-result.timer.confirm",
        ])
        .subscribe((result) => {
          swal
            .fire({
              title: result["train.search-result.timer.end"],
              icon: "warning",
              confirmButtonColor: "#0CC27E",
              confirmButtonText: result["train.search-result.timer.confirm"],
              customClass: {
                confirmButton: "btn btn-success btn-raised",
              },
              buttonsStyling: false,
            })
            .then((action) => {
              this.changeSearchParams();
            });
        });
    }
  }

  changeTrainsReturn(filterReturnTrains: boolean, isConfirm = false) {
    this.cleanSelection();

    if (filterReturnTrains) {
      this.filteredTrains = this.trainsReturningUI;
      if (this.oldSelection.return != null) {
        this.selectedClass = this.oldSelection.return.selectedClass;
        this.selectedIdx = this.oldSelection.return.selectedIdx;
        this.selectedFareIdx = this.oldSelection.return.selectedFareIdx;
        this.selectedClassFares = this.oldSelection.return.selectedClassFares;
        this.showFare = this.oldSelection.return.showFare;
      }
      this.showingTrainsReturn = true;
    } else {
      this.filteredTrains = this.trainsGoingUI;
      if (this.oldSelection.outward != null) {
        this.selectedClass = this.oldSelection.outward.selectedClass;
        this.selectedIdx = this.oldSelection.outward.selectedIdx;
        this.selectedFareIdx = this.oldSelection.outward.selectedFareIdx;
        this.selectedClassFares = this.oldSelection.outward.selectedClassFares;
        this.showFare = this.oldSelection.outward.showFare;
      }
      this.showingTrainsReturn = false;
    }
  }

  changeSearchParams() {
    const searchDialog = this.dialog.open(TrainSearchComponent, {
      panelClass: "new-dialog",
      data: {
        isDialog: true,
        searchConfig: this.searchConfig,
      },
    });

    searchDialog.afterClosed().subscribe((result) => {
      if (result != null) {
        this.handleSearchSubmit(result);
      }
    });
  }

  cleanData() {
    this.trainsList = null;
    this.trainsGoingUI = [];
    this.trainsReturningUI = [];
    this.showingTrainsReturn = false;
    this.confirmedSelection = [];
    // this.trainReturning = null;
    // this.trainGoing = null;
    // this.return = false;
    // this.enable = false;
    // this.trainArr = [];
    // this.promoGoing = null;
    // this.promoRet = null;
  }

  onSearch(searchInput: any) {
    this.firstRequestDone = true;
    this.requestDone = false;
    this.utilsService.loadingTrains = true;
    const travelers = this.searchConfig.travelers;
    this.searchConfig = searchInput;
    this.searchConfig.travelers = travelers;
    const formResult = searchInput;
    const departDateMonth =
      formResult.departDate.month < 10
        ? `0${formResult.departDate.month}`
        : formResult.departDate.month;
    const departDateDay =
      formResult.departDate.day < 10
        ? `0${formResult.departDate.day}`
        : formResult.departDate.day;
    const departDate = `${formResult.departDate.year}${departDateMonth}${departDateDay}`;
    const dto: TrainSearchDto = {
      origin: formResult.origin,
      destination: formResult.destination,
      departDate: departDate,
      oneWay: formResult.oneWay != null ? formResult.oneWay : false,
      numberOfAdults:
        formResult.adults.length > 0
          ? formResult.adults.length.toString()
          : "1",
      plus50Card:
        formResult.travelCard === "plus50" || formResult.travelCard === "all"
          ? true
          : false,
      goldenCard:
        formResult.travelCard === "goldenCard" ||
          formResult.travelCard === "all"
          ? true
          : false,
    };
    dto["filters"] = formResult.filters;

    if (this.userUuid) {
      dto["userUuid"] = this.userUuid;
    }
    this.notOneWay = !dto.oneWay;

    if (formResult.children > 0) {
      dto["numberOfOlderChildren"] = formResult.children.length.toString();
    }
    if (formResult.infant > 0) {
      dto["numberOfChildrenUnder"] = formResult.infant.length.toString();
    }
    this.trainDates = {
      dateIn: departDate,
      dateOut: null,
    };
    if (formResult.returnDate && !formResult.oneWay) {
      const returnDateMonth =
        formResult.returnDate.month < 10
          ? `0${formResult.returnDate.month}`
          : formResult.returnDate.month;
      const returnDateDay =
        formResult.returnDate.day < 10
          ? `0${formResult.returnDate.day}`
          : formResult.returnDate.day;
      const returnDate = `${formResult.returnDate.year}${returnDateMonth}${returnDateDay}`;
      dto["returnDate"] = returnDate;
      this.trainDates.dateOut = returnDate;
    }

    this.subscriptions.push(
      this.trainService.postTrainRequestTrainMore(dto).subscribe(
        (s) => {
          this.cleanSelection();
          this.cleanData();
          this.oldSelection = { return: null, outward: null };
          this.trainsList = {
            outwardTrains: s.OutwardTrains,
            returnTrains: s.ReturnTrains,
          };

/*           this.fixEligeFares(this.trainsList.outwardTrains);
 */          this.apiSession = s.ApiSession;
          this.transformTrainList(
            this.trainsList["outwardTrains"],
            this.trainsGoingUI
          );
          if (this.trainsList.returnTrains?.length > 0) {
/*             this.fixEligeFares(this.trainsList.returnTrains);
 */            this.transformTrainList(
              this.trainsList["returnTrains"],
              this.trainsReturningUI
            );
          }

          if (this.showingTrainsReturn) {
            this.filteredTrains = this.trainsReturningUI;
          } else {
            this.filteredTrains = this.trainsGoingUI;
          }

          this.requestDone = true;
          this.countdown.restart();
          this.utilsService.loadingTrains = false;
          this.notAvailable = false;
        },
        (err) => {
          this.utilsService.loadingTrains = false;
          this.requestDone = true;
          this.trainsGoingUI = [];
          this.trainsReturningUI = [];
          if (err.status === 818) {
            this.subscriptions.push(
              this.translate
                .get(["train.trenymas.notAvailable"])
                .subscribe((result) => {
                  this.utilsService.loadingTrains = false;
                  this.ngxToastrService.typeInfo(
                    "Error",
                    result["train.trenymas.notAvailable"]
                  );
                })
            );
            this.notAvailable = true;
          } else {
            this.subscriptions.push(
              this.translate
                .get(["train.trenymas.messages.error"])
                .subscribe((result) => {
                  this.ngxToastrService.typeInfo(
                    "Error",
                    result["train.trenymas.messages.error"]
                  );
                  this.utilsService.loadingTrains = false;
                })
            );
          }
        }
      )
    );
  }

  transformTrainList(listFrom, listTo) {
    listFrom.forEach((t) => {
      this.trainUI.trainNumber = t.TrainNumber;
      this.trainUI.trainType = t.TrainType;
      this.trainUI.trainTypeDescription = t.TrainTypeDescription;
      this.trainUI.departureStationName = t.DepartureStationName;
      this.trainUI.arrivalStationName = t.ArrivalStationName;
      this.trainUI.departureTime = t.DepartureTime;
      this.trainUI.arrivalTime = t.ArrivalTime;
      this.trainUI.travelDuration = t.TravelDuration;
      this.trainUI.travelDurationString =
        Math.trunc(t.TravelDuration / 60).toString() +
        "h " +
        (t.TravelDuration % 60) +
        "m";
      this.trainUI.travelDate = t.TravelDate;
      this.trainUI.isAve = t.IsAVE;
      this.trainUI.priceTourist = [];
      this.trainUI.priceTouristPlus = [];
      this.trainUI.priceBusiness = [];

      t.Classes.forEach((c) => {
        if (c.ClassCode === "T") {
          c.SalesGroups.forEach((sg) => {
            sg.Cabins.forEach((cabin) => {
              cabin.Paxes.forEach((pax) => {
                pax.Fares.forEach((fare) => {
                  this.trainUI.priceTourist.push(fare.CurNetTa);
                });
              });
            });
          });
        } else if (c.ClassCode === "P") {
          c.SalesGroups.forEach((sg) => {
            sg.Cabins.forEach((cabin) => {
              cabin.Paxes.forEach((pax) => {
                pax.Fares.forEach((fare) => {
                  this.trainUI.priceTouristPlus.push(fare.CurNetTa);
                });
              });
            });
          });
        } else {
          c.SalesGroups.forEach((sg) => {
            sg.Cabins.forEach((cabin) => {
              cabin.Paxes.forEach((pax) => {
                pax.Fares.forEach((fare) => {
                  this.trainUI.priceBusiness.push(fare.CurNetTa);
                });
              });
            });
          });
        }
      });

      this.trainUI.priceTourist.sort((a, b) => {
        return a - b;
      });

      if (this.trainUI.priceTouristPlus) {
        this.trainUI.priceTouristPlus.sort((a, b) => {
          return a - b;
        });
      }
      if (this.trainUI.priceBusiness) {
        this.trainUI.priceBusiness.sort((a, b) => {
          return a - b;
        });
      }
      listTo.push(this.trainUI);
      this.trainUI = {};
    });
  }

  fixEligeFares(trains: OutwardTrain[]) {
    trains.forEach((train) => {
      const basicTrainsIndex = train.Classes.findIndex(
        (trainClass) => trainClass.ClassCode === "T"
      );
      const eligeTrainsIndex = train.Classes.findIndex(
        (trainClass) => trainClass.ClassCode === "P"
      );

      if (basicTrainsIndex > -1 && eligeTrainsIndex > -1) {
        const basicTrainsSalesGroups: SalesGroup[] =
          train.Classes[basicTrainsIndex].SalesGroups;

        const foundEligeInBasicIndex = basicTrainsSalesGroups.findIndex(
          (salesGroup) => salesGroup.SalesGroupCode === "X"
        );

        const eligeTrainsSalesGroups: SalesGroup[] =
          train.Classes[eligeTrainsIndex].SalesGroups;

        if (foundEligeInBasicIndex > -1) {
          train.Classes[eligeTrainsIndex].SalesGroups.unshift(
            train.Classes[basicTrainsIndex].SalesGroups[foundEligeInBasicIndex]
          );
          train.Classes[basicTrainsIndex].SalesGroups.splice(
            foundEligeInBasicIndex,
            1
          );
        }

        const foundPremiumInEligeIndex = eligeTrainsSalesGroups.findIndex(
          (salesGroup) => salesGroup.SalesGroupCode === "Y"
        );

        if (foundPremiumInEligeIndex > -1) {
          if (train.Classes[train.Classes.length] == null) {
            train.Classes[train.Classes.length] = {
              SalesGroups: [],
              ClassCode: "B",
              ClassName: "Premium",
              RemainingSeats: train.Classes[eligeTrainsIndex].RemainingSeats,
            };
          }
          train.Classes[train.Classes.length - 1].SalesGroups.push(
            train.Classes[eligeTrainsIndex].SalesGroups[
            foundPremiumInEligeIndex
            ]
          );
          train.Classes[eligeTrainsIndex].SalesGroups.splice(
            foundPremiumInEligeIndex,
            1
          );
        }
      }
    });

    return trains;
  }

  handleSearchSubmit(searchInput: any) {
    const searchInputCopy = JSON.parse(JSON.stringify(searchInput));
    searchInputCopy.adults = [];
    searchInputCopy.children = [];
    searchInputCopy.infants = [];
    this.searchConfig = JSON.parse(JSON.stringify(searchInput));

    searchInputCopy.travelers.forEach((traveler) => {
      if (traveler.age >= 13) {
        searchInputCopy.adults.push(traveler);
      } else if (traveler.age < 13 && traveler.age >= 4) {
        searchInputCopy.children.push(traveler);
      } else {
        searchInputCopy.infants.push(traveler);
      }
    });
    delete searchInputCopy.travelers;
    this.getTrainStations();
    this.updateDates();
    this.onSearch(searchInputCopy);
  }

  cleanSelection() {
    this.selectedClass = null;
    this.selectedIdx = null;
    this.selectedFareIdx = null;
    this.selectedClassFares = [];
    this.showFare = {};
    this.complements.ComplementosIda = null;
    this.complements.ComplementosVuelta = null;
  }

  openTrainDialog(trainIndex: number, classCode: string) {
    this.cleanSelection();
    let selectedTrain;
    if (this.showingTrainsReturn) {
      selectedTrain = this.trainsList.returnTrains[trainIndex];
    } else {
      selectedTrain = this.trainsList.outwardTrains[trainIndex];
    }
    const sgListIndex = selectedTrain.Classes.findIndex(
      (trainClass) => trainClass.ClassCode === classCode
    );
    if (sgListIndex > -1) {
      this.selectedClass = classCode;
      this.showFare[trainIndex] = true;
      this.selectedIdx = trainIndex;

      const sgList: SalesGroup[] =
        selectedTrain.Classes[sgListIndex].SalesGroups;
      sgList.forEach((sg) => {
        sg.Cabins.forEach((cabin) => {
          cabin.Paxes.forEach((pax) => {
            pax.Fares.forEach((fare) => {
              const promo = {
                name: fare.FareName,
                price: fare.CurNetTa,
                code: sg.SalesGroupCode,
                paxType: pax.PaxType,
                class: classCode,
                id:
                  selectedTrain.TrainTypeDescription +
                  "" +
                  selectedTrain.TrainNumber,
                departTime: selectedTrain.DepartureTime,
                arrivalTime: selectedTrain.ArrivalTime,
                travelDate: selectedTrain.TravelDate,
                departureStationName: selectedTrain.DepartureStationName,
                arrivalStationName: selectedTrain.ArrivalStationName,
                fareConditions: classCode ? this.getFareCondition(classCode) : "",
              };
              this.selectedClassFares.push(promo);
            });
          });
        });
      });
      this.selectedClassFares.sort((a, b) => {
        return a.price - b.price;
      });
      if (this.selectedClassFares.length === 1) {
        this.selectFare(0, this.selectedClassFares[0]);
      }
      
      // if (!this.return) {
      //   this.classCodeG = classCode;
      //   this.outwardId = `${train.TrainTypeDescription + train.TrainNumber}`;
      // } else {
      //   this.classCodeR = classCode;
      //   this.retId = `${train.TrainTypeDescription + train.TrainNumber}`;
      // }
    }
  }

  getFareCondition(fareClass) {
    let fareConditions;
    this.renfeClasses.forEach((renfeClass) => {
      if (renfeClass.classCode === fareClass) {
        fareConditions = renfeClass.perks
      }
    })
    return fareConditions;
  }

  selectFare(index: number, fare: any) {
    this.loading = true;
    this.selectedFareIdx = index;
    this.selectedFare = fare;
    if (this.showingTrainsReturn === false) {
      if (this.selectedClass != null) {
        this.oldSelection["outward"] = {
          selectedClass: this.selectedClass,
          selectedIdx: this.selectedIdx,
          selectedFareIdx: this.selectedFareIdx,
          selectedClassFares: this.selectedClassFares,
          showFare: this.showFare,
        };
      }
    } else {
      if (this.selectedClass != null) {
        this.oldSelection["return"] = {
          selectedClass: this.selectedClass,
          selectedIdx: this.selectedIdx,
          selectedFareIdx: this.selectedFareIdx,
          selectedClassFares: this.selectedClassFares,
          showFare: this.showFare,
        };
      }
    }
    this.outwardT =
        this.oldSelection.outward.selectedClassFares[
          this.oldSelection.outward.selectedFareIdx
        ];
        if (this.notOneWay && this.oldSelection.return != null) {
          this.returnT =
            this.oldSelection.return.selectedClassFares[
              this.oldSelection.return.selectedFareIdx
            ];
          if (this.outwardT.code === "X") {
            this.outwardT.class = "T";
          } else if (this.outwardT.code === "Y") {
            this.outwardT.class = "P";
          }
          if (this.returnT.code === "X") {
            this.returnT.class = "T";
          } else if (this.returnT.code === "Y") {
            this.returnT.class = "P";
          }
        }

      const classes = this.trainsList.outwardTrains.find((t) => this.outwardT.id.includes(t.TrainNumber)).Classes;
      let classCode;

      for (let c of classes) {
        for (let t of c.SalesGroups) {
          for (let s of t.Cabins) {
            for (let p of s.Paxes) {
              for (let f of p.Fares) {
                if (f.CurNetTa == this.outwardT.price) {
                  classCode = c.ClassCode;
                  if (classCode) break;
                }
              }
            }
          }
        }
      }

      const body = {
        apiSession: this.apiSession,
        classCodeOutward: classCode,
        classSalesGroupCodeOutward: this.outwardT.code,
        outwardId: this.outwardT.id,
      };
      if (this.oldSelection.return != null) {
        body["classCodeReturn"] = this.returnT.class;
        body["classSalesGroupCodeReturn"] = this.returnT.code;
        body["retId"] = this.returnT.id;
      }
      this.complements.ComplementosIda = null;
      this.complements.ComplementosVuelta = null;
      this.trainService.getTrainsComplements(body).subscribe(
        (res) => {
          this.loading= false;
          this.complements = res;
        },
        (err) => {
          this.loading= false;
        })
  }

  getTrainStations() {
    trainStations.Table1.forEach((e) => {
      if (
        e.CODIGO_PROVEEDOR === this.searchConfig.origin ||
        e.CODIGO_PROVEEDOR === this.searchConfig.destination
      ) {
        this.trainStationList.push({
          title: e.NOMBRE,
          value: e.CODIGO_PROVEEDOR,
        });
      }
    });
  }

  parseTrainCodeToName(code: string) {
    return this.trainStationList.find((station) => station.value === code)
      .title;
  }

  getClassName() {
    return this.renfeClasses.filter(
      (renfeClass) => renfeClass.classCode === this.selectedClass
    )[0].title;
  }

  getRenfePerks() {
    return this.renfeClasses.filter(
      (renfeClass) => renfeClass.classCode === this.selectedClass
    )[0].perks;
  }

  onAccept() {
    if (this.canConfirm()) {
      this.outwardT =
        this.oldSelection.outward.selectedClassFares[
        this.oldSelection.outward.selectedFareIdx
        ];
      let canBook = true;
      if (this.notOneWay || this.oldSelection.return != null) {
        this.returnT =
          this.oldSelection.return.selectedClassFares[
          this.oldSelection.return.selectedFareIdx
          ];
        if (this.outwardT.code === "X") {
          this.outwardT.class = "T";
        } else if (this.outwardT.code === "Y") {
          this.outwardT.class = "P";
        }
        if (this.returnT.code === "X") {
          this.returnT.class = "T";
        } else if (this.returnT.code === "Y") {
          this.returnT.class = "P";
        }
        if (
          (this.notOneWay &&
            this.outwardT.code === "I" &&
            this.outwardT.code !== this.returnT.code) ||
          (this.notOneWay &&
            this.returnT.code === "I" &&
            this.returnT.code !== this.outwardT.code)
        ) {
          canBook = false;
          this.subscriptions.push(
            this.translate
              .get("train.search-result.adulto-ida-y-vuelta")
              .subscribe((res) => {
                this.ngxToastrService.typeInfo("", res);
              })
          );
        }
      }
      if (canBook === true) {
        const body = {
          apiSession: this.apiSession,
          classCodeOutward: this.outwardT.class,
          classSalesGroupCodeOutward: this.outwardT.code,
          outwardId: this.outwardT.id,
        };
        if (this.oldSelection.return != null) {
          body["classCodeReturn"] = this.returnT.class;
          body["classSalesGroupCodeReturn"] = this.returnT.code;
          body["retId"] = this.returnT.id;
        }
        this.utilsService.loadingTrains = true;
        this.confirmedSelection = [this.outwardT, this.returnT];
        if (this.groupFilters.chooseTrainSeat === true && ((this.complements.ComplementosIda && this.complements.ComplementosIda[0].complements.find((c) => c.code === 'SEAT')) || (this.complements.ComplementosVuelta && this.complements.ComplementosVuelta[0].complements.find((c) => c.code === 'SEAT')))) {
          this.trainService.getGraph(body).subscribe(
            (graphRes) => {
              // Tiene asientos reales para seleccionar
              if (
                graphRes.Errors == null &&
                graphRes.ida.Errors == null &&
                graphRes.vuelta.Errors == null
              ) {
                this.utilsService.loadingTrains = false;
                const navigationExtras: NavigationExtras = {
                  state: {
                    searchConfig: this.searchConfig,
                    trainsComplements: this.complements,
                    graphRes: graphRes,
                    seatSelection: false,
                    trainsList: this.trainsList,
                    apiSession: this.apiSession,
                    groupFilters: this.groupFilters,
                    trainStationList: this.trainStationList,
                    confirmedSelection: this.confirmedSelection,
                    uuidUser: this.userUuid,
                  },
                };
                const seatSelectionPrompt = this.dialog.open(
                  CustomConfirmDialogComponent,
                  {
                    panelClass: "custom-confirm-dialog",
                    data: {
                      title: "train.seatPrompt.title",
                      subtitle: "train.seatPrompt.subtitle",
                      cancelText: "train.seatPrompt.cancelText",
                      confirmText: "train.seatPrompt.confirmText",
                    },
                  }
                );
                seatSelectionPrompt
                  .afterClosed()
                  .subscribe((result) => {
                    // Quiere seleccionar asientos
                    if (result != null && result === true) {
                      navigationExtras["state"]["seatSelection"] = true;
                      this.router.navigateByUrl(
                        "train/confirm-form",
                        navigationExtras
                      );
                    } else {
                      // NO quiere seleccionar asientos
                      this.goToConfirm(null, false);
                    }
                  });
              } else {
                // Falla obtencion de asientos de Renfe
                this.goToConfirm(null, false);
              }
            },
            (graphErr) => {
              // Falla obtencion de asientos de Renfe
              this.goToConfirm(null, false);
            }
          );
        } else {
          this.goToConfirm(null, false);
        }
      }
    } else {
      if (this.selectedFare.code === "X") {
        this.selectedFare.class = "T";
      } else if (this.selectedFare.code === "Y") {
        this.selectedFare.class = "P";
      }
      if (this.showingTrainsReturn) {
        this.confirmedSelection.push(this.selectedFare);
        this.changeTrainsReturn(false, true);
      } else {
        this.confirmedSelection.splice(0, 0, this.selectedFare);
        this.changeTrainsReturn(true, true);
      }
    }
  }

  handleError(res: any) {
    const seatSelectionPrompt = this.dialog.open(CustomConfirmDialogComponent, {
      panelClass: "custom-confirm-dialog",
      data: {
        title: "train.seatPrompt.title",
        subtitle: "train.seatPrompt.subtitle",
        cancelText: "train.seatPrompt.cancelText",
        confirmText: "train.seatPrompt.confirmText",
      },
    });
    seatSelectionPrompt.afterClosed().subscribe(async (result) => {
      if (result != null) {
        if (result === false) {
          // NO quiere seleccionar asientos
          this.goToConfirm(null, false);
        } else {
          // Quiere seleccionar asientos
          const letItGoRes = await this.letItGo(res);
          this.goToConfirm(letItGoRes, true);
        }
      } else {
        // Do nothing
        this.utilsService.loadingTrains = false;
      }
    });
  }

  async letItGo(trainComplements?: any) {
    if (trainComplements == null) {
      trainComplements = await import(
        "assets/utils/mockup/train-getTrainsComplements-mockup.json"
      );
      trainComplements = trainComplements.default;
      // this.outwardSeatPrice =
      //   trainComplements.ComplementosIda[0].complements[3].price;
      // this.returnSeatPrice =
      //   trainComplements.ComplementosIda[0].complements[3].price;
    }
    let graphRes: any = await import(
      "assets/utils/mockup/train-getGraph-mockup.json"
    );
    graphRes = graphRes.default;
    this.utilsService.loadingTrains = false;
    return { trainComplements, graphRes };
  }

  goToConfirm(letItGoRes: any, seatSelection: boolean) {
    if (
      letItGoRes == null ||
      letItGoRes?.graphRes == null ||
      seatSelection == false
    ) {
      this.utilsService.loadingTrains = true;
      const outwardT =
        this.oldSelection.outward.selectedClassFares[
        this.oldSelection.outward.selectedFareIdx
        ];
      const bookBody = {
        apiSession: this.apiSession,
        outwardId: outwardT.id,
        classCodeOutward: outwardT.class,
        classSalesGroupCodeOutward: outwardT.code,
        fee: "0",
        markup: "0",
        userUuid: this.userUuid,
      };
      let returnT;
      if (
        this.searchConfig.oneWay === false &&
        this.oldSelection.return != null
      ) {
        returnT =
          this.oldSelection.return.selectedClassFares[
          this.oldSelection.return.selectedFareIdx
          ];
        bookBody["retId"] = returnT.id;
        bookBody["classCodeReturn"] = returnT.class;
        bookBody["classSalesGroupCodeReturn"] = returnT.code;
      }
      const confirmedSelection = [outwardT, returnT];
      const navigationExtras: any = {
        state: {
          searchConfig: this.searchConfig,
          trainsComplements: null,
          graphRes: null,
          seatSelection: false,
          trainsList: this.trainsList,
          apiSession: this.apiSession,
          groupFilters: this.groupFilters,
          trainStationList: this.trainStationList,
          confirmedSelection: confirmedSelection,
          uuidUser: this.userUuid,
        },
      };
      this.subscriptions.push(
        this.trainService.postTrainBookTrainMore(bookBody).subscribe(
          (res) => {
            this.utilsService.loadingTrains = false;
            navigationExtras["state"]["amount"] = res.AmountToPay;
            this.router.navigateByUrl("train/confirm-form", navigationExtras);
          },
          async (err) => {
            if (err.status === 403 && err.error && err.error.message === "Su empresa no dispone de una tarjeta válida, contacte con su administrador") {
              this.translate.get(["common.card-permission-error"])
                .subscribe((value) =>
                  this.ngxToastrService.typeInfo(null, value["common.card-permission-error"])
                );
              this.utilsService.loadingTrains = false;
            } else {
              const letItGoRes = await this.letItGo();
              navigationExtras.state.bookFailed = true;
              navigationExtras.trainsComplements = letItGoRes.trainComplements;
              navigationExtras.graphRes = letItGoRes.graphRes;
              this.utilsService.loadingTrains = false;
              // navigationExtras["state"]["amount"] = res.AmountToPay;
              this.router.navigateByUrl("train/confirm-form", navigationExtras);
            }
          }
        )
      );
    } else {
      const navigationExtras: NavigationExtras = {
        state: {
          searchConfig: this.searchConfig,
          trainsComplements: letItGoRes.trainComplements,
          graphRes: letItGoRes.graphRes,
          seatSelection: seatSelection,
          trainsList: this.trainsList,
          apiSession: this.apiSession,
          groupFilters: this.groupFilters,
          trainStationList: this.trainStationList,
          confirmedSelection: this.confirmedSelection,
          uuidUser: this.userUuid,
        },
      };
      this.utilsService.loadingTrains = false;
      // navigationExtras["state"]["amount"] = res.AmountToPay;
      this.router.navigateByUrl("train/confirm-form", navigationExtras);
    }
  }

  isSelected(train: any, fare: string) {
    const selectedTrain =
      this.confirmedSelection[this.showingTrainsReturn ? 1 : 0];
    const trainName = train.trainTypeDescription + "" + train.trainNumber;
    if (selectedTrain?.id === trainName && selectedTrain?.class === fare) {
      // return true;
    }
    return false;
  }

  canConfirm() {
    let canConfirm = false;
    if (!this.notOneWay) {
      return true;
    } else {
      if (
        this.oldSelection.outward != null &&
        this.oldSelection.return != null
      ) {
        canConfirm = true;
      }
      return canConfirm;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
