import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Plan } from "@core/models/plan";
import {
  CompanyPlanService,
  NgxToastrService,
  UtilsService,
} from "@core/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Subscription } from "rxjs";
import swal from "sweetalert2";
import { PlanFormComponent } from "./plan-form/plan-form.component";

@Component({
  selector: "app-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.scss"],
})
export class PlanComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  plans: Plan[];
  filtered: Plan[];
  sortOptions = [
    {
      displayName: "plans.sortOptions.alphabetically",
      value: "alphabetically",
    },
    {
      displayName: "plans.sortOptions.dateAsc",
      value: "dateAsc",
    },
    {
      displayName: "plans.sortOptions.dateDesc",
      value: "dateDesc",
    },
    {
      displayName: "plans.sortOptions.price",
      value: "price",
    },
  ];
  @ViewChild("searchPlanInput") searchPlanInput: any;
  @ViewChild("selectFilter") selectFilter: any;

  constructor(
    private planService: CompanyPlanService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    public utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.getPlans();
  }

  searchPlan(term: string) {
    this.plans = this.filtered.filter((plan) =>
      plan.name?.toLowerCase().includes(term?.toLowerCase())
    );
  }

  getRandomColorFromArray() {
    const colorsArr = [
      "#bccbcb",
      "#8a99fc",
      "#4cbecb",
      "#f69977",
      "#f8d05e",
      "#b694fb",
      "#f46f69",
      "#37d197",
      "#205954",
      "#ef82d2",
    ];

    return colorsArr[Math.floor(Math.random() * colorsArr.length)];
  }

  getPlans() {
    this.subscriptions.push(
      this.planService.getPlans().subscribe((res) => {
        this.plans = res;
        this.filtered = res;
        this.onSortSelected("alphabetically");
      })
    );
  }

  onNewPlan() {
    const modalRef = this.modalService.open(PlanFormComponent, {
      size: "lg",
      centered: true,
      windowClass: "custom-modal",
    });
    this.subscriptions.push(
      modalRef.componentInstance.sendInfo.subscribe((info) => {
        this.planService.postPlan(info).subscribe(() => this.getPlans());
      })
    );
  }

  onEdit(plan: Plan, uuid) {
    const modalRef = this.modalService.open(PlanFormComponent, {
      size: "lg",
      centered: true,
      windowClass: "custom-modal",
    });
    modalRef.componentInstance.plan = plan;
    this.subscriptions.push(
      modalRef.componentInstance.sendInfo.subscribe((info) => {
        this.planService.putPlan(info, uuid).subscribe(() => this.getPlans());
      })
    );
  }

  onDelete(uuid) {
    this.subscriptions.push(
      this.planService.getPlanCompanies(uuid).subscribe((companies) => {
        if (companies.length <= 0) {
          this.translate
            .get([
              "plans.delete-title",
              "plans.delete-text",
              "common.confirm",
              "common.cancel",
            ])
            .subscribe((result) => {
              swal
                .fire({
                  title: result["plans.delete-title"],
                  text: result["plans.delete-text"],
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#0CC27E",
                  cancelButtonColor: "#FF586B",
                  confirmButtonText: result["common.confirm"],
                  cancelButtonText: result["common.cancel"],
                  customClass: {
                    confirmButton: "btn btn-success btn-raised",
                    cancelButton: "btn btn-danger btn-raised mr-5",
                  },
                  buttonsStyling: false,
                })
                .then((action) => {
                  if (action.value) {
                    this.subscriptions.push(
                      this.planService
                        .deletePlan(uuid)
                        .subscribe(() => this.getPlans())
                    );
                  }
                });
            });
        } else {
          this.translate.get("plans.delete-error").subscribe((resp) => {
            this.ngxToastrService.typeInfo(null, resp);
          });
        }
      })
    );
  }

  onSortSelected(value) {
    switch (value) {
      case "alphabetically":
        this.plans = this.plans.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case "dateAsc":
        this.plans = this.plans.sort((a, b) =>
          moment(b.createdAt).isBefore(moment(a.createdAt)) ? 1 : -1
        );
        break;
      case "dateDesc":
        this.plans = this.plans.sort((a, b) =>
          moment(b.createdAt).isAfter(moment(a.createdAt)) ? 1 : -1
        );
        break;
      case "price":
        this.plans = this.plans.sort((a, b) => (a.price < b.price ? 1 : -1));
        break;
    }
  }

  getInitials(planName: string) {
    const nameArr = planName.split(" ");
    let initials = "";
    nameArr.forEach((name) => {
      initials += name.charAt(0);
    });
    if (nameArr.length > 2) {
      initials = initials.charAt(0) + initials.charAt(initials.length - 1);
    }
    return initials;
  }

  removeFilters() {
    this.plans = this.filtered;
    this.searchPlanInput.nativeElement.value = null;
    this.selectFilter.nativeElement.value = "alphabetically";
    this.onSortSelected("alphabetically");
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
