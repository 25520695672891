<div class="row">
  <div class="col-12">
    <div class="media profil-cover-details row">
      <div class="col-12 text-center" *ngIf="image">
        <div class="align-self-center halfway-fab text-center mt-2 mb-2">
          <img src="{{ image }}" class="rounded-circle img-border"
            style="width: 200px; height: 200px; object-fit: contain" alt="Agency logo" />
        </div>
      </div>
    </div>

    <div class="col-12 department mb-3" *ngIf="showAgencyAdmins">
      <div class="row col-12 header-department actions" style="align-items: center;">
        <p class="col-12 group-name" (click)="toggleContent()" style="display: flex;">
          <span style="padding-right: 0.6rem;">{{ "common.agency-workers" | translate }}</span>
          <img _ngcontent-iib-c284="" src="assets/img/svg/arrow-down.svg" [style.transform]="showContent ? '' : 'rotate(180deg)'" style="margin-left: auto; cursor: pointer;">
        </p>
      </div>
      <div class="col-12 invitation-content" [ngClass]="{'hidden-content': !showContent}" *ngIf="usersAgency && usersAgency.length !== 0">
        <ng-container>
          <div class="col-12 users">
            <div class="users-header row col-12">
              <div class="profile-picture"></div>
              <div class="user-id">
                {{ "company.company-detail.id" | translate }}
              </div>
              <div class="user-name">{{ "common.name" | translate }}</div>
              <div class="user-status">{{ "common.status" | translate }}</div>
              <div class="user-bills">
                <span class="text-center">{{ "common.accumulated-expense" | translate }}</span>
              </div>
              <div class="user-projects">
                <span class="text-center">{{ "common.projects" | translate }}</span>
              </div>
              <div class="menu-btn"></div>
              <div class="user-actions"></div>
            </div>
          
            <div class="users-container row col-12" *ngFor="let user of usersAgency; let index = i">
              <div class="profile-picture">
                <ng-container *ngIf="user.image; else elseTemplate">
                  <div class="user-image">
                    <img src="{{ user.image }}" />
                  </div>
                </ng-container>
                <ng-template #elseTemplate>
                  <div class="user-image">
                    <div class="profile-initials text-white">
                      {{ user.name[0] }}{{ user.lastname[0] }}
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="user-id">
                {{ user.employeeNumber ? user.employeeNumber : "N/A" }}
              </div>
              <div class="user-name">{{ user.name }} {{ user.lastname }}</div>
              <div class="user-status">
                <mat-slide-toggle [disabled]="!isAdmin"
                  (click)="isAdmin || isCompany ? changeActive(user) : ''" [checked]="user.active"></mat-slide-toggle>
              </div>
              <div class="user-bills">
                {{ user.totalExpenses | price }}
              </div>
              <div class="user-projects">
                {{ user.numProjects | number }}
              </div>
    
              <div ngbDropdown [disabled]="dropdownHabilitado" placement="left" class="menu-btn cursor-pointer">
                <!-- <p ngbDropdownToggle [disabled]="dropdownHabilitado">
                  <img src="assets/img/svg/add-booking-circle.svg" />
                  {{ "common.create-booking" | translate }}
                </p>
                <div ngbDropdownMenu role="menu" class="dropdown-menu" aria-labelledby="dropdownBasic2">
                  <p *ngIf="
                      userType === 'EMPLOYEE'
                    ">
                    {{ "common.hotel" | translate }}
                  </p>
                  <p *ngIf="userType === 'EMPLOYEE'">
                    {{ "common.custom-hotel" | translate }}
                  </p>
                  <p *ngIf="
                      isAmadeusEnabled &&
                      ((userType !== 'EMPLOYEE' &&
                        authService.userCompanyPlan &&
                        authService.userCompanyPlan.permissions
                          .canRequestAutomatizedBookings) ||
                        isSuperAdmin)
                    ">
                    {{ "company.company-detail.flight" | translate }}
                  </p>
                  <p *ngIf="userType === 'EMPLOYEE'">
                    {{ "company.company-detail.specialFlight" | translate }}
                  </p>
                  <p *ngIf="
                      (userType !== 'EMPLOYEE' &&
                        authService.userCompanyPlan &&
                        authService.userCompanyPlan.permissions
                          .canRequestAutomatizedBookings) ||
                      isSuperAdmin
                    ">
                    {{ "company.company-detail.train" | translate }}
                  </p>
                  <p *ngIf="userType === 'EMPLOYEE'">
                    {{ "company.company-detail.custom-train" | translate }}
                  </p>
                  <p *ngIf="
                      userType === 'EMPLOYEE' &&
                      authService.userCompanyPlan &&
                      authService.userCompanyPlan.permissions
                        .canRequestCustomBookings
                    " (click)="adminFormCreateCar(user.uuid)">
                    {{ "company.company-detail.custom-car" | translate }}
                  </p>
                  <p *ngIf="userType === 'EMPLOYEE'">
                    {{ "expenses.title" | translate }}
                  </p>
                  <p *ngIf="userType !== 'EMPLOYEE'">
                    {{ "expenses.title" | translate }}
                  </p>
                </div> -->
              </div>
    
              <div class="user-actions" ngbDropdown placement="bottom-right">
                <img class="pointer" src="assets/img/svg/black-kebab.svg" ngbDropdownToggle/>
                <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic3">
                  <!-- <p (click)="seeUserBooked(user.uuid)">
                    <img src="assets/img/svg/black-bonus.svg" />
                    {{ "common.see-booked" | translate }}
                  </p> -->
                  <div class="menu-btn pointer" (click)="
                  openNewGraphModal(
                        user.name + ' ' + user.lastname,
                        'user',
                        'all',
                        user.uuid
                      )
                    ">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/bar-chart.svg"/>
                    </div>
                    <p class="menu-btn pointer" >
                      {{ "company.company-detail.see-graphics" | translate }}
                    </p>
                  </div>
                  <div class="menu-btn pointer" (click)="openUserStorage(user)">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/cloud.svg" />
                    </div>
                    <p>
                      {{ "common.see-documents" | translate }}
                    </p>
                  </div>
                  <div class="menu-btn pointer" (click)="onEditUser(user, group)">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/pen-black.svg" />
                    </div>
                    <p>
                      {{ "user.form.edit-user" | translate }}
                    </p>
                  </div>
                  <div class="menu-btn pointer" (click)="onDeleteUser(user)">
                    <div class="icon-bcg icon-bcg--small">
                      <img src="assets/img/svg/trash-black.svg" />
                    </div>
                    <p>
                      {{
                        "project.my-projects.delete-user.delete-user"
                          | translate
                      }}
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-12 invitation-content" [ngClass]="{'hidden-content': !showContent}" *ngIf="usersAgency && usersAgency.length === 0">
        <p style="font-weight: 500;">{{ "common.no-agency-workers" | translate }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-sm-12">
        <br />
        <h2 class="heading-4">
          {{ "company.company-homepage.companys" | translate }}
        </h2>
      </div>
      <div class="col-lg-8 col-sm-12">
        <br />
        <div class="row navbar-create-search buttons">
          <div class="navbar-right mt-1 mr-2 width-250" role="search">
            <div class="has-icon-right">
              <input [value]="search" #company_string type="text" class="form-control pr-3 search-input" placeholder="{{
                  'company.company-homepage.search' | translate
                }}" (keyup)="dispatchSearchAction(company_string.value)" />
            </div>
          </div>
          <div ngbDropdown placement="bottom-right" class="button custom-btn custom-btn--black">
            <a id="dropdownBasic1" ngbDropdownToggle type="button">
              {{ "common.order" | translate }}
              <img class="dropdown-arrow" src="assets/img/svg/arrow-down-white.svg" />
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu">
              <button (click)="orderBy('alphabetic')" class="dropdown-item text-center">
                {{ "common.alphabetic" | translate }}
              </button>
              <button (click)="orderBy('creation-desc')" class="dropdown-item text-center">
                {{ "common.creation-desc" | translate }}
              </button>
              <button (click)="orderBy('creation-asc')" class="dropdown-item text-center">
                {{ "common.creation-asc" | translate }}
              </button>
            </div>
          </div>
          <a class="button custom-btn" (click)="onNew()">
            <i class="ft-plus"></i>
            {{ "company.company-homepage.create-company" | translate }}
          </a>
          <a class="button custom-btn custom-btn--yellow" *ngIf="!fullCompaniesPage" (click)="onNewUser()">
            {{ "agency.create-admin" | translate }}
          </a>
          <a class="button custom-btn custom-btn--yellow"
            *ngIf="fullCompaniesPage && userType === 'AGENCY'" (click)="onEditAgency()">
            {{ "agency.edit-admin" | translate }}
          </a>
          <!-- Comentado para que de momento no puedan acceder -->
          <div ngbDropdown class="button custom-btn custom-btn--black" placement="bottom-right"
            *ngIf="fullCompaniesPage && userType === 'EMPLOYEE'">
            <a ngbDropdownToggle type="button" [matBadgeHidden]="
                !totalRequests &&
                !totalAuthorizations &&
                !totalPendingFlights &&
                !(totalAuthorizations + totalRequests + totalPendingFlights > 0)
              " [matBadge]="totalRequests + totalAuthorizations + totalPendingFlights" matBadgePosition="after" matBadgePosition="above"
              matBadgeColor="#f69977"
              matBadgeSize="medium">{{ "common.see" | translate }}
              <img class="dropdown-arrow" src="assets/img/svg/arrow-down-white.svg" /></a>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic1">
              <!-- <p (click)="seeBookeds()">
                {{ "company.company-detail.see-booked" | translate }}
              </p> -->
              <p (click)="goToRequests()">
                {{ "company.company-detail.request" | translate }}
                <span *ngIf="totalRequests && totalRequests > 0" class="notification">{{ totalRequests }}</span>
              </p>
              <p (click)="goToAuthorizations()">
                {{ "common.authorizations" | translate }}
                <span *ngIf="totalAuthorizations && totalAuthorizations > 0"
                  class="notification">{{ totalAuthorizations }}</span>
              </p>
              <p (click)="goPendings()">
                {{ "company.company-homepage.pendings" | translate }}
                <span *ngIf="totalPendingFlights && totalPendingFlights > 0"
                  class="notification">{{ totalPendingFlights }}</span>
              </p>
            </div>
          </div>
          <!-- TODO: eliminar el bloque comentado de abajo una vez comprobemos que todo funciona con el nuevo botón VER en superadmin -->            
          <!-- <a (click)="seeRequests()" *ngIf="this.userType === 'EMPLOYEE'"
            class="btn btn-vyoo-primary round-1 text-white mt-1 mr-2" [matBadgeHidden]="
              !totalRequests &&
              !totalAuthorizations &&
              !(totalAuthorizations + totalRequests > 0)
            " [matBadge]="totalRequests + totalAuthorizations" matBadgePosition="after" matBadgePosition="above"
            matBadgeColor="#F69977" matBadgeSize="medium">
            <i class="ft-list"></i>
            {{ "company.company-detail.authorizations" | translate }}
          </a> -->
          <!-- <div ngbDropdown placement="bottom-right" class="d-inline-block">
            <button class="btn btn-outline-vyoo-primary ml-1 mt-1" id="dropdownBasic2" ngbDropdownToggle>
              <i class="ft-bar-chart-2"></i> {{ "common.graphics" | translate }}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
              <button (click)="openGraphModal('Admin', whoAmI, 'all')" class="dropdown-item text-center">
                {{ "graph.amount-expense" | translate }}
              </button>
              <button (click)="openGraphModal('Admin', whoAmI, 'service')" class="dropdown-item text-center">
                {{ "graph.service-statistics" | translate }}
              </button>
              <button class="dropdown-item text-center" *ngIf="userType === 'EMPLOYEE' && fullCompaniesPage"
                (click)="goToDashboard()">
                {{ "dashboard.title" | translate }}
              </button>
              <button class="dropdown-item text-center" (click)="exportExcel()">
                {{ "common.download-report" | translate }}
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <!-- Empty state empresas -->
    <div class="text-center mt-5" *ngIf="companies.length <= 0">
      <img src="assets/img/gallery/empty-companies.svg" width="300" height="300" alt="No hay archivos" />
      <h3 class="mt-4">
        <b>{{ "company.company-homepage.no-company" | translate }}</b>
      </h3>
      <span>{{
        "company.company-homepage.create-company-task" | translate
      }}</span>
    </div>

    <!-- Compañías -->
    <div class="row" *ngIf="companies.length > 0">
      <div class="col-12">
        <div class="row">
          <div *ngFor="
              let company of companies | search: 'name':search;
              trackBy: utilsService.getUuidTracking
            " class="col-xl-4 col-md-6 col-12">
            <app-company-card [company]="company" (reloadCompanies)="loadCompanies()"></app-company-card>
          </div>
          <div *ngIf="(companies | search: 'name':search).length === 0"
            class="col-12 my-5 d-flex justify-content-center">
            <div class="icon-bcg icon-bcg--large">
              <img src="assets/img/svg/empty-search.svg" alt="emptySearch" class="empty-search" />
            </div>
            <h2 class="ml-3 my-auto">{{ "search.not-data" | translate }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>