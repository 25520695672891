import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, NgModel, Validators } from "@angular/forms";
import {
  NgbActiveModal,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxToastrService, TravelService, UtilsService } from "@services";
import * as moment from "moment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-create-travel-modal",
  templateUrl: "./create-travel-modal.component.html",
  styleUrls: ["./create-travel-modal.component.scss"],
})
export class CreateTravelModalComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  formSelect: UntypedFormGroup;
  subcriptions: Subscription[] = [];

  showCreate = true;
  showEdit = false;

  selected = "none";
  date = new Date();

  minDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  @Output() fromDateEmit = new EventEmitter();
  @Output() toDateEmit = new EventEmitter();
  @Input() loading?;
  // TODO ver si se puede reutilizar el componente para reservas ya hechas con este parámetro
  @Input() previousBooking?;
  @Input() travelToEdit?;
  @Input() bookingToAssign?;
  isAdmin = false;
  @Input() userUuid?: string;
  @Input() customBooking?;
  @Input() create?: boolean;
  @Input() booking?: any;
  @Input() companyUuid?: string;
  hoveredDate: NgbDateStruct;
  @ViewChild("d1") bookedInput: NgbInputDatepicker;
  @ViewChild("bookedRange") bookedRange: ElementRef;
  @ViewChild(NgModel) datePick: NgModel;
  @Output() sendInfo = new EventEmitter<any>();
  travelList = [];
  constructor(
    private travelService: TravelService,
    private ngbDateParser: NgbDateParserFormatter,
    private formBuilder: UntypedFormBuilder,
    private utilsService: UtilsService,
    public activeModal: NgbActiveModal,
    private ngxToastrService: NgxToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      title: [
        this.previousBooking ? this.travelToEdit.title : '',
        [Validators.required],
      ],
      description: [this.previousBooking ? this.travelToEdit.description : ""],
      initDate: [
        this.previousBooking
          ? this.ngbDateParser.parse(
              moment(this.travelToEdit.initDate).add("1", "M").calendar()
            )
          : '',
        Validators.required,
      ],
      endDate: [
        this.previousBooking
          ? this.ngbDateParser.parse(
              moment(this.travelToEdit.endDate).add("1", "M").calendar()
            )
          : '',
        Validators.required,
      ],
    });
    if (!this.previousBooking) {
      this.getTravels();
    }
    if (this.bookingToAssign) {
      this.selected = this.travelToEdit.uuid ? this.travelToEdit.uuid : "none";
      this.showCreate = false;
      this.showEdit = true;
    }
  }

  get f() {
    return this.form.controls;
  }

  getTravels() {
    this.loading = true;
    const params = {
      pageSize: 9999,
    };
    if (this.companyUuid) {
      params["companyUuid"] = this.companyUuid;
    }

    this.subcriptions.push(
      this.travelService.getTravelsWithParams(params).subscribe((result) => {
        this.loading = false;
        result.docs.forEach((travel) => {
          const beginDate = new Date(travel.initDate);
          const finalDate = new Date(travel.endDate);
          if (this.booking && this.booking.initDate && this.booking.endDate) {
            const initDate = new Date(this.booking.initDate);
            const endDate = new Date(this.booking.endDate);
            if (beginDate <= initDate && finalDate >= endDate) {
              this.travelList.push({
                value: travel.uuid,
                viewValue:
                  travel.title +
                  " (" +
                  moment(travel.initDate).format("DD/MM/YYYY") +
                  "-" +
                  moment(travel.endDate).format("DD/MM/YYYY") +
                  ")",
              });
            }
          } else {
            if (moment(travel.endDate).isSameOrAfter(new Date(), "day")) {
              this.travelList.push({
                value: travel.uuid,
                viewValue:
                  travel.title +
                  " (" +
                  moment(travel.initDate).format("DD/MM/YYYY") +
                  "-" +
                  moment(travel.endDate).format("DD/MM/YYYY") +
                  ")",
              });
            }
          }
        });
      })
    );
  }

  createTravel() {
    const body = {
      type: "new",
      data: {
        title: this.f.title.value,
        description: this.f.description.value,
        initDate: moment(
          this.ngbDateParser.format(this.f.initDate.value),
          "DD/MM/YYYY"
        )
          .subtract(1, "month")
          .format("YYYY-MM-DD"),
        endDate: moment(
          this.ngbDateParser.format(this.f.endDate.value),
          "DD/MM/YYYY"
        )
          .subtract(1, "month")
          .format("YYYY-MM-DD"),
      },
    };
    if (body.data.endDate < body.data.initDate) {
      this.translate.get("common.oops-error").subscribe((result) => {
        this.ngxToastrService.typeInfo(result, "");
      });
    } else {
      this.sendInfo.emit(body);
      if (!this.customBooking) {
        this.closeModal();
      }
    }
  }

  editTravel() {
    const body = {
      title: this.f.title.value,
      description: this.f.description.value,
      initDate: moment(
        this.ngbDateParser.format(this.f.initDate.value),
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD"),
      endDate: moment(
        this.ngbDateParser.format(this.f.endDate.value),
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD"),
    };
    if (this.userUuid) {
      body["userUuid"] = this.userUuid;
    }
    if (this.companyUuid) {
      body["companyUuid"] = this.companyUuid;
    }
    this.sendInfo.emit(body);
    this.closeModal();
  }

  changeSelected(value) {
    this.selected = value;
  }
  addItemToTravel() {
    // si vengo de crear un viaje nuevo
    if (!this.bookingToAssign) {
      if (this.selected !== "none") {
        const data = {
          type: "add",
          data: {
            uuid: this.selected,
          },
        };
        this.sendInfo.emit(data);
      }
      // si estoy asignando una reserva previa a un viaje ya creado
    } else {
      let data;
      if (this.selected !== "none") {
        data = {
          type: "add",
          data: {
            uuid: this.selected,
          },
        };
      } else {
        data = {
          type: "none",
        };
      }
      this.sendInfo.emit(data);
    }
    if (!this.customBooking) {
      this.closeModal();
    }
  }

  clickAction(type: string) {
    if (type === "create") {
      this.showCreate = true;
      this.showEdit = false;
    } else {
      this.showCreate = false;
      this.showEdit = true;
    }
  }

  emitDates() {
    this.fromDateEmit.emit(this.f.initDate.value);
    this.toDateEmit.emit(this.f.endDate.value);
  }

  onDateSelection(date: NgbDate) {
    if (!this.f.initDate.value && !this.f.endDate.value) {
      this.f.initDate.setValue(date);
    } else if (
      this.f.initDate.value &&
      !this.f.endDate.value &&
      date &&
      date.after(this.f.initDate.value)
    ) {
      this.f.endDate.setValue(date);
    } else {
      this.f.endDate.setValue(null);
      this.f.initDate.setValue(date);
    }
    this.bookedRange.nativeElement.value =
      this.ngbDateParser.format(this.f.initDate.value) +
      " - " +
      this.ngbDateParser.format(this.f.endDate.value);
    this.emitDates();
  }

  isHovered(date: NgbDate) {
    return (
      this.f.initDate.value &&
      !this.f.endDate.value &&
      this.hoveredDate &&
      date.after(this.f.initDate.value) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      date.after(this.f.initDate.value) && date.before(this.f.endDate.value)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.f.initDate.value) || date.equals(this.f.endDate.value)
    );
  }

  getOutMinDate() {
    return this.utilsService.getOutDate(
      this.minDateNgStruct,
      this.f.initDate.value
    );
  }
  closeModal() {
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    this.subcriptions.forEach((s) => s.unsubscribe());
  }
}
