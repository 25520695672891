import { Billingdata } from "./billingdata";

export class Agency {
  uuid: string;
  name: string;
  cif: string;
  cian?: string;
  address: string;
  city: string;
  province?: string;
  postalCode: string;
  country: string;
  email: string;
  contactEmail: string;
  type: "AGENCY";
  billingData: Billingdata;
  cardData: any;
  bookedWithoutCard?: boolean;
  discount: string;
  flightDiscount?: string;
  image?: string;
  phone?: String;
  hotelComission?: any;
  trainComission?: any;
  accessType?: "agency";
  hubUuid?: any;
}
