import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { GroupService } from "@services";
import { MatchHeightModule } from "@shared/directives/match-height.directive";
import { SharedModule } from "@shared/shared.module";
import { GroupDetailComponent } from "./group-detail/group-detail.component";
import { GroupFormComponent } from "./group-form/group-form.component";
import { GroupRoutingModule } from "./group-routing.module";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
    imports: [
        CommonModule,
        MatchHeightModule,
        SharedModule,
        ReactiveFormsModule,
        GroupRoutingModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule,
    ],
    exports: [],
    declarations: [GroupDetailComponent, GroupFormComponent],
    providers: [GroupService]
})
export class GroupModule {}
