import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { RouterModule, Routes } from "@angular/router";
import { SharedModule } from "@shared/shared.module";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { GroupModule } from "../group/group.module";
import { StatsComponent } from "./stats/stats.component";

const routes: Routes = [
  {
    path: "",
    component: StatsComponent,
  },
];

@NgModule({
    declarations: [StatsComponent],
    imports: [
        CommonModule,
        SharedModule,
        GroupModule,
        MatSelectModule,
        PdfJsViewerModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        ReactiveFormsModule,
        MatPaginatorModule,
    ],
    exports: []
})
export class StatsModule {}
