<div class="row">
  <div class="col-sm-12">
    <div class="card border-card">
      <div class="card-header">
        <h4>{{ "company.booked.info-company" | translate }}</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <ul class="no-list-style">
                <li class="mb-2">
                  <span class="text-bold-500 primary cursor-default"
                    ><i class="ft-credit-card font-small-3"></i>
                    {{ "common.cif" | translate }}:</span
                  >
                  <span class="d-block overflow-hidden">{{
                    businessEntity ? businessEntity.cif : ""
                  }}</span>
                </li>
                <li class="mb-2">
                  <span class="text-bold-500 primary cursor-default"
                    ><i class="ft-map-pin font-small-3"></i>
                    {{ "common.address" | translate }}:</span
                  >
                  <span class="d-block overflow-hidden">{{
                    businessEntity ? businessEntity.address : ""
                  }}</span>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <ul class="no-list-style">
                <li class="mb-2">
                  <span class="text-bold-500 primary cursor-default"
                    ><i class="ft-map font-small-3"></i>
                    {{ "common.city" | translate }}:</span
                  >
                  <span class="d-block overflow-hidden">{{
                    businessEntity ? businessEntity.city : ""
                  }}</span>
                </li>
                <li class="mb-2">
                  <span class="text-bold-500 primary cursor-default"
                    ><i class="ft-map font-small-3"></i>
                    {{ "common.postal-code" | translate }}:</span
                  >
                  <span class="d-block overflow-hidden">{{
                    businessEntity ? businessEntity.postalCode : ""
                  }}</span>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <ul class="no-list-style">
                <li class="mb-2">
                  <span class="text-bold-500 primary cursor-default"
                    ><i class="ft-map font-small-3"></i>
                    {{ "common.country" | translate }}:</span
                  >
                  <span class="d-block overflow-hidden">{{
                    businessEntity ? businessEntity.country : ""
                  }}</span>
                </li>
                <li class="mb-2">
                  <span class="text-bold-500 primary cursor-default"
                    ><i class="ft-map font-small-3"></i>
                    {{ "common.email" | translate }}:</span
                  >
                  <span class="d-block overflow-hidden">{{
                    businessEntity ? businessEntity.email : ""
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
