import { Location } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { GroupService, NgxToastrService, UtilsService } from "@core/services";
import { Company, GroupRestriction } from "@models";
import { NgbActiveModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ValidatePrice } from "app/validators/price.validator";
import { Subscription } from "rxjs";

@Component({
  selector: "app-group-form",
  templateUrl: "./group-form.component.html",
  styleUrls: ["./group-form.component.scss"],
})
export class GroupFormComponent implements OnInit, OnDestroy {
  company?: Company;
  user?: any;
  @Input() group?: GroupRestriction;
  @Input() loading: Boolean;
  @Output() sendInfo = new EventEmitter<any>();
  subcriptions: Subscription[] = [];
  isAdmin: boolean;
  starRating = 0;
  isCreateGroup: boolean = this.router.url.includes("create-group");
  toppings = new UntypedFormControl();
  @ViewChild("instanceCompany") instanceCompany: NgbTypeahead;
  dropdownSettings = {};
  form: UntypedFormGroup;
  isInvalid: boolean = false;
  lodgingTypeOptions = [
    { value: "room", text: "hotel.custom.request.option" },
    { value: "apartment", text: "hotel.apartment" },
    { value: "all", text: "expenses.all" },
  ];
  sits = [
    {
      value: "ECONOMY",
      viewValue: "Economy",
    },
    {
      value: "PREMIUM_ECONOMY",
      viewValue: "Premium Economy",
    },
    {
      value: "BUSINESS",
      viewValue: "Business",
    },
    {
      value: "FIRST",
      viewValue: "First",
    },
  ];

  hotelPermits = [
    {
      value: "onlyAuthorization",
      text: "common.onlyAuthorization",
    },
    {
      value: "breakfast",
      text: "common.breakfast",
    },
    {
      value: "price",
      text: "group.group-form.show-price",
    },
    {
      value: "requestBooked",
      text: "group.group-form.book-allowed",
    },
    {
      value: "cancelBooking",
      text: "group.group-form.cancel-booking-allowed",
    },
  ];

  flightPermits = [
    {
      value: "onlyFlightAuthorization",
      text: "common.onlyFlightAuthorization",
    },
    {
      value: "flight",
      text: "group.group-form.flight-allowed",
    },
    {
      value: "requestCustomFlight",
      text: "group.group-form.customFlight-allowed",
    },
    {
      value: "allFlightsAllowed",
      text: "group.group-form.allFlightsAllowed",
    },
    {
      value: "allowCheckedBag",
      text: "group.group-form.checkedBagsAllowed",
    },
  ];

  trainPermits = [
    {
      value: "onlyTrainAuthorization",
      text: "common.onlyTrainAuthorization",
    },
    {
      value: "train",
      text: "group.group-form.train-allowed",
    },
    {
      value: "requestCustomTrain",
      text: "group.group-form.customTrain-allowed",
    },
    {
      value: "chooseTrainSeat",
      text: "group.group-form.chooseTrainSeat",
    },
  ];

  carPermits = [
    {
      value: true,
      text: "departament.departament-form.true",
    },
    {
      value: false,
      text: "departament.departament-form.false",
    },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public translate: TranslateService,
    private groupService: GroupService,
    private ngxToastrService: NgxToastrService,
    private location: Location,
    private utilsService: UtilsService
  ) {
    const navigation = this.router.getCurrentNavigation();

    if (navigation && navigation.extras.state != null) {
      this.company = this.router.getCurrentNavigation().extras.state.company;
      this.group = this.router.getCurrentNavigation().extras.state.group;
      this.user = this.router.getCurrentNavigation().extras.state.user;
      this.storeParams();
    } else {
      this.getFromLocalStorage();
    }
  }

  storeParams() {
    this.utilsService.storeParamInLocalStorage({
      title: "company",
      value: this.company,
    });
    this.utilsService.storeParamInLocalStorage({
      title: "group",
      value: this.group,
    });
    this.utilsService.storeParamInLocalStorage({
      title: "user",
      value: this.user,
    });
  }

  getFromLocalStorage() {
    this.company = this.utilsService.getLocalStorageItem("company").value;
    this.group = this.utilsService.getLocalStorageItem("group").value;
    this.user = this.utilsService.getLocalStorageItem("user").value;
  }

  ngOnDestroy(): void {
    this.utilsService.removeItemFromLocalStorage("company");
    this.utilsService.removeItemFromLocalStorage("group");
    this.utilsService.removeItemFromLocalStorage("user");
  }

  ngOnInit() {
    if (this.group && !this.group.filters.onlyAuthorization) {
      this.group.filters.onlyAuthorization = false;
    }
    this.form = this.formBuilder.group(
      {
        name: [
          this.group ? this.group.name : "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30),
          ],
        ],
        stars: [
          this.group ? this.group.filters.stars : "",
          [Validators.required, Validators.min(0), Validators.max(5)],
        ],
        priceMin: [
          this.group ? this.group.filters.priceMin.toFixed(2) : Number(0).toFixed(2),
          [Validators.required, Validators.min(0)],
        ],
        priceMax: [
          this.group ? this.group.filters.priceMax.toFixed(2) : Number(10).toFixed(2),
          [Validators.required, Validators.min(1)],
        ],
        onlyAuthorization: [
          this.group ? this.group.filters.onlyAuthorization : false,
        ],
        onlyFlightAuthorization: [
          this.group ? this.group.filters.onlyFlightAuthorization : false,
        ],
        onlyTrainAuthorization: [
          this.group ? this.group.filters.onlyTrainAuthorization : false,
        ],
        requestCustomFlight: [
          this.group ? this.group.filters.requestCustomFlight : false,
        ],
        requestCustomTrain: [
          this.group ? this.group.filters.requestCustomTrain : false,
        ],
        chooseTrainSeat: [
          this.group
            ? this.group.filters.chooseTrainSeat
              ? this.group.filters.chooseTrainSeat
              : false
            : false,
        ],
        lodgingType: [
          this.group ? this.group.filters.lodgingType : "apartment",
          [Validators.required],
        ],
        sitType: [
          this.group && this.group.filters.sitType
            ? this.group.filters.sitType
            : "ECONOMY",
        ],
        breakfast: [
          this.group ? this.group.filters.breakfast : false,
          [Validators.required],
        ],
        flight: [
          this.group ? this.group.filters.requestFlight : false,
          [Validators.required],
        ],
        train: [
          this.group ? this.group.filters.requestTrain : false,
          [Validators.required],
        ],
        requestBooked: [
          this.group ? this.group.filters.requestBooked : false,
          [Validators.required],
        ],
        requestCar: [
          this.group && this.group.filters.requestCar
            ? this.group.filters.requestCar
            : false,
          [Validators.required],
        ],
        cancelBooking: [
          this.group ? this.group.filters.requestCancelBooked : false,
          [Validators.required],
        ],
        allFlightsAllowed: [
          this.group && this.group.filters.allFlightsAllowed
            ? this.group.filters.allFlightsAllowed
            : false,
        ],
        allowCheckedBag: [
          this.group && this.group.filters.allowCheckedBag
            ? this.group.filters.allowCheckedBag
            : false,
        ],
        priceMaxBag: [
          this.group && this.group.filters.priceMaxBag
            ? this.group.filters.priceMaxBag
            : 1,
          [Validators.min(0)],
        ],
        weight: [
          this.group && this.group.filters.weight
            ? this.group.filters.weight
            : 0,
          [Validators.min(0)],
        ],
      },
      { validator: ValidatePrice }
    );

    if (this.company && !this.group) {
      this.form.addControl(
        "company",
        new UntypedFormControl(this.company ? this.company : "", [Validators.required])
      );
    }
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    // let formData = new FormData();
    // let attributes = {};
    this.isInvalid = false;

    const objectToEmit: any = {
      company: !this.group ? this.f.company.value : undefined,
      name: this.f.name.value,
      filters: {
        stars: this.f.stars.value,
        priceMin: this.f.priceMin.value,
        priceMax: this.f.priceMax.value,
        lodgingType: this.f.lodgingType.value,
        breakfast: this.f.breakfast.value,
        requestFlight: this.f.flight.value,
        requestTrain: this.f.train.value,
        chooseTrainSeat: this.f.chooseTrainSeat.value,
        requestBooked: this.f.requestBooked.value,
        onlyAuthorization: this.f.onlyAuthorization.value,
        onlyFlightAuthorization:
          this.f.onlyFlightAuthorization.value !== null
            ? this.f.onlyFlightAuthorization.value
            : false,
        onlyTrainAuthorization:
          this.f.onlyTrainAuthorization.value !== null
            ? this.f.onlyTrainAuthorization.value
            : false,
        requestCancelBooked: this.f.cancelBooking.value,
        requestCustomTrain:
          this.f.requestCustomTrain.value !== null
            ? this.f.requestCustomTrain.value
            : false,
        requestCustomFlight:
          this.f.requestCustomFlight.value !== null
            ? this.f.requestCustomFlight.value
            : true,
        requestCar: this.f.requestCar.value,
        sitType: this.f.sitType.value,
        allFlightsAllowed: this.f.allFlightsAllowed.value,
        allowCheckedBag: this.f.allowCheckedBag.value,
        priceMaxBag: this.f.priceMaxBag.value,
        weight: this.f.weight.value,
      },
    };

    const body = {
      name: objectToEmit.name,
      filters: objectToEmit.filters,
    };

    const bodyEdit = {
      uuid: this.group ? this.group.uuid : undefined,
      name: objectToEmit.name,
      filters: objectToEmit.filters,
      users: this.group ? this.group.users : undefined,
    };
    const action = this.isCreateGroup
      ? this.groupService.postGroup(this.company.uuid, body)
      : this.groupService.putGroup(this.company.uuid, bodyEdit);
    this.subcriptions.push(
      action.subscribe(
        (res) => {
          this.loading = false;
          this.translate
            .get(
              !this.isCreateGroup
                ? "company.company-detail.edit-group-succesful"
                : "company.company-detail.created-group"
            )
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess(null, result);
              this.goBack();
            });
          this.group = res;
        },
        (err) => {
          this.loading = false;
          this.translate
            .get(
              this.isCreateGroup
                ? "company.company-detail.error-edit-group"
                : "company.company-detail.error-create-group"
            )
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.message);
            });
        }
      )
    );
    // this.sendInfo.emit(objectToEmit);
  }

  // setValue(event, id: string) {
  //   if (id == 'hotelPermits') {
  //     this.hotelPermits.forEach(h => {
  //       const val = h.value;
  //       this.form.get(val).setValue(false);
  //     });
  //   } else if (id == "fligthPermits") {
  //     this.flightPermits.forEach((h) => {
  //       const val = h.value;
  //       this.form.get(val).setValue(false);
  //     });
  //   } else if (id == "trainPermits") {
  //     this.trainPermits.forEach((h) => {
  //       const val = h.value;
  //       this.form.get(val).setValue(false);
  //     });
  //   }

  //   if (event.length > 0) {
  //     event.forEach((s) => {
  //       if (this.form.get(s)) {
  //         const value = this.form.get(s).value;
  //         this.form.get(s).setValue(true);
  //       }
  //     });
  //   }
  // }

  goBack() {
    this.location.back();
  }
}
