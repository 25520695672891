import { Location } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NavigationExtras, Router } from "@angular/router";
import { User } from "@core/models";
import {
  NgxToastrService,
  TravelUtilsService,
  UserService,
  UtilsService,
} from "@core/services";
import { AirbnbService } from "@core/services/airbnb.service";
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { CustomDateParserFormatter } from "@shared/component/date-parser";
import { AppState } from "app/app.reducer";
import { DialogTravelersComponent } from "app/modules/components/trains/dialog-travelers/dialog-travelers.component";
import cities from "assets/utils/cities.json";
import moment from "moment";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

@Component({
  selector: "app-airbnb-request",
  templateUrl: "./airbnb-request.component.html",
  styleUrls: ["./airbnb-request.component.scss"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class AirbnbRequestComponent implements OnInit, OnDestroy {
  public get ngbDateParser(): NgbDateParserFormatter {
    return this._ngbDateParser;
  }
  public set ngbDateParser(value: NgbDateParserFormatter) {
    this._ngbDateParser = value;
  }
  @Input() isModal?;
  @Input() userUuid?;
  @Input() companyUuid?;
  @Output() closeEvent? = new EventEmitter();
  subscriptions: Subscription[] = [];
  canBookHotel: boolean;
  canRequestCustomFlight: boolean;
  canRequestCustomTrain: boolean;
  canRequestCustomCar: boolean;
  canRequestAirbnb: boolean;
  loading = false;
  user: User;
  authUserUuid = "";
  userType: string;
  form: FormGroup;
  date = new Date();
  hoveredDate: NgbDateStruct;
  minDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  city = "";
  citiesList = [];
  titleSelect = "";
  translationsDone = false;
  userImageBase64: string;
  isAdmin = false;
  isEmployee = false;
  canUseTRM: boolean;
  userToBook: string;
  bookedUserAccessType: string;
  searchConfig = {
    travelers: [],
  };
  isInvalid: boolean = false
  @ViewChild("bookedRange") bookedRange: ElementRef;
  @ViewChild("createdAtRange") createdAtRange: ElementRef;
  @Output() checkOutEmit = new EventEmitter();
  @Output() checkInEmit = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    private _ngbDateParser: NgbDateParserFormatter,
    private utilsService: UtilsService,
    private router: Router,
    private modalService: NgbModal,
    private airbnbService: AirbnbService,
    private store: Store<AppState>,
    private travelUtilsService: TravelUtilsService,
    private userService: UserService,
    private location: Location,
    private dialog: MatDialog
  ) {
    this.subscriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() !== "USER") {
          this.isAdmin = true;
          if (s.user.type?.toUpperCase() === "EMPLOYEE") {
            this.isEmployee = true;
          }
          this.userToBook = localStorage.getItem("userToBook")
            ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
            : null;
          this.canUseTRM = s.user.type
            ? s.user.plan && s.user.plan.permissions.canUseTRM
            : s.user.user.plan && s.user.user.plan.permissions.canUseTRM;
        }
        this.userType = s.user.type
        this.authUserUuid = s.user.uuid;
      })
    );
  }

  ngOnInit(): void {
    if (
      window.history.state.canBookHotel !== null &&
      window.history.state.canRequestCustomCar !== null &&
      window.history.state.canRequestCustomFlight !== null &&
      window.history.state.canRequestCustomTrain !== null &&
      window.history.state.canRequestAirbnb !== null
    ) {
      this.canBookHotel = window.history.state.canBookHotel;
      this.canRequestCustomCar = window.history.state.canRequestCustomCar;
      this.canRequestCustomFlight = window.history.state.canRequestCustomFlight;
      this.canRequestCustomTrain = window.history.state.canRequestCustomTrain;
      this.canRequestAirbnb = window.history.state.canRequestAirbnb;
      this.userUuid = window.history.state.userUuid;
      this.companyUuid = window.history.state.companyUuid;
      this.userImageBase64 = window.history.state.userImageBase64;
    } else {
      this.router.navigate(["/custom-services"]);
    }

    if (
      this.userUuid &&
      (this.userUuid !== null || this.userUuid !== undefined)
    ) {
      this.userService
        .getUser(this.userUuid)
        .pipe(take(1))
        .subscribe((user: User) => {
          this.bookedUserAccessType = user.type;
          this.user = user;
          if (
            this.user != null &&
            (this.searchConfig.travelers == null ||
              this.searchConfig.travelers.length <= 0)
          ) {
            const userBirthdate: any = new Date(this.user.birthdate);
            const currDate: any = new Date();
            const age = currDate - userBirthdate;
            const ageFinal = Math.floor(age / 31557600000);
            this.searchConfig.travelers.push({
              age: ageFinal,
            });
            this.createForm(this.searchConfig.travelers);
          } else {
            this.createForm(null);
          }
        });
    }
  }

  createForm(travelers: any[]) {
    this.form = this.formBuilder.group({
      checkIn: [null, [Validators.required]],
      checkOut: [null, [Validators.required]],
      link: [null],
      city: ["", [Validators.required]],
      travelers: [
        this.searchConfig.travelers,
        [Validators.required, Validators.min(16)],
      ],
      comments: [""],
      userUuid: this.userUuid,
      companyUuid: this.companyUuid,
      images: [null],
      customCode: [null],
      costCenter: [null],
    });
  }

  get f() {
    return this.form.controls;
  }

  closeModalCross() {
    this.modalService.dismissAll();
  }

  // Buscador de ciudades
  getCities(value: string) {
    this.citiesList = [];
    if (value !== "" && value.length > 2) {
      cities.Table1.forEach((v) => {
        if (
          v["name"]?.toLowerCase().includes(value?.toLowerCase()) &&
          !this.citiesList.includes(v["name"])
        ) {
          this.citiesList.push(v["name"]);
        }
      });
    } else {
      this.citiesList = [];
    }
  }

  goBack() {
    this.location.back();
  }

  navigate(route: string) {
    const navigationExtra: NavigationExtras = {
      state: {
        canBookHotel: this.canBookHotel,
        canRequestCustomFlight: this.canRequestCustomFlight,
        canRequestCustomTrain: this.canRequestCustomTrain,
        canRequestCustomCar: this.canRequestCustomCar,
        canRequestAirbnb: this.canRequestAirbnb,
        userUuid: this.userToBook ? this.userToBook : this.authUserUuid,
        companyUuid: this.companyUuid,
        userImageBase64: this.userImageBase64,
      },
    };

    this.router.navigate(["custom-services/" + route], navigationExtra);
  }

  cleanCitiesList(option) {
    this.city = option;
    this.citiesList = [];
  }

  onDateSelection(date: NgbDate) {
    if (
      !this.form.controls["checkIn"].value &&
      !this.form.controls["checkOut"].value
    ) {
      this.form.controls["checkIn"].setValue(date);
    } else if (
      this.form.controls["checkIn"].value &&
      !this.form.controls["checkOut"].value &&
      date &&
      date.after(this.form.controls["checkIn"].value)
    ) {
      this.form.controls["checkOut"].setValue(date);
    } else {
      this.form.controls["checkOut"].setValue(null);
      this.form.controls["checkIn"].setValue(date);
    }
    this.bookedRange.nativeElement.value =
      this.ngbDateParser.format(this.form.controls["checkIn"].value) + " - ";
    if (this.form.value.checkOut != null) {
      this.bookedRange.nativeElement.value += this.ngbDateParser.format(
        this.form.controls["checkOut"].value
      );
    }
    this.emitDates();
  }

  onSubmit() {
    this.isInvalid = false
    this.loading = true;
    const body = this.form.getRawValue();
    const travel = this.travelUtilsService.getTravelLocalStorage();
    const dateDepartureAux = moment(
      this.ngbDateParser.format(this.f.checkIn.value),
      "DD/MM/YYYY"
    ).format("DD-MM-YYYY");
    const dateReturnAux = moment(
      this.ngbDateParser.format(this.f.checkOut.value),
      "DD/MM/YYYY"
    ).format("DD-MM-YYYY");
    body["checkIn"] = `${dateDepartureAux}`;
    body["checkOut"] = `${dateReturnAux}`;
    if (travel && travel.title) {
      body["travelName"] = travel.title;
    }
    body["userUuid"] = this.userUuid;
    // TODO: Remove both delete when backend accepts both these inputs
    const adults = [];
    const minors = [];
    body.travelers.forEach((traveler) => {
      if (traveler.age >= 18) {
        adults.push(null);
      } else {
        minors.push(null);
      }
    });
    body["guests"] = adults.length + minors.length;
    delete body.travelers;
    delete body.images;
    this.subscriptions.push(
      this.airbnbService.sendCustomAirbnbRequest(body).subscribe(
        (s) => {
          this.subscriptions.push(
            this.translate
              .get(["common.congrat", "airbnb.custom.request.success"])
              .subscribe((result) => {
                this.loading = false;
                this.ngxToastrService.typeSuccess(
                  result["common.congrat"],
                  result["airbnb.custom.request.success"]
                );
                if (this.isModal) {
                  this.closeEvent.emit(s);
                } else {
                  if(this.userType?.toUpperCase() === 'USER') {
                    this.router.navigateByUrl("my-bookeds");
                  } else { 
                    this.router.navigateByUrl(`/user/booked/${this.user.uuid}`)
                  }
                }
              })
          );
        },
        (err) => {
          this.translate.get("common.error").subscribe((result) => {
            this.loading = false;
            this.ngxToastrService.typeInfo(result, err.error.message);
          });
        }
      )
    );
  }

  openTravelerSelector() {
    const dialogTravelers = this.dialog.open(DialogTravelersComponent, {
      panelClass: "new-dialog",
      data: {
        travelerList: JSON.parse(JSON.stringify(this.form.value.travelers)),
      },
    });
    dialogTravelers.afterClosed().subscribe((result) => {
      if (result != null) {
        this.form.patchValue({ travelers: result });
        this.searchConfig.travelers = result;
      }
    });
  }

  isHovered(date: NgbDate) {
    return (
      this.form.controls["checkIn"].value &&
      !this.form.controls["checkOut"].value &&
      this.hoveredDate &&
      date.after(this.form.controls["checkIn"].value) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      date.after(this.form.controls["checkIn"].value) &&
      date.before(this.form.controls["checkOut"].value)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.form.controls["checkIn"].value) ||
      date.equals(this.form.controls["checkOut"].value)
    );
  }

  emitDates() {
    this.checkInEmit.emit(this.form.controls["checkIn"].value);
    this.checkOutEmit.emit(this.form.controls["checkOut"].value);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
