import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthService } from "@core/services";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { AppState } from "../../company.reducer";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent implements OnInit {
  @Output() showPage = new EventEmitter();
  @Input() currentPage;
  @Input() company;
  isAdmin: boolean;
  constructor(private store: Store<AppState>, public authService: AuthService) {
    this.store
      .select("auth")
      .pipe(take(1))
      .subscribe((auth) => {
        this.isAdmin = auth.user.type?.toUpperCase() === "EMPLOYEE";
      });
  }

  ngOnInit() {}

  selectTab(tab) {
    this.showPage.emit(tab);
  }
}
