<div class="modal-body p-4">
  <img src="../../../../assets/img/svg/safe.svg" />
  <h3>{{ "insurance.before-continue" | translate }}</h3>
  <h5>{{ "insurance.question" | translate }}</h5>
  <div class="actions" *ngIf="actions">
    <button (click)="onSubmit()" class="dismiss">
      {{ "insurance.cancel" | translate }}
    </button>
    <button (click)="actions = false" class="accept">
      {{ "insurance.accept" | translate }}
    </button>
  </div>
  <div class="insurances-container" *ngIf="actions === false">
    <div class="insurances">
      <div
        class="insurance"
        [ngClass]="{ checked: choosed === 'BASIC' }"
        (click)="select('BASIC')"
      >
        <span>BASIC</span>
      </div>
      <div
        class="insurance"
        [ngClass]="{ checked: choosed === 'PREMIUM' }"
        (click)="select('PREMIUM')"
      >
        <span>PREMIUM</span>
      </div>
    </div>
    <button (click)="onSubmit()" class="accept">
      {{ "insurance.continue" | translate }}
    </button>
  </div>
</div>
