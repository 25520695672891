import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { NavigationExtras, Router } from "@angular/router";
import { TrainRequest, User } from "@core/models";
import {
  OutwardTrain,
  SalesGroup,
  TrainSearchResponse,
} from "@core/models/trenymas/train-search-response";
import {
  AuthService,
  GroupService,
  NgxToastrService,
  TrainService,
  TrainStateService,
  UserService,
  UtilsService,
} from "@core/services";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { PaginationConfig } from "@shared/interfaces";
import { TrainUI } from "@shared/interfaces/trains-ui.interface";
import { AppState } from "app/app.reducer";
import cities from "assets/utils/cities.json";
import trainStations from "assets/utils/train-stations.json";
import * as moment from "moment";
import { forkJoin, Subscription } from "rxjs";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import { TravelersComponent } from "../flight-train/travelers/travelers.component";
import { TrainSearchDto } from "./../../core/models/trenymas/train-search-dto";

@Component({
  selector: "app-trains",
  templateUrl: "./trains.component.html",
  styleUrls: ["./trains.component.scss"],
})
export class TrainsComponent implements OnInit, OnDestroy {
  @Input() type = "train";
  @Input() userUuid?;
  @Input() companyUuid?;

  private subcriptions: Subscription[] = [];
  lowValue = 0;
  highValue = 10;
  requestId = "";
  form: UntypedFormGroup;
  trainDto: TrainRequest;
  formSearchTrain: UntypedFormGroup;
  trainsListResult: TrainSearchResponse;
  trainUI: TrainUI = {};
  trainsGoingUI: TrainUI[] = [];
  trainsReturningUI: TrainUI[] = [];
  loading = false;
  prefOptions = [];
  date = new Date();
  trainArr = [];
  minDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  complementsOutward = [];
  complementsReturn = [];
  checkedOneWay = false;
  checkedReturn = true;
  user: User;
  users = [];
  checked = false;
  disabled = true;
  complementsBody = {};
  spinner = false;
  groupFilters: any = {};
  renfeCard = [
    {
      value: "plus50",
      viewValue: "Renfe Plus50",
    },
    {
      value: "goldenCard",
      viewValue: "Renfe Golden Card",
    },
    {
      value: "all",
      viewValue: "Todos",
    },
  ];
  travelersFromModal = {
    adults: 1,
    children: 0,
    infants: 0,
    total: 1,
  };
  trainsLoaded = false;
  paginationConfig: PaginationConfig = {
    page: 1,
    pageSize: 10,
    collectionSize: 0,
  };
  requestDone = true;
  enable = false;
  state: any;
  isUser = true;
  allowTrains = false;
  trainStationJson: any = {};
  bodyTravelModal: any = {};
  citiesList = [];
  trainStationList = [];
  originCode = "";
  destinationCode = "";
  promoGoing: any;
  promoRet: any;
  apiSession = "";
  selectedTrain = {};
  promoList = [];
  trainDates: { dateIn: string; dateOut: string };
  return = false;
  bookTrainParams = {
    apiSession: "",
    outwardId: "",
    classCodeOutward: "",
    classSalesGroupCodeOutward: "",
    classCodeReturn: "",
    classSalesGroupCodeReturn: "",
    fee: "0",
    markup: "0",
  };
  classCodeG = "";
  classCodeR = "";
  outwardId = "";
  retId = "";
  notOneWay = true;
  trainGoing: OutwardTrain;
  trainReturning: OutwardTrain;
  outwardWagonList = [];
  returnWagonList = [];
  outwardSeats = [];
  returnSeats = [];
  selectSeat = false;
  firstRequestDone = false;
  isMocked = false;
  isAdmin = false;
  isEmployee = false;
  canUseTRM: boolean;
  userToBook: string;
  outwardSeatPrice: number;
  returnSeatPrice: number;
  isTDO = false;
  isTJO = false;
  isRequest: boolean;
  searchHasFailed = false;
  selectedUsers: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    private trainService: TrainService,
    private router: Router,
    private groupService: GroupService,
    public utilsService: UtilsService,
    public activeModal: NgbActiveModal,
    private stateService: TrainStateService,
    private store: Store<AppState>,
    private userService: UserService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    this.subcriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() !== "USER") {
          this.isAdmin = true;
          if (s.user.type?.toUpperCase() === "EMPLOYEE") {
            this.isEmployee = true;
          }
          this.userToBook = localStorage.getItem("userToBook")
            ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
            : null;
          if (this.userToBook != null) {
            this.userUuid = this.userToBook;
          }
          this.canUseTRM = s.user.type
            ? s.user.plan && s.user.plan.permissions.canUseTRM
            : s.user.user.plan && s.user.user.plan.permissions.canUseTRM;
          if(!this.canUseTRM){
            this.canUseTRM = this.authService.userCompanyPlan?.permissions.canUseTRM;
          }
        } else {
          this.companyUuid = s.user.companyUuid;
        }
      })
    );

    if (window.history.state.dataFromCompany) {
      const dataFromCompany = window.history.state.dataFromCompany;
      this.userUuid = dataFromCompany.userUuid;
      this.type = dataFromCompany.type;
      this.companyUuid = dataFromCompany.companyUuid;
      this.isUser = false;
      this.stateService.setDataFromCompany(dataFromCompany);
    } else {
      const dataFromState = this.stateService.dataFromCompany$.getValue();
      if (dataFromState && dataFromState.userUuid) {
        this.userUuid = dataFromState.userUuid;
        this.type = dataFromState.type;
        this.companyUuid = dataFromState.companyUuid;
        this.isUser = true;
      }
    }
  }

  ngOnInit() {
    this.subcriptions.push(
      this.userService.getGroupUser(this.userUuid).subscribe((g) => {
        this.groupFilters = g.filters;
        this.isRequest = this.groupFilters.onlyTrainAuthorization;
        this.renfeCard = [];
        this.renfeCard.push(
          {
            value: "plus50",
            viewValue: "Renfe Plus50",
          },
          {
            value: "goldenCard",
            viewValue: "Renfe Golden Card",
          }
        );
      }),
      this.userService.getUser(this.userUuid).subscribe((u) => (this.user = u))
    );
    this.state = this.stateService.state$.getValue() || {};
    if (this.state.trains) {
      this.trainsListResult = this.state.flights;
      this.requestId = this.state.flights.requestId;
      this.travelersFromModal = {
        adults: this.stateService.getTrainField("travelers").adults || 1,
        children: this.stateService.getTrainField("travelers").children || 0,
        infants: this.stateService.getTrainField("travelers").infants || 0,
        total: this.stateService.getTrainField("travelers").total || 1,
      };
    }
    this.stateService.state$.next({});
    this.subcriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user.type?.toUpperCase() === "USER") {
          this.userUuid = s.user.uuid;
          this.isUser = true;
          this.subcriptions.push(
            this.userService.getGroupUser(s.user.uuid).subscribe((g) => {
              this.groupFilters = g.filters;
              this.groupFilters.requestTrain
                ? (this.allowTrains = true)
                : (this.allowTrains = false);
            })
          );
        } else {
          this.isUser = false;
          this.subcriptions.push(
            this.userService.getGroupUser(this.userUuid).subscribe((g) => {
              this.groupFilters = g.filters;
              this.groupFilters.requestTrain
                ? (this.allowTrains = true)
                : (this.allowTrains = false);
            })
          );
        }
      })
    );
    this.trainStationJson = trainStations;

    if (this.state.trains) {
      this.trainsListResult = this.state.trains;
    }
    this.formSearchTrain = this.formBuilder.group({
      origin: [
        this.state.searchTrain ? this.stateService.getTrainField("origin") : "",
        [Validators.required],
      ],
      dateIn: [
        this.state.searchTrain ? this.stateService.getTrainField("dateIn") : "",
        [Validators.required],
      ],
      dateOut: [
        this.state.searchTrain && this.state.searchTrain.route[1]
          ? this.stateService.getTrainField("dateOut")
          : "",
      ],
      destination: [
        this.state.searchTrain
          ? this.stateService.getTrainField("destination")
          : "",
        [Validators.required],
      ],
      onlyDirect: [
        this.state.searchTrain ? this.state.searchTrain.onlyDirect : false,
      ],
      roundtrip: [
        this.state.searchTrain ? this.state.searchTrain.roundtrip : "false",
        [Validators.required],
      ],
      adults: [this.state.searchTrain ? this.state.searchTrain.adults : 1],
      children: [this.state.searchTrain ? this.state.searchTrain.children : 0],
      infants: [this.state.searchTrain ? this.state.searchTrain.infants : 0],
      travelers: [
        this.state.searchTrain ? this.state.searchTrain.travelers : 1,
        [Validators.required],
      ],
      cards: [
        this.state.searchTrain ? this.state.searchTrain.cards : "none",
        [Validators.required],
      ],
    });
    // Cargo los empleados de la compañia
    if (!this.userUuid) {
      this.formSearchTrain.addControl(
        "userUuid",
        new UntypedFormControl(null, [Validators.required])
      );
      this.loadGroups(this.companyUuid);
    }
    this.notOneWay = !this.formSearchTrain.getRawValue().roundtrip;
  }

  getTrainStations(value: string) {
    this.trainStationList = [];
    if (value !== "" && value.length > 2) {
      trainStations.Table1.forEach((e) => {
        if (
          e.CODIGO_PROVEEDOR.includes(value) ||
          e.CODIGO_PROVEEDOR.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(value?.toLowerCase()) ||
          e.NOMBRE.includes(value) ||
          e.NOMBRE?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              value
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
        ) {
          this.trainStationList.push({
            title: e.NOMBRE,
            value: e.CODIGO_PROVEEDOR,
          });
        }
      });
    } else {
      this.trainStationList = [];
    }
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  loadGroups(uuid) {
    this.loading = true;
    this.groupService.getGroups(uuid).subscribe(
      (result: any[]) => {
        this.loading = false;
        result
          .filter((group) => group.filters.requestTrain)
          .forEach((group) => (this.users = this.users.concat(group.users)));
      },
      (err) => {
        this.loading = false;
        this.translate
          .get(`${this.type?.toLowerCase()}.create-${this.type?.toLowerCase()}.error-load-employees`)
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  get f() {
    return this.formSearchTrain.controls;
  }

  getOutMinDate() {
    return this.utilsService.getOutDateSame(
      this.minDateNgStruct,
      this.formSearchTrain.controls["dateIn"].value
    );
  }

  returnTrip(type) {
    this.checkedOneWay = type === "oneWay";
    this.checkedReturn = type === "return";
    if (this.checkedOneWay) {
      this.f.dateOut = null;
    }
    const validator = this.checkedOneWay ? null : [Validators.required];
    this.form.get("dateReturn").setValidators(validator);
    this.form.get("dateReturn").updateValueAndValidity();
  }

  // Buscador de ciudades
  getCities(value: string) {
    this.citiesList = [];
    if (value !== "" && value.length > 2) {
      cities.Table1.forEach((v) => {
        if (
          v["name"]?.toLowerCase().includes(value?.toLowerCase()) &&
          !this.citiesList.includes(v.name)
        ) {
          this.citiesList.push(v.name);
        }
      });
    } else {
      this.citiesList = [];
    }
  }

  optionSelected(field: string, value: string) {
    if (field === "origin") {
      // this.formSearchTrain.controls['origin'].setValue(value.split('(')[1]);
      this.trainStationList.forEach((s) => {
        if (s.title === value) {
          this.originCode = s.value;
        }
      });
      this.disabled = false;
      this.trainStationList = [];
    }
    if (field === "destination") {
      //  this.formSearchTrain.controls['destination'].setValue(value.split('(')[1]);
      this.trainStationList.forEach((s) => {
        if (s.title === value) {
          this.destinationCode = s.value;
        }
      });
      this.trainStationList = [];
    }
  }

  cleanCitiesList() {
    this.citiesList = [];
  }

  openDialog() {
    const dialogRef = this.dialog.open(TravelersComponent, {
      width: "60%",
      data: this.travelersFromModal,
      disableClose: true,
    });
    dialogRef.componentInstance.isTrain = true;
    this.subcriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        this.travelersFromModal = result;
        this.formSearchTrain.controls["adults"].setValue(result.adults);
        this.formSearchTrain.controls["children"].setValue(result.children);
        this.formSearchTrain.controls["infants"].setValue(result.infants);
        this.formSearchTrain.controls["travelers"].setValue(result.total);
      })
    );
  }

  fixEligeFares(trains: OutwardTrain[]) {
    trains.forEach((train) => {
      const basicTrainsIndex = train.Classes.findIndex(
        (trainClass) => trainClass.ClassCode === "T"
      );
      const eligeTrainsIndex = train.Classes.findIndex(
        (trainClass) => trainClass.ClassCode === "P"
      );

      if (basicTrainsIndex > -1 && eligeTrainsIndex > -1) {
        const basicTrainsSalesGroups: SalesGroup[] =
          train.Classes[basicTrainsIndex].SalesGroups;

        const foundEligeInBasicIndex = basicTrainsSalesGroups.findIndex(
          (salesGroup) => salesGroup.SalesGroupCode === "X"
        );

        const eligeTrainsSalesGroups: SalesGroup[] =
          train.Classes[eligeTrainsIndex].SalesGroups;

        if (foundEligeInBasicIndex > -1) {
          train.Classes[eligeTrainsIndex].SalesGroups.unshift(
            train.Classes[basicTrainsIndex].SalesGroups[foundEligeInBasicIndex]
          );
          train.Classes[basicTrainsIndex].SalesGroups.splice(
            foundEligeInBasicIndex,
            1
          );
        }

        const foundPremiumInEligeIndex = eligeTrainsSalesGroups.findIndex(
          (salesGroup) => salesGroup.SalesGroupCode === "Y"
        );

        if (foundPremiumInEligeIndex > -1) {
          if (train.Classes[train.Classes.length] == null) {
            train.Classes[train.Classes.length] = {
              SalesGroups: [],
              ClassCode: "B",
              ClassName: "Premium",
              RemainingSeats: train.Classes[eligeTrainsIndex].RemainingSeats,
            };
          }
          train.Classes[train.Classes.length - 1].SalesGroups.push(
            train.Classes[eligeTrainsIndex].SalesGroups[
              foundPremiumInEligeIndex
            ]
          );
          train.Classes[eligeTrainsIndex].SalesGroups.splice(
            foundPremiumInEligeIndex,
            1
          );
        }
      }
    });

    return trains;
  }

  cleanData() {
    this.trainsGoingUI = [];
    this.trainReturning = null;
    this.trainGoing = null;
    this.trainsReturningUI = [];
    this.return = false;
    this.enable = false;
    this.trainArr = [];
    this.promoGoing = null;
    this.promoRet = null;
    this.searchHasFailed = false;
  }

  onSearch(searchInput: any) {
    this.firstRequestDone = true;
    this.requestDone = false;
    this.loading = true;
    this.utilsService.loadingTrains = true;
    this.trainsListResult = {};
    this.cleanData();
    const formResult = searchInput;
    const departDateMonth =
      formResult.departDate.month < 10
        ? `0${formResult.departDate.month}`
        : formResult.departDate.month;
    const departDateDay =
      formResult.departDate.day < 10
        ? `0${formResult.departDate.day}`
        : formResult.departDate.day;
    const departDate = `${formResult.departDate.year}${departDateMonth}${departDateDay}`;
    const dto: TrainSearchDto = {
      origin: formResult.origin,
      destination: formResult.destination,
      departDate: departDate,
      oneWay: formResult.oneWay != null ? formResult.oneWay : false,
      numberOfAdults:
        formResult.adults.length > 0
          ? formResult.adults.length.toString()
          : "1",
      plus50Card:
        formResult.travelCard === "plus50" || formResult.travelCard === "all"
          ? true
          : false,
      goldenCard:
        formResult.travelCard === "goldenCard" ||
        formResult.travelCard === "all"
          ? true
          : false,
    };

    if (this.userUuid) {
      dto["userUuid"] = this.userUuid;
    }
    this.notOneWay = !dto.oneWay;

    if (formResult.children > 0) {
      dto["numberOfOlderChildren"] = formResult.children.length.toString();
    }
    if (formResult.infant > 0) {
      dto["numberOfChildrenUnder"] = formResult.infant.length.toString();
    }
    this.trainDates = {
      dateIn: departDate,
      dateOut: "",
    };
    if (formResult.returnDate && !formResult.oneWay) {
      const returnDateMonth =
        formResult.returnDate.month < 10
          ? `0${formResult.returnDate.month}`
          : formResult.returnDate.month;
      const returnDateDay =
        formResult.returnDate.day < 10
          ? `0${formResult.returnDate.day}`
          : formResult.returnDate.day;
      const returnDate = `${formResult.returnDate.year}${returnDateMonth}${returnDateDay}`;
      dto["returnDate"] = returnDate;
      this.trainDates.dateOut = returnDate;
    }
    dto["filters"] = formResult.filters;

    this.subcriptions.push(
      this.trainService.postTrainRequestTrainMore(dto).subscribe(
        (s) => {
          this.trainsListResult = s;

/*           this.fixEligeFares(this.trainsListResult.OutwardTrains);
 */          this.apiSession = s.ApiSession;
          this.transformTrainList(
            this.trainsListResult["OutwardTrains"],
            this.trainsGoingUI
          );
          if (this.trainsListResult.ReturnTrainsNumber > 0) {
/*             this.fixEligeFares(this.trainsListResult.ReturnTrains);
 */            this.transformTrainList(
              this.trainsListResult["ReturnTrains"],
              this.trainsReturningUI
            );
          }
          this.loading = false;
          this.utilsService.loadingTrains = false;
          this.requestDone = true;
          searchInput["travelers"] = [];
          searchInput.adults.forEach((adult) => {
            searchInput.travelers.push(adult);
          });
          searchInput.children.forEach((child) => {
            searchInput.travelers.push(child);
          });
          searchInput.infants.forEach((infant) => {
            searchInput.travelers.push(infant);
          });
          const navigationExtras: NavigationExtras = {
            state: {
              trainsListResult: this.trainsListResult,
              apiSession: this.apiSession,
              searchConfig: searchInput,
              groupFilters: this.groupFilters,
              userUuid: this.userUuid,
            },
          };
          this.router.navigate(["train/search-result"], navigationExtras);
        },
        (err) => {
          this.loading = false;
          this.utilsService.loadingTrains = false;
          this.requestDone = true;
          this.trainsGoingUI = [];
          this.trainsReturningUI = [];
          this.searchHasFailed = true;
          if (err.status === 818) {
            this.subcriptions.push(
              this.translate
                .get(["train.trenymas.notAvailable"])
                .subscribe((result) => {
                  this.loading = false;
                  this.utilsService.loadingTrains = false;
                  this.ngxToastrService.typeInfo(
                    "Error",
                    result["train.trenymas.notAvailable"]
                  );
                })
            );
          } else {
            this.subcriptions.push(
              this.translate
                .get(["train.trenymas.messages.error"])
                .subscribe((result) => {
                  this.ngxToastrService.typeInfo(
                    "Error",
                    result["train.trenymas.messages.error"]
                  );
                  this.loading = false;
                  this.utilsService.loadingTrains = false;
                })
            );
          }
        }
      )
    );
  }

  transformTrainList(listFrom, listTo) {
    listFrom.forEach((t) => {
      this.trainUI.trainNumber = t.TrainNumber;
      this.trainUI.trainType = t.TrainType;
      this.trainUI.trainTypeDescription = t.TrainTypeDescription;
      this.trainUI.departureStationName = t.DepartureStationName;
      this.trainUI.arrivalStationName = t.ArrivalStationName;
      this.trainUI.departureTime = t.DepartureTime;
      this.trainUI.arrivalTime = t.ArrivalTime;
      this.trainUI.travelDuration = t.TravelDuration;
      this.trainUI.travelDurationString =
        Math.trunc(t.TravelDuration / 60).toString() +
        "h " +
        (t.TravelDuration % 60) +
        "m";
      this.trainUI.travelDate = t.TravelDate;
      this.trainUI.isAve = t.IsAVE;
      this.trainUI.priceTourist = [];
      this.trainUI.priceTouristPlus = [];
      this.trainUI.priceBusiness = [];

      t.Classes.forEach((c) => {
        if (c.ClassCode === "T") {
          c.SalesGroups.forEach((sg) => {
            sg.Cabins.forEach((cabin) => {
              cabin.Paxes.forEach((pax) => {
                pax.Fares.forEach((fare) => {
                  this.trainUI.priceTourist.push(fare.CurNetTa);
                });
              });
            });
          });
        } else if (c.ClassCode === "P") {
          c.SalesGroups.forEach((sg) => {
            sg.Cabins.forEach((cabin) => {
              cabin.Paxes.forEach((pax) => {
                pax.Fares.forEach((fare) => {
                  this.trainUI.priceTouristPlus.push(fare.CurNetTa);
                });
              });
            });
          });
        } else {
          c.SalesGroups.forEach((sg) => {
            sg.Cabins.forEach((cabin) => {
              cabin.Paxes.forEach((pax) => {
                pax.Fares.forEach((fare) => {
                  this.trainUI.priceBusiness.push(fare.CurNetTa);
                });
              });
            });
          });
        }
      });

      this.trainUI.priceTourist.sort((a, b) => {
        return a - b;
      });

      if (this.trainUI.priceTouristPlus) {
        this.trainUI.priceTouristPlus.sort((a, b) => {
          return a - b;
        });
      }
      if (this.trainUI.priceBusiness) {
        this.trainUI.priceBusiness.sort((a, b) => {
          return a - b;
        });
      }
      listTo.push(this.trainUI);
      this.trainUI = {};
    });
  }

  async bookTrainMock() {
    this.isMocked = true;
    this.loading = true;
    let mockRes: any = await import(
      "assets/utils/mockup/train-bookTrain-mockup.json"
    );
    let mockAmount: any = "";
    mockAmount = parseFloat(this.promoGoing.price);
    if (this.promoRet != null) {
      mockAmount += parseFloat(this.promoRet.price);
    }
    mockRes = mockRes.default;
    mockRes.ApiSession = this.apiSession ? this.apiSession : mockRes.ApiSession;
    mockRes.Amount = mockAmount;
    mockRes.AmountToPay = mockAmount;
    mockRes.InsurancePrice = 24.05;
    mockRes.LimitDate = moment().add(20, "minutes").format("DD-MM-YY HH:mm");
    const params = {
      ApiSession: this.apiSession,
      outwardId: this.outwardId,
      classCodeOutward: this.classCodeG,
      classSalesGroupCodeOutward: this.promoGoing.code,
      classCodeReturn: null || this.classCodeR,
      classSalesGroupCodeReturn: this.promoRet ? this.promoRet.code : "",
      fee: "0",
      markup: "0",
      retId: null || this.retId,
    };
    const navigationExtras: NavigationExtras = {
      state: {
        response: mockRes,
        passengers: this.travelersFromModal.total,
        user: this.user,
        trains: this.trainArr,
        bookParams: params,
        trainDates: this.trainDates,
        isMocked: this.isMocked,
      },
    };
    this.loading = false;
    if (this.isUser) {
      this.router.navigate(["train/user-form"], navigationExtras);
    } else {
      this.router.navigate(
        [`train/${this.userUuid}/user-form`],
        navigationExtras
      );
    }
  }

  async bookTrainGraphMock(params: any) {
    this.isMocked = true;
    this.loading = true;
    const fares = [];
    if (this.promoGoing) {
      fares.push(this.promoGoing.code);
      if (this.promoGoing.code === "X") {
        params.classCodeOutward = "T";
      }
    }
    if (this.promoRet) {
      fares.push(this.promoRet.code);
      if (this.promoRet.code === "X") {
        params.classCodeReturn = "T";
      }
    }
    let res: any = await import(
      "assets/utils/mockup/train-bookTrainGraph-mockup.json"
    );
    res = res.default;
    let mockAmount: any = "";
    mockAmount = parseFloat(this.promoGoing.price);
    if (this.promoRet != null) {
      mockAmount += parseFloat(this.promoRet.price);
    }
    res.ApiSession = this.apiSession ? this.apiSession : res.ApiSession;
    res.Amount = mockAmount;
    res.AmountToPay = mockAmount;
    res.InsurancePrice = 24.05;
    res.LimitDate = moment().add(20, "minutes").format("DD-MM-YY HH:mm");
    const navigationExtras: NavigationExtras = {
      state: {
        response: res,
        passengers: this.travelersFromModal.total,
        user: this.user,
        ApiSession: params.apiSession,
        searchParams: params,
        trains: this.trainArr,
        complementsOutward: this.complementsOutward,
        complementsReturn: this.complementsReturn,
        trainDates: this.trainDates,
        complement: {
          fares: fares,
        },
        isMocked: this.isMocked,
        outwardSeats: this.outwardSeats,
        returnSeats: this.returnSeats,
      },
    };
    this.loading = false;
    if (this.isUser) {
      this.router.navigate(["train/user-form"], navigationExtras);
    } else {
      this.router.navigate(
        [`train/${this.userUuid}/user-form`],
        navigationExtras
      );
    }
  }

  select(trainIndex, classCode) {
    this.promoList = [];
    let train = this.trainsListResult.OutwardTrains[trainIndex];

    if (this.return) {
      train = this.trainsListResult.ReturnTrains[trainIndex];
    }

    const sgListIndex = train.Classes.findIndex(
      (trainClass) => trainClass.ClassCode === classCode
    );

    if (sgListIndex > -1) {
      const sgList: SalesGroup[] = train.Classes[sgListIndex].SalesGroups;

      sgList.forEach((sg) => {
        sg.Cabins.forEach((cabin) => {
          cabin.Paxes.forEach((pax) => {
            pax.Fares.forEach((fare) => {
              const promo = {
                name: fare.FareName,
                price: fare.CurNetTa,
                selected: false,
                code: sg.SalesGroupCode,
                paxType: pax.PaxType,
              };
              this.promoList.push(promo);
            });
          });
        });
      });

      this.promoList.sort((a, b) => {
        return a.price - b.price;
      });

      if (!this.return) {
        this.classCodeG = classCode;
        this.outwardId = `${train.TrainTypeDescription + train.TrainNumber}`;
      } else {
        this.classCodeR = classCode;
        this.retId = `${train.TrainTypeDescription + train.TrainNumber}`;
      }
      return true;
    } else {
      return false;
    }
  }

  bookTrain(params) {
    this.loading = true;
    this.utilsService.loadingTrains = true;
    this.subcriptions.push(
      this.trainService.postTrainBookTrainMore(params).subscribe(
        (res) => {
          this.loading = false;
          const navigationExtras: NavigationExtras = {
            state: {
              response: res,
              passengers: this.travelersFromModal.total,
              user: this.user,
              trains: this.trainArr,
              bookParams: params,
              trainDates: this.trainDates,
              isTJO: this.isTJO,
              isTDO: this.isTDO,
            },
          };
          if (res.Amount == null || isNaN(res.Amount)) {
            this.subcriptions.push(
              this.translate
                .get([
                  "train.error.seat",
                  "train.error.seatTitle",
                  "train.error.unhandled",
                  "train.error.unhandledTitle",
                ])
                .subscribe((resp) => {
                  this.ngxToastrService.typeInfo(
                    resp["train.error.unhandledTitle"],
                    resp["train.error.unhandle"]
                  );
                  this.navigate("train");
                })
            );
          }
          this.loading = false;
          if (this.isUser) {
            this.router.navigate(["train/user-form"], navigationExtras);
          } else {
            this.router.navigate(
              [`train/${this.userUuid}/user-form`],
              navigationExtras
            );
          }
        },
        (err) => {
          if (err.status === 818) {
            this.subcriptions.push(
              this.translate
                .get(["train.trenymas.messages.error-availability"])
                .subscribe((result) => {
                  this.loading = false;
                  this.ngxToastrService.typeInfo(
                    "Error",
                    result["train.trenymas.messages.error-availability"]
                  );
                })
            );
            this.cleanData();
            this.loading = false;
            this.utilsService.loadingTrains = false;
          } else {
            // this.subcriptions.push(
            //   this.translate
            //     .get(["train.trenymas.messages.error"])
            //     .subscribe((result) => {
            //       this.loading = false;
            //       this.ngxToastrService.typeInfo(
            //         "Error",
            //         result["train.trenymas.messages.error"]
            //       );
            //     })
            // );
            this.bookTrainMock();
          }
          // this.router.navigate(["/train"]);
        }
      )
    );
  }

  bookTrainGraph(params) {
    this.loading = true;
    const fares = [];
    if (this.promoGoing) {
      fares.push(this.promoGoing.code);
    }
    if (this.promoGoing.code === "X") {
      params.classCodeOutward = "T";
    } else if (this.promoGoing.code === "Y") {
      params.classCodeOutward = "P";
    }
    if (this.promoRet) {
      fares.push(this.promoRet.code);
      if (this.promoRet.code === "X") {
        params.classCodeReturn = "T";
      } else if (this.promoRet.code === "Y") {
        params.classCodeReturn = "P";
      }
    }
    params.outwardSeats = [];
    params.returnSeats = [];
    let outwardTotalSeatPrice = 0;
    let returnTotalSeatPrice = 0;

    if (this.outwardSeats != null && this.outwardSeats.length > 0) {
      this.outwardSeats.forEach((seat) => {
        params.outwardSeats.push({
          seat_code: seat.seat_code,
          car_code: seat.car_code,
        });
      });
      outwardTotalSeatPrice = this.outwardSeatPrice * this.outwardSeats.length;
    } else {
      params.outwardSeats = null;
    }
    if (this.returnSeats != null && this.returnSeats.length > 0) {
      this.returnSeats.forEach((seat) => {
        params.returnSeats.push({
          seat_code: seat.seat_code,
          car_code: seat.car_code,
        });
      });
      returnTotalSeatPrice = this.returnSeatPrice * this.returnSeats.length;
    } else {
      params.returnSeats = null;
    }
    const totalSeatPrice = outwardTotalSeatPrice + returnTotalSeatPrice;
    this.utilsService.loadingTrains = true;
    this.subcriptions.push(
      this.trainService.bookTrainsGraph(params).subscribe(
        (res) => {
          if (res.Amount == null || isNaN(res.Amount)) {
            this.subcriptions.push(
              this.translate
                .get([
                  "train.error.seat",
                  "train.error.seatTitle",
                  "train.error.unhandled",
                  "train.error.unhandledTitle",
                ])
                .subscribe((resp) => {
                  if (
                    res.Errors.length > 0 &&
                    res.Errors[0].includes(".renfe.")
                  ) {
                    this.loading = false;
                    this.ngxToastrService.typeInfo(
                      resp["train.error.seatTitle"],
                      resp["train.error.seat"]
                    );
                    this.router.navigateByUrl("train");
                  } else {
                    this.loading = false;
                    this.ngxToastrService.typeInfo(
                      resp["train.error.unhandledTitle"],
                      resp["train.error.unhandle"]
                    );
                    this.navigate("train");
                  }
                })
            );
          } else {
            const navigationExtras: NavigationExtras = {
              state: {
                response: res,
                passengers: this.travelersFromModal.total,
                user: this.user,
                apiSession: params.apiSession,
                searchParams: params,
                trains: this.trainArr,
                complementsOutward: this.complementsOutward,
                complementsReturn: this.complementsReturn,
                trainDates: this.trainDates,
                complement: {
                  fares: fares,
                },
                outwardSeats: this.outwardSeats,
                returnSeats: this.returnSeats,
                totalSeatPrice: totalSeatPrice,
                isTJO: this.isTJO,
                isTDO: this.isTDO,
              },
            };
            this.loading = false;
            if (this.isUser) {
              this.router.navigate(["train/user-form"], navigationExtras);
            } else {
              this.router.navigate(
                [`train/${this.userUuid}/user-form`],
                navigationExtras
              );
            }
          }
        },
        (err) => {
          this.loading = false;
          // this.subcriptions.push(
          //   this.translate
          //     .get([
          //       "train.error.seat",
          //       "train.error.seatTitle",
          //       "train.error.unhandled",
          //       "train.error.unhandledTitle",
          //     ])
          //     .subscribe((resp) => {
          //       this.ngxToastrService.typeInfo(
          //         resp["train.error.unhandledTitle"],
          //         resp["train.error.unhandle"]
          //       );
          //       this.navigate("train");
          //     })
          // );
          this.bookTrainGraphMock(params);
        }
      )
    );
  }

  navigate(route: string, extra?: NavigationExtras) {
    this.router.navigate([route], extra!!);
  }

  ngOnDestroy() {
    this.subcriptions.forEach((s) => s.unsubscribe());
  }
  openErrorDialog(errorType: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {
      error: errorType,
    };

    this.dialog.open(ErrorDialogComponent, dialogConfig);
  }

  toggleDateOutRequired(required: boolean) {
    if (required === true) {
      this.formSearchTrain.controls["dateOut"].setValidators([
        Validators.required,
      ]);
      this.formSearchTrain.get("dateOut").updateValueAndValidity();
    } else {
      this.formSearchTrain.controls["dateOut"].clearValidators();
      this.formSearchTrain.get("dateOut").updateValueAndValidity();
    }
  }

  handleSelectedTravelers(selectedUserUuids: any){
    const userRequests = selectedUserUuids.map(uuid => this.userService.getUserByUuid(uuid));
    forkJoin(userRequests).subscribe(
      users => {
        this.selectedUsers = users;
        this.stateService.selectedUsers = this.selectedUsers;
      },
      error => {}
    );
  }

  handleSearchSubmit(searchInput: any) {
    const searchInputCopy = JSON.parse(JSON.stringify(searchInput));
    searchInputCopy.adults = [];
    searchInputCopy.children = [];
    searchInputCopy.infants = [];

    searchInputCopy.travelers.forEach((traveler) => {
      if (traveler.age >= 13) {
        searchInputCopy.adults.push(traveler);
      } else if (traveler.age < 13 && traveler.age >= 4) {
        searchInputCopy.children.push(traveler);
      } else {
        searchInputCopy.infants.push(traveler);
      }
    });
    delete searchInputCopy.travelers;
    this.onSearch(searchInputCopy);
  }
}
